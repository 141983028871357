import React, { useState } from 'react';
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody, Button } from 'appkit-react';
import { RadioButton } from 'primereact/radiobutton';
import { TableSchema } from '../../CMTableSchema';
import AdvanceForm from './AdvanceForm';
import SecurityDepositForm from './SecurityDepositForm';
import OtherDeductionForm from './OtherDeductionForm';
import PaymentScheduleForm from './PaymentScheduleForm';
import SupplyCatTopForm from './SupplyCatTopForm';
import FinancialSanctionModal from './FinancialSanctionModal';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryTable';
import FormSchema from '../formSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import Schema from '../../../../schema/AppSchema';
import DeleteConfirmModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { contractClient } from '../../../../apollo';
import { Toast } from 'primereact/toast';
import { getDate, serverDate } from '../../../../util';
import ContractTypeModal from '../ContractTypeModal';
import * as FormValidation from '../../../../Validation';
import HOASelection from '../../../../components/HOADetails/HOASelection';

const ContractDetailsForm = (props) => {
    let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';
    let genFormDetails = props.formStateObj?.General_Contract_Information;
    const [formState, setFormState] = useState({
        contracType: props.formStateObj?.Contract_Details.contracType,
        rows: JSON.parse(JSON.stringify(props.formStateObj?.Contract_Details.rows)),
        totals: props?.formStateObj?.Contract_Details.totals
    });
    const [isHOAValid, setHOA] = useState(false);
    const [growl, setGrowl] = useState();
    let selRow = formState.contracType !== 'supplyCat' ? props?.formStateObj?.Contract_Details?.totals : {};
    let catOptions =
        (props.contractDetails?.supply_catagories &&
            props.contractDetails?.supply_catagories.filter((catagory) => {
                return catagory.is_active;
            })) ||
        [];
    let viewMode = TableSchema.CONTCT_DETAILS.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.CONTCT_DETAILS : viewMode;
    const [errors, setErrors] = useState(false);
    const [editable, setEditable] = useState(false);

    const [state, setState] = useState({
        selRow: selRow,
        columns: tablecolms,
        activeAccordion: [],
        sup_catOptions: getcategoriesList(),
        showForm: false,
        showHoaModal: false,
        showFinModal: false,
        showDeleteModal: false,
        showCntrctModal: false,
        radioEvent: '',
        sAction: ''
    });

    function getcategoriesList() {
        let list = JSON.parse(JSON.stringify(catOptions));
        return list;
    }

    let moreDeatilsEmptyObj = {
        Advance: {
            rows: []
        },
        Security_Deposit_Deduction: {
            scc_depo_deduc_rate: '',
            scc_depo_refund_time: ''
        },
        Other_Deductions: {
            rows: [],
            totalAmount: ''
        },
        Payment_Schedule: {
            rows: []
        }
    };
    let totalEmptyObj = {
        cntrct_fin_sanc: {
            totalAmount: 0
        },
        cntrct_val_inc_tax: '',
        cntrct_val_exc_tax: ''
    };

    let obj = formState.contracType !== 'supplyCat' ? props?.formStateObj?.Contract_Details?.moreDetailsObj : moreDeatilsEmptyObj;
    const [moreDetailsObj, setMoreDetailsObj] = useState({ ...obj });

    let emptyObj = {
        slno: '',
        admissable_var: '',
        cntrct_val_inc_tax: '',
        cntrct_val_exc_tax: '',
        hoa: '',
        sup_cat: '',
        cntrct_fin_sanc: {
            finRows: [],
            totalAmount: 0
        },
        moreDetails: false
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const handleAccordionChange = (value) => {
        setState({
            ...state,
            activeAccordion: state.activeAccordion[0] === value ? '' : [value]
        });
    };

    const addNewRow = () => {
        state.sup_catOptions = getcategoriesList();
        currentDetailsObj['slno'] = formState.rows.length + 1;
        currentDetailsObj['moreDetails'] = formState.contracType === 'supplyCat' ? true : false;
        currentDetailsObj['admissable_var'] = '0';
        setCurrentDetailsObj(currentDetailsObj);
        setState({ ...state, showForm: true, sup_catOptions: state.sup_catOptions, activeAccordion: [] });
        setEditable(true);
    };
    const handleChange = (ev, key) => {
        let pattern = new RegExp(/^\d*(\.\d{0,2})?$/g);
        let err = errors === false ? {} : errors;
        if (key === 'sup_cat') {
            currentDetailsObj.hoa = '';
        }
        setCurrentDetailsObj({ ...currentDetailsObj, [key]: ev });
        if (
            key === 'admissable_var' &&
            window?._VALIDATIONS_?.CONTRACT_ADMISSABLE_VARAIANCE &&
            (parseFloat(ev) > parseFloat(props.admisVar) || isNaN(ev) || ev.indexOf(' ') > 0 || !pattern.test(ev))
        ) {
            err['admissable_var'] = true;
            let preverrors = { ...errors, ...err };
            setErrors(preverrors);
            return;
        }
        if (
            key === 'cntrct_val_inc_tax' &&
            (parseFloat(ev) > parseFloat(currentDetailsObj.cntrct_fin_sanc.totalAmount) ||
                parseFloat(ev) < parseFloat(props.raisedBillAmount) ||
                isNaN(ev) ||
                ev.indexOf('.') >= 0 ||
                parseInt(ev) === 0 ||
                ev.indexOf(' ') > 0)
        ) {
            err['cntrct_val_inc_tax'] = true;
            let preverrors = { ...errors, ...err };
            setErrors(preverrors);
            return;
        }
        if (
            key === 'cntrct_val_exc_tax' &&
            (parseFloat(ev) > parseFloat(currentDetailsObj.cntrct_val_inc_tax) ||
                parseFloat(ev) < parseFloat(props.raisedBillAmount) ||
                isNaN(ev) ||
                ev.indexOf('.') >= 0 ||
                parseInt(ev) === 0 ||
                ev.indexOf(' ') > 0)
        ) {
            err['cntrct_val_exc_tax'] = true;
            let preverrors = { ...errors, ...err };
            setErrors(preverrors);
            return;
        }
        if (key === 'cntrct_val_inc_tax') {
            currentDetailsObj.cntrct_val_exc_tax = '';
        }

        if (key === 'admissable_var' || key === 'cntrct_val_exc_tax' || key === 'cntrct_val_inc_tax') {
            validateFeild(ev, key);
        }
        if (key === 'hoa') {
            currentDetailsObj.hoa = ev;
        }
    };
    const validateFeild = (ev, key) => {
        let err = {};
        err = FormValidation.validateFileld('required', key, ev);
        let preverrors = { ...errors, ...err };
        setErrors(preverrors);
    };

    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    // preapare Advance Recovories for selected row and return sum of all recovories
    function getRecoveryTotal(moreObj, value) {
        let amount = 0;
        let advRows = [];
        if (moreObj?.Advance?.rows?.length > 0) {
            moreObj.Advance.rows.forEach((a, index) => {
                let val = (parseFloat(value) / 100) * parseFloat(a.adv_rate);
                if (!isNaN(val)) amount = amount + val;
                advRows.push({
                    slno: index + 1,
                    adv_type: a.adv_type.name,
                    edit: a.adv_rec_mthd === 'Manual' ? true : false,
                    enableEdit: false,
                    adv_inc_amount: !isNaN(val) ? val.toFixed(2) : '',
                    adv_rec_mthd: a.adv_rec_mthd,
                    is_edited: a.adv_rec_mthd === 'Manual' ? true : false,
                    id: a.adv_type.id
                });
            });
        }
        if (isNaN(amount) || (!isNaN(amount) && parseFloat(amount) <= 0)) amount = '';
        else amount = amount.toFixed(2);
        return { amount: amount, advRows: advRows };
    }
    // calculate security deposit deduction rate on incl of taxes and return value
    function getSecurityRate(moreObj, value) {
        let val = (parseFloat(value) / 100) * parseFloat(moreObj?.Security_Deposit_Deduction?.scc_depo_deduc_rate?.rate);
        if (isNaN(val)) val = '';
        else val = val.toFixed(2);
        return val;
    }

    // other deductions prepare table rows and based on type(incl or excl) return sum of rows
    const calculateDeductions = (item, incVal, excVal) => {
        let val = 0;
        if (item?.deduction?.exceptions?.length > 0 && item.deduction.exceptions?.find((a) => a.fmis_function === 'Create Contract')) {
            val = (parseFloat(excVal) / 100) * parseFloat(item.deduction.amount);
        } else {
            val = (parseFloat(incVal) / 100) * parseFloat(item.deduction.amount);
        }
        return val;
    };

    function getOtherDeductionRate(moreObj, incVal, excVal) {
        let amount = 0;
        let deductionRows = [];
        if (moreObj?.Other_Deductions?.rows?.length > 0 && incVal && excVal) {
            moreObj.Other_Deductions.rows.forEach((a, index) => {
                let val = a.deduction?.type !== 'Percentage' ? parseFloat(a.amount) : calculateDeductions(a, incVal, excVal);
                deductionRows.push({
                    slno: index + 1,
                    deduction: a.deduction,
                    dedType: a.dedType,
                    pay_amount: !isNaN(val) ? val.toFixed(2) : '',
                    remarks: '',
                    edit: a.deduction?.is_editable,
                    enableEdit: false,
                    is_edited: a.deduction?.is_editable
                });
                if (!isNaN(val)) amount = amount + val;
            });
        }
        if (isNaN(amount) || (!isNaN(amount) && parseFloat(amount) <= 0)) amount = '';
        else amount = amount.toFixed(2);
        return { amount: amount, deductionRows: deductionRows };
    }

    // calculate net payment amount -->( incl amount - adv rec - security deposit - other deductions)
    const getNetPay = (moreObj, data) => {
        let netAmt = parseFloat(data.amt_inc_tax);
        if (!isNaN(parseFloat(data.security_depst_dedct))) {
            netAmt = netAmt - parseFloat(data.security_depst_dedct);
        }
        if (!isNaN(parseFloat(data.adavce_recovery))) {
            netAmt = netAmt - parseFloat(data.adavce_recovery);
        }
        if (!isNaN(parseFloat(data.other_deduction))) {
            netAmt = netAmt - parseFloat(data.other_deduction);
        }
        if (isNaN(netAmt)) netAmt = '';
        else netAmt = netAmt.toFixed(2);
        return netAmt;
    };

    const setPaySchedules = (moreObj, autosave) => {
        // to check and set adv recocories, deductions, net payment amount in deliverables
        let moreDetails = JSON.parse(JSON.stringify(moreObj));
        if (moreDetails?.Payment_Schedule?.rows?.length > 0) {
            moreDetails.Payment_Schedule.rows.forEach((a) => {
                if (!a.type || a.type !== 'advance') {
                    let adv = getRecoveryTotal(moreObj, a.amt_inc_tax);
                    let ded = getOtherDeductionRate(moreObj, a.amt_inc_tax, a.amt_exc_tax);
                    a.adavce_recovery = adv.amount;
                    a.advance_recoveries = adv.advRows;
                    a.security_depst_dedct = getSecurityRate(moreObj, a.amt_inc_tax);
                    a.other_deduction = ded.amount;
                    a.deduction_details = ded.deductionRows;
                    a.net_pay_amt = getNetPay(moreObj, a);
                }
            });
        }
        setMoreDetailsObj({ ...moreDetails });
        if (autosave) saveAdditionalData(moreDetails);
    };

    const setAdvAndPayschedules = (data, autosave) => {
        let payData = { rows: [] };
        let payAdvRows = [];
        let delRows = [];
        if (data.rows.length > 0) {
            data.rows.forEach((a) => {
                payAdvRows.push({
                    slno: payAdvRows.length + 1,
                    due_date: getDate(serverDate()),
                    hoa: a.hoa,
                    delete: false,
                    pay_milestone: a.adv_type.name,
                    advance_id: a.adv_type.id,
                    amt_exc_tax: a.adv_amount,
                    amt_inc_tax: a.adv_amount,
                    net_pay_amt: a.adv_amount,
                    adavce_recovery: 'NA',
                    security_depst_dedct: 'NA',
                    other_deduction: 'NA',
                    type: 'advance'
                });
            });
        }
        if (moreDetailsObj?.Payment_Schedule?.rows?.length > 0) {
            delRows = moreDetailsObj.Payment_Schedule.rows.filter((a) => {
                a.delete = true;
                return !a.type || a.type !== 'advance';
            });
        }
        payData.rows = [...payAdvRows, ...delRows];
        let moreObj = moreDetailsObj;
        moreObj['Advance'] = data;
        moreObj['Payment_Schedule'] = payData;
        let hasDedChange = setMoreDetailsDeds();
        if (hasDedChange) moreObj['Other_Deductions'] = hasDedChange;
        setPaySchedules(moreObj, autosave);
    };

    const saveCntrctFormsData = (type, data, autosave) => {
        if (type === 'Advance') {
            setAdvAndPayschedules(data, autosave);
            return;
        }
        if (type === 'Other_Deductions' || type === 'Security_Deposit_Deduction') {
            let moreObj = moreDetailsObj;
            moreObj[type] = data;
            setPaySchedules(moreObj, autosave);
            return;
        }
        setMoreDetailsObj({
            ...moreDetailsObj,
            [type]: data
        });
    };
    const setMoreData = (rowData) => {
        if (formState.contracType === 'supplyCat') {
            let moreObj = JSON.parse(JSON.stringify(moreDeatilsEmptyObj));
            if (rowData.moreDetailsObj) {
                moreObj = JSON.parse(JSON.stringify(rowData.moreDetailsObj));
                setMoreDetailsObj(moreObj);
            } else {
                setMoreDetailsObj(moreObj);
            }
        }
    };
    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            setHOA(rowData.hoa !== '' ? true : false);
            let newObj = JSON.parse(JSON.stringify(rowData));
            let newObj1 = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj1);
            setMoreData(rowData);
            setState({
                ...state,
                showForm: true,
                actionType: sAction,
                activeAccordion: [],
                sup_catOptions: getcategoriesList(),
                selRow: rowData
            });
            setErrors({});
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, activeAccordion: [], showForm: false, sAction: '' });
        }
        if (
            (formState.contracType === 'supplyCat' && sAction === 'moreDetails') ||
            (column.field === 'sup_cat.name' && sAction === 'view_click')
        ) {
            setState({ ...state, selRow: rowData, activeAccordion: [], showForm: false, sAction: sAction });
            setMoreData(rowData);
        }
        if (sAction === 'view_click' && column.field === 'cntrct_fin_sanc.totalAmount') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setState({ ...state, showFinModal: true, sAction: sAction });
        }
    };

    function typeOfDeduction(data) {
        let type = 'cntrct_val_inc_tax';
        if (data.exceptions?.find((a) => a.fmis_function === 'Create Contract')) {
            type = 'cntrct_val_exc_tax';
        }
        return type;
    }
    const setMoreDetailsDeds = () => {
        // update deductions when contract value chnage
        let editIndex = formState.rows.findIndex((a) => a.id === state.selRow?.id && a.hoa === state.selRow?.hoa);
        if (
            formState.contracType === 'supplyCat' &&
            formState.rows[parseInt(editIndex)]?.moreDetailsObj?.Other_Deductions?.rows?.length > 0
        ) {
            let deds = JSON.parse(JSON.stringify(formState.rows[parseInt(editIndex)].moreDetailsObj.Other_Deductions.rows));
            let amt = 0;
            deds.forEach((a) => {
                if (a.deduction?.type === 'Percentage') {
                    let type = typeOfDeduction(a.deduction);
                    let val = (parseFloat(a.deduction.amount) / 100) * parseFloat(formState.rows[parseInt(editIndex)][type]);
                    a.amount = val.toFixed(2);
                }
                if (!isNaN(parseFloat(a.amount))) amt = amt + parseFloat(a.amount);
                if (!isNaN(amt)) amt = parseFloat(amt).toFixed(2);
                else amt = '';
            });
            return { rows: deds, totalAmount: amt };
        }
        if (formState.contracType !== 'supplyCat' && formState.rows.length > 0 && moreDetailsObj?.Other_Deductions?.rows?.length > 0) {
            let deds = JSON.parse(JSON.stringify(moreDetailsObj.Other_Deductions.rows));
            let amt = 0;
            deds.forEach((a) => {
                if (a.deduction?.type === 'Percentage') {
                    let type = typeOfDeduction(a.deduction);
                    let val = (parseFloat(a.deduction.amount) / 100) * parseFloat(formState.totals[type]);
                    a.amount = val.toFixed(2);
                }
                if (!isNaN(parseFloat(a.amount))) amt = amt + parseFloat(a.amount);
                if (!isNaN(amt)) amt = parseFloat(amt).toFixed(2);
                else amt = '';
            });
            return { rows: deds, totalAmount: amt };
        }
    };
    const updateCatTypeMoreDetails = (index) => {
        // advances & payschedule amount updation when incl value chnages for supply category type contract
        if (formState.rows.length > 0 && index) {
            if (formState.rows[parseInt(index)]?.moreDetailsObj?.Advance?.rows?.length > 0) {
                let advances = JSON.parse(JSON.stringify(formState.rows[parseInt(index)]?.moreDetailsObj?.Advance.rows));
                advances.forEach((a) => {
                    if (a.adv_rate) {
                        let amt = (parseFloat(a.adv_rate) / 100) * parseFloat(formState.rows[parseInt(index)].cntrct_val_inc_tax);
                        a.adv_amount = amt.toFixed(2);
                    }
                });
                saveCntrctFormsData('Advance', { rows: advances }, true);
            }
            if (formState.rows[parseInt(index)]?.moreDetailsObj?.Other_Deductions?.rows?.length > 0) {
                let deds = JSON.parse(JSON.stringify(formState.rows[parseInt(index)].moreDetailsObj.Other_Deductions.rows));
                let amt = 0;
                deds.forEach((a) => {
                    if (a.deduction?.type === 'Percentage') {
                        let type = typeOfDeduction(a.deduction);
                        a.amount = (parseFloat(a.deduction.amount) / 100) * parseFloat(formState.rows[parseInt(index)][type]);
                    }
                    if (!isNaN(parseFloat(a.amount))) amt = amt + parseFloat(a.amount);
                    if (!isNaN(amt)) amt = parseFloat(amt).toFixed(2);
                    else amt = '';
                });
                moreDetailsObj.Other_Deductions = {
                    rows: deds,
                    totalAmount: amt
                };
            }
        }
    };

    const updateTotalMoreDetails = () => {
        // advances & payschedule amount updation when incl value chnages for total contract type contract
        if (formState.rows.length > 0 && moreDetailsObj?.Advance?.rows?.length > 0) {
            let advances = JSON.parse(JSON.stringify(moreDetailsObj.Advance.rows));
            advances.forEach((a) => {
                if (a.adv_rate) {
                    let amt = (parseFloat(a.adv_rate) / 100) * parseFloat(formState.totals.cntrct_val_inc_tax);
                    a.adv_amount = amt.toFixed(2);
                }
            });
            saveCntrctFormsData('Advance', { rows: advances }, true);
        }
        if (formState.rows.length > 0 && moreDetailsObj?.Other_Deductions?.rows?.length > 0) {
            let deds = JSON.parse(JSON.stringify(moreDetailsObj.Other_Deductions.rows));
            let amt = 0;
            deds.forEach((a) => {
                if (a.deduction?.type === 'Percentage') {
                    let type = typeOfDeduction(a.deduction);
                    a.amount = (parseFloat(a.deduction.amount) / 100) * parseFloat(formState.totals[type]);
                }
                if (!isNaN(parseFloat(a.amount))) amt = amt + parseFloat(a.amount);
                if (!isNaN(amt)) amt = parseFloat(amt).toFixed(2);
                else amt = '';
            });
            moreDetailsObj.Other_Deductions = {
                rows: deds,
                totalAmount: amt
            };
        }
    };

    const saveCntrctDetails = () => {
        if (parseFloat(currentDetailsObj.cntrct_fin_sanc?.totalAmount) < parseFloat(currentDetailsObj.cntrct_val_inc_tax)) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.ContractValueIncl
            });
            return;
        }
        if (parseFloat(currentDetailsObj.cntrct_val_inc_tax) < parseFloat(currentDetailsObj.cntrct_val_exc_tax)) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.ContractValueExcl
            });
            return;
        }
        if (
            formState.rows.find((a) => {
                return (
                    (state.actionType !== 'edit' && a.sup_cat.id === currentDetailsObj.sup_cat.id && a.hoa === currentDetailsObj.hoa) ||
                    (state.actionType === 'edit' &&
                        !(a.sup_cat.id === editObj.sup_cat.id && a.hoa === editObj.hoa) &&
                        a.sup_cat.id === currentDetailsObj.sup_cat.id &&
                        a.hoa === currentDetailsObj.hoa)
                );
            })
        ) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.SupplyCategory });
            return;
        }

        let cntrctFinSanc = 0;
        let cntrctValIncTax = 0;
        let cntrctValExcTax = 0;
        formState.rows.forEach((a) => {
            if (
                state.actionType !== 'edit' ||
                (state.actionType === 'edit' && !(a.sup_cat.id === editObj.sup_cat.id && a.hoa === editObj.hoa))
            ) {
                cntrctFinSanc = cntrctFinSanc + parseFloat(a.cntrct_fin_sanc?.totalAmount);
                cntrctValIncTax = cntrctValIncTax + parseFloat(a.cntrct_val_inc_tax);
                cntrctValExcTax = cntrctValExcTax + parseFloat(a.cntrct_val_exc_tax);
            }
        });

        formState.totals.cntrct_fin_sanc.totalAmount = cntrctFinSanc + parseFloat(currentDetailsObj.cntrct_fin_sanc?.totalAmount);
        formState.totals.cntrct_val_inc_tax = cntrctValIncTax + parseFloat(currentDetailsObj.cntrct_val_inc_tax);
        formState.totals.cntrct_val_exc_tax = cntrctValExcTax + parseFloat(currentDetailsObj.cntrct_val_exc_tax);

        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.sup_cat.id === editObj.sup_cat.id && a.hoa === editObj.hoa);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState });
            if (formState.contracType === 'supplyCat') updateCatTypeMoreDetails(indx.toString());
        } else {
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState });
        }
        if (formState.contracType !== 'supplyCat') updateTotalMoreDetails();
        setState({
            ...state,
            showForm: false,
            actionType: '',
            selRow: formState.contracType === 'supplyCat' ? currentDetailsObj : { ...formState.totals }
        });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        setEditable(false);
        props.saveFormData('Contract_Details', formState);
    };

    const checkErrors = () => {
        const result = [];
        for (let i in errors) {
            if (errors[i] === true) {
                result.push(i);
            }
        }
        return result.length > 0;
    };

    const disableSave = () => {
        for (let key in currentDetailsObj) {
            if (
                (key !== 'moreDetails' || key !== 'cntrct_fin_sanc') &&
                (currentDetailsObj[key] === undefined || currentDetailsObj[key] === '' || currentDetailsObj[key]) &&
                typeof currentDetailsObj[key] === 'string' &&
                (currentDetailsObj[key].trim() === '' || checkErrors() || !isHOAValid)
            ) {
                return true;
            }
        }
        return false;
    };

    const saveHoa = (arr, hoa) => {
        setCurrentDetailsObj({ ...currentDetailsObj, hoa: hoa });
        handleCancel('showHoaModal');
        setHOA(true);
    };

    const handleDeleteRow = () => {
        formState.rows = formState.rows.filter((a) => {
            return !(a.sup_cat.id === currentDetailsObj.sup_cat.id && a.hoa === currentDetailsObj.hoa);
        });

        formState.totals.cntrct_fin_sanc.totalAmount =
            formState.totals?.cntrct_fin_sanc?.totalAmount - parseFloat(currentDetailsObj.cntrct_fin_sanc?.totalAmount);
        formState.totals.cntrct_val_inc_tax = formState.totals.cntrct_val_inc_tax - parseFloat(currentDetailsObj.cntrct_val_inc_tax);
        formState.totals.cntrct_val_exc_tax = formState.totals.cntrct_val_exc_tax - parseFloat(currentDetailsObj.cntrct_val_exc_tax);

        setFormState({ ...formState, rows: formState.rows, totals: formState.totals });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        if (formState.contracType !== 'supplyCat' && formState.rows.length === 0) {
            setMoreDetailsObj(moreDeatilsEmptyObj);
        }
        props.saveFormData('Contract_Details', formState);
        handleCancel('showDeleteModal');
    };

    const saveFinSancData = (data) => {
        currentDetailsObj.cntrct_fin_sanc = data;
        setCurrentDetailsObj(currentDetailsObj);
        handleCancel('showFinModal');
    };

    const handleRadioChanage = (e) => {
        setState({ ...state, showCntrctModal: true, radioEvent: e });
    };

    const handleYes = () => {
        setState({ ...state, showForm: false, selRow: {}, activeAccordion: '', showCntrctModal: false });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        setMoreDetailsObj(moreDeatilsEmptyObj);
        if (state.radioEvent?.target.value !== 'supplyCat') {
            formState.moreDetailsObj = moreDeatilsEmptyObj;
        }
        setFormState({ ...formState, rows: [], contracType: state.radioEvent?.target.value, totals: totalEmptyObj });
        props.saveFormData('Contract_Details', formState);
    };

    const checkPayHoa = () => {
        let hoa = false;
        if (moreDetailsObj.Payment_Schedule?.rows?.length > 0) {
            moreDetailsObj.Payment_Schedule.rows.forEach((a) => {
                if (a.type === 'advance' && (a.hoa === undefined || a.hoa === '')) {
                    hoa = true;
                }
                if (hoa) return;
            });
        }
        return hoa;
    };

    const saveAdditionalData = (moreObj) => {
        if (formState.contracType === 'supplyCat') {
            let indx = formState.rows.findIndex((a) => a.sup_cat.id === state.selRow?.sup_cat?.id && a.hoa === state.selRow.hoa);
            if (indx !== -1) {
                formState.rows[indx].moreDetailsObj = moreObj;
                setMoreDetailsObj(moreDeatilsEmptyObj);
                setState({ ...state, selRow: {}, activeAccordion: [], sAction: '' });
            }
        } else {
            if (checkPayHoa()) {
                growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_CONTRACT_DETAILS.HOAMandatory });
                return;
            }
            formState['moreDetailsObj'] = moreObj;
            setState({ ...state, activeAccordion: [], sAction: '' });
        }
        setFormState({ ...formState });
        props.saveFormData('Contract_Details', formState);
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={3} />
                <Column footer={formState?.totals?.cntrct_fin_sanc.totalAmount} footerStyle={{ textAlign: 'right' }} />
                <Column footer={formState?.totals?.cntrct_val_inc_tax} footerStyle={{ textAlign: 'right' }} />
                <Column footer={formState?.totals?.cntrct_val_exc_tax} footerStyle={{ textAlign: 'right' }} />
                <Column />
                {props.formType !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );

    const handleHOAPopup = (e, hoa) => {
        setState({
            ...state,
            showHoaModal: e
        });
        setCurrentDetailsObj({
            ...currentDetailsObj,
            hoa: hoa
        });
    };
    const validateHOA = (e) => {
        setHOA(e);
    };
    return (
        <div className="coa-card overflow-initial">
            <Toast ref={(el) => setGrowl(el)} />
            <div className="row m-l-10 m-r-10 p-t-10">
                <div className={`col-sm-5 col-xs-12 col-lg-5 col-xl-3 p-0 ${props.formType ? 'col-md-8' : 'col-md-12'}`}>
                    <b>Set Advances, Deductions & Payment Milestones at : </b>
                </div>
                {props.formType !== 'view' && !props.checkIsApproved ? (
                    <div className="p-l-10 col-sm-5 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                        <RadioButton
                            inputId="rb1"
                            value="supplyCat"
                            name="supplyCat"
                            onChange={(e) => handleRadioChanage(e)}
                            checked={formState.contracType === 'supplyCat'}
                        />
                        <label htmlFor="rb1" className="p-radiobutton-label">
                            Supply Category Value
                        </label>
                        <RadioButton
                            inputId="rb2"
                            value="totalCntrct"
                            name="totalCntrct"
                            className="m-l-20"
                            onChange={(e) => handleRadioChanage(e)}
                            checked={formState.contracType === 'totalCntrct'}
                        />
                        <label htmlFor="rb2" className="p-radiobutton-label">
                            Total Contract Value
                        </label>
                    </div>
                ) : (
                    <div className="col-sm-5 col-xs-12 col-md-4 col-lg-3 col-xl-3">
                        <span>{formState.contracType === 'supplyCat' ? 'Supply Category Value' : 'Total Contract Value'}</span>
                    </div>
                )}
                {props.formType !== 'view' && (
                    <div className="col-sm-12 p-r-0 p-t-10">
                        <Button size="md" className="float-right" disabled={state.showForm} kind="primary" onClick={() => addNewRow()}>
                            Add Contract Details
                        </Button>
                    </div>
                )}
            </div>
            <div className="p-10">
                {formState.contracType !== '' && (
                    <div>
                        <SecondaryDataTable
                            rows={formState?.rows}
                            columns={state.columns}
                            emptyMessage="Contract Details are yet to be created"
                            noPaginator={true}
                            handleActionsInParent={handleActions}
                            footerGroup={footerGroup}
                            formType={props.formType}
                            className="secondary-table"
                            scrollable
                        />
                    </div>
                )}
                {formState.contracType !== '' && state.showForm && (
                    <div className="m-t-10">
                        <div className="row m-b-10">
                            {FormSchema.FLD_CD_SUP_CATG.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex height-content">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_SUP_CATG.name}
                                        {FormSchema.FLD_CD_SUP_CATG.is_mandatory !== -1 &&
                                        props.formType !== 'view' &&
                                        (!props.checkIsApproved || editable) ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    {props.formType !== 'view' && (!props.checkIsApproved || editable) ? (
                                        <Dropdown
                                            name="sup_cat"
                                            value={currentDetailsObj.sup_cat}
                                            onChange={(e) => handleChange(e.value, 'sup_cat')}
                                            appendTo={document.self}
                                            options={state.sup_catOptions}
                                            placeholder="Select Supply Category"
                                            className="w-60"
                                            optionLabel="name"
                                        />
                                    ) : (
                                        <span>{currentDetailsObj?.sup_cat?.name ? currentDetailsObj.sup_cat.name : '–'}</span>
                                    )}
                                </div>
                            )}
                            {FormSchema.FLD_CD_SUPP_HOA.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_SUPP_HOA.name}
                                        {FormSchema.FLD_CD_SUPP_HOA.is_mandatory !== -1 &&
                                        props.formType !== 'view' &&
                                        (!props.checkIsApproved || editable) ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <div className={!currentDetailsObj.sup_cat ? 'disabled w-60' : 'w-60'}>
                                        {props.formType !== 'view' && (!props.checkIsApproved || editable) ? (
                                            <HOASelection
                                                handleHOAPopup={handleHOAPopup}
                                                hoa={currentDetailsObj.hoa}
                                                function="Supply Category"
                                                placeholder="HOA for Supply Category"
                                                showHoaModal={state.showHoaModal}
                                                handleHOACancel={(e) => handleCancel(e)}
                                                updateHOAInParent={saveHoa}
                                                selectedHOA={currentDetailsObj.hoa}
                                                validateHOA={validateHOA}
                                                isHOAValid={isHOAValid}
                                                hoaDetails={{
                                                    fetchCompositionQry: Schema.getHOAForSupplyCats,
                                                    variables: {
                                                        category_id: currentDetailsObj?.sup_cat?.id,
                                                        functionCode: 'CNTR_CONTRACT_CREATION',
                                                        officeCode: 'GLOBAL',
                                                        activityCode: 'SUPPLY_CATEGORY'
                                                    },
                                                    emptyMessage: 'HOAs are yet to be created',
                                                    responseObj: 'GetCntrSupplyCategoryMappings',
                                                    client: contractClient
                                                }}
                                            />
                                        ) : (
                                            <span>{currentDetailsObj?.hoa ? currentDetailsObj.hoa : '–'}</span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row m-b-10">
                            {FormSchema.FLD_CD_FIN_SANC_INR.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_FIN_SANC_INR.name}
                                        {FormSchema.FLD_CD_FIN_SANC_INR.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <p
                                        className={`primary-color pointer ${
                                            genFormDetails?.signing_date === '' && genFormDetails?.closing_date === '' ? 'disabled' : ''
                                        }`}
                                        onClick={() => setState({ ...state, showFinModal: true })}
                                    >
                                        {currentDetailsObj.cntrct_fin_sanc.totalAmount > 0
                                            ? currentDetailsObj.cntrct_fin_sanc.totalAmount
                                            : 'Add Financial Sanction'}
                                    </p>
                                </div>
                            )}
                            {FormSchema.FLD_CD_ADMIS_VAR.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_ADMIS_VAR.name}
                                        {FormSchema.FLD_CD_ADMIS_VAR.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="admissable_var"
                                        type="text"
                                        value={currentDetailsObj.admissable_var}
                                        placeholder="Admissible Variance in (%)"
                                        onChange={(e) => handleChange(e, 'admissable_var')}
                                        hasError={errors && errors.admissable_var}
                                        errMsg={'Admissible Variance should not be greater than ' + props.admisVar}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row m-b-10">
                            {FormSchema.FLD_CD_INC_TAX.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_INC_TAX.name}
                                        {FormSchema.FLD_CD_INC_TAX.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="cntrct_val_inc_tax"
                                        type="text"
                                        value={currentDetailsObj.cntrct_val_inc_tax}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'cntrct_val_inc_tax')}
                                        placeholder={'Enter Contract Value Incl of Taxes (' + currency + ')'}
                                        disabled={!currentDetailsObj.cntrct_fin_sanc.totalAmount}
                                        hasError={errors && errors.cntrct_val_inc_tax}
                                        errMsg={
                                            parseFloat(currentDetailsObj.cntrct_val_inc_tax) < parseFloat(props.raisedBillAmount)
                                                ? `Contract Value Incl of Taxes should not be less than ${props.raisedBillAmount}`
                                                : 'Contract Value Incl of Taxes should not be greater than Financial Sanction'
                                        }
                                    />
                                </div>
                            )}
                            {FormSchema.FLD_CD_EXCL_TAX.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_CD_EXCL_TAX.name}
                                        {FormSchema.FLD_CD_EXCL_TAX.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="cntrct_val_exc_tax"
                                        type="text"
                                        value={currentDetailsObj.cntrct_val_exc_tax}
                                        maxLength={15}
                                        onChange={(e) => handleChange(e, 'cntrct_val_exc_tax')}
                                        placeholder={'Enter Contract Value Excl of Taxes (' + currency + ')'}
                                        disabled={!currentDetailsObj.cntrct_val_inc_tax}
                                        hasError={errors && errors.cntrct_val_exc_tax}
                                        errMsg={
                                            parseFloat(currentDetailsObj.cntrct_val_exc_tax) < parseFloat(props.raisedBillAmount)
                                                ? `Contract Value Excl of Taxes should not be less than ${props.raisedBillAmount}`
                                                : 'Contract Value Excl of Taxes should not be greater than Contract Value Incl of Taxes'
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div>
                            <Button
                                size="sm"
                                disabled={disableSave()}
                                kind="primary"
                                className="m-r-10"
                                onClick={() => saveCntrctDetails()}
                            >
                                {state.actionType === 'edit' ? 'Update Contract Details' : 'Add Contract Details'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                    setEditable(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}

                {((state?.selRow?.sup_cat?.name &&
                    formState.contracType === 'supplyCat' &&
                    (state.sAction === 'moreDetails' || state.sAction === 'view_click')) ||
                    (formState.contracType === 'totalCntrct' && formState?.rows?.length > 0)) && (
                    <div className="page-body m-t-10">
                        <div className="page-header m-b-10">
                            <span className="light-color">
                                {formState.contracType === 'totalCntrct'
                                    ? 'Total Contract'
                                    : 'Supply Category : ' + state.selRow.sup_cat.name}
                            </span>
                        </div>
                        {formState.contracType === 'supplyCat' && (
                            <SupplyCatTopForm saveFormData={saveCntrctFormsData} selectedRow={state.selRow} formType={props.formType} />
                        )}
                        <div className="p-l-10 p-r-10 p-t-10">
                            <Accordions
                                multiOpen={props.formType === 'view'}
                                activeItem={state.activeAccordion}
                                onChange={(e) => handleAccordionChange(e)}
                            >
                                <AccordionItem itemId="Advance" className="accordian-item m-b-10 code-acc">
                                    <AccordionItemHeader title={'Advance'} />
                                    <AccordionItemBody>
                                        <AdvanceForm
                                            saveFormData={saveCntrctFormsData}
                                            Advance={moreDetailsObj?.Advance}
                                            selectedRow={state.selRow}
                                            formStateObj={formState}
                                            advances={props.supplierData.advances}
                                            growl={growl}
                                            formType={props.formType}
                                            suplycats={formState?.rows}
                                            checkIsApproved={props.checkIsApproved}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem itemId="Security_Deposit_Deduction" className="accordian-item m-b-10 code-acc">
                                    <AccordionItemHeader title={'Security Deposit Deduction'} />
                                    <AccordionItemBody>
                                        <SecurityDepositForm
                                            saveFormData={saveCntrctFormsData}
                                            security_deductions={props.supplierData.cntrDeductionRates}
                                            Security_Deposit_Deduction={moreDetailsObj?.Security_Deposit_Deduction}
                                            formType={props.formType}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem itemId="Other_Deductions" className="accordian-item m-b-10 code-acc">
                                    <AccordionItemHeader title={'Other Deductions'} />
                                    <AccordionItemBody>
                                        <OtherDeductionForm
                                            saveFormData={saveCntrctFormsData}
                                            Other_Deductions={moreDetailsObj?.Other_Deductions}
                                            selectedRow={state.selRow}
                                            growl={growl}
                                            deductions={props.adminData.deductions}
                                            formType={props.formType}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                                <AccordionItem itemId="Payment_Schedule" className="accordian-item m-b-10 code-acc">
                                    <AccordionItemHeader title={'Payment Schedule'} />
                                    <AccordionItemBody>
                                        <PaymentScheduleForm
                                            saveFormData={saveCntrctFormsData}
                                            moreDetailsObj={moreDetailsObj}
                                            selectedRow={state.selRow}
                                            finalData={props.formStateObj}
                                            admisVar={props.admisVar}
                                            formType={props.formType}
											finYear={props.finYear}
                                        />
                                    </AccordionItemBody>
                                </AccordionItem>
                            </Accordions>
                            {props.formType !== 'view' && (
                                <div className="bottom-right-div m-b-10 m-t-10 ">
                                    {props.formType !== 'view' && (
                                        <Button size="sm" kind="primary" onClick={() => saveAdditionalData(moreDetailsObj)}>
                                            Save
                                        </Button>
                                    )}
                                    {props.formType !== 'view' && formState.contracType !== 'totalCntrct' && (
                                        <Button
                                            size="sm"
                                            kind="secondary"
                                            className="m-l-10"
                                            onClick={() => setState({ ...state, selRow: {}, sAction: '' })}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {state.showFinModal && (
                <FinancialSanctionModal
                    showModal={state.showFinModal}
                    handleCancel={() => handleCancel('showFinModal')}
                    growl={growl}
                    handleSave={saveFinSancData}
                    propsObj={genFormDetails}
                    currentObj={currentDetailsObj.cntrct_fin_sanc}
                    formType={props.formType}
                    formStateObj={props.formStateObj}
                    raisedBillAmount={props.raisedBillAmount}
                    checkIsApproved={props.checkIsApproved}
                    finYear={props.finYear}
					islatest={props.islatest}
					versionNo={props.versionNo}
                />
            )}
            {state.showDeleteModal && (
                <DeleteConfirmModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Contract Details"
                    showModal={state.showDeleteModal}
                    name={'Supply Category : ' + currentDetailsObj.sup_cat.name}
                />
            )}
            {state.showCntrctModal && (
                <ContractTypeModal
                    handleYes={handleYes}
                    handleCancel={() => handleCancel('showCntrctModal')}
                    showModal={state.showCntrctModal}
                    bodyText="Are you sure you want to change Contract Value ? "
                    headerText="Contract Value"
                />
            )}
        </div>
    );
};

export default ContractDetailsForm;
