import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import './TreeCheckBox.scss';

const TreeCheckBox = (props) => {
    const [selectedKey, setSelectedKey] = useState(null);

    useEffect(() => {
        document.querySelectorAll('.treeCheckBox .p-checkbox-box').forEach((item) => {
            if (!props.chkDisabled && item.classList.contains('disabled')) {
                item.classList.remove('disabled');
            } else if (!item.classList.contains('disabled') && props.chkDisabled) {
                item.classList.add('disabled');
            }
        });
        setSelectedKey(props.selectedRiskItems);
    }, [props]);

    const onSelectedKey = (e) => {
        if (!props.chkDisabled) {
            setSelectedKey(e.value);
            props.handleSelectedKey(e.value);
        }
    };

    return (
        <div className="m-t-10">
            <div className={props.title ? 'componentWrapper' : ''}>
                {props.title && <div className="header">{props.title}</div>}
                <div className={`treeCheckBox ${props.className}`}>
                    <Tree
                        value={props.auditHierarchyList}
                        selectionMode="checkbox"
                        selectionKeys={selectedKey}
                        onSelectionChange={onSelectedKey}
                    />
                </div>
            </div>
        </div>
    );
};

export default TreeCheckBox;
