import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import FDataTable from '../../../../components/fDataTable/FDataTable';
import TableSchema from '../../../expenditureManagement/TableSchema';
import { useQuery } from '@apollo/client';
import Schema from '../../../../schema/AppSchema';
import { expenditureClient } from '../../../../apollo';
import { getDate } from '../../../../util';

const PreviousBills = (props) => {
    let { loading } = useQuery(Schema.personnelBills, {
        fetchPolicy: 'no-cache',
        variables: {
            where: {
                bill_type: { id: { equals: props.formState?.BILLS?.billType.id } },
                emp_id: { equals: props.formState?.BILLS?.employee?.id },
                is_latest: { equals: true },
                id: { not: { equals: props.recordID } }
            }
        },
        client: expenditureClient,
        onCompleted: (res) => {
            if (res.personnelBills) {
                res.personnelBills.forEach((a) => (a.version_date = getDate(a.version_date)));
                setRows(res.personnelBills);
            }
        },
        onError: () => {}
    });

    const onClickEdit = (rowData) => {
        props.history.replace('personnelbills/view/' + rowData['id']);
        localStorage.setItem('transaction_id', rowData.txnId);
    };

    const [rows, setRows] = useState([]);

    return (
        <div className="">
            {loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <>
                <div className="page-header">
                    {<span className="light-color">Previous Bills</span>}

                    <span className="backBtn pointer">
                        <span
                            onClick={() => {
                                props.handleBack();
                            }}
                        >
                            <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                        </span>
                    </span>
                </div>
				<div className="coa-card-content">
                <FDataTable
                    rows={rows}
                    columns={TableSchema.Previous_BILLS}
                    onClickDetails={(rowData) => onClickEdit(rowData)}
                    className="secondary-table"
                    emptyMessage="No records found "
                    noPaginator={true}
                />
				</div>
            </>
        </div>
    );
};

export default PreviousBills;
