/* eslint-disable max-nested-callbacks */
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useLazyQuery, useQuery } from '@apollo/client';
import { iaClient } from '../../../../apollo';
import Schema from './GMQueries';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';

const CommonHierarchyFields = (props) => {
    const [state, setState] = useState({
        loading: true,
        auditDataConfig: [],
        risks: [],
        controls: [],
        hierarchyList: []
    });

    useEffect(() => {
        state.auditDataConfig &&
            state.auditDataConfig.forEach((a) => {
                a.value = '';
            });
        setState({ auditDataConfig: state.auditDataConfig });
        props.risks && props.handleFilterClearedDone();
    }, [props.clearFilter]);
    useEffect(() => {
        const risk = props.risk ? props.risk : null;
        const control = props.control ? props.control : null;
        setState({
            ...state,
            auditDataConfig: props.auditDataConfig ? props.auditDataConfig : state.auditDataConfig,
            loading: false,
            risk: risk,
            control: control
        });
    }, [props]);

    useQuery(Schema.hierarchyCodesWrtPlan, {
        variables: { type: props.audit ? 'AUDIT_PROGRAM_LEVEL' : 'RISK_CONTROL_LEVEL' },
        client: iaClient,
        fetchPolicy: 'no-cache',
        skip: props.auditDataConfig,
        onCompleted: (data) => {
            if (data?.hierarchyCodesWrtPlan) {
                let arr = [];
                let hierarchyArr = [];
                let index = 0;
                for (const [key, value] of Object.entries(data.hierarchyCodesWrtPlan)) {
                    let obj = {
                        name: key,
                        auditSegmentList: value,
                        value: props.risks ? [] : '',
                        isDisabled: props.risks ? false : index > 0 ? true : false
                    };
                    arr.push(obj);
                    hierarchyArr.push(key);
                    index++;
                }
                setState({ ...state, auditDataConfig: arr, hierarchyList: hierarchyArr, loading: false });
                props.setEditData && props.onSetLevels(hierarchyArr);
                props.riskDef && props.onSelectedData(null, null, { auditDataConfig: arr });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getAuditHierarchyCodes] = useLazyQuery(Schema.auditHierarchyCodes, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.auditHierarchyCodes?.length > 0 && state.auditDataConfig?.length > 0) {
                state.auditDataConfig.map((list) => {
                    list.auditSegmentList.map((obj) => {
                        data.auditHierarchyCodes.map((ele) => {
                            if (obj.audit_level_id === ele.audit_level_id) {
                                list.auditSegmentList = data.auditHierarchyCodes;
                                list.isDisabled = false;
                            }
                        });
                    });
                });
                setState({ ...state, auditDataConfig: state.auditDataConfig, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleChange = (e, field) => {
        if (field === 'risk' || field === 'control') {
            setState({ ...state, [field]: e });
            props.onSelectedData(e, field, state);
        } else {
            let inx = state.auditDataConfig.findIndex((a) => a === field);
            state.auditDataConfig.forEach((a, index) => {
                if (a === field) a.value = e;
                if (index > inx) {
                    a.value = props.risks ? [] : '';
                    a.isDisabled = true;
                }
            });
            if (props.risks && e?.length > 0) {
                let arr = e.map((i) => i.id);
                getAuditHierarchyCodes({
                    variables: { where1: { parent_id: { in: arr } } },
                    transations: { none: { is_active: { equals: false } } }
                });
            } else if (props.audit) {
                getAuditHierarchyCodes({
                    variables: { where1: { parent_id: { equals: e.id } } },
                    transations: { none: { is_active: { equals: false } } }
                });
            }
            setState({ ...state, auditDataConfig: state.auditDataConfig });
            props.onSelectedData(e, field, state, state.hierarchyList);
        }
    };

    const setEditDraftAuditValues = (auditName, type) => {
        let obj = {};
        let retValues = props.passAuditCommonValuesReturn;
        for (let i = 0; i < retValues.length; i++) {
            if (retValues[i].audit_level_id === auditName) {
                obj = type === 'EDIT' ? filterAuditLevels(retValues[i].name, auditName) : retValues[i].name;
                return obj;
            }
        }
    };

    const filterAuditLevels = (segmentName, auditName) => {
        let segmentObj = {};
        state.auditDataConfig.map((segment) => {
            if (segment.name === auditName) {
                return segment.auditSegmentList.filter((seg) => {
                    if (seg.name === segmentName) {
                        segmentObj = seg;
                    }
                });
            }
        });
        return segmentObj;
    };
    return (
        <React.Fragment>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            {state.auditDataConfig?.length > 0 &&
                state.auditDataConfig.map((segment, ind) => (
                    <div className="col-md-6 m-b-10 display-flex" key={ind}>
                        <b className="col-md-6 p-l-0 f-14">
                            {props.appendText ? props.appendText : ''} {segment.name}
                            {!props.isMandatory ? (
                                props.formType !== 'view' && !props.isApproved ? (
                                    <span className="primary-color"> *</span>
                                ) : (
                                    ' :'
                                )
                            ) : (
                                ' :'
                            )}
                        </b>
                        <div className="col-md-6 p-r-0">
                            {props.formType !== 'view' && !props.isApproved ? (
                                props.audit ? (
                                    <Dropdown
                                        name={segment.name}
                                        value={
                                            segment['value'] ||
                                            (props.passAuditCommonValuesReturn && props.passAuditCommonValuesReturn.length > 0
                                                ? setEditDraftAuditValues(segment.name, 'EDIT')
                                                : '')
                                        }
                                        disabled={
                                            props?.fieldDisable ||
                                            (props.passAuditCommonValuesReturn && props.passAuditCommonValuesReturn.length > 0
                                                ? false
                                                : segment.isDisabled)
                                        }
                                        onChange={(e) => handleChange(e.value, segment)}
                                        options={segment?.auditSegmentList}
                                        placeholder={`Select ${segment.name}`}
                                        className="w-100"
                                        validations={['required']}
                                        optionLabel="name"
                                        filter={true}
                                        filterBy="name"
                                        filterPlaceholder="Search"
                                        resetFilterOnHide={true}
                                    />
                                ) : (
                                    <MultiSelect
                                        resetFilterOnHide={true}
                                        name="office"
                                        appendTo={document.self}
                                        disabled={props?.fieldDisable || segment.isDisabled}
                                        onChange={(e) => handleChange(e.value, segment)}
                                        value={segment['value']}
                                        options={segment?.auditSegmentList}
                                        placeholder={`Select ${segment.name}`}
                                        className="w-100"
                                        optionLabel="name"
                                        filter={true}
                                        filterBy="name"
                                        filterPlaceholder="Search"
                                    ></MultiSelect>
                                )
                            ) : (
                                <span className="fs-14">
                                    {props.passAuditCommonValuesReturn
                                        ? setEditDraftAuditValues(segment.name, 'VIEW')
                                        : props.audit
                                        ? segment.value
                                        : segment.value
                                              ?.map((v) => v.name)
                                              ?.toString()
                                              ?.split(',')
                                              ?.join(', ')}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            {props.risks && (
                <div className="col-md-6 display-flex m-b-10">
                    <b className="col-md-6 p-l-0">
                        {props.riskDef ? 'Risk' : 'Control'}
                        {!props.isMandatory ? props.formType !== 'view' ? <span className="primary-color"> *</span> : ' :' : ' :'}
                    </b>
                    <div className="col-md-6 p-r-0">
                        {props.formType !== 'view' ? (
                            <MultiSelect
                                resetFilterOnHide={true}
                                name="office"
                                appendTo={document.self}
                                onChange={(e) => handleChange(e.value, props.riskDef ? 'risk' : 'control')}
                                value={props.riskDef ? state.risk : state.control}
                                options={props.riskDef ? props.riskDefList : props.controlDefList}
                                placeholder={`Select ${props.riskDef ? 'Risk' : 'Control'}`}
                                className="w-100"
                                filter={true}
                                filterBy="label"
                                filterPlaceholder="Search"
                            ></MultiSelect>
                        ) : (
                            <span className="fs-14">
                                {props.riskDef
                                    ? state.risk
                                          ?.map((v) => v.name)
                                          ?.toString()
                                          ?.split(',')
                                          ?.join(', ')
                                    : state.control
                                          ?.map((v) => v.name)
                                          ?.toString()
                                          ?.split(',')
                                          ?.join(', ')}
                            </span>
                        )}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default CommonHierarchyFields;
