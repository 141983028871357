/* eslint-disable max-depth */
import React, { useState, useEffect } from 'react';
import InputComponent from '../InputComponent/InputComponent';
// import './SupplierRegistration.scss';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'appkit-react';
import TableSchema from '../../pages/employeeManagement/TableSchema';
import DeleteConfirmationModal from '../DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import FormSchema from '../../pages/contractManagement/supplierManagement/supplierRegistration/formSchema';
import * as FormValidation from '../../Validation';
import SecondaryDataTable from '../fDataTable/SecondaryTable';
import ConfirmationModal from '../actionsComponent/confirmationModal';
import { uniqueId } from '../../util';
import AddressModal from './AddressModal';

const Address = (props) => {
    let viewMode = TableSchema.EMP_ADDRESS_DETAILS.filter((a) => a.fieldType !== 'actions');
    const ADDRESS_DETAILS_SCHEMA = props.formType !== 'view' ? TableSchema.EMP_ADDRESS_DETAILS : viewMode;
    const addressObjEmpty = {
        line1: '',
        pin_code: '',
        state: '',
        district: '',
        state_name: '',
        district_name: '',
        country: '',
        country_name: props.country,
        is_primary: false,
        is_active: true
    };
    const [growl, setGrowl] = useState();
    const [loading] = useState(false);
    const [addressList, setAddressList] = useState(props?.addressData);
    let states = props.countries?.length > 0 ? props.countries.find((a) => a?.name?.toUpperCase() === props.country?.toUpperCase()) : [];
    const [stateList, setStateList] = useState(states?.states);
    const [locationList, setLocationList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [action, setAction] = useState('');
    const [countries, setCountries] = useState(props?.countries);
    const [errors, setErrors] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [currentAddressObj, setCurrentAddressObj] = useState(addressObjEmpty);
    const fields = [
        { key: 'line1', validators: FormSchema.FLD_ADDR_ADDR_LINE1.is_mandatory ? 'alphaNumeric' : '' },
        { key: 'line2', validators: 'alphaNumeric' },
        { key: 'line3', validators: 'alphaNumeric' },
        { key: 'country', validators: 'required' },
        { key: 'state', validators: 'required' },
        { key: 'pin_code', validators: 'zipCode' },
        { key: 'district', validators: 'alphaNumeric' },
        { key: 'is_primary', validators: '' },
        { key: 'is_active', validators: '' }
    ];
    const [state, setState] = useState({
        is_active: false,
        showConfirmationModal: false,
        selectedRow: {},
        column: {},
        showAddressModal: false,
        addressType: ''
    });
    if (addressList?.length !== 0) {
        addressList &&
            addressList.forEach((acc) => {
                acc.deleteDisable = acc.is_primary;
            });
    }
    useEffect(() => {
        setCountries(props?.countries);
        // eslint-disable-next-line
    }, [props?.countries]);
    useEffect(() => {
        setAddressList(props?.addressData);
        // eslint-disable-next-line
    }, [props?.addressData]);

    const setBodyContent = () => {
        let bodyMessage = '';
        switch (state.column.field) {
            case 'is_primary':
                bodyMessage = state.selectedRow[state.column.field]
                    ? 'make this address as non primary for this Employee '
                    : 'make this address as primary for this Employee ';
                break;
            case 'is_active':
                bodyMessage = state.selectedRow[state.column.field]
                    ? 'Deactivate this Address for this Employee '
                    : 'Activate this Address for this Employee ';
                break;
            default:
                break;
        }

        return bodyMessage;
    };
    const validateFeild = async (ev, key) => {
        let err = {};
        const field = fields.filter((obj) => {
            return key === obj['key'];
        });
        if (
            (key !== 'pin_code' && key !== 'district') ||
            (key === 'pin_code' && window?._VALIDATIONS_?.SUPPLIER_PIN_CODE) ||
            (key === 'district' && window?._VALIDATIONS_?.SUPPLIER_DISTRICT_CITY_CODE)
        ) {
            err = FormValidation.validateFileld(field[0].validators, key, ev, field[0].maxLength, field[0].minLength);
        }
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };

    const handleChange = (ev, key) => {
        if (key === 'country') {
            currentAddressObj['country'] = ev;
            countries.forEach((country) => {
                if (country.name === ev) {
                    currentAddressObj['country_name'] = country.name;
                    setStateList(country.states);
                }
            });
            currentAddressObj['state'] = '';
            currentAddressObj['state_name'] = '';
            currentAddressObj['district'] = '';
            currentAddressObj['district_name'] = '';
        } else if (key === 'is_primary' && !ev) {
            let existValue = addressList.filter((item) => {
                return item[key] === true && item.id !== currentAddressObj.id;
            });
            if (existValue.length === 0) {
                growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.AtleastPrimaryAddress
                });
            } else {
                currentAddressObj[key] = ev;
            }
        }
        // else if (key === 'state') {
        //     if (currentAddressObj['country'] !== 'India') {
        //         currentAddressObj['state_name'] = ev;
        //         validateFeild(ev, key);
        //     } else {
        //         currentAddressObj['state_name'] = stateList.find((a) => a.id === ev)?.name;
        //     }
        //     currentAddressObj['state'] = ev;
        // }
        else {
            currentAddressObj[key] = ev;
            validateFeild(ev, key);
        }
        setCurrentAddressObj({ ...currentAddressObj });
    };

    const performActions = () => {
        if (action !== 'delete' && addressList.some((a) => a.is_primary && a.id !== currentAddressObj.id) && currentAddressObj.is_primary) {
            growl.show({ life: 6000, severity: 'error', summary: window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.PrimaryAddress });
            return;
        }
        if (action === 'edit' || action === 'delete') {
            for (let index = 0; index < addressList.length; index++) {
                const account = addressList[index];
                if (account.id === currentAddressObj.id) {
                    if (action === 'edit') {
                        addressList[index] = currentAddressObj;
                    } else {
                        addressList.splice(index, 1);
                    }
                    setAction('');
                }
            }
        } else {
            currentAddressObj['id'] = uniqueId(25);
            addressList.push(currentAddressObj);
        }
        setCurrentAddressObj(addressObjEmpty);
        setShowForm(false);
        props.updateData('address_list', addressList);
    };

    const handleDelete = (flag) => {
        if (flag) {
            performActions();
        }
        setShowPopup(false);
    };
    const handleCancel = () => {
        setState({
            ...state,
            showConfirmationModal: false,
            selectedRate: {},
            action: ''
        });
    };
    const handleActions = (rowData, column, sAction) => {
        setAction(sAction);
        if (sAction === 'switch') {
            if (column.field === 'is_active') {
                setState({
                    ...state,
                    showConfirmationModal: true,
                    selectedRow: rowData,
                    column: column
                });
            }
            if (column.field === 'is_primary') {
                setState({
                    ...state,
                    showConfirmationModal: true,
                    selectedRow: rowData,
                    column: column
                });
            }
        }
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentAddressObj(newObj);
            countries.forEach((country) => {
                if (country.name === rowData.country) {
                    currentAddressObj['country_name'] = country.name;
                    let statesLi = country.states || [];
                    setStateList(statesLi);
                    if (statesLi.length > 0) {
                        let stateSel = statesLi.find((a) => a.id === rowData.id);
                        setLocationList(stateSel?.locations || []);
                    }
                }
            });
            setShowForm(true);
        }
        if (sAction === 'delete') {
            setCurrentAddressObj(rowData);
            setShowPopup(true);
        }
    };

    const disableSave = () => {
        if (
            currentAddressObj.line1 === '' ||
            currentAddressObj.pin_code.toString().length !== 6 ||
            currentAddressObj.country === '' ||
            currentAddressObj.state_name === '' ||
            currentAddressObj.district_name === ''
        ) {
            return true;
        }
        return false;
    };
    const checkPrimary = () => {
        if (addressList.length === 0 || (addressList.length > 0 && !addressList.some((a) => a.is_primary))) {
            currentAddressObj.is_primary = true;
        }
    };

    const disableActions = (rowData, column) =>
        (column.field === 'is_active' && rowData.is_primary === true) ||
        (column.field === 'is_primary' && rowData.is_active === false) ||
        action === 'edit';

    const handleContinue = () => {
        let rowIndex = addressList.findIndex((item) => {
            return state.selectedRow.id === item.id;
        });
        if (state.column.field === 'is_active') {
            addressList[rowIndex].is_active = !state.selectedRow.is_active;
            growl.show({
                life: 6000,
                severity: 'success',
                summary: state.selectedRow[state.column.field]
                    ? window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.ActivateAddress
                    : window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.DeactivateAddress
            });
        }
        if (state.column.field === 'is_primary') {
            if (state.column?.validation === 'unique') {
                if (!state.selectedRow.is_primary === false) {
                    let trueIndex = addressList.findIndex((item) => {
                        return item[state.column.field] === true && item.id !== state.selectedRow.id;
                    });
                    if (trueIndex > -1) {
                        addressList[rowIndex].deleteDisable = !state.selectedRow.is_primary;
                        addressList[rowIndex].is_primary = !state.selectedRow.is_primary;
                        growl.show({
                            life: 6000,
                            severity: 'success',
                            summary: state.selectedRow[state.column.field]
                                ? window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.UpdatePrimaryAddress
                                : window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.UpdateNonPrimaryAddress
                        });
                    } else {
                        growl.show({
                            life: 6000,
                            severity: 'error',
                            summary: window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.AtleastPrimaryAddress
                        });
                    }
                } else {
                    addressList.forEach((item) => {
                        if (item.id !== state.selectedRow.id) {
                            item[state.column.field] = false;
                            item.deleteDisable = false;
                        }
                    });
                    addressList[rowIndex].deleteDisable = !state.selectedRow.is_primary;
                    addressList[rowIndex].is_primary = !state.selectedRow.is_primary;
                    growl.show({
                        life: 6000,
                        severity: 'success',
                        summary: state.selectedRow[state.column.field]
                            ? window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.UpdatePrimaryAddress
                            : window.CONTRACT_MANAGEMENT_SUPPLIER_REGISTRATION.UpdateNonPrimaryAddress
                    });
                }
            }
        }
        setState({ ...state, showConfirmationModal: false });
    };

    const handleSave = (addData) => {
        currentAddressObj.state = addData.region;
        currentAddressObj.district = addData.location;
        currentAddressObj.state_name = addData.regionName;
        currentAddressObj.district_name = addData.locationName;
        setCurrentAddressObj(currentAddressObj);
        setState({ ...state, showAddressModal: false, addressType: '' });
    };

    const addAddress = () => {
        if (props?.redirectTo) {
            props.redirectUrl(true);
        } else {
            setShowForm(true);
            setErrors({});
            checkPrimary();
        }
    };

    return (
        <div className={loading ? 'coa-card p-10 mask' : 'coa-card overflow-initial p-10'}>
            {loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row m-0">
                <div className="col-md-12 p-0">
                    <SecondaryDataTable
                        rows={addressList}
                        columns={
                            addressList?.length === 0
                                ? ADDRESS_DETAILS_SCHEMA.filter((a) => a.fieldType !== 'actions')
                                : ADDRESS_DETAILS_SCHEMA
                        }
                        emptyMessage="No addresses added yet"
                        className="secondary-table inner-table-overflow"
                        noPaginator={true}
                        formType={props.formType}
                        handleActionsInParent={handleActions}
                        scrollable
                        disableActions={disableActions}
                    ></SecondaryDataTable>
                </div>
                {showForm && (
                    <div className="col-md-12 p-0">
                        <div className="row m-0">
                            <div className="col-md-6 p-0">
                                {FormSchema.FLD_ADDR_ADDR_LINE1.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_ADDR_LINE1.name}
                                                {FormSchema.FLD_ADDR_ADDR_LINE1.is_mandatory !== -1 ? (
                                                    <span className="icon-primary p-l-5">*</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-md-7">
                                            <InputComponent
                                                inputBoxSize="sm"
                                                name="line1"
                                                type="text"
                                                value={currentAddressObj.line1}
                                                hasError={errors && errors.line1}
                                                errMsg="Please enter valid Address Line 1"
                                                onChange={(e) => handleChange(e, 'line1')}
                                                placeholder="Enter Address Line 1"
                                                validations={['required']}
                                                maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                {FormSchema.FLD_ADDR_ADDR_LINE2.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_ADDR_LINE2.name}:{FormSchema.FLD_ADDR_ADDR_LINE2.is_mandatory !== -1}
                                            </b>
                                        </div>
                                        <div className="col-md-7 p-0">
                                            <InputComponent
                                                inputBoxSize="sm"
                                                name="line2"
                                                type="text"
                                                value={currentAddressObj.line2}
                                                hasError={errors && errors.line2}
                                                errMsg="Please enter valid Address Line 2"
                                                onChange={(e) => handleChange(e, 'line2')}
                                                placeholder="Enter Address Line 2"
                                                validations={['required']}
                                                maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 p-0">
                                {FormSchema.FLD_ADDR_ADDR_LINE3.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_ADDR_LINE3.name}:{FormSchema.FLD_ADDR_ADDR_LINE3.is_mandatory !== -1}
                                            </b>
                                        </div>
                                        <div className="col-md-7">
                                            <InputComponent
                                                inputBoxSize="sm"
                                                name="line3"
                                                type="text"
                                                value={currentAddressObj.line3}
                                                hasError={errors && errors.line3}
                                                errMsg="Please enter valid Address Line 3"
                                                onChange={(e) => handleChange(e, 'line3')}
                                                placeholder="Enter Address Line 3"
                                                validations={['required']}
                                                maxLength={window._VALIDATIONS_?.IA_DEFAULT_MAXLENGTH_LIMIT}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                {FormSchema.FLD_ADDR_PIN.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_PIN.name}
                                                {FormSchema.FLD_ADDR_PIN.is_mandatory !== -1 ? (
                                                    <span className="icon-primary p-l-5">*</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-md-7 p-0">
                                            <InputComponent
                                                inputBoxSize="sm"
                                                name="pin_code"
                                                type="text"
                                                value={currentAddressObj.pin_code}
                                                hasError={errors && errors.pin_code}
                                                errMsg="Please enter valid Pin Code E.g. : 500044"
                                                onChange={(e) => handleChange(e, 'pin_code')}
                                                placeholder="Enter Pin Code"
                                                maxLength={6}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 p-0">
                                {FormSchema.FLD_ADDR_CNTRY.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                Country
                                                {FormSchema.FLD_ADDR_CNTRY.is_mandatory !== -1 ? (
                                                    <span className="icon-primary p-l-5">*</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-md-7">
                                            <Dropdown
                                                name="country"
                                                appendTo={document.self}
                                                value={currentAddressObj.country}
                                                filter={true}
                                                onChange={(e) => handleChange(e.value, 'country')}
                                                options={countries}
                                                placeholder="Select Country"
                                                filterMatchMode="startsWith"
                                                filterPlaceholder="Search"
                                                className="w-100"
                                                optionLabel="name"
                                                optionValue="name"
                                                resetFilterOnHide={true}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <div className="row p-t-10">
                                    <div className="col-md-5">
                                        <b className="f-14 p-t-5">
                                            Region / State
                                            {FormSchema.FLD_ADDR_STATE.is_mandatory !== -1 ? (
                                                <span className="icon-primary p-l-5">*</span>
                                            ) : (
                                                ' :'
                                            )}
                                        </b>
                                    </div>
                                    <div className="col-md-7 p-0">
                                        <p
                                            className={`${currentAddressObj.country ? '' : 'disabled'} primary-color pointer fs-14`}
                                            onClick={() => setState({ ...state, showAddressModal: true, addressType: 'state' })}
                                        >
                                            {currentAddressObj?.state ? currentAddressObj?.state_name : 'Add Region / State'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="row p-t-10">
                                    <div className="col-md-5">
                                        <b className="f-14 p-t-5">
                                            Location / City
                                            {FormSchema.FLD_ADDR_DIST_CTY.is_mandatory !== -1 ? (
                                                <span className="icon-primary p-l-5">*</span>
                                            ) : (
                                                ' :'
                                            )}
                                        </b>
                                    </div>
                                    <div className="col-md-7">
                                        <p
                                            className={`${currentAddressObj.state ? '' : 'disabled'} primary-color pointer fs-14`}
                                            onClick={() => setState({ ...state, showAddressModal: true, addressType: 'district' })}
                                        >
                                            {currentAddressObj?.district ? currentAddressObj?.district_name : 'Add Location / City'}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {FormSchema.FLD_ADDR_IS_PRMRY.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_IS_PRMRY.name}
                                                {FormSchema.FLD_ADDR_IS_PRMRY.is_mandatory !== -1 ? (
                                                    <span className="icon-primary p-l-5">*</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-md-7">
                                            <RadioButton
                                                inputId="rb1"
                                                value={true}
                                                name="is_primary"
                                                disabled={!currentAddressObj.is_active}
                                                onChange={(e) => handleChange(e.value, 'is_primary')}
                                                checked={currentAddressObj.is_primary === true}
                                            />
                                            <label htmlFor="rb1" className="p-radiobutton-label p-r-20 ">
                                                Yes
                                            </label>
                                            <RadioButton
                                                inputId="rb2"
                                                value={false}
                                                name="is_primary"
                                                onChange={(e) => handleChange(e.value, 'is_primary')}
                                                checked={currentAddressObj.is_primary === false}
                                            />
                                            <label htmlFor="rb2" className="p-radiobutton-label">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6 p-0">
                                {FormSchema.FLD_ADDR_IS_ACTV.is_visible !== -1 && (
                                    <div className="row p-t-10">
                                        <div className="col-md-5">
                                            <b className="f-14 p-t-5">
                                                {FormSchema.FLD_ADDR_IS_ACTV.name}
                                                {FormSchema.FLD_ADDR_IS_ACTV.is_mandatory !== -1 ? (
                                                    <span className="icon-primary p-l-5">*</span>
                                                ) : (
                                                    ' :'
                                                )}
                                            </b>
                                        </div>
                                        <div className="col-md-7 p-0">
                                            <RadioButton
                                                inputId="rb1"
                                                value={true}
                                                name="is_active"
                                                onChange={(e) => handleChange(e.value, 'is_active')}
                                                checked={currentAddressObj.is_active === true}
                                            />
                                            <label htmlFor="rb1" className="p-radiobutton-label p-r-20">
                                                Yes
                                            </label>
                                            <RadioButton
                                                inputId="rb2"
                                                value={false}
                                                disabled={currentAddressObj.is_primary}
                                                name="is_active"
                                                onChange={(e) => handleChange(e.value, 'is_active')}
                                                checked={currentAddressObj.is_active === false}
                                            />
                                            <label htmlFor="rb2" className="p-radiobutton-label">
                                                No
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" kind="primary" onClick={performActions} disabled={disableSave()} className="m-t-10">
                                {action === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setShowForm(false);
                                    setAction('');
                                    setCurrentAddressObj(addressObjEmpty);
                                }}
                                className="m-l-10 m-t-10"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!showForm && props.formType !== 'view' && (
                <div
                    className="comment-header"
                    onClick={() => {
                        addAddress();
                    }}
                >
                    <span className="comment-color pointer" disabled={loading}>
                        +&nbsp;Add Address
                    </span>
                </div>
            )}
            {showPopup && (
                <DeleteConfirmationModal
                    handleDelete={handleDelete}
                    handleCancel={() => setShowPopup(false)}
                    type="Address"
                    showModal={showPopup}
                    name="Address"
                />
            )}
            {state.showConfirmationModal && (
                <ConfirmationModal
                    row={state.selectedRow}
                    showModal={state.showConfirmationModal}
                    handleCancel={handleCancel}
                    title={'Address Details'}
                    name={'Address Details'}
                    bodyContent={setBodyContent()}
                    noComments={'noComments'}
                    handleContinue={handleContinue}
                    handleCancelModal={() => setState({ ...state, showConfirmationModal: false })}
                />
            )}
            {state.showAddressModal && (
                <AddressModal
                    showModal={state.showAddressModal}
                    addressType={state.addressType}
                    handleCancel={() => setState({ ...state, showAddressModal: false })}
                    stateList={stateList || []}
                    locationList={locationList || []}
                    handleSave={handleSave}
                    currentAddressObj={currentAddressObj}
                />
            )}
            <Toast ref={(el) => setGrowl(el)} />
        </div>
    );
};

export default Address;
