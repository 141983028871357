import React, { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Schema from '../../../../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { iaClient, eventClient } from '../../../../../../apollo';
import { Sidebar } from 'primereact/sidebar';
import WorklowSidebarContent from '../../../../../../components/WorklowSidebarContent/WorklowSidebarContent';
import { getFormTypeAndRecordId, InfoMessage } from '../../../../../../services/commonService';
import { Dropdown } from 'primereact/dropdown';
import TreeCheckBox from '../../../../../../components/TreeCheckBox/TreeCheckBox';
import GMSchema from '../../GMQueries';
import ActionsComponents from '../../../../../../components/actionsComponent/actionComponents';
import { MenuItemsSchema } from '../../../../../../commons/MenuItemsSchema';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import { MultiSelect } from 'primereact/multiselect';
import IsAuthorized from '../../../../../../commons/AuthorizationService';

const CreateAAHierarchy = (props) => {
    const [growl, setGrowl] = useState();
    const formTypeAndId = getFormTypeAndRecordId(props.location.pathname);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let isApproved = formTypeAndId['isApproved'];
    let isExistApproved = formTypeAndId['isExistApproved'];
    let isExistDraft = formTypeAndId['isExistDraft'];
    let isDraft = isExistDraft ? isExistDraft : formTypeAndId['isDraft'];
    let transactionId = localStorage.getItem('transaction_id');
    const [mode, setMode] = useState(formType);
    const [approved, setApproved] = useState(false);
    const [versionNo, setVersionNo] = useState();
    const [versionUser, setVersionUser] = useState();
    const [resObj, setResObj] = useState();
    const [recID, setID] = useState('');
    const [duplicate, setDuplicate] = useState(false);
    const [auditHierarchyList, setAuditHierarchyList] = useState([]);
    const [auditHierarchyLst] = useState([]);
    const [mdaProfiles, setMdaList] = useState([]);
    const [aaList, setAAList] = useState([]);
    const [applyToAAList, setApplyToAAList] = useState([]);
    const [currentAction, setCurrentAction] = useState();
    let Privileges = {};
    let prev = props.location.pathname.split('/');
    let path = prev[0] + '/' + prev[1] + '/' + prev[2];
    if (prev.length > 0 && path) {
        MenuItemsSchema['GLOBAL_MASTERS'].forEach((a) => {
            let val = '';
            if (a.subMenu)
                val = a.subMenu.find((b) => {
                    return b.routerLink === path;
                });
            else val = MenuItemsSchema['GLOBAL_MASTERS'].find((v) => v.routerLink === path);
            if (val) {
                Privileges = val;
            }
        });
    }
    const [state, setState] = useState({
        loading: false,
        showWorkflowStatus: false,
        showPreview: false,
        listToShowComment: [],
        auditHierarchyList: [],
        selectedRiskItems: {},
        mappingRecords: [],
        entityMappings: []
    });
    const [formState, setFormState] = useState({ applyToAuditArea: [] });

    useQuery(GMSchema.fetchAuditHierarchyForRiskSelection, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (res) => {
            if (res && res.hierarchyCodesInTreeStructure) {
                prepareTree(res.hierarchyCodesInTreeStructure.data);
            }
        }
    });

    const [fetchDevisions] = useLazyQuery(GMSchema.iauDevisions, {
        fetchPolicy: 'no-cache',
        variables: {
            where: { is_effective: { equals: true } }
        },
        client: iaClient,
        onCompleted: (response) => {
            if (response?.iauDevisions) {
                let records = response?.iauDevisions;
                records.forEach((v) => {
                    v.mda = v.code + ' - ' + v.alias;
                    v.mda_code = v.code;
                });
                setMdaList(records);
                if (mode !== 'create' && !isDraft) getAAMapping();
                else if (mode !== 'create' && isDraft) getEntityRecord();
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [fetchAuditableAreas] = useLazyQuery(GMSchema.mdaProfileDefs, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (response) => {
            if (response?.mdaProfileDefs) {
                let records = response?.mdaProfileDefs;
                records.forEach((v) => {
                    v.auditable_name = v.auditable_area_code + '-' + v.auditable_area;
                    v.iau_devision_id = state.fromApplyToAction ? formState.applyToMda : formState.mda;
                });
                if (state.fromApplyToAction) setApplyToAAList(records);
                else {
                    let recs = formState?.fetchFirst ? [...aaList, ...records] : records;
                    setAAList(recs);
                }
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const prepareTree = (data) => {
        const ahList = { root: [] };
        const mutatedData = mutateData(data, null);
        ahList['root'].push(mutatedData);
        setAuditHierarchyList(mutatedData);
        fetchDevisions();
    };

    const mutateData = (data, keys) => {
        for (let i = 0; i < data?.length; i++) {
            let nodeKey = '';
            if (!keys && keys !== 0) nodeKey = i.toString();
            else nodeKey = keys + '-' + i;
            data[i]['key'] = nodeKey;
            data[i]['label'] = data[i].name;
            data[i]['children'] = data[i].child_codes;
            delete data[i].child_codes;
            auditHierarchyLst.push({ key: nodeKey, audit_level_id: data[i].audit_level_id, classif_code: data[i].id });
            if (data[i].children && data[i].children.length !== 0) {
                const dataObject = mutateData(data[i].children, nodeKey);
                data[i].children = dataObject;
            }
        }
        return data;
    };

    const handleSelectedKey = (value) => {
        setState({ ...state, selectedRiskItems: value, selectedTreeValues: getSelectedIds(value) });
    };

    const getSelectedIds = (e) => {
        const selectedRiskIds = [];
        const selectedAuditHierarchyLst = auditHierarchyLst.filter((ahl) => Object.keys(e).indexOf(ahl.key) !== -1);
        Object.keys(e).map((sri) => {
            const sRiskItems = selectedAuditHierarchyLst.filter((sah) => sah.key === sri);
            const existSelectedRiskIds = selectedRiskIds.filter((ss) => ss.classif_level === sRiskItems[0].audit_level_id);
            if (!existSelectedRiskIds || existSelectedRiskIds.length === 0) {
                selectedRiskIds.push({ classif_level: sRiskItems[0].audit_level_id, classif_code: sRiskItems[0].classif_code });
            } else {
                selectedRiskIds.map((ssh) => {
                    if (ssh.classif_level === sRiskItems[0].audit_level_id) {
                        ssh.classif_code = ssh.classif_code + ',' + sRiskItems[0].classif_code;
                    }
                });
            }
        });
        return selectedRiskIds;
    };

    const getSelectedAuditClassifications = (data, selectedItems, list) => {
        for (let i = 0; i < data?.length; i++) {
            list &&
                list.map((sr) => {
                    if (data[i].audit_level_id === sr.classif_level && sr.classif_code.split(',').indexOf(data[i].id) !== -1) {
                        selectedItems[data[i]['key']] = { checked: true, partialChecked: false };
                    }
                });
            if (data[i].children && data[i].children.length !== 0) {
                getSelectedAuditClassifications(data[i].children, selectedItems, list);
            }
        }
        return selectedItems;
    };

    const prepareData = (rec) => {
        checkAAH({
            variables: {
                where: {
                    ref: { equals: rec.ref },
                    status: { not: { equals: 'Approved' } },
                    version_no: { gte: rec.app_version_no || rec.version_no },
                    version_date: rec.entity_id ? { gt: rec.version_date } : undefined
                }
            }
        });
        if (rec.id) setID(rec.id);
        setVersionUser(rec?.version_user);
        let mdaDevs = rec?.mda_profile?.iau_devision_detail?.filter((v) => v?.iau_devision?.is_effective)[0];
        let auditVal = rec?.mda_profile?.auditable_area_code
            ? rec?.mda_profile?.auditable_area_code + '-' + rec?.mda_profile?.auditable_area
            : '';
        setFormState({
            ...formState,
            mda: mdaDevs?.iau_devision?.id,
            mdaDivision: mdaDevs?.iau_devision?.code + ' - ' + mdaDevs?.iau_devision?.alias,
            auditArea: rec?.mda_profile_id,
            auditAreaName: auditVal,
            aaObj: rec?.mda_profile,
            ref: rec?.ref,
            fetchFirst: true
        });
        let aaRecs = [
            {
                auditable_area: rec?.mda_profile?.auditable_area,
                id: rec?.mda_profile_id,
                auditable_area_code: rec?.mda_profile?.auditable_area_code,
                auditable_name: auditVal
            }
        ];
        setAAList(aaRecs);
        fetchAuditableAreas({
            variables: {
                where: {
                    is_effective: { equals: true },
                    office_code: { equals: mdaDevs?.iau_devision?.code },
                    iau_devision_detail: { some: { iau_devision: { is_effective: { equals: true } } } },
                    hierarchy_mappings: { every: { status: { in: ['Cancelled', 'Rejected'] } } }
                }
            }
        });
        if (Object.keys(state.selectedRiskItems).length === 0 && auditHierarchyList?.length > 0) {
            const selectedItems = getSelectedAuditClassifications(auditHierarchyList, [], rec?.audit_hierarchy);
            setState({ ...state, selectedTreeValues: rec?.audit_hierarchy, selectedRiskItems: selectedItems });
        }
    };

    const [getAAMapping] = useLazyQuery(GMSchema.auditHierarchyMdaMapping, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        variables: { where: { id: recordID } },
        onCompleted: (response) => {
            if (response?.auditHierarchyMdaMapping) {
                let rec = response?.auditHierarchyMdaMapping;
                setVersionNo(rec?.version_no);
                prepareData(rec);
                setResObj(rec);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [getEntityRecord, { loading: entityLoader }] = useLazyQuery(Schema.entityRecord, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        skip: !isDraft || isApproved || mode === 'create',
        variables: { where: { id: recordID } },
        onCompleted: (data) => {
            if (data?.entityRecord?.record) {
                let rec = data.entityRecord.record;
                isApproved = rec?.is_approved;
                setApproved(rec?.is_approved ? true : false);
                setVersionNo(rec?.app_version_no ? rec?.app_version_no : rec?.version_no);
                prepareData(rec);
            }
        },
        onError: () => {}
    });

    const showWorkflow = (flag) => {
        setState({ ...state, showWorkflowStatus: flag });
    };

    const handleCommentClick = (selRow, flag) => {
        const lstToShowComment = state.listToShowComment;
        lstToShowComment[selRow.id] = flag;
        setState({ ...state, listToShowComment: lstToShowComment });
    };

    const setActionsStatus = (hasActions) => {
        setState({ ...state, hasActions });
    };

    const canDisable = () => {
        return formState.mda && formState.auditArea && Object.keys(state?.selectedRiskItems).length > 0 ? false : true;
    };

    const handleLoader = (status) => {
        setState({ ...state, loading: status });
    };

    const handleCancel = () => {
        setState({ ...state, loading: false });
        props.history.goBack();
    };

    const showGrowl = (msg, type) => {
        growl.show({ life: 6000, severity: type, summary: msg });
        setTimeout(() => {
            if (type !== 'error') handleCancel();
        }, 1000);
    };

    const navigateToEdit = () => {
        setMode('edit');
    };

    const handleChange = (ev, key) => {
        if (key === 'mda') {
            let rec = mdaProfiles.filter((v) => v.id === ev)[0];
            let mdaPr = { iau_devision_detail: [{ iau_devision: rec }] };
            setFormState({
                ...formState,
                [key]: ev,
                aaObj: mdaPr,
                auditArea: '',
                applyToAuditArea: [],
                applyToMda: '',
                fetchFirst: false
            });
            setState({ ...state, fromApplyToAction: false, entityMappings: [] });
            fetchAuditableAreas({
                variables: {
                    where: {
                        is_effective: { equals: true },
                        office_code: { equals: rec.code },
                        iau_devision_detail: { some: { iau_devision: { is_effective: { equals: true } } } },
                        hierarchy_mappings: { every: { status: { in: ['Cancelled', 'Rejected'] } } }
                    }
                }
            });
        } else if (key === 'auditArea') {
            let rec = aaList.filter((v) => v.id === ev)[0];
            let obj = formState.aaObj;
            obj['auditable_area'] = rec?.auditable_area;
            obj['auditable_area_code'] = rec?.auditable_area_code;
            setFormState({
                ...formState,
                [key]: ev,
                aaObj: obj,
                applyToAuditArea: [],
                applyToMda: ''
            });
            setState({ ...state, applyToObj: {}, selectedRiskItems: {}, entityMappings: [] });
        } else if (key === 'applyToMda') {
            setFormState({
                ...formState,
                [key]: ev,
                applyToAuditArea: [],
                fetchFirst: false
            });
            let rec = mdaProfiles.filter((v) => v.id === ev)[0];
            setState({ ...state, fromApplyToAction: true, entityMappings: [] });
            fetchAuditableAreas({
                variables: {
                    where: {
                        is_effective: { equals: true },
                        office_code: { equals: rec.code },
                        iau_devision_detail: { some: { iau_devision: { is_effective: { equals: true } } } },
                        hierarchy_mappings: { every: { status: { in: ['Cancelled', 'Rejected'] } } }
                    }
                }
            });
        } else {
            setFormState({
                ...formState,
                [key]: ev
            });
        }
    };

    const getBulkCreateData = (fromEntity) => {
        let data = fromEntity
            ? []
            : [
                  {
                      record: {
                          audit_hierarchy: state.selectedTreeValues,
                          mda_profile_id: formState.auditArea,
                          mda_profile: formState.aaObj,
                          is_approved: isApproved || approved,
                          app_version_no: versionNo ? versionNo : undefined,
                          ref: formState.ref
                      },
                      display_data: {
                          mda_profile: formState.aaObj,
                          is_approved: isApproved || approved,
                          app_version_no: versionNo ? versionNo : undefined,
                          ref: formState.ref
                      }
                  }
              ];
        state.entityMappings &&
            state.entityMappings.map((cd) => {
                const obj = {
                    audit_hierarchy: state.selectedTreeValues,
                    mda_profile_id: cd.mda_profile_id,
                    mda_profile: cd.mda_profile,
                    is_approved: isApproved || approved,
                    app_version_no: versionNo ? versionNo : undefined,
                    ref: formState.ref
                };
                data.push({
                    record: obj,
                    display_data: {
                        mda_profile: cd.mda_profile,
                        is_approved: isApproved || approved,
                        app_version_no: versionNo ? versionNo : undefined,
                        ref: formState.ref
                    }
                });
            });
        return data;
    };

    const getRecord = () => {
        const obj = {
            audit_hierarchy: state.selectedTreeValues,
            mda_profile_id: formState.auditArea,
            mda_profile: formState.aaObj,
            is_approved: isApproved || approved,
            app_version_no: versionNo ? versionNo : undefined,
            ref: formState.ref
        };
        if (versionNo) obj.version_no = versionNo;
        if (isDraft && recordID) obj.entity_id = recordID;
        if (isDraft && recID) {
            obj.id = recID;
        }
        return obj;
    };

    const getDisplayData = () => {
        return {
            mda_profile: formState.aaObj,
            is_approved: isApproved || approved,
            app_version_no: versionNo ? versionNo : undefined,
            ref: formState.ref
        };
    };

    const additinalButtonClick = (type) => {
        if (type === 'applytoclick') {
            setState({ ...state, showApplyModal: true });
            setFormState({
                ...formState,
                applyToMda: state.applyToObj?.applyToMda ? state.applyToObj?.applyToMda : '',
                applyToAuditArea: state.applyToObj?.applyToAuditArea ? state.applyToObj?.applyToAuditArea : []
            });
        }
    };
    const [createAAMapping] = useMutation(GMSchema.createOneAuditHierarchyMDAMapping, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = '';
                if (!isDraft && versionNo > 0) growlMsg = currentAction?.name + ' action taken successfully';
                else growlMsg = window.AUDIT_HEIRARCHY_DETAILS.CreateAAHierarchy;
                showGrowl(growlMsg, 'success');
            }
        },
        onError: () => {
            handleLoader(false);
        }
    });
    const [createAAManyMapping] = useMutation(GMSchema.createManyAuditHierarchyMDAMappings, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = '';
                if (!isDraft && versionNo > 0) growlMsg = currentAction?.name + ' action taken successfully';
                else growlMsg = window.AUDIT_HEIRARCHY_DETAILS.CreateAAHierarchy;
                showGrowl(growlMsg, 'success');
            }
        },
        onError: () => {
            handleLoader(false);
        }
    });

    const [updateAAMapping] = useMutation(GMSchema.updateOneAuditHierarchyMDAMapping, {
        variables: { where: { id: recID } },
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                let growlMsg = currentAction?.name
                    ? currentAction?.name + ' action taken successfully'
                    : window.AUDIT_HEIRARCHY_DETAILS.UpdateAAHierarchy;
                showGrowl(growlMsg, 'success');
                setVersionNo(parseInt(data.updateOneEmployee.version_no));
                setVersionUser(data.updateOneEmployee.version_user);
            }
        },
        onError: () => {
            handleLoader(false);
        }
    });

    const [checkAAH] = useLazyQuery(GMSchema.auditHierarchyMdaMappingCount, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (resp?.auditHierarchyMdaMappingCount > 0) setDuplicate(true);
        }
    });

    const [saveEntity] = useMutation(Schema.upsertEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            // eslint-disable-next-line no-empty
            if (data && data.upsertEntityRecord?.status === 'SUCCESS') {
            }
        },
        onError: () => {}
    });

    const handleSave = (wfParams, action) => {
        setCurrentAction(action);
        let dataObj = {};
        dataObj.wf_params = wfParams;
        delete dataObj.entity_id;
        delete dataObj.version_no;
        handleLoader(true);
        if ((!versionNo || versionNo === 0) && mode === 'create' && !isApproved && !approved) {
            delete dataObj.id;
            dataObj['mappings'] = [
                {
                    mda_profile: { connect: { id: formState.auditArea } },
                    audit_hierarchy: { create: state.selectedTreeValues }
                }
            ];

            createAAManyMapping({
                variables: {
                    data: dataObj
                }
            });
            if (state.entityMappings && state.entityMappings.length > 0) {
                let transactionID = localStorage.getItem('transaction_id');
                saveEntity({
                    variables: {
                        module: 'Masters',
                        fmis_function: 'IA_AUDITABLE_AREA_HIERARCHY',
                        fmis_sub_function: 'NA',
                        txn_id: transactionID,
                        entity_id: dataObj?.entity_id,
                        source_id: recID,
                        record: {},
                        display_data: {},
                        version_no: versionNo,
                        bulk_create_data: getBulkCreateData(true)
                    }
                });
            }
        } else if (isApproved || approved) {
            dataObj['ref'] = isApproved || approved ? formState.ref : undefined;
            dataObj['version_no'] = versionNo ? Number(versionNo) + 1 : undefined;
            dataObj['mda_profile'] = { connect: { id: formState.auditArea } };
            dataObj.id = isDraft ? recID : undefined;
            dataObj['audit_hierarchy'] = {
                create: state.selectedTreeValues?.map((val) => {
                    return { classif_level: val.classif_level, classif_code: val.classif_code };
                })
            };
            createAAMapping({
                variables: {
                    data: dataObj
                }
            });
        } else if ((!versionNo || versionNo === 0) && isDraft) {
            dataObj.id = recID;
            dataObj['mda_profile'] = { connect: { id: formState.auditArea } };
            dataObj['ref'] = formState.ref && (isApproved || approved) ? formState.ref : undefined;
            dataObj['audit_hierarchy'] = {
                create: state.selectedTreeValues?.map((v) => {
                    return { classif_code: v.classif_code, classif_level: v.classif_level };
                })
            };
            createAAMapping({
                variables: {
                    data: dataObj
                }
            });
        } else {
            delete dataObj.id;
            dataObj['mda_profile'] = { connect: { id: formState.auditArea } };
            dataObj['ref'] = formState.ref && (isApproved || approved || duplicate) ? { set: formState.ref } : undefined;
            dataObj['audit_hierarchy'] = {
                create: state.selectedTreeValues?.map((val) => {
                    return { classif_level: val.classif_level, classif_code: val.classif_code };
                })
            };
            updateAAMapping({
                variables: {
                    data: dataObj
                }
            });
        }
    };

    const applyToCreate = () => {
        let mappingRecords = [];
        let entityMappings = [];
        let applyToObj = {
            applyToAuditArea: formState.applyToAuditArea,
            applyToMda: formState.applyToMda
        };
        formState.applyToAuditArea.forEach((val) => {
            let obj = {};
            let entityObj = {};
            entityObj['mda_profile_id'] = val;
            entityObj['mda_profile'] = applyToAAList.filter((v) => v.id === val)[0];
            obj['mda_profile'] = { connect: { id: val } };
            obj['audit_hierarchy'] = { create: state.selectedTreeValues };
            mappingRecords.push(obj);
            entityMappings.push(entityObj);
        });
        setState({ ...state, mappingRecords: mappingRecords, entityMappings, showApplyModal: false, applyToObj: applyToObj });
    };

    return (
        <div className={`page-body pos-rel ${entityLoader || state.loading ? 'mask' : ''}`}>
            {(entityLoader || state.loading) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            <div className="page-header">
                {!entityLoader && (
                    <span className="light-color">
                        {recordID
                            ? transactionId && transactionId !== 'undefined'
                                ? `Transaction Number : ${transactionId}`
                                : `Auditable Area and Hierarchy - ${resObj?.ref ? resObj?.ref : ''}`
                            : 'Auditable Area and Hierarchy'}
                    </span>
                )}

                {!entityLoader && (
                    <span className="backBtn pointer">
                        {recordID && versionNo !== 0 && (
                            <img
                                alt=""
                                className="header-icon"
                                title="Workflow Stages"
                                src={process.env.PUBLIC_URL + '/assets/icons/workflow.svg'}
                                onClick={() => showWorkflow(true)}
                            ></img>
                        )}
                        <span
                            onClick={() => {
                                localStorage.setItem('transaction_id', '');
                                props.history.goBack();
                            }}
                        >
                            <span className="appkiticon icon-left-chevron-outline icon_size"></span>Back
                        </span>
                    </span>
                )}
            </div>
            <div className={`${state.hasActions || mode === 'create' ? 'page-content-ht-with-btns' : 'page-content-ht-without-btns'}`}>
                <Sidebar
                    visible={state.showWorkflowStatus}
                    onHide={() => showWorkflow(false)}
                    position="right"
                    dismissable={true}
                    closeOnEscape={false}
                    showCloseIcon={false}
                >
                    <WorklowSidebarContent
                        sourceId={recordID}
                        listToShowComment={state.listToShowComment}
                        showWorkflow={showWorkflow}
                        handleCommentClick={handleCommentClick}
                    />
                </Sidebar>
                {(isExistApproved || isExistDraft) &&
                    mode === 'view' &&
                    InfoMessage(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessageForView)}
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Internal Audit Unit
                                    {mode !== 'view' && !isApproved && !approved ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {mode !== 'view' && !isApproved && !approved ? (
                                    <Dropdown
                                        name="mda"
                                        value={formState.mda}
                                        onChange={(e) => handleChange(e.value, 'mda')}
                                        appendTo={document.self}
                                        options={mdaProfiles}
                                        placeholder="Select Internal Audit Unit"
                                        className="w-100"
                                        optionLabel="mda"
                                        optionValue="id"
                                        disabled={versionNo > 0}
                                    />
                                ) : (
                                    <>{formState.mdaDivision}</>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Auditable Area
                                    {mode !== 'view' && !isApproved && !approved && !duplicate ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {mode !== 'view' && !isApproved && !approved && !duplicate ? (
                                    <Dropdown
                                        name="auditArea"
                                        value={formState.auditArea}
                                        onChange={(e) => handleChange(e.value, 'auditArea')}
                                        appendTo={document.self}
                                        options={aaList}
                                        placeholder="Select Auditable Area"
                                        className="w-100"
                                        optionLabel="auditable_name"
                                        optionValue="id"
                                        disabled={!formState.mda}
                                    />
                                ) : (
                                    <>{formState.auditAreaName}</>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 m-t-10">
                        {auditHierarchyList.length > 0 && (
                            <TreeCheckBox
                                className={'treecheckbox-height-in-aahierarchy m-b-10'}
                                title="Auditable Area and Hierarchy Selection"
                                auditHierarchyList={auditHierarchyList}
                                selectedRiskItems={state.selectedRiskItems}
                                chkDisabled={mode === 'view'}
                                handleSelectedKey={handleSelectedKey}
                            />
                        )}
                    </div>
                </div>
            </div>
            {mode === 'view' && isApproved ? (
                <div className="bottom-left-btm-div">
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <Button size="sm" kind="primary" className="m-r-10 m-t-10" onClick={() => navigateToEdit()}>
                                Edit
                            </Button>
                        )}
                    ></IsAuthorized>
                </div>
            ) : (
                <ActionsComponents
                    navigateToEdit={() => navigateToEdit()}
                    mode={isApproved ? 'create' : mode}
                    setActionsStatus={setActionsStatus}
                    canDisable={canDisable() || isExistDraft || isExistApproved}
                    data={formState}
                    onSave={(wfParams, action) => handleSave(wfParams, action)}
                    onCancel={() => handleCancel()}
                    showEdit={isExistDraft || isExistApproved ? true : false}
                    version_no={isApproved || approved || isDraft ? 0 : versionNo}
                    version_user={versionUser}
                    privRequired={Privileges?.UPDATE}
                    officeCode={Privileges?.OFFICE_CODE}
                    module="Masters"
                    fmis_function="IA_AUDITABLE_AREA_HIERARCHY"
                    fmis_sub_function="NA"
                    minFieldsRequired={!formState.mda || !formState.auditArea}
                    getRecord={mode !== 'create' ? getRecord : null}
                    getDisplayData={mode !== 'create' ? getDisplayData : null}
                    handleLoader={handleLoader}
                    screenName={'Auditable Area and Hierarchy'}
                    isApproved={isApproved || approved}
                    source_id={isApproved ? '' : recID}
                    additinalButtonClick={additinalButtonClick}
                    buttons={isApproved || approved ? [] : [{ name: 'Apply To', buttonClickType: 'applytoclick', mode: 'create' }]}
                    getBulkCreateData={mode === 'create' ? getBulkCreateData : null}
                />
            )}
            {state.showApplyModal && (
                <Modal
                    visible={state.showApplyModal}
                    onCancel={() => {
                        setState({ ...state, showApplyModal: false });
                    }}
                    className="common_css add_user"
                    backdropClosable={false}
                >
                    <ModalHeader>Apply To</ModalHeader>
                    <ModalBody className="p-0">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">Internal Audit Unit</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                <Dropdown
                                    value={formState.applyToMda}
                                    onChange={(e) => handleChange(e.value, 'applyToMda')}
                                    appendTo={document.self}
                                    options={mdaProfiles}
                                    placeholder="Select Internal Audit Unit"
                                    className="w-100"
                                    optionLabel="mda"
                                    optionValue="id"
                                />
                            </div>
                        </div>
                        <div className="row m-t-10">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">Auditable Areas</b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                <MultiSelect
                                    value={formState.applyToAuditArea}
                                    onChange={(e) => handleChange(e.value, 'applyToAuditArea')}
                                    appendTo={document.self}
                                    options={applyToAAList.filter(
                                        (val) => val.iau_devision_id === formState.applyToMda && val.id !== formState.auditArea
                                    )}
                                    placeholder="Select Auditable Area"
                                    className="w-100"
                                    optionLabel="auditable_name"
                                    optionValue="id"
                                    disabled={!formState.applyToMda}
                                    filter={true}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            className="m-r-10"
                            size="sm"
                            kind="primary"
                            disabled={!formState.applyToMda || !formState.applyToAuditArea || formState?.applyToAuditArea?.length < 1}
                            onClick={() => {
                                applyToCreate();
                            }}
                        >
                            Apply
                        </Button>
                        <Button size="sm" kind="secondary" onClick={() => setState({ ...state, showApplyModal: false })}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
};

export default CreateAAHierarchy;
