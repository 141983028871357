import './Notifications.scss';
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { eventClient } from '../../apollo';
import { getDate, getMonth, getDateVal } from '../../util';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Calendar from '../../components/Calendar/CalendarComponent';

const GeneralNotifications = () => {
    const [state, setState] = useState({
        loading: true,
        first: 0,
        rows: 10,
        page: 0,
        notificationsData: []
    });
    let dt = useRef(null);
    let variable = { type: { notIn: ['Workflow Task'] } };
    const [globalFilter, setGlobalFilter] = useState({ searchVal: '', dateVal: '' });
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [variables, setVariable] = useState(variable);
    const [tableLoader, setTableLoader] = useState(false);
    const [sortField] = useState('version_date');
    const [sortOrder] = useState('desc');

    useQuery(Schema.total_notifications, {
        fetchPolicy: 'no-cache',
        client: eventClient,
        variables: { where: variables },
        onCompleted: (resp) => {
            if (resp) setTotalRecords(resp.total_notifications.totalCount);
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const { refetch: getNotifications } = useQuery(Schema.notifications, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { orderBy: { [sortField]: sortOrder }, skip: skip, take: take, where: variables },
        onCompleted: (data) => {
            if (data && data.notifications?.length > 0) {
                prepareDate(data.notifications);
                let ids = data.notifications.filter((a) => a.is_read === 0).map((b) => b.id);
                if (ids.length > 0) updateAllRead({ variables: { where: { id: { in: ids } }, data: { is_read: { increment: 1 } } } });
                setState({ ...state, loading: false, notificationsData: data.notifications });
                setTableLoader(false);
            } else {
                setState({ ...state, loading: false, notificationsData: [] });
                setTableLoader(false);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });

    const [updateAllRead] = useMutation(Schema.updateManyNotification, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        onError: () => {}
    });

    const prepareDate = (data) => {
        data.forEach((ele) => {
            ele.notif_date = getDate(ele.version_date);
        });
        return data;
    };

    const titleBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="notif_name notif-card">
                    <div>{rowData.message}</div>
                </div>
            </React.Fragment>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="notif-card">
                    <span>{rowData.notif_date}</span>
                </div>
            </React.Fragment>
        );
    };

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left searchIconAlign w-70 p-r-10">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    autoComplete="off"
                    value={globalFilter.searchVal}
                    className="w-100 fs-15 overrideInput"
                    onChange={(e) => setGlobalFilter({ ...globalFilter, dateVal: '', searchVal: e.target.value })}
                    placeholder="Search by Title"
                />
            </span>
            <span className="p-input-icon-left overrideCalender w-30">
                <Calendar
                    showIcon
                    overlayVisible
                    showButtonBar={true}
                    dateFormat="M dd, yy"
                    className="w-100 fs-15"
                    appendTo={document.self}
                    placeholder="Search by Date"
                    value={globalFilter.dateVal}
                    onChange={(e) => setGlobalFilter({ ...globalFilter, searchVal: '', dateVal: e.target.value })}
                />
            </span>
        </div>
    );

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        getNotifications();
    };

    const onFilter = (event) => {
        setTableLoader(true);
        let filterKeys = {};
        let keyData = { ...filterKeys };
        if (event?.filters?.globalFilter?.value?.searchVal) {
            filterKeys['message'] = { contains: event.filters.globalFilter.value.searchVal, mode: 'insensitive' };
        }
        if (event?.filters?.globalFilter?.value?.dateVal) {
            const time = new Date(event.filters.globalFilter.value.dateVal);
            const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
            const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
            filterKeys['version_date'] = { gte: date, lte: date2 };
        }
        /*
         * if (Object.keys(globalFilter).length === 1 && Object.keys(filterKeys).length === 0) {
         *     setTableLoader(false);
         * }
         */
        setVariable({ ...variable, ...filterKeys });
        setSearchFieldKey(keyData);
        setSkip(0);
        setTake(10);
        setPageRows(10);
        getNotifications();
    };

    return (
        <div className="p-t-5 p-l-15 p-r-15 h-100 overflow-auto">
            <div className={`h-100 ${state.loading || tableLoader ? 'mask' : ''}`}>
                {(state.loading || tableLoader) && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <div className="coa-ht p-b-15">
                    <div className="page-header">
                        <span className="light-color">Notifications</span>
                        <span className="backBtn pointer">
                            <Link className="whiteLink" to="/dashboard">
                                <span className="appkiticon icon-left-chevron-outline icon_size" />
                                Back
                            </Link>
                        </span>
                    </div>

                    {!state.loading && (
                        <div className="p-l-15 p-r-15 p-t-15">
                            <div className="row m-0">
                                <div className="col-md-12 p-0 notification-table">
                                    <DataTable
                                        ref={dt}
                                        rows={pageRows}
                                        header={header}
                                        onFilter={onFilter}
                                        filters={searchFieldKey}
                                        paginator={state.loading ? false : true}
                                        value={state.notificationsData}
                                        globalFilter={globalFilter}
                                        emptyMessage="Notifications are yet to be created."
                                        paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                                        rowGroupFooterTemplate={() => 'Records per page'}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        totalRecords={totalRecords}
                                        first={skip}
                                        sortField={sortField}
                                        sortOrder={sortOrder === 'asc' ? 1 : -1}
                                        onPage={(e) => onPageChange(e)}
                                        lazy={true}
                                    >
                                        {!state.loading && <Column field="message" body={titleBodyTemplate} className="w-60 p-l-10" />}
                                        {!state.loading && (
                                            <Column field="notif_date" body={dateBodyTemplate} className="w-20 notif_date p-r-10 " />
                                        )}
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default GeneralNotifications;
