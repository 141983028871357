import React from 'react';
import { Button } from 'appkit-react';
import IsAuthorized from '../../commons/AuthorizationService';

const TopButtonComponent = (props) => {
    const onClickAddNewRate = () => {
        props.showPopUp();
    };
    return (
        <IsAuthorized
            privRequired={props.topGridProps.createPriv}
            officeCode={props.topGridProps.officeCode}
            yes={() => (
                <div>
                    <div className={`row m-0 ${props.topGridProps.className ? props.topGridProps.className : 'p-b-10'} `}>
                        <div className="col-md-12 p-0">
                            <Button
                                size="md"
                                className={props?.topGridProps?.disableAdd ? 'disabled float-right' : 'float-right'}
                                onClick={() => onClickAddNewRate()}
                            >
                                {props?.topGridProps.buttonLabel}
                            </Button>
                        </div>
                    </div>
                    {props?.topGridProps?.buttonInformation && (
                        <div className="row">
                            <span className="col-12 text-right p-r-15 p-b-5">
                                <img className="m-r-10" src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                                <span className="fs-12 ">{props?.topGridProps?.buttonInformation}</span>
                            </span>
                        </div>
                    )}
                </div>
            )}
        ></IsAuthorized>
    );
};

export default TopButtonComponent;
