/* eslint-disable max-depth */
import React, { useState } from 'react';
import Schema from '../AUMQueries';
import AppSchema from '../../../../../schema/AppSchema';
import TableScheme from '../AUMTableSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import FDataTable from '../../../../../components/fDataTable/FDataTable';
import DeleteConfirmModal from '../../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import { Toast } from 'primereact/toast';
import TopCardComponent from '../../../../../components/topComponents/TopCardComponent';
import { getDate, getMonth, getDateVal, convertTextCase } from '../../../../../util';
import { Button } from 'appkit-react';
import { MenuItemsSchema } from '../../../../../commons/MenuItemsSchema';
import IsAuthorized from '../../../../../commons/AuthorizationService';
import { eventClient, iaClient } from '../../../../../apollo';
import AuditComponent from '../../../../../components/auditLog/auditLog';
import RecallButton from '../../../../../commons/RecallButton';
import { useHistory } from 'react-router-dom';
import { ApprovedRecordApproveCheckVariable, ApprovedRecordEntityCheckVariable } from '../../../../../commons/CommonVariables';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';

const StakeHolders = (props) => {
    let Privileges = '';
    let history = useHistory();
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema?.AUDIT_UNIVERSE_MANAGEMENT?.find((a) => a.routerLink === loc[1]) || '';

    let tableColumns = JSON.parse(JSON.stringify(TableScheme.Key_Stake_Holders));
    const [state, setState] = useState({
        customizedRows: [],
        selectedData: {},
        showModal: false,
        loading: true,
        createModal: false,
        where: { is_latest: { equals: true } },
        columns: tableColumns,
        howConfirmModal: false,
        commentRequired: false,
        selectedRec: {}
    });
    const [growl, setGrowl] = useState();
    const [selected, setSelected] = useState({});
    const [cardCount, setCardCount] = useState('');
    const [reference, setReference] = useState({});
    const [recallObj, setRecallObj] = useState();
    const [showAudit, setShowAudit] = useState(false);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState();
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [tableLoader, setTableLoader] = useState(false);
    const [sortOrder, setSortOrder] = useState('desc');
    const [recallLoader, setRecallLoader] = useState(false);
    const [actions, setActions] = useState('');
    const [where, setWhere] = useState(
        props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
    );

    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '200px',
        fieldType: 'tooltip'
    };

    const { refetch: refetchCount } = useQuery(Schema.keyStakeHolderCount, {
        fetchPolicy: 'no-cache',
        variables: { where: state.where },
        client: iaClient,
        skip: selected.label && selected.label !== 'Total Key Stakeholders',
        onCompleted: (resp) => {
            setTotalRecords(resp.keyStakeHolderCount);
            if (!cardCount || cardCount === '') {
                setCardCount(resp.keyStakeHolderCount);
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const growlMessages = (growlMessage) => {
        growl.show({ life: 6000, severity: 'error', summary: growlMessage });
    };

    const [checkCount] = useLazyQuery(Schema.stakeholderDefCount, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (actions === 'edit') {
                if (resp.keyStakeHolderCount > 0) {
                    if (state.selectedRec?.entity_id) growlMessages(window.EDIT_OF_APPROVAL_MESSAGES.EntityMessage);
                    else growlMessages(window.EDIT_OF_APPROVAL_MESSAGES.ApprovedMessage);
                } else {
                    if (state.selectedRec?.entity_id) history.push('stakeholders/edit/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push('stakeholders/edit/' + state.selectedRec['id'] + '/approved');
                }
            } else {
                if (resp.keyStakeHolderCount > 0) {
                    if (state.selectedRec?.entity_id) history.push('stakeholders/view/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push('stakeholders/view/' + state.selectedRec?.id);
                } else {
                    if (state.selectedRec?.entity_id) history.push('stakeholders/view/' + state.selectedRec['entity_id'] + '/draft');
                    else history.push('stakeholders/view/' + state.selectedRec['id'] + '/approved');
                }
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    let variables = { where: state.where, orderBy: { [sortField]: sortOrder } };
    if (!selected.label || selected.label === 'Total Key Stakeholders') {
        variables.skip = skip;
        variables.take = take;
    }

    let { refetch: fetchStakeholders, error } = useQuery(Schema.keyStakeHolders, {
        fetchPolicy: 'no-cache',
        variables: variables,
        client: iaClient,
        onCompleted: (response) => {
            if (response?.keyStakeHolders) {
                prepareRows(response);
                return;
            }
            setState({ ...state, customizedRows: response.keyStakeHolders });
        },
        onError: () => {
            setState({ ...state, loading: false });
            setTableLoader(false);
        }
    });
    const prepareRows = (response) => {
        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            response.keyStakeHolders = [...response.keyStakeHolders, ...selected.new_objs];
        }
        for (let index = 0; index < response.keyStakeHolders.length; index++) {
            let ele = response.keyStakeHolders[index];
            if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Key Stakeholders' &&
                selected.status_obj &&
                selected.status_obj[ele.id] &&
                selected?.status_obj[ele.id]?.record
            ) {
                let record = selected?.status_obj[ele.id]?.record;
                record.updated_at_local = getDate(record.version_date);
                record.status = selected.status_obj[ele.id].status;
                if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self')
                    response.keyStakeHolders[index].version_no = response.keyStakeHolders[index]?.versionNo;
                response.keyStakeHolders[index] = record;
            } else if (
                selected.ids?.length > 0 &&
                selected.label !== 'Total Key Stakeholders' &&
                selected.status_obj &&
                selected.status_obj[ele.id]
            ) {
                ele.status = selected.status_obj[ele.id].status;
                ele.txnId = selected.status_obj[ele.id].txnId;
                ele.assignedTo = convertTextCase(selected.status_obj[ele.id].assignedTo, false) || '';
            }
            ele.updated_at_local = getDate(ele.version_date);
        }

        let arr = response.keyStakeHolders.map((a) => a.status).filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'status') {
                a.filterOptions = arr;
            }
        });

        setState({
            ...state,
            customizedRows: response.keyStakeHolders,
            showModal: false,
            selectedData: {},
            columns: state.columns,
            loading: false
        });
        setTableLoader(false);
    };

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(AppSchema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
                state.customizedRows.splice(index, 1);
                setState({ ...state, customizedRows: state.customizedRows, loading: false, showModal: false, selectedData: {} });
                growl.show({ life: 6000, severity: 'success', summary: window.AUDIT_HEIRARCHY_DETAILS.DeleteStakeHolder });
                return;
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleCancel = () => {
        setState({ ...state, showModal: false, selectedData: {}, createModal: false, loading: false });
    };
    const handleDelete = () => {
        if (state.selectedData?.entity_id) {
            deleteWorkFlowAction({ variables: { where: { id: state.selectedData.entity_id } } });
            return;
        }
    };

    const onClickDelete = (rowData) => {
        setState({ ...state, selectedData: rowData, showModal: true });
    };

    const navigateTo = (query, select, columns) => {
        if (select.label !== selected.label) {
            let tWhere = JSON.parse(JSON.stringify(where));
            setWhere(query);

            if (select.label === 'Pending For Verification/Approval') {
                setRecallObj(select.canRecall);
                columns.splice(columns.length - 1, 0, assignedTo);
            }
            setState({ ...state, where: query, columns: columns, loading: true });
            setSelected(select);
            setSkip(0);
            setTake(10);
            setPageRows(10);
            setSortField('version_date');
            setSortOrder('asc');
            setSearchFieldKey({});
            setTableLoader(true);
            if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
                fetchStakeholders({ variables: { where: query } }).then((response) => {
                    if (response) prepareRows(response.data);
                });
            }
        }
    };

    const onClickEdit = (rowData, action) => {
        setActions(action);
        setState({ ...state, selectedRec: rowData });
        if (rowData.txnId) localStorage.setItem('transaction_id', rowData.txnId);
        else localStorage.setItem('transaction_id', '');

        if (rowData.entity_id) checkCount({ variables: ApprovedRecordEntityCheckVariable(rowData) });
        else if (rowData.is_latest) checkCount({ variables: ApprovedRecordApproveCheckVariable(rowData) });
        else history.push('stakeholders' + '/' + action + '/' + rowData['id']);
    };

    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };

    const goBack = () => {
        setShowAudit(false);
    };

    const onClickRecall = (rowData) => {
        setState({ ...state, selectedData: rowData });
    };

    const setCustomizedRows = () => {
        let index = state.customizedRows.findIndex((pro) => pro === state.selectedData);
        state.customizedRows.splice(index, 1);
        setState({ ...state, selectedData: {}, showConfirmModal: false, customizedRows: state.customizedRows });
        setRecallLoader(false);
    };

    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };

    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };

    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };

    const actionTemplate = (rowData) => {
        const showRecallBtn = recallObj && recallObj[rowData.id];
        return (
            <>
                {(selected.label === 'Pending With Self' || rowData.is_latest) && (
                    <IsAuthorized
                        privRequired={Privileges?.UPDATE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-r-5 m-l-5"
                                onClick={() => onClickEdit(rowData, 'edit')}
                            >
                                <img
                                    title="Edit"
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {(rowData.status === 'Draft' || rowData.status === 'Recalled') && selected.label === 'Pending With Self' && (
                    <IsAuthorized
                        privRequired={Privileges?.DELETE}
                        officeCode={Privileges?.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickDelete(rowData)}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status !== 'Draft' && (
                    <button className="a-btn-background-transparent a-icon-container m-l-5 m-r-5" onClick={() => onClickAudit(rowData)}>
                        <img
                            className="a-font-18"
                            title="Audit"
                            src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                            alt="view Icon"
                        />
                    </button>
                )}
                {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                    <RecallButton
                        rowData={rowData}
                        setCustomizedRows={setCustomizedRows}
                        onClickRecall={onClickRecall}
                        source_id={state.selectedData?.id}
                        handleCalRecallStatus={handleCalRecallStatus}
                        handleCancelModal={handleCancelModal}
                        handleLoader={handleLoader}
                        commentRequired={state.commentRequired}
                        showConfirmModal={state.showConfirmModal}
                        growl={growl}
                        module={'Masters'}
                        fmis_function={'IA_KEY_STAKE_HOLDER'}
                    />
                )}
            </>
        );
    };

    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Key Stakeholders') refetchCount();
    };

    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).map((filterKey) => {
            if (filterKeys[filterKey].contains === null) delete filterKeys[filterKey];
            return filterKey;
        });
        let keyData = { ...filterKeys };
        setSearchFieldKey(keyData);
        let obj;
        obj = { is_latest: { equals: true } };
        if (filterKeys && filterKeys['status']) {
            if (filterKeys['status'].contains) {
                filterKeys['status'] = { contains: filterKeys['status'].contains, mode: 'insensitive' };
            } else {
                delete filterKeys['status'];
            }
        }

        if (filterKeys['updated_at_local']) {
            if (filterKeys['updated_at_local'].contains) {
                const time = new Date(filterKeys['updated_at_local'].contains);
                const date = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T00:00:00.000Z';
                const date2 = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T23:59:59.000Z';
                filterKeys['version_date'] = { gte: date, lte: date2 };
                delete filterKeys['updated_at_local'];
            } else {
                delete filterKeys['updated_at_local'];
            }
        }

        if (Object.keys(state.where).length === 1 && Object.keys(filterKeys).length === 0) setTableLoader(false);
        setState({ ...state, where: { ...obj, ...filterKeys } });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        if (!selected.label || selected.label === 'Total Key Stakeholders') refetchCount();
    };

    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
        if (event.sortField === 'updated_at_local') srtField = 'version_date';
        let srtOrder = 'asc';
        if (sortField === srtField) srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };

    const viewData = (rowData) => {
        return (
            <span className="pointer icon-primary" onClick={() => onClickEdit(rowData, 'view')}>
                <EllipsisWithTooltip placement="auto">
                    <p className="m-0 p-ellipsis">{rowData[state.columns[0].field]}</p>
                </EllipsisWithTooltip>
            </span>
        );
    };

    if (state.columns.length) {
        state.columns[0].body = viewData;
        state.columns[state.columns.length - 1].body = actionTemplate;
    }

    if (error) return `Error! ${error.message}`;

    return (
        <div>
            {!showAudit ? (
                <div className={`${state.loading || delLoader || recallLoader ? ' mask page-body' : 'page-body'}`}>
                    <Toast ref={(el) => setGrowl(el)} />
                    {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                    <div>
                        <div className="page-header">
                            <span className="light-color">Key Stakeholders (External)</span>
                        </div>
                        <div className="coa-card-content">
                            {!state.loading && !delLoader && !recallLoader && (
                                <TopCardComponent
                                    cards={state.cards}
                                    fetchParent={(query, select, columns) => navigateTo(query, select, columns)}
                                    selected={selected}
                                    fmis_function="IA_KEY_STAKE_HOLDER"
                                    approvedLabel="Total Key Stakeholders"
                                    columns={tableColumns}
                                    cardCount={cardCount}
                                />
                            )}
                            <div className="m-t-10">
                                <div className="row flex-end  m-t-10 m-r-0 m-b-10">
                                    <IsAuthorized
                                        privRequired={Privileges.CREATE}
                                        officeCode={Privileges.OFFICE_CODE}
                                        yes={() => (
                                            <Button
                                                size="md"
                                                className="float-right"
                                                onClick={() => {
                                                    props.history.push('stakeholders/create');
                                                    localStorage.setItem('transaction_id', '');
                                                }}
                                            >
                                                Add Stakeholders
                                            </Button>
                                        )}
                                    ></IsAuthorized>
                                </div>
                            </div>
                            {!state.loading && (
                                <FDataTable
                                    rows={state.customizedRows}
                                    columns={state.columns}
                                    deletePriv={Privileges.DELETE}
                                    updatePriv={Privileges.UPDATE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    emptyMessage="Key Stakeholders are yet to be created"
                                    loading={tableLoader}
                                    className="primary-table"
                                    totalRecords={totalRecords}
                                    count={skip}
                                    onPageChange={(e) => onPageChange(e)}
                                    pageRows={pageRows}
                                    searchRecord={searchRecord}
                                    filterKeys={searchFieldKey}
                                    lazyLoad={!selected.label || selected.label === 'Total Key Stakeholders' ? true : false}
                                    onSort={onSort}
                                    sortField={sortField}
                                    sortOrder={sortOrder === 'asc' ? 1 : -1}
                                    scrollable
                                />
                            )}
                        </div>
                        {state.showModal && (
                            <DeleteConfirmModal
                                showModal={state.showModal}
                                handleCancel={handleCancel}
                                type={'Key Stakeholders'}
                                name={
                                    state.selectedData.entity_id
                                        ? `Transaction ID: ${state.selectedData.txnId}`
                                        : 'Key Stakeholders: ' + state.selectedData.txnId
                                }
                                handleDelete={handleDelete}
                                state={{ loading: delLoader }}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <AuditComponent
                    refence_label="Key Stakeholders"
                    goBack={goBack}
                    transaction_Id={reference.txnId}
                    reference_value={reference.ref}
                    sourceData={reference}
                />
            )}
        </div>
    );
};
export default StakeHolders;
