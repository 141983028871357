import React, { useState, useEffect } from 'react';
import * as FormValidation from '../../Validation';
import Schema from '../../schema/AppSchema';
import { useLazyQuery } from '@apollo/client';
import { adminClient } from '../../apollo';
import HOADetailsModal from '../../components/HOADetails/HOADetailsModal';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export function HOASelection(props) {
    const [hoaCode, setHoaCode] = useState(props.hoa);
    const [state, setState] = useState({
        hoaCode: props?.hoa,
        showHOA: false,
        isHOAValid: true
    });

    const [errors, setErrors] = useState(false);
    const [isValid, setValid] = useState(true);

    const fields = [{ key: 'hoaCode', validators: 'hoa', mandatory: true }];

    useEffect(() => {
        setState({ ...state, hoaCode: props.hoa });
    }, [props]);

    const [validateHOA] = useLazyQuery(Schema.validateHOA, {
        client: adminClient,
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            function_name: props.function,
            sub_function_name: '',
            hoa_code: hoaCode || '',
            segement_to_exclude: props?.economic_segment
        },
        onCompleted: (res) => {
            if (res?.validateHOA?.status === 'SUCCESS') {
                props.handleHOAPopup(true, state.hoaCode, props?.type);
                setErrors(false);
            }
        },
        onError: (res) => {
            if (res.message.search('No segment code found with') !== -1) {
                props.handleHOAPopup(true, state.hoaCode, props?.type);
            }
        }
    });

    const handleChange = (e, key) => {
        setState({ ...state, [key]: e });
        if (props.isHOAValid) {
            handleErrors(false);
            props.validateHOA(false);
        }
        const field = fields.filter((obj) => {
            return key === obj['key'];
        });
        validateField(field, e, key);
        props.handleHOAPopup(false, e, props?.type);
    };

    const validateField = async (field, ev, key) => {
        let err = {};
        err = FormValidation.validateFileld(field[0].validators, key, ev);
        setErrors(err?.hoaCode);
        if (err?.hoaCode === true) {
            if (props.validateHOA) props.validateHOA(false);
        }
    };

    const handleKeyPress = (target) => {
        if ((target.keyCode === 9 || target.keyCode === 13) && state.hoaCode !== '') {
            setHoaCode(state.hoaCode);
            validateHOA({ fin_year: props.finYear });
        }
    };

    const onClickSearch = () => {
        if (state.hoaCode === '' || !state.hoaCode) {
            props.handleHOAPopup(true, state.hoaCode, props?.type);
            return;
        }
        setHoaCode(state.hoaCode);
        validateHOA({ fin_year: props.finYear });
    };

    const handleErrors = (flag) => {
        setState({ ...state, isHOAValid: flag });
        setValid(flag);
    };

    return (
        <div>
            <div className="p-inputgroup hoa-input">
                <InputText
                    type="text"
                    name="hoaCode"
                    autoComplete="off"
                    value={state.hoaCode}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => handleChange(e.target.value, 'hoaCode')}
                    placeholder={props?.placeHolder ? props.placeHolder : 'Enter HOA Code'}
                />
                <Button icon="pi pi-search" onClick={onClickSearch} />
            </div>
            {(errors || !isValid) && <p className="error"> Please enter valid HOA code</p>}
            {props.showHoaModal && (
                <HOADetailsModal
                    showModal={props.showHoaModal}
                    handleHOACancel={() => props.handleHOACancel('showHoaModal')}
                    validateHOA={props.validateHOA}
                    updateHOAInParent={props.updateHOAInParent}
                    selectedHOA={props.selectedHOA}
                    hoaList={props.hoaList}
                    errors={setErrors}
                    handleErrors={handleErrors}
                    type={props.type}
                    hoaDetails={props.hoaDetails}
                />
            )}
        </div>
    );
}

export default HOASelection;
