import React, { useState } from 'react';
import { Input, Button } from 'appkit-react';
import * as FormValidation from '../../Validation';

const ChangePassword = (props) => {
    const [errors, setErrors] = useState(false);
    const [statePassword, setStatePassword] = useState({
        password: '',
        new_password: '',
        confirm_password: ''
    });

    const handleChangePassword = (ev, key) => {
        const value = ev;
        props.handleChange(ev, key);
        setStatePassword({
            ...statePassword,
            [key]: value
        });
        validateFeild(ev, key);
    };

    const validateFeild = async (ev, key) => {
        let err = {};
        if (key === 'new_password') {
            err = FormValidation.validateFileld('password', key, ev);
        }
        if (key === 'confirm_password') {
            err = FormValidation.validateFileld('mismatch', key, ev, statePassword.new_password);
        }
        if (key === 'first_name' || key === 'last_name' || key === 'password') {
            err = FormValidation.validateFileld('required', key, ev);
        }
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };
    return (
        <div className="p-l-30 p-r-30">
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-30">
                <Input
                    inputBoxSize="lg"
                    key="password"
                    placeholder="New Password"
                    type="password"
                    validations={['required']}
                    errMsg="Password should contain atleast 8 characters and should be like this 'Abc@1cdf'"
                    hasError={errors && errors.new_password}
                    autoComplete="off"
                    value={props.properties.new_password}
                    onChange={(e) => handleChangePassword(e, 'new_password')}
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 m-t-30">
                <Input
                    inputBoxSize="lg"
                    key="confirmPassword"
                    placeholder="Re-Confirm Password"
                    validations={['required']}
                    autoComplete="off"
                    errMsg="New Password and Re-type New Password does not match"
                    hasError={errors && errors.confirm_password}
                    type="password"
                    value={props?.properties?.confirm_password}
                    onChange={(e) => handleChangePassword(e, 'confirm_password')}
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 text-center">
                <Button
                    size="lg"
                    className=" m-t-30"
                    onClick={props.onChangPasswordClick}
                    disabled={
                        props.properties.new_password === '' ||
                        props.properties.new_password !== props.properties.confirm_password ||
                        errors.new_password ||
                        errors.confirm_password
                    }
                >
                    Submit
                </Button>
                <Button size="lg" className=" m-t-30 m-l-10" onClick={props.onChangePasswordCancel}>
                    Cancel
                </Button>
            </div>
            {!props.properties.password_update_required && (
                <div
                    className="pointer col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 p-20 m-t-10 primary-color text-center"
                    onClick={props.backToLogin}
                >
                    <span className="go-back-label">Go back to login</span>
                </div>
            )}
        </div>
    );
};
export default ChangePassword;
