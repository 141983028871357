import React, { useState, useEffect } from 'react';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import TextAreaComponent from '../../../../components/InputComponent/TextAreaComponent';
import * as FormValidation from '../../../../Validation';
import { getDate, serverDate } from '../../../../util';
import { Toast } from 'primereact/toast';
import '../../../../styles/utils.scss';

const AdditionalBillDetails = (props) => {
    const [formState, setFormState] = useState(props.formStateObj?.Additional_Bill_Details);
    const [errors] = useState({});
    const [growl, setGrowl] = useState();
    useEffect(() => {
        setFormState(props.formStateObj?.Additional_Bill_Details);
        // eslint-disable-next-line
    }, [props.formStateObj?.Additional_Bill_Details]);
    const fields = [
        { key: 'encashment_days', validators: 'numbersOnly', maxLength: 3 },
        { key: 'no_of_leaves', validators: 'numbersOnly', maxLength: 3 },
        { key: 'other_expenses', validators: 'numbersOnly', maxLength: 6 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);
        if (
            err[key] ||
            ((key === 'encashment_days' || key === 'no_of_leaves' || key === 'other_expenses') && parseInt(ev) <= 0) ||
            (typeof ev === 'string' && ev.trim() === '')
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        props.updateErrorStatus(Object.keys(errors).length > 0);
        // setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        if (key === 'from_date') {
            formState.to_date = '';
        }
        if (key === 'block_from_date') {
            formState.block_to_date = '';
        }
        if (key === 'encashment_days' && props.formStateObj?.BILLS?.billType?.config_details[0]?.include_el_encashment_days < ev) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary:
                    window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.ELEncashment +
                    props.formStateObj?.BILLS?.billType?.config_details[0]?.include_el_encashment_days +
                    window.EXPENDITURE_MANAGEMENT_BILLS_MANAGEMENT.Days
            });
            return;
        }
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    useEffect(() => {
        props.saveFormData('Additional_Bill_Details', formState);
        // eslint-disable-next-line
    }, [formState]);

    return (
        <div className="coa-card p-10 p-b-0">
            <Toast ref={(el) => setGrowl(el)} />
            <div className="row">
                {props.additionalDetailsObj?.isVisibleObj?.EL_Encashment__No_of_Days && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    EL Encashment - No. of Days
                                    {props.additionalDetailsObj?.isMandatory?.EL_Encashment__No_of_Days && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="encashment_days"
                                        type="text"
                                        value={formState.encashment_days}
                                        maxLength={3}
                                        onChange={(e) => handleChange(e, 'encashment_days')}
                                        placeholder="Enter EL Encashment - No. of Days"
                                        hasError={errors && errors.encashment_days}
                                        errMsg="Please enter valid EL Encashment - No. of Days"
                                    />
                                )}
                                {props.mode === 'view' && formState.encashment_days}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.Block_From__Date && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Block From - Date
                                    {props.additionalDetailsObj?.isMandatory?.Block_From__Date && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <Calendar
                                        className="w-100"
                                        minDate={serverDate()}
                                        readOnlyInput={true}
                                        placeholder="Select Block From - Date"
                                        appendTo={document.self}
                                        value={formState.block_from_date}
                                        onChange={(e) => handleChange(e.target.value, 'block_from_date')}
                                        dateFormat="dd M yy"
                                        showIcon
                                    />
                                )}
                                {props.mode === 'view' && formState.block_from_date && getDate(formState.block_from_date, true)}
                            </div>
                        </div>
                    </div>
                )}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    {props.additionalDetailsObj?.isVisibleObj?.Block_To__Date && (
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Block To - Date
                                    {props.additionalDetailsObj?.isMandatory?.Block_To__Date && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <Calendar
                                        className="w-100"
                                        minDate={
                                            new Date(formState.block_from_date) < serverDate()
                                                ? new Date(serverDate().setDate(serverDate().getDate() + 1))
                                                : new Date(formState.block_from_date)
                                        }
                                        disabled={!formState.block_from_date}
                                        readOnlyInput={true}
                                        placeholder="Select Block To - Date"
                                        appendTo={document.self}
                                        value={formState.block_to_date}
                                        onChange={(e) => handleChange(e.target.value, 'block_to_date')}
                                        dateFormat="dd M yy"
                                        showIcon
                                    />
                                )}
                                {props.mode === 'view' && formState.block_to_date && getDate(formState.block_to_date, true)}
                            </div>
                        </div>
                    )}
                    {props.additionalDetailsObj?.isVisibleObj?.No_of_Leave_days && (
                        <div className="row m-t-10">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    No. of Leave Days
                                    {props.additionalDetailsObj?.isMandatory?.No_of_Leave_days && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="no_of_leaves"
                                        type="text"
                                        value={formState.no_of_leaves}
                                        maxLength={3}
                                        onChange={(e) => handleChange(e, 'no_of_leaves')}
                                        placeholder="Enter No. of Leave Days"
                                        hasError={errors && errors.no_of_leaves}
                                        errMsg="Please enter valid No. of Leave Days"
                                    />
                                )}
                                {props.mode === 'view' && formState.no_of_leaves}
                            </div>
                        </div>
                    )}
                </div>
                {props.additionalDetailsObj?.isVisibleObj?.Purpose_of_Journey && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Purpose of the Journey
                                    {props.additionalDetailsObj?.isMandatory?.Purpose_of_Journey && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                {props.mode !== 'view' && (
                                    <>
                                        <TextAreaComponent
                                            textBoxSize="xl"
                                            rows="3"
                                            name="journey_purpouse"
                                            placeholder="Enter Purpose of the Journey"
                                            maxLength={300}
                                            value={formState.journey_purpouse}
                                            onChange={(e) => handleChange(e, 'journey_purpouse')}
                                            alphanumeric="true"
                                        />
                                        {errors && errors.journey_purpouse && (
                                            <p className="a-form-error">Please enter valid Purpose of the Journey</p>
                                        )}
                                    </>
                                )}
                                {props.mode === 'view' && (formState?.journey_purpouse ? formState.journey_purpouse : '–')}
                            </div>
                        </div>
                    </div>
                )}

                {props.additionalDetailsObj?.isVisibleObj?.From_Date && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    From Date
                                    {props.additionalDetailsObj?.isMandatory?.From_Date && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <Calendar
                                        className="w-100"
                                        readOnlyInput={true}
                                        maxDate={serverDate()}
                                        placeholder="Select From Date"
                                        appendTo={document.self}
                                        value={formState.from_date}
                                        onChange={(e) => handleChange(e.target.value, 'from_date')}
                                        dateFormat="dd M yy"
                                        showIcon
                                    />
                                )}
                                {props.mode === 'view' && formState.from_date && getDate(formState.from_date, true)}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.To_Date && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    To Date
                                    {props.additionalDetailsObj?.isMandatory?.To_Date && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <Calendar
                                        className="w-100"
                                        minDate={new Date(formState.from_date)}
                                        maxDate={serverDate()}
                                        disabled={!formState.from_date}
                                        readOnlyInput={true}
                                        placeholder="Select To Date"
                                        appendTo={document.self}
                                        value={formState.to_date}
                                        onChange={(e) => handleChange(e.target.value, 'to_date')}
                                        dateFormat="dd M yy"
                                        showIcon
                                    />
                                )}
                                {props.mode === 'view' && formState.to_date && getDate(formState.to_date, true)}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.Headquarter && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Headquarter
                                    {props.additionalDetailsObj?.isMandatory?.Headquarter && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="headquarter"
                                        type="text"
                                        value={formState.headquarter}
                                        maxLength={30}
                                        onChange={(e) => handleChange(e, 'headquarter')}
                                        placeholder="Enter Headquarter"
                                        hasError={errors && errors.headquarter}
                                        errMsg="Please enter valid Headquarter"
                                        alphanumeric="true"
                                    />
                                )}
                                {props.mode === 'view' && (formState?.headquarter ? formState.headquarter : '–')}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.HeadquarterOld && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Headquarter(Old)
                                    {props.additionalDetailsObj?.isMandatory?.HeadquarterOld && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="headquarter_old"
                                        type="text"
                                        value={formState.headquarter_old}
                                        maxLength={30}
                                        onChange={(e) => handleChange(e, 'headquarter_old')}
                                        placeholder="Enter Headquarter(Old)"
                                        hasError={errors && errors.headquarter_old}
                                        errMsg="Please enter valid Headquarter(Old)"
                                        alphanumeric="true"
                                    />
                                )}
                                {props.mode === 'view' && (formState?.headquarter_old ? formState.headquarter_old : '–')}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.HeadquarterNew && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Headquarter(New)
                                    {props.additionalDetailsObj?.isMandatory?.HeadquarterNew && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="headquarter_new"
                                        type="text"
                                        value={formState.headquarter_new}
                                        maxLength={30}
                                        onChange={(e) => handleChange(e, 'headquarter_new')}
                                        placeholder="Enter Headquarter(New)"
                                        hasError={errors && errors.headquarter_new}
                                        errMsg="Please enter valid Headquarter(New)"
                                        alphanumeric="true"
                                    />
                                )}
                                {props.mode === 'view' && (formState?.headquarter_new ? formState.headquarter_new : '–')}
                            </div>
                        </div>
                    </div>
                )}
                {props.additionalDetailsObj?.isVisibleObj?.Order_No && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Order No.
                                    {props.additionalDetailsObj?.isMandatory?.Order_No && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 word-break-word">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="order_no"
                                        type="text"
                                        value={formState.order_no}
                                        maxLength={30}
                                        onChange={(e) => handleChange(e, 'order_no')}
                                        placeholder="Enter Order No."
                                        hasError={errors && errors.order_no}
                                        errMsg="Please enter valid Order No."
                                    />
                                )}
                                {props.mode === 'view' && (formState?.order_no ? formState.order_no : '–')}
                            </div>
                        </div>
                    </div>
                )}

                {props.additionalDetailsObj?.isVisibleObj?.Other_Expenses && (
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 word-break-word">
                                <b className="fs-14">
                                    Other Expenses
                                    {props.additionalDetailsObj?.isMandatory?.Other_Expenses && props.mode !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.mode !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="other_expenses"
                                        type="text"
                                        value={formState.other_expenses}
                                        maxLength={6}
                                        onChange={(e) => handleChange(e, 'other_expenses')}
                                        placeholder="Enter Other Expenses"
                                        hasError={errors && errors.other_expenses}
                                        errMsg="Please enter valid Other Expenses"
                                    />
                                )}
                                {props.mode === 'view' && formState.other_expenses}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdditionalBillDetails;
