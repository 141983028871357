/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const AAPEQueries = {
    coiDeclarations: gql`
        query coiDeclarations($where: COIDeclarationWhereInput!, $orderBy: [COIDeclarationOrderByInput!], $skip: Int, $take: Int) {
            coiDeclarations(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                declarant
                conflict
                description
                proposed_management
                status
                is_latest
                version_no
                version_user
                version_date
                userInfo
                aap_detail {
                    id
                    scope
                    classification
                    budget
                    total_auditor_weeks
                    aap_item_id
                    mda_profile_def {
                        auditable_area
                        auditable_area_code
                    }
                    auditors {
                        aap_auditor_details {
                            role
                            name
                            specialization
                        }
                    }
                }
                assignment {
                    id
                    ref
                    status
                }
            }
        }
    `,
    coiDeclaration: gql`
        query coiDeclaration($where: COIDeclarationWhereUniqueInput!) {
            coiDeclaration(where: $where) {
                id
                ref
                declarant
                conflict
                description
                proposed_management
                status
                is_latest
                version_no
                version_user
                version_date
                userInfo
                aap_detail {
                    id
                    scope
                    classification
                    budget
                    total_auditor_weeks
                }
                assignment {
                    id
                    ref
                    status
                }
            }
        }
    `,
    createManyCOIDeclaration: gql`
        mutation createManyCOIDeclaration($data: COIDeclarationBulkCreateInput!) {
            createManyCOIDeclaration(data: $data)
        }
    `,
    createOneCOIDeclaration: gql`
        mutation createOneCOIDeclaration($data: COIDeclarationCreateInput!) {
            createOneCOIDeclaration(data: $data) {
                id
            }
        }
    `,
    updateOneCOIDeclaration: gql`
        mutation updateOneCOIDeclaration($data: COIDeclarationUpdateInput!, $where: COIDeclarationWhereUniqueInput!) {
            updateOneCOIDeclaration(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneCOIDeclaration: gql`
        mutation deleteOneCOIDeclaration($where: COIDeclarationWhereUniqueInput!) {
            deleteOneCOIDeclaration(where: $where) {
                id
            }
        }
    `,
    coiDeclarationCount: gql`
        query coiDeclarationCount($where: COIDeclarationWhereInput) {
            coiDeclarationCount(where: $where)
        }
    `,
    files: gql`
        query files($where: FileWhereInput) {
            files(where: $where) {
                id
                path
                input_name
                size
                mimetype
                version_no
                version_user
                version_date
            }
        }
    `,
    aapDetailsForCOI: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                classification
                budget
                scope
                mda_profile_def {
                    id
                    mda
                    mda_code
                    auditable_area
                    auditable_area_code
                    management_contacts {
                        employee_id
                    }
                }
                auditors {
                    designation
                    resource_source
                    aap_auditor_details {
                        user_id
                        name
                        role
                        dept_org
                        specialization
                    }
                }
            }
        }
    `,
    aapDetails: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                classification
                budget
                scope
                objective
                total_auditor_weeks
                aap_item {
                    id
                    ref
                    aap {
                        ref
                        fin_year_id
                    }
                }
                aap_sub_assignments {
                    assignment {
                        id
                        ref
                        followup_assignment_ref
                    }
                }
                sap_selection_detail {
                    id
                    recommended_year
                    proposed_year
                    budget
                    auditor_weeks
                    classification
                }
                mda_profile_def {
                    id
                    mda
                    mda_code
                    auditable_area
                    auditable_area_code
                    management_contacts {
                        employee_id
                    }
                }
                objective_mappings {
                    objective {
                        objective
                        description
                        is_active
                        id
                        ref
                        audit_classif_codes {
                            classif_codes
                        }
                    }
                }
                aap_level_details {
                    aap_detail_id
                    level_id
                    classif_code
                    level_alias
                    classif_code
                    classif_code_obj {
                        name
                        parent_id
                        audit_level_id
                    }
                }
                schedule_months {
                    seq
                    month
                }
                assignment_status {
                    state
                    status
                }
                audit_program_details {
                    procedure {
                        ref
                        name
                    }
                }
                commencement_detail {
                    id
                    audit_commencement_form {
                        id
                        name
                        file_number
                        audit_type
                        year_of_audit
                        audit_authority
                        ref
                    }
                }
                auditors {
                    designation
                    resource_source
                    aap_auditor_details {
                        user_id
                        name
                        role
                        dept_org
                        specialization
                    }
                }
                finding {
                    ref
                    audit_finding_details {
                        title
                        description
                    }
                    finding_observations {
                        audit_finding_observation_details {
                            name
                            description
                        }
                    }
                    audit_program_detail {
                        procedure {
                            ref
                            name
                        }
                    }
                    commencement_detail {
                        aap_detail {
                            aap_item {
                                aap {
                                    ref
                                }
                            }
                            aap_sub_assignments {
                                assignment {
                                    ref
                                }
                            }
                        }
                        audit_commencement_form {
                            file_number
                            audit_authority
                            ref
                        }
                    }
                }
            }
        }
    `,
    aapDetailsForTestResults: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                assignment_status {
                    state
                    status
                }
                aap_item {
                    id
                    ref
                    aap {
                        ref
                    }
                }
                objective_mappings {
                    guideline_openion {
                        audit_opinion_guideline_details {
                            slab_no
                            txn_fail_percent_start
                            txn_fail_percent_end
                            conclusion_opinion
                            description
                        }
                    }
                }
                aap_sub_assignments {
                    assignment {
                        id
                        ref
                        followup_assignment_ref
                    }
                }
                mda_profile_def {
                    id
                    mda
                    mda_code
                    auditable_area
                    auditable_area_code
                }
                aap_level_details {
                    aap_detail_id
                    level_id
                    classif_code
                    level_alias
                    classif_code
                    classif_code_obj {
                        name
                        parent_id
                        audit_level_id
                    }
                }
                finding {
                    ref
                    audit_program_detail {
                        procedure {
                            id
                            ref
                            name
                        }
                    }
                    commencement_detail {
                        aap_detail {
                            aap_item {
                                aap {
                                    ref
                                }
                            }
                            aap_sub_assignments {
                                assignment {
                                    ref
                                }
                            }
                        }
                        audit_commencement_form {
                            file_number
                            audit_authority
                            ref
                        }
                    }
                }
                audit_program_details {
                    objective {
                        objective
                        ref
                        id
                    }
                    procedure {
                        id
                        ref
                        name
                    }
                    control {
                        id
                        name
                        ref
                    }
                }
                commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                    id
                    audit_commencement_form {
                        id
                        name
                        file_number
                        audit_type
                        year_of_audit
                        audit_authority
                        ref
                    }
                }
            }
        }
    `,
    aapFindingDetails: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                commencement_detail {
                    id
                    aap_detail {
                        id
                        aap_level_details {
                            level_id
                            classif_code
                            level_alias
                            classif_code_obj {
                                name
                            }
                        }
                        aap_item {
                            id
                            audit_type
                        }
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                    }
                    audit_commencement_form {
                        file_number
                        year_of_audit
                        name
                    }
                }
                finding {
                    ref
                    id
                    version_no
                    version_user
                    version_date
                    status
                    recurrent_finding
                    finding_remarks
                    finding_status
                    audit_finding_details {
                        document_info
                        criteria_observation
                        title
                        implication
                        description
                        cause
                        mngmt_response
                        mngmt_response_doc_id
                        category
                        risk_rating
                        followup_audit_approach
                        recommendations {
                            id
                            sno
                            recommendation
                            status
                        }
                        mgmt_action_plan {
                            id
                            ref
                            sno
                            action
                            description
                            lead_person
                            support_person
                            start_date
                            end_date
                            document_id
                            rr_numbers
                            document_info
                            status
                        }
                    }

                    finding_observations {
                        audit_finding_observation_details {
                            id
                            name
                            description
                            status
                            risk_rating
                            post_fwp_obs
                        }
                        audit_program_detail {
                            id
                            objective {
                                id
                                ref
                                objective
                                description
                            }
                            audit_program {
                                id
                            }
                            procedure {
                                id
                                name
                                ref
                                description
                            }
                            test_assignements {
                                test {
                                    test
                                }
                                audit_test_result_detail {
                                    test_conclusion
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    summaryAuditableAreas: gql`
        query summaryAuditableAreas(
            $where: SummaryAuditableAreaWhereInput
            $orderBy: [SummaryAuditableAreaOrderByInput!]
            $take: Int
            $skip: Int
        ) {
            summaryAuditableAreas(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                status
                audit_period
                file_number
                sub_assignment_name
                mda_profile_id
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                mda_profile {
                    auditable_area
                }
                commencement_detail {
                    aap_detail {
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        aap_level_details {
                            classif_code
                            classif_code_obj {
                                audit_level_id
                                name
                            }
                        }
                    }
                }
            }
        }
    `,
    summaryAuditableArea: gql`
        query summaryAuditableArea($where: SummaryAuditableAreaWhereUniqueInput!) {
            summaryAuditableArea(where: $where) {
                id
                ref
                mda_profile_id
                commencement_detail_id
                file_number
                sub_assignment_name
                audit_period
                purpose
                governance_process
                risk_management_process
                control_env
                financial_arrangements
                fraud_consideration
                audit_criteria
                status
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                mda_profile {
                    auditable_area_code
                    auditable_area
                    id
                }
                commencement_detail {
                    aap_detail {
                        aap_level_details {
                            classif_code
                            classif_code_obj {
                                audit_level_id
                                name
                            }
                        }
                    }
                }
            }
        }
    `,
    summaryAuditableAreaCount: gql`
        query summaryAuditableAreaCount($where: SummaryAuditableAreaWhereInput) {
            summaryAuditableAreaCount(where: $where)
        }
    `,
    createOneSummaryAuditableArea: gql`
        mutation createOneSummaryAuditableArea($data: SummaryAuditableAreaCreateInput!) {
            createOneSummaryAuditableArea(data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    updateOneSummaryAuditableArea: gql`
        mutation updateOneSummaryAuditableArea($where: SummaryAuditableAreaWhereUniqueInput!, $data: SummaryAuditableAreaUpdateInput!) {
            updateOneSummaryAuditableArea(where: $where, data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    fetchAapDetailsInSummaryAuditaleArea: gql`
        query aapDetails($where: AAPDetailWhereInput) {
            aapDetails(where: $where) {
                mda_profile_def {
                    id
                    auditable_area
                }
                commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                    id
                    aap_detail_id
                    aap_detail {
                        mda_profile_def_id
                        aap_level_details {
                            classif_code_obj {
                                name
                            }
                        }
                    }
                    audit_commencement_form {
                        audit_type
                        name
                        ref
                        file_number
                        year_of_audit
                    }
                }
            }
        }
    `,
    fetchAapDetailsInSummaryAuditSubject: gql`
        query aapDetails($where: AAPDetailWhereInput) {
            aapDetails(where: $where) {
                mda_profile_def {
                    auditable_area
                    auditable_area_code
                    id
                }
                aap_level_details {
                    level_id
                    classif_code
                    level_alias
                    classif_code_obj {
                        name
                        audit_level_id
                    }
                }
                commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                    id
                    aap_detail {
                        mda_profile_def_id
                    }
                    audit_commencement_form {
                        audit_type
                        name
                        ref
                        file_number
                        year_of_audit
                    }
                }
            }
        }
    `,
    fetchRiskControlsWithModuleTask: gql`
        query riskDefs($where: RiskDefWhereInput) {
            riskDefs(where: $where) {
                name
                ref
                id
                audit_classif_codes {
                    classif_level
                    classif_code
                    classif_code_obj {
                        parent_id
                        name
                        audit_level_id
                    }
                }
                risk_control_mappings {
                    control_def {
                        name
                        ref
                        id
                    }
                }
            }
        }
    `,
    createOneSummaryAuditSubject: gql`
        mutation createOneSummaryAuditSubject($data: SummaryAuditSubjectCreateInput!) {
            createOneSummaryAuditSubject(data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    updateOneSummaryAuditSubject: gql`
        mutation updateOneSummaryAuditSubject($where: SummaryAuditSubjectWhereUniqueInput!, $data: SummaryAuditSubjectUpdateInput!) {
            updateOneSummaryAuditSubject(where: $where, data: $data) {
                id
                ref
                is_latest
                version_user
                version_no
                version_date
                status
            }
        }
    `,
    summaryAuditSubject: gql`
        query summaryAuditSubject($where: SummaryAuditSubjectWhereUniqueInput!) {
            summaryAuditSubject(where: $where) {
                id
                ref
                status
                is_latest
                is_effective
                version_date
                version_no
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                            id
                        }
                        aap_level_details {
                            aap_detail_id
                            classif_code
                            classif_code_obj {
                                name
                                audit_level_id
                            }
                        }
                    }
                    audit_commencement_form {
                        file_number
                        name
                        year_of_audit
                    }
                }
                summary_details {
                    id
                    summary_id
                    ref
                    risk_id
                    control_id
                    document_id
                }
                version_no
                version_user
            }
        }
    `,
    summaryAuditSubjects: gql`
        query summaryAuditSubjects(
            $where: SummaryAuditSubjectWhereInput!
            $orderBy: [SummaryAuditSubjectOrderByInput!]
            $take: Int
            $skip: Int
        ) {
            summaryAuditSubjects(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                status
                version_date
                is_latest
                is_effective
                commencement_detail {
                    aap_detail {
                        aap_item_id
                        aap_sub_assignments {
                            id
                            assignment_id
                        }
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                        aap_level_details {
                            classif_code_obj {
                                name
                                audit_level_id
                            }
                        }
                    }
                }
            }
        }
    `,
    summaryAuditSubjectCount: gql`
        query summaryAuditSubjectCount($where: SummaryAuditSubjectWhereInput) {
            summaryAuditSubjectCount(where: $where)
        }
    `,
    tiers: gql`
        query tiers {
            tiers {
                id
                name
            }
        }
    `,
    iaMasterData: gql`
        query iaMasterData($where: CountryWhereInput) {
            mdaAndDeputationOffice: mdaAndDeputationOffice {
                id
                name
                code
                full_code
                seg_def {
                    name
                    id
                }
                children {
                    id
                    name
                    code
                    full_code
                    seg_def {
                        name
                        id
                    }
                }
            }
            countries: countries(where: $where) {
                id
                name
                ccy
                ccy_code
                states {
                    id
                    name
                }
            }
            appSetting: appSetting(where: { id: "DESIGNATION_LEVEL" }) {
                id
                value1
                value2
            }
            admFinYears: admFinYears {
                master_start_date
            }
        }
    `,
    auditCommencementForms: gql`
        query auditCommencementForms(
            $where: AuditCommencementFormWhereInput!
            $orderBy: [AuditCommencementFormOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditCommencementForms(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                audit_type
                name
                comments
                vote
                scope
                limitation_of_scope
                file_number
                year_of_audit
                audit_authority
                status
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                commencement_details {
                    aap_detail_id
                    aap_detail {
                        aap_item_id
                        aap_sub_assignments {
                            id
                            assignment_id
                        }
                    }
                }
                objective_mappings {
                    id
                    objective {
                        id
                        objective
                        ref
                    }
                }
                audit_dates {
                    id
                    order
                    duration
                    description
                    start_date
                    end_date
                }
                resource_and_budget {
                    id
                    status
                    auditable_area
                }
                report_distribution {
                    id
                    department
                    designation
                    name
                    email
                }
                request_list {
                    id
                    document_details {
                        id
                        ref
                        title
                        description
                        aap_detail_id
                    }
                }
                adhoc_consulting_details {
                    id
                    introduction
                    background
                    scope
                    limitation_of_scope
                    support_expected
                    management_expectation
                }
                followup_commencement_details {
                    id
                    followup_audit_no
                    sub_assignment_ref
                    followup_assignment_ref
                    scope
                    scope_doc
                    limitation_of_scope
                }
                audit_dates_and_budget {
                    id
                    start_date
                    end_date
                    estimated_budget
                    time_budget
                    funding_source
                    time_budget
                    other_reason
                }
            }
        }
    `,
    auditCommencementForm: gql`
        query auditCommencementForm($where: AuditCommencementFormWhereUniqueInput!) {
            auditCommencementForm(where: $where) {
                id
                ref
                audit_type
                name
                vote
                status
                file_number
                year_of_audit
                audit_authority
                scope
                limitation_of_scope
                comments
                is_approved_edit
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                objective_mappings {
                    id
                    objective {
                        id
                        objective
                        ref
                    }
                }
                audit_dates {
                    id
                    order
                    duration
                    description
                    start_date
                    end_date
                }
                resource_and_budget {
                    id
                    status
                    auditable_area
                }
                report_distribution {
                    id
                    department
                    designation
                    name
                    email
                }
                request_list {
                    id
                    document_details {
                        id
                        ref
                        title
                        src_ref
                        description
                        aap_detail_id
                    }
                }
                adhoc_consulting_details {
                    id
                    introduction
                    background
                    scope
                    limitation_of_scope
                    support_expected
                    management_expectation
                }
                followup_commencement_details {
                    id
                    followup_audit_no
                    sub_assignment_ref
                    followup_assignment_ref
                    scope
                    scope_doc
                    limitation_of_scope
                }
                audit_dates_and_budget {
                    id
                    start_date
                    end_date
                    estimated_budget
                    time_budget
                    funding_source
                    time_budget
                    other_reason
                }
            }
        }
    `,
    createOneAuditCommencementForm: gql`
        mutation createOneAuditCommencementForm($data: AuditCommencementFormCreateInput!) {
            createOneAuditCommencementForm(data: $data) {
                id
            }
        }
    `,
    updateOneAuditCommencementForm: gql`
        mutation updateOneAuditCommencementForm($data: AuditCommencementFormUpdateInput!, $where: AuditCommencementFormWhereUniqueInput!) {
            updateOneAuditCommencementForm(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditCommencementForm: gql`
        mutation deleteOneAuditCommencementForm($where: AuditCommencementFormWhereUniqueInput!) {
            deleteOneAuditCommencementForm(where: $where) {
                id
            }
        }
    `,
    auditCommencementFormCount: gql`
        query auditCommencementFormCount($where: AuditCommencementFormWhereInput) {
            auditCommencementFormCount(where: $where)
        }
    `,
    auditAssignmentPlanCount: gql`
        query auditAssignmentPlanCount($where: AuditAssignmentPlanWhereInput) {
            auditAssignmentPlanCount(where: $where)
        }
    `,
    auditorAvailabilityWRTCalenderBlocking: gql`
        query auditorAvailabilityWRTCalenderBlocking($where: AuditorAssignmentDetailWhereInput!) {
            auditorAvailabilityWRTCalenderBlocking(where: $where)
        }
    `,
    getInherentRisks: gql`
        query inherentRisks(
            $where: InherentRiskWhereInput
            $orderBy: [InherentRiskOrderByInput!]
            $take: Int
            $skip: Int
            $cursor: InherentRiskWhereUniqueInput
        ) {
            inherentRisks(where: $where, orderBy: $orderBy, skip: $skip, take: $take, cursor: $cursor) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        id
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                inherent_details {
                    id
                    inherent_risk_id
                    audit_scope_detail_id
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    audit_scope_detail {
                        objective {
                            objective
                        }
                    }
                }
            }
        }
    `,
    getInherentRiskByID: gql`
        query inherentRisk($where: InherentRiskWhereUniqueInput!) {
            inherentRisk(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        id
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                inherent_details {
                    id
                    inherent_risk_id
                    audit_scope_detail_id
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    audit_scope_detail {
                        objective {
                            id
                            objective
                        }
                        risk {
                            id
                            name
                            description
                        }
                        control {
                            id
                            name
                            description
                        }
                    }
                }
            }
        }
    `,
    createOneInherentRisk: gql`
        mutation createOneInherentRisk($data: InherentRiskCreateInput!) {
            createOneInherentRisk(data: $data) {
                id
            }
        }
    `,
    updateOneInherentRisk: gql`
        mutation updateOneInherentRisk($data: InherentRiskUpdateInput!, $where: InherentRiskWhereUniqueInput!) {
            updateOneInherentRisk(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneInherentRisk: gql`
        mutation deleteOneInherentRisk($where: InherentRiskWhereUniqueInput!) {
            deleteOneInherentRisk(where: $where) {
                id
            }
        }
    `,
    inherentRiskCount: gql`
        query inherentRiskCount($where: InherentRiskWhereInput) {
            inherentRiskCount(where: $where)
        }
    `,
    auditScopeCount: gql`
        query auditScopeCount($where: AuditScopeWhereInput) {
            auditScopeCount(where: $where)
        }
    `,
    assignmentActivities: gql`
        query assignmentActivities($where: AssignmentActivityWhereInput!, $orderBy: [AssignmentActivityOrderByInput!]) {
            assignmentActivities(where: $where, orderBy: $orderBy) {
                name
                audit_type
                order
            }
        }
    `,
    auditableAreaSummarizedStatus: gql`
        query auditableAreaSummarizedStatus($item_id: String, $assignment_id: String) {
            auditableAreaSummarizedStatus(item_id: $item_id, assignment_id: $assignment_id)
        }
    `,
    auditScopes: gql`
        query auditScopes($where: AuditScopeWhereInput!, $orderBy: [AuditScopeOrderByInput!], $skip: Int, $take: Int) {
            auditScopes(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        id
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                audit_scope_details {
                    id
                    risk_id
                    control_id
                    scope_id
                    risk {
                        id
                        ref
                        name
                        description
                        category
                        status
                        is_active
                    }
                    control {
                        id
                        name
                        ref
                        description
                        control_type
                        category
                        status
                    }
                    objective {
                        id
                        ref
                        description
                        objective
                        status
                        audit_classification
                        is_active
                    }
                }
            }
        }
    `,
    auditScope: gql`
        query auditScope($where: AuditScopeWhereUniqueInput!) {
            auditScope(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        id
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                audit_scope_details {
                    id
                    risk_id
                    control_id
                    scope_id
                    risk {
                        id
                        ref
                        name
                        description
                        category
                        status
                        is_active
                    }
                    control {
                        id
                        name
                        ref
                        description
                        control_type
                        category
                        status
                    }
                    objective {
                        id
                        ref
                        description
                        objective
                        status
                        audit_classification
                        is_active
                    }
                }
            }
        }
    `,
    createOneAuditScope: gql`
        mutation createOneAuditScope($data: AuditScopeCreateInput!) {
            createOneAuditScope(data: $data) {
                id
            }
        }
    `,
    updateOneAuditScope: gql`
        mutation updateOneAuditScope($data: AuditScopeUpdateInput!, $where: AuditScopeWhereUniqueInput!) {
            updateOneAuditScope(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditScope: gql`
        mutation deleteOneAuditScope($where: AuditScopeWhereUniqueInput!) {
            deleteOneAuditScope(where: $where) {
                id
            }
        }
    `,
    getRiskDefsForRiskAssessmentAndControls: gql`
        query riskDefs($where: RiskDefWhereInput, $orderBy: [RiskDefOrderByInput!], $skip: Int, $take: Int) {
            riskDefs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                name
                description
                category
                status
                is_active
                version_date
                risk_control_mappings {
                    control_def {
                        id
                        ref
                        name
                        description
                        control_type
                        is_active
                    }
                }
                objective_mappings {
                    id
                    objective {
                        id
                        objective
                        ref
                        description
                    }
                    risk_id
                    control_id
                    objective_id
                }
            }
        }
    `,
    aapDetailsForRiskAssessmentAndControls: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                mda_profile_def {
                    id
                    mda
                    mda_code
                    auditable_area
                    auditable_area_code
                }
                objective_mappings {
                    objective {
                        objective
                        description
                        id
                        ref
                    }
                }
                aap_level_details {
                    aap_detail_id
                    level_id
                    classif_code
                    level_alias
                    classif_code
                    classif_code_obj {
                        name
                        parent_id
                        audit_level_id
                    }
                }
                commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                    id
                    inherent_risks {
                        id
                        inherent_details {
                            id
                            audit_scope_detail {
                                id
                                risk {
                                    id
                                    ref
                                    name
                                    description
                                    category
                                    status
                                    is_active
                                }
                                control {
                                    id
                                    name
                                    ref
                                    description
                                    control_type
                                    category
                                    status
                                }
                                objective {
                                    id
                                    ref
                                    description
                                    objective
                                    status
                                    is_active
                                }
                            }
                        }
                    }
                    audit_scope {
                        id
                        audit_scope_details {
                            id
                            objective {
                                id
                                objective
                            }
                        }
                    }
                }
            }
        }
    `,
    auditScopesForInherent: gql`
        query auditScopeDetails($where: AuditScopeDetailWhereInput!) {
            auditScopeDetails(where: $where) {
                id
                risk_id
                control_id
                objective_id
                audit_scope {
                    commencement_detail_id
                    id
                    commencement_detail {
                        aap_detail {
                            id
                            aap_item {
                                audit_type
                            }
                        }
                    }
                }
                risk {
                    id
                    name
                    description
                }
                control {
                    id
                    ref
                    name
                    description
                }
                objective {
                    id
                    objective
                    description
                }
            }
        }
    `,
    requestListResponseCount: gql`
        query requestListResponseCount($where: RequestListResponseWhereInput) {
            requestListResponseCount(where: $where)
        }
    `,
    deleteOneRequestListResponse: gql`
        mutation deleteOneRequestListResponse($where: RequestListResponseWhereUniqueInput!) {
            deleteOneRequestListResponse(where: $where)
        }
    `,
    requestLists: gql`
        query requestLists($where: RequestListWhereInput, $orderBy: [RequestListOrderByInput!], $skip: Int, $take: Int) {
            requestLists(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                commencement {
                    commencement_details {
                        aap_detail {
                            aap_sub_assignments {
                                assignment {
                                    followup_assignment_ref
                                    ref
                                }
                            }
                            aap_item {
                                ref
                            }
                            id
                            mda_profile_def {
                                auditable_area
                                auditable_area_code
                            }
                        }
                    }
                    request_list {
                        id
                        document_details {
                            title
                            ref
                            description
                        }
                    }
                    name
                }
                document_details {
                    id
                    title
                    description
                    aap_detail_id
                }
            }
        }
    `,
    requestListResponses: gql`
        query requestListResponses(
            $where: RequestListResponseWhereInput
            $orderBy: [RequestListResponseOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            requestListResponses(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                aap_detail_id
                request_list_id
                ref
                status
                is_active
                is_latest
                is_effective
                version_user
                version_date
                version_no
                aap_detail {
                    aap_item_id
                    commencement_detail {
                        audit_commencement_form {
                            name
                            ref
                            file_number
                        }
                    }
                    aap_sub_assignments {
                        assignment_id
                        assignment {
                            ref
                            followup_assignment_ref
                        }
                    }
                    aap_item {
                        ref
                    }
                    id
                    mda_profile_def {
                        auditable_area
                        auditable_area_code
                    }
                }
                req_list_resp_detail {
                    id
                    remarks
                    document_id
                    document_detail_id
                    req_list_resp_id
                }
                request_list {
                    id
                    commencement_id
                    document_details {
                        id
                        title
                        description
                        request_id
                    }
                }
            }
        }
    `,
    requestListResponse: gql`
        query requestListResponse($where: RequestListResponseWhereUniqueInput!) {
            requestListResponse(where: $where) {
                id
                aap_detail_id
                request_list_id
                ref
                status
                is_active
                version_user
                version_date
                version_no
                aap_detail {
                    id
                    mda_profile_def {
                        auditable_area
                        auditable_area_code
                    }
                }
                req_list_resp_detail {
                    id
                    remarks
                    document_id
                    document_detail_id
                    req_list_resp_id
                    uploaded_date
                }
                request_list {
                    id
                    commencement_id
                    document_details {
                        id
                        title
                        description
                        request_id
                    }
                }
            }
        }
    `,
    createOneRequestListResponse: gql`
        mutation createOneRequestListResponse($data: RequestListResponseCreateInput!) {
            createOneRequestListResponse(data: $data) {
                id
                status
            }
        }
    `,
    createManyRequestListResponse: gql`
        mutation createManyRequestListResponse($data: RequestListResponseBulkCreateInput!) {
            createManyRequestListResponse(data: $data)
        }
    `,
    updateOneRequestListResponse: gql`
        mutation updateOneRequestListResponse($data: RequestListResponseUpdateInput!, $where: RequestListResponseWhereUniqueInput!) {
            updateOneRequestListResponse(data: $data, where: $where) {
                id
            }
        }
    `,

    aapAuditorDetails: gql`
        query aapAuditorDetails($where: AAPAuditorDetailWhereInput!) {
            aapAuditorDetails(where: $where) {
                aap_auditor_id
                user_id
                name
            }
        }
    `,
    controlAssessments: gql`
        query controlAssessments($where: ControlAssessmentWhereInput!, $orderBy: [ControlAssessmentOrderByInput!], $skip: Int, $take: Int) {
            controlAssessments(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                assessment_details {
                    id
                    inherent_risk {
                        id
                        audit_scope_detail {
                            objective {
                                id
                                ref
                                description
                                objective
                            }
                        }
                    }
                }
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        id
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                    inherent_risks {
                        id
                        inherent_details {
                            id
                        }
                    }
                }
            }
        }
    `,
    controlAssessment: gql`
        query controlAssessment($where: ControlAssessmentWhereUniqueInput!) {
            controlAssessment(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                assessment_details {
                    id
                    response
                    alternate_control
                    inherent_risk {
                        id
                        audit_scope_detail {
                            id
                            risk {
                                id
                                ref
                                name
                                description
                                category
                                status
                                is_active
                            }
                            control {
                                id
                                name
                                ref
                                description
                                control_type
                                category
                                status
                            }
                            objective {
                                id
                                ref
                                description
                                objective
                                status
                                is_active
                            }
                        }
                    }
                }
                commencement_detail {
                    id
                    aap_detail {
                        id
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                    inherent_risks {
                        id
                        inherent_details {
                            id
                        }
                    }
                }
            }
        }
    `,
    createOneControlAssessment: gql`
        mutation createOneControlAssessment($data: ControlAssessmentCreateInput!) {
            createOneControlAssessment(data: $data) {
                id
            }
        }
    `,
    updateOneControlAssessment: gql`
        mutation updateOneControlAssessment($data: ControlAssessmentUpdateInput!, $where: ControlAssessmentWhereUniqueInput!) {
            updateOneControlAssessment(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneControlAssessment: gql`
        mutation deleteOneControlAssessment($where: ControlAssessmentWhereUniqueInput!) {
            deleteOneControlAssessment(where: $where) {
                id
            }
        }
    `,
    controlAssessmentCount: gql`
        query controlAssessmentCount($where: ControlAssessmentWhereInput) {
            controlAssessmentCount(where: $where)
        }
    `,
    auditScopeDetails: gql`
        query auditScopeDetails($where: AuditScopeDetailWhereInput) {
            auditScopeDetails(where: $where) {
                id
                inherent_risk_details {
                    id
                }
                risk {
                    id
                    ref
                    name
                    description
                    category
                    status
                    is_active
                }
                control {
                    id
                    name
                    ref
                    description
                    control_type
                    category
                    status
                }
                objective {
                    id
                    ref
                    description
                    objective
                    status
                    is_active
                }
            }
        }
    `,
    inherentRisksForControlAssessments: gql`
        query inherentRisks($where: InherentRiskWhereInput) {
            inherentRisks(where: $where) {
                id
                inherent_details {
                    id
                    audit_scope_detail {
                        id
                        risk {
                            id
                            ref
                            name
                            description
                            category
                            status
                            is_active
                        }
                        control {
                            id
                            name
                            ref
                            description
                            control_type
                            category
                            status
                        }
                        objective {
                            id
                            ref
                            description
                            objective
                            status
                            is_active
                        }
                    }
                }
            }
        }
    `,
    getGuildelineTestByObjectiveId: gql`
        query auditOpinionGuidelineConfs($where: AuditOpinionGuidelineConfWhereInput) {
            auditOpinionGuidelineConfs(where: $where) {
                audit_objective {
                    objective
                }
                audit_opinion_guideline_details {
                    slab_no
                    txn_fail_percent_start
                    txn_fail_percent_end
                    conclusion_opinion
                    description
                }
            }
        }
    `,
    getauditCommencementForms: gql`
        query auditCommencementForms($where: AuditCommencementFormWhereInput) {
            auditCommencementForms(where: $where) {
                id
                ref
                name
                file_number
                year_of_audit
                commencement_details {
                    audit_commencement_form {
                        id
                        audit_dates {
                            description #phase
                            end_date
                            # duration #days
                        }
                    }
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                            mda_code
                            management_contacts {
                                employee_id
                            }
                        }
                        aap_level_details {
                            level_id
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                            }
                        }
                        financial_resources {
                            # OPE cost
                            expense_details {
                                planning
                                field_work
                                reporting
                                review
                            }
                            rate_card {
                                rate_per_unit
                                unit
                            }
                            expense_head
                        }
                        objective_mappings {
                            objective {
                                objective
                                description
                                is_active
                                id
                                ref
                            }
                        }
                    }
                }
            }
        }
    `,
    getAuditorDetails: gql`
        query aapAuditorDetails($where: AAPAuditorDetailWhereInput) {
            aapAuditorDetails(where: $where) {
                user_id
                name
                role
                days_assigned
                prof_charges
                expense_details {
                    planning
                    field_work
                    reporting
                    review
                }
                aap_auditor {
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                        }
                    }
                }
                user_info
            }
        }
    `,
    aapObjeciveMappings: gql`
        query aapObjeciveMappings($where: AAPObjeciveMappingWhereInput) {
            aapObjeciveMappings(where: $where) {
                objective_id
                objective {
                    id
                    objective
                    description
                }
                guideline_openion {
                    audit_objective_id
                    audit_opinion_guideline_details {
                        slab_no
                        txn_fail_percent_start
                        txn_fail_percent_end
                        conclusion_opinion
                        description
                    }
                }
            }
        }
    `,
    createOneAuditAssignmentPlan: gql`
        mutation createOneAuditAssignmentPlan($data: AuditAssignmentPlanCreateInput!) {
            createOneAuditAssignmentPlan(data: $data) {
                id
            }
        }
    `,
    updateOneAuditAssignmentPlan: gql`
        mutation updateOneAuditAssignmentPlan($data: AuditAssignmentPlanUpdateInput!, $where: AuditAssignmentPlanWhereUniqueInput!) {
            updateOneAuditAssignmentPlan(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditAssignmentPlan: gql`
        mutation deleteOneAuditAssignmentPlan($where: AuditAssignmentPlanWhereUniqueInput!) {
            deleteOneAuditAssignmentPlan(where: $where) {
                id
            }
        }
    `,
    auditAssignmentPlan: gql`
        query auditAssignmentPlan($where: AuditAssignmentPlanWhereUniqueInput!) {
            auditAssignmentPlan(where: $where) {
                id
                ref
                status
                version_date
                version_user
                version_no
                background_info
                audit_commencement {
                    id
                    name
                    file_number
                    year_of_audit
                    audit_authority
                    objective_mappings {
                        objective_id
                        objective {
                            objective
                        }
                    }
                    commencement_details {
                        aap_detail {
                            aap_level_details {
                                classif_code
                                level_alias
                                level_id
                                classif_code_obj {
                                    name
                                    parent_id
                                }
                            }
                            mda_profile_def {
                                auditable_area
                                mda_code
                            }
                            financial_resources {
                                expense_head
                                rate_card {
                                    unit
                                    rate_per_unit
                                }
                                expense_details {
                                    planning
                                    field_work
                                    reporting
                                    review
                                }
                            }
                        }
                    }
                }
                contacts {
                    sno
                    auditable_area
                    designition
                    name
                    email
                    phone
                }
                team_composition {
                    sno
                    auditable_area
                    role
                    auditor_name
                    email
                    phone
                }
                time_fin_resources {
                    sno
                    phase
                    days
                    resource_cost
                    ope_cost
                    tottal_cost
                    target_date
                }
            }
        }
    `,
    auditAssignmentPlans: gql`
        query auditAssignmentPlans(
            $where: AuditAssignmentPlanWhereInput!
            $orderBy: [AuditAssignmentPlanOrderByInput!]
            $take: Int
            $skip: Int
        ) {
            auditAssignmentPlans(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                status
                version_date
                background_info
                audit_commencement {
                    name
                    file_number
                    year_of_audit
                    audit_authority
                    commencement_details {
                        aap_detail {
                            aap_item_id
                            aap_sub_assignments {
                                assignment_id
                            }
                        }
                    }
                }
            }
        }
    `,
    createOneAuditProgram: gql`
        mutation createOneAuditProgram($data: AuditProgramCreateInput!) {
            createOneAuditProgram(data: $data) {
                id
            }
        }
    `,
    updateOneAuditProgram: gql`
        mutation updateOneAuditProgram($data: AuditProgramUpdateInput!, $where: AuditProgramWhereUniqueInput!) {
            updateOneAuditProgram(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditProgram: gql`
        mutation deleteOneAuditProgram($where: AuditProgramWhereUniqueInput!) {
            deleteOneAuditProgram(where: $where) {
                id
            }
        }
    `,
    fetchAapDetailsFollowUpAuditProgram: gql`
        query aapDetails($where: AAPDetailWhereInput) {
            aapDetails(where: $where) {
                mda_profile_def {
                    id
                    auditable_area
                    office_code
                }
                commencement_detail {
                    commencement_id
                    id
                    aap_detail_id
                    aap_detail {
                        mda_profile_def_id
                    }
                    audit_commencement_form {
                        id
                        ref
                        name
                        file_number
                        year_of_audit
                        audit_type
                        audit_authority
                    }
                    aap_detail {
                        auditors {
                            aap_auditor_details(where: { is_active: { equals: true } }) {
                                name
                                user_id
                                role
                            }
                        }
                    }
                }
                finding {
                    audit_finding_details {
                        audit_finding_id
                        title
                        description
                        audit_finding {
                            ref
                            audit_finding_details {
                                mgmt_action_plan {
                                    action
                                    description
                                    lead_person
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    fetchAuditFindingObservations: gql`
        query auditFindingObservations($where: AuditFindingObservationWhereInput) {
            auditFindingObservations(where: $where) {
                id
                audit_program_detail {
                    aap_detail_id
                    objective_id
                    objective {
                        id
                        ref
                        objective
                        description
                    }
                    procedure_id
                    procedure {
                        id
                        ref
                        name
                        description
                        tests {
                            id
                            test
                            ref
                        }
                    }
                    control_id
                    control {
                        id
                        ref
                        name
                        description
                    }
                }
            }
        }
    `,
    fetchProceduresMasterData: gql`
        query auditProcedureDefs($where: AuditProcedureDefWhereInput) {
            auditProcedureDefs(where: $where) {
                id
                ref
                name
                description
                tests {
                    test
                    id
                    ref
                    procedure_id
                }
            }
        }
    `,
    auditFindingCount: gql`
        query auditFindingCount($where: AuditFindingWhereInput) {
            auditFindingCount(where: $where)
        }
    `,
    residualRiskAssessments: gql`
        query residualRiskAssessments(
            $where: ResidualRiskAssessmentWhereInput!
            $orderBy: [ResidualRiskAssessmentOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            residualRiskAssessments(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                residual_risk_assessment_details {
                    id
                    assessment_id
                    adequacy
                    effectiveness
                    effectiveness_reason
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    control_score
                    control_assessment_detail {
                        id
                        assessment_id
                        alternate_control
                        response
                    }
                    residual_risk_assessment {
                        id
                    }
                }
                commencement_detail {
                    id
                    aap_detail_id
                    inherent_risks {
                        inherent_details {
                            audit_scope_detail {
                                objective {
                                    objective
                                }
                            }
                        }
                    }
                    aap_detail {
                        id
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
            }
        }
    `,
    residualRiskAssessment: gql`
        query residualRiskAssessment($where: ResidualRiskAssessmentWhereUniqueInput!) {
            residualRiskAssessment(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                residual_risk_assessment_details {
                    id
                    assessment_id
                    adequacy
                    effectiveness
                    effectiveness_reason
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    control_score
                    control_assessment_detail {
                        id
                        assessment_id
                        alternate_control
                        response
                    }
                    residual_risk_assessment {
                        id
                    }
                }
                commencement_detail {
                    id
                }
            }
        }
    `,
    createOneResidualRiskAssessment: gql`
        mutation createOneResidualRiskAssessment($data: ResidualRiskAssessmentCreateInput!) {
            createOneResidualRiskAssessment(data: $data) {
                id
            }
        }
    `,
    updateOneResidualRiskAssessment: gql`
        mutation updateOneResidualRiskAssessment(
            $data: ResidualRiskAssessmentUpdateInput!
            $where: ResidualRiskAssessmentWhereUniqueInput!
        ) {
            updateOneResidualRiskAssessment(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneResidualRiskAssessment: gql`
        mutation deleteOneResidualRiskAssessment($where: ResidualRiskAssessmentWhereUniqueInput!) {
            deleteOneResidualRiskAssessment(where: $where) {
                id
            }
        }
    `,
    residualRiskCount: gql`
        query residualRiskCount($where: ResidualRiskAssessmentWhereInput) {
            residualRiskCount(where: $where)
        }
    `,
    riskAssessmentConclusions: gql`
        query riskAssessmentConclusions(
            $where: RiskAssessmentConclusionWhereInput!
            $orderBy: [RiskAssessmentConclusionOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            riskAssessmentConclusions(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                audit_way_forward
                remarks
                status
                is_latest
                version_no
                version_user
                version_date
                risk_assessment_conclusion_details {
                    id
                    risk_id
                    control_id
                    control_score
                    audit_method
                    audit_decision
                    risk {
                        id
                        name
                    }
                    control {
                        id
                        name
                    }
                    risk_assessment_con_codes {
                        id
                        level_id
                        level_code
                        classif_code_obj {
                            audit_level_id
                            parent_id
                            name
                        }
                    }
                }
                commencement_detail {
                    id
                    aap_detail_id
                    aap_detail {
                        aap_item_id
                        aap_sub_assignments {
                            assignment_id
                        }
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
            }
        }
    `,
    riskAssessmentConclusion: gql`
        query riskAssessmentConclusion($where: RiskAssessmentConclusionWhereUniqueInput!) {
            riskAssessmentConclusion(where: $where) {
                id
                ref
                audit_way_forward
                remarks
                status
                version_no
                version_user
                version_date
                risk_assessment_conclusion_details {
                    id
                    risk_id
                    control_id
                    control_score
                    audit_method
                    audit_decision
                    risk {
                        id
                        name
                    }
                    control {
                        id
                        name
                    }
                    risk_assessment_con_codes {
                        id
                        level_id
                        level_code
                        classif_code_obj {
                            audit_level_id
                            parent_id
                            name
                        }
                    }
                }
                commencement_detail {
                    id
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
            }
        }
    `,
    createOneRiskAssessmentConclusion: gql`
        mutation createOneRiskAssessmentConclusion($data: RiskAssessmentConclusionCreateInput!) {
            createOneRiskAssessmentConclusion(data: $data) {
                id
            }
        }
    `,
    updateOneRiskAssessmentConclusion: gql`
        mutation updateOneRiskAssessmentConclusion(
            $data: RiskAssessmentConclusionUpdateInput!
            $where: RiskAssessmentConclusionWhereUniqueInput!
        ) {
            updateOneRiskAssessmentConclusion(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneRiskAssessmentConclusion: gql`
        mutation deleteOneRiskAssessmentConclusion($where: RiskAssessmentConclusionWhereUniqueInput!) {
            deleteOneRiskAssessmentConclusion(where: $where) {
                id
            }
        }
    `,
    riskAssessmentConclusionCount: gql`
        query riskAssessmentConclusionCount($where: RiskAssessmentConclusionWhereInput) {
            riskAssessmentConclusionCount(where: $where)
        }
    `,
    auditScopesForResidualRiskAssessment: gql`
        query auditScopeDetails($where: AuditScopeDetailWhereInput!) {
            auditScopeDetails(where: $where) {
                id
                risk_id
                control_id
                objective_id
                inherent_risk_details {
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    control_assessment_details {
                        id
                        response
                    }
                }
                audit_scope {
                    commencement_detail_id
                    id
                    commencement_detail {
                        aap_detail {
                            id
                            aap_item {
                                audit_type
                            }
                        }
                    }
                }
                risk {
                    id
                    name
                    description
                    category
                }
                control {
                    id
                    ref
                    name
                    description
                }
                objective {
                    id
                    objective
                    description
                }
            }
        }
    `,
    auditObservationDetail: gql`
        query auditCommencementForms($where: AuditCommencementFormWhereInput!) {
            auditCommencementForms(where: $where) {
                ref
                name
                id
                file_number
                year_of_audit
                commencement_details {
                    id
                    commencement_id
                    aap_detail {
                        aap_sub_assignments {
                            assignment {
                                ref
                                followup_assignment_ref
                            }
                        }
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                            mda_code
                            id
                            management_contacts {
                                employee_id
                            }
                        }
                        aap_level_details {
                            level_id
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                            }
                        }
                        classification
                        objective_mappings {
                            is_deleted
                            objective {
                                id
                                objective
                            }
                        }
                    }
                }
                audit_programs {
                    details {
                        id
                    }
                }
            }
        }
    `,
    auditProgramDetails: gql`
        query auditProgramDetails($where: AuditProgramDetailWhereInput!) {
            auditProgramDetails(where: $where) {
                id
                audit_program {
                    id
                }
                procedure {
                    id
                    name
                    description
                    status
                }
                objective {
                    id
                    objective
                }
                test_assignements {
                    test {
                        test
                    }
                    audit_test_result_detail {
                        test_conclusion
                    }
                }
            }
        }
    `,
    auditFindings: gql`
        query auditFindings($where: AuditFindingWhereInput!, $orderBy: [AuditFindingOrderByInput!], $skip: Int, $take: Int) {
            auditFindings(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                version_no
                version_user
                version_date
                status
                is_effective
                recurrent_finding
                finding_status
                finding_remarks
                commencement_detail {
                    audit_commencement_form {
                        file_number
                        year_of_audit
                        name
                    }
                }
            }
        }
    `,

    auditFindingsData: gql`
        query auditFindings($where: AuditFindingWhereInput) {
            auditFindings(where: $where) {
                id
                ref
                audit_finding_details {
                    title
                    description
                    mgmt_action_plan {
                        action
                        description
                    }
                }
                commencement_detail {
                    aap_detail {
                        id
                        aap_sub_assignments {
                            assignment {
                                ref
                            }
                        }
                        aap_item {
                            ref
                        }
                        classification
                        mda_profile_def {
                            mda_code
                            auditable_area
                            auditable_area_code
                            id
                        }
                        aap_level_details {
                            level_alias
                            classif_code
                            level_id
                            aap_detail_id
                            classif_code_obj {
                                name
                                parent_id
                                id
                            }
                        }
                    }
                }
            }
        }
    `,
    auditFinding: gql`
        query auditFinding($where: AuditFindingWhereUniqueInput!) {
            auditFinding(where: $where) {
                ref
                id
                version_no
                version_user
                version_date
                is_latest
                finding_status
                finding_remarks
                recurrent_finding
                parent_finding {
                    id
                    ref
                }
                parent_finding_id
                audit_finding_details {
                    document_info
                    criteria_observation
                    title
                    implication
                    description
                    cause
                    mngmt_response
                    mngmt_response_doc_id
                    category
                    risk_rating
                    followup_audit_approach
                    recommendations {
                        sno
                        recommendation
                        status
                    }
                    mgmt_action_plan {
                        sno
                        ref
                        action
                        description
                        lead_person
                        support_person
                        start_date
                        end_date
                        document_id
                        rr_numbers
                        document_info
                        status
                    }
                }
                commencement_detail {
                    id
                    aap_detail {
                        aap_sub_assignments {
                            assignment {
                                followup_assignment_ref
                            }
                        }
                        mda_profile_def {
                            auditable_area_code
                            auditable_area
                        }
                    }
                    audit_commencement_form {
                        file_number
                        year_of_audit
                        name
                    }
                    reports(where: { is_effective: { equals: true }, report_type: { equals: "Final Report" } }) {
                        report_type
                        is_effective
                        is_latest
                    }
                    audit_meeting_details(
                        where: { audit_meeting: { is_effective: { equals: true }, meeting_type: { equals: "Closing Meeting" } } }
                    ) {
                        audit_meeting {
                            meeting_type
                        }
                    }
                }
                finding_observations {
                    audit_finding_observation_details {
                        id
                        name
                        description
                        post_fwp_obs
                        risk_rating
                        status
                    }
                    audit_program_detail {
                        id
                        objective {
                            id
                            ref
                            objective
                            description
                        }
                        audit_program {
                            id
                        }
                        procedure {
                            id
                            name
                            ref
                            description
                        }
                        test_assignements {
                            test {
                                test
                            }
                            audit_test_result_detail {
                                test_conclusion
                            }
                        }
                    }
                }
            }
        }
    `,
    createManyAuditFinding: gql`
        mutation createManyAuditFinding($data: AuditFindingBulkCreateInput!) {
            createManyAuditFinding(data: $data)
        }
    `,
    createOneAuditFinding: gql`
        mutation createOneAuditFinding($data: AuditFindingCreateInput!) {
            createOneAuditFinding(data: $data) {
                id
            }
        }
    `,
    updateOneAuditFinding: gql`
        mutation updateOneAuditFinding($data: AuditFindingUpdateInput!, $where: AuditFindingWhereUniqueInput!) {
            updateOneAuditFinding(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditFinding: gql`
        mutation deleteOneAuditFinding($where: AuditFindingWhereUniqueInput!) {
            deleteOneAuditFinding(where: $where) {
                id
            }
        }
    `,
    controlAssessmentsForResidualRiskAssessment: gql`
        query controlAssessmentDetails($where: ControlAssessmentDetailWhereInput) {
            controlAssessmentDetails(where: $where) {
                id
                response
                inherent_risk {
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    audit_scope_detail {
                        audit_scope {
                            commencement_detail_id
                        }
                        control {
                            id
                            name
                            description
                        }
                        risk {
                            id
                            ref
                            name
                            category
                            description
                        }
                    }
                }
            }
        }
    `,
    summaryAuditSubjectCodes: gql`
        query summaryAuditSubjectCodes($where: SummaryAuditSubjectCodeWhereInput) {
            summaryAuditSubjectCodes(where: $where) {
                summary_detail {
                    risk_id
                    summary_audit_subject {
                        commencement_detail {
                            aap_detail {
                                aap_level_details {
                                    classif_code
                                    classif_code_obj {
                                        audit_level_id
                                        name
                                        parent_id
                                    }
                                }
                            }
                        }
                    }
                }
                level_id
                level_code
                classif_code_obj {
                    name
                    parent_id
                }
            }
        }
    `,
    residualRiskAssessmentDetails: gql`
        query residualRiskAssessmentDetails($where: ResidualRiskAssessmentDetailWhereInput) {
            residualRiskAssessmentDetails(where: $where) {
                id
                control_score
                control_assessment_detail {
                    inherent_risk {
                        audit_scope_detail {
                            risk {
                                id
                                name
                            }
                            control {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    `,
    getResidualRiskAssessmentById: gql`
        query residualRiskAssessment($where: ResidualRiskAssessmentWhereUniqueInput!) {
            residualRiskAssessment(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    aap_detail {
                        aap_level_details {
                            aap_detail_id
                            level_id
                            classif_code
                            level_alias
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                                audit_level_id
                            }
                        }
                        mda_profile_def {
                            id
                            mda_code
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                residual_risk_assessment_details {
                    adequacy
                    adequacy_reason
                    effectiveness
                    effectiveness_reason
                    likelihood
                    likelihood_reason
                    impact
                    impact_reason
                    total_score
                    control_score
                    id
                    control_assessment_detail {
                        id
                        response
                        inherent_risk {
                            id
                            likelihood_reason
                            likelihood
                            impact
                            impact_reason
                            total_score
                            audit_scope_detail {
                                audit_scope {
                                    commencement_detail_id
                                }
                                risk {
                                    id
                                    name
                                    description
                                    category
                                }
                                control {
                                    id
                                    name
                                    description
                                    category
                                }
                                objective {
                                    id
                                    description
                                    objective
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    getAuditObjFilterCommencementForms: gql`
        query auditCommencementForms($where: AuditCommencementFormWhereInput) {
            auditCommencementForms(where: $where) {
                id
                name
                file_number
                year_of_audit
                audit_dates {
                    start_date
                    end_date
                }
                commencement_details {
                    risk_assessment_conclusion {
                        status
                        audit_way_forward
                    }
                    aap_detail {
                        id
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                            office_code
                            id
                        }
                        aap_level_details {
                            level_id
                            classif_code
                            classif_code_obj {
                                name
                                parent_id
                            }
                        }
                        objective_mappings {
                            objective {
                                objective
                                description
                                id
                            }
                        }
                        auditors {
                            aap_auditor_details(where: { is_active: { equals: true } }) {
                                name
                                user_id
                            }
                        }
                    }
                }
            }
        }
    `,
    auditObjectiveTableScopeDetails: gql`
        query auditScopeDetailsWrtClosedRisks($where: AuditScopeDetailWhereInput) {
            auditScopeDetailsWrtClosedRisks(where: $where) {
                audit_scope {
                    commencement_detail {
                        aap_detail_id
                    }
                }
                objective {
                    id
                    objective
                    description
                }
                control {
                    id
                    name
                    description
                    audit_procedure_details(where: { procedure_def: { is_effective: { equals: true } } }) {
                        procedure_def {
                            id
                            ref
                            name
                            description
                            tests {
                                id
                                ref
                                test
                            }
                        }
                    }
                }
            }
        }
    `,
    auditProgram: gql`
        query auditProgram($where: AuditProgramWhereUniqueInput!) {
            auditProgram(where: $where) {
                id
                ref
                audit_commencement_id
                status
                version_no
                version_user
                is_effective
                is_latest
                audit_commencement {
                    ref
                    id
                    name
                    file_number
                    year_of_audit

                    commencement_details {
                        aap_detail {
                            aap_sub_assignments {
                                assignment {
                                    followup_assignment_ref
                                }
                            }
                            auditors {
                                aap_auditor_details {
                                    name
                                    user_id
                                }
                            }
                            mda_profile_def {
                                auditable_area
                                auditable_area_code
                                office_code
                                id
                            }
                            finding {
                                ref
                                id
                                audit_finding_details {
                                    audit_finding_id
                                    title
                                    description
                                    audit_finding {
                                        audit_finding_details {
                                            mgmt_action_plan {
                                                action
                                                description
                                                lead_person
                                            }
                                        }
                                    }
                                }
                            }
                            aap_item {
                                audit_type
                            }
                            commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                                reports {
                                    status
                                }
                            }
                        }
                        audit_findings {
                            status
                        }
                    }
                }
                details {
                    sno
                    ref
                    audit_program_id
                    aap_detail_id
                    objective_id
                    control_id
                    procedure_id
                    objective {
                        id
                        objective
                        description
                    }
                    procedure {
                        id
                        ref
                        name
                        description
                        tests {
                            id
                            ref
                            test
                            procedure_id
                        }
                    }
                    test_assignements {
                        auditor_name
                        auditor_id
                        start_date
                        end_date
                        test {
                            id
                            test
                        }
                    }
                }
            }
        }
    `,
    auditPrograms: gql`
        query auditPrograms($where: AuditProgramWhereInput, $orderBy: [AuditProgramOrderByInput!], $take: Int, $skip: Int) {
            auditPrograms(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                status
                is_effective
                is_latest
                version_date
                audit_commencement {
                    name
                    file_number
                    year_of_audit

                    commencement_details {
                        aap_detail {
                            aap_item_id
                            mda_profile_def {
                                auditable_area
                                auditable_area_code
                            }
                            ## finding obj only for FOLLOWUP audits
                            finding {
                                ref
                            }
                            aap_item {
                                audit_type
                            }
                            aap_sub_assignments {
                                assignment_id
                                assignment {
                                    followup_assignment_ref
                                }
                            }
                        }
                    }
                }
                details {
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                        }
                    }
                }
            }
        }
    `,
    auditProgramCount: gql`
        query auditProgramCount($where: AuditProgramWhereInput) {
            auditProgramCount(where: $where)
        }
    `,
    auditMeetings: gql`
        query auditMeetings($where: AuditMeetingWhereInput!, $orderBy: [AuditMeetingOrderByInput!], $skip: Int, $take: Int) {
            auditMeetings(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                version_no
                version_user
                version_date
                status
                location
                proposed_date
                actual_date
                documentation
                meeting_type
                reason
                meeting_agendas {
                    sno
                    item
                    lead_agency
                    lead_person
                }
                meeting_documents {
                    sno
                    document_name
                    document_type
                    document_id
                }
                commencemet_form {
                    commencement_details {
                        aap_detail {
                            aap_item_id
                            aap_sub_assignments {
                                assignment_id
                                aap_detail_id
                            }
                        }
                    }
                }
            }
        }
    `,
    auditMeeting: gql`
        query auditMeeting($where: AuditMeetingWhereUniqueInput!) {
            auditMeeting(where: $where) {
                ref
                id
                version_no
                version_user
                version_date
                is_latest
                location
                proposed_date
                actual_date
                documentation
                meeting_type
                reason
                participants {
                    id
                    name
                    designation
                    institution
                }
                meeting_agendas {
                    sno
                    item
                    lead_agency
                    lead_person
                }
                meeting_documents {
                    sno
                    document_name
                    document_type
                    document_id
                    document_info
                }
                commencemet_form {
                    id
                }
                audit_meeting_details {
                    commencement_detail {
                        id
                        aap_detail {
                            mda_profile_def {
                                auditable_area
                                management_contacts {
                                    employee_id
                                }
                            }
                        }
                        audit_commencement_form {
                            file_number
                            year_of_audit
                            name
                        }
                    }
                }
            }
        }
    `,
    createOneAuditMeeting: gql`
        mutation createOneAuditMeeting($data: AuditMeetingCreateInput!) {
            createOneAuditMeeting(data: $data) {
                id
            }
        }
    `,
    updateOneAuditMeeting: gql`
        mutation updateOneAuditMeeting($data: AuditMeetingUpdateInput!, $where: AuditMeetingWhereUniqueInput!) {
            updateOneAuditMeeting(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditMeeting: gql`
        mutation deleteOneAuditMeeting($where: AuditMeetingWhereUniqueInput!) {
            deleteOneAuditMeeting(where: $where) {
                id
            }
        }
    `,
    auditMeetingCount: gql`
        query auditMeetingCount($where: AuditMeetingWhereInput) {
            auditMeetingCount(where: $where)
        }
    `,
    auditProceduresWithAuditTest: gql`
        query auditProceduresWithAuditTest($objective_id: String, $commencement_ids: String!) {
            auditProceduresWithAuditTest(objective_id: $objective_id, commencement_ids: $commencement_ids)
        }
    `,
    auditClosureForms: gql`
        query auditClosureForms($where: AuditClosureFormWhereInput!, $orderBy: [AuditClosureFormOrderByInput!], $skip: Int, $take: Int) {
            auditClosureForms(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                version_no
                version_user
                version_date
                status
                commencement_form {
                    id
                    name
                    file_number
                    year_of_audit
                    ref
                    commencement_details {
                        aap_detail {
                            aap_item_id
                            aap_sub_assignments {
                                assignment_id
                            }
                        }
                    }
                }
            }
        }
    `,
    auditClosureForm: gql`
        query auditClosureForm($where: AuditClosureFormWhereUniqueInput!) {
            auditClosureForm(where: $where) {
                ref
                id
                version_no
                version_user
                version_date
                is_latest
                area_of_exposure
                sugesstion_for_auditor
                commencement_form {
                    id
                    name
                    file_number
                    year_of_audit
                    ref
                }
                audit_working_papers {
                    sno
                    question
                    status
                    remarks
                }
                result_of_assignment {
                    sno
                    item
                    value
                }
                assignment_info {
                    sno
                    item
                    approved_standard
                    actual
                    variance
                }
            }
        }
    `,
    createOneAuditClosureForm: gql`
        mutation createOneAuditClosureForm($data: AuditClosureFormCreateInput!) {
            createOneAuditClosureForm(data: $data) {
                id
            }
        }
    `,
    updateOneAuditClosureForm: gql`
        mutation updateOneAuditClosureForm($data: AuditClosureFormUpdateInput!, $where: AuditClosureFormWhereUniqueInput!) {
            updateOneAuditClosureForm(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneAuditClosureForm: gql`
        mutation deleteOneAuditClosureForm($where: AuditClosureFormWhereUniqueInput!) {
            deleteOneAuditClosureForm(where: $where) {
                id
            }
        }
    `,
    auditClosureFormCount: gql`
        query auditClosureFormCount($where: AuditClosureFormWhereInput) {
            auditClosureFormCount(where: $where)
        }
    `,
    createManyAuditTestWorksheet: gql`
        mutation createManyAuditTestWorksheet($data: AuditTestWorksheetBulkCreateInput!) {
            createManyAuditTestWorksheet(data: $data)
        }
    `,
    createOneAuditTestWorksheet: gql`
        mutation createOneAuditTestWorksheet($data: AuditTestWorksheetCreateInput!) {
            createOneAuditTestWorksheet(data: $data) {
                id
            }
        }
    `,
    updateOneAuditTestWorksheet: gql`
        mutation updateOneAuditTestWorksheet($data: AuditTestWorksheetUpdateInput!, $where: AuditTestWorksheetWhereUniqueInput!) {
            updateOneAuditTestWorksheet(data: $data, where: $where) {
                id
            }
        }
    `,
    getCommencementDetailsForReportActuals: gql`
        query commencementDetails($where: CommencementDetailWhereInput) {
            commencementDetails(where: $where) {
                audit_commencement_form {
                    id
                    name
                    file_number
                }
            }
        }
    `,
    createOneReportActual: gql`
        mutation createOneReportActual($data: ReportActualCreateInput!) {
            createOneReportActual(data: $data) {
                id
            }
        }
    `,
    updateOneReportActual: gql`
        mutation updateOneReportActual($data: ReportActualUpdateInput!, $where: ReportActualWhereUniqueInput!) {
            updateOneReportActual(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneReportActual: gql`
        mutation deleteOneReportActual($where: ReportActualWhereUniqueInput!) {
            deleteOneReportActual(where: $where) {
                id
            }
        }
    `,
    reportActualCount: gql`
        query reportActualCount($where: ReportActualWhereInput) {
            reportActualCount(where: $where)
        }
    `,
    getReportActualById: gql`
        query reportActual($where: ReportActualWhereUniqueInput!) {
            reportActual(where: $where) {
                ref
                id
                version_no
                version_user
                version_date
                status
                auditor_id
                report_actual_details {
                    sno
                    phase
                    days
                    resource_cost
                    ope_cost
                    total_cost
                    completion_date
                    report_actual_id
                }
                commencement_form {
                    id
                    name
                    vote
                    file_number
                }
            }
        }
    `,
    reportActuals: gql`
        query reportActuals($where: ReportActualWhereInput!, $orderBy: [ReportActualOrderByInput!], $skip: Int, $take: Int) {
            reportActuals(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                version_no
                version_user
                version_date
                is_latest
                status
                auditor_id
                auditor_info
                report_actual_details {
                    sno
                    phase
                    days
                    resource_cost
                    ope_cost
                    total_cost
                    completion_date
                }
                commencement_form {
                    id
                    name
                    file_number
                    commencement_details {
                        aap_detail {
                            aap_item_id
                            aap_sub_assignments {
                                assignment_id
                            }
                        }
                    }
                }
            }
        }
    `,
    deleteOneAuditTestWorksheet: gql`
        mutation deleteOneAuditTestWorksheet($where: AuditTestWorksheetWhereUniqueInput!) {
            deleteOneAuditTestWorksheet(where: $where) {
                id
            }
        }
    `,
    auditTestWorksheets: gql`
        query auditTestWorksheets(
            $where: AuditTestWorksheetWhereInput
            $orderBy: [AuditTestWorksheetOrderByInput!]
            $skip: Int
            $take: Int
        ) {
            auditTestWorksheets(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                commencement_detail {
                    audit_commencement_form {
                        name
                        file_number
                        year_of_audit
                    }
                    aap_detail {
                        aap_item_id
                        mda_profile_def {
                            auditable_area
                        }
                        classification
                        aap_level_details {
                            level_id
                            level_alias
                            classif_code_obj {
                                parent_id
                                name
                            }
                        }
                        objective_mappings {
                            is_deleted
                            objective {
                                ref
                                objective
                            }
                        }
                        aap_sub_assignments {
                            assignment_id
                        }
                    }
                }
            }
        }
    `,
    auditTestWorksheet: gql`
        query auditTestWorksheet($where: AuditTestWorksheetWhereUniqueInput!) {
            auditTestWorksheet(where: $where) {
                id
                ref
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                commencement_detail {
                    id
                    reports(where: { is_effective: { equals: true }, report_type: { equals: "Final Report" } }) {
                        report_type
                        is_effective
                        is_latest
                    }
                    audit_commencement_form {
                        name
                        file_number
                    }
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                        }
                        classification
                        aap_level_details {
                            level_id
                            level_alias
                            classif_code_obj {
                                parent_id
                                name
                            }
                        }
                        objective_mappings {
                            is_deleted
                            objective {
                                ref
                                objective
                            }
                        }
                    }
                }
                worksheet_details {
                    type_of_test
                    population_size
                    sample_size
                    information_source
                    audit_program_detail {
                        id
                        objective_id
                        procedure {
                            name
                            description
                        }
                    }
                    worksheet {
                        commencement_detail_id
                    }
                }
            }
        }
    `,
    auditTestWorksheetCount: gql`
        query auditTestWorksheetCount($where: AuditTestWorksheetWhereInput) {
            auditTestWorksheetCount(where: $where)
        }
    `,
    auditFindingsByItem: gql`
        query auditFindingsByItem($assignmentId: String!) {
            auditFindingsByItem(assignmentId: $assignmentId)
        }
    `,
    getAuditAssignmentsByItem: gql`
        query getAuditAssignmentsByItem($assignmentId: String!) {
            getAuditAssignmentsByItem(assignmentId: $assignmentId)
        }
    `,
    engagementForms: gql`
        query engagementForms($where: EngagementFormWhereInput, $orderBy: [EngagementFormOrderByInput!], $skip: Int, $take: Int) {
            engagementForms(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                category
                name
                description
                other_reason
                report_doc
                status
                is_latest
                is_effective
                version_no
                version_user
                version_date
            }
        }
    `,
    engagementForm: gql`
        query engagementForm($where: EngagementFormWhereUniqueInput!) {
            engagementForm(where: $where) {
                id
                ref
                category
                name
                description
                report_doc
                status
                is_latest
                is_effective
                other_reason
                version_no
                version_user
                version_date
                commencement_form {
                    id
                    name
                    file_number
                    commencement_details {
                        id
                        aap_detail {
                            mda_profile_def {
                                auditable_area
                                management_contacts {
                                    employee_id
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    engagementFormCount: gql`
        query engagementFormCount($where: EngagementFormWhereInput) {
            engagementFormCount(where: $where)
        }
    `,
    createOneEngagementForm: gql`
        mutation createOneEngagementForm($data: EngagementFormCreateInput!) {
            createOneEngagementForm(data: $data) {
                id
            }
        }
    `,
    updateOneEngagementForm: gql`
        mutation updateOneEngagementForm($data: EngagementFormUpdateInput!, $where: EngagementFormWhereUniqueInput!) {
            updateOneEngagementForm(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneEngagementForm: gql`
        mutation deleteOneEngagementForm($where: EngagementFormWhereUniqueInput!) {
            deleteOneEngagementForm(where: $where) {
                id
            }
        }
    `,
    aapItems: gql`
        query aapItems($where: AAPItemWhereInput) {
            aapItems(where: $where) {
                ref
                id
                aap_item_details {
                    commencement_detail {
                        id
                        aap_detail {
                            id
                        }
                    }
                    id
                    mda_profile_def {
                        auditable_area_code
                        auditable_area
                    }
                    aap_sub_assignments {
                        assignment {
                            id
                            ref
                        }
                    }
                }
            }
        }
    `,
    auditFindingsForRegister: gql`
        query auditFindings($where: AuditFindingWhereInput) {
            auditFindings(where: $where) {
                id
                ref
                audit_finding_details {
                    id
                    audit_finding_id
                    followup_audit_approach
                    description
                    title
                    mngmt_response
                    mgmt_action_plan {
                        id
                        action
                        description
                        lead_person
                        support_person
                        start_date
                        end_date
                    }
                }
                commencement_detail {
                    audit_commencement_form {
                        id
                        name
                    }
                    aap_detail_id
                    aap_detail {
                        id
                        aap_item_id
                        aap_item {
                            ref
                            aap_id
                            aap {
                                ref
                                version_no
                            }
                        }
                        aap_sub_assignments {
                            assignment_id
                            assignment {
                                id
                                ref
                            }
                        }
                        mda_profile_def {
                            id
                            mda
                            mda_code
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
            }
        }
    `,
    auditAssignmentViewData: gql`
        query auditAssignmentViewData(
            $aap_item_id: String
            $sub_assignment_id: String
            $aap_detail_id: String
            $mngmt_action_status: String
            $office_code: String
            $fin_year: String
            $take: Int
        ) {
            auditAssignmentViewData(
                aap_item_id: $aap_item_id
                sub_assignment_id: $sub_assignment_id
                aap_detail_id: $aap_detail_id
                mngmt_action_status: $mngmt_action_status
                office_code: $office_code
                fin_year: $fin_year
                take: $take
            )
        }
    `,
    getAuditObservations: gql`
        query getAuditObservations($aapItemId: String, $assignmentId: String!) {
            getAuditObservations(aapItemId: $aapItemId, assignmentId: $assignmentId)
        }
    `,
    auditHistory: gql`
        query auditHistory($item_id: String, $assignment_id: String!) {
            auditHistory(item_id: $item_id, assignment_id: $assignment_id)
        }
    `,
    createManyAapAuditorDetails: gql`
        mutation createManyAAPAuditorDetail($data: AAPAuditorDetailBulkCreateInput!) {
            createManyAAPAuditorDetail(data: $data)
        }
    `,
    getAuditObjectives: gql`
        query auditObjectiveDefs($where: AuditObjectiveDefWhereInput!) {
            auditObjectiveDefs(where: $where) {
                id
                ref
                objective
            }
        }
    `,
    auditAssignmentStatus: gql`
        query auditAssignmentStatus($aap_detail_id: String!) {
            auditAssignmentStatus(where: $where) {
                id
                aap_detail_id
                state
                status
            }
        }
    `,
    getConclusionStatus: gql`
        query conclusionStatus($assignment_id: String, $aap_item_id: String) {
            conclusionStatus(assignment_id: $assignment_id, aap_item_id: $aap_item_id)
        }
    `,
    reportCount: gql`
        query reportCount($where: ReportWhereInput) {
            reportCount(where: $where)
        }
    `,
    createOneReport: gql`
        mutation createOneReport($data: ReportCreateInput!) {
            createOneReport(data: $data) {
                id
            }
        }
    `,
    updateOneReport: gql`
        mutation updateOneReport($data: ReportUpdateInput!, $where: ReportWhereUniqueInput!) {
            updateOneReport(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneReport: gql`
        mutation deleteOneReport($where: ReportWhereUniqueInput!) {
            deleteOneReport(where: $where) {
                id
            }
        }
    `,
    reports: gql`
        query reports($where: ReportWhereInput, $orderBy: [ReportOrderByInput!], $skip: Int, $take: Int) {
            reports(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                version_no
                version_user
                version_date
                report_type
                submission_date
                commencement_detail {
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
            }
        }
    `,
    report: gql`
        query report($where: ReportWhereUniqueInput!) {
            report(where: $where) {
                ref
                id
                status
                version_no
                version_user
                version_date
                report_type
                submission_date
                commencement_detail {
                    aap_detail {
                        mda_profile_def {
                            auditable_area
                            auditable_area_code
                        }
                    }
                }
                items {
                    sno
                    item
                    document_id
                }
            }
        }
    `,
    issuanceLetterDownload: gql`
        query issuanceLetter($detailId: String!, $reportType: String!) {
            issuanceLetter(detailId: $detailId, reportType: $reportType)
        }
    `,
    acknowledgementReportDownload: gql`
        query acknowledgementReport($where: CommencementDetailWhereInput!, $reportType: String!) {
            acknowledgementReport(where: $where, reportType: $reportType)
        }
    `,
    getAuditableAreaForReport: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                mda_profile_def {
                    auditable_area
                    auditable_area_code
                }
                commencement_detail {
                    commencement_id
                    id
                    audit_commencement_form {
                        id
                    }
                }
            }
        }
    `,
    getAapDetailsForGenerateReport: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                mda_profile_def {
                    id
                    mda
                    auditable_area
                    auditable_area_code
                }
                commencement_detail(where: { audit_commencement_form: { is_effective: { equals: true } } }) {
                    commencement_id
                    id
                    aap_detail_id
                    aap_detail {
                        mda_profile_def_id
                        mda_profile_def {
                            office_code
                            mda
                            auditable_area
                            addresses {
                                address {
                                    id
                                    line1
                                    line2
                                    line3
                                    pin_code
                                    country
                                    state
                                    district
                                }
                            }
                        }
                        objective_mappings {
                            objective_id
                            objective {
                                ref
                                objective
                            }
                        }
                    }
                    audit_commencement_form {
                        id
                        name
                        file_number
                        year_of_audit
                        audit_authority
                        audit_dates {
                            start_date
                            end_date
                        }
                        audit_assignment_plan {
                            background_info # for schedule audit, followup, consulting
                        }
                        adhoc_consulting_details {
                            background # for adhoc audit
                        }
                        scope
                        limitation_of_scope
                        # adhoc_consulting
                        adhoc_consulting_details {
                            scope
                            introduction
                            background
                            support_expected
                            management_expectation
                            limitation_of_scope
                        }
                        # followup
                        followup_commencement_details {
                            scope
                            limitation_of_scope
                        }
                        audit_dates_and_budget {
                            start_date
                            end_date
                        }
                        # objectives for followups
                        objective_mappings {
                            id
                            objective {
                                ref
                                objective
                                description
                            }
                        }
                    }
                    # worksheet details
                    audit_worksheet {
                        worksheet_details {
                            type_of_test
                            population_size
                            sample_size
                            information_source
                            audit_program_detail {
                                procedure {
                                    name
                                }
                            }
                        }
                    }
                    # audit findings
                    audit_findings {
                        ref
                        audit_finding_details {
                            title
                            criteria_observation
                            implication
                            recommendations {
                                sno
                                recommendation
                                status
                            }
                            mngmt_response
                            risk_rating
                            mgmt_action_plan {
                                action
                                rr_numbers
                                status
                            }
                        }
                        finding_observations {
                            audit_finding_observation_details {
                                name
                                status
                                risk_rating
                                post_fwp_obs
                                description
                            }
                        }
                    }
                    # audit meetings
                    audit_meeting_details {
                        audit_meeting {
                            meeting_type
                            documentation
                            actual_date
                            version_date
                            participants {
                                name
                            }
                        }
                    }
                }
            }
        }
    `,
    createOneDraftReport: gql`
        mutation createOneDraftReport($data: DraftReportCreateInput!) {
            createOneDraftReport(data: $data) {
                id
            }
        }
    `,
    generateReport: gql`
        query generateReport($detailId: String, $audit_type: String!, $reportData: JSON) {
            generateReport(detailId: $detailId, audit_type: $audit_type, reportData: $reportData)
        }
    `,
    draftReports: gql`
        query draftReports($where: DraftReportWhereInput, $orderBy: [DraftReportOrderByInput!], $take: Int, $skip: Int) {
            draftReports(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                commencement_detail_id
                report_detail
                desc_ack
                exec_summary
                audit_background
                audit_scope
                process_methodology
                audit_finding
                conc_openion
                closing_meeting
                commencement_detail {
                    aap_detail {
                        mda_profile_def {
                            office_code
                        }
                        objective_mappings {
                            objective_id
                        }
                    }
                    audit_commencement_form {
                        audit_type
                    }
                }
            }
        }
    `,
    auditCommencementReport: gql`
        query auditCommencementReport($where: AuditCommencementFormWhereInput) {
            auditCommencementReport(where: $where)
        }
    `,
    riskAssessmentReport: gql`
        query riskAssessmentReport($source_id: String!) {
            riskAssessmentReport(source_id: $source_id)
        }
    `,
    auditEnvironmentReportDownload: gql`
        query auditEnvironmentReportDownload($source_id: String!) {
            auditEnvironmentReportDownload(source_id: $source_id)
        }
    `,
    summaryTestResultReport: gql`
        query summaryTestResultReport($where: AuditTestResultWhereInput) {
            summaryTestResultReport(where: $where)
        }
    `,
    ageAnalysisReport: gql`
        query ageAnalysisReport($source_id: String!) {
            ageAnalysisReport(source_id: $source_id)
        }
    `,
    aapDetailsForAuditAssignmentStatus: gql`
        query aapDetails($where: AAPDetailWhereInput!) {
            aapDetails(where: $where) {
                id
                aap_item_id
                aap_sub_assignments {
                    assignment_id
                }
                commencement_detail {
                    id
                    audit_commencement_form {
                        id
                        name
                        file_number
                        audit_type
                        year_of_audit
                        audit_authority
                    }
                }
            }
        }
    `
};

export default AAPEQueries;
