import React from 'react';
import { Input } from 'appkit-react';

const InputComponent = (props) => {
    const restrictSpace = (e) => {
        if (props.alphanumericspecial) {
            let pattern = new RegExp(/^[ A-Za-z0-9_@.~!$%^*()/#&+-]*$/g);
            if (!pattern.test(e.key) || e.which === 32) {
                e.preventDefault();
            }
        }
        if (props.alphanumeric) {
            let pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.alphabets) {
            let pattern = new RegExp(/^[a-zA-Z\s]*$/);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.alphabetsandspecialchars) {
            let pattern = new RegExp(/^[a-zA-Z-./\s]*$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.alphabetsandspecs) {
            let pattern = new RegExp(/^[a-zA-Z-.()\s]*$/g);
            if (!pattern.test(e.key)) {
                e.preventDefault();
            }
        }
        if (props.floatdecimal) {
            let regex = /^0(?! \d+$)/;
            let stringRegex = /^[a-zA-Z!@#$%^&*)(+=,_-]$/;
            if (stringRegex.test(e.key) || e.which === 32 || (e?.currentTarget?.selectionStart === 0 && e.which === 190))
                e.preventDefault();
            if (regex.test(e.key) && e?.currentTarget?.selectionStart === 0) {
                e.preventDefault();
            }
        }
        if (props.maxval) {
            let val = Number(props.value + e.key);
            if (val > props.maxval) {
                e.preventDefault();
            }
        }
        if (props.minval) {
            let val = Number(props.value + e.key);
            if (val < props.minval) {
                e.preventDefault();
            }
        }
        if (props.numbers) {
            let reg = new RegExp('^[0-9]+$');
            let stringRegex = /^[a-zA-Z!@#$%^&*)(+=,_-]$/;
            if (stringRegex.test(e.key) || e.which === 32 || (e?.currentTarget?.selectionStart === 0 && e.which === 190)) {
                e.preventDefault();
            }
            if (reg.test(e.key) && e?.currentTarget?.selectionStart === 0) {
                e.preventDefault();
            }
        }

        if (e.which === 32 && e?.currentTarget?.selectionStart === 0) e.preventDefault();
        if (props.type === 'number' && (e.which === 69 || e.which === 189 || e.which === 187 || e.which === 190)) e.preventDefault();
    };

    return <Input autoComplete="off" onKeyDown={(ev) => restrictSpace(ev)} {...props} />;
};

export default InputComponent;
