import { gql } from '@apollo/client';

export const Schema = {
    admCoaSegments: gql`
        query admCoaSegments($where: AdmCOASegmentWhereInput) {
            admCoaSegments(where: $where, orderBy: { full_code: asc }) {
                id
                name
                code
                full_code
                status
                parent {
                    id
                    code
                    full_code
                }
                approval_status
                seg_def {
                    id
                    name
                    description
                    size
                }
                children {
                    id
                    name
                    code
                    full_code
                    status
                    approval_status
                    parent {
                        id
                        code
                        full_code
                    }
                    seg_def {
                        id
                        name
                        description
                        size
                    }
                    children {
                        id
                        name
                        code
                        full_code
                        status
                        approval_status
                        parent {
                            id
                            code
                            full_code
                        }
                        seg_def {
                            id
                            name
                            description
                            size
                        }
                        children {
                            id
                            name
                            code
                            full_code
                            status
                            approval_status
                            parent {
                                id
                                code
                                full_code
                            }
                            seg_def {
                                id
                                name
                                description
                                size
                            }
                            children {
                                id
                                name
                                code
                                full_code
                                status
                                approval_status
                                parent {
                                    id
                                    code
                                    full_code
                                }
                                seg_def {
                                    id
                                    name
                                    description
                                    size
                                }
                                children {
                                    id
                                    name
                                    code
                                    full_code
                                    status
                                    approval_status
                                    parent {
                                        id
                                        code
                                        full_code
                                    }
                                    seg_def {
                                        id
                                        name
                                        description
                                        size
                                    }
                                    children {
                                        id
                                        name
                                        code
                                        full_code
                                        status
                                        approval_status
                                        parent {
                                            id
                                            code
                                            full_code
                                        }
                                        seg_def {
                                            id
                                            name
                                            description
                                            size
                                        }

                                        children {
                                            id
                                            name
                                            code
                                            full_code
                                            status
                                            approval_status
                                            parent {
                                                id
                                                code
                                                full_code
                                            }
                                            seg_def {
                                                id
                                                name
                                                description
                                                size
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    current_fin_year: gql`
        query current_fin_year {
            current_fin_year {
                id
                name
                master_start_date
            }
        }
    `,

    createOneAdmCOASegment: gql`
        mutation createOneAdmCOASegment($data: AdmCOASegmentCreateInput!) {
            createOneAdmCOASegment(data: $data) {
                id
                name
                code
                seg_def {
                    id
                    name
                    description
                    size
                    grouping_type
                }
                status
            }
        }
    `,

    admCoaStructures: gql`
        query admCoaStructures($where1: AdmCOAStructureWhereInput, $orderBy: [AdmCOAStructureOrderByInput!]) {
            admCoaStructures(where: $where1, orderBy: $orderBy) {
                id
                name
                description
                year {
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        year {
                            name
                            master_start_date
                            master_end_date
                        }
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    coaSegmentConf: gql`
        query coaSegmentConf {
            coaSegmentConf(where: { id: "COA_SEGMENT_CONF" }) {
                office_level
                dept_level
                org_segment
                office_codes {
                    name
                    full_code
                    id
                }
            }
        }
    `,
    coaSegmentDeptConf: gql`
        query coaSegmentConf {
            coaSegmentConf(where: { id: "COA_SEGMENT_CONF" }) {
                dept_level
                org_segment
            }
        }
    `,
    departmentDrafts: gql`
        query departmentDrafts($where: DepartmentDraftWhereInput,$orderBy: [DepartmentDraftOrderByInput!]) {
            departmentDrafts(where: $where, orderBy: $orderBy) {
                id
                ref
                version_date
                status
                version_no
                version_user
                created_by
                codes {
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
    departments: gql`
        query {
            departments {
                segment_name
                segment_full_code
            }
        }
    `,
    departmentCodes: gql`
        query deptCodes($fin_year: String) {
            departmentCodes(fin_year: $fin_year) {
                name
                code
                full_code
                seg_def_id
            }
        }
    `,
    admFinYearCoaSegDefs: gql`
        query admFinYearCoaSegDefs($where: AdmFinYearCOASegDefWhereInput) {
            admFinYearCoaSegDefs(where: $where) {
                id
                name
                description
                parent {
                    id
                }
                size
                grouping_type
                levels {
                    id
                    name
                    parent {
                        id
                    }
                    size
                    description
                }
            }
        }
    `,
    admFinYears: gql`
        query admFinYears($where: AdmFinYearWhereInput, $orderBy: [AdmFinYearOrderByInput!]) {
            admFinYears(where: $where, orderBy: $orderBy) {
                id
                name
                master_start_date
                master_end_date
            }
        }
    `,
    updateOneAdmCOASegment: gql`
        mutation updateOneAdmCOASegment($data: AdmCOASegmentUpdateInput!, $where: AdmCOASegmentWhereUniqueInput!) {
            updateOneAdmCOASegment(data: $data, where: $where) {
                id
                name
                code
                status
                approval_status
            }
        }
    `,

    admFinYearsAlongWithStructures: gql`
        query admFinYears($where: AdmFinYearWhereInput, $orderBy: [AdmFinYearOrderByInput!]) {
            admFinYears(where: $where, orderBy: $orderBy) {
                id
                name
                master_start_date
                master_end_date
                ext_coa_mappings {
                    id
                }
                mappings {
                    id
                }
            }
            admCoaStructures {
                id
                name
                description
                year {
                    id
                }
            }
        }
    `,
    DownloadCOACodesTemplate: gql`
        query DownloadCOACodesTemplate($yearID: String!) {
            DownloadCOACodesTemplate(yearID: $yearID) {
                id
                input_name
            }
        }
    `,
    importCOACodes: gql`
        mutation ImportCOACodes($file: Upload!, $yearID: String!) {
            ImportCOACodes(data: { file: $file, yearID: $yearID }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    approveSegments: gql`
        mutation approveSegments($yearID: String!) {
            updateOneAdmFinYear(
                where: { id: $yearID }
                data: {
                    segments: { updateMany: { where: { year_id: { equals: $yearID } }, data: { approval_status: { set: "Approved" } } } }
                }
            ) {
                id
            }
        }
    `,

    departmentQuery: gql`
        query departmentQuery {
            responseObj: departments {
                segment_name
                segment_full_code
            }
        }
    `,
    budgetQuery: gql`
        query budgetQuery {
            responseObj: budgetOffices {
                segment_name
                segment_full_code
            }
        }
    `,
    deputationQuery: gql`
        query deputationQuery {
            responseObj: deputationOffices {
                segment_name
                segment_full_code
            }
        }
    `,
    implementingQuery: gql`
        query implementingQuery {
            responseObj: implementingAgencies {
                segment_name
                segment_full_code
            }
        }
    `,
    sanctioningQuery: gql`
        query sanctioningQuery {
            responseObj: sanctioningAgencies {
                segment_name
                segment_full_code
            }
        }
    `,
    commonQuery: gql`
        query admFinYearCoaSegDefs {
            admFinYearCoaSegDefs(where: { grouping_type: { equals: "SEGMENT" } }) {
                id
                name
                description
                parent {
                    id
                }
                size
                grouping_type
                levels {
                    id
                    name
                    parent {
                        id
                    }
                    size
                    description
                }
            }
        }
    `
};

export default Schema;
