export const menuClick = (activeMenu, breadcrumb) => {
    return { type: 'MENU_CLICK', activeMenu, breadcrumb };
};

export const loadSpinner = (showSpinner) => {
    return { type: 'SPINNER', spinner: showSpinner };
};

export const setGrowl = (growl) => {
    return { type: 'GROWL', growl };
};

export const onLogin = (auth) => {
    return { type: 'AUTH', auth };
};

export const setAuditHistoryData = (auditHistoryData) => {
    return { type: 'AUDITHISTORYDATA', auditHistoryData };
};
