import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, HttpLink, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import store from './index';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, asyncMap } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

const authMiddlewareLink = new ApolloLink((operation, forward) => {
    const authorizationHeaders = {
        authorization:
            'Bearer ' +
            (localStorage.getItem('AUTH_TOKEN') ||
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJja2FkZzh3bGIwMDAxdHduYm4zNnF2cTFqIiwidXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoiaXRhZG1pbkB0ZWN0b3JvLmNvbSIsImlhdCI6MTU4OTg2NzU3Nn0.wCRc3vJ_3OqqQRK6olf4dGQ6oFf_jHMGxx2eTjOvhtE'),
        'X-External': 'Y'
    };
    operation.setContext({ headers: authorizationHeaders });
    return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => {
    return asyncMap(forward(operation), async (response) => {
        const context = operation.getContext();
        const headers = context.response.headers;
        if (headers) {
            const token = headers.get('x-refresh-token');
            const serverDate = headers.get('x-server-date');
            if (token) localStorage.setItem('AUTH_TOKEN', token);
            if (serverDate) localStorage.setItem('SERVER_DATE', serverDate);
        }
        return response;
    });
});

const errorLink = onError(({ graphQLErrors }) => {
    let flag = false;
    let growl = store?.getState()?.appReducer.growl;
    let growlMsg = window.APOLLO?.QueryError;
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            if (window.INTERNAL_AUDIT && (window.RP_LIMIT_EXCEEDED === message || window.RP_CONFIG_NOT_DEFINED === message)) flag = true;
            growlMsg = message;
        });
        if (!flag) growl.show({ life: 6000, severity: 'error', summary: growlMsg });
        return false;
    }
    growl.show({ life: 6000, severity: 'error', summary: growlMsg });
});

const cache = new InMemoryCache();
let defaultLinks = [authMiddlewareLink, afterwareLink, errorLink];
let socketURL = '';
if (window.API_URL.includes('https')) socketURL = window.API_URL.replace('https', 'wss');
if (window.API_URL.includes('http')) socketURL = window.API_URL.replace('http', 'ws');

const uploadURL = createUploadLink({ uri: window.API_URL + 'sysadmin/graphql' });
const adminURL = new HttpLink({ uri: window.API_URL + 'sysadmin/graphql' });
const contractURL = new HttpLink({ uri: window.API_URL + 'contract/graphql' });
const eventsURL = new HttpLink({ uri: window.API_URL + 'events/graphql' });
const revenueURL = new HttpLink({ uri: window.API_URL + 'revenue/graphql' });
const revenueUploadURL = createUploadLink({ uri: window.API_URL + 'revenue/graphql' });
const expenditureURL = new HttpLink({ uri: window.API_URL + 'expenditure/graphql' });
const paymentURL = new HttpLink({ uri: window.API_URL + 'payment/graphql' });
const employeeURL = new HttpLink({ uri: window.API_URL + 'employee/graphql' });
const empUploadUrl = createUploadLink({ uri: window.API_URL + 'employee/graphql' });
const httpLink = new HttpLink({ uri: socketURL + 'events/graphql' });
const iaURL = new HttpLink({ uri: window.API_URL + 'ia/graphql' });
const iaUploadUrl = createUploadLink({ uri: window.API_URL + 'ia/graphql' });

const adminLink = ApolloLink.from([...defaultLinks, adminURL]);
const contractLink = ApolloLink.from([...defaultLinks, contractURL]);
const uploadLink = ApolloLink.from([...defaultLinks, uploadURL]);
const eventLink = ApolloLink.from([...defaultLinks, eventsURL]);
const revenueLink = ApolloLink.from([...defaultLinks, revenueURL]);
const revenueUploadLink = ApolloLink.from([...defaultLinks, revenueUploadURL]);
const expenditureLink = ApolloLink.from([...defaultLinks, expenditureURL]);
const paymentLink = ApolloLink.from([...defaultLinks, paymentURL]);
const employeeLink = ApolloLink.from([...defaultLinks, employeeURL]);
const empUploadUrlLink = ApolloLink.from([...defaultLinks, empUploadUrl]);
const iaUploadUrlLink = ApolloLink.from([...defaultLinks, iaUploadUrl]);
const iaLink = ApolloLink.from([...defaultLinks, iaURL]);
const wsLink = new WebSocketLink({ uri: socketURL + 'events/graphql', options: { reconnect: true } });
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

const adminClient = new ApolloClient({ cache, link: adminLink });
const contractClient = new ApolloClient({ cache, link: contractLink });
const uploadClient = new ApolloClient({ cache, link: uploadLink });
const eventClient = new ApolloClient({ cache, link: eventLink });
const revenueClient = new ApolloClient({ cache, link: revenueLink });
const expenditureClient = new ApolloClient({ cache, link: expenditureLink });
const paymentClient = new ApolloClient({ cache, link: paymentLink });
const employeeClient = new ApolloClient({ cache, link: employeeLink });
const empClientUploadURL = new ApolloClient({ cache, link: empUploadUrlLink });
const iaClient = new ApolloClient({ cache, link: iaLink });
const iaClientUploadURL = new ApolloClient({ cache, link: iaUploadUrlLink });
const wsClient = new ApolloClient({ cache, link: splitLink });
const revenueUploadClient = new ApolloClient({ cache, link: revenueUploadLink });
export {
    adminClient,
    contractClient,
    uploadClient,
    eventClient,
    revenueClient,
    expenditureClient,
    employeeClient,
    empClientUploadURL,
    paymentClient,
    wsClient,
    revenueUploadClient,
    iaClient,
    iaClientUploadURL
};
