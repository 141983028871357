/* eslint-disable max-lines */
import { gql } from '@apollo/client';

export const Schema = {
    fetchConfiguredData: gql`
        query {
            getClientConfig {
                id
                code
                group
                value1
                value2
                value3
                value4
            }
        }
    `,
    appSettings: gql`
        query appSettings($where: AppSettingWhereInput!) {
            appSettings(where: $where) {
                id
                group
                code
                value1
                value2
                value3
                value4
                version_date
            }
        }
    `,
    roles: gql`
        query roles($where: RoleWhereInput) {
            roles(where: $where) {
                id
                code
                name
                description
                privileges {
                    privilege {
                        id
                        enum_type
                        enum_value
                        enum_group
                        display_value
                        sort_order
                        status
                    }
                }
            }
        }
    `,
    userLogin: gql`
        mutation userLogin($username: String!, $password: String!) {
            userLogin(username: $username, password: $password) {
                token
                privileges
                user {
                    roles {
                        role {
                            name
                        }
                    }
                    id
                    img_url
                    employee_id
                    is_active
                    status
                    user_name
                    last_loggedin_at
                    employee
                }
                password_update_required
            }
        }
    `,
    updateOneRole: gql`
        mutation updateOneRole($id: String, $data: RolePrivilegeUpdateManyWithoutRoleInput) {
            deletion: updateOneRole(where: { id: $id }, data: { privileges: { deleteMany: { role_id: { equals: $id } } } }) {
                code
            }

            update: updateOneRole(where: { id: $id }, data: { privileges: $data }) {
                code
            }
        }
    `,

    deleteOneRole: gql`
        mutation deleteOneRole($id: String!) {
            deletion: updateOneRole(where: { id: $id }, data: { privileges: { deleteMany: { role_id: { equals: $id } } } }) {
                code
            }

            update: deleteOneRole(where: { id: $id }) {
                code
            }
        }
    `,
    users: gql`
        query users($orderBy: [UserOrderByInput!], $where: UserWhereInput, $take: Int, $skip: Int) {
            users(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                user_name
                employee
                is_active
                status
                act_status
            }
        }
    `,
    createOneUser: gql`
        mutation createOneUser($data: UserCreateInput!) {
            createOneUser(data: $data) {
                id
                username
                first_name
                last_name
                email
                img_url
                role {
                    id
                    code
                    name
                    description
                }
                status
                auth_type
            }
        }
    `,
    updateOneUser: gql`
        mutation updateOneUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
            updateOneUser(data: $data, where: $where) {
                id
                username
                first_name
                last_name
                email
                img_url
                role {
                    id
                    code
                    name
                    description
                }
                status
                auth_type
            }
        }
    `,
    deleteOneUser: gql`
        mutation deleteOneUser($where: UserWhereUniqueInput!) {
            deleteOneUser(where: $where) {
                id
            }
        }
    `,
    ImportUsers: gql`
        mutation ImportUsers($file: Upload!) {
            ImportUsers(data: { file: $file }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    ExportUserUploadFile: gql`
        query ExportUserUploadFile {
            ExportUserUploadFile {
                id
                input_name
            }
        }
    `,
    downloadAllUsers: gql`
        mutation deleteOneUser($where: UserWhereUniqueInput!) {
            deleteOneUser(where: $where) {
                id
            }
        }
    `,
    appEnums: gql`
        query appEnums($where: AppEnumWhereInput, $orderBy: [QueryAppEnumsOrderByInput!]) {
            appEnums(where: $where, orderBy: $orderBy) {
                id
                enum_type
                enum_value
                enum_group
                display_value
                sort_order
                status
            }
        }
    `,
    admCoaSegDefs: gql`
        query admCoaSegDefs($where: AdmCOASegDefWhereInput, $orderBy: [AdmCOASegDefOrderByInput!]) {
            admCoaSegDefs(where: $where, orderBy: $orderBy) {
                id
                name
                description
                size
                grouping_type
                status
                path
                levels {
                    id
                    name
                    description
                    size
                    grouping_type
                    levels {
                        name
                        id
                    }
                    status
                    path
                }
            }
        }
    `,
    admFinYearCoaSegDefs: gql`
        query admFinYearCoaSegDefs($where: AdmFinYearCOASegDefWhereInput) {
            admFinYearCoaSegDefs(where: $where) {
                id
                name
                levels {
                    name
                    levels {
                        name
                    }
                }
            }
        }
    `,
    createOneAdmCOASegDef: gql`
        mutation createOneAdmCOASegDef($data: AdmCOASegDefCreateInput!) {
            createOneAdmCOASegDefWithDetails(data: $data) {
                id
                name
                description
                size
                grouping_type
                status
                path
                levels {
                    id
                    name
                    description
                    size
                    grouping_type
                    status
                    path
                }
            }
        }
    `,
    deleteOneAdmCOASegDef: gql`
        mutation deleteOneAdmCOASegDef($where: AdmCOASegDefWhereUniqueInput!) {
            deleteOneAdmCOASegDef(where: $where) {
                id
                name
                description
                size
                grouping_type
                status
                path
                levels {
                    id
                    name
                    description
                    size
                    grouping_type
                    status
                    path
                }
            }
        }
    `,
    updateOneAdmCOASegDef: gql`
        mutation updateOneAdmCOASegDef($data: AdmCOASegDefUpdateInput!, $where: AdmCOASegDefWhereUniqueInput!) {
            updateOneAdmCOASegDef(data: $data, where: $where) {
                id
                name
                description
                size
                grouping_type
                status
                path
                levels {
                    id
                    name
                    description
                    size
                    grouping_type
                    status
                    path
                }
            }
        }
    `,
    updateSegmentData: gql`
        mutation updateSegmentData($id: String!, $name: String!, $path: String!, $size: Int!) {
            updateSegmentDefData(id: $id, name: $name, path: $path, size: $size) {
                status
            }
        }
    `,
    updateManyAdmCOASegDef: gql`
        mutation updateManyAdmCOASegDef($data: AdmCOASegDefUpdateManyMutationInput!, $where: AdmCOASegDefWhereInput) {
            updateManyAdmCOASegDef(data: $data, where: $where) {
                count
            }
        }
    `,
    admCoaStructures: gql`
        query admCoaStructures($where: AdmCOAStructureWhereInput) {
            admCoaStructures(where: $where) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type

                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    createOneAdmCOAStructure: gql`
        mutation createOneAdmCOAStructure($data: AdmCOAStructureCreateInput!) {
            createOneAdmCOAStructure(data: $data) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        parent {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                        children {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                    }
                    structure_id
                    structure {
                        id
                        name
                        description
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        details {
                            id
                            seg_def_id
                            structure_id
                            serial_no
                        }
                    }
                    serial_no
                }
            }
        }
    `,
    updateOneAdmCOAStructure: gql`
        mutation updateOneAdmCOAStructure($data: AdmCOAStructureUpdateInput!, $where: AdmCOAStructureWhereUniqueInput!) {
            updateOneAdmCOAStructure(data: $data, where: $where) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        parent {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                        children {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                    }
                    structure_id
                    structure {
                        id
                        name
                        description
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        details {
                            id
                            seg_def_id
                            structure_id
                            serial_no
                        }
                    }
                    serial_no
                }
            }
        }
    `,

    singleUpload: gql`
        mutation singleUpload($file: Upload) {
            singleUpload(file: $file) {
                id
                path
                input_name
                size
                mimetype
            }
        }
    `,

    uploadProfileImage: gql`
        mutation upload($data: UserProfileImage!) {
            uploadProfileImage(data: $data) {
                status
                error
                fileID
                input_name
                mimetype
            }
        }
    `,
    deleteProfileImage: gql`
        mutation resetProfileImage($user_id: String!) {
            resetProfileImage(user_id: $user_id)
        }
    `,

    deleteOneAdmCOAStructure: gql`
        mutation deleteOneAdmCOAStructure($where: AdmCOAStructureWhereUniqueInput!) {
            deleteOneAdmCOAStructure(where: $where) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        parent {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                        children {
                            id
                            name
                            description
                            size
                            grouping_type
                            parent {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                    }
                    structure_id
                    structure {
                        id
                        name
                        description
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        details {
                            id
                            seg_def_id
                            structure_id
                            serial_no
                        }
                    }
                    serial_no
                }
            }
        }
    `,
    getCompositionsForDisplay: gql`
        query getCompositionsForDisplay($data: FinYearInput!) {
            getCompositionsForDisplay(data: $data) {
                status
                headers {
                    field
                    header
                }
                data {
                    Status
                    Function
                    SubFunction
                    Field1 {
                        id
                        name
                    }
                    Field2 {
                        id
                        name
                    }
                    Field3 {
                        id
                        name
                    }
                    Field4 {
                        id
                        name
                    }
                    Field5 {
                        id
                        name
                    }
                    Field6 {
                        id
                        name
                    }
                    Field7 {
                        id
                        name
                    }
                    Field8 {
                        id
                        name
                    }
                    Field9 {
                        id
                        name
                    }
                    Field10 {
                        id
                        name
                    }
                    composition {
                        id
                        mappings {
                            id
                            status
                        }
                    }
                }
            }
        }
    `,
    admCoaCompositions: gql`
        query admCoaCompositions($where: AdmCOACompositionWhereInput) {
            admCoaCompositions(where: $where) {
                id
                year_id
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                fmis_function
                fmis_sub_function
                structure_id
                structure {
                    id
                    name
                    description
                    year {
                        id
                        name
                        master_start_date
                        master_end_date
                    }
                }
                details {
                    id
                    seg_def_id
                    composition {
                        id
                        year_id
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        fmis_function
                        fmis_sub_function
                        structure_id
                        structure {
                            id
                            name
                            description
                        }
                        details {
                            id
                            seg_def_id
                            comp_id
                            seg_level_id
                            seg_level {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                    }
                    comp_id
                    seg_level_id
                }
            }
        }
    `,
    admCoaStructureWithCompositions: gql`
        query admCoaStructure($whereForStructure: AdmCOAStructureWhereUniqueInput!, $whereforCompositions: AdmCOACompositionWhereInput) {
            admCoaStructure(where: $whereForStructure) {
                id
                name
                description
                year {
                    id
                    name
                }
                details {
                    seg_def_id
                    seg_def {
                        id
                        name
                        levels {
                            id
                            name
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }

            admCoaCompositions(where: $whereforCompositions) {
                fmis_function
                fmis_sub_function
            }
        }
    `,
    admCoaComposition: gql`
        query admCoaComposition($where: AdmCOACompositionWhereUniqueInput!) {
            admCoaComposition(where: $where) {
                id
                fmis_function
                fmis_sub_function
                status
                details {
                    seg_def {
                        id
                        path
                        name
                    }
                    seg_level {
                        id
                        name
                        path
                    }
                }
                structure {
                    id
                    name
                    year {
                        name
                    }
                    description
                    details {
                        seg_def {
                            year {
                                id
                            }
                            name
                            id
                            path
                            levels {
                                id
                                path
                                name
                            }
                        }
                    }
                }
            }
        }
    `,

    createMultiComposition: gql`
        mutation createMultiComposition($data: MultiCompositionCreateInput!) {
            createMultiComposition(data: $data) {
                status
            }
        }
    `,

    updateOneAdmCOAComposition: gql`
        mutation updateOneAdmCOAComposition(
            $dataDelete: AdmCOACompositionUpdateInput!
            $data: AdmCOACompositionUpdateInput!
            $where: AdmCOACompositionWhereUniqueInput!
        ) {
            deletion: updateOneAdmCOAComposition(where: $where, data: $dataDelete) {
                id
            }
            updation: updateOneAdmCOAComposition(data: $data, where: $where) {
                id
            }
        }
    `,

    deleteCOACompositionAlongWithCompDetail: gql`
        mutation deleteManyAdmCOACompDetail($where1: AdmCOACompDetailWhereInput, $where2: AdmCOACompositionWhereUniqueInput!) {
            deleteManyAdmCOACompDetail(where: $where1) {
                count
            }
            deleteOneAdmCOAComposition(where: $where2) {
                id
            }
        }
    `,

    deleteOneAdmCOAComposition: gql`
        mutation deleteOneAdmCOAComposition($where: AdmCOACompositionWhereUniqueInput!) {
            deleteOneAdmCOAComposition(where: $where) {
                id
            }
        }
    `,
    createOneAdmCOASegment: gql`
        mutation createOneAdmCOASegment($data: AdmCOASegmentCreateInput!) {
            createOneAdmCOASegment(data: $data) {
                id
                name
                code
                seg_def {
                    id
                    name
                    description
                    size
                    grouping_type
                    parent {
                        id
                        name
                        description
                        size
                        grouping_type
                        parent {
                            id
                            name
                            description
                            size
                            grouping_type
                            year {
                                id
                                name
                                master_start_date
                                master_end_date
                            }
                            children {
                                id
                                name
                                description
                                size
                                grouping_type
                            }
                        }
                    }
                    year {
                        id
                        name
                        master_start_date
                        master_end_date
                    }
                    children(after: $after2, before: $before2, first: $first2, last: $last2) {
                        id
                        name
                        description
                        size
                        grouping_type
                        parent {
                            id
                            name
                            description
                            size
                            grouping_type
                        }
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        children(after: $after1, before: $before1, first: $first1, last: $last1) {
                            id
                            name
                            description
                            size
                            grouping_type
                        }
                    }
                }
                parent {
                    id
                    name
                    code
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                    }
                    status
                }
                status
                children(after: $after3, before: $before3, first: $first3, last: $last3) {
                    id
                    name
                    code
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                    }
                    status
                }
            }
        }
    `,
    admCoaSegments: gql`
        query admCoaSegments($where: AdmCOASegmentWhereInput) {
            admCoaSegments(where: $where) {
                id
                name
                code
                full_code
                seg_def {
                    name
                }
                children {
                    seg_def_id
                    id
                    seg_def {
                        id
                    }
                }
            }
        }
    `,
    GetMeCompositionWiseSegments: gql`
        query GetMeCompositionWiseSegments($compositionID: String!) {
            GetMeCompositionWiseSegments(compositionID: $compositionID) {
                status
                compositionWiseSegments {
                    name
                    segments {
                        id
                        name
                        code
                        full_code
                        seg_def {
                            name
                            id
                            levels {
                                name
                                id
                            }
                        }
                    }
                }
            }
        }
    `,
    DownloadCOAStructure: gql`
        query DownloadCOAStructure($yearID: String!, $status: String) {
            DownloadCOAStructure(yearID: $yearID, status: $status) {
                id
                input_name
            }
        }
    `,
    DownloadCOAMapping: gql`
        query DownloadCOAMapping($mappingID: String!) {
            DownloadCOAMapping(mappingID: $mappingID) {
                id
                input_name
            }
        }
    `,

    createOneAdmFinYear: gql`
        mutation createOneAdmFinYear($data: AdmFinYearCreateInput!) {
            createOneAdmFinYear(data: $data) {
                id
                name
                master_start_date
                master_end_date
                start_date
                end_date
            }
        }
    `,
    updateOneAdmFinYear: gql`
        mutation updateOneAdmFinYear($data: AdmFinYearUpdateInput!, $where: AdmFinYearWhereUniqueInput!) {
            updateOneAdmFinYear(data: $data, where: $where) {
                id
                name
                master_start_date
                master_end_date
                start_date
                end_date
            }
        }
    `,

    deleteOneAdmFinYear: gql`
        mutation deleteOneAdmFinYear($where: AdmFinYearWhereUniqueInput!) {
            deleteOneAdmFinYear(where: $where) {
                name
                master_start_date
                master_end_date
            }
        }
    `,
    admFinYears: gql`
        query admFinYears($where: AdmFinYearWhereInput, $orderBy: [AdmFinYearOrderByInput!]) {
            admFinYears(where: $where, orderBy: $orderBy) {
                id
                name
                master_start_date
                master_end_date
            }
        }
    `,
    MastersAdmFinYears: gql`
        query admFinYears($where: AdmFinYearWhereInput, $orderBy: [AdmFinYearOrderByInput!]) {
            admFinYears(where: $where, orderBy: $orderBy) {
                id
                name
                start_date
                end_date
                master_start_date
                master_end_date
                structures {
                    id
                }
            }
        }
    `,

    admCoaStructure: gql`
        query admCoaStructure($where: AdmCOAStructureWhereUniqueInput!) {
            admCoaStructure(where: $where) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        year {
                            id
                            name
                            master_start_date
                            master_end_date
                        }
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                            levels {
                                name
                                id
                            }
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    admCoaStructureDrafts: gql`
        query admCoaStructureDrafts($where: AdmCOAStructureDraftWhereInput) {
            admCoaStructureDrafts(where: $where) {
                id
                name
                description
                status
                year {
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    admCoaStructureDraftsAndStructures: gql`
        query admCoaStructureDraftsAndStructures(
            $where: AdmCOAStructureDraftWhereInput
            $where1: AdmCOAStructureWhereInput
            $yearname: AdmFinYearWhereUniqueInput!
            $orderBy: [AdmCOAStructureOrderByInput!]
        ) {
            admFinYear(where: $yearname) {
                name
                id
                master_start_date
                master_end_date
            }
            admCoaStructureDrafts(where: $where) {
                id
                name
                description
                status
                year {
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
            admCoaStructures(where: $where1, orderBy: $orderBy) {
                id
                name
                description
                year {
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        year {
                            name
                            master_start_date
                            master_end_date
                        }
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    admCoaStructureDraft: gql`
        query admCoaStructureDraft($where: AdmCOAStructureDraftWhereUniqueInput!) {
            admCoaStructureDraft(where: $where) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                status
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        path
                        status
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                            status
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    createOneAdmCOAStructureDraft: gql`
        mutation createOneAdmCOAStructureDraft($data: AdmCOAStructureDraftCreateInput!) {
            createOneAdmCOAStructureDraft(data: $data) {
                status
                id
            }
        }
    `,
    updateOneAdmCOAStructureDraft: gql`
        mutation updateOneAdmCOAStructureDraft($data: AdmCOAStructureDraftUpdateInput!, $where: AdmCOAStructureDraftWhereUniqueInput!) {
            updateOneAdmCOAStructureDraft(data: $data, where: $where) {
                id
                name
                description
                status
            }
        }
    `,
    approveFinCOAStructure: gql`
        mutation approveFinCOAStructure($data: StructureIDInput!) {
            approveFinCOAStructure(data: $data) {
                status
            }
        }
    `,
    getClonableStructures: gql`
        query getClonableStructures {
            getClonableStructures {
                status
                structures {
                    name
                    description
                    year {
                        name
                    }
                }
            }
        }
    `,

    getClonableStructuresOfTheYear: gql`
        query getClonableStructuresOfTheYear($data: CloneParams!) {
            getClonableStructuresOfTheYear(data: $data) {
                records {
                    structure {
                        name
                        description
                        year {
                            id
                            name
                        }
                    }
                    segments
                }
            }
        }
    `,
    CloneTheCOAComponents: gql`
        mutation CloneTheCOAComponents($data: CloneInput!) {
            CloneTheCOAComponents(data: $data) {
                status
            }
        }
    `,
    deleteOneAdmCOAStructureDraft: gql`
        mutation deleteOneAdmCOAStructureDraft($where: AdmCOAStructureDraftWhereUniqueInput!) {
            deleteOneAdmCOAStructureDraft(where: $where) {
                status
                id
            }
        }
    `,
    admCoaStructureOrDraftById: gql`
        query admCoaStructure($where: AdmCOAStructureWhereUniqueInput!, $where1: AdmCOAStructureDraftWhereUniqueInput!) {
            admCoaStructure(where: $where) {
                id
                name
                description
                year {
                    name
                    master_start_date
                    master_end_date
                }
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        year {
                            name
                            master_start_date
                            master_end_date
                        }
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
            admCoaStructureDraft(where: $where1) {
                id
                name
                description
                year {
                    id
                    name
                    master_start_date
                    master_end_date
                }
                status
                details {
                    id
                    seg_def_id
                    structure_id
                    serial_no
                    seg_def {
                        id
                        name
                        description
                        size
                        grouping_type
                        status
                        path
                        levels {
                            id
                            name
                            description
                            size
                            grouping_type
                            status
                            path
                        }
                    }
                    structure {
                        id
                        name
                        description
                    }
                }
            }
        }
    `,
    admFinYear: gql`
        query admFinYear($where: AdmFinYearWhereUniqueInput!) {
            admFinYear(where: $where) {
                name
                id
                start_date
                end_date
                master_start_date
                master_end_date
            }
        }
    `,
    current_fin_year: gql`
        query current_fin_year {
            current_fin_year {
                id
                name
                master_start_date
                master_end_date
                start_date
                end_date
            }
        }
    `,
    admCoaMappings: gql`
        query admCoaMappings($where: AdmCOAMappingWhereInput, $data: FinYearInput!) {
            admCoaMappings(where: $where) {
                id
                fmis_function
                fmis_sub_function
                status
                year {
                    name
                }
            }
            getCompositionsForDisplay(data: $data) {
                status
                data {
                    Status
                    Function
                    SubFunction
                    Field1 {
                        id
                        name
                    }
                }
            }
        }
    `,
    admCoaMapping: gql`
        query admCoaMapping($where: AdmCOAMappingWhereUniqueInput!) {
            admCoaMapping(where: $where) {
                id
                fmis_function
                fmis_sub_function
                year {
                    name
                    id
                }
                composition {
                    id
                }
                details {
                    segment {
                        id
                    }
                }
            }
        }
    `,
    createMappingCombinedQuery: gql`
        query createMappingCombinedQuery(
            $where: AdmCOAStructureWhereUniqueInput!
            $data: FinYearInput!
            $whereMapping: AdmCOAMappingWhereInput
        ) {
            admCoaStructure(where: $where) {
                id
                name
                description
            }
            admCoaMappings(where: $whereMapping) {
                id
                fmis_function
                fmis_sub_function
                status
                year {
                    name
                }
            }
            getCompositionsForDisplay(data: $data) {
                status
                headers {
                    field
                    header
                }
                data {
                    Status
                    Function
                    SubFunction
                    Field1 {
                        id
                        name
                    }
                    Field2 {
                        id
                        name
                    }
                    Field3 {
                        id
                        name
                    }
                    Field4 {
                        id
                        name
                    }
                    Field5 {
                        id
                        name
                    }
                    Field6 {
                        id
                        name
                    }
                    Field7 {
                        id
                        name
                    }
                    Field8 {
                        id
                        name
                    }
                    Field9 {
                        id
                        name
                    }
                    Field10 {
                        id
                        name
                    }
                    composition {
                        id
                    }
                }
            }
        }
    `,
    updateOneAdmCOAMapping: gql`
        mutation updateOneAdmCOAMapping($data: AdmCOAMappingUpdateInput!, $where: AdmCOAMappingWhereUniqueInput!) {
            updateOneAdmCOAMapping(data: $data, where: $where) {
                status
            }
        }
    `,
    createMultiMapping: gql`
        mutation createMultiMapping($data: MultiMappingCreateInput!) {
            createMultiMapping(data: $data) {
                status
            }
        }
    `,
    deleteOneAdmCOAMapping: gql`
        mutation deleteOneAdmCOAMapping($where: AdmCOAMappingWhereUniqueInput!) {
            deleteOneAdmCOAMapping(where: $where) {
                status
            }
        }
    `,

    deleteOneAdmExtCOAMapping: gql`
        mutation deleteOneAdmExtCOAMapping($where: AdmExtCOAMappingWhereUniqueInput!) {
            deleteOneAdmExtCOAMapping(where: $where) {
                id
            }
        }
    `,
    fetchExternalFilesStructureAndSegments: gql`
        query fetchExternalFilesStructureAndSegments(
            $where1: ExternalFileWhereInput
            $where2: AdmCOAStructureWhereUniqueInput!
            $where3: AdmFinYearCOASegDefWhereInput
            $where4: AdmExtCOAMappingWhereInput
        ) {
            externalFiles(where: $where1) {
                id
                report_name
                ext_seg_defs {
                    grouping_type
                    id
                    name
                    levels {
                        name
                        id
                    }
                }
            }
            admCoaStructure(where: $where2) {
                year {
                    name
                }
                name
                description
            }

            admFinYearCoaSegDefs(where: $where3) {
                id
                name
                levels {
                    id
                    name
                }
            }
            admExtCoaMappings(where: $where4) {
                file {
                    id
                }
            }
        }
    `,
    externalFilesWithMappings: gql`
        query externalFiles($where1: ExternalFileWhereInput, $where2: AdmExtCOAMappingWhereInput) {
            externalFiles(where: $where1) {
                id
                report_name
                input_name
                ext_seg_defs {
                    grouping_type
                    id
                    name
                    levels {
                        name
                        id
                    }
                }
            }
            admExtCoaMappings(where: $where2) {
                file {
                    id
                    report_name
                    input_name
                }
            }
        }
    `,

    importExternalMapping: gql`
        mutation ImportExternalSegments($file: Upload!, $yearID: String!) {
            ImportExternalSegments(data: { file: $file, yearID: $yearID }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    updatePassword: gql`
        mutation updatePassword($id: String!, $password: String!, $newPassword: String!) {
            updatePassword(id: $id, password: $password, newPassword: $newPassword) {
                id
                status
            }
        }
    `,
    DownloadCOASegmentReport: gql`
        query DownloadCOASegmentReport($yearID: String!) {
            DownloadCOASegmentReport(yearID: $yearID) {
                id
                input_name
            }
        }
    `,
    admExtCoaMappings: gql`
        query admExtCoaMappings($where: AdmExtCOAMappingWhereInput, $orderBy: [AdmExtCOAMappingOrderByInput!]) {
            admExtCoaMappings(where: $where, orderBy: $orderBy) {
                id
                ext_seg_def {
                    name
                }
                ext_level_def {
                    name
                }
                file {
                    report_name
                    input_name
                    id
                }
                seg_def {
                    name
                }
                status
            }
        }
    `,
    admExtCoaMapping: gql`
        query admExtCoaMapping($where: AdmExtCOAMappingWhereUniqueInput!) {
            admExtCoaMapping(where: $where) {
                id
                ext_seg_def {
                    id
                    name
                }
                ext_level_def {
                    id
                    name
                }
                file {
                    id
                    report_name
                    ext_seg_defs {
                        id
                        name
                        grouping_type
                        levels {
                            name
                            id
                        }
                    }
                }
                seg_def {
                    id
                    name
                }
                year {
                    id
                }
                level_def {
                    id
                    name
                }
                status
                details {
                    segment {
                        id
                        code
                        name
                        full_code
                    }
                    ext_segment {
                        name
                        code
                        id
                    }
                }
            }
        }
    `,
    admExtCoaSegmentsNCOASegments: gql`
        query admExtCoaSegments($where1: AdmExtCOASegmentWhereInput, $where2: AdmCOASegmentWhereInput) {
            admExtCoaSegments(where: $where1) {
                id
                code
                full_code
            }
            admCoaSegments(where: $where2) {
                name
                id
                code
                full_code
            }
        }
    `,

    createOneAdmExtCOAMapping: gql`
        mutation createOneAdmExtCOAMapping($data: AdmExtCOAMappingCreateInput!) {
            createOneAdmExtCOAMapping(data: $data) {
                status
            }
        }
    `,
    updateOneAdmExtCOAMapping: gql`
        mutation updateOneAdmExtCOAMapping(
            $dataDelete: AdmExtCOAMappingUpdateInput!
            $data: AdmExtCOAMappingUpdateInput!
            $where: AdmExtCOAMappingWhereUniqueInput!
        ) {
            deletion: updateOneAdmExtCOAMapping(where: $where, data: $dataDelete) {
                id
            }
            updation: updateOneAdmExtCOAMapping(data: $data, where: $where) {
                id
            }
        }
    `,
    createOneAdmCOAMapping: gql`
        mutation createOneAdmCOAMapping($data: AdmCOAMappingCreateInput!) {
            createOneAdmCOAMapping(data: $data) {
                status
            }
        }
    `,
    deleteOneExternalFile: gql`
        mutation deleteOneExternalFile($where: ExternalFileWhereUniqueInput!) {
            deleteOneExternalFile(where: $where) {
                id
            }
        }
    `,

    externalFiles: gql`
        query externalFiles($where: ExternalFileWhereInput) {
            externalFiles(where: $where) {
                id
                report_name
                ext_seg_defs {
                    grouping_type
                    id
                    name
                    levels {
                        name
                        id
                    }
                }
                year_id
                input_name
            }
        }
    `,
    DownloadExternalReport: gql`
        query DownloadExternalReport($yearID: String!, $uploadFileID: String!, $fileName: String!) {
            DownloadExternalReport(yearID: $yearID, uploadFileID: $uploadFileID, fileName: $fileName) {
                input_name
                path
                id
            }
        }
    `,
    cntrDepositDeduction: gql`
        query cntrDepositDeduction {
            cntrDepositDeduction(where: { id: "SECURITY_DEPOSIT" }) {
                is_editable
                hoa
                deduction_rates {
                    id
                    is_active
                    is_default
                    rate
                    from
                    to
                }
            }
        }
    `,

    createOneCntrDeductionRate: gql`
        mutation createOneCntrDeductionRate($data: CntrDeductionRateCreateInput!) {
            createOneCntrDeductionRate(data: $data) {
                id
                rate
                is_active
                is_default
                from
                to
                deposit_deduction {
                    is_editable
                    hoa
                    deduction_rates {
                        deduction_id
                        is_default
                        is_active
                        rate
                        from
                        to
                    }
                }
            }
        }
    `,
    createOneDeduction: gql`
        mutation createOneDeduction($data: DeductionCreateInput!) {
            createOneDeduction(data: $data) {
                id
            }
        }
    `,
    deleteOneDeduction: gql`
        mutation deleteOneDeduction($where: DeductionWhereUniqueInput!) {
            deleteOneDeduction(where: $where) {
                id
            }
        }
    `,
    updateOneDeduction: gql`
        mutation updateOneDeduction($data: DeductionUpdateInput!, $where: DeductionWhereUniqueInput!) {
            updateOneDeduction(data: $data, where: $where) {
                id
                version_no
            }
        }
    `,
    deductions: gql`
        query deductions($where: DeductionWhereInput, $orderBy: [DeductionOrderByInput!], $take: Int, $skip: Int) {
            deductions(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                type
                id
                code
                value
                is_editable
                can_add_remarks
                info
                is_active
                name
                ref
                is_latest
                version_date
                exceptions {
                    fmis_function
                    applicability
                }
                status
                hoa
                is_effective
                effective_from
                version_no
            }
        }
    `,
    deduction: gql`
        query deduction($where: DeductionWhereUniqueInput!) {
            deduction(where: $where) {
                id
                code
                name
                type
                value
                is_editable
                can_add_remarks
                info
                is_latest
                is_active
                exceptions {
                    fmis_function
                    applicability
                }
                hoa
                ref
                version_no
                version_user
                is_effective
                effective_from
            }
        }
    `,
    deductionCount: gql`
        query deductionCount($where: DeductionWhereInput!) {
            deductionCount(where: $where)
        }
    `,
    rvnRemitSegDef: gql`
        query rvnRemitSegDef($where: RvnRemitSegDefWhereUniqueInput!) {
            rvnRemitSegDef(where: $where) {
                id
                ref
                status
                remit_level_name
                remit_seg_def_name
                sub_remit_level_name
                seg_details {
                    seg_def_name
                    level_name
                }
                version_no
                version_user
                version_date
                is_effective
                effective_from
                is_latest
                is_active
            }
        }
    `,
    updateRemSegDef: gql`
        mutation updateOneRvnRemitSegDef($where: RvnRemitSegDefWhereUniqueInput!, $data: RvnRemitSegDefUpdateInput!) {
            updateOneRvnRemitSegDef(data: $data, where: $where) {
                id
            }
        }
    `,

    getMeMappingId: gql`
        query GetMeFunctionWiseSegments(
            $functionName: String!
            $subFunctionName: String!
            $segmentToExclude: String
            $functionCode: String
            $officeCode: String
            $activityCode: String
            $entityId: String
        ) {
            GetMeFunctionWiseSegments(
                functionName: $functionName
                subFunctionName: $subFunctionName
                segmentToExclude: $segmentToExclude
                functionCode: $functionCode
                officeCode: $officeCode
                activityCode: $activityCode
                entityId: $entityId
            ) {
                status
                compositionWiseSegments {
                    name
                    segments {
                        name
                        code
                        full_code
                        id
                        seg_def {
                            name
                        }
                    }
                }
            }
        }
    `,

    getProjectMaster: gql`
        query cntrProjects($where: CntrProjectWhereInput) {
            cntrProjects(where: $where) {
                id
                project_name
                project_number
                status
                is_active
                attachments {
                    id
                    file_id
                    name
                    file
                }
            }
        }
    `,

    createOneCntrProjectVDetails: gql`
        mutation createOneCntrProjectVDetails($data: CntrProjectCreateInput!) {
            createOneCntrProject(data: $data) {
                status
            }
        }
    `,

    updateOneCntrProjectVDetails: gql`
        mutation updateOneCntrProjectVDetails($data: CntrProjectUpdateInput!, $where: CntrProjectWhereUniqueInput!) {
            updateOneCntrProject(data: $data, where: $where) {
                status
            }
        }
    `,

    deleteOneCntrProjectVDetails: gql`
        mutation deleteOneCntrProject($where: CntrProjectWhereUniqueInput!) {
            deleteOneCntrProject(where: $where) {
                status
            }
        }
    `,

    updateOneHOA: gql`
        mutation updateOneHOA($data: CntrDepositDeductionUpdateInput!, $where: CntrDepositDeductionWhereUniqueInput!) {
            updateOneCntrDepositDeduction(data: $data, where: $where) {
                id
                hoa
            }
        }
    `,
    updateManyCntrDeductionRate: gql`
        mutation updateManyCntrDeductionRate($data: CntrDeductionRateUpdateManyMutationInput!, $where: CntrDeductionRateWhereInput) {
            updateManyCntrDeductionRate(data: $data, where: $where) {
                count
            }
        }
    `,
    updateOneCntrDeductionRate: gql`
        mutation updateOneCntrDeductionRate($data: CntrDeductionRateUpdateInput!, $where: CntrDeductionRateWhereUniqueInput!) {
            updateOneCntrDeductionRate(data: $data, where: $where) {
                id
                rate
                is_active
                is_default
                from
                to
            }
        }
    `,
    deleteOneCntrDeductionRate: gql`
        mutation deleteOneCntrDeductionRate($where: CntrDeductionRateWhereUniqueInput!) {
            deleteOneCntrDeductionRate(where: $where) {
                id
            }
        }
    `,
    updateOneCntrDepositDeduction: gql`
        mutation updateOneCntrDepositDeduction($data: CntrDepositDeductionUpdateInput!, $where: CntrDepositDeductionWhereUniqueInput!) {
            updateOneCntrDepositDeduction(data: $data, where: $where) {
                id
                is_editable
            }
        }
    `,
    bankCount: gql`
        query bankCount($where: BankWhereInput) {
            bankCount(where: $where)
        }
    `,
    banks: gql`
        query banks($where: BankWhereInput, $orderBy: [BankOrderByInput!], $skip: Int, $take: Int) {
            banks(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ifsc
                name
                branch_name
                is_active
                version_date
            }
        }
    `,
    createOneBank: gql`
        mutation createOneBank($data: BankCreateInput!) {
            createOneBank(data: $data) {
                id
                ifsc
                name
                branch_name
                is_active
            }
        }
    `,
    updateOneBank: gql`
        mutation updateOneBank($data: BankUpdateInput!, $where: BankWhereUniqueInput!) {
            updateOneBank(data: $data, where: $where) {
                id
                name
                ifsc
                branch_name
                is_active
            }
        }
    `,
    deleteOneBank: gql`
        mutation deleteOneBank($where: BankWhereUniqueInput!) {
            deleteOneBank(where: $where) {
                id
            }
        }
    `,
    IFSCSampleDownload: gql`
        query IFSCSampleDownload {
            IFSCSampleDownload {
                id
                input_name
            }
        }
    `,
    ImportIFSCData: gql`
        mutation ImportIFSCData($file: Upload!) {
            ImportIFSCData(data: { file: $file }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    cntrSupplyCategoryCount: gql`
        query cntrSupplyCategoryCount($where: CntrSupplyCategoryWhereInput) {
            cntrSupplyCategoryCount(where: $where)
        }
    `,
    supplyCategoryMaster: gql`
        query cntrSupplyCategories(
            $orderBy: [CntrSupplyCategoryOrderByInput!]
            $where: CntrSupplyCategoryWhereInput
            $skip: Int
            $take: Int
        ) {
            cntrSupplyCategories(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                ref
                id
                name
                is_active
                status
                version_date
                is_latest
                is_effective
            }
        }
    `,
    findOneCntrSupplyCategory: gql`
        query cntrSupplyCategory($where: CntrSupplyCategoryWhereUniqueInput!) {
            cntrSupplyCategory(where: $where) {
                id
                name
                is_active
                ref
                status
                version_no
                codes {
                    seg_def_name
                    level_name
                    segment_code
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
    createSupplyCategory: gql`
        mutation createOneCntrSupplyCategory($data: CntrSupplyCategoryCreateInput!) {
            createOneCntrSupplyCategory(data: $data) {
                status
            }
        }
    `,
    updateSupplyCategory: gql`
        mutation updateOneCntrSupplyCategory($data: CntrSupplyCategoryUpdateInput!, $where: CntrSupplyCategoryWhereUniqueInput!) {
            updateOneCntrSupplyCategory(data: $data, where: $where) {
                status
            }
        }
    `,
    deleteOneCntrSupplyCategory: gql`
        mutation deleteOneCntrSupplyCategory($where: CntrSupplyCategoryWhereUniqueInput!) {
            deleteOneCntrSupplyCategory(where: $where) {
                status
            }
        }
    `,
    advanceMasters: gql`
        query cntrAdvances($orderBy: [CntrAdvanceOrderByInput!], $where: CntrAdvanceWhereInput, $take: Int) {
            cntrAdvances(orderBy: $orderBy, where: $where, take: $take) {
                id
                code
                name
                status
                ref
                is_latest
                is_effective
                effective_from
                version_no
                version_date
                supplies {
                    supply_category {
                        id
                        name
                        is_active
                    }
                }
                codes {
                    seg_def_name
                    level_name
                    segment_code
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
    getAdvanceMasterById: gql`
        query cntrAdvance($where: CntrAdvanceWhereUniqueInput!) {
            cntrAdvance(where: $where) {
                id
                code
                name
                ref
                is_latest
                version_no
                effective_from
                supplies {
                    supply_category {
                        id
                        name
                        is_active
                    }
                }
                codes {
                    seg_def_name
                    level_name
                    segment_code
                    segment_full_code
                    segment_name
                }
            }
        }
    `,
    createAdvanceMasters: gql`
        mutation createOneCntrAdvance($data: CntrAdvanceCreateInput!) {
            createOneCntrAdvance(data: $data) {
                status
            }
        }
    `,
    cntrAdvanceCount: gql`
        query cntrAdvanceCount($where: CntrAdvanceWhereInput!) {
            cntrAdvanceCount(where: $where)
        }
    `,
    updateAdvanceMasters: gql`
        mutation updateOneCntrAdvance($data: CntrAdvanceUpdateInput!, $where: CntrAdvanceWhereUniqueInput!) {
            updateOneCntrAdvance(data: $data, where: $where) {
                status
            }
        }
    `,
    deleteAdvanceMasters: gql`
        mutation deleteOneCntrAdvance($where: CntrAdvanceWhereUniqueInput!) {
            deleteOneCntrAdvance(where: $where) {
                id
            }
        }
    `,
    getMappingandSupps: gql`
        query GetMeFunctionWiseSegments($functionName: String!, $subFunctionName: String!) {
            GetMeFunctionWiseSegments(functionName: $functionName, subFunctionName: $subFunctionName) {
                status
                compositionWiseSegments {
                    name
                    segments {
                        name
                        code
                        full_code
                        id
                        seg_def {
                            name
                        }
                    }
                }
            }
        }
    `,
    cntrSupplyCategories: gql`
        query cntrSupplyCategories($where: CntrSupplyCategoryWhereInput) {
            cntrSupplyCategories(where: $where, orderBy: [{ name: asc }]) {
                id
                name
                is_active
            }
        }
    `,
    updateOneImplement: gql`
        mutation updateOneImplementingAgency($data: ImplementingAgencyBulkCreateInput!) {
            createManyImplementingAgency(data: $data) {
                status
            }
        }
    `,
    updateOneSanctioning: gql`
        mutation updateOneSanctioningAgency($data: SanctioningAgencyBulkCreateInput!) {
            createManySanctioningAgency(data: $data) {
                status
            }
        }
    `,
    updateOneDepartmentDraft: gql`
        mutation updateOneDepartmentDraft($data: DepartmentDraftUpdateInput!, $where: DepartmentDraftWhereUniqueInput!) {
            updateOneDepartmentDraft(data: $data, where: $where) {
                status
            }
        }
    `,
    createOneDepartmentDraft: gql`
        mutation createOneDepartmentDraft($data: DepartmentDraftCreateInput!) {
            createOneDepartmentDraft(data: $data) {
                status
            }
        }
    `,
    updateOneBudgetOffice: gql`
        mutation createManyBudgetOffice($data: BudgetOfficeBulkCreateInput!) {
            createManyBudgetOffice(data: $data) {
                budget_offices {
                    segment_full_code
                }
            }
        }
    `,
    updateOneDeputationOffice: gql`
        mutation createManyDeputationOffice($data: DeputationOfficeBulkCreateInput!) {
            createManyDeputationOffice(data: $data) {
                deputation_offices {
                    segment_full_code
                }
            }
        }
    `,
    updateOneAppEnum: gql`
        mutation updateOneAppEnum($data: AppEnumUpdateInput!, $where: AppEnumWhereUniqueInput!) {
            updateOneAppEnum(data: $data, where: $where) {
                id
                enum_type
                enum_value
                enum_group
                display_value
                sort_order
                status
            }
        }
    `,
    RvnChallanDashboardCount: gql`
        query RvnChallanDashboardCount($params: RvnDashboardWhereInput!) {
            RvnChallanDashboardCount(params: $params)
        }
    `,
    GetChallans: gql`
        query GetRvnChallanData($params: RvnDashboardWhereInput!) {
            GetRvnChallanData(params: $params) {
                status
                paid {
                    data {
                        id
                        challan_id
                        dept
                        remit_purpose
                        amount
                        is_receipt
                        date
                        pay_mode
                        pay_status
                        amount_received
                    }
                    children {
                        data {
                            id
                            challan_id
                            dept
                            remit_purpose
                            amount
                            is_receipt
                            date
                            pay_mode
                            pay_status
                            amount_received
                        }
                    }
                }
                pending_with_self {
                    data {
                        id
                        challan_id
                        dept
                        remit_purpose
                        amount
                        is_receipt
                        date
                        pay_mode
                        pay_status
                        amount_received
                    }
                    children {
                        data {
                            id
                            challan_id
                            dept
                            remit_purpose
                            amount
                            is_receipt
                            date
                            pay_mode
                            pay_status
                            amount_received
                        }
                    }
                }
            }
        }
    `,
    GetOneRvnChallan: gql`
        query rvnChallan($where1: RvnChallanWhereUniqueInput!, $where2: RvnPaymentDetailWhereInput!) {
            rvnChallan(where: $where1) {
                id
                ref
                remit_name
                id_num
                id_ref
                id_type
                email
                mobile
                locality_id
                address
                details {
                    dept_id
                    remit_id
                    customer_id
                    bill_ref
                    amount
                }
            }
            rvnPaymentDetails(where: $where2) {
                id
                mode
                method_type
                pospay {
                    transaction_id
                }
                cash {
                    received
                }
                upi {
                    upi_from
                    transaction_id
                }
                chq_dd
                cheque {
                    received
                    bank_id
                    date
                    num
                    payee
                    pan
                    amount
                }
            }
        }
    `,
    GetChequeDetails: gql`
        query rvnChequeDd($where: RvnChequeDDWhereUniqueInput!) {
            rvnChequeDd(where: $where) {
                id
                received
                bank_id
                date
                num
                pan
                amount
            }
        }
    `,
    GetCashDetails: gql`
        query rvnPaymentCash($where: RvnPaymentCashWhereUniqueInput!) {
            rvnPaymentCash(where: $where) {
                id
                received
            }
        }
    `,
    GetPosDetails: gql`
        query rvnPaymentPosPay($where: RvnPaymentPOSPayWhereUniqueInput!) {
            rvnPaymentPosPay(where: $where) {
                id
                transaction_id
            }
        }
    `,
    GetUpiDetails: gql`
        query rvnPaymentUpi($where: RvnPaymentUPIWhereUniqueInput!) {
            rvnPaymentUpi(where: $where) {
                id
                upi_from
                transaction_id
            }
        }
    `,
    CreateChallan: gql`
        mutation createOneRvnChallan($data: RvnChallanCreateInput!) {
            createOneRvnChallan(data: $data) {
                id
                ref
                remit_name
                payment_status
                address
                id_num
                id_type
                locality_id
                email
                mobile
            }
        }
    `,
    UpdateChallan: gql`
        mutation UpdateRvnChallan($data: RvnChallanUpdateInput!, $id: String!) {
            UpdateRvnChallan(data: $data, id: $id) {
                id
                ref
                remit_name
                payment_status
                address
                id_num
                id_type
                locality_id
                email
                mobile
            }
        }
    `,
    deleteOneChallan: gql`
        mutation deleteOneRvnChallan($where1: RvnChallanWhereUniqueInput!, $where2: RvnPaymentDetailWhereInput) {
            deleteOneRvnChallan(where: $where1) {
                id
            }
            deleteManyRvnPaymentDetail(where: $where2) {
                count
            }
        }
    `,
    printChallan: gql`
        query DownloadChallanPDF($challanID: String!) {
            DownloadChallanPDF(challanID: $challanID) {
                status
                error
                fileID
                input_name
                mimetype
            }
        }
    `,
    printReceipt: gql`
        query DownloadReceipt($challanID: String!) {
            DownloadReceipt(challanID: $challanID) {
                status
                error
                fileID
                input_name
                mimetype
            }
        }
    `,
    createOneChallanNF: gql`
        mutation createOneChallanNF($data: ChallanNFCreateInput!) {
            createOneChallanNF(data: $data) {
                id
            }
        }
    `,
    GetSegmentsForGivenLevels: gql`
        query GetSegmentsForGivenLevels($where: SegDefNLevelList, $function_code: String, $office_code: String) {
            GetSegmentsForGivenLevels(where: $where, function_code: $function_code, office_code: $office_code) {
                status
                compositionWiseSegments {
                    name
                    level_name
                    segments {
                        id
                        name
                        code
                        full_code
                    }
                }
            }
        }
    `,
    GetLocalities: gql`
        {
            localities {
                id
                name
                code
                city
                city_code
                district
                district_code
                state
                state_code
            }
        }
    `,
    GetDepartmentList: gql`
        query departments {
            departments {
                id
                segment_name
                segment_full_code
            }
        }
    `,
    RemittanceDetails: gql`
        query RemittanceDetails {
            RemittanceDetails {
                remittanceList {
                    remittance_name
                    sub_remittances {
                        id
                        remit_name
                        remit_full_code
                        sub_remit_name
                        sub_remit_full_code
                        hoa_code
                        hoa_details {
                            id
                            remit_code_id
                            seg_def_name
                            level_name
                            segment_name
                            segment_full_code
                        }
                        is_active
                        payment_mode
                        can_use_in_emp_loan
                        can_use_in_pension_loan
                        can_use_in_contr_adv
                        can_use_in_ofc_imprest
                    }
                }
            }
        }
    `,
    GetBankDetails: gql`
        query banks($where: BankWhereInput) {
            banks(where: $where) {
                id
                ifsc
                name
                branch_name
            }
        }
    `,
    comments: gql`
        query comments($where: CommentWhereInput) {
            comments(where: $where, orderBy: [{ text: asc }]) {
                id
                text
                is_active
                is_default
            }
        }
    `,
    comment: gql`
        query comment($where: CommentWhereUniqueInput!) {
            comment(where: $where) {
                id
                text
                is_default
                mappings {
                    id
                }
            }
        }
    `,
    createComments: gql`
        mutation createComments($data: comments!) {
            createComments(data: $data) {
                status
            }
        }
    `,
    updateComments: gql`
        mutation updateComments($data: comments!) {
            updateComments(data: $data) {
                status
            }
        }
    `,
    updateOneComment: gql`
        mutation updateOneComment($where: CommentWhereUniqueInput!, $data: CommentUpdateInput!) {
            updateOneComment(where: $where, data: $data) {
                id
                is_active
            }
        }
    `,
    deleteOneComment: gql`
        mutation deleteOneComment($where: CommentWhereUniqueInput!) {
            deleteOneComment(where: $where) {
                id
            }
        }
    `,
    createOneComment: gql`
        mutation createOneComment($data: CommentCreateInput!) {
            createOneComment(data: $data) {
                id
            }
        }
    `,
    commentMappings: gql`
        query commentMappings($where: CommentMappingWhereInput) {
            commentMappings(where: $where, orderBy: [{ module: asc }]) {
                id
                module
                fmis_function
                fmis_sub_function
                fmis_function_code
                details {
                    id
                    action
                    comment_id
                    comment {
                        text
                        is_active
                    }
                }
            }
        }
    `,
    commentMapping: gql`
        query commentMapping($where: CommentMappingWhereUniqueInput!) {
            commentMapping(where: $where) {
                id
                module
                fmis_function
                details {
                    id
                    action
                    comment_id
                    comment {
                        text
                    }
                }
            }
        }
    `,
    commentMappingDetails: gql`
        query commentMappingDetails($where: CommentMappingDetailWhereInput) {
            commentMappingDetails(where: $where) {
                id
                action
                comment_id
                comment {
                    is_active
                    text
                }
            }
        }
    `,
    createOneCommentMapping: gql`
        mutation createOneCommentMapping($data: CommentMappingCreateInput!) {
            createOneCommentMapping(data: $data) {
                id
            }
        }
    `,
    updateOneCommentMapping: gql`
        mutation updateOneCommentMapping($data: CommentMappingUpdateInput!, $where: CommentMappingWhereUniqueInput!) {
            updateOneCommentMapping(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneCommentMapping: gql`
        mutation deleteOneCommentMapping($where: CommentMappingWhereUniqueInput!) {
            deleteOneCommentMapping(where: $where) {
                id
            }
        }
    `,
    commentsNfmisFunctions: gql`
        query commentsNfmisFunctions($where: CommentWhereInput) {
            comments(where: $where, orderBy: [{ text: asc }]) {
                id
                text
                is_active
                is_default
            }
            privilegeConfs {
                id
                function
                sub_function
                module
                sub_module
                function_code
            }
        }
    `,
    ImportCommentsFile: gql`
        mutation ImportCommentsFile($file: Upload!) {
            ImportCommentsFile(data: { file: $file }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    ExportCommentsFile: gql`
        query ExportCommentsFile {
            ExportCommentsFile {
                id
                input_name
            }
        }
    `,
    createSupplierCategory: gql`
        mutation createOneCntrSupplierCategory($data: CntrSupplierCategoryCreateInput!) {
            createOneCntrSupplierCategory(data: $data) {
                id
            }
        }
    `,

    getSupplierCategories: gql`
        query cntrSupplierCategories(
            $where: CntrSupplierCategoryWhereInput!
            $orderBy: [CntrSupplierCategoryOrderByInput!]
            $take: Int
            $skip: Int
        ) {
            cntrSupplierCategories(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                name
                version_date
                status
                effective_from
                can_link_supplier
                is_active
                version_user
                version_no
                is_latest
                no_of_suppliers
                ref
                is_effective
                fields {
                    category_id
                    id
                    field_name
                    is_visible
                    is_mandatory
                    can_add_validate
                    can_add_attachment
                }
            }
        }
    `,

    getOneSupplyCategory: gql`
        query cntrSupplierCategory($where: CntrSupplierCategoryWhereUniqueInput!) {
            cntrSupplierCategory(where: $where) {
                id
                name
                ref
                status
                version_no
                version_user
                effective_from
                can_link_supplier
                is_active
                fields {
                    id
                    field_name
                    is_visible
                    is_mandatory
                    can_add_validate
                    can_add_attachment
                }
            }
        }
    `,

    updateSupplierCategory: gql`
        mutation updateOneCntrSupplierCategory($data: CntrSupplierCategoryUpdateInput!, $where: CntrSupplierCategoryWhereUniqueInput!) {
            updateOneCntrSupplierCategory(data: $data, where: $where) {
                id
                name
                version_no
                version_user
                status
                can_link_supplier
                fields {
                    id
                    field_name
                    is_visible
                    is_mandatory
                    can_add_validate
                    can_add_attachment
                }
            }
        }
    `,
    UpdateSupplierVDetails: gql`
        mutation UpdateSupplierVDetails($data: SupplierRegistrationInput!, $supplierID: String!) {
            UpdateSupplierVDetails(data: $data, supplierID: $supplierID) {
                version_no
                version_user
                category_data {
                    id
                    version_date
                    version_user
                    version_no
                    category_id
                    field_name
                    field_value
                }
            }
        }
    `,

    deleteOneCntrSupplierCategory: gql`
        mutation deleteOneCntrSupplierCategory($where: CntrSupplierCategoryWhereUniqueInput!) {
            deleteOneCntrSupplierCategory(where: $where) {
                status
            }
        }
    `,
    GetRvnRemitsCodesToDisplay: gql`
        query GetRvnRemitsCodesToDisplay {
            GetRvnRemitsCodesToDisplay {
                status
                headers {
                    field
                    header
                }
                data {
                    id
                    isActive
                    field1
                    field2
                    field3
                    field4
                    field4
                    field5
                    field6
                    field7
                    field8
                    field9
                    field10
                }
            }
        }
    `,
    remitCodeDrafts: gql`
        query remitCodeDrafts($skip: Int, $take: Int, $where: RemitCodeDraftWhereInput, $orderBy: [RemitCodeDraftOrderByInput!]) {
            remitCodeDrafts(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                status
                is_latest
                effective_from
                is_effective
                version_no
                version_user
                version_date
                code_details {
                    id
                    draft_id
                    segment_name
                    segment_def_id
                    level_name
                    segment_def_name
                    segment_full_code
                    version_no
                    version_user
                    version_date
                }
            }
        }
    `,
    remitCodeDraftCount: gql`
        query remitCodeDraftCount($where: RemitCodeDraftWhereInput) {
            remitCodeDraftCount(where: $where)
        }
    `,
    rvnRevenueStandardRepsCount: gql`
        query rvnRevenueStandardRepsCount($where: RvnRevenueStandardRepWhereInput) {
            rvnRevenueStandardRepsCount(where: $where)
        }
    `,
    createOneRemitCodeDraft: gql`
        mutation createOneRemitCodeDraft($data: RemitCodeDraftCreateInput!) {
            createOneRemitCodeDraft(data: $data) {
                id
            }
        }
    `,
    updateOneRemitCodeDraft: gql`
        mutation updateOneRemitCodeDraft($where: RemitCodeDraftWhereUniqueInput!, $data: RemitCodeDraftUpdateInput!) {
            updateOneRemitCodeDraft(where: $where, data: $data) {
                id
                status
            }
        }
    `,
    deleteOneRemitCodeDraft: gql`
        mutation deleteOneRemitCodeDraft($where: RemitCodeDraftWhereUniqueInput!) {
            deleteOneRemitCodeDraft(where: $where) {
                id
            }
        }
    `,
    CreateRvnRemitCodes: gql`
        mutation CreateRvnRemitCodes($data: RvnRemitCodesCreateInput!) {
            CreateRvnRemitCodes(data: $data) {
                status
            }
        }
    `,
    deleteOneRvnRemitCode: gql`
        mutation deleteOneRvnRemitCode($where: RvnRemitCodeWhereUniqueInput!) {
            deleteOneRvnRemitCode(where: $where) {
                id
            }
        }
    `,
    GetDistinctRvnRemitCodes: gql`
        query GetDistinctRvnRemitCodes($where: RvnRemitCodeWhereInput) {
            GetDistinctRvnRemitCodes(where: $where) {
                status
                remitCodes {
                    id
                    remit_name
                    payment_mode
                }
            }
        }
    `,
    updateManyRvnRemitCode: gql`
        mutation updateManyRvnRemitCode($data: RvnRemitCodeUpdateManyMutationInput!, $where: RvnRemitCodeWhereInput) {
            updateManyRvnRemitCode(data: $data, where: $where) {
                count
            }
        }
    `,
    rvnRevenueStandardReps: gql`
        query rvnRevenueStandardReps(
            $skip: Int
            $take: Int
            $where: RvnRevenueStandardRepWhereInput
            $orderBy: [RvnRevenueStandardRepOrderByInput!]
        ) {
            rvnRevenueStandardReps(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                name
                status
                version_date
                version_no
                version_user
                fields {
                    field_name
                    report_id
                    id
                }
            }
        }
    `,
    getOnervnRevenueStandardReps: gql`
        query rvnRevenueStandardReps($where: RvnRevenueStandardRepWhereInput) {
            rvnRevenueStandardReps(where: $where) {
                id
                ref
                name
                status
                version_no
                fields {
                    field_name
                    report_id
                    id
                }
            }
        }
    `,
    createOneRvnRevenueStandardRep: gql`
        mutation createOneRvnRevenueStandardRep($data: RvnRevenueStandardRepCreateInput!) {
            createOneRvnRevenueStandardRep(data: $data) {
                id
            }
        }
    `,
    updateOneRvnRevenueStandardRep: gql`
        mutation updateOneRvnRevenueStandardRep($data: RvnRevenueStandardRepUpdateInput!, $where: RvnRevenueStandardRepWhereUniqueInput!) {
            updateOneRvnRevenueStandardRep(data: $data, where: $where) {
                id
            }
        }
    `,
    GetRvnSubRemitCodes: gql`
        query GetRvnSubRemitCodes($parentID: String!) {
            GetRvnSubRemitCodes(parentID: $parentID) {
                status
                segments
            }
        }
    `,
    updateOneRvnRemitCode: gql`
        mutation updateOneRvnRemitCode($data: RvnRemitCodeUpdateInput!, $where: RvnRemitCodeWhereUniqueInput!) {
            updateOneRvnRemitCode(data: $data, where: $where) {
                is_active
            }
        }
    `,
    localities: gql`
        query localities($where: LocalityWhereInput) {
            localities(where: $where, orderBy: { name: asc }) {
                id
                name
                code
                state
                state_code
                district
                district_code
                city
                city_code
            }
        }
    `,
    createOneLocality: gql`
        mutation createOneLocality($data: LocalityCreateInput!) {
            createOneLocality(data: $data) {
                id
                name
                code
                state
                state_code
                district
                district_code
                city
                city_code
            }
        }
    `,
    updateOneLocality: gql`
        mutation updateOneLocality($data: LocalityUpdateInput!, $where: LocalityWhereUniqueInput!) {
            updateOneLocality(data: $data, where: $where) {
                id
                name
                code
                state
                state_code
                district
                district_code
                city
                city_code
            }
        }
    `,
    deleteOneLocality: gql`
        mutation deleteOneLocality($where: LocalityWhereUniqueInput!) {
            deleteOneLocality(where: $where) {
                id
            }
        }
    `,
    LocalitySampleDownload: gql`
        query LocalitySampleDownload {
            LocalitySampleDownload {
                id
                input_name
            }
        }
    `,
    ImportLocalityData: gql`
        mutation ImportLocalityData($file: Upload!) {
            ImportLocalityData(data: { file: $file }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    RemitCodeConfig: gql`
        query RemitCodeConfig($data: RemitInput, $addData: RemitInput) {
            getTable: RemitCodeConfig(data: $data) {
                remittanceList {
                    remittance_name
                    sub_remittances {
                        id
                        sub_remit_name
                    }
                }
            }
            getDropdown: RemitCodeConfig(data: $addData) {
                remittanceList {
                    remittance_name
                    sub_remittances {
                        id
                        sub_remit_name
                    }
                }
            }
        }
    `,
    UpdateRvnRemitCodes: gql`
        mutation UpdateRvnRemitCodes($id: String!, $data: RemitInput!) {
            UpdateRvnRemitCodes(id: $id, data: $data) {
                status
            }
        }
    `,

    deleteOneRvnRevenueStandardRep: gql`
        mutation deleteOneRvnRevenueStandardRep($where: RvnRevenueStandardRepWhereUniqueInput!) {
            deleteOneRvnRevenueStandardRep(where: $where) {
                id
            }
        }
    `,

    cntrSuppliers: gql`
        query cntrSuppliers($where: CntrSupplierWhereInput, $orderBy: [CntrSupplierOrderByInput!], $skip: Int, $take: Int) {
            cntrSuppliers(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                is_effective
                is_latest
                version_no
                id
                name
                ref
                alt_name
                mobile
                category_id
                updated_at_str
                version_date
                status
                parent {
                    id
                }
                accounts {
                    bank_account {
                        id
                        name
                        category
                        type
                        number
                        holder_name
                        bank_id
                        ccy
                        is_active
                        bank
                    }
                    is_primary
                    acc_id
                    supplier_id
                    version_date
                    id
                }
                category {
                    id
                    name
                }
            }
        }
    `,
    deleteOneCntrSupplier: gql`
        mutation deleteOneCntrSupplier($where: CntrSupplierWhereUniqueInput!) {
            deleteOneCntrSupplier(where: $where) {
                status
            }
        }
    `,
    cntrSupplierCategories: gql`
        query cntrSupplierCategories($where: CntrSupplierCategoryWhereInput) {
            cntrSupplierCategories(where: $where, orderBy: { version_date: desc }) {
                id
                name
                ref
                status
                version_user
                version_no
                can_link_supplier
                fields {
                    is_visible
                    is_mandatory
                    field_name
                    can_add_attachment
                    can_add_validate
                    category_id
                }
            }
        }
    `,
    validateHOA: gql`
        query validateHOA(
            $function_name: String!
            $sub_function_name: String!
            $hoa_code: String!
            $segement_to_exclude: String
            $fin_year: String
        ) {
            validateHOA(
                function_name: $function_name
                sub_function_name: $sub_function_name
                hoa_code: $hoa_code
                segement_to_exclude: $segement_to_exclude
                fin_year: $fin_year
            ) {
                status
            }
        }
    `,
    cntrSupplierCategory: gql`
        query cntrSupplierCategory($where: CntrSupplierCategoryWhereUniqueInput) {
            cntrSupplierCategory(where: $where) {
                id
                name
                status
                version_user
                version_no
                can_link_supplier
                fields {
                    is_visible
                    is_mandatory
                    field_name
                    can_add_attachment
                    can_add_validate
                    category_id
                }
            }
        }
    `,
    countries: gql`
        query countries($where: CountryWhereInput) {
            countries(where: $where) {
                id
                name
                ccy
                ccy_code
                states {
                    id
                    name
                }
            }
        }
    `,
    CreateSupplierVDetails: gql`
        mutation CreateSupplierVDetails($data: SupplierRegistrationInput!) {
            CreateSupplierVDetails(data: $data) {
                status
            }
        }
    `,
    getBanksCountries: gql`
        query getBanksCountries {
            countries: countries {
                id
                name
                ccy
                ccy_code
                states {
                    id
                    name
                }
            }
            banks: banks(orderBy: [{ ifsc: asc }]) {
                id
                ifsc
                name
                branch_name
                is_active
            }
        }
    `,
    GetSupplierWithDetail: gql`
        query GetSupplierWithDetail($supplierID: String!) {
            GetSupplierWithDetail(supplierID: $supplierID) {
                status
                version_no
                version_user
                id
                is_active
                name
                alt_name
                ref
                category_id
                skills
                category
                accounts
                addresses
                contacts
                category_data
                supplierIDs
                supplies
                is_parent
            }
        }
    `,
    updateOneScreen: gql`
        mutation updateOneScreen($where: ScreenWhereUniqueInput!, $data: ScreenUpdateInput!) {
            updateOneScreen(where: $where, data: $data) {
                id
                function_code
                function_name
            }
        }
    `,

    updateOneSection: gql`
        mutation updateOneSection($where: SectionWhereUniqueInput!, $data: SectionUpdateInput!) {
            updateOneSection(where: $where, data: $data) {
                id
                code
            }
        }
    `,
    getScreenWiseFieldsToDisplay: gql`
        query getScreenWiseFieldsToDisplay($module_name: String!, $function_name: String!) {
            getScreenWiseFieldsToDisplay(module_name: $module_name, function_name: $function_name) {
                status
                fields_list
                section_list
            }
        }
    `,
    ScreensList: gql`
        query ScreensList {
            ScreensList {
                module_name
                function_list {
                    id
                    function_name
                    function_code
                }
            }
        }
    `,

    updateFieldQuery: gql`
        mutation updateOneField($where: FieldWhereUniqueInput!, $data: FieldUpdateInput!) {
            updateOneField(where: $where, data: $data) {
                id
                name
                desc
                data_type
                is_editable
                is_mandatory
                is_visible
                is_audited
                has_attachment
            }
        }
    `,
    CreateReceipt: gql`
        mutation createOneRvnReceipt($data: RvnReceiptCreateInput!) {
            createOneRvnReceipt(data: $data) {
                id
                ref
                ofc_id
                emp_id
                dept_id
                remittance_name
                transaction_count
                chq_dd_list {
                    id
                    payee
                    amount
                    date
                    num
                    bank_id
                    receipt_id
                }
                amount
                payment_method
            }
        }
    `,
    UpdateReceipt: gql`
        mutation updateOneRvnReceipt($data: RvnReceiptUpdateInput!, $where: RvnReceiptWhereUniqueInput!) {
            updateOneRvnReceipt(data: $data, where: $where) {
                id
                ref
                ofc_id
                emp_id
                dept_id
                remittance_name
                transaction_count
                amount
                payment_method
            }
        }
    `,
    GetOneRvnReceipt: gql`
        query rvnReceipt($where: RvnReceiptWhereUniqueInput!) {
            rvnReceipt(where: $where) {
                id
                payment_method
                emp_id
                remittance_name
                dept_id
                ofc_id
                amount
                date
                hoa_code
                transaction_count
                chq_dd_list {
                    id
                    bank_id
                    num
                    payee
                    amount
                    date
                }
            }
        }
    `,
    deleteOneReceipt: gql`
        mutation deleteOneRvnReceipt($where: RvnReceiptWhereUniqueInput!) {
            deleteOneRvnReceipt(where: $where) {
                id
            }
        }
    `,
    cntrContracts: gql`
        query cntrContracts($orderBy: [CntrContractOrderByInput!], $where: CntrContractWhereInput) {
            cntrContracts(orderBy: $orderBy, where: $where) {
                id
                year_id
                status
                id
                sanct_agency_id
                name
                pay_term
                pay_method
                ref
                is_latest
                is_effective
                version_date
                closing_date
                signing_date
                supplier {
                    name
                    id
                    accounts {
                        id
                        is_primary
                        bank_account {
                            id
                            is_active
                            category
                            type
                            number
                            holder_name
                            bank
                        }
                    }
                    ref
                }
                on_total_val
                supplies {
                    fin_sanctions {
                        amount
                    }
                    hoa
                    supply_category {
                        name
                        id
                    }
                    more_data {
                        advances {
                            advance_id
                            advance {
                                name
                                code
                            }
                            rate
                            amount
                            recovery_method
                        }
                        sec_deposit {
                            rate
                            id
                            is_default
                            deposit_deduction {
                                is_editable
                                hoa
                            }
                        }
                        pay_schedules {
                            sequence
                            hoa
                            id
                            adv_recov_amount
                            mile_stone
                            amount_incl_tax
                            amount_excl_tax
                            out_standing_amount
                            excl_out_standing_amount
                        }
                    }
                }
                more_data {
                    advances {
                        advance_id
                        advance {
                            name
                            code
                        }
                        rate
                        amount
                        recovery_method
                    }
                    sec_deposit {
                        rate
                        id
                        is_default
                        deposit_deduction {
                            is_editable
                            hoa
                        }
                    }
                    pay_schedules {
                        sequence
                        hoa
                        id
                        adv_recov_amount
                        out_standing_amount
                        mile_stone
                        amount_incl_tax
                        amount_excl_tax
                        excl_out_standing_amount
                    }
                }
            }
        }
    `,

    deleteOneCntrContract: gql`
        mutation deleteOneCntrContract($where: CntrContractWhereUniqueInput!) {
            deleteOneCntrContract(where: $where) {
                status
            }
        }
    `,
    cntrContractCount: gql`
        query cntrContractCount($where: CntrContractWhereInput) {
            cntrContractCount(where: $where)
        }
    `,
    getContracts: gql`
        query cntrContracts($orderBy: [CntrContractOrderByInput!], $where: CntrContractWhereInput, $skip: Int, $take: Int) {
            cntrContracts(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
                id
                year_id
                status
                name
                ref
                version_no
                is_latest
                is_effective
                version_date
                version_user
                signing_date
                effective_from
                extended_ref
                contract_status
                remarks
                closing_date
                more_data {
                    id
                    pay_schedules {
                        out_standing_amount
                    }
                }
                sanct_agency {
                    segment_full_code
                }
                supplier {
                    id
                    name
                    ref
                }
                supplies {
                    amount_incl_tax
                    more_data {
                        id
                        pay_schedules {
                            out_standing_amount
                        }
                    }
                    fin_sanctions {
                        amount
                    }
                    supply_category {
                        name
                        id
                    }
                }
            }
        }
    `,
    generalInfoForm: gql`
        query coaSegmentSelection($prjcts: CntrProjectWhereInput) {
            sanction: sanctionAgenciesAuthorized(functionCode: "CNTR_CONTRACT_CREATION_R") {
                id
                segment_name
                segment_full_code
            }
            implement: implementingAgencies(orderBy: [{ segment_full_code: asc }]) {
                id
                segment_name
                segment_full_code
            }
            projects: cntrProjects(where: $prjcts, orderBy: [{ project_name: asc }]) {
                id
                project_name
                project_number
            }
        }
    `,
    advanceForm: gql`
        query cntrAdvances($where: CntrAdvanceWhereInput) {
            advances: cntrAdvances(where: $where, orderBy: [{ name: asc }]) {
                id
                code
                name
                ref
                is_latest
                codes {
                    id
                }
            }
        }
    `,
    securityDepositForm: gql`
        query cntrDeductionRates($where: CntrDeductionRateWhereInput) {
            cntrDeductionRates(where: $where) {
                rate
                id
                is_default
                deposit_deduction {
                    is_editable
                }
            }
        }
    `,
    deductionsForm: gql`
        query deductions($where: DeductionWhereInput, $orderBy: [DeductionOrderByInput!]) {
            deductions(where: $where, orderBy: $orderBy) {
                type
                id
                code
                value
                is_editable
                can_add_remarks
                is_latest
                info
                is_active
                name
            }
        }
    `,
    GetRemittanceHoaCode: gql`
        query RemittanceHoaCode {
            RemittanceHoaCode {
                remittanceList {
                    remittance_name
                    id
                    hoa
                }
            }
        }
    `,
    createContactSuppliers: gql`
        query cntrSuppliers(
            $where: CntrDeductionRateWhereInput
            $cntrwhere: CntrSupplierWhereInput
            $cntrAdvanceWhere: CntrAdvanceWhereInput
        ) {
            advances: cntrAdvances(where: $cntrAdvanceWhere, orderBy: [{ name: asc }]) {
                id
                code
                name
                ref
                is_latest
                codes {
                    id
                }
            }
            cntrDeductionRates(where: $where) {
                rate
                id
                is_default
                deposit_deduction {
                    is_editable
                }
            }
            cntrSuppliers(where: $cntrwhere, orderBy: [{ name: asc }]) {
                id
                ref
                name
                contacts {
                    id
                    contact {
                        first_name
                        last_name
                        middle_name
                        salutation
                        mobile
                        designation
                        alt_mobile
                        email
                        fax
                    }
                }
                supplies {
                    supply {
                        name
                        id
                        is_active
                    }
                }
            }
        }
    `,
    advIns: gql`
        query advIns($where: CntrAdvanceWhereInput) {
            advances: cntrAdvances(where: $where, orderBy: [{ name: asc }]) {
                id
                code
                name
                ref
                is_latest
                codes {
                    id
                }
            }
        }
    `,
    getCntrctNPaymntBillStatus: gql`
        query getCntrctNPaymntBillStatus($contract_ref: String!) {
            getCntrctNPaymntBillStatus(contract_ref: $contract_ref)
        }
    `,
    getContractByID: gql`
        query cntrContract($where: CntrContractWhereUniqueInput!) {
            cntrContract(where: $where) {
                year_id
                id
                name
                ref
                version_no
                is_latest
                is_effective
                version_date
                version_user
                effective_from
                description
                status
                extended_ref
                contract_status
                remarks
                supplier {
                    id
                    name
                    accounts {
                        id
                        is_primary
                        bank_account {
                            id
                            is_active
                            category
                            type
                            number
                            holder_name
                            bank
                        }
                    }
                    ref
                }
                supplier_contact {
                    id
                }
                sanct_agency_id
                impl_agency_id
                ccy
                signing_date
                closing_date
                govt_ref
                adm_ref
                tech_ref
                proc_ref
                pay_term
                pay_method
                project_id
                bank_guarantees {
                    id
                    bank_id
                    ref
                    expiry
                    amount
                }
                on_total_val
                supplies {
                    id
                    amount_incl_tax
                    amount_excl_tax
                    hoa
                    supply_category {
                        id
                        name
                    }
                    variance
                    fin_sanctions {
                        year_id
                        ref
                        amount
                        id
                    }
                    more_data {
                        advances {
                            advance_id
                            advance {
                                name
                                code
                            }
                            rate
                            amount
                            hoa
                            recovery_method
                        }
                        sec_deposit {
                            rate
                            id
                            is_default
                            deposit_deduction {
                                is_editable
                                hoa
                            }
                        }
                        refund_in_months
                        deductions {
                            deduction_id
                            amount
                            rate
                        }
                        pay_schedules {
                            id
                            sequence
                            mile_stone
                            due_date
                            hoa
                            amount_incl_tax
                            amount_excl_tax
                            adv_recov_amount
                            sec_dep_amount
                            other_deduction_amount
                            out_standing_amount
                            excl_out_standing_amount
                            net_pay_amount
                            adv_recov_amount
                            deduction_details {
                                deduction
                                deduction_id
                                amount
                                remarks
                                is_edited
                            }
                            advance_recoveries {
                                advance {
                                    id
                                    name
                                    code
                                }
                                amount
                                is_edited
                            }
                        }
                    }
                }
                more_data {
                    advances {
                        advance_id
                        advance {
                            name
                            code
                        }
                        rate
                        amount
                        hoa
                        recovery_method
                    }
                    refund_in_months
                    sec_deposit {
                        rate
                        id
                        is_default
                        deposit_deduction {
                            is_editable
                            hoa
                        }
                    }
                    deductions {
                        deduction_id
                        amount
                        rate
                    }
                    pay_schedules {
                        sequence
                        id
                        mile_stone
                        hoa
                        due_date
                        amount_incl_tax
                        amount_excl_tax
                        adv_recov_amount
                        sec_dep_amount
                        other_deduction_amount
                        excl_out_standing_amount
                        out_standing_amount
                        adv_recov_amount
                        net_pay_amount
                        deduction_details {
                            deduction
                            deduction_id
                            amount
                            remarks
                            is_edited
                        }
                        advance_recoveries {
                            advance {
                                id
                                name
                                code
                            }
                            amount
                            is_edited
                        }
                    }
                }
            }
        }
    `,
    createContractForm: gql`
        query createContractForm {
            appEnums(where: { enum_type: { equals: "ADMISSIBLE_VARIANCE" }, enum_group: { equals: "CONTRACT_MANAGEMENT" } }) {
                enum_type
                enum_value
                enum_group
            }
            current_fin_year {
                id
                name
                master_start_date
            }
            banks(orderBy: [{ name: asc }]) {
                id
                ifsc
                name
                branch_name
                is_active
            }
            deductions(where: { is_effective: { equals: true } }, orderBy: [{ name: asc }]) {
                type
                id
                code
                value
                is_editable
                can_add_remarks
                info
                is_active
                name
                ref
                is_latest
                exceptions {
                    fmis_function
                    applicability
                }
            }
        }
    `,
    deductionIns: gql`
        query deductionIns($where: DeductionWhereInput) {
            deductions(where: $where, orderBy: [{ name: asc }]) {
                type
                id
                code
                value
                is_editable
                can_add_remarks
                info
                is_active
                name
                ref
                is_latest
                exceptions {
                    fmis_function
                    applicability
                }
            }
        }
    `,
    CreateContract: gql`
        mutation createOneCntrContract($data: CntrContractCreateInput!) {
            createOneCntrContract(data: $data) {
                id
            }
        }
    `,
    getReceiptEntries: gql`
        query {
            rvnReceipts(where: { status: { equals: "Draft" } }) {
                id
                ofc_id
                office_dept
                emp_id
                hoa_code
                remittance_name
                dept_id
                transaction_count
                payment_method
                payment_status
                date
                amount
                chq_dd_list {
                    id
                    bank_id
                    num
                    payee
                    amount
                    date
                }
            }
        }
    `,
    saveReceiptEntries: gql`
        mutation approveTheReceipts($data: ReceiptAprrovalInp) {
            approveTheReceipts(data: $data) {
                status
            }
        }
    `,
    updateOneCntrContract: gql`
        mutation updateOneCntrContract($data: CntrContractUpdateInput!, $where: CntrContractWhereUniqueInput!) {
            updateOneCntrContract(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    getHOAForSupplyCats: gql`
        query GetCntrSupplyCategoryMappings($category_id: String!, $functionCode: String, $officeCode: String, $activityCode: String) {
            GetCntrSupplyCategoryMappings(
                category_id: $category_id
                functionCode: $functionCode
                officeCode: $officeCode
                activityCode: $activityCode
            ) {
                status
                compositionWiseSegments {
                    name
                    level_name
                    segments
                }
            }
        }
    `,
    GetCntrAdvanceMappings: gql`
        query GetCntrAdvanceMappings($category_id: String!, $functionCode: String, $officeCode: String, $activityCode: String) {
            GetCntrAdvanceMappings(
                category_id: $category_id
                functionCode: $functionCode
                officeCode: $officeCode
                activityCode: $activityCode
            ) {
                status
                compositionWiseSegments {
                    name
                    level_name
                    segments
                }
            }
        }
    `,
    exchRates: gql`
        query exchRates($orderBy: [ExchRateOrderByInput!], $where: ExchRateWhereInput) {
            exchRates(orderBy: $orderBy, where: $where) {
                id
                exch_rate
                country_id
                ccy
                is_editable
                version_date
                country {
                    id
                    name
                    ccy
                    ccy_code
                }
            }
        }
    `,
    GetCurrency: gql`
        query GetCurrency {
            GetCurrency {
                currencyList {
                    currency
                    countries {
                        name
                        id
                        ccy
                        ccy_code
                    }
                }
            }
        }
    `,
    createOneExchRate: gql`
        mutation createOneExchRate($data: ExchRateCreateInput!) {
            createOneExchRate(data: $data) {
                id
                exch_rate
                country_id
                ccy
                is_editable

                country {
                    id
                    name
                    ccy
                    ccy_code
                }
            }
        }
    `,
    updateOneExchRate: gql`
        mutation updateOneExchRate($data: ExchRateUpdateInput!, $where: ExchRateWhereUniqueInput!) {
            updateOneExchRate(data: $data, where: $where) {
                id
                exch_rate
                country_id
                ccy
                is_editable

                country {
                    id
                    name
                    ccy
                    ccy_code
                }
            }
        }
    `,
    deleteOneExchRate: gql`
        mutation deleteOneExchRate($where: ExchRateWhereUniqueInput!) {
            deleteOneExchRate(where: $where) {
                id
            }
        }
    `,
    ExchangeRateSampleDownload: gql`
        query ExchangeRateSampleDownload {
            ExchangeRateSampleDownload {
                id
                input_name
            }
        }
    `,
    ImportExchangeRate: gql`
        mutation ImportExchangeRate($file: Upload!) {
            ImportExchangeRate(data: { file: $file }) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                }
            }
        }
    `,
    GetDailyCashReport: gql`
        query GetDailyCashReport($params: DailyCashReportParams!) {
            GetDailyCashReport(params: $params) {
                status
                details {
                    type
                    total_amount
                    count
                    data
                }
            }
        }
    `,
    GetSummaryReport: gql`
        query GetSummaryReport($params: DailyCashReportParams!) {
            GetSummaryReport(params: $params) {
                status
                error
                fileID
                input_name
            }
        }
    `,
    GetDetailReport: gql`
        query GetDetailReport($params: DailyCashReportParams!) {
            GetDetailReport(params: $params) {
                status
                error
                fileID
                input_name
            }
        }
    `,
    updateCollectionCashBook: gql`
        mutation UpdateRvnCashBookDetail($data: RvnCashBookUpdateInput) {
            UpdateRvnCashBookDetail(data: $data) {
                status
            }
        }
    `,
    contractBillCount: gql`
        query contractBillCount($where: ContractBillWhereInput) {
            contractBillCount(where: $where)
        }
    `,

    imprestAdjustmentCount: gql`
        query imprestAdjustmentCount($where: ImprestAdjustmentWhereInput) {
            imprestAdjustmentCount(where: $where)
        }
    `,
    imprestReleaseCount: gql`
        query imprestReleaseCount($where: ImprestReleaseWhereInput) {
            imprestReleaseCount(where: $where)
        }
    `,
    createOneContractBillConf: gql`
        mutation createOneContractBillConf($data: ContractBillConfCreateInput!) {
            createOneContractBillConf(data: $data) {
                status
            }
        }
    `,
    updateOneContractBillConf: gql`
        mutation updateOneContractBillConf($data: ContractBillConfUpdateInput!, $where: ContractBillConfWhereUniqueInput!) {
            updateOneContractBillConf(data: $data, where: $where) {
                status
            }
        }
    `,
    contractBillConf: gql`
        query contractBillConf($where: ContractBillConfWhereUniqueInput!) {
            contractBillConf(where: $where) {
                id
                ref
                is_latest
                is_effective
                effective_from
                status
                version_date
                version_no
                version_user
                bill_type
                bill_code
                perf_showcase
                incl_sanctions
                is_active
                incl_deductions
                deductions {
                    deduction_id
                    deduction
                    supplies {
                        supply_id
                    }
                    is_mandatory
                }
            }
        }
    `,
    GetTheListOfAdvances: gql`
        query GetTheListOfAdvances($where: AdvanceListParams) {
            GetTheListOfAdvances(where: $where) {
                bill_type
                bill_number
                amount
                approved_date
            }
        }
    `,
    GetTheListOfSecurityDeposits: gql`
        query GetTheListOfSecurityDeposits($where: SecurityDepositsParams) {
            GetTheListOfSecurityDeposits(where: $where) {
                bill_type
                bill_number
                amount
                approved_date
            }
        }
    `,
    GetTheListOfSecurityDepositsAndRaisedBills: gql`
        query GetTheListOfSecurityDepositsAndRaisedBills($where: SecurityDepositsParams, $where1: ContractBillDetailWhereInput) {
            GetTheListOfSecurityDeposits(where: $where) {
                bill_type
                bill_number
                amount
                approved_date
            }
            securityDepositBills: contractBillDetails(where: $where1) {
                id
                pay_schedule_id
                amount_incl_tax
                bill {
                    id
                    ref
                    updated_at_str
                    status
                    is_effective
                }
            }
        }
    `,
    amountPendingPayment: gql`
        query amountPendingPayment($data: MilestoneInput!) {
            amountPendingPayment(data: $data)
        }
    `,
    GetAdvancedRecoveriesOnBills: gql`
        query GetAdvancedRecoveriesOnBills($contract_id: String!, $bill_type: String!, $bill_id: String) {
            GetAdvancedRecoveriesOnBills(contract_id: $contract_id, bill_type: $bill_type, bill_id: $bill_id)
        }
    `,

    contractBillConfs: gql`
        query contractBillConfs($where: ContractBillConfWhereInput, $orderBy: [ContractBillConfOrderByInput!], $skip: Int, $take: Int) {
            contractBillConfs(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
                id
                ref
                is_latest
                is_effective
                effective_from
                status
                version_date
                version_no
                version_user
                bill_type
                bill_code
                perf_showcase
                incl_sanctions
                is_active
                incl_deductions
                deductions {
                    deduction_id
                    deduction
                    supplies {
                        supply_id
                    }
                    is_mandatory
                }
            }
        }
    `,

    deleteOneContractBillConf: gql`
        mutation deleteOneContractBillConf($where: ContractBillConfWhereUniqueInput!) {
            deleteOneContractBillConf(where: $where) {
                status
            }
        }
    `,
    ProcessNExportStandardReport: gql`
        query ProcessNExportStandardReport($data: StandardReportParams!) {
            ProcessNExportStandardReport(data: $data) {
                file_info
                headers
                data
            }
        }
    `,
    fetchEnumValue: gql`
        query appEnums {
            appEnums(
                where: { enum_type: { equals: "PAN_AMOUNT_CONFIG" }, enum_group: { equals: "COMMONS_MASTER" }, status: { equals: "A" } }
            ) {
                enum_value
            }
        }
    `,
    fetchStaffDetails: gql`
        query GetStaffDetails {
            GetStaffDetails {
                status
                office_list
            }
        }
    `,
    fetchChallansToDepositList: gql`
        query GetListOfChallansToDeposit {
            GetListOfChallansToDeposit {
                status
                details {
                    type
                    total_amount
                    count
                    data
                }
            }
        }
    `,
    createDepositVoucher: gql`
        mutation createOneRvnDepositVoucher($data: RvnDepositVoucherCreateInput!) {
            createOneRvnDepositVoucher(data: $data) {
                id
                ref
                acc_num
                payment_type
                amount
                status
            }
        }
    `,
    updateDepositVoucher: gql`
        mutation updateOneRvnDepositVoucher($data: RvnDepositVoucherUpdateInput!, $where: RvnDepositVoucherWhereUniqueInput!) {
            updateOneRvnDepositVoucher(data: $data, where: $where) {
                id
                ref
                acc_num
                payment_type
                amount
                status
            }
        }
    `,
    rvnDepositVouchersCount: gql`
        query rvnDepositVouchersCount($where: RvnDepositVoucherWhereInput) {
            rvnDepositVouchersCount(where: $where)
        }
    `,
    fetchRvnDepositList: gql`
        query rvnDepositVouchers($where: RvnDepositVoucherWhereInput, $orderBy: [RvnDepositVoucherOrderByInput!], $skip: Int, $take: Int) {
            rvnDepositVouchers(orderBy: $orderBy, where: $where, take: $take, skip: $skip) {
                id
                status
                ref
                acc_num
                payment_type
                amount
                challans {
                    id
                    dv_id
                    status
                    source_id
                    source_ref
                    type
                }
            }
        }
    `,
    sanctioningAgency: gql`
        query sanctioningAgency {
            sanctioningAgencies {
                id
                segment_name
                segment_full_code
            }
        }
    `,
    otherBillConfs: gql`
        query otherBillConfs($skip: Int, $take: Int, $orderBy: [OtherBillConfOrderByInput!], $where: OtherBillConfWhereInput) {
            otherBillConfs(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                is_latest
                is_effective
                status
                version_date
                version_no
                version_user
                code
                description
                is_active
                is_refund_related
                incl_santion_detail
                supplier_applicability
                third_party_applicability
                beneficary_categories {
                    id
                    beneficary_category_id
                }
                report_type
                bills {
                    id
                }
                version_date
                deductions {
                    id
                    deduction_id
                    other_bill_id
                    is_mandatory
                    deduction
                }
            }
        }
    `,
    GetOtherBillConfMappings: gql`
        query GetOtherBillConfMappings($conf_id: String!, $functionCode: String, $activityCode: String, $officeCode: String) {
            GetOtherBillConfMappings(conf_id: $conf_id, functionCode: $functionCode, activityCode: $activityCode, officeCode: $officeCode) {
                status
                compositionWiseSegments {
                    name
                    level_name
                    segments
                }
            }
        }
    `,
    otherBillConfFindOne: gql`
        query otherBillConf($where: OtherBillConfWhereUniqueInput!) {
            otherBillConf(where: $where) {
                id
                ref
                code
                description
                is_active
                is_refund_related
                incl_santion_detail
                is_latest
                is_effective
                status
                version_date
                version_no
                version_user
                supplier_applicability
                third_party_applicability
                beneficary_categories {
                    id
                    beneficary_category_id
                }
                report_type
                bills {
                    id
                }
                codes {
                    id
                    conf_id
                    segment_full_code
                    level_name
                    segment_name
                }
                deductions {
                    id
                    deduction_id
                    other_bill_id
                    is_mandatory
                    deduction
                }
            }
        }
    `,
    otherBillConfFew: gql`
        query otherBillConfs($where: OtherBillConfWhereInput, $orderBy: [OtherBillConfOrderByInput!]) {
            otherBillConfs(where: $where, orderBy: $orderBy) {
                id
                code
            }
        }
    `,
    createOneOtherBillConf: gql`
        mutation createOneOtherBillConf($data: OtherBillConfCreateInput!) {
            createOneOtherBillConf(data: $data) {
                id
                status
            }
        }
    `,
    imprestReleases: gql`
        query imprestReleases($skip: Int, $take: Int, $orderBy: [ImprestReleaseOrderByInput!], $where: ImprestReleaseWhereInput!) {
            imprestReleases(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                out_standing_amount
                id
                ref
                year_id
                amount
                hoa
                is_latest
                is_effective
                adjustmemt_date
                beneficiary_id
                budget_ofc_id
                description
                period
                status
                budget_ofc
                version_date
                beneficiary
            }
        }
    `,
    imprestReleaseFindOne: gql`
        query imprestRelease($where: ImprestReleaseWhereUniqueInput!) {
            imprestRelease(where: $where) {
                out_standing_amount
                id
                ref
                is_latest
                bank_acc_id
                pay_method
                year_id
                amount
                hoa
                adjustmemt_date
                beneficiary_id
                budget_ofc_id
                description
                period
                budget_ofc
                updated_at_str
                beneficiary
                version_user
                version_no
                version_date
                imprest_conf {
                    amount
                }
            }
        }
    `,
    imprestReleaseWhere: gql`
        query imprestReleases($where: ImprestReleaseWhereInput!) {
            imprestReleases(where: $where) {
                out_standing_amount
                id
                ref
                year_id
                amount
                hoa
                adjustmemt_date
                beneficiary_id
                beneficiary
                is_latest
                budget_ofc_id
                description
                period
                budget_ofc
            }
        }
    `,
    imprestAdjustments: gql`
        query imprestAdjustments($skip: Int, $take: Int, $orderBy: [ImprestAdjustmentOrderByInput!], $where: ImprestAdjustmentWhereInput!) {
            imprestAdjustments(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                imprest_id
                gross_bill
                version_date
                ref
                status
                is_latest
                imprest {
                    ref
                    budget_ofc
                    budget_ofc_id
                    beneficiary_id
                    amount
                    beneficiary
                    out_standing_amount
                }
            }
        }
    `,

    imprestAdjustmentFindOne: gql`
        query imprestAdjustment($where: ImprestAdjustmentWhereUniqueInput!) {
            imprestAdjustment(where: $where) {
                id
                ref
                is_latest
                imprest_id
                gross_bill
                updated_at_str
                version_date
                version_no
                version_user
                details {
                    invoice_num
                    invoice_date
                    amount
                    hoa
                    description
                    attachments {
                        file_id
                        file
                    }
                    id
                }
                imprest {
                    ref
                    budget_ofc
                    budget_ofc_id
                    beneficiary_id
                    amount
                    beneficiary
                    out_standing_amount
                    adjustmemt_date
                    imprest_conf {
                        amount
                    }
                    period
                    year_id
                }
            }
        }
    `,
    imprestConfs: gql`
        query imprestConfs($skip: Int, $take: Int, $orderBy: [ImprestConfOrderByInput!], $where: ImprestConfWhereInput!) {
            imprestConfs(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                status
                budget_ofc
                budget_ofc_id
                adjustment_time
                amount
                budget_ofc
                version_date
                is_latest
                effective_from
                is_active
                is_effective
                version_no
                version_user
                updated_at_str
            }
        }
    `,
    imprestConfFindOne: gql`
        query imprestConf($where: ImprestConfWhereUniqueInput!) {
            imprestConf(where: $where) {
                id
                ref
                status
                budget_ofc
                budget_ofc_id
                adjustment_time
                amount
                budget_ofc
                version_date
                is_latest
                effective_from
                is_active
                is_effective
                version_no
                version_user
                updated_at_str
            }
        }
    `,
    createOneImprestConf: gql`
        mutation createOneImprestConf($data: ImprestConfCreateInput!) {
            createOneImprestConf(data: $data) {
                status
            }
        }
    `,
    createManyImprestConf: gql`
        mutation CreateManyImprestConf($data: ImprestConfBulkCreateInput!) {
            CreateManyImprestConf(data: $data) {
                status
            }
        }
    `,
    updateOneImprestConf: gql`
        mutation updateOneImprestConf($data: ImprestConfUpdateInput!, $where: ImprestConfWhereUniqueInput!) {
            updateOneImprestConf(data: $data, where: $where) {
                id
                budget_ofc_id
                amount
                adjustment_time
            }
        }
    `,
    deleteOneImprestConf: gql`
        mutation deleteOneImprestConf($where: ImprestConfWhereUniqueInput!) {
            deleteOneImprestConf(where: $where) {
                id
            }
        }
    `,
    budgetOfficesList: gql`
        query budgetOffices($orderBy: [BudgetOfficeOrderByInput!]) {
            budgetOffices(orderBy: $orderBy) {
                id
                segment_full_code
                segment_name
            }
        }
    `,
    budgetOfficesAuthorized: gql`
        query budgetOfficesAuthorized($functionCode: String!) {
            budgetOfficesAuthorized(functionCode: $functionCode) {
                id
                segment_full_code
                segment_name
            }
        }
    `,
    ImprestConfsAuthorized: gql`
        query ImprestConfsAuthorized($functionCode: String!) {
            ImprestConfsAuthorized(functionCode: $functionCode) {
                id
                ref
                budget_ofc_id
                adjustment_time
                amount
                budget_ofc
                version_date
                updated_at_str
            }
        }
    `,
    createOneImprestRelease: gql`
        mutation createOneImprestRelease($data: ImprestReleaseCreateInput!) {
            createOneImprestRelease(data: $data) {
                id
            }
        }
    `,
    updateOneImprestRelease: gql`
        mutation updateOneImprestRelease($data: ImprestReleaseUpdateInput!, $where: ImprestReleaseWhereUniqueInput!) {
            updateOneImprestRelease(data: $data, where: $where) {
                version_no
                version_user
            }
        }
    `,
    updateOneImprestAdjustment: gql`
        mutation updateOneImprestAdjustment($data: ImprestAdjustmentUpdateInput!, $where: ImprestAdjustmentWhereUniqueInput!) {
            updateOneImprestAdjustment(data: $data, where: $where) {
                version_no
                version_user
            }
        }
    `,
    createOneImprestAdjustment: gql`
        mutation createOneImprestAdjustment($data: ImprestAdjustmentCreateInput!) {
            createOneImprestAdjustment(data: $data) {
                id
            }
        }
    `,
    deleteOneImprestAdjustment: gql`
        mutation deleteOneImprestAdjustment($where: ImprestAdjustmentWhereUniqueInput!) {
            deleteOneImprestAdjustment(where: $where) {
                id
            }
        }
    `,
    deleteOneImprestRelease: gql`
        mutation deleteOneImprestRelease($where: ImprestReleaseWhereUniqueInput!) {
            deleteOneImprestRelease(where: $where) {
                id
            }
        }
    `,
    beneficiaries: gql`
        query beneficiaries($orderBy: [BeneficiaryOrderByInput!], $where: BeneficiaryWhereInput, $skip: Int, $take: Int) {
            beneficiaries(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                name
                status
                type_id
                ref
                aadhar
                aadhar_ref
                pan
                gstin
                category_name
                version_date
                version_date_str
                is_active
                is_latest
                is_effective
                effective_from
                type {
                    name
                    id
                }
                accounts {
                    id
                    beneficiary_id
                    acc_id
                    is_primary
                    account {
                        id
                        name
                        category
                        is_active
                        number
                        holder_name
                        ccy
                        bank_id
                        type
                        bank {
                            ifsc
                            branch_name
                            name
                        }
                    }
                }
                budget_offices {
                    budget_office_id
                    budget_office_codes {
                        id
                        segment_full_code
                        segment_name
                    }
                }
                employees {
                    employee_id
                }
            }
        }
    `,
    getAllBeneficiaries: gql`
        query beneficiaries($orderBy: [BeneficiaryOrderByInput!], $where: BeneficiaryWhereInput, $skip: Int, $take: Int) {
            beneficiaries(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                type {
                    name
                    id
                }
                aadhar
                aadhar_ref
                pan
                name
                is_active
                ref
                gstin
                category_name
                version_date
                version_no
                version_user
                is_latest
                is_effective
                effective_from
                accounts {
                    is_primary
                    account {
                        id
                        name
                        category
                        type
                        number
                        holder_name
                        ccy
                        bank_id
                        is_active
                        bank {
                            ifsc
                            branch_name
                            name
                            is_active
                        }
                    }
                }
                budget_offices {
                    budget_office_codes {
                        id
                        segment_full_code
                        segment_name
                    }
                }
                employees {
                    employee_id
                }
            }
        }
    `,

    CreateOneBeneficiary: gql`
        mutation CreateOneBeneficiary($data: BeneficiaryCreatInput!) {
            CreateOneBeneficiary(data: $data) {
                status
            }
        }
    `,
    UpdateOneBeneficiary: gql`
        mutation UpdateOneBeneficiary($id: String!, $data: BeneficiaryCreatInput!) {
            UpdateOneBeneficiary(id: $id, data: $data) {
                status
            }
        }
    `,

    beneficiary: gql`
        query beneficiary($where: BeneficiaryWhereUniqueInput!) {
            beneficiary(where: $where) {
                id
                type {
                    name
                    id
                }
                aadhar
                aadhar_ref
                pan
                name
                gstin
                is_active
                ref
                category_name
                version_date
                version_no
                version_user
                is_latest
                is_effective
                effective_from
                accounts {
                    is_primary
                    account {
                        id
                        name
                        category
                        type
                        number
                        holder_name
                        ccy
                        bank_id
                        is_active
                        bank {
                            ifsc
                            branch_name
                            name
                            is_active
                        }
                    }
                }
                budget_offices {
                    budget_office_codes {
                        id
                        segment_full_code
                        segment_name
                    }
                }
                employees {
                    employee_id
                }
            }
        }
    `,
    budgetOffices: gql`
        query budgetOffices($where: BudgetOfficeWhereInput) {
            budgetOffices(where: $where) {
                id
                segment_name
                segment_full_code
            }
        }
    `,
    createOneBeneficiary: gql`
        mutation createOneBeneficiary($data: BeneficiaryCreateInput!) {
            createOneBeneficiary(data: $data) {
                id
                status
            }
        }
    `,
    deleteOneOtherBillConf: gql`
        mutation deleteOneOtherBillConf($where: OtherBillConfWhereUniqueInput!) {
            deleteOneOtherBillConf(where: $where) {
                id
            }
        }
    `,
    updateOneOtherBillConf: gql`
        mutation updateOneOtherBillConf($data: OtherBillConfUpdateInput!, $where: OtherBillConfWhereUniqueInput!) {
            updateOneOtherBillConf(data: $data, where: $where) {
                id
            }
        }
    `,
    createOneContractBill: gql`
        mutation createOneContractBill($data: ContractBillCreateInput!) {
            createOneContractBill(data: $data) {
                id
            }
        }
    `,
    updateOneContractBill: gql`
        mutation updateOneContractBill($data: ContractBillUpdateInput!, $where: ContractBillWhereUniqueInput!) {
            updateOneContractBill(data: $data, where: $where) {
                version_no
                version_user
            }
        }
    `,
    contractBills: gql`
        query contractBills($where: ContractBillWhereInput, $orderBy: [ContractBillOrderByInput!], $skip: Int, $take: Int) {
            contractBills(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                version_date
                version_no
                version_user
                is_latest
                is_effective
                year_id
                period
                status
                contract
                contract_id
                net_payment_amount
                type {
                    bill_type
                    bill_code
                }
                details {
                    amount_incl_tax
                    amount_excl_tax
                    deductions {
                        deduction_id
                        amount
                    }
                    advance_recoveries {
                        amount
                    }
                    sec_deposit_amount
                }
            }
        }
    `,
    getContractBillsData: gql`
        query contractBills($where: ContractBillWhereInput, $orderBy: [ContractBillOrderByInput!], $skip: Int, $take: Int) {
            contractBills(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                contract_id
                status
                is_effective
                type {
                    bill_type
                }
            }
        }
    `,
    contractBill: gql`
        query contractBill($where: ContractBillWhereUniqueInput!) {
            contractBill(where: $where) {
                ref
                id
                is_latest
                is_effective
                version_user
                version_no
                version_date
                type {
                    bill_type
                    incl_sanctions
                    incl_deductions
                    deductions {
                        deduction
                    }
                }
                created_by
                type_id
                supplier_bk_acc_id
                ref
                contract
                pay_method
                contract_id
                net_payment_amount
                year_id
                period
                sanction_num
                sanction_date
                sanction_amount
                sanction_authority
                status
                invoice_type
                invoice_date
                description
                details {
                    id
                    hoa
                    pay_schedule_id
                    amount_incl_tax
                    pay_schedule
                    amount_excl_tax
                    deduction_rate
                    sec_deposit_id
                    sec_deposit_amount
                    advance_recoveries {
                        amount
                        advance_id
                        advance_recovery
                        value
                    }
                    deductions {
                        id
                        is_mandatory
                        deduction
                        is_edited
                        deduction_id
                        amount
                        value
                        remarks
                    }
                }
            }
        }
    `,
    bills: gql`
        query bills($where: BillWhereInput, $orderBy: [BillOrderByInput!]) {
            bills(where: $where, orderBy: $orderBy) {
                release_date
                version_date
                payee_details {
                    payee_name
                    acc_num
                    bank
                }
                status
                vouchers {
                    id
                    release_amount
                    pi_status
                }
            }
        }
    `,
    billTxNSeq: gql`
        query billTxNSeq($id: String!) {
            billTxNSeq(id: $id)
        }
    `,
    deleteOneContractBill: gql`
        mutation deleteOneContractBill($where: ContractBillWhereUniqueInput!) {
            deleteOneContractBill(where: $where) {
                id
            }
        }
    `,
    downloadVoucher: gql`
        query DownloadVoucherInPdf($params: VoucherParams!) {
            DownloadVoucherInPdf(params: $params) {
                status
                error
                fileID
                input_name
            }
        }
    `,
    GetImprestCreationDetail: gql`
        query GetImprestCreationDetail($budget_office_id: String!, $period: String!) {
            GetImprestCreationDetail(budget_office_id: $budget_office_id, period: $period) {
                adjustment_date
                un_adjusted_amount
                imprest_amount
                prev_un_adjusted_amount
            }
        }
    `,
    otherBills: gql`
        query otherBills($skip: Int, $take: Int, $orderBy: [OtherBillOrderByInput!], $where: OtherBillWhereInput!) {
            otherBills(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                is_latest
                type {
                    code
                }
                period
                type_id
                version_date
                gross_bill_amount
                net_payment_amount
                year_id
                budget_ofc_id
                budget_ofc
                status
                version_user
                version_no
                beneficiary_id
                beneficiary
                supplier_id
                supplier
            }
        }
    `,
    otherBillFindOne: gql`
        query otherBill($where: OtherBillWhereUniqueInput!) {
            otherBill(where: $where) {
                id
                is_latest
                pay_method
                bk_acc_id
                type {
                    code
                    id
                    description
                    is_refund_related
                    incl_santion_detail
                    third_party_applicability
                    supplier_applicability
                    deductions {
                        deduction_id
                        is_mandatory
                        deduction
                        id
                    }
                }
                type_id
                year_id
                ref
                period
                supplier_id
                supplier
                budget_ofc_id
                gross_bill_amount
                net_payment_amount
                budget_ofc
                beneficiary_id
                beneficiary
                description
                hoa
                challan_no
                challan_date
                challan_amount
                sanction_num
                sanction_date
                sanction_authority
                sanction_amount
                details {
                    id
                    invoice_num
                    invoice_date
                    amount
                    description
                    attachments {
                        file_id
                        file
                    }
                }
                deductions {
                    deduction
                    deduction_id
                    amount
                    remarks
                    id
                }
                version_no
            }
        }
    `,
    deleteOneOtherBill: gql`
        mutation deleteOneOtherBill($where: OtherBillWhereUniqueInput!) {
            deleteOneOtherBill(where: $where) {
                id
            }
        }
    `,
    createOneOtherBill: gql`
        mutation createOneOtherBill($data: OtherBillCreateInput!) {
            createOneOtherBill(data: $data) {
                id
            }
        }
    `,
    updateOneOtherBill: gql`
        mutation updateOneOtherBill($data: OtherBillUpdateInput!, $where: OtherBillWhereUniqueInput!) {
            updateOneOtherBill(data: $data, where: $where) {
                version_no
                version_user
            }
        }
    `,
    rvnChallans: gql`
        query rvnChallans($orderBy: [RvnChallanOrderByInput!]) {
            rvnChallans(orderBy: $orderBy) {
                id
                ref
                id_num
                id_ref
                details {
                    amount
                }
                out_standing_amount
                version_date
            }
        }
    `,
    getWFActions: gql`
        query workflow_actions($source_id: String!) {
            workflow_actions(source_id: $source_id) {
                actions {
                    name
                    status
                    kind
                    comments_required
                }
            }
        }
    `,
    submitWorkFlowAction: gql`
        mutation wfsubmit($source_id: String!, $source_version: Int!, $status: String!, $comments: String, $office_code: String) {
            wfsubmit(
                source_id: $source_id
                source_version: $source_version
                status: $status
                comments: $comments
                office_code: $office_code
            )
        }
    `,
    fetchWFStats: gql`
        query wf_stats($fmis_function: String!, $fmis_sub_function: String) {
            wf_stats(fmis_function: $fmis_function, fmis_sub_function: $fmis_sub_function) {
                stats {
                    label
                    default_src
                    highlight_src
                    count
                    ids
                    status_obj
                    new_objs
                    canRecall
                }
            }
        }
    `,
    wf_stats_wrt_office: gql`
        query wf_stats_wrt_office($fmis_function: String!, $fmis_sub_function: String) {
            wf_stats_wrt_office(fmis_function: $fmis_function, fmis_sub_function: $fmis_sub_function) {
                stats {
                    label
                    default_src
                    highlight_src
                    count
                    ids
                    status_obj
                    new_objs
                    canRecall
                }
            }
        }
    `,
    Imprest_Release_InPdf: gql`
        query Imprest_Release_InPdf($imprestID: String!) {
            Imprest_Release_InPdf(imprestID: $imprestID) {
                status
                error
                fileID
            }
        }
    `,

    GenerateOtherBillPDF: gql`
        query GenerateOtherBillPDF($other_bill_id: String!) {
            GenerateOtherBillPDF(other_bill_id: $other_bill_id) {
                status
                input_name
                fileID
            }
        }
    `,
    fetchWorkflowInstances: gql`
        query workflowInstances($source_ref: String!) {
            workflowInstances(where: { source_ref: { equals: $source_ref } }, orderBy: { version_date: asc }) {
                id
                version_no
                version_user
                status
                version_date
                assigned_to
            }
        }
    `,
    fetchWorkflowInstanceBySourceId: gql`
        query workflowInstances($where: WorkflowInstanceWhereInput) {
            workflowInstances(where: $where) {
                id
                version_no
                version_user
                status
                version_date
                assigned_to
            }
        }
    `,
    fetchWorkflowInstanceSteps: gql`
        query workflowInstanceSteps($wf_instance_id: String!) {
            workflowInstanceSteps(
                where: { wf_instance_id: { equals: $wf_instance_id } }
                orderBy: [{ version_date: desc }, { phase_seq: desc }, { status: desc }]
            ) {
                id
                status
                version_user
                version_date
                comments
                source_version_no
                is_audit_exists
                phase_seq
                phase_type
            }
        }
    `,
    fetchWorkflowInstanceStepsBySourceId: gql`
        query workflowInstanceSteps($where: WorkflowInstanceStepWhereInput) {
            workflowInstanceSteps(where: $where, orderBy: [{ version_date: desc }, { phase_seq: desc }, { status: desc }]) {
                id
                status
                version_user
                version_date
                comments
                source_version_no
                is_audit_exists
                phase_seq
                phase_type
            }
        }
    `,
    fetchWorkflowAuditDetails: gql`
        query audit($where: AuditWhereUniqueInput!) {
            audit(where: $where) {
                details
            }
        }
    `,
    contractBillDetails: gql`
        query contractBillDetails($where: ContractBillDetailWhereInput) {
            contractBillDetails(where: $where) {
                id
                bill {
                    ref
                    type {
                        bill_type
                    }
                }
            }
        }
    `,
    getOtherBills: gql`
        query getOtherBills($orderBy: [OtherBillOrderByInput!], $where: OtherBillWhereInput) {
            otherBills(orderBy: $orderBy, where: $where) {
                id
                ref
                type {
                    code
                }
                period
                status
                updated_at_str
                version_date
                type_id
                gross_bill_amount
                net_payment_amount
                supplier_id
                supplier
            }
        }
    `,
    generateResetPasswordLink: gql`
        mutation generateResetPasswordLink($username: String!) {
            generateResetPasswordLink(username: $username)
        }
    `,
    resetPassword: gql`
        mutation resetPassword($token: String!, $newPassword: String!) {
            resetPassword(token: $token, newPassword: $newPassword)
        }
    `,
    generalNotificationsApi: gql`
        subscription generalNotificationsApi($userId: String) {
            notifications(userId: $userId)
        }
    `,
    total_notifications: gql`
        query total_notifications($where: NotificationWhereInput) {
            total_notifications(where: $where)
        }
    `,
    notifications: gql`
        query notifications($where: NotificationWhereInput, $orderBy: [NotificationOrderByInput!], $take: Int, $skip: Int) {
            notifications(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                type
                is_read
                source_id
                source_type
                title
                message
                version_date
            }
        }
    `,
    pending_with_self_task_total_count: gql`
        query pending_with_self_task_total_count($where: WorkflowInstanceWhereInput) {
            pending_with_self_task_total_count(where: $where)
        }
    `,
    dashboard_pending_tasks: gql`
        query dashboard_pending_tasks($where: WorkflowInstanceWhereInput, $orderBy: WorkflowInstanceOrderByInput!, $take: Int, $skip: Int) {
            dashboard_pending_tasks(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                status
                source_ref
                source_id
                office_code
                version_date
                fmis_function
                fmis_function_name
                curr_phase_type
                workflow_conf_id
            }
        }
    `,
    dashboard_stats: gql`
        query dashboard_stats {
            dashboard_stats
        }
    `,
    offices: gql`
        query offices {
            offices(full_codes: "") {
                id
                code
                name
            }
        }
    `,
    officeNDepartment: gql`
        query officeNDeparmentList($fin_year: String) {
            officeNDeparmentList(fin_year: $fin_year) {
                office {
                    id
                    name
                    full_code
                }
                department {
                    id
                    name
                    full_code
                }
            }
        }
    `,
    getDepartmentsAndOffices: gql`
        query deparmentWithOfficeList($privilegeCode: String!) {
            deparmentWithOfficeList(authorize: true, privilegeCode: $privilegeCode) {
                department {
                    name
                    full_code
                }
                offices {
                    name
                    full_code
                }
            }
        }
    `,
    departmentsWithOffice: gql`
        query deparmentWithOfficeList {
            deparmentWithOfficeList {
                offices {
                    id
                    name
                    full_code
                }
                department {
                    id
                    name
                    full_code
                }
            }
        }
    `,
    fetchAgenciesAndSuppliers: gql`
        query fetchAgenciesAndSuppliers {
            sanctioningAgencies: sanctionAgenciesAuthorized(functionCode: "EXP_CONTRACT_BILL_CREATION_R") {
                id
                segment_name
                segment_full_code
            }
            cntrSuppliers(where: { is_effective: { equals: true } }, orderBy: { name: asc }) {
                id
                name
                ref
            }
        }
    `,
    getBills: gql`
        query getBills($orderBy: [ContractBillOrderByInput!], $where1: ContractBillWhereInput, $where2: ContractBillWhereInput) {
            bills: contractBills(orderBy: $orderBy, where: $where1) {
                id
                type {
                    bill_type
                }
                ref
            }
            finalBills: contractBills(orderBy: $orderBy, where: $where2) {
                id
                type {
                    bill_type
                }
                ref
            }
        }
    `,
    activeUsers: gql`
        {
            users(where: { is_active: { equals: true } }) {
                id
                user_name
                employee
            }
        }
    `,
    fin_period_list: gql`
        {
            fin_period_list
        }
    `,
    fin_period_future_list: gql`
        {
            fin_period_list(only_future_list: true)
        }
    `,
    getCurrentPeriod: gql`
        {
            getCurrentPeriod
        }
    `,
    economicClassifDraft: gql`
        query economicClassifDraft($where: EconomicClassifDraftWhereUniqueInput!) {
            economicClassifDraft(where: $where) {
                ref
                id
                status
                is_latest
                version_user
                version_no
                version_date
                codes {
                    segment_full_code
                    segment_name
                    classif_type
                }
            }
        }
    `,
    economicClassifCodes: gql`
        query economicClassifCodes {
            economicClassifCodes {
                segment_full_code
                segment_name
                classif_type
            }
        }
    `,
    getEconomicData: gql`
        query getEconomicData($fin_year: String) {
            coaSegmentConf(where: { id: "COA_SEGMENT_CONF" }) {
                economic_segment
                economic_level
            }
            unClassifiedCodes(fin_year: $fin_year) {
                name
                code
                full_code
            }
        }
    `,
    createOneEconomicClassifDraft: gql`
        mutation createOneEconomicClassifDraft($data: EconomicClassifDraftCreateInput!) {
            createOneEconomicClassifDraft(data: $data) {
                status
            }
        }
    `,
    updateOneEconomicClassifDraft: gql`
        mutation updateOneEconomicClassifDraft($data: EconomicClassifDraftUpdateInput!, $where: EconomicClassifDraftWhereUniqueInput!) {
            updateOneEconomicClassifDraft(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    deleteOneEconomicClassifDraft: gql`
        mutation deleteOneEconomicClassifDraft($where: EconomicClassifDraftWhereUniqueInput!) {
            deleteOneEconomicClassifDraft(where: $where) {
                status
            }
        }
    `,
    ecoClassifCodes: gql`
        query economicClassifDrafts(
            $where: EconomicClassifDraftWhereInput
            $orderBy: [EconomicClassifDraftOrderByInput!]
            $take: Int
            $skip: Int
        ) {
            coaSegmentConf(where: { id: "COA_SEGMENT_CONF" }) {
                economic_segment
                economic_level
            }
            economicClassifDrafts(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                ref
                id
                status
                is_latest
                version_user
                version_date
                codes {
                    segment_full_code
                    segment_name
                    classif_type
                }
            }
        }
    `,
    getCOASegments: gql`
        query ($where: AdmCOAStructureWhereInput) {
            admCoaStructures(where: $where) {
                name
                details {
                    seg_def {
                        name
                        levels {
                            name
                        }
                    }
                }
            }
        }
    `,
    createCOASegmentConf: gql`
        mutation createOneCOASegmentConf($data: COASegmentConfCreateInput!) {
            createOneCOASegmentConf(data: $data) {
                id
            }
        }
    `,
    updateCOASegmentConf: gql`
        mutation updateOneCOASegmentConf($data: COASegmentConfUpdateInput!, $where: COASegmentConfWhereUniqueInput!) {
            updateOneCOASegmentConf(data: $data, where: $where) {
                id
                status
            }
        }
    `,
    getCOASegmentsConfiguration: gql`
        query {
            coaSegmentConf(where: { id: "COA_SEGMENT_CONF" }) {
                id
                status
                balancing_segment
                economic_segment
                economic_level
                ledger_level
                org_segment
                dept_level
                financing_source
                office_level
                fund_segment
                key_level
            }
        }
    `,
    payCommissions: gql`
        query payCommissions($orderBy: [PayCommissionOrderByInput!], $where: PayCommissionWhereInput, $skip: Int, $take: Int) {
            payCommissions(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                name
                ref
                is_active
                is_latest
                status
                version_date
				version_no
				version_user
            }
        }
    `,
    payCommission: gql`
        query payCommission($where: PayCommissionWhereUniqueInput!) {
            payCommission(where: $where) {
                name
                is_active
                is_latest
                status
                id
                ref
                version_no
                version_user
            }
        }
    `,
    createOnePayCommission: gql`
        mutation createOnePayCommission($data: PayCommissionCreateInput!) {
            createOnePayCommission(data: $data) {
                id
            }
        }
    `,
    updateOnePayCommission: gql`
        mutation updateOnePayCommission($data: PayCommissionUpdateInput!, $where: PayCommissionWhereUniqueInput!) {
            updateOnePayCommission(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOnePayCommission: gql`
        mutation deleteOnePayCommission($where: PayCommissionWhereUniqueInput!) {
            deleteOnePayCommission(where: $where) {
                id
            }
        }
    `,
    payComponents: gql`
        query payComponents($orderBy: [PayComponentOrderByInput!], $where: PayComponentWhereInput, $skip: Int, $take: Int) {
            payComponents(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                name
                is_active
                is_latest
				is_effective
                status
                have_sub_components
                version_date
                sub_components {
                    name
                    have_sub_components
                    is_active
                    version_date
                    status
                    id
                    ref
                }
            }
        }
    `,
    payComponent: gql`
        query payComponent($where: PayComponentWhereUniqueInput!) {
            payComponent(where: $where) {
                id
                ref
                name
                is_active
                is_latest
                status
                have_sub_components
                sub_components {
                    name
                    ref
                    id
					is_effective
					is_latest
                }
                version_date
                version_no
                version_user
            }
        }
    `,
    createOnePayComponent: gql`
        mutation createOnePayComponent($data: PayComponentCreateInput!) {
            createOnePayComponent(data: $data) {
                id
            }
        }
    `,
    updateOnePayComponent: gql`
        mutation updateOnePayComponent($data: PayComponentUpdateInput!, $where: PayComponentWhereUniqueInput!) {
            updateOnePayComponent(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOnePayComponent: gql`
        mutation deleteOnePayComponent($where: PayComponentWhereUniqueInput!) {
            deleteOnePayComponent(where: $where) {
                id
            }
        }
    `,
    employeeClassifLevels: gql`
        query employeeClassifLevels($where: EmployeeClassifLevelWhereInput) {
            employeeClassifLevels(where: $where) {
                id
                name
                child_level {
                    name
                    id
                    child_level {
                        name
                        id
                        child_level {
                            name
                            id
                            child_level {
                                name
                                id
                                child_level {
                                    name
                                    id
                                    child_level {
                                        name
                                        id
                                        child_level {
                                            name
                                            id
                                            child_level {
                                                name
                                                id
                                                child_level {
                                                    name
                                                    id
                                                    child_level {
                                                        name
                                                        id
                                                        child_level {
                                                            name
                                                            id
                                                            child_level {
                                                                name
                                                                id
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    createOneEmployeeClassifLevel: gql`
        mutation createOneEmployeeClassifLevel($data: EmployeeClassifLevelCreateInput!) {
            createOneEmployeeClassifLevel(data: $data) {
                id
            }
        }
    `,

    entityRecords: gql`
        query entityRecords($where: EntityRecordWhereInput) {
            entityRecords(where: $where) {
                id
                record
                status
                version_date
                txn_id
            }
        }
    `,
    entityRecord: gql`
        query entityRecord($where: EntityRecordWhereUniqueInput!) {
            entityRecord(where: $where) {
                id
                txn_id
                record
                source_created
            }
        }
    `,
    upsertEntityRecord: gql`
        mutation upsertEntityRecord(
            $module: String!
            $fmis_function: String!
            $fmis_sub_function: String!
            $record: Json!
            $txn_id: String
            $source_id: String
            $entity_id: String
            $version_no: Int
            $display_data: Json!
            $bulk_create_data: Json!
        ) {
            upsertEntityRecord(
                module: $module
                fmis_function: $fmis_function
                fmis_sub_function: $fmis_sub_function
                txn_id: $txn_id
                entity_id: $entity_id
                source_id: $source_id
                version_no: $version_no
                record: $record
                display_data: $display_data
                bulk_create_data: $bulk_create_data
            )
        }
    `,
    deleteOneEntityRecord: gql`
        mutation deleteOneEntityRecord($where: EntityRecordWhereUniqueInput!) {
            deleteOneEntityRecord(where: $where) {
                id
            }
        }
    `,
    wf_assign_details: gql`
        query wf_assign_details(
            $function_code: String!
            $source_id: String
            $office_code: String!
            $action: String!
            $record: Json!
            $users: Json
        ) {
            wf_assign_details(
                function_code: $function_code
                source_id: $source_id
                office_code: $office_code
                action: $action
                record: $record
                users: $users
            )
        }
    `,

    getWorkFlowConfiguration: gql`
        query workflows($take: Int, $skip: Int, $orderBy: [WorkflowOrderByInput!], $where: WorkflowWhereInput) {
            workflows(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
                id
                ref
                module
                function
                sub_function
                activity
                conf_id
                steps {
                    assignment_details {
                        role_name
                    }
                }
                departments {
                    dept_code
                    dept_name
                    office_code
                    office_name
                }
            }
        }
    `,
    workflowAssignments: gql`
        query workflowAssignments($where: WorkflowAssignmentWhereInput) {
            workflowAssignments(where: $where) {
                id
                office_type
                role_name
            }
        }
    `,

    getWorkFlowConfigurationCount: gql`
        query workflowCount($where: WorkflowWhereInput) {
            workflowCount(where: $where)
        }
    `,

    getOneWorkFlowConfiguration: gql`
        query workflow($where: WorkflowWhereUniqueInput!) {
            workflow(where: $where) {
                id
                ref
                module
                function
                sub_function
                activity
                auto_approve
                conf_id
                departments {
                    dept_code
                    dept_name
                    office_code
                    office_name
                    id
                }
                steps {
                    id
                    phase
                    serial_no
                    assign_method
                    condition_field
                    condition_op
                    condition_value
                    sla_period
                    auto_assign
                    assignment_details {
                        assign_type
                        role_name
                        default_role_name
                        department_code
                        office_code
                        office_type
                        serial_no
                    }
                }
            }
        }
    `,

    fmis_functions: gql`
        query fmis_functions {
            fmis_functions
        }
    `,

    createOneWorkflow: gql`
        mutation createOneWorkflow($data: WorkflowCreateInput!) {
            createOneWorkflow(data: $data) {
                id
            }
        }
    `,
    updateOneWorkflow: gql`
        mutation updateOneWorkflow($data: WorkflowUpdateInput!, $where: WorkflowWhereUniqueInput!) {
            updateOneWorkflow(data: $data, where: $where) {
                id
            }
        }
    `,
    workflowDepartments: gql`
        query workflowDepartments($where: WorkflowDepartmentWhereInput) {
            workflowDepartments(where: $where) {
                dept_code
                dept_name
                office_code
                office_name
            }
        }
    `,

    rolebasedofficeNDeparmentList: gql`
        query officeNDeparmentList($full_code: String, $fin_year: String) {
            officeNDeparmentList(full_code: $full_code, fin_year: $fin_year) {
                office {
                    name
                    full_code
                }
                department {
                    name
                    full_code
                }
            }
        }
    `,

    workflowInstances: gql`
        query workflowInstances($where: WorkflowInstanceWhereInput) {
            workflowInstances(where: $where) {
                id
            }
        }
    `,
    GetBillWiseAmounts: gql`
        query GetBillWiseAmounts($bill_id: String!) {
            GetBillWiseAmounts(bill_id: $bill_id)
        }
    `,
    wf_existing_offices: gql`
        query wf_existing_offices($conf_id: String!) {
            wf_existing_offices(conf_id: $conf_id)
        }
    `,
    appSetting: gql`
        query appSetting($where: AppSettingWhereUniqueInput!) {
            appSetting(where: $where) {
                id
                value1
                value2
            }
        }
    `,
    updateOneAppSetting: gql`
        mutation updateOneAppSetting($data: AppSettingUpdateInput!, $where: AppSettingWhereUniqueInput!) {
            updateOneAppSetting(data: $data, where: $where) {
                id
            }
        }
    `,
    payEntitlements: gql`
        query payEntitlements($where: PayEntitlementWhereInput, $orderBy: [PayEntitlementOrderByInput!], $skip: Int, $take: Int) {
            payEntitlements(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
				is_latest
				is_effective
                description
                classif_level
                classif_code
				entitlement_used
                classif_level_detail {
                    id
                    name
                }
                classif_code_detail {
                    id
                    name
                }
                classif_level
                classif_code
                pay_commission_id
                ref
                is_latest
                pay_commission {
                    id
                    name
                }
                is_active
                status
                version_date
            }
        }
    `,
    payEntitlement: gql`
        query payEntitlement($where: PayEntitlementWhereUniqueInput!) {
            payEntitlement(where: $where) {
                id
                description
                classif_level_detail {
                    id
                    name
                }
                classif_code_detail {
                    id
                    name
                }
                classif_level
				entitlement_used
                classif_code
                pay_commission_id
                ref
                pay_commission {
                    id
                    name
                }
                version_no
                is_active
                is_latest
                status
                version_date
            }
        }
    `,
    createOnePayEntitlement: gql`
        mutation createOnePayEntitlement($data: PayEntitlementCreateInput!) {
            createOnePayEntitlement(data: $data) {
                id
            }
        }
    `,
    updateOnePayEntitlement: gql`
        mutation updateOnePayEntitlement($data: PayEntitlementUpdateInput!, $where: PayEntitlementWhereUniqueInput!) {
            updateOnePayEntitlement(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOnePayEntitlement: gql`
        mutation deleteOnePayEntitlement($where: PayEntitlementWhereUniqueInput!) {
            deleteOnePayEntitlement(where: $where) {
                id
            }
        }
    `,
    createOnePayEntitlementComponent: gql`
        mutation createOnePayComponentDef($data: PayComponentDefCreateInput!) {
            createOnePayComponentDef(data: $data) {
                id
            }
        }
    `,
    updateOnePayEntitlementComponent: gql`
        mutation updateOnePayComponentDef($data: PayComponentDefUpdateInput!, $where: PayComponentDefWhereUniqueInput!) {
            updateOnePayComponentDef(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    deleteOnePayEntitlementComponent: gql`
        mutation deleteOnePayComponentDef($where: PayComponentDefWhereUniqueInput!) {
            deleteOnePayComponentDef(where: $where) {
                id
            }
        }
    `,

    employeeMastersData: gql`
        query employeeMastersData {
            deputationOffices: deputationOffices {
                segment_name
                segment_full_code
            }
            countries: countries {
                id
                name
                ccy
                ccy_code
                states {
                    id
                    name
                }
            }
            banks: banks(orderBy: [{ ifsc: asc }]) {
                id
                ifsc
                name
                branch_name
                is_active
            }
        }
    `,
    employees: gql`
        query employees($where: EmployeeWhereInput, $orderBy: [EmployeeOrderByInput!], $skip: Int, $take: Int) {
            employees(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                first_name
                last_name
                group_id
                mda
                employee_group {
                    name
                }
                budget_office
                budget_office_name
                deputation_office
                deputation_office_name
                payroll_status
                active_status
                status
                is_active
                is_latest
                is_effective
                effective_from
                version_user
                version_no
                version_date
                classif_details {
                    classif_id
                    classif_code
                    classif_code_detail {
                        name
                    }
                }
            }
            employeeGroups: employeeGroups {
                id
                name
                ref
                budget_office
                version_date
                budgetOffice
            }
        }
    `,
    employee: gql`
        query employee($where: EmployeeWhereUniqueInput!) {
            employee(where: $where) {
                id
                group_id
                version_no
                version_user
                mda
                employee_group {
                    id
                    name
                    ref
                }
                ref
                effective_from
                budget_office
                deputation_office
                budget_office_name
                deputation_office_name
                payroll_status
                active_status
                first_name
                middle_name
                last_name
                gender
                email
                img_url
                is_latest
                last_payment_drawn_outside
                has_govt_quarter
                status
                classif_details {
                    emp_id
                    classif_id
                    classif_code
                    classif_code_detail {
                        name
                    }
                    id
                }
                general_info {
                    father_name
                    mother_name
                    nationality
                    marital_status
                    spouse_name
                    pan_num
                    aadhar
                    mobile
                    other_mobile
                    belongs_to_minority
                    reservation_category
                    caste
                    is_disabled
                    dob
                    disability_percent
                    disability_type
                    office_location
                    daily_rate
                }
                special_info {
                    gpf_type
                    gpf_num
                    appointment_order
                    appointment_date
                    joining_date
                    super_annuation_date
                    overall_experience
                }
                bank_accounts {
                    id
                    is_primary
                    account {
                        id
                        name
                        category
                        type
                        number
                        holder_name
                        bank_id
                        ccy
                        is_active
                        bank
                    }
                }
                addresses {
                    id
                    address_id
                    is_primary
                    address {
                        id
                        line1
                        line2
                        line3
                        pin_code
                        country
                        state
                        district
                        sub_district
                        village
                        panchayat
                        is_active
                        tier_id
                    }
                }
                pay_components {
                    component_id
                    sub_component_id
                    amount
                }
                qualifications {
                    id
                    q1ualification
                    university
                    cert_number
                    year_of_passing
                    grade
                    percentage
                }
            }
        }
    `,
    createOneEmployee: gql`
        mutation createOneEmployee($data: EmployeeCreateInput!) {
            createOneEmployee(data: $data) {
                id
            }
        }
    `,
    updateOneEmployee: gql`
        mutation updateOneEmployee($data: EmployeeUpdateInput!, $where: EmployeeWhereUniqueInput!) {
            updateOneEmployee(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    deleteOneEmployee: gql`
        mutation deleteOneEmployee($where: EmployeeWhereUniqueInput!) {
            deleteOneEmployee(where: $where) {
                id
            }
        }
    `,
    payComponentDefs: gql`
        query payComponentDefs($where: PayComponentDefWhereInput, $orderBy: [PayComponentDefOrderByInput!], $skip: Int, $take: Int) {
            payComponentDefs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                entitlement_id
                component_type
                effective_from
                component_id
                status
				is_active
                ref
                is_latest
                entitlement {
                    description
                    classif_level
                    classif_code
                    ref
                    pay_commission_id
                    pay_commission {
                        name
                        id
                    }
                    classif_level_detail {
                        name
                    }
                    classif_code_detail {
                        name
                    }
                }
                is_active
                version_no
                status
                version_date
                version_user
                component {
                    id
                    have_sub_components
                    name
                    ref
                    sub_components {
                        id
                        name
                        ref
                    }
                }
                details {
                    id
                    sub_component_id
                    sub_component {
                        name
                        id
                        ref
                    }
                    applicability_level
                    applicability_level_detail {
                        id
                        name
                    }
                    value_type
                    is_recurring
                    payout_month
                    coa_selection_type
                    coa_code
                    is_global
                    arrears_allowed
                    arrears_coa_selection_type
                    arrears_coa
                    is_editable
                    version_no
                    applicabilities {
                        id
                        name
                        type
                        value
                    }
                    classif_code_details {
                        id
                        value
                        def_id
                        classif_code
                        formula_variables
                        classif_code_details {
                            name
                            id
                            classif_level_id
                        }
                    }
                }
            }
        }
    `,
    payComponentDef: gql`
        query payComponentDef($where: PayComponentDefWhereUniqueInput!) {
            payComponentDef(where: $where) {
                id
                entitlement_id
                component_type
				is_active
                effective_from
                component_id
                status
                ref
                is_latest
                entitlement {
                    description
                    classif_level
                    classif_code
                    ref
                    id
                    pay_commission_id
                    pay_commission {
                        name
                        id
                    }
                    classif_level_detail {
                        name
                    }
                    classif_code_detail {
                        name
                    }
                }
                is_active
                version_no
                status
                version_date
                version_user
                component {
                    id
					ref
					name
					is_active
					is_latest
					is_effective
					status
					have_sub_components
					version_date
					sub_components {
						name
						have_sub_components
						is_active
						version_date
						status
						id
						ref
					}
                }
                details {
                    id
                    sub_component_id
                    sub_component {
                        name
                        id
                        ref
                    }
                    applicability_level
                    applicability_level_detail {
                        id
                        name
                    }
                    value_type
                    is_recurring
                    payout_month
                    coa_selection_type
                    coa_code
                    is_global
                    arrears_allowed
                    arrears_coa_selection_type
                    arrears_coa
                    is_editable
                    version_no
                    applicabilities {
                        id
                        name
                        type
                        value
                    }
                    classif_code_details {
                        id
                        value
                        def_id
                        formula_variables
                        classif_code
                        classif_code_details {
                            name
                            id
                            classif_level_id
                        }
                    }
                }
            }
        }
    `,
    payComponentDefsList: gql`
        query payComponentDefs($where: PayComponentDefWhereInput!) {
            payComponentDefs(where: $where) {
                id
                entitlement_id
                component_type
                component_id
                status
                effective_from
                ref
				is_active
                is_latest
                entitlement {
                    description
                    classif_level
                    classif_code
                    ref
                    id
                    pay_commission_id
                    pay_commission {
                        name
                        id
                    }
                    classif_level_detail {
                        name
                    }
                    classif_code_detail {
                        name
                    }
                }
                is_active
                version_no
                status
                version_date
                version_user
                component {
                    id
					ref
					name
					is_active
					is_latest
					is_effective
					status
					have_sub_components
					version_date
					sub_components {
						name
						have_sub_components
						is_active
						version_date
						status
						id
						ref
					}
                }
                details {
                    id
                    sub_component_id
                    sub_component {
                        name
                        id
                        ref
                    }
                    applicability_level
                    applicability_level_detail {
                        id
                        name
                    }
                    value_type
                    is_recurring
                    payout_month
                    coa_selection_type
                    coa_code
                    is_global
                    arrears_allowed
                    arrears_coa_selection_type
                    arrears_coa
                    is_editable
                    version_no
                    applicabilities {
                        id
                        name
                        type
                        value
                    }
                    classif_code_details {
                        id
                        value
                        def_id
                        formula_variables
                        classif_code
                        classif_code_details {
                            name
                            id
                            classif_level_id
                        }
                    }
                }
            }
        }
    `,
    economic_codes: gql`
        query economic_codes($fin_year: String) {
            economic_codes(full_codes: "", fin_year: $fin_year) {
                name
                id
                code
                full_code
                seg_def_id
            }
        }
    `,
    createOneEmployeeClassifCode: gql`
        mutation createOneEmployeeClassifCode($data: EmployeeClassifCodeCreateInput!) {
            createOneEmployeeClassifCode(data: $data) {
                id
            }
        }
    `,
    updateOneEmployeeClassifCode: gql`
        mutation updateOneEmployeeClassifCode($data: EmployeeClassifCodeUpdateInput!, $where: EmployeeClassifCodeWhereUniqueInput!) {
            updateOneEmployeeClassifCode(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    updateManyEmployeeClassifCode: gql`
        mutation updateManyEmployeeClassifCode($data: EmployeeClassifCodeUpdateManyMutationInput!, $where: EmployeeClassifCodeWhereInput) {
            updateManyEmployeeClassifCode(data: $data, where: $where) {
                count
            }
        }
    `,
    deleteOneEmployeeClassifCode: gql`
        mutation deleteOneEmployeeClassifCode($where: EmployeeClassifCodeWhereUniqueInput!) {
            deleteOneEmployeeClassifCode(where: $where) {
                id
            }
        }
    `,
    employeeClassifCodes: gql`
        query employeeClassifCodes($where: EmployeeClassifCodeWhereInput) {
            employeeClassifCodes(where: $where) {
                id
                name
                path
                classif_level {
                    name
                    id
                }
                child_codes {
                    name
                    id
                    path
                    classif_level {
                        name
                        id
                    }
                    child_codes {
                        name
                        id
                        path
                        classif_level {
                            name
                            id
                        }
                        child_codes {
                            name
                            id
                            path
                            classif_level {
                                name
                                id
                            }
                            child_codes {
                                name
                                id
                                path
                                classif_level {
                                    name
                                    id
                                }
                                child_codes {
                                    name
                                    id
                                    path
                                    classif_level {
                                        name
                                        id
                                    }
                                    child_codes {
                                        name
                                        id
                                        path
                                        classif_level {
                                            name
                                            id
                                        }
                                        child_codes {
                                            name
                                            id
                                            path
                                            classif_level {
                                                name
                                                id
                                            }
                                            child_codes {
                                                name
                                                id
                                                path
                                                classif_level {
                                                    name
                                                    id
                                                }
                                                child_codes {
                                                    name
                                                    id
                                                    path
                                                    classif_level {
                                                        name
                                                        id
                                                    }
                                                    child_codes {
                                                        name
                                                        id
                                                        path
                                                        classif_level {
                                                            name
                                                            id
                                                        }
                                                        child_codes {
                                                            name
                                                            id
                                                            path
                                                            classif_level {
                                                                name
                                                                id
                                                            }
                                                            child_codes {
                                                                name
                                                                id
                                                                path
                                                                classif_level {
                                                                    name
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    getEmpGroupData: gql`
        query getEmpGroupData(
            $entitleWhere: PayEntitlementWhereInput
            $entitleOrderBy: [PayEntitlementOrderByInput!]
            $classifWhere: EmployeeClassifLevelWhereInput
        ) {
            payEntitlements: payEntitlements(where: $entitleWhere, orderBy: $entitleOrderBy) {
                id
                is_active
                status
                version_date
                pay_commission_id
                classif_level
                classif_code
				entitlement_used
                ref
                description
                pay_commission {
                    id
                    name
                }
            }
            employeeStructure: employeeClassifLevels(where: $classifWhere) {
                id
                name
                child_level {
                    name
                    id
                    child_level {
                        name
                        id
                        child_level {
                            name
                            id
                            child_level {
                                name
                                id
                                child_level {
                                    name
                                    id
                                    child_level {
                                        name
                                        id
                                        child_level {
                                            name
                                            id
                                            child_level {
                                                name
                                                id
                                                child_level {
                                                    name
                                                    id
                                                    child_level {
                                                        name
                                                        id
                                                        child_level {
                                                            name
                                                            id
                                                            child_level {
                                                                name
                                                                id
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    ImportEmployeeClassifDetails: gql`
        mutation ImportEmployeeClassifDetails($file: Upload!) {
            ImportEmployeeClassifDetails(data: $file) {
                status
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    id
                    input_name
                    path
                }
            }
        }
    `,
    employeeClassifLevel: gql`
        query employeeClassifLevel($where: EmployeeClassifLevelWhereUniqueInput!) {
            employeeClassifLevel(where: $where) {
                name
                id
            }
        }
    `,

    groupWiseComponents: gql`
        query groupWiseComponents(
            $group_id: String!
            $classif_details: JSON!
            $has_govt_quarter: Boolean!
            $joining_date: DateTime!
            $is_disabled: Boolean!
        ) {
            groupWiseComponents(
                group_id: $group_id
                classif_details: $classif_details
                has_govt_quarter: $has_govt_quarter
                joining_date: $joining_date
                is_disabled: $is_disabled
            )
        }
    `,
    employeeGroups: gql`
        query employeeGroups($orderBy: [EmployeeGroupOrderByInput!], $where: EmployeeGroupWhereInput, $skip: Int, $take: Int) {
            employeeGroups(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                name
                ref
                budget_office
                budgetOffice
                entitlement_id
                entitlement {
                    id
                    ref
                    description
                }
                hoa
                is_active
                is_latest
                status
                version_date
                classif_details {
                    classif_level
                    classif_codes
                }
            }
        }
    `,
	employeeGroupsData: gql`
        query employeeGroups($where: EmployeeGroupWhereInput) {
            employeeGroups(where: $where) {
                id
                name
                ref
				effective_from
                budget_office
                budgetOffice
                entitlement_id
                hoa
                is_active
                is_latest
                have_components
                status
                version_date
                version_no
                version_user
                classif_details {
                    classif_level
                    classif_codes
                }
                pay_components {
                    id
                    component_id
                    sub_component_id
                    is_mandatory
                    component {
                    	id
                        component_type
                        pay_component {
                            name
							id
                        }
                        details {
                            value_type
                            classif_code_details {
                                value
                            }
                        }
                    }
                }
            }
        }
    `,
    employeeGroup: gql`
        query employeeGroup($where: EmployeeGroupWhereUniqueInput!) {
            employeeGroup(where: $where) {
                id
                name
                ref
				effective_from
                budget_office
                budgetOffice
                entitlement_id
                hoa
                is_active
                is_latest
                have_components
                status
                version_date
                version_no
                version_user
                classif_details {
                    classif_level
                    classif_codes
                }
                pay_components {
                    id
                    component_id
                    sub_component_id
                    is_mandatory
                    component {
                        component_type
                        pay_component {
                            name
                        }
                        details {
                            value_type
                            classif_code_details {
                                value
                            }
                        }
                    }
                }
            }
        }
    `,
    getEmployeeDetailsData: gql`
        query getEmployeeDetails($orderBy: [EmployeeGroupOrderByInput!], $where: EmployeeGroupWhereInput) {
            employeeGroups: employeeGroups(orderBy: $orderBy, where: $where) {
                id
                name
                ref
                budget_office
                entitlement_id
                hoa
                is_active
                is_latest
                status
                version_date
                budgetOffice
                classif_details {
                    classif_level
                    classif_codes
                    classifcodes_list {
                        id
                        name
                        child_codes {
                            id
                            name
                            child_codes {
                                id
                                name
                                child_codes {
                                    id
                                    name
                                    child_codes {
                                        id
                                        name
                                        child_codes {
                                            id
                                            name
                                            child_codes {
                                                id
                                                name
                                                child_codes {
                                                    id
                                                    name
                                                    child_codes {
                                                        id
                                                        name
                                                        child_codes {
                                                            id
                                                            name
                                                            child_codes {
                                                                id
                                                                name
                                                                child_codes {
                                                                    id
                                                                    name
                                                                    child_codes {
                                                                        id
                                                                        name
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            employeeStructure: employeeClassifLevels(where: { parent_id: { equals: null } }) {
                id
                name
                child_level {
                    name
                    id
                    child_level {
                        name
                        id
                        child_level {
                            name
                            id
                            child_level {
                                name
                                id
                                child_level {
                                    name
                                    id
                                    child_level {
                                        name
                                        id
                                        child_level {
                                            name
                                            id
                                            child_level {
                                                name
                                                id
                                                child_level {
                                                    name
                                                    id
                                                    child_level {
                                                        name
                                                        id
                                                        child_level {
                                                            name
                                                            id
                                                            child_level {
                                                                name
                                                                id
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    createOneEmployeeGroup: gql`
        mutation createOneEmployeeGroup($data: EmployeeGroupCreateInput!) {
            createOneEmployeeGroup(data: $data) {
                id
            }
        }
    `,
    updateOneEmployeeGroup: gql`
        mutation updateOneEmployeeGroup($data: EmployeeGroupUpdateInput!, $where: EmployeeGroupWhereUniqueInput!) {
            updateOneEmployeeGroup(data: $data, where: $where) {
                id
                version_no
                version_user
            }
        }
    `,
    deleteOneEmployeeGroup: gql`
        mutation deleteOneEmployeeGroup($where: EmployeeGroupWhereUniqueInput!) {
            deleteOneEmployeeGroup(where: $where) {
                id
            }
        }
    `,
    getClassifCodesOnEntitlementNpayComponentDefs: gql`
        query getClassifCodesOnEntitlementNpayComponentDefs(
            $code_id: String!
            $codeWhere: EmployeeClassifCodeWhereInput!
            $payCompWhere: PayComponentDefWhereInput
        ) {
            getClassifCodesOnEntitlementBasis(code_id: $code_id)
            employeeClassifCodes(where: $codeWhere) {
                id
                name
                path
                classif_level {
                    name
                    id
                }
                child_codes {
                    name
                    id
                    path
                    classif_level {
                        name
                        id
                    }
                    child_codes {
                        name
                        id
                        path
                        classif_level {
                            name
                            id
                        }
                        child_codes {
                            name
                            id
                            path
                            classif_level {
                                name
                                id
                            }
                            child_codes {
                                name
                                id
                                path
                                classif_level {
                                    name
                                    id
                                }
                                child_codes {
                                    name
                                    id
                                    path
                                    classif_level {
                                        name
                                        id
                                    }
                                    child_codes {
                                        name
                                        id
                                        path
                                        classif_level {
                                            name
                                            id
                                        }
                                        child_codes {
                                            name
                                            id
                                            path
                                            classif_level {
                                                name
                                                id
                                            }
                                            child_codes {
                                                name
                                                id
                                                path
                                                classif_level {
                                                    name
                                                    id
                                                }
                                                child_codes {
                                                    name
                                                    id
                                                    path
                                                    classif_level {
                                                        name
                                                        id
                                                    }
                                                    child_codes {
                                                        name
                                                        id
                                                        path
                                                        classif_level {
                                                            name
                                                            id
                                                        }
                                                        child_codes {
                                                            name
                                                            id
                                                            path
                                                            classif_level {
                                                                name
                                                                id
                                                            }
                                                            child_codes {
                                                                name
                                                                id
                                                                path
                                                                classif_level {
                                                                    name
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            payComponentDefs(where: $payCompWhere) {
                entitlement {
                    description
                }
                id
				is_active
                component_type
                component_id
                pay_component {
                    name
					id
                }
                details {
                    value_type
                    sub_component_id
                    sub_component {
                        name
						id
                    }
                    is_global
                    classif_code_details {
                        classif_code_details {
                            name
                        }
                        value
                    }
                }
            }
        }
    `,
    employeeClassifCodesNStatus: gql`
        query employeeClassifCodesNStatus(
            $where1: EmployeeClassifCodeWhereInput
            $where2: EmployeeClassifCodeWhereInput
            $orderBy: [EmployeeClassifCodeOrderByInput!]
        ) {
            employeeClassifCodes: employeeClassifCodes(where: $where1, orderBy: $orderBy) {
                id
                name
                approval_status
                path
                is_active
                classif_level {
                    name
                    id
                }
                child_codes {
                    name
                    id
                    path
                    approval_status
                    is_active
                    classif_level {
                        name
                        id
                    }
                    child_codes {
                        name
                        id
                        path
                        approval_status
                        is_active
                        classif_level {
                            name
                            id
                        }
                        child_codes {
                            name
                            id
                            path
                            approval_status
                            is_active
                            classif_level {
                                name
                                id
                            }
                            child_codes {
                                name
                                id
                                path
                                approval_status
                                is_active
                                classif_level {
                                    name
                                    id
                                }
                                child_codes {
                                    name
                                    id
                                    path
                                    approval_status
                                    is_active
                                    classif_level {
                                        name
                                        id
                                    }
                                    child_codes {
                                        name
                                        id
                                        path
                                        approval_status
                                        is_active
                                        classif_level {
                                            name
                                            id
                                        }
                                        child_codes {
                                            name
                                            id
                                            path
                                            approval_status
                                            is_active
                                            classif_level {
                                                name
                                                id
                                            }
                                            child_codes {
                                                name
                                                id
                                                path
                                                approval_status
                                                is_active
                                                classif_level {
                                                    name
                                                    id
                                                }
                                                child_codes {
                                                    name
                                                    id
                                                    path
                                                    approval_status
                                                    is_active
                                                    classif_level {
                                                        name
                                                        id
                                                    }
                                                    child_codes {
                                                        name
                                                        id
                                                        path
                                                        approval_status
                                                        is_active
                                                        classif_level {
                                                            name
                                                            id
                                                        }
                                                        child_codes {
                                                            name
                                                            id
                                                            path
                                                            approval_status
                                                            is_active
                                                            classif_level {
                                                                name
                                                                id
                                                            }
                                                            child_codes {
                                                                name
                                                                id
                                                                path
                                                                approval_status
                                                                is_active
                                                                classif_level {
                                                                    name
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            codesStatus: employeeClassifCodes(where: $where2) {
                id
            }
        }
    `,
    EmployeeClassifSampleFile: gql`
        query EmployeeClassifSampleFile {
            EmployeeClassifSampleFile {
                status
                uploadResp {
                    id
                    input_name
                }
            }
        }
    `,
    deleteManyEmployeeClassifCode: gql`
        mutation deleteManyEmployeeClassifCode($where: EmployeeClassifCodeWhereInput) {
            deleteManyEmployeeClassifCode(where: $where) {
                count
            }
        }
    `,
    budgetOfcNcoaSegmentConf: gql`
        query budgetOfcNcoaSegmentConf($where: COASegmentConfWhereUniqueInput!, $functionCode: String!) {
            budgetOfficesAuthorized(functionCode: $functionCode) {
                segment_name
                segment_full_code
            }
            coaSegmentConf(where: $where) {
                economic_segment
            }
        }
    `,

    commssionAndClassifCodeList: gql`
        query commssionAndClassifCodeList {
            commssionAndClassifCodeList
        }
    `,
    trigger_payroll: gql`
        mutation trigger_payroll {
            trigger_payroll
        }
    `,
    generate_pdf: gql`
        mutation generate_pdf($html_str: String!, $file_name: String!) {
            generate_pdf(html_str: $html_str, file_name: $file_name)
        }
    `,
    jobs: gql`
        query jobs($orderBy: [JobOrderByInput!]) {
            jobs(orderBy: $orderBy) {
                id
                type
                start_date
                end_date
                run_date
                version_date
                status
            }
        }
    `,
    jobsAppEnums: gql`
        query jobsAppEnums {
            jobConfigured: appSetting(where: { id: "PAYROLL_PERIOD" }) {
                value1
                value2
                value3
                value4
            }
            current: appEnums(where: { enum_type: { equals: "PAYROLL_CURRENT_MONTH_PERIOD" } }) {
                id
                enum_type
                enum_value
                enum_group
                display_value
                sort_order
                status
            }
            previous: appEnums(where: { enum_type: { equals: "PAYROLL_PREVIOUS_MONTH_PERIOD" } }) {
                id
                enum_type
                enum_value
                enum_group
                display_value
                sort_order
                status
            }
        }
    `,
    updateManyNotification: gql`
        mutation updateManyNotification($data: NotificationUpdateManyMutationInput!, $where: NotificationWhereInput!) {
            updateManyNotification(data: $data, where: $where) {
                count
            }
        }
    `,
    periods: gql`
        query periods($where: PeriodWhereInput!) {
            periods: periods(where: $where) {
                id
                code
                name
            }
        }
    `,
    payslips_data: gql`
        query payslips_data($periods: JSON!, $emp_id: String!) {
            payslips_data(periods: $periods, emp_id: $emp_id)
        }
    `,
    wf_can_recall: gql`
        query wf_can_recall($source_id: String!) {
            wf_can_recall(source_id: $source_id)
        }
    `,
    recall_next_step: gql`
        mutation recall_next_step($source_id: String!, $comments: String) {
            recall_next_step(source_id: $source_id, comments: $comments)
        }
    `,
    economicClassifCodeCount: gql`
        query economicClassifDraftCount($where: EconomicClassifDraftWhereInput) {
            economicClassifDraftCount(where: $where)
        }
    `,
    contractBillConfCount: gql`
        query contractBillConfCount($where: ContractBillConfWhereInput) {
            contractBillConfCount(where: $where)
        }
    `,
    otherBillConfsCount: gql`
        query contractBillConfCount($where: OtherBillConfWhereInput) {
            otherBillConfsCount(where: $where)
        }
    `,
    imprestConfCount: gql`
        query imprestConfCount($where: ImprestConfWhereInput) {
            imprestConfCount(where: $where)
        }
    `,
    employeeCount: gql`
        query employeeCount($where: EmployeeWhereInput) {
            employeeCount(where: $where)
        }
    `,
    payCommissionCount: gql`
        query payCommissionCount($where: PayCommissionWhereInput) {
            payCommissionCount(where: $where)
        }
    `,
    payEntitlementCount: gql`
        query payEntitlementCount($where: PayEntitlementWhereInput) {
            payEntitlementCount(where: $where)
        }
    `,
    payComponentCount: gql`
        query payComponentCount($where: PayComponentWhereInput) {
            payComponentCount(where: $where)
        }
    `,
    payComponentDefsCount: gql`
        query payComponentDefsCount($where: PayComponentDefWhereInput) {
            payComponentDefsCount(where: $where)
        }
    `,
    employeeGroupCount: gql`
        query employeeGroupCount($where: EmployeeGroupWhereInput) {
            employeeGroupCount(where: $where)
        }
    `,
    beneficiaryCount: gql`
        query beneficiaryCount($where: BeneficiaryWhereInput) {
            beneficiaryCount(where: $where)
        }
    `,
    cntrSupplierCategoryCount: gql`
        query cntrSupplierCategoryCount($where: CntrSupplyCategoryWhereInput) {
            cntrSupplierCategoryCount(where: $where)
        }
    `,
    cntrSuppliersCount: gql`
        query cntrSuppliersCount($where: CntrSupplierWhereInput) {
            cntrSuppliersCount(where: $where)
        }
    `,
    payEntitlementChangeCount: gql`
        query payEntitlementChangeCount($where: PayEntitlementChangeWhereInput) {
            payEntitlementChangeCount(where: $where)
        }
    `,
    otherBillCount: gql`
        query otherBillCount($where: OtherBillWhereInput) {
            otherBillCount(where: $where)
        }
    `,
    getMasterDataForPersonnelBills: gql`
        query getMasterDataForPersonnelBills($where: AdmFinYearWhereInput, $orderBy: [AdmFinYearOrderByInput!]) {
            admFinYears(where: $where, orderBy: $orderBy) {
                id
                name
                master_start_date
                master_end_date
            }
            current_fin_year {
                id
                name
                master_start_date
                master_end_date
            }
            fin_period_list(only_future_list: true)
            deductions(where: { is_latest: { equals: true }, is_active: { equals: true } }, orderBy: [{ code: asc }]) {
                type
                id
                value
                name
            }
        }
    `,
    employeesList: gql`
        query employeesList($where: EmployeeWhereInput, $orderBy: [EmployeeOrderByInput!], $skip: Int, $take: Int) {
            employees(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                ref
                first_name
                last_name
                email
                general_info {
                    mobile
                }
                classif_details {
                    classif_id
                    classif_code
                    classif_code_detail {
                        name
                    }
                }
                budget_office
                budget_office_name
                deputation_office
                deputation_office_name
                special_info {
                    gpf_type
                    gpf_num
                    super_annuation_date
                }
            }
        }
    `,
    personnelBills: gql`
        query personnelBills($skip: Int, $take: Int, $orderBy: [PersonnelBillOrderByInput!], $where: PersonnelBillWhereInput) {
            personnelBills(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                emp_id
                fin_year
                status
                is_active
                is_latest
                bill_type {
                    code
                }
                bill_sub_type {
                    code
                }
                employee
                gross_bill_amount
                net_payment
                version_date
            }
        }
    `,
    personnelBillCount: gql`
        query personnelBillCount($where: PersonnelBillWhereInput) {
            personnelBillCount(where: $where)
        }
    `,
    createOnePersonnelBill: gql`
        mutation createOnePersonnelBill($data: PersonnelBillCreateInput!) {
            createOnePersonnelBill(data: $data) {
                id
            }
        }
    `,
    updateOnePersonnelBill: gql`
        mutation updateOnePersonnelBill($data: PersonnelBillUpdateInput!, $where: PersonnelBillWhereUniqueInput!) {
            updateOnePersonnelBill(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOnePersonnelBill: gql`
        mutation deleteOnePersonnelBill($where: PersonnelBillWhereUniqueInput!) {
            deleteOnePersonnelBill(where: $where) {
                id
            }
        }
    `,
    personnelBillConfCodes: gql`
        query personnelBillConfCodes($where: PersonnelBillConfCodeWhereInput) {
            personnelBillConfCodes(where: $where) {
                seg_def_name
                segment_code
                segment_full_code
                level_name
                segment_name
                id
            }
        }
    `,
    personnelBillConfs: gql`
        query personnelBillConfs($where: PersonnelBillConfWhereInput, $orderBy: [PersonnelBillConfOrderByInput!]) {
            personnelBillConfs(where: $where, orderBy: $orderBy) {
                id
                code
                include_subbill
                sub_bill_details {
                    is_active
                    code
                    id
                }
                include_additional_details
                include_deductions
                additional_details {
                    id
                    fields {
                        id
                        field
                        is_visible
                        is_mandatory
                        detail_id
                    }
                }
                config_details {
                    include_thrirdparty_details
                    include_sanction_details
                    include_journey_details
                    include_private_expenses
                    include_personnel_expenses
                    include_stay_details
                    include_day_allowances
                    include_transfer_charges
                    include_family_members
                    include_gpf_pran_epf
                    include_superannuation_date
                    bill_value_type
                    amount
                    include_el_encashment_days
                }
                deductions {
                    deduction_id
                    deduction
                    is_mandatory
                }
            }
        }
    `,
    personnelBill: gql`
        query personnelBill($where: PersonnelBillWhereUniqueInput!) {
            personnelBill(where: $where) {
                fin_year
                fin_period
                hoa
                id
                ref
                emp_id
                status
                is_active
                is_latest
                bill_type {
                    id
                    code
                    include_subbill
                    sub_bill_details {
                        is_active
                        code
                        id
                    }
                    include_additional_details
                    include_deductions
                    additional_details {
                        id
                        fields {
                            id
                            field
                            is_visible
                            is_mandatory
                            detail_id
                        }
                    }
                    config_details {
                        include_thrirdparty_details
                        include_sanction_details
                        include_journey_details
                        include_private_expenses
                        include_personnel_expenses
                        include_stay_details
                        include_day_allowances
                        include_transfer_charges
                        include_family_members
                        include_gpf_pran_epf
                        include_superannuation_date
                        bill_value_type
                        amount
                        include_el_encashment_days
                    }
                    deductions {
                        deduction_id
                        deduction
                        is_mandatory
                    }
                }
                bill_sub_type {
                    id
                    code
                }
                employee
                gross_bill_amount
                net_payment
                version_date
                description
                bill_conf_id
                sub_bill_conf_id
                version_no
                version_user
                version_date
                sanction_details {
                    number
                    date
                    authority
                    amount
                }
                journey_details {
                    id
                    departure_from
                    departure_date
                    arrival_date
                    arrival_to
                    claim_allowed
                    fare_paid
                    travel_class
                    ticket_no
                    travel_mode
                    distance
                }
                additional_details {
                    block_from_date
                    block_to_date
                    no_of_leaves
                    journey_purpouse
                    from_date
                    to_date
                    encashment_days
                    headquarter
                    headquarter_old
                    headquarter_new
                    order_no
                    other_expenses
                }
                day_allowances {
                    id
                    days
                    rate
                    no_of_persons
                    amount
                }
                family_members {
                    id
                    name
                    age
                    relationship
                }
                stay_details {
                    id
                    from_date
                    to_date
                    stay_type
                    halting_charge
                }
                personnel_expenses {
                    id
                    date
                    mode
                    station_from
                    station_to
                    weight
                    rate
                }
                conveyance_expenses {
                    mode
                    station
                    weight
                    rate
                    distance
                    packing_charge
                }
                deductions {
                    deduction_id
                    id
                    amount
                    remarks
                }
                transfer_charges {
                    distance
                    rate
                    home_journey_date
                    transfer_grant
                }
                third_party_payment {
                    beneficiary_id
                    share
                    amount
                    id
                }
            }
        }
    `,
    nonFMISChallalnReport: gql`
        query {
            non_fmis_challan_report {
                status
                uploadResp {
                    id
                    input_name
                }
            }
        }
    `,
    fetchChallanNfs: gql`
        query challanNfs($where: ChallanNFWhereInput, $orderBy: [ChallanNFOrderByInput!], $take: Int, $skip: Int) {
            challanNfs(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
                id
                ref
                details {
                    remit_name
                    remit_purpose
                    amount
                    bill_ref
                    remittance_details {
                        remit_name
                        sub_remit_name
                    }
                }
                payment_detail {
                    mode
                    version_date
                }
                payment_status
                amount_received
                payment_date
                total_amount
            }
        }
    `,
    importChallanNF: gql`
        mutation importChallanNF($file: Upload!, $date: DateTime!, $mis_report: String!) {
            importChallanNF(data: { file: $file, date: $date, mis_report: $mis_report }) {
                errorCodes {
                    code
                    description
                    index
                }
                errorFile {
                    uploadResp {
                        id
                        input_name
                    }
                }
                status
            }
        }
    `,
    fetchMISReports: gql`
        query {
            misReports {
                id
                name
            }
        }
    `,
    createOneMisReport: gql`
        mutation createOneMisReport($data: MisReportCreateInput!) {
            createOneMisReport(data: $data) {
                id
            }
        }
    `,
    updateOneMisReport: gql`
        mutation updateOneMisReport($data: MisReportUpdateInput!, $where: MisReportWhereUniqueInput!) {
            updateOneMisReport(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneMisReport: gql`
        mutation deleteOneMisReport($where: MisReportWhereUniqueInput!) {
            deleteOneMisReport(where: $where) {
                id
                name
            }
        }
    `,
    documentCategories: gql`
        query documentCategories($skip: Int, $take: Int, $orderBy: [DocumentCategoryOrderByInput!], $where: DocumentCategoryWhereInput) {
            documentCategories(orderBy: $orderBy, where: $where, skip: $skip, take: $take) {
                id
                ref
                name
                file_type
                upload_type
                file_size
                status
                is_active
                version_date
                is_latest
                mappings {
                    fmis_function_code
                    is_mandatory
                    allow_url
                }
            }
            appEnums(where: { enum_type: { equals: "SYS_DOC_FILE_TYPE" }, enum_group: { equals: "SYS_ADMIN" } }) {
                id
                enum_type
                enum_value
                enum_group
                display_value
                sort_order
                status
            }
        }
    `,
    documentCategory: gql`
        query documentCategory($where: DocumentCategoryWhereUniqueInput!) {
            documentCategory(where: $where) {
                id
                ref
                name
                file_type
                upload_type
                file_size
                status
                is_active
                is_latest
                version_no
                version_user
                version_date
                mappings {
                    fmis_function_code
                    is_mandatory
                }
            }
        }
    `,
    documentCategoryCount: gql`
        query documentCategoryCount($where: DocumentCategoryWhereInput) {
            documentCategoryCount(where: $where)
        }
    `,
    createOneDocumentCategory: gql`
        mutation createOneDocumentCategory($data: DocumentCategoryCreateInput!) {
            createOneDocumentCategory(data: $data) {
                id
            }
        }
    `,
    updateOneDocumentCategory: gql`
        mutation updateOneDocumentCategory($data: DocumentCategoryUpdateInput!, $where: DocumentCategoryWhereUniqueInput!) {
            updateOneDocumentCategory(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneDocumentCategory: gql`
        mutation deleteOneDocumentCategory($where: DocumentCategoryWhereUniqueInput!) {
            deleteOneDocumentCategory(where: $where) {
                id
            }
        }
    `,
    getDocumentMappings: gql`
        query getDocumentMappings($function_code: String!, $source_id: String!) {
            getDocumentMappings(function_code: $function_code, source_id: $source_id)
        }
    `,
    updateOneDocumentAttachment: gql`
        mutation updateOneDocumentAttachment($data: DocumentAttachmentUpdateInput!, $where: DocumentAttachmentWhereUniqueInput!) {
            updateOneDocumentAttachment(data: $data, where: $where) {
                version_no
                version_user
                is_rejected
                file_id
                document {
                    ref
                    attachments {
                        id
                        document_id
                        name
                        file_id
                        is_rejected
                        remarks
                        url
                        version_no
                        version_user
                        version_date
                    }
                }
            }
        }
    `,
    createOneDocumentAttachment: gql`
        mutation createOneDocumentAttachment($data: DocumentAttachmentCreateInput!) {
            createOneDocumentAttachment(data: $data) {
                version_no
                version_user
                is_rejected
                file_id
                document {
                    ref
                    attachments {
                        id
                        document_id
                        name
                        file_id
                        is_rejected
                        remarks
                        url
                        version_no
                        version_user
                        version_date
                    }
                    id
                }
                id
            }
        }
    `,
    deleteOneDocument: gql`
        mutation deleteOneDocument($where: DocumentWhereUniqueInput!) {
            deleteOneDocument(where: $where) {
                id
            }
        }
    `,
    GeneratePersonnelBillPDF: gql`
        query GeneratePersonnelBillPDF($personnel_bill_id: String!) {
            GeneratePersonnelBillPDF(personnel_bill_id: $personnel_bill_id)
        }
    `,
    challanNfCount: gql`
        query challanNfCount($where: ChallanNFWhereInput) {
            challanNfCount(where: $where)
        }
    `,
    employeeClassifLevelsAndCode: gql`
        query employeeClassifLevelsAndCode($where: EmployeeClassifLevelWhereInput) {
            employeeClassifLevels: employeeClassifLevels(where: $where) {
                id
                name
                child_level {
                    name
                    id
                    child_level {
                        name
                        id
                        child_level {
                            name
                            id
                            child_level {
                                name
                                id
                                child_level {
                                    name
                                    id
                                    child_level {
                                        name
                                        id
                                        child_level {
                                            name
                                            id
                                            child_level {
                                                name
                                                id
                                                child_level {
                                                    name
                                                    id
                                                    child_level {
                                                        name
                                                        id
                                                        child_level {
                                                            name
                                                            id
                                                            child_level {
                                                                name
                                                                id
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            employeeClassifCodes: employeeClassifCodes(where: { parent_id: { equals: null } }) {
                id
                name
                path
                classif_level {
                    name
                    id
                }
                child_codes {
                    name
                    id
                    path
                    classif_level {
                        name
                        id
                    }
                    child_codes {
                        name
                        id
                        path
                        classif_level {
                            name
                            id
                        }
                        child_codes {
                            name
                            id
                            path
                            classif_level {
                                name
                                id
                            }
                            child_codes {
                                name
                                id
                                path
                                classif_level {
                                    name
                                    id
                                }
                                child_codes {
                                    name
                                    id
                                    path
                                    classif_level {
                                        name
                                        id
                                    }
                                    child_codes {
                                        name
                                        id
                                        path
                                        classif_level {
                                            name
                                            id
                                        }
                                        child_codes {
                                            name
                                            id
                                            path
                                            classif_level {
                                                name
                                                id
                                            }
                                            child_codes {
                                                name
                                                id
                                                path
                                                classif_level {
                                                    name
                                                    id
                                                }
                                                child_codes {
                                                    name
                                                    id
                                                    path
                                                    classif_level {
                                                        name
                                                        id
                                                    }
                                                    child_codes {
                                                        name
                                                        id
                                                        path
                                                        classif_level {
                                                            name
                                                            id
                                                        }
                                                        child_codes {
                                                            name
                                                            id
                                                            path
                                                            classif_level {
                                                                name
                                                                id
                                                            }
                                                            child_codes {
                                                                name
                                                                id
                                                                path
                                                                classif_level {
                                                                    name
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    auditHierarchyCodes: gql`
        query auditHierarchyCodes($where: AuditHierarchyCodeWhereInput!) {
            auditHierarchyCodes(where: $where) {
                id
                name
                audit_level_id
            }
        }
    `,
    riskAssessmentChildLevels: gql`
        query riskAssessmentChildLevels($mda_id: String!, $parent_id: String!) {
            riskAssessmentChildLevels(mda_id: $mda_id, parent_id: $parent_id) {
                id
                name
                audit_level_id
            }
        }
    `,
    iaEmpSysadminData: gql`
        query iaEmpSysadminData($where: CountryWhereInput, $fin_year: String) {
            countries: countries(where: $where) {
                id
                name
                ccy
                ccy_code
                isd_code
                states {
                    id
                    name
                }
            }
            departmentWithDeputationOffices: departmentWithDeputationOffices(fin_year: $fin_year)
            tiers: tiers {
                id
                name
            }
        }
    `,
    tiers: gql`
        query tiers {
            tiers {
                id
                name
            }
        }
    `,
    admCoaStructDetails: gql`
        query admCoaStructDetails($where: AdmCOAStructDetailWhereInput, $orderBy: [AdmCOAStructDetailOrderByInput!]) {
            admCoaStructDetails(where: $where, orderBy: $orderBy) {
                id
                serial_no
                seg_def {
                    id
                    name
                    levels {
                        id
                        name
                    }
                }
            }
        }
    `,
    iaemployeeClassifLevelsMapping: gql`
        query employeeClassifLevels($where: EmployeeClassifLevelWhereInput) {
            employeeClassifLevels(where: $where) {
                id
                name
            }
        }
    `,
    fetchIAClassifLevelMapping: gql`
        query classifLevelMapping($where: ClassifLevelMappingWhereUniqueInput!) {
            classifLevelMapping(where: $where) {
                ref
                id
                activity
                location_allowance
                status
                is_active
                version_no
                version_user
            }
        }
    `,
    createOneClassifLevelMapping: gql`
        mutation createOneClassifLevelMapping($data: ClassifLevelMappingCreateInput!) {
            createOneClassifLevelMapping(data: $data) {
                id
            }
        }
    `,
    updateOneClassifLevelMapping: gql`
        mutation updateOneClassifLevelMapping($data: ClassifLevelMappingUpdateInput!, $where: ClassifLevelMappingWhereUniqueInput!) {
            updateOneClassifLevelMapping(data: $data, where: $where) {
                id
            }
        }
    `,
    fileNumber: gql`
        query fileNumberConfs {
            fileNumberConfs {
                id
                no_of_components
                delimeter
                components {
                    component
                    id
                    sno
                    component_size
                    component_type
                    filenumber_config_id
                }
            }
        }
    `,
    files: gql`
        query files($where: FileWhereInput) {
            files(where: $where) {
                id
                path
                input_name
                size
                mimetype
                version_no
                version_user
                version_date
            }
        }
    `,
    annualAuditPlanInfo: gql`
        query annualAuditPlanInfo($where: AAPInfoParams) {
            annualAuditPlanInfo(where: $where)
        }
    `,
    employeesMultiple: gql`
        query employees($where: EmployeeWhereInput!) {
            employees(where: $where) {
                id
                group_id
                version_no
                version_user
                mda
                employee_group {
                    id
                    name
                    ref
                }
                ref
                effective_from
                budget_office
                deputation_office
                budget_office_name
                deputation_office_name
                payroll_status
                active_status
                first_name
                middle_name
                last_name
                gender
                email
                img_url
                is_latest
                last_payment_drawn_outside
                has_govt_quarter
                status
                classif_details {
                    emp_id
                    classif_id
                    classif_code
                    classif_code_detail {
                        name
                    }
                    id
                }
                general_info {
                    father_name
                    mother_name
                    nationality
                    marital_status
                    spouse_name
                    pan_num
                    aadhar
                    mobile
                    other_mobile
                    belongs_to_minority
                    reservation_category
                    caste
                    is_disabled
                    dob
                    disability_percent
                    disability_type
                    office_location
                    daily_rate
                }
                special_info {
                    gpf_type
                    gpf_num
                    appointment_order
                    appointment_date
                    joining_date
                    super_annuation_date
                    overall_experience
                }
                bank_accounts {
                    id
                    is_primary
                    account {
                        id
                        name
                        category
                        type
                        number
                        holder_name
                        bank_id
                        ccy
                        is_active
                        bank
                    }
                }
                addresses {
                    id
                    address_id
                    is_primary
                    address {
                        id
                        line1
                        line2
                        line3
                        pin_code
                        country
                        state
                        district
                        sub_district
                        village
                        panchayat
                        is_active
                        tier_id
                    }
                }
                pay_components {
                    component_id
                    sub_component_id
                    amount
                }
                qualifications {
                    id
                    q1ualification
                    university
                    cert_number
                    year_of_passing
                    grade
                    percentage
                }
            }
        }
    `,
    beneficiaryConfs: gql`
        query beneficiaryConfs($where: BeneficiaryConfWhereInput!, $orderBy: [BeneficiaryConfOrderByInput!], $skip: Int, $take: Int) {
            beneficiaryConfs(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
                id
                name
                ref
                status
                effective_from
                description
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                can_use_pan
                can_use_gstin
                can_use_aadhar
                tag_to_employee
                tag_to_budget_office
                beneficiaries {
                    id
                }
            }
        }
    `,
    beneficiaryConf: gql`
        query beneficiaryConf($where: BeneficiaryConfWhereUniqueInput!) {
            beneficiaryConf(where: $where) {
                id
                name
                ref
                status
                effective_from
                description
                is_active
                is_latest
                is_effective
                version_no
                version_user
                version_date
                can_use_pan
                can_use_gstin
                can_use_aadhar
                tag_to_employee
                tag_to_budget_office
                beneficiaries {
                    id
                }
            }
        }
    `,
    createOneBeneficiaryConf: gql`
        mutation createOneBeneficiaryConf($data: BeneficiaryConfCreateInput!) {
            createOneBeneficiaryConf(data: $data) {
                id
            }
        }
    `,
    updateOneBeneficiaryConf: gql`
        mutation updateOneBeneficiaryConf($data: BeneficiaryConfUpdateInput!, $where: BeneficiaryConfWhereUniqueInput!) {
            updateOneBeneficiaryConf(data: $data, where: $where) {
                id
            }
        }
    `,
    deleteOneBeneficiaryConf: gql`
        mutation deleteOneBeneficiaryConf($where: BeneficiaryConfWhereUniqueInput!) {
            deleteOneBeneficiaryConf(where: $where) {
                id
            }
        }
    `,
    beneficiaryConfCount: gql`
        query beneficiaryConfCount($where: BeneficiaryConfWhereInput) {
            beneficiaryConfCount(where: $where)
        }
    `,
    getFinYearOnRef: gql`
        query fin_year_based_on_txn($source_ref: String!) {
            fin_year_based_on_txn(source_ref: $source_ref)
        }
    `
};

export default Schema;
