import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const ContractBill = lazy(() => import('../pages/expenditureManagement/master-configuration/contract-bills/ContractBill'));
const OtherBillsConfig = lazy(() => import('../pages/expenditureManagement/master-configuration/otherBills/OtherBills'));
const ConfigureOtherBill = lazy(() => import('../pages/expenditureManagement/master-configuration/otherBills/ConfigOtherBill'));
const ImprestLimit = lazy(() => import('../pages/expenditureManagement/master-configuration/imprest-limit/ImprestLimit'));
const CreateImprestLimit = lazy(() => import('../pages/expenditureManagement/master-configuration/imprest-limit/CreateImprest'));
const ConfigureBillType = lazy(() => import('../pages/expenditureManagement/master-configuration/contract-bills/ConfigureBillType'));
const PersonnelBills = lazy(() => import('../pages/expenditureManagement/master-configuration/PersonnelBills/PersonnelBills'));
const CreatePersonnelBill = lazy(() => import('../pages/expenditureManagement/master-configuration/PersonnelBills/CreatePersonnelBill'));

const expMasterItems = MenuItemsSchema.EXP_MASTER;
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const expMasterBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Expenditure Management', redirectTo: '/expendituremanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/expmaster/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const ExpMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={expMasterBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={ContractBill}
                    exact
                    path={`${props?.path}/contractbill`}
                    name="Contract Bills"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/create`}
                    name="Create Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/view/:id`}
                    name="Update Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/edit/:id`}
                    name="Update Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/view/:id/approved/:ref`}
                    name="Update Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/edit/:id/approved/:ref`}
                    name="Update Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/edit/:id/draft`}
                    name="Update Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureBillType}
                    exact
                    path={`${props?.path}/contractbill/view/:id/draft`}
                    name="View Contract Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/create`}
                    name="Create Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/edit/:id`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/view/:id`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/edit/:id/approved`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/view/:id/approved`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/view/:id/draft`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateImprestLimit}
                    exact
                    path={`${props?.path}/imprestlimit/edit/:id/draft`}
                    name="Monthly Imprest Limit"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={OtherBillsConfig}
                    exact
                    path={`${props?.path}/otherbills`}
                    name="Other Bills"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/create`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/edit/:id`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/view/:id`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/edit/:id/approved`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/view/:id/approved`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/view/:id/draft`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigureOtherBill}
                    exact
                    path={`${props?.path}/otherbills/edit/:id/draft`}
                    name="Configure Other Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={PersonnelBills}
                    exact
                    path={`${props?.path}/personnelbills`}
                    name="Create Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props?.path}/personnelbills/create`}
                    name="Create Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props?.path}/personnelbills/edit/:id`}
                    name="Edit Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props?.path}/personnelbills/view/:id/draft`}
                    name="Edit Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props.path}/personnelbills/edit/:id/draft`}
                    name="Edit Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props.path}/personnelbills/view/:id`}
                    name="View Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props.path}/personnelbills/edit/:id/approved`}
                    name="Edit Personnel Bill"
                    menuItems={expMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBill}
                    exact
                    path={`${props.path}/personnelbills/view/:id/approved`}
                    name="View Personnel Bill"
                    menuItems={expMasterItems}
                />
            </Switch>
        </div>
    );
};
export default ExpMastersRouting;
