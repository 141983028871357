import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'appkit-react';
import Schema from '../../../../schema/AppSchema';
import { useQuery } from '@apollo/client';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryTable';
import { ProgressSpinner } from 'primereact/progressspinner';

function Beneficiaries(props) {
    const [benifyRows, setBenifyRows] = useState([]);
    const [formState, setFormState] = useState({
        selected: ''
    });

    let { loading } = useQuery(Schema.beneficiaries, {
        variables: {
            orderBy: { id: 'asc' },
            where: { is_effective: { equals: true }, id: { notIn: props.ids } }
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.beneficiaries) {
                setBenifyRows(data.beneficiaries);
            }
        },
        onError: () => {}
    });

    const handleAction = (type, e, rowData) => {
        setBenifyRows(
            benifyRows.map((row) => {
                if (row.id === rowData.id) {
                    row.checked = true;
                } else {
                    row.checked = false;
                }
                return row;
            })
        );
        setFormState({ ...formState, selected: rowData });
    };

    return (
        <Modal visible={props.showModal} onCancel={props.handleCancel} className="common_css w-600" backdropClosable={false}>
            <ModalHeader>Add Beneficiary</ModalHeader>
            <ModalBody className="confirm-modal font-14">
                {loading ? (
                    <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
                ) : (
                    <SecondaryDataTable
                        className="primary-table"
                        rows={benifyRows}
                        columns={props.columns}
                        emptyMessage="No Benificiaries found"
                        pagination={true}
                        handleChangeInParent={handleAction}
                    />
                )}
            </ModalBody>
            <ModalFooter className="p-10">
                <Button
                    size="sm"
                    kind="primary"
                    className="m-r-10"
                    disabled={!formState.selected}
                    onClick={() => props.handleSave(formState)}
                >
                    Yes
                </Button>
                <Button size="sm" kind="secondary" className="" onClick={props.handleCancel}>
                    No
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default Beneficiaries;
