import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const PayCommission = lazy(() => import('../pages/employeeManagement/employeeMasters/payCommission/PayCommission'));
const AddPayCommission = lazy(() => import('../pages/employeeManagement/employeeMasters/payCommission/AddPayCommission'));
const PayEntitlements = lazy(() => import('../pages/employeeManagement/employeeMasters/payEntitlements/PayEntitlements'));
const AddPayEntitlements = lazy(() => import('../pages/employeeManagement/employeeMasters/payEntitlements/AddPayEntitlement'));
const PayComponent = lazy(() => import('../pages/employeeManagement/employeeMasters/payComponent/PayComponent'));
const AddPayComponent = lazy(() => import('../pages/employeeManagement/employeeMasters/payComponent/AddPayComponent'));
const CreateEmpStructure = lazy(() => import('../pages/employeeManagement/employeeMasters/structure/CreateStructure'));
const CreateDetails = lazy(() => import('../pages/employeeManagement/employeeMasters/details/CreateDetails'));
const EmployeeGroup = lazy(() => import('../pages/employeeManagement/employeeMasters/employeeGroup/EmployeeGroup'));
const AddEmployeeGroup = lazy(() => import('../pages/employeeManagement/employeeMasters/employeeGroup/AddEmployeeGroup'));
const EmployeePayEntitlements = lazy(() =>
    import('../pages/employeeManagement/employeeMasters/EmployeePayEntitlement/EmployeePayEntitlement')
);
const AddPayEntitlementComponent = lazy(() =>
    import('../pages/employeeManagement/employeeMasters/EmployeePayEntitlement/AddPayEntitlementComponent')
);

const EntitleView = lazy(() => import('../pages/employeeManagement/employeeMasters/EmployeePayEntitlement/EntitlementsView'));
const employeeMasterBreadCrmb = (props) => {
    let employeeMasterValues = [{ name: 'Employee Management', redirectTo: '/employeemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/empmaster/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    employeeMasterValues = [...employeeMasterValues, ...obj];
    return employeeMasterValues;
};
const employeeMasterItems = MenuItemsSchema.EMP_MASTERS;
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const EmployeeMangMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={employeeMasterBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={PayCommission}
                    exact
                    path={`${props?.path}/paycommission`}
                    name="Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/create`}
                    name="Create Pay Commission"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/view/:id/approved`}
                    name="Create Pay Commission"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/edit/:id/approved`}
                    name="EDit Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/edit/:id`}
                    name="Edit Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/view/:id/draft`}
                    name="Edit Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/edit/:id/draft`}
                    name="Edit Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayCommission}
                    exact
                    path={`${props?.path}/paycommission/view/:id`}
                    name="View Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={PayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/create`}
                    name="Create Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/view/:id`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/view/:id/draft`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/edit/:id`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/edit/:id/approved`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/view/:id/approved`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlements}
                    exact
                    path={`${props?.path}/payentitlement/edit/:id/draft`}
                    name="Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={PayComponent}
                    exact
                    path={`${props?.path}/paycomponent`}
                    name="Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/create`}
                    name="Create Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/view/:id/draft`}
                    name="Edit Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/edit/:id`}
                    name="View Pay Component"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/view/:id/approved`}
                    name="View Pay Component"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/edit/:id/approved`}
                    name="View Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/edit/:id/draft`}
                    name="Edit Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayComponent}
                    exact
                    path={`${props?.path}/paycomponent/view/:id`}
                    name="View Pay Component"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={EmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup`}
                    name="Pay Commission"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/create`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/view/:id`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/view/:id/draft`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/edit/:id`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/view/:id/approved/:ref`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/edit/:id/approved/:ref`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddEmployeeGroup}
                    exact
                    path={`${props?.path}/empgroup/edit/:id/draft`}
                    name="Employee Group"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEmpStructure}
                    exact
                    path={`${props?.path}/empclsstructure`}
                    name="Employee Classification"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDetails}
                    exact
                    path={`${props?.path}/empclsdetails`}
                    name="Employee Classification Details"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={EmployeePayEntitlements}
                    exact
                    path={`${props?.path}/emppayentitlement`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/create`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/create/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/view/:id/approved/:ref`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/edit/:id/approved/:ref`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={EntitleView}
                    exact
                    path={`${props?.path}/emppayentitlement/exist`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={EntitleView}
                    exact
                    path={`${props?.path}/emppayentitlement/exist/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/exist/create/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/exist/view/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/exist/edit/:id/approved/:ref`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
				<AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/exist/view/:id/approved/:ref`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/view/:id/draft`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/view/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/edit/:id/draft`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayEntitlementComponent}
                    exact
                    path={`${props?.path}/emppayentitlement/edit/:id`}
                    name="Employee Pay Entitlements"
                    menuItems={employeeMasterItems}
                />
            </Switch>
        </div>
    );
};
export default EmployeeMangMastersRouting;
