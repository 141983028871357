export const TableSchema = {
    ROLES: [
        {
            header: 'Code',
            field: 'code',
            filter: true,
            filterPlaceholder: 'Code',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true
        },
        {
            header: 'Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Name',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true
        },
        {
            header: '',
            field: null,
            fieldType: 'actions',
            edit: true,
            editRoute: 'popup',
            delete: true
        }
    ],

    COA_STRUCTURE: [
        {
            header: 'Structure Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Structure Name',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Financial Year',
            field: 'f_year',
            filter: true,
            filterPlaceholder: 'Search by Financial Year',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            bodyStyle: 'left',
            headerAlign: 'center',
            width: '100px'
        }
    ],
    COA_MASTERS: [
        {
            header: 'Financial Year',
            field: 'name',
            filter: false,
            filterPlaceholder: 'Search By Financial Year',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        /*
         * {
         *     header: 'From Date',
         *     field: 'master_start_date_local',
         *     fieldType: 'date',
         *     sortable: true,
         *     width: '150px'
         * },
         * {
         *     header: 'To Date',
         *     fieldType: 'date',
         *     field: 'master_end_date_local',
         *     sortable: true,
         *     width: '150px'
         * },
         */
        {
            header: 'Masters From Date',
            field: 'start_date_local',
            fieldType: 'date',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Masters To Date',
            fieldType: 'date',
            field: 'end_date_local',
            sortable: true,
            width: '180px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '50px'
        }
    ],
    SEGLEVEL_MAPPING: [
        {
            header: 'FMIS Function',
            field: 'fmis_function',
            filter: true,
            filterPlaceholder: 'Search by FMIS Function',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px'
        },
        {
            header: 'FMIS Sub Function',
            field: 'fmis_sub_function',
            filter: true,
            filterPlaceholder: 'Search by FMIS Sub Function',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '80px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '30px'
        }
    ],
    EXTERNAL_REPORT_MAPPING: [
        {
            header: 'External Reporting Name',
            field: 'file.report_name',
            filter: true,
            filterPlaceholder: 'Search by External Reporting Name',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '300px'
        },
        {
            header: 'External Segment Name',
            field: 'ext_seg_def.name',
            filter: true,
            filterPlaceholder: 'Search by External Segment Name',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '230px',
            fieldType: 'tooltip'
        },
        {
            header: 'External Segment Level',
            field: 'ext_level_def.name',
            filter: true,
            filterPlaceholder: 'Search by External Segment Level',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '230px',
            fieldType: 'tooltip'
        },
        {
            header: 'COA Segment Name',
            field: 'seg_def.name',
            filter: true,
            filterPlaceholder: 'Search by COA Segment Name',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '230px',
            fieldType: 'tooltip'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: null,
            filterMatchMode: 'contains',
            width: '120px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '80px'
        }
    ],
    COA_Details: [
        {
            header: 'FMIS Function',
            field: 'fmis_function',
            filter: true,
            filterPlaceholder: 'Search by Function',
            filterElement: null,
            filterMatchMode: 'contains'
        },
        {
            header: 'FMIS Sub Function',
            field: 'fmis_sub_function',
            filter: true,
            filterPlaceholder: 'Search by Function',
            filterElement: null,
            filterMatchMode: 'contains'
        },
        {
            header: 'Fund',
            field: 'fund',
            filter: true,
            filterPlaceholder: 'Search by Fund',
            filterElement: null,
            filterMatchMode: 'contains'
        },
        {
            header: 'Function',
            field: 'function',
            filter: true,
            filterPlaceholder: 'Search by Function',
            filterElement: null,
            filterMatchMode: 'contains'
        },
        {
            header: 'Functionary',
            field: 'functionary',
            filter: true,
            filterPlaceholder: 'Search by Functionary',
            filterElement: null,
            filterMatchMode: 'contains'
        },
        {
            header: 'Account Head',
            field: 'accountHead',
            filter: true,
            filterPlaceholder: 'Search by Account Head',
            filterElement: null,
            filterMatchMode: 'contains'
        }
    ],
    EXTERNAL_REPORT_MAPPING_TABLE: [
        {
            header: 'External Report Codes',
            field: 'externalReportCodes',
            filter: true,
            filterPlaceholder: 'Search by External Report Codes',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false
        },
        {
            header: 'COA Segment Level & Codes',
            field: 'coasegmentLevelNCodes',
            filter: true,
            filterPlaceholder: 'Search by COA Segment Level & Codes',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: false
        }
    ],
    AUDIT_LOG: [
        {
            header: 'Version',
            field: 'version_no',
            filter: true,
            filterPlaceholder: 'Search by Version',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip',
            width: '200px'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Status',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Date & Time',
            field: 'version_date_local',
            filter: true,
            filterPlaceholder: 'Search by Date & Time',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip',
            width: '200px'
        },
        {
            header: '',
            field: null,
            fieldType: 'actions',
            audit: true,
            routerLink: '',
            sortable: false,
            width: '80px'
        }
    ],
    AUDIT_INSTANCE_LOG: [
        {
            header: 'Activity',
            field: 'status',
            filter: true,
            filterPlaceholder: 'Search by Activity',
            filterElement: null,
            filterMatchMode: 'contains',
            fieldType: 'tooltip',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Performed By',
            field: 'version_user',
            filter: true,
            filterPlaceholder: 'Search by Performed By',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip',
            width: '200px'
        },
        {
            header: 'Date',
            field: 'version_date_local',
            filter: true,
            filterPlaceholder: 'Search by Date',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            fieldType: 'tooltip',
            width: '200px'
        },
        {
            header: 'Remarks/Comments',
            field: 'comments',
            fieldType: 'actions',
            comments: true,
            width: '200px'
        },
        {
            header: 'Actions',
            field: null,
            fieldType: 'actions',
            clickable: true,
            routerLink: '',
            width: '80px'
        }
    ],
    TASKS: [
        {
            header: 'Transaction ID',
            field: 'id',
            filter: true,
            filterPlaceholder: 'Search by Transaction ID',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '25%',
            fieldType: 'columnClickable'
        },
        {
            header: 'Activity Name',
            field: 'fmis_function_name',
            filter: true,
            filterPlaceholder: 'Search by Activity Name',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '25%',
            fieldType: 'tooltip'
        },
        {
            header: 'Department',
            field: 'office_code',
            filter: true,
            filterPlaceholder: 'Search by Department',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '25%',
            fieldType: 'tooltip'
        },
        {
            header: 'Transaction Date',
            field: 'created_date',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Select Transaction Date',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '25%'
        }
    ],
    ECONOMIC_CLASSIFICATION: [
        {
            header: 'Classification ID',
            field: 'ref',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Classification ID',
            width: '100px'
        },
        {
            header: 'Created by',
            field: 'version_user',
            filter: true,
            filterPlaceholder: 'Search By Username',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterElement: 'dropdown',
            filterOptions: [],
            filterPlaceholder: 'Search By Status',
            width: '100px',
            sortable: true
        }
    ],
    DOC_UPLOAD: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '30px',
            textCenter: true,
            headerAlign: 'center'
        },
        {
            header: 'Document Category',
            field: 'name_ref',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Document Description',
            field: 'description',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Document',
            field: 'fileName',
            fieldType: 'view_clickable',
            width: '100px'
        },
        {
            header: 'Actions',
            width: '30px'
        }
    ],
    DOC_UPLOAD_INFO: [
        {
            header: 'Document ID',
            field: 'document_ref',
            width: '80px'
        },
        {
            header: 'Date & Time',
            field: 'local_date',
            width: '80px'
        },
        {
            header: 'User',
            field: 'version_user',
            width: '80px'
        }
    ],
    AUDIT_PROCEDURE: [
        {
            header: '',
            field: 'checked',
            filterElement: null,
            fieldType: 'checkbox',
            selectionMode: 'multiple',
            filter: false,
            headerAlign: 'center',
            alignTo: 'center',
            bodyStyle: 'center',
            width: '50px',
            className: 'alignCheckbox'
        },
        {
            header: 'Risk No.',
            field: 'riskNo',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk No.',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk',
            field: 'riskName',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk',
            fieldType: 'viewDetails',
            width: '200px'
        },
        {
            header: 'Control No.',
            field: 'controlNo',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Control No.',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control',
            field: 'controlName',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Control',
            fieldType: 'viewDetails',
            width: '200px'
        },
        {
            header: 'Procedure',
            field: 'procedureLabel',
            width: '150px',
            fieldType: 'viewDetails'
        }
    ],
    AUDIT_PROCEDURE_AP: [
        {
            header: 'AP No.',
            field: 'ap_no',
            width: '10px'
        },
        {
            header: 'Audit Procedure',
            field: 'audit_procedure',
            width: '80px'
        }
    ],
    AUDIT_PROCEDURE_TESTS: [
        {
            header: 'Audit Test no.',
            field: 'audit_test_no',
            width: '10px',
            filterPlaceholder: 'Search',
            filter: true
        },
        {
            header: 'Audit Test ',
            field: 'audit_test',
            width: '80px',
            filterPlaceholder: 'Search',
            filter: true
        }
    ],
    HUMAN_RESOURCES_PLAN: [
        {
            header: 'Description',
            field: 'description',
            fieldtype: 'tooltip',
            width: '200px'
        },
        {
            header: 'ABC(Grade1)',
            field: 'abc',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'DEF(Grade2)',
            field: 'def',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'GHI(Grade3)',
            field: 'ghi',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'JKL(Grade3)',
            field: 'jkl',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'MNO(Grade3)',
            field: 'mno',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'PQR(Grade4)',
            field: 'pqr',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'STU(Grade4)',
            field: 'stu',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'XYZ(Grade5)',
            field: 'xyz',
            fieldtype: 'tooltip',
            width: '90px',
            bodyStyle: 'center'
        },
        {
            header: 'Total Weeks',
            field: 'total_weeks',
            fieldtype: 'tooltip',
            width: '100px',
            bodyStyle: 'center'
        },
        {
            header: 'Outsourced HR Weeks',
            field: 'outsourced_hr_weeks',
            fieldtype: 'tooltip',
            width: '100px',
            bodyStyle: 'center'
        }
    ],
    MDA_STRATEGIC_OBJ: [
        {
            header: 'Sl. No',
            field: 'sno',
            width: '20%'
        },
        {
            header: 'Strategic Objectives',
            field: 'strategicobj',
            width: '80%'
        },
        {
            header: 'Summary Of Strategies',
            field: 'summary',
            width: '80%'
        },
        {
            header: 'Document',
            field: 'advanceType',
            width: '80%'
        }
    ],
    ORGANISATION_STRUCTURE: [
        {
            header: 'Organisation Structure',
            field: 'orgstructure',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: 'Name of the Document',
            field: 'nameofdoc',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: 'Upload',
            field: 'upload',
            field_type: 'tooltip',
            width: '100px'
        }
    ],
    KEY_STAKEHOLDERS: [
        {
            header: 'S.No. ',
            field: 's_no',
            field_type: 'tooltip',
            width: '20px'
        },
        {
            header: 'Code',
            field: 'code',
            field_type: 'tooltip',
            width: '80px'
        },
        {
            header: 'External Stakeholder Name',
            field: 'externalname',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: 'Code',
            field: 'code',
            field_type: 'tooltip',
            width: '80px'
        },
        {
            header: 'Internal Stakeholder Name',
            field: 'internalname',
            field_type: 'tooltip',
            width: '100px'
        }
    ],
    OBJECTIVE_POLICIES: [
        {
            header: 'S.No. ',
            field: 's_no',
            field_type: 'tooltip',
            width: '30px'
        },
        {
            header: 'Nature of Document',
            field: 'nature_of_doc',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: 'Document Name',
            field: 'doc_name',
            field_type: 'tooltip',
            width: '100px'
        },

        {
            header: 'Link to Document',
            field: 'link_doc',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: 'Date of Upload',
            field: 'upload_date',
            field_type: 'tooltip',
            width: '100px'
        },
        {
            header: '',
            field: 'actions',
            field_type: 'tooltip',
            width: '50px'
        }
    ],
    AUDIT_HR_PLAN: [
        {
            header: 'Event Type',
            field: 'event_type',
            field_type: 'tooltip',
            width: '50px'
        },
        {
            header: 'Available Weeks',
            field: 'available_weeks',
            field_type: 'tooltip',
            width: '50px'
        }
    ],
    CALENDAR_EVENTS: [
        {
            header: 'Event ID',
            field: 'ref',
            width: '180px',
            filter: true,
            sortable: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Event ID'
        },
        {
            header: 'Event Type',
            field: 'event_type',
            fieldType: 'tooltip',
            width: '180px',
            filter: true,
            sortable: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Event Type'
        },
        {
            header: 'Start Date',
            field: 'start_date',
            fieldType: 'date',
            width: '200px',
            filter: true,
            sortable: false,
            filterElement: 'date',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Start Date'
        },
        {
            header: 'End Date',
            field: 'end_date',
            fieldType: 'date',
            width: '200px',
            filter: true,
            sortable: false,
            filterElement: 'date',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by End Date'
        },
        {
            header: 'Description',
            field: 'description',
            fieldType: 'tooltip',
            width: '250px',
            filter: true,
            sortable: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Description'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            width: '180px',
            filter: true,
            sortable: true,
            filterOptions: [],
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Workflow Status'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date_local',
            width: '200px',
            filter: true,
            sortable: true,
            fieldType: 'date',
            filterElement: 'date',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Last Update Date & Time'
        },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],
    FILE_NUMBER: [
        {
            header: 'S.No. ',
            field: 'sno',
            field_type: 'tooltip',
            width: '10px',
            bodyStyle: 'left',
            sortable: true
        },
        {
            header: 'Component ',
            field: 'component',
            field_type: 'tooltip',
            width: '50px'
        },
        {
            header: 'Component Size ',
            field: 'component_size',
            field_type: 'tooltip',
            width: '40px'
        },
        {
            header: 'Component Type ',
            field: 'component_type',
            field_type: 'tooltip',
            width: '40px'
        }
    ],
    CREATE_BENEFICIARY_CATEGORY: [
        {
            header: 'Field Name',
            field: 'field_label',
            field_type: 'tooltip',
            width: '50px'
        },
        {
            header: 'Applicability',
            field: 'checked',
            fieldType: 'checkboxVal',
            selectionMode: 'multiple',
            width: '200px',
            className: 'headerCheckbox'
        }
    ],
    CREATE_BENEFICIARY_CATEGORY_VIEW: [
        {
            header: 'Field Name',
            field: 'field_label',
            field_type: 'tooltip',
            width: '50px'
        },
        {
            header: 'Applicability',
            field: 'applicability',
            field_type: 'tooltip',
            width: '200px'
        }
    ]
};

export default TableSchema;
