import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../../../components/Calendar/CalendarComponent';
import InputComponent from '../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../components/fDataTable/SecondaryTable';
import ConfirmationModal from '../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../Validation';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { getDate, serverDate, uniqueId } from '../../../../util';

const TCPE = (props) => {
    const [rows, setRows] = useState(props.formStateObj?.Transportation_Charges_of_Personnel_Effect);
    useEffect(() => {
        setRows(props.formStateObj?.Transportation_Charges_of_Personnel_Effect);
        // eslint-disable-next-line
    }, [props.formStateObj?.Transportation_Charges_of_Personnel_Effect]);
    let viewMode = TableSchema.Transportation_Charges_of_Personnel_Effect.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.mode !== 'view' ? TableSchema.Transportation_Charges_of_Personnel_Effect : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        modes: ['Rail', 'Road', 'Airways', 'Waterways']
    });
    let emptyObj = {
        date: '',
        mode: '',
        station_from: '',
        station_to: '',
        weight: '',
        rate: '',
        amount: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    const fields = [
        { key: 'rate', validators: 'numbersOnly', maxLength: 6 },
        { key: 'weight', validators: 'numbersOnly', maxLength: 3 }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (err[key] || ((key === 'rate' || key === 'weight') && parseInt(ev) <= 0) || (typeof ev === 'string' && ev.trim() === '')) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const saveForm = () => {
        currentDetailsObj.station_from = currentDetailsObj.station_from?.trim();
        currentDetailsObj.station_to = currentDetailsObj.station_to?.trim();
        currentDetailsObj.date = getDate(currentDetailsObj.date, true);
        currentDetailsObj.amount = parseInt(currentDetailsObj.weight) * parseInt(currentDetailsObj.rate);
        if (state.actionType === 'edit') {
            let indx = rows.findIndex((a) => a.id === editObj.id);
            rows[indx] = currentDetailsObj;
            setRows(rows);
        } else {
            currentDetailsObj.id = uniqueId(20);
            rows.push(currentDetailsObj);
            setRows(rows);
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transportation_Charges_of_Personnel_Effect', rows);
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                key !== 'amount' &&
                (currentDetailsObj[key] === undefined ||
                    currentDetailsObj[key] === '' ||
                    (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === ''))
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['date'] = new Date(newObj.date);
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setRows(filteredRows);
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Transportation_Charges_of_Personnel_Effect', filteredRows);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const getSumOfRows = () => {
        let Total = 0;
        rows.forEach((ro) => {
            Total = Total + parseInt(ro.amount);
        });
        return isNaN(Total) ? '' : Total;
    };

    let footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total" colSpan={6} />
                <Column footer={getSumOfRows()} footerStyle={{ textAlign: 'right' }} />
                {props.mode !== 'view' && <Column />}
            </Row>
        </ColumnGroup>
    );
    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Transportation Charges of Personnel Effect are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.mode}
                    footerGroup={footerGroup}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Date{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Calendar
                                            className="w-100"
                                            maxDate={new Date(serverDate().setDate(serverDate().getDate() - 1))}
                                            readOnlyInput={true}
                                            placeholder="Select Date From"
                                            appendTo={document.self}
                                            value={currentDetailsObj.date}
                                            onChange={(e) => handleChange(e.target.value, 'date')}
                                            dateFormat="dd M yy"
                                            showIcon
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Mode{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="mode"
                                            value={currentDetailsObj.mode}
                                            onChange={(e) => handleChange(e.value, 'mode')}
                                            appendTo={document.self}
                                            options={state.modes}
                                            placeholder="Select Mode"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Station From{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="station_from"
                                            type="text"
                                            value={currentDetailsObj.station_from}
                                            maxLength={25}
                                            onChange={(e) => handleChange(e, 'station_from')}
                                            placeholder="Enter Station From"
                                            hasError={errors && errors.station_from}
                                            alphanumeric="true"
                                            errMsg="Please enter valid Station From"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Station To{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="station_to"
                                            type="text"
                                            value={currentDetailsObj.station_to}
                                            maxLength={25}
                                            onChange={(e) => handleChange(e, 'station_to')}
                                            placeholder="Enter Station To"
                                            hasError={errors && errors.station_to}
                                            alphanumeric="true"
                                            errMsg="Please enter valid Station To"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Weight{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="weight"
                                            type="text"
                                            value={currentDetailsObj.weight}
                                            maxLength={3}
                                            onChange={(e) => handleChange(e, 'weight')}
                                            placeholder="Enter Weight"
                                            hasError={errors && errors.weight}
                                            errMsg="Please enter valid Weight"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Rate{props.mode !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="rate"
                                            type="text"
                                            value={currentDetailsObj.rate}
                                            maxLength={6}
                                            onChange={(e) => handleChange(e, 'rate')}
                                            placeholder="Enter Rate"
                                            hasError={errors && errors.rate}
                                            errMsg="Please enter valid Rate"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.mode !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (rows?.length === 0 ? '' : 'Another ') + 'Transportation Charges of Personnel Effect'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Transportation Charges of Personnel Effect"
                    showModal={state.showDeleteModal}
                    name={
                        'Transportation Charges of Personnel Effect : From Station: ' +
                        currentDetailsObj.station_from +
                        ' - To Station: ' +
                        currentDetailsObj.station_to
                    }
                />
            )}
        </div>
    );
};

export default TCPE;
