import React from 'react';
const Footer = () => {
    // let copyRight = JSON.parse(localStorage.getItem('APP_CONFIGURATION_VALUES'))?.filter((configData) => configData.code === 'COPY_RIGHT');
    return (
        <div className="main-footer">
            <div className=" row align-center">
                <div className="ellipses col-11 fs-12 p-r-0 p-l-25 footer-link">
                    {/* {copyRight && copyRight[0] && (
                        <span className="m-l-20">
                            © {copyRight[0]?.value1} <span dangerouslySetInnerHTML={{ __html: `${copyRight[0]?.value2}` }}></span>
                        </span>
                    )} */}
                    {new Date().getFullYear()} © Tectoro. All rights reserved.
                </div>
                <div className="col-1 text-right">
                    <img height="40" src={process.env.PUBLIC_URL + '/assets/icons/Innovoke_bulb.png'} alt="nnovoke Bulb" />
                </div>
            </div>
        </div>
    );
};

export default Footer;
