import React, { lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import IsAuthorized from '../commons/AuthorizationService';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import Auth from '../pages/auth/Auth';

const AnnualAuditPlan = lazy(() => import('../pages/internalAudit/strategicAndAnnualAuditPlan/annualAuditPlan/annualAuditPlan'));
const AddAnnualAuditPlan = lazy(() => import('../pages/internalAudit/strategicAndAnnualAuditPlan/annualAuditPlan/addAnnualPlan'));

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AnnualAuditPlanItems = MenuItemsSchema.ANNUAL_AUDIT_PLAN;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const AnnualAuditPlanBreadCrmb = (props) => {
    let AnnualAuditPlanValues = [{ name: 'Annual Audit Plan', redirectTo: '/saap' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            {
                name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1),
                redirectTo: '/annualauditplan/' + subPath[2],
                key: subPath[2]
            },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AnnualAuditPlanValues = [...AnnualAuditPlanValues, ...obj];
    return AnnualAuditPlanValues;
};
const AnnualAuditPlanRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={AnnualAuditPlanBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={AnnualAuditPlan}
                    exact
                    path={`${props?.path}/annualauditplan`}
                    name="Annual Audit Plan View"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/create`}
                    name="Create Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/edit/:id/approved`}
                    name="Create Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/edit/:id`}
                    name="Edit Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/view/:id/draft`}
                    name="View Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/edit/:id/draft`}
                    name="Edit Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/view/:id/existdraft`}
                    name="Edit Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/view/:id`}
                    name="View Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/view/:id/approved`}
                    name="View Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddAnnualAuditPlan}
                    exact
                    path={`${props.path}/annualauditplan/view/:id/existapproved`}
                    name="View Annual Audit Plan"
                    menuItems={AnnualAuditPlanItems}
                />
            </Switch>
        </div>
    );
};
export default AnnualAuditPlanRouting;
