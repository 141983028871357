export const TableSchema = {
    USERS: [
        {
            header: 'Employee ID',
            field: 'employee.ref',
            filter: false,
            filterPlaceholder: 'Search by Employee ID',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '150px'
        },
        {
            header: 'First Name',
            field: 'employee.first_name',
            filter: false,
            filterPlaceholder: 'Search by First Name',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Name',
            field: 'employee.last_name',
            filter: false,
            filterPlaceholder: 'Search by Last Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Login Name',
            field: 'user_name',
            filter: true,
            filterPlaceholder: 'Search by Login Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Email',
            field: 'employee.email',
            filter: false,
            filterPlaceholder: 'Search by Email',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px',
            filterElement: 'dropdown',
            filterOptions: []
        },
        {
            header: 'Active / Inactive',
            field: 'act_status',
            filter: true,
            filterPlaceholder: 'Search by Active / Inactive',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'actions',
            idFieldName: 'employee.id',
            width: '95px'
        }
    ],

    MASTER: [
        {
            header: 'Role ID',
            field: 'ref',
            filter: true,
            filterPlaceholder: 'Search by Role ID',
            filterElement: 'search',
            sortable: true,
            width: '150px',
            filterMatchMode: 'contains'
        },
        {
            header: 'Role Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Role Name',
            filterElement: 'search',
            width: '150px',
            sortable: true,
            filterMatchMode: 'contains',
            fieldType: 'tooltip'
        },
        {
            header: 'Role Type',
            field: 'type',
            filter: true,
            filterPlaceholder: 'Search by Role Type',
            filterElement: 'dropdown',
            filterOptions: 'GLOBAL_NONGLOBAL',
            width: '150px',
            sortable: true,
            filterMatchMode: 'contains',
            fieldType: 'tooltip'
        },
        {
            header: 'Department Name',
            field: 'department',
            filter: false,
            filterPlaceholder: 'Search by Department Name',
            filterElement: 'search',
            className: 'departmentTooltip pointer',
            width: '180px',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            fieldType: 'tooltip'
        },
        {
            header: 'Office Name',
            field: 'office',
            filter: false,
            filterPlaceholder: 'Search by Office Name',
            filterElement: 'search',
            className: 'officeTooltip pointer',
            width: '200px',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: 'Active / InActive',
            field: 'is_active',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            body: '',
            width: '120px'
        }
    ],
    SYSTEM_ADMINISTRATION: [
        {
            header: 'Function',
            field: 'function',
            filter: true,
            filterPlaceholder: 'Search by Function',
            filterElement: null,
            sortable: true,
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Function',
            field: 'subfunction',
            filter: true,
            filterPlaceholder: 'Search by Sub Function',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Module',
            field: 'submodule',
            filter: true,
            filterPlaceholder: 'Search by Sub Module',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'View',
            field: 'view',
            filterElement: null,
            fieldType: 'checkbox',
            sortable: false,
            width: '100px'
        },
        {
            header: 'Create',
            field: 'create',
            filterElement: null,
            fieldType: 'checkbox',
            sortable: false,
            width: '100px'
        },
        {
            header: 'Edit',
            field: 'edit',
            filterElement: null,
            sortable: false,
            fieldType: 'checkbox',
            width: '100px'
        },
        {
            header: 'Delete',
            field: 'delete',
            filterElement: null,
            sortable: false,
            fieldType: 'checkbox',
            width: '100px'
        }
    ],
    ACCESS_PRIVILEGES: [
        {
            header: 'FMIS Function',
            field: 'function',
            filter: true,
            filterPlaceholder: 'Search by FMIS Function',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'FMIS Sub Function',
            field: 'subfunction',
            filter: true,
            filterPlaceholder: 'Search by FMIS Sub Function',
            filterElement: null,
            sortable: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub Module',
            field: 'submodule',
            filter: true,
            filterPlaceholder: 'Search by Sub Module',
            filterElement: null,
            sortable: true,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'deselctHOA',
            width: '150px'
        },
        {
            header: 'View',
            field: 'view',
            filterElement: null,
            fieldType: 'checkbox',
            sortable: false,
            width: '100px'
        },
        {
            header: 'Create',
            field: 'create',
            filterElement: null,
            fieldType: 'checkbox',
            sortable: false,
            width: '100px'
        },
        {
            header: 'Edit',
            field: 'edit',
            filterElement: null,
            sortable: false,
            fieldType: 'checkbox',
            width: '100px'
        },
        {
            header: 'Delete',
            field: 'delete',
            filterElement: null,
            sortable: false,
            fieldType: 'checkbox',
            width: '100px'
        }
    ],
    UR_Requests: [
        {
            header: 'Employee ID',
            field: 'id',
            filter: true,
            filterPlaceholder: 'Search by Employee ID',
            filterMatchMode: 'contains'
        },
        {
            header: 'First Name',
            filterPlaceholder: 'Search by First Name',
            field: 'first_name',
            filter: true
        },
        {
            header: 'Middle Name',
            filterPlaceholder: 'Search by Middle Name',
            field: 'mdl_name',
            filter: true
        },
        {
            header: 'Last Name',
            filterPlaceholder: 'Search by Last Name',
            field: 'last_name',
            filter: true
        },
        {
            header: 'Department',
            filterPlaceholder: 'Search by Department',
            field: 'department',
            filter: true
        },
        {
            header: 'Office',
            field: 'office',
            filter: true,
            filterPlaceholder: 'Search by Office',
            filterElement: null,
            body: ''
        },
        {
            header: 'Zone',
            field: 'zone',
            filter: true,
            filterPlaceholder: 'Search by Zone',
            filterElement: null,
            body: ''
        },
        {
            header: 'Role',
            field: 'role',
            filter: true,
            filterPlaceholder: 'Search by Role',
            filterElement: null,
            body: ''
        },
        {
            header: 'Notifying Manager',
            field: 'notifying_manager',
            filter: true,
            filterPlaceholder: 'Search by Notifying Manager',
            filterElement: null,
            body: '',
            width: '12%'
        },
        {
            header: 'Workflow Status',
            field: 'workflow_status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: null,
            body: '',
            width: '12%'
        },
        {
            header: '',
            field: 'actions',
            filterElement: null
        }
    ],
    ASSIGN_ROLE: [
        {
            header: 'Department',
            filterPlaceholder: 'Search by Department',
            field: 'department',
            filter: true,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Office',
            field: 'office',
            filter: true,
            filterPlaceholder: 'Search by Office',
            filterElement: null,
            width: '200px'
        },
        {
            header: 'Role',
            field: 'role',
            filter: true,
            filterPlaceholder: 'Search by Role',
            filterElement: null,
            body: '',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Notifying Manager',
            field: 'Notifying_Manager',
            filter: true,
            filterPlaceholder: 'Search by Notifying Manager',
            filterElement: null,
            body: '',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Active /Inactive',
            field: 'is_active',
            filter: true,
            filterPlaceholder: 'Search by Active /Inactive',
            filterElement: null,
            body: '',
            width: '150px'
        },
        {
            header: '',
            field: 'actions',
            filterElement: null,
            width: '150px'
        }
    ],
    ASSIGN_ROLE_SELECT: [
        {
            header: '',
            field: 'checked',
            fieldType: 'checkbox',
            selectionMode: 'multiple',
            headerAlign: 'center',
            alignTo: 'center',
            bodyStyle: 'center',
            width: '25px',
            className: 'alignCheckbox'
        },
        {
            header: 'Department',
            field: 'department',
            filter: true,
            filterPlaceholder: 'Search by Department',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Office',
            field: 'office',
            filter: true,
            filterPlaceholder: 'Search by Office',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '180px',
            fieldType: 'tooltip'
        }
    ],
    VIEW_ROLE: [
        {
            header: 'Department',
            field: 'department.label',
            filter: true,
            filterPlaceholder: 'Search by Department',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '50%'
        },
        {
            header: 'Office',
            field: 'office.label',
            filter: true,
            filterPlaceholder: 'Search by Office',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '50%'
        }
    ],
    HOA_DESELECT_ACTIVITY: [
        {
            header: 'Activity',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Search by Activity',
            filterElement: null,
            filterMatchMode: 'contains',
            sortable: true,
            width: '30px',
            fieldType: 'tooltip'
        },
        {
            width: '30px'
        }
    ]
};
export default TableSchema;
