let currency = localStorage.getItem('CURRENCY') ? localStorage.getItem('CURRENCY') : 'INR';

export const TableSchema = {
    SUPPLY_CATEGORY: [
        {
            header: 'Supplier Category Number',
            field: 'ref',
            filter: true,
            fieldType: 'clickable',
            filterPlaceholder: 'Supplier Category Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            customView: true,
            sortable: true,
            width: '150px'
        },
        {
            header: 'Supplier Category',
            field: 'name',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Supplier Category',
            width: '180px',
            fieldType: 'tooltip'
        },
        {
            header: 'Number of Suppliers',
            field: 'no_of_suppliers',
            sortable: true,
            sortableDisabled: true,
            filter: false,
            width: '115px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            width: '170px'
        },
        {
            header: 'Active / Inactive',
            field: 'is_active',
            filterOptions: 'ACTIVE_INACTIVE',
            filter: true,
            filterPlaceholder: 'Active / Inactive',
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            sortable: true,
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date_local',
            fieldType: 'date',
            filter: true,
            sortable: true,
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            width: '180px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            width: '100px'
        }
    ],
    CATEGORIES: [
        {
            header: 'Fields (Job Title)',
            field: 'field_name',
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'Display on Supplier Registration Form',
            field: 'is_visible',
            fieldType: 'checkbox',
            width: '100px',
            alignTo: 'center'
        },
        {
            header: 'Is Field Input Mandatory?',
            field: 'is_mandatory',
            fieldType: 'checkbox',
            width: '100px',
            alignTo: 'center'
        },
        {
            header: 'Is Attachment Mandatory?',
            field: 'can_add_attachment',
            fieldType: 'checkbox',
            width: '100px',
            alignTo: 'center'
        },
        {
            header: 'Enable Validation Check?',
            field: 'can_add_validate',
            fieldType: 'checkbox',
            width: '100px',
            alignTo: 'center'
        }
    ],
    SUPPLY_REGISTRATION: [
        {
            header: 'Supplier Number',
            field: 'ref',
            filter: true,
            fieldType: 'clickable',
            customView: true,
            filterPlaceholder: 'Supplier Number',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '130px'
        },
        {
            header: 'Supplier Category',
            field: 'category.name',
            filter: true,
            filterPlaceholder: 'Supplier Category',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            filter: true,
            filterPlaceholder: 'Supplier Name',
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            width: '110px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '200px'
        }
    ],
    BANK_DETAILS: [
        {
            header: 'Bank Account Number',
            field: 'number',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'ifsc',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            fieldType: 'switch',
            validation: 'unique',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '150px'
        }
    ],

    BANK_DETAILS_VIEW: [
        {
            header: 'Account Category',
            field: 'category',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Type',
            field: 'type',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Number',
            field: 'number',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'IFSC Code',
            field: 'ifsc',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Name',
            field: 'bank_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'branch_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Currency',
            field: 'ccy',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '80px',
            fieldType: 'tooltip'
        },
        {
            header: 'Account Holder Name',
            field: 'holder_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '100px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            width: '100px',
            headerAlign: 'center'
        }
    ],

    ADDRESS_DETAILS: [
        {
            header: 'Address',
            field: 'line1',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '250px',
            fieldType: 'tooltip'
        },
        {
            header: 'City',
            field: 'district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Pin Code',
            field: 'pin_code',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Country',
            field: 'country_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'State',
            field: 'state_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Sub-District/Block/Tehsil',
            field: 'sub_district',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Village',
            field: 'village',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Panchayat',
            field: 'panchayat',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '120px'
        }
    ],
    CONTACT_DETAILS: [
        {
            header: 'Name',
            field: 'full_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Designation',
            field: 'designation',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Mobile Number',
            field: 'mobile',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Alternate Contact No',
            field: 'alt_mobile',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Fax Number',
            field: 'fax',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Email ID',
            field: 'email',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '120px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: '',
            fieldType: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            edit: true,
            delete: true,
            width: '120px'
        }
    ],
    CONTACT_DETAILS_VIEW: [
        {
            header: 'Salutation',
            field: 'salutation',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'First Name',
            field: 'first_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Middle Name',
            field: 'middle_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Last Name',
            field: 'last_name',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Designation',
            field: 'designation',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Mobile Number',
            field: 'mobile',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Alternate Contact No',
            field: 'alt_mobile',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Fax Number',
            field: 'fax',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Email ID',
            field: 'email',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'is_active',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '100px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        },
        {
            header: 'Is Primary',
            field: 'is_primary',
            validation: 'unique',
            fieldType: 'switch',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '100px',
            className: 'activeInactiveSwitch',
            bodyStyle: 'center',
            headerAlign: 'center'
        }
    ],
    DOCUMENT_SECTION: [
        {
            header: 'Document Name',
            field: 'field_name',
            fieldType: 'text',
            inputPlaceholder: 'Enter Dcoument Name',
            filter: false,
            width: '10%'
        },
        {
            header: 'Upload',
            field: 'upload',
            fieldType: 'upload',
            buttonType: 'upload',
            buttonLabel: 'Browse',
            filter: false,
            width: '10%'
        },
        {
            header: 'Document',
            field: 'document',
            fieldType: 'template',
            filter: false,
            width: '10%'
        },
        {
            header: '',
            field: 'actions',
            filter: false,
            body: '',
            width: '5%'
        }
    ],
    LINK_SUPPLIER_VIEW: [
        {
            header: 'Supplier Number',
            field: 'ref',
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Relationship Type',
            field: 'linked_as',
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            field: 'isActive',
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        }
    ],
    LINK_PARENT_SUPPLIER_LIST: [
        {
            header: '',
            field: 'checked',
            selectionMode: 'multiple',
            width: '6%',
            fieldType: 'checkbox'
        },
        {
            header: 'Supplier Number',
            field: 'ref',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Number',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Name',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Category',
            field: 'category.name',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Category',
            fieldType: 'tooltip'
        },
        {
            header: 'Contact Number',
            field: 'mobile',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Contact Number',
            fieldType: 'tooltip'
        }
    ],
    CONTRACTS: [
        {
            header: 'Contract Number',
            field: 'display_ref',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Contract Number',
            width: '170px'
        },
        {
            header: 'Contract Name',
            field: 'name',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Contract Name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Supply Category',
            field: 'supply_catogories',
            filter: true,
            filterElement: 'search',
            sortable: true,
            sortableDisabled: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Supply Category',
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Contract Value(Incl of taxes)',
            field: 'cntrctVal',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            sortable: true,
            sortableDisabled: true,
            filterPlaceholder: 'Search By Contract Value(Incl of taxes)',
            width: '250px',
            fieldType: 'tooltip',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Supplier',
            field: 'supplier.name',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search By Supplier',
            sortable: true,
            sortableDisabled: true,
            width: '140px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            sortable: true,
            filterElement: 'dropdown',
            filterOptions: [],
            filterPlaceholder: 'Search By Workflow Status',
            width: '150px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date_local',
            filterPlaceholder: 'Search By Last Update Date & Time',
            fieldType: 'date',
            filterMatchMode: 'contains',
            filter: true,
            sortable: true,
            filterElement: 'date',
            width: '210px'
        },
        {
            header: 'Contract Status',
            field: 'contract_status',
            filter: true,
            sortable: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterOptions: [],
            filterPlaceholder: 'Search By Contract Status',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '190px',
            body: ''
        }
    ],
    LINK_BRANCH_SUPPLIER_LIST: [
        {
            header: '',
            fieldType: 'radio',
            selectionMode: 'single',
            field: 'checked',
            width: '70px'
        },
        {
            header: 'Supplier Number',
            field: 'ref',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Number',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Name',
            field: 'name',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Name',
            fieldType: 'tooltip'
        },
        {
            header: 'Supplier Category',
            field: 'category.name',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Supplier Catrgory',
            fieldType: 'tooltip'
        },
        {
            header: 'Contact Number',
            field: 'mobile',
            filter: true,
            width: '200px',
            filterPlaceholder: 'Contact Number',
            fieldType: 'tooltip'
        }
    ],
    BankForm: [
        {
            header: 'Bank Name',
            field: 'bankName.name',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Branch Name',
            field: 'bankName.branch_name',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bank Guarantee Number',
            field: 'bGauNo',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Expiry Date',
            field: 'expiryDate',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount of Bank Guarantee (' + currency + ')',
            field: 'amountBGaurnte',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '150px'
        }
    ],
    CONTCT_DETAILS: [
        {
            header: 'Sl No.',
            field: 'slno',
            width: '80px'
        },
        {
            header: 'Supply Category',
            field: 'sup_cat.name',
            fieldType: 'view_clickable',
            width: '150px'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Sanction (' + currency + ')',
            fieldType: 'view_clickable',
            field: 'cntrct_fin_sanc.totalAmount',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Contract Value Incl of Taxes(' + currency + ')',
            field: 'cntrct_val_inc_tax',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Contract Value Excl of Taxes (' + currency + ')',
            field: 'cntrct_val_exc_tax',
            width: '100px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Admissible Variance(%)',
            field: 'admissable_var',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '100px'
        }
    ],
    CONTCT_FIN_DETAILS: [
        {
            header: 'Financial Year',
            field: 'fin_year',
            width: '20%'
        },
        {
            header: 'Financial Sanction reference number',
            inputPlaceholder: 'Financial Sanction reference number',
            field: 'fin_sanc_ref_no',
            fieldType: 'input',
            inputType: 'text',
            filter: false,
            width: '40%'
        },
        {
            header: 'Financial Sanction (' + currency + ')',
            inputPlaceholder: 'Financial Sanction (' + currency + ')',
            field: 'fin_sanc_inr',
            fieldType: 'input',
            inputType: 'text',
            filter: false,
            width: '25%'
        },
        {
            header: '',
            field: 'InlineActions',
            fieldType: 'InlineActions',
            filter: false,
            width: '15%'
        }
    ],
    CONTCT_ADVNCE: [
        {
            header: 'Advance Type',
            field: 'adv_type.name',
            fieldType: 'islatest',
            width: '100px'
        },
        {
            header: 'Advance Rate(%)',
            field: 'adv_rate',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Advance Amount',
            field: 'adv_amount',
            width: '100px',
            fieldType: 'tooltip',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Advance Recovery Method',
            field: 'adv_rec_mthd',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '100px'
        }
    ],
    CONTCT_OTHER_DEDUCTIONS: [
        {
            header: 'Sl No.',
            field: 'slno',
            filter: false,
            width: '100px'
        },
        {
            header: 'Deduction',
            field: 'deduction.name',
            fieldType: 'islatest',
            width: '100px'
        },
        {
            header: 'Type of Deduction',
            field: 'dedType',
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Amount (' + currency + ')',
            field: 'amount',
            width: '100px',
            headerAlign: 'right',
            fieldType: 'tooltip',
            bodyStyle: 'right'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '100px'
        }
    ],
    CONTCT_PAY_SCHEDULE: [
        {
            header: 'Sl No.',
            field: 'slno',
            width: '50px'
        },
        {
            header: 'Payment Milestone',
            field: 'pay_milestone',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Due Date',
            field: 'due_date',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Incl of Taxes (' + currency + ')',
            field: 'amt_inc_tax',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Amount Excl of Taxes (' + currency + ')',
            field: 'amt_exc_tax',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Advance Recovery (' + currency + ')',
            field: 'adavce_recovery',
            fieldType: 'view_clickable',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'Security Deposit Deduction (' + currency + ')',
            field: 'security_depst_dedct',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: 'Other Deduction (' + currency + ')',
            fieldType: 'view_clickable',
            field: 'other_deduction',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right'
        },
        {
            header: 'HOA',
            field: 'hoa',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment Amount(' + currency + ')',
            field: 'net_pay_amt',
            width: '150px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            width: '100px'
        }
    ],
    CONTCT_PAY_ADVNCE: [
        {
            header: 'Sl No.',
            field: 'slno',
            filter: false,
            width: '15%'
        },
        {
            header: 'Advance Type',
            field: 'adv_type',
            fieldType: 'tooltip',
            filter: false,
            width: '17%'
        },
        {
            header: 'Advance Amount',
            field: 'adv_inc_amount',
            fieldType: 'rowinput',
            inputType: 'text',
            inputPlaceholder: 'Advance Amount',
            inputMaxlength: 15,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '18%'
        },
        {
            header: 'Advance Recovery Method',
            field: 'adv_rec_mthd',
            filter: false,
            fieldType: 'tooltip',
            width: '27%'
        },
        {
            header: '',
            field: 'Actions',
            fieldType: 'Actions',
            width: '15%'
        }
    ],
    CONTCT_PAY_OTH_DEDUCTIONS: [
        {
            header: 'Sl No.',
            field: 'slno',
            filter: false,
            width: '8%'
        },
        {
            header: 'Deduction',
            field: 'deduction.name',
            hover: 'deduction.info',
            fieldType: 'hover',
            filter: false,
            width: '25%'
        },
        {
            header: 'Type of Deduction',
            field: 'dedType',
            filter: false,
            fieldType: 'tooltip',
            width: '20%'
        },
        {
            header: 'Amount (' + currency + ')',
            field: 'pay_amount',
            fieldType: 'rowinput',
            inputType: 'text',
            inputPlaceholder: 'Advance Amount',
            inputMaxlength: 15,
            filter: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '20%'
        },
        {
            header: 'Remarks',
            field: 'remarks',
            fieldType: 'rowinput',
            inputType: 'text',
            inputPlaceholder: 'Remarks',
            inputMaxlength: 50,
            filter: false,
            width: '20%'
        },
        {
            field: 'Actions',
            fieldType: 'Actions',
            width: '15%'
        }
    ],
    CONTCT_FINANC_SANC: [
        {
            header: 'Year',
            field: 'year',
            width: '70px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Sanction Ref',
            field: 'fin_sanc_ref',
            width: '120px',
            fieldType: 'tooltip'
        },
        {
            header: 'Financial Sanction (' + currency + ')',
            field: 'fin_sanc_inr',
            width: '120px',
            headerAlign: 'right',
            bodyStyle: 'right',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '50px',
            bodyStyle: 'center'
        }
    ],
    CONTACT_MODAL: [
        {
            header: '',
            field: 'checked',
            selectionMode: 'single',
            width: '70px',
            fieldType: 'radio'
        },
        {
            header: 'Name',
            field: 'contact.name',
            filter: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Designation',
            field: 'contact.designation',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Mobile Number',
            field: 'contact.mobile',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Alternate Contact',
            field: 'contact.alt_mobile',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Fax',
            field: 'contact.fax',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Email',
            field: 'contact.email',
            width: '200px',
            fieldType: 'tooltip'
        }
    ],
    TRANSACTION_DETAILS: [
        {
            header: 'Contract Number',
            field: 'ref',
            fieldType: 'view_clickable',
            routerLink: 'contracts/view',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px'
        },
        {
            header: 'Signing Date',
            field: 'signing_date',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Closing Date',
            field: 'closing_date',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Contract Status',
            field: 'contract_status',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Total Contract Amount',
            field: 'total_val',
            filter: false,
            filterElement: null,
            sortable: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Contract Outstanding Amount',
            field: 'netAmount',
            filter: false,
            filterElement: null,
            sortable: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '200px',
            fieldType: 'tooltip'
        }
    ],

    OTHER_INVOICES: [
        {
            header: 'Bills Number',
            field: 'ref',
            fieldType: 'view_clickable',
            routerLink1: 'otherbills/view',
            routerLink2: 'contractbills/view',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px'
        },
        {
            header: 'Bill Type',
            field: 'billType',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Approval Date',
            field: 'updated_at_local',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Gross Bill Amount',
            field: 'gross_bill_amount',
            filter: false,
            filterElement: null,
            sortable: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Net Payment',
            field: 'net_payment_amount',
            filter: false,
            filterElement: null,
            sortable: false,
            headerAlign: 'right',
            bodyStyle: 'right',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Bill Status',
            field: 'status',
            filter: false,
            filterElement: null,
            sortable: false,
            width: '200px',
            fieldType: 'tooltip'
        }
    ]
};

export default TableSchema;
