import React, { useState } from 'react';
import InputComponent from '../../../components/InputComponent/InputComponent';
import { Button } from 'appkit-react';
import { TableSchema } from '../CMTableSchema';
import FormSchema from './formSchema';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../../components/Calendar/CalendarComponent';
import { getDate, serverDate } from '../../../util';
import SecondaryDataTable from '../../../components/fDataTable/SecondaryTable';
import ConfirmationModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../Validation';

const BankGuaranteeForm = (props) => {
    const [formState, setFormState] = useState({
        rows:
            props.Performance_Bank_Guarantee.rows && props.Performance_Bank_Guarantee.rows.length > 0
                ? props.Performance_Bank_Guarantee.rows.map((obj) => {
                      obj.expiryDate = getDate(obj.expiryDate, true);
                      return obj;
                  })
                : []
    });
    let viewMode = TableSchema.BankForm.filter((a) => a.fieldType !== 'actions');
    let tablecolms = props.formType !== 'view' ? TableSchema.BankForm : viewMode;
    let genFormDetails = props.formStateObj?.General_Contract_Information;
    let currYear = serverDate().getFullYear();
    let signingDateRange = currYear + ':' + (parseInt(currYear) + 20).toString();
    const [editable, setEditable] = useState(false);
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        banksList: getBanksList(),
        actionType: '',
        showDeleteModal: false
    });
    let emptyObj = {
        bankName: '',
        bGauNo: '',
        expiryDate: '',
        amountBGaurnte: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState(false);
    function getBanksList() {
        let list = JSON.parse(JSON.stringify(props.bankList));
        if (formState.rows.length > 0)
            list = list.filter((a) => {
                return !formState.rows.find((b) => b?.bankName?.id === a.id);
            });
        return list;
    }
    const handleChange = (ev, key) => {
        let pattern = new RegExp(/^\d*(\.\d{0,2})?$/g);
        if (
            key === 'amountBGaurnte' &&
            window?._VALIDATIONS_?.CONTRACT_BANK_GUARANTEE_AMOUNT &&
            (isNaN(ev) || ev.indexOf(' ') >= 0 || parseInt(ev) === 0 || !pattern.test(ev))
        ) {
            return;
        }
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
        if (key === 'bGauNo' || key === 'amountBGaurnte') {
            validateFeild(ev, key);
        }
    };
    const validateFeild = (ev, key) => {
        let err = {};
        err = FormValidation.validateFileld('required', key, ev);
        let preverrors = { ...errors, ...err };
        setErrors(preverrors);
    };

    const saveForm = () => {
        currentDetailsObj.expiryDate = getDate(currentDetailsObj.expiryDate, true);
        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.bankName.id === editObj.bankName.id);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState });
        } else {
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState });
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        setEditable(false);
        props.saveFormData('Performance_Bank_Guarantee', formState);
    };

    const disableSave = () => {
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            newObj['expiryDate'] = new Date(newObj.expiryDate);
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction, banksList: [...getBanksList(), newObj.bankName] });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        formState.rows = formState.rows.filter((a) => {
            return a.bankName.id !== currentDetailsObj.bankName.id;
        });
        setFormState({ ...formState, rows: formState.rows });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
        props.saveFormData('Performance_Bank_Guarantee', formState);
        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true, banksList: getBanksList() });
        setCurrentDetailsObj(emptyObj);
        setEditable(true);
        setEditObj({});
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <SecondaryDataTable
                    rows={formState.rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Performance Bank Guarantee details are yet to be created"
                    className="secondary-table"
                    scrollable
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row m-b-10">
                            {FormSchema.FLD_PBG_BNK_NME.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_PBG_BNK_NME.name}
                                        {FormSchema.FLD_PBG_BNK_NME.is_mandatory !== -1 &&
                                        props.formType !== 'view' &&
                                        (!props.checkIsApproved || editable) ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    {props.formType !== 'view' && (!props.checkIsApproved || editable) ? (
                                        <Dropdown
                                            name="bankName"
                                            value={currentDetailsObj.bankName}
                                            onChange={(e) => handleChange(e.value, 'bankName')}
                                            appendTo={document.self}
                                            options={state.banksList}
                                            placeholder="Select Bank Name"
                                            className="w-60"
                                            optionLabel="name"
                                            filter={true}
                                            filterPlaceholder="Search"
                                            filterBy="name"
                                            resetFilterOnHide={true}
                                        />
                                    ) : (
                                        <span>{currentDetailsObj?.bankName?.name ? currentDetailsObj.bankName.name : '–'}</span>
                                    )}
                                </div>
                            )}
                            {FormSchema.FLD_PBG_BRNCH_NME.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">{FormSchema.FLD_PBG_BRNCH_NME.name} :</b>
                                    <div className={'input-div'}>
                                        <span className="ellipses h1-name">
                                            {currentDetailsObj?.bankName?.branch_name ? currentDetailsObj.bankName.branch_name : '–'}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="row m-b-10">
                            {FormSchema.FLD_PBG_BNK_GAUR_NO.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_PBG_BNK_GAUR_NO.name}
                                        {FormSchema.FLD_PBG_BNK_GAUR_NO.is_mandatory !== -1 &&
                                        props.formType !== 'view' &&
                                        (!props.checkIsApproved || editable) ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    {props.formType !== 'view' && (!props.checkIsApproved || editable) ? (
                                        <InputComponent
                                            className="w-60"
                                            inputBoxSize="sm"
                                            name="bGauNo"
                                            type="text"
                                            value={currentDetailsObj.bGauNo}
                                            maxLength={50}
                                            onChange={(e) => handleChange(e, 'bGauNo')}
                                            placeholder="Enter Bank Guarantee Number"
                                            alphanumeric={window?._VALIDATIONS_?.CONTRACT_BANK_GUARANTEE_NUMBER}
                                            hasError={errors && errors.bGauNo}
                                            errMsg="Bank Guarantee Number is mandatory"
                                        />
                                    ) : (
                                        <span>{currentDetailsObj?.bGauNo ? currentDetailsObj.bGauNo : '–'}</span>
                                    )}
                                </div>
                            )}
                            {FormSchema.FLD_PBG_EXP_DATE.is_visible !== -1 && (
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_PBG_EXP_DATE.name}
                                        {FormSchema.FLD_PBG_EXP_DATE.is_mandatory !== -1 ? <span className="icon-primary"> *</span> : ' :'}
                                    </b>
                                    <div className="w-60">
                                        <Calendar
                                            placeholder="Select Expiry Date"
                                            value={currentDetailsObj.expiryDate}
                                            appendTo={document.self}
                                            onChange={(e) => handleChange(e.target.value, 'expiryDate')}
                                            minDate={
                                                window?._VALIDATIONS_?.CONTRACT_PERFORMANCE_EXPIRY_DATE ? genFormDetails.closing_date : ''
                                            }
                                            dateFormat="dd M yy"
                                            showIcon
                                            readOnlyInput={true}
                                            yearRange={signingDateRange}
                                            yearNavigator={true}
                                            monthNavigator={true}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {FormSchema.FLD_PBG_AMT_BNK_GAUR.is_visible !== -1 && (
                            <div className="row m-b-10">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 display-flex">
                                    <b className="w-40 fs-14">
                                        {FormSchema.FLD_PBG_AMT_BNK_GAUR.name}
                                        {FormSchema.FLD_PBG_AMT_BNK_GAUR.is_mandatory !== -1 ? (
                                            <span className="icon-primary"> *</span>
                                        ) : (
                                            ' :'
                                        )}
                                    </b>
                                    <InputComponent
                                        className="w-60"
                                        inputBoxSize="sm"
                                        name="amountBGaurnte"
                                        type="text"
                                        maxLength={15}
                                        value={currentDetailsObj.amountBGaurnte}
                                        onChange={(e) => handleChange(e, 'amountBGaurnte')}
                                        placeholder="Enter Amount of Bank Guarantee"
                                        hasError={errors && errors.amountBGaurnte}
                                        errMsg="Amount of Bank Guarantee is mandatory"
                                    />
                                </div>
                            </div>
                        )}
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update Performance Bank Guarantee' : 'Add Performance Bank Guarantee'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                    setEditable(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.formType !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (formState?.rows?.length === 0 ? '' : 'Another ') + 'Performance Bank Guarantee Details'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Performance Bank Guarantee"
                    showModal={state.showDeleteModal}
                    name={'Bank Name : ' + currentDetailsObj.bankName.name}
                />
            )}
        </div>
    );
};

export default BankGuaranteeForm;
