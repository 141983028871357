import store from '../index';
import Schema from '../schema/AppSchema';
import { useQuery } from '@apollo/client';
import { serverDate } from '../util';
import { eventClient } from '../apollo';

let publicUrl = process.env.PUBLIC_URL;
let iconUrl = '/assets/icons/';
let imageUrl = '/assets/images/';
let auditType = localStorage.getItem('audit_assignment_type');

export const dashboardConfig = {
    SYSTEM_ADMINISTRATION: {
        order: 1,
        type: 'System Administration',
        noOfPendings: 0,
        title: 'Configuration of COA, user administration, workflow management and other masters.',
        src: publicUrl + imageUrl + 'system-administration.png',
        icon: publicUrl + iconUrl + 'systemAdministrationIcon.svg',
        redirectTo: 'systemadministration'
    },
    CONTRACT_MANAGEMENT: {
        order: 2,
        type: 'Contract Management',
        noOfPendings: 0,
        title: 'Support in Supplier registration and Contract creation and amendment.',
        src: publicUrl + imageUrl + 'paymentManage.png',
        icon: publicUrl + iconUrl + 'contractManageIcon.svg',
        redirectTo: 'contractmanagement'
    },
    BUDGETPREPARATION: {
        type: 'Budget Preparation',
        noOfPendings: 0,
        title: 'Different activities of Budget Preparation can be managed here.',
        src: publicUrl + imageUrl + 'Budget_Preperation.png',
        icon: publicUrl + iconUrl + 'Budget_Preperation.svg',
        redirectTo: 'budgetpreparation'
    },
    BUDGETALLOCATION: {
        type: 'Budget Allocation / Distribution',
        noOfPendings: 0,
        title: 'Different activities of Budget Allocation / Distribution can be managed here.',
        src: publicUrl + imageUrl + 'Budget_Allocation.png',
        icon: publicUrl + iconUrl + 'Budget_Allocation.svg',
        redirectTo: 'budgetallocationdistribution'
    },
    COMMITMENT: {
        type: 'Commitment Management',
        noOfPendings: 0,
        title: 'Different activities of Commitment Management can be managed here.',
        src: publicUrl + imageUrl + 'Commitment_Management.png',
        icon: publicUrl + iconUrl + 'Commitment_Management.svg',
        redirectTo: 'commitmentmanagement'
    },
    EXPENDITURE_MANAGEMENT: {
        order: 3,
        type: 'Expenditure Management',
        noOfPendings: 0,
        title: 'Different activities of Expenditure Management can be managed here.',
        src: publicUrl + imageUrl + 'billsManage.png',
        icon: publicUrl + iconUrl + 'billsManageIcon.svg',
        redirectTo: 'expendituremanagement'
    },
    PAYMENT_MANAGEMENT: {
        order: 5,
        type: 'Payments Management',
        noOfPendings: 0,
        title: 'Different activities of Payments Management can be managed here.',
        src: publicUrl + imageUrl + 'paymentManage.png',
        icon: publicUrl + iconUrl + 'paymentManageIcon.svg',
        redirectTo: 'paymentmanagement'
    },
    /*
     * REVENUE_MANAGEMENT: {
     *     order: 4,
     *     type: 'Revenue Management',
     *     noOfPendings: 0,
     *     title: 'Different activities of Revenue Management can be managed here.',
     *     src: publicUrl + imageUrl + 'receiptManage.png',
     *     icon: publicUrl + iconUrl + 'receiptManageIcon.svg',
     *     redirectTo: 'revenuemanagement'
     * },
     * GENERALLEDGER: {
     *     type: 'General Ledger',
     *     noOfPendings: 0,
     *     title: 'Different activities of General Ledger can be managed here.',
     *     src: publicUrl + imageUrl + 'generalLedger.png',
     *     icon: publicUrl + iconUrl + 'generalLedgerIcon.svg',
     *     redirectTo: 'generalledger'
     * },
     */
    ASSET: {
        type: 'Asset Management',
        noOfPendings: 0,
        title: 'Different activities of Asset Management can be managed here.',
        src: publicUrl + imageUrl + 'Asset_Management.png',
        icon: publicUrl + iconUrl + 'Asset_Management.svg',
        redirectTo: 'assetmanagement'
    },
    EMPLOYEE_MANAGEMENT: {
        order: 7,
        type: 'Employee Management',
        noOfPendings: 0,
        title: 'Different activities of Employee Management can be managed here.',
        src: publicUrl + imageUrl + 'contractManage.png',
        icon: publicUrl + iconUrl + 'Emp.svg',
        redirectTo: 'employeemanagement'
    },
    INTERNAL_AUDIT_MASTERS: {
        icon: '/assets/icons/masters.svg',
        noOfPendings: 0,
        order: 8,
        redirectTo: 'masters',
        src: '/assets/images/receiptManage.png',
        title: 'Different activities of Masters can be managed here.',
        type: 'Masters'
    },
    STRATEGIC_AND_ANNUAL_AUDIT_PLANNING: {
        icon: '/assets/icons/sap.svg',
        noOfPendings: 0,
        order: 9,
        redirectTo: 'saap',
        src: '/assets/images/billsManage.png',
        title: 'Different activities of Strategic and Annual Audit Planning can be managed here.',
        type: 'Strategic and Annual Audit Planning'
    },
    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION: {
        icon: '/assets/icons/aape.svg',
        noOfPendings: 0,
        order: 10,
        redirectTo: 'aape',
        src: '/assets/images/generalLedger.png',
        title: 'Different activities of Audit Assignment Planning and Execution can be managed here.',
        type: 'Audit Assignment Planning and Execution'
    },
    REPORTS_AND_DASHBOARDS: {
        icon: '/assets/icons/Emp.svg',
        noOfPendings: 0,
        order: 10,
        redirectTo: 'reportsanddashboards',
        src: '/assets/images/billsManage.png',
        title: 'Different activities of Reports and Dashboards can be managed here.',
        type: 'Reports and Dashboards'
    }
};
export const takeActionNavigationForFmis = (history, notif) => {
    switch (notif.fmis_function) {
        //* ***  Expenditure Management *** *//
        case 'EMP_PAYROLL_VOUCHER':
            history.push('/expbills/payrollvoucher/view/' + notif.source_id);
            break;
        case 'EXP_PERSONNEL_BILL':
            history.push('/expbills/personnelbills/view/' + notif.source_id);
            break;
        case 'EXP_CONTRACT_ADVANCE_BILL':
        case 'EXP_CONTRACT_RUNNING_BILL':
        case 'EXP_CONTRACT_SEC_DEPOSIT_REFUND':
        case 'EXP_CONTRACT_FINAL_BILL':
            history.push('/expbills/contractbill/view/' + notif.source_id);
            break;
        case 'EXP_OTHER_BILL_CREATION':
            history.push('/expbills/otherbills/view/' + notif.source_id);
            break;
        case 'EXP_IMPREST_RELEASE_CREATION':
            history.push('/expbills/imprestrelease/view/' + notif.source_id);
            break;
        case 'EXP_IMPREST_ADJ_CREATION':
            history.push('/expbills/imprestadjustment/view/' + notif.source_id);
            break;
        case 'EXP_PERSONNEL_BILL_MASTER':
            history.push('/expmaster/personnelbills/view/' + notif.source_id);
            break;
        case 'EXP_CONTRACT_BILL_MASTER':
            history.push('/expmaster/contractbill/view/' + notif.source_id);
            break;
        case 'EXP_OTHER_BILL_MASTER':
            history.push('/expmaster/otherbills/view/' + notif.source_id);
            break;
        case 'EXP_IMPREST_CONFIG_MASTER':
            history.push('/expmaster/imprestlimit/view/' + notif.source_id);
            break;
        //* ***  Contract Management *** *//
        case 'CNTR_SUPPLIER_CTGRY_MASTER':
            history.push('/cm/configuresuppliercategory/view/' + notif.source_id);
            break;
        case 'CNTR_SUPPLIER_REGISTRATION':
            history.push('/cm/supplierregistration/view/' + notif.source_id);
            break;
        case 'CNTR_CONTRACT_CREATION':
            history.push('/cc/contracts/view/' + notif.source_id);
            break;
        case 'SYS_SUPPLY_CTGRY_MASTER':
            history.push('/cmm/supplycategorymaster/view/' + notif.source_id);
            break;
        case 'SYS_ADVANCE_MASTER':
            history.push('/cmm/advancemaster/view/' + notif.source_id);
            break;
        //* *** Payment Management *** *//
        case 'PAYMENT_INSTRUCTION':
            history.push('/pm/pis/piview/' + notif.source_id);
            break;
        case 'PAYMENT_BILL':
            history.push('/pm/bills/view/' + notif.source_id);
            break;
        //* *** System Administration *** *//
        case 'COA_SEGMENT_CONF_MASTER':
            history.push('/coa/csc');
            break;
        case 'COA_ECONOMIC_CODES_MASTER':
            history.push('/coa/cecc/view/' + notif.source_id);
            break;
        case 'SYS_USER_MASTER':
            history.push('/users/view/' + notif.source_id);
            break;
        case 'SYS_ROLE_MASTER':
            history.push('/usermasters/view/' + notif.source_id);
            break;
        case 'SYS_DEPT_MASTER':
            history.push('/commonmasters/departmentconfig/view/' + notif.source_id);
            break;
        case 'SYS_DEDUCTION_MASTER':
            history.push('/commonmasters/deductionmaster/view/' + notif.source_id);
            break;
        case 'SYS_BENEFICIARY_DETAILS':
            history.push('/commonmasters/beneficiarydetails/view/' + notif.source_id);
            break;
        case 'SYS_BENEFICIARY_CONF':
            history.push('/commonmasters/beneficiarycategory/view/' + notif.source_id);
            break;
        case 'SYS_DOC_CATEGORY':
            history.push('/commonmasters/documentcategory/view/' + notif.source_id);
            break;
        //* *** Revenue Management *** *//
        case 'RVN_SEG_DEF_CONFIG':
            history.push('/rm/remittanceconfig');
            break;
        case 'RVN_REMITTANCE_PURPOSE_CONFIG':
            history.push('/rm/rpm/view/' + notif.source_id);
            break;
        case 'RVN_REPORT_CONFIG':
            history.push('/rm/crf/view/' + notif.source_id);
            break;
        //* *** Employee Management *** *//
        case 'EMP_CREATION':
            history.push('/empfunction/empdetails/view/' + notif.source_id);
            break;
        case 'EMP_PAY_ENTITLEMENT_CHANGE':
            history.push('/empfunction/payentitlementchange/view/' + notif.source_id);
            break;
        case 'EMP_PAY_COMMISSION_MASTER':
            history.push('/empmaster/paycommission/view/' + notif.source_id);
            break;
        case 'EMP_PAY_ENTITLEMENT_MASTER':
            history.push('/empmaster/payentitlement/view/' + notif.source_id);
            break;
        case 'EMP_PAY_COMPONENT_MASTER':
            history.push('/empmaster/paycomponent/view/' + notif.source_id);
            break;
        case 'EMP_PAY_COMPONENT_DEF_MASTER':
            history.push('/empmaster/emppayentitlement/view/' + notif.source_id);
            break;
        case 'EMP_GROUP_MASTER':
            history.push('/empmaster/empgroup/view/' + notif.source_id);
            break;
        default:
    }
};

export const takeActionNavigationForAms = (history, notif) => {
    switch (notif.fmis_function) {
        //* *** System Administration *** *//
        case 'COA_SEGMENT_CONF_MASTER':
            history.push('/coa/csc');
            break;
        case 'SYS_USER_MASTER':
            history.push('/users/view/' + notif.source_id);
            break;
        case 'SYS_ROLE_MASTER':
            history.push('/usermasters/view/' + notif.source_id);
            break;
        case 'SYS_DEPT_MASTER':
            history.push('/commonmasters/departmentconfig/view/' + notif.source_id);
            break;
        case 'SYS_DOC_CATEGORY':
            history.push('/commonmasters/documentcategory/view/' + notif.source_id);
            break;
        //* ***  Supplier Management *** *//
        case 'CNTR_SUPPLIER_CTGRY_MASTER':
            history.push('/cm/configuresuppliercategory/view/' + notif.source_id);
            break;
        case 'CNTR_SUPPLIER_REGISTRATION':
            history.push('/cm/supplierregistration/view/' + notif.source_id);
            break;
        //* ***  Employee Management *** *//
        case 'EMP_CREATION':
            history.push('/empfunction/employee/view/' + notif.source_id);
            break;
        //* *** Global Masters *** *//
        case 'IA_RISK_DEFINITION':
            history.push('/globalmaster/riskdef/view/' + notif.source_id);
            break;
        case 'IA_CONTROLS_DEFINITION':
            history.push('/globalmaster/controlsdef/view/' + notif.source_id);
            break;
        case 'IA_AUDIT_OBJECTIVE_DEF':
            history.push('/globalmaster/auditprogram/auditobj/view/' + notif.source_id);
            break;
        case 'IA_AUDIT_PROCEDURE_DEF':
            history.push('/globalmaster/auditprogram/auditproceduremaster/view/' + notif.source_id);
            break;
        case 'IA_OBJECTIVE_PROCEDURE_DEF_MAPPING':
            history.push('/globalmaster/auditprogram/objProcedureDefMappings/view/' + notif.source_id);
            break;
        case 'IA_AUDITABLE_AREA_HIERARCHY':
            history.push('/globalmaster/auditareaandhierarchy/view/' + notif.source_id);
            break;
        case 'IA_GUIDELINE_AUDIT_OPINION':
            history.push('/globalmaster/guidelineauditopinion/view/' + notif.source_id);
            break;
        case 'IA_FOLLOWUP_AUDIT_CONF':
            history.push('/globalmaster/followupauditconfig');
            break;
        case 'IA_KEY_STAKE_HOLDER':
            history.push('/audituniversemang/stakeholders/view/' + notif.source_id);
            break;
        case 'IA_MDA_PROFILE':
            history.push('/audituniversemang/mdaprofile/view/' + notif.source_id);
            break;
        case 'IA_IAU_DIVISION':
            history.push('/audituniversemang/auditunit/view/' + notif.source_id);
            break;
        case 'IA_RESOURCE_PLAN_CONFIG':
            history.push('/resourcemang/resourceplanconfig');
            break;
        case 'IA_LOCATION_ALLOWANCE_MAPPING':
            history.push('/resourcemang/gradeallowanceconfig/view/' + notif.source_id);
            break;
        case 'IA_RATE_CARD':
            history.push('/resourcemang/ratecard/view/' + notif.source_id);
            break;
        //* *** Strategic Audit Plan *** *//
        case 'IA_RISK_MATURITY_ASSESSMENT':
            history.push('/strategicauditplan/riskmaturityassessment/view/' + notif.source_id);
            break;
        case 'IA_AUDIT_PLAN_SCHEDULE':
            history.push('/strategicauditplan/audiplanschedule/view/' + notif.source_id);
            break;
        case 'IA_RISK_ASSESSMENT':
            history.push('/strategicauditplan/riskassessment/view/' + notif.source_id);
            break;
        case 'IA_STRATEGIC_AUDIT_PLAN':
            history.push('/strategicauditplan/strategicadtplan/view/' + notif.source_id);
            break;
        //* *** Annual Audit Plan *** *//
        case 'IA_ANNUAL_AUDIT_PLAN':
            localStorage.removeItem('AAP_Approved');
            localStorage.removeItem('AAP_TAB_VAL');
            history.push('/annualauditplan/annualauditplan/view/' + notif.source_id);
            break;
        // * *** Audit Assignment Planning and Execution *** *//
        case 'IA_SCHEDULE_AAP_SUBASSIGNMENT':
            history.push('/aape/aapexecution/scheduleaudits/view/' + notif.source_id);
            break;
        case 'IA_AAP_ADMIN_ACTIVITY_EXEC':
            history.push('/aape/aapexecution/administrativeactivities/view/' + notif.source_id);
            break;
        case 'IA_FOLLOW_UP_AAP_SUBASSIGNMENT':
            history.push('/aape/aapexecution/followup/view/' + notif.source_id);
            break;
        case 'IA_AUDIT_REGISTER':
            history.push('/aape/aapexecution/auditregister/view/' + notif.source_id);
            break;
        // * *** Conflict of Interest Declaration *** *//
        case 'IA_COI_DECLARATION':
            history.push('/aape/aapexecution/conflictofinterest/view/' + notif.source_id);
            break;
        // * *** Initiating Audit Assignment *** *//
        case 'IA_AUDIT_COMMENCEMENT_FORM':
            history.push('/aape/aapexecution/auditcommencementform/view/' + notif.source_id);
            break;
        case 'IA_REQUEST_LIST_RESPONSE':
            history.push('/aape/aapexecution/requestlistresponse/view/' + notif.source_id);
            break;
        // * *** Understanding Auditable Entity *** *//
        case 'IA_SUMMARY_AUDITABLE_AREA':
            history.push('/aape/aapexecution/summaryauditablearea/view/' + notif.source_id);
            break;
        case 'IA_SUMMARY_AUDIT_SUBJECT':
            history.push('/aape/aapexecution/summaryauditsubject/view/' + notif.source_id);
            break;
        // * *** Risk Assessment and Controls *** *//
        case 'IA_AUDIT_SCOPE':
            history.push('/aape/aapexecution/auditscope/view/' + notif.source_id);
            break;
        case 'IA_INHERENT_RISK':
            history.push('/aape/aapexecution/inherentrisk/view/' + notif.source_id);
            break;
        case 'IA_CONTROL_ASSESSMENT':
            history.push('/aape/aapexecution/controlassessment/view/' + notif.source_id);
            break;
        case 'IA_RESIDUAL_RISK_ASSESSMENT':
            history.push('/aape/aapexecution/residualriskassessment/view/' + notif.source_id);
            break;
        case 'IA_RISK_ASSESSMENT_CONCLUSION':
            history.push('/aape/aapexecution/riskassessmentconclusion/view/' + notif.source_id);
            break;
        // * *** Audit Assignment Plan *** *//
        case 'IA_AUDIT_ASSIGNMENT_PLAN':
            history.push('/aape/aapexecution/auditassignmentplan/view/' + notif.source_id);
            break;
        // * *** Audit Program*** *//
        case 'IA_AUDIT_PROGRAM':
            history.push('/aape/aapexecution/aapauditprogram/auditprogram/view/' + notif.source_id);
            break;
        case 'IA_AUDIT_TEST_WORKSHEET':
            history.push('/aape/aapexecution/aapauditprogram/auditworksheet/view/' + notif.source_id);
            break;
        // * *** Audit Test Results *** *//
        case 'IA_AUDIT_TEST_RESULT':
            history.push('/aape/aapexecution/audittestresults/view/' + notif.source_id);
            break;
        // * *** Audit Finding *** *//
        case 'IA_AUDIT_FINDING':
            history.push(
                (auditType === window.AUDIT_TYPE.FOLLOW_UP_AUDIT
                    ? '/aape/aapexecution/auditfindings/followup/view/'
                    : '/aape/aapexecution/auditfindings/view/') + notif.source_id
            );
            break;
        // * *** Audit Meeting *** *//
        case 'IA_AUDIT_MEETING':
            history.push('/aape/aapexecution/auditmeetings/view/' + notif.source_id);
            break;
        // * *** Report Actual *** *//
        case 'IA_REPORT_ACTUAL':
            history.push('/aape/aapexecution/reportactuals/view/' + notif.source_id);
            break;
        // * *** Audit Closure Form *** *//
        case 'IA_AUDIT_CLOSURE_FORM':
            history.push('/aape/aapexecution/auditclosureform/view/' + notif.source_id);
            break;
        // * *** Reports *** *//
        case 'IA_REPORT':
            history.push('/aape/aapexecution/reports/view/' + notif.source_id);
            break;
        default:
    }
};

export const setDashboardConfigList = async (data) => {
    let tempArr = [];
    let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
    if (userPrivileges) {
        for (const [key1] of Object.entries(userPrivileges)) {
            await prepareModules(data, tempArr, key1);
            await prepareModules(priv, tempArr, key1);
        }
    }
    tempArr.sort((a, b) => a.order - b.order);
    const namesArr = tempArr.filter(function (elem, pos) {
        return tempArr.indexOf(elem) === pos;
    });
    return namesArr;
};
const prepareModules = (data, tempArr, key1) => {
    for (const [key, value] of Object.entries(data)) {
        const modulesArr = priv[key];
        if (modulesArr !== undefined && modulesArr.length > 0) {
            modulesArr.forEach((ele) => {
                if (key1 === ele + '_C' || key1 === ele + '_R' || key1 === ele + '_D' || key1 === ele + '_U') {
                    if (Object.keys(dashboardConfig).includes(key)) {
                        dashboardConfig[key].noOfPendings =
                            value && value.total_count ? value.total_count : dashboardConfig[key].noOfPendings;
                        tempArr.push(dashboardConfig[key]);
                        return;
                    }
                }
            });
        }
    }
    return tempArr;
};

export const setSubDashboardConfigList = (data) => {
    let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
    userPrivileges['GL_MASTER_C'] = ['ALL'];
    userPrivileges['JOURNAL_LEDGER_C'] = ['ALL'];
    let showSub = false;
    for (const [key1] of Object.entries(userPrivileges)) {
        const sub = subPriv[data.type];
        if (sub && sub.length > 0) {
            sub.forEach((ele) => {
                if (key1 === ele + '_C' || key1 === ele + '_R' || key1 === ele + '_D' || key1 === ele + '_U') {
                    showSub = true;
                }
            });
        }
        if (showSub) {
            break;
        }
    }
    return showSub;
};

export const priv = {
    //* ***  Contract Management *** *//
    CONTRACT_MANAGEMENT: [
        'SYS_SUPPLY_CTGRY_MASTER',
        'SYS_SEC_DEP_DEDUCTION_MASTER',
        'SYS_SANCTION_AGENCY_MASTER',
        'SYS_IMPLEMENTING_AGENCY_MASTER',
        'SYS_PROJECT_MASTER',
        'SYS_ADVANCE_MASTER',
        'SYS_ADMISSIBLE_VARIANCE_MASTER',
        'CNTR_SUPPLIER_CTGRY_MASTER',
        'CNTR_SUPPLIER_REGISTRATION',
        'CNTR_CONTRACT_CREATION',
        'CNTR_CUSTOM_REPORT',
        'CNTR_MCA_REPORT',
        'CNTR_STD_REPORT'
    ],
    //* ***  Expenditure Management *** *//
    EXPENDITURE_MANAGEMENT: [
        'EXP_CONTRACT_BILL_CREATION',
        'EXP_OTHER_BILL_CREATION',
        'EXP_IMPREST_RELEASE_CREATION',
        'EXP_IMPREST_ADJ_CREATION',
        'EXP_CONTRACT_BILL_MASTER',
        'EXP_OTHER_BILL_MASTER',
        'EXP_IMPREST_CONFIG_MASTER',
        'EMP_PAYROLL_VOUCHER',
        'EXP_PERSONNEL_BILL',
        'EXP_PERSONNEL_BILL_MASTER'
    ],
    //* *** System Administration *** *//
    SYSTEM_ADMINISTRATION: [
        'COA_SEGMENT_MASTER',
        'COA_STRUCTURE_MASTER',
        'COA_CODE_MASTER',
        'COA_COMPOSITION_MASTER',
        'COA_MAPPING_MASTER',
        'COA_REPORT_MASTER',
        'SYS_FIN_YEAR_MASTER',
        'SYS_ORG_LEVEL_DISPLAY',
        'SYS_USER_MASTER',
        'SYS_ROLE_MASTER',
        'SYS_CLIENT_CONFIG',
        'SYS_EXCH_CCY_RATE_MASTER',
        'SYS_IFSC_CODE_MASTER',
        'SYS_DEDUCTION_MASTER',
        'SYS_COMMENT_MASTER',
        'SYS_DEPT_MASTER',
        'SYS_BUDGET_OFFICE',
        'SYS_DEPUTATION_OFFICE',
        'SYS_BENEFICIARY_DETAILS',
        'SYS_LOCALITY_MASTER',
        'SYS_SEC_ACC_LOCK',
        'SYS_SEC_SESSION_EXPIRY',
        'SYS_SEC_PWD_EXPIRY',
        'COA_ECONOMIC_CODES_MASTER',
        'COA_SEGMENT_CONF_MASTER'
    ],
    //* *** Payment Management *** *//
    PAYMENT_MANAGEMENT: [
        'PAYMENT_INSTRUCTION',
        'PAYMENT_CHEQUE_DELIVERY_STATUS',
        'PAYMENT_INSTRUCTION_STATUS',
        'SYS_BANK_ACC_MASTER',
        'SYS_CASH_ACC_MASTER',
        'PAYMENT_CHQ_BOOK_MASTER',
        'PAYMENT_CHQ_VOID',
        'PAYMENT_CHQ_REVERSE',
        'PAYMENT_ASSIGN_CHEQUE',
        'PAYMENT_INSTRUCTION_DOWNLOAD_D'
    ],
    //* *** Revenue Management *** *//
    REVENUE_MANAGEMENT: [
        'GENERATE_CHALLAN',
        'RVN_DAILY_CASH_REPORT',
        'RVN_DAILY_COLLECTION_REPORT',
        'RVN_DEPOSIT_VOUCHER',
        'RVN_BANK_REF_UPDATE',
        'RVN_STD_REPORT',
        'RVN_SEG_DEF_CONFIG',
        'RVN_REMITTANCE_PURPOSE_CONFIG',
        'RVN_REMITTANCE_EMP_CONFIG',
        'RVN_REMITTANCE_PENSION_CONFIG',
        'RVN_REMITTANCE_CONTRACT_CONFIG',
        'RVN_REMITTANCE_IMPREST_CONFIG',
        'RVN_REMITTANCE_PURPOSE',
        'RVN_PAYMENT_CONFIG',
        'RVN_REPORT_CONFIG',
        'RVN_PAN_AMOUNT_CONFIG',
        'RVN_MIS_REPORT_CONFIG',
        'RVN_NON_FMIS_CHALLAN'
    ],
    //* *** General Ledger *** *//
    GENERALLEDGER: ['GL_SETUP_MASTER', 'GL_JOURNAL_SOURCE_MASTER', 'GL_MANUAL_JOURNAL'],
    //* *** Employee Management *** *//
    EMPLOYEE_MANAGEMENT: [
        'EMP_PAY_COMMISSION_MASTER',
        'EMP_CLASSIFICATION_MASTER',
        'EMP_PAY_ENTITLEMENT_MASTER',
        'EMP_PAY_COMPONENT_MASTER',
        'EMP_GROUP_MASTER',
        'EMP_PAY_COMPONENT_DEF_MASTER',
        'EMP_CREATION'
    ],
    INTERNAL_AUDIT_MASTERS: [
        'IA_RISK_DEFINITION',
        'IA_CONTROLS_DEFINITION',
        'IA_TXN_RISK_CNTRL',
        'IA_TXN_RISK_RANK',
        'IA_AUDIT_PLAN_CONFIG',
        'IA_AUDIT_PROGRAM_CONFIG',
        'IA_AUDIT_PROGRAM_STRUCTURE',
        'IA_AUDIT_OBJECTIVE_DEF',
        'IA_AUDIT_PROCEDURE_DEF',
        'IA_AUDITABLE_AREA_HIERARCHY',
        'IA_FILE_NUMBER',
        'IA_GUIDELINE_AUDIT_OPINION',
        'IA_FOLLOWUP_AUDIT_CONF',
        'IA_KEY_STAKE_HOLDER',
        'IA_MDA_PROFILE',
        'IA_HR_PLAN'
    ],
    STRATEGIC_AND_ANNUAL_AUDIT_PLANNING: [
        'IA_RISK_MATURITY_ASSESSMENT',
        'IA_AUDIT_PLAN_SCHEDULE',
        'IA_AUDIT_PLAN_STATUS',
        'IA_RISK_ASSESSMENT',
        'IA_RANKED_AUDIT_UNIVERSE',
        'IA_STRATEGIC_AUDIT_PLAN',
        'IA_ANNUAL_AUDIT_PLAN'
    ],
    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION: [
        'IA_AUDIT_ASSIGNMENT_STATUS',
        'IA_COI_DECLARATION',
        'IA_AUDIT_COMMENCEMENT_FORM',
        'IA_REQUEST_LIST_RESPONSE',
        'IA_SUMMARY_AUDITABLE_AREA',
        'IA_SUMMARY_AUDIT_SUBJECT',
        'IA_AUDIT_SCOPE',
        'IA_INHERENT_RISK',
        'IA_CONTROL_ASSESSMENT',
        'IA_RESIDUAL_RISK_ASSESSMENT',
        'IA_RISK_ASSESSMENT_CONCLUSION',
        'IA_AUDIT_ASSIGNMENT_PLAN',
        'IA_AUDIT_PROGRAM',
        'IA_AUDIT_TEST_RESULT',
        'IA_AUDIT_FINDING',
        'IA_ENGAGEMENT_FORM',
        'IA_AUDIT_MEETING',
        'IA_REPORT_ACTUAL',
        'IA_AUDIT_CLOSURE_FORM',
        'IA_AUDIT_OBSERVATIONS',
        'IA_AUDIT_HISTORY',
        'IA_REPORT'
    ],
    REPORTS_AND_DASHBOARDS: []
};

export const subPriv = {
    //* ***  Contract Management *** *//
    MASTERS: [
        'SYS_SUPPLY_CTGRY_MASTER',
        'SYS_SEC_DEP_DEDUCTION_MASTER',
        'SYS_SANCTION_AGENCY_MASTER',
        'SYS_IMPLEMENTING_AGENCY_MASTER',
        'SYS_PROJECT_MASTER',
        'SYS_ADVANCE_MASTER',
        'SYS_ADMISSIBLE_VARIANCE_MASTER'
    ],
    SUPPLIER_MANAGEMENT: ['CNTR_SUPPLIER_CTGRY_MASTER', 'CNTR_SUPPLIER_REGISTRATION'],
    CONTRACT_MANAGEMENT: ['CNTR_CONTRACT_CREATION', 'CNTR_CUSTOM_REPORT', 'CNTR_MCA_REPORT', 'CNTR_STD_REPORT'],
    //* ***  Expenditure Management *** *//
    BILLS_MANAGEMENT: [
        'EXP_CONTRACT_BILL_CREATION',
        'EXP_OTHER_BILL_CREATION',
        'EXP_IMPREST_RELEASE_CREATION',
        'EXP_IMPREST_ADJ_CREATION',
        'EMP_PAYROLL_VOUCHER',
        'EXP_PERSONNEL_BILL'
    ],
    //* *** System Administration *** *//
    CHART_OF_ACCOUNTS: [
        'COA_SEGMENT_MASTER',
        'COA_ECONOMIC_CODES_MASTER',
        'COA_STRUCTURE_MASTER',
        'COA_CODE_MASTER',
        'COA_COMPOSITION_MASTER',
        'COA_MAPPING_MASTER',
        'COA_REPORT_MASTER',
        'SYS_FIN_YEAR_MASTER'
    ],
    USER_MANAGEMENT: ['SYS_ORG_LEVEL_DISPLAY', 'SYS_USER_MASTER', 'SYS_ROLE_MASTER', 'SYS_ACTIVITY_LOG'],
    COMMON_MASTERS: [
        'SYS_EXCH_CCY_RATE_MASTER',
        'SYS_IFSC_CODE_MASTER',
        'SYS_DEDUCTION_MASTER',
        'SYS_COMMENT_MASTER',
        'SYS_DEPT_MASTER',
        'SYS_BUDGET_OFFICE',
        'SYS_DEPUTATION_OFFICE',
        'SYS_BENEFICIARY_DETAILS',
        'SYS_LOCALITY_MASTER',
        'SYS_SEC_ACC_LOCK',
        'SYS_SEC_SESSION_EXPIRY',
        'SYS_SEC_PWD_EXPIRY'
    ],
    WORKFLOW_CONFIGURATION: ['WORKFLOW_CONFIG'],
    GLOBAL_CONFIG: ['SYS_CLIENT_CONFIG'],

    //* *** Payment Management *** *//
    GENERATE_PAYMENT_INSTRUCTIONS: [
        'PAYMENT_INSTRUCTION',
        'PAYMENT_CHEQUE_DELIVERY_STATUS',
        'PAYMENT_ASSIGN_CHEQUE',
        'PAYMENT_INSTRUCTION_STATUS',
        'PAYMENT_INSTRUCTION_DOWNLOAD_D',
        'RECALL_PAYMENT'
    ],
    CHEQUE_MANAGEMENT: ['SYS_BANK_ACC_MASTER', 'SYS_CASH_ACC_MASTER', 'PAYMENT_CHQ_BOOK_MASTER', 'PAYMENT_CHQ_VOID', 'PAYMENT_CHQ_REVERSE'],
    //* *** Revenue Management *** *//
    GENERATE_CHALLANS: ['GENERATE_CHALLAN'],
    COLLECTION_AND_CASH_BOOKS: ['RVN_DAILY_CASH_REPORT', 'RVN_DAILY_COLLECTION_REPORT'],
    DEPOSIT_VOUCHER: ['RVN_DEPOSIT_VOUCHER', 'RVN_BANK_REF_UPDATE'],
    REPORTS: ['RVN_STD_REPORT'],
    NON_FMIS: ['RVN_NON_FMIS_CHALLAN'],
    MASTER_CONFIGURATION: [
        'RVN_SEG_DEF_CONFIG',
        'RVN_REMITTANCE_PURPOSE_CONFIG',
        'RVN_REMITTANCE_EMP_CONFIG',
        'RVN_REMITTANCE_PENSION_CONFIG',
        'RVN_REMITTANCE_CONTRACT_CONFIG',
        'RVN_REMITTANCE_IMPREST_CONFIG',
        'RVN_REMITTANCE_PURPOSE',
        'RVN_PAYMENT_CONFIG',
        'RVN_REPORT_CONFIG',
        'RVN_PAN_AMOUNT_CONFIG',
        'EXP_CONTRACT_BILL_MASTER',
        'EXP_OTHER_BILL_MASTER',
        'EXP_IMPREST_CONFIG_MASTER',
        'EXP_PERSONNEL_BILL_MASTER',
        'RVN_MIS_REPORT_CONFIG'
    ],
    //* *** General Ledger *** *//
    GL_MASTER: ['GL_MASTER'],
    JOURNAL_LEDGER: ['JOURNAL_LEDGER'],
    //* *** Employee Management *** *//
    EMPLOYEE_MASTERS: [
        'EMP_PAY_COMMISSION_MASTER',
        'EMP_CLASSIFICATION_MASTER',
        'EMP_PAY_ENTITLEMENT_MASTER',
        'EMP_PAY_COMPONENT_MASTER',
        'EMP_GROUP_MASTER',
        'EMP_PAY_COMPONENT_DEF_MASTER',
        'EMP_CLASSIF_CODE',
        'EMP_CLASSIF_LEVEL_MAPPING',
        'EMP_CLASSIF_STRUCTURE'
    ],
    EMPLOYEE_MANAGEMENT: ['EMP_CREATION', 'EMP_PAY_ENTITLEMENT_CHANGE'],
    //* *** Internal Audit *** *//
    GLOBAL_MASTERS: [
        'IA_RISK_DEFINITION',
        'IA_CONTROLS_DEFINITION',
        'IA_TXN_RISK_CNTRL',
        'IA_TXN_RISK_RANK',
        'IA_AUDIT_PLAN_CONFIG',
        'IA_AUDIT_PROGRAM_CONFIG',
        'IA_AUDIT_PROGRAM_STRUCTURE',
        'IA_AUDIT_OBJECTIVE_DEF',
        'IA_AUDIT_PROCEDURE_DEF',
        'IA_AUDITABLE_AREA_HIERARCHY',
        'IA_FILE_NUMBER',
        'IA_GUIDELINE_AUDIT_OPINION',
        'IA_FOLLOWUP_AUDIT_CONF'
    ],
    AUDIT_UNIVERSE_MANAGEMENT: ['IA_KEY_STAKE_HOLDER', 'IA_MDA_PROFILE'],
    RESOURCE_MANAGEMENT: ['IA_RESOURCE_PLAN_CONFIG', 'IA_LOCATION_ALLOWANCE_MAPPING', 'IA_RATE_CARD', 'IA_HR_PLAN'],
    STRATEGIC_AUDIT_PLAN: [
        'IA_RISK_MATURITY_ASSESSMENT',
        'IA_AUDIT_PLAN_SCHEDULE',
        'IA_AUDIT_PLAN_STATUS',
        'IA_RISK_ASSESSMENT',
        'IA_RANKED_AUDIT_UNIVERSE',
        'IA_STRATEGIC_AUDIT_PLAN'
    ],
    ANNUAL_AUDIT_PLAN: ['IA_ANNUAL_AUDIT_PLAN'],
    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION: [
        'IA_AUDIT_ASSIGNMENT_STATUS',
        'IA_COI_DECLARATION',
        'IA_AUDIT_COMMENCEMENT_FORM',
        'IA_REQUEST_LIST_RESPONSE',
        'IA_SUMMARY_AUDITABLE_AREA',
        'IA_SUMMARY_AUDIT_SUBJECT',
        'IA_AUDIT_SCOPE',
        'IA_INHERENT_RISK',
        'IA_CONTROL_ASSESSMENT',
        'IA_RESIDUAL_RISK_ASSESSMENT',
        'IA_RISK_ASSESSMENT_CONCLUSION',
        'IA_AUDIT_ASSIGNMENT_PLAN',
        'IA_AUDIT_PROGRAM',
        'IA_AUDIT_TEST_RESULT',
        'IA_AUDIT_FINDING',
        'IA_ENGAGEMENT_FORM',
        'IA_AUDIT_MEETING',
        'IA_AUDIT_OBSERVATIONS',
        'IA_AUDIT_HISTORY',
        'IA_REPORT_ACTUAL',
        'IA_AUDIT_CLOSURE_FORM',
        'IA_REPORT'
    ]
};

export const roundFloat = (val, limit) => {
    let roundedValue = '-';
    if (val && val !== 'NaN' && val !== '-' && val !== undefined) {
        roundedValue = parseFloat(val).toFixed(limit);
    }
    return roundedValue;
};

export const GetFinYear = (isObjRequired) => {
    let finYear = useQuery(Schema.current_fin_year, {});
    if (isObjRequired) {
        return finYear?.data?.current_fin_year;
    } else {
        return finYear?.data?.current_fin_year?.name;
    }
};
export const GetFinYearOnRef = (ref) => {
    let finYear = useQuery(Schema.getFinYearOnRef, {
        variables: { source_ref: ref },
        client: eventClient
    });
    return finYear?.data?.fin_year_based_on_txn?.year_id;
};

export const fincialYear = (finYear) => {
    let currentDate = serverDate();
    let year = currentDate.getFullYear();
    let e = finYear?.toUpperCase();
    if (e || `${e} -` || `${e} - ` || `${e} - ${year}` || `${e} - ${year} `) {
        return `${e?.substring(0, 3)}_${year}`;
    }
};
export const getFmisFunctions = () => {
    return [
        {
            seq: 1,
            code: 'IA_COI_DECLARATION',
            value: 'IA_COI_DECLARATION_C',
            name: 'Conflict of Interest Declaration',
            route: 'conflictofinterest',
            aliasName: 'Conflict Of Interest'
        },
        {
            seq: 2,
            code: 'IA_AUDIT_COMMENCEMENT_FORM',
            value: 'IA_AUDIT_COMMENCEMENT_FORM_C',
            name: 'Audit Resources and Schedule',
            route: 'auditcommencementform',
            aliasName: 'Audit Resources and Schedule'
        },
        {
            seq: 3,
            code: 'IA_REQUEST_LIST_RESPONSE',
            value: 'IA_REQUEST_LIST_RESPONSE_C',
            name: 'Request List Response',
            route: 'requestlistresponse',
            aliasName: 'Request List Response'
        },
        {
            seq: 4,
            code: 'IA_SUMMARY_AUDITABLE_AREA',
            value: 'IA_SUMMARY_AUDITABLE_AREA_C',
            name: 'Summary Auditable Area',
            route: 'summaryauditablearea',
            aliasName: 'Summary Auditable Area'
        },
        {
            seq: 5,
            code: 'IA_SUMMARY_AUDIT_SUBJECT',
            value: 'IA_SUMMARY_AUDIT_SUBJECT_C',
            name: 'Summary Audit Subject',
            route: 'summaryauditsubject',
            aliasName: 'Summary Audit Subject'
        },
        { seq: 6, code: 'IA_AUDIT_SCOPE', value: 'IA_AUDIT_SCOPE_C', name: 'Audit Scope', aliasName: 'Audit Scope', route: 'auditscope' },
        {
            seq: 7,
            code: 'IA_INHERENT_RISK',
            value: 'IA_INHERENT_RISK_C',
            name: 'Inherent Risk',
            aliasName: 'Inherent Risk',
            route: 'inherentrisk'
        },
        {
            seq: 8,
            code: 'IA_CONTROL_ASSESSMENT',
            value: 'IA_CONTROL_ASSESSMENT_C',
            name: 'Control Assessment',
            aliasName: 'Control Assessment',
            route: 'controlassessment'
        },
        {
            seq: 9,
            code: 'IA_RESIDUAL_RISK_ASSESSMENT',
            value: 'IA_RESIDUAL_RISK_ASSESSMENT_C',
            name: 'Residual Risk Assessment',
            aliasName: 'Residual Risk Assessment',
            route: 'residualriskassessment'
        },
        {
            seq: 10,
            code: 'IA_RISK_ASSESSMENT_CONCLUSION',
            value: 'IA_RISK_ASSESSMENT_CONCLUSION_C',
            name: 'Risk Assessment Conclusion',
            aliasName: 'Risk Assessment Conclusion',
            route: 'riskassessmentconclusion'
        },
        {
            seq: 11,
            code: 'IA_AUDIT_ASSIGNMENT_PLAN',
            value: 'IA_AUDIT_ASSIGNMENT_PLAN_C',
            name: 'Audit Assignment Plan',
            aliasName: 'Audit Assignment Plan',
            route: 'auditassignmentplan'
        },
        {
            seq: 12,
            code: 'IA_AUDIT_PROGRAM',
            value: 'IA_AUDIT_PROGRAM_C',
            name: 'Audit Program',
            aliasName: 'Audit Program',
            route: 'aapauditprogram'
        },
        {
            seq: 13,
            code: 'IA_AUDIT_TEST_RESULT',
            value: 'IA_AUDIT_TEST_RESULT_C',
            name: 'Audit Test Results',
            aliasName: 'Audit Test Result',
            route: 'audittestresults'
        },
        {
            seq: 14,
            code: 'IA_AUDIT_FINDING',
            value: 'IA_AUDIT_FINDING_C',
            name: 'Audit Findings',
            aliasName: 'Audit Finding',
            route: 'auditfindings'
        },
        {
            seq: 15,
            code: 'IA_ENGAGEMENT_FORM',
            value: 'IA_ENGAGEMENT_FORM_C',
            name: 'Engagement Reports',
            aliasName: 'Engagement Form',
            route: 'engagementReports'
        },
        {
            seq: 16,
            code: 'IA_AUDIT_MEETING',
            value: 'IA_AUDIT_MEETING_C',
            name: 'Audit Meetings',
            aliasName: 'Audit Meetings',
            route: 'auditmeetings'
        },
        {
            seq: 17,
            code: 'IA_REPORT_ACTUAL',
            value: 'IA_REPORT_ACTUAL_C',
            name: 'Report Actuals',
            aliasName: 'Report Actuals',
            route: 'reportactuals'
        },
        {
            seq: 18,
            code: 'IA_AUDIT_CLOSURE_FORM',
            value: 'IA_AUDIT_CLOSURE_FORM_C',
            name: 'Closure Form',
            aliasName: 'Closure Form',
            route: 'auditclosureform'
        },
        {
            seq: 19,
            code: 'IA_REPORT',
            value: 'IA_REPORT_C',
            name: 'Reports',
            aliasName: 'Reports',
            route: 'report'
        }
    ];
};

export const auditorStatus = () => {
    return [
        {
            auditor_status: 'Implemented but Verified as Unsatisfactory',
            notation: 'IVU',
            interpretation:
                'Management has completed implementation of agreed action but internal audit has verified the completion as unsatisfactory'
        },
        {
            auditor_status: 'Implemented and Verified as Satisfactory',
            notation: 'IVS',
            interpretation:
                'Management has completed implementation of agreed action and internal audit has verified the completion as satisfactory'
        },
        {
            auditor_status: 'Implemented Not Verified',
            notation: 'INV',
            interpretation: 'Management has completed implementation of agreed action but internal audit has not verified its completion'
        },
        {
            auditor_status: 'Cancelled',
            notation: 'C',
            interpretation:
                'Management action and recommendation cancelled on mutual agreement with the internal auditor because it is no longer irrelevant'
        },
        { auditor_status: 'Pending', notation: 'P', interpretation: 'Pending' },
        {
            auditor_status: 'Management Assumed Risk',
            notation: 'MAR',
            interpretation: 'Management has rejected implementation and has accepted responsibiliy for risk'
        }
    ];
};

export const managementStatus = () => {
    return [
        {
            management_status: 'Done',
            notation: 'D',
            interpretation: 'Completed'
        },
        {
            management_status: 'Not done',
            notation: 'ND',
            interpretation: 'Management has not implemented agreed action or any action after the end date of the agreed timeline'
        },
        {
            management_status: 'In Progress',
            notation: 'IP',
            interpretation: 'Management has started but not completed implementing agreed action'
        },
        {
            management_status: 'Cancelled',
            notation: 'C',
            interpretation:
                'Management action and recommendation cancelled on mutual agreement with the internal auditor because it is no longer irrelevant'
        },
        {
            management_status: 'Rejected',
            notation: 'R',
            interpretation: 'Management has rejected implementation and has accepted responsibiliy for risk'
        }
    ];
};

export const findingStatus = (FollowUpStatus, ManagementStatus, AuditorStatus) => {
    let status = '';
    switch (FollowUpStatus?.toUpperCase()) {
        case 'REMOTE VERIFICATION':
        case 'REMOTE_VERIFICATION':
        case 'RV':
        case 'TARGETED':
        case 'TA':
        case 'SCHEDULED':
        case 'SA':
            if (ManagementStatus === 'Done' && AuditorStatus === 'IVS') {
                status = 'Closed';
            } else if (ManagementStatus === 'Done' && AuditorStatus === 'IVU') {
                status = 'Notify';
            } else if (ManagementStatus === 'Done' && AuditorStatus === 'INV') {
                status = 'Open';
            } else if ((ManagementStatus === 'In Progress' || ManagementStatus === 'Not Done') && AuditorStatus === '') {
                status = 'Open';
            } else if (ManagementStatus === 'Cancelled' && AuditorStatus === 'Pending') {
                status = 'Notify';
            } else if (ManagementStatus === 'Cancelled' && AuditorStatus === 'Cancelled') {
                status = 'Closed';
            } else if (ManagementStatus === 'Rejected' && AuditorStatus === 'Pending') {
                status = 'Notify';
            } else if (ManagementStatus === 'Rejected' && AuditorStatus === 'MAR') {
                status = 'Closed';
            }
            break;
        default:
            break;
    }
    return status;
};

export const auditTypeName = (audittype) => {
    let auditTypeFullName = '';
    switch (audittype) {
        case window.AUDIT_TYPE.SCHEDULE_AUDIT:
            auditTypeFullName = 'Scheduled Audits';
            break;
        case window.AUDIT_TYPE.ADHOC_AUDIT:
            auditTypeFullName = 'Ad-Hoc Audits';
            break;
        case window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT:
            auditTypeFullName = 'Consulting Assignments';
            break;
        case window.AUDIT_TYPE.FOLLOW_UP_AUDIT:
            auditTypeFullName = 'Follow-Up Audits';
            break;
        default:
            break;
    }
    return auditTypeFullName;
};

export const auditTypeForAAPE = (audittype) => {
    let auditTypeFullName = '';
    switch (audittype) {
        case window.AUDIT_TYPE.SCHEDULE_AUDIT:
            auditTypeFullName = 'Scheduled';
            break;
        case window.AUDIT_TYPE.ADHOC_AUDIT:
            auditTypeFullName = 'Ad-Hoc';
            break;
        case window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT:
            auditTypeFullName = 'Consulting Assignment';
            break;
        case window.AUDIT_TYPE.FOLLOW_UP_AUDIT:
            auditTypeFullName = 'Follow-Up';
            break;
        default:
            break;
    }
    return auditTypeFullName;
};

export default {
    takeActionNavigationForFmis,
    takeActionNavigationForAms,
    setDashboardConfigList,
    setSubDashboardConfigList,
    roundFloat,
    GetFinYear,
    fincialYear,
    getFmisFunctions,
    auditorStatus,
    findingStatus,
    managementStatus,
    auditTypeName
};
