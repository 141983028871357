import React, { useState, useRef } from 'react';
import Schema from '../../schema/AppSchema';
import { OverlayPanel } from 'primereact/overlaypanel';
import { prepareURL } from '../../commons/Downloader';
import { Link, useHistory } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonModal from '../../commons/CommonModal';
import { Select, SelectOption, List, ListItemText, Tooltip, Input, Button, Avatar } from 'appkit-react';
import UpdateProfileDialog from './FDialog';
import UpdatePasswordDialog from './UpdatePasswordDialog';
import * as FormValidation from '../../Validation';
import { Toast } from 'primereact/toast';
import './Header.scss';
import { getDate } from '../../util';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { eventClient, wsClient, uploadClient, adminClient, iaClient } from '../../apollo';
import { takeActionNavigationForFmis, takeActionNavigationForAms, auditTypeName, getFmisFunctions } from '../../commons/commons';
import AAPEQueries from '../../pages/internalAudit/auditAssignmentPlanningAndExecution/AAPEQueries';

const UPDATE_PROFILE = Schema.updateOneUser;
const UPLOAD_PROFILE_IMAGE = Schema.uploadProfileImage;
const DELETE_PROFILE_IMAGE = Schema.deleteProfileImage;
const UPDATE_PASSWORD = Schema.updatePassword;
const FModalHeader = () => {
    return <div>Profile View</div>;
};
const FModalHeaderUpdatePw = () => {
    return <div>Change Password</div>;
};
const Header = () => {
    let history = useHistory();
    let profileForm = {};
    let op = useRef(null);
    let generalNotifRef = useRef(null);
    let userInfo = JSON.parse(localStorage.getItem('USER_INF'));
    const [state, setState] = useState({
        taskNotifications: [],
        loading: false,
        showConfirmation: false,
        showNotifCount: false,
        confirmationTitle: '',
        confirmationText: '',
        deleteProfile: false
    });
    const [generalNotifications, setGeneralNotification] = useState([]);
    const loginDate = userInfo?.last_loggedin_at ? new Date(userInfo?.last_loggedin_at).toString().slice(0, 24) : '_';
    const [showProfile, setShowProfile] = useState(false);
    const [showUpdatePw, setShowUpdatePw] = useState(false);
    const [localImgUrl, setlocalImgUrl] = useState(prepareURL(userInfo?.img_url));
    const [taskNotifCount, setTaskNotifCount] = useState(0);
    const [selectdNotif, setSelectdNotif] = useState({});
    const [growl, setGrowl] = useState();
    const [profileState, setProfileState] = useState({
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name
    });
    const [statePassword, setStatePassword] = useState({
        password: '',
        new_password: '',
        confirm_new_password: '',
        password_Icon: true,
        new_password_Icon: true,
        confirm_new_password_Icon: true,
        updateDisable: true
    });
    const fmisFunctions = getFmisFunctions();
    const [errors, setErrors] = useState(false);
    const [generalNotifCount, setGeneralNotifCount] = useState();

    const handleClick = () => {
        setState({ ...state, showNotifCount: true });
    };

    const [updateProfile, { data, loading, called }] = useMutation(UPDATE_PROFILE, {
        onCompleted: (response) => {
            userInfo = response.updateOneUser;
            setProfileState({
                first_name: userInfo?.first_name,
                last_name: userInfo?.last_name,
                img_url: userInfo?.img_url
            });
            if (response) {
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: 'Profile updated successfully'
                });
            }
        },
        onError: () => {}
    });

    const [updatePassword] = useMutation(UPDATE_PASSWORD, {
        errorPolicy: 'all',
        onCompleted: (response) => {
            if (response?.updatePassword?.id) {
                growl.show({ life: 6000, severity: 'success', summary: 'Login password updated successfully' });
                handleCancelUpdatePW();
            }
        }
    });

    const [uploadProfileImage] = useMutation(UPLOAD_PROFILE_IMAGE, {
        client: uploadClient,
        onCompleted: (response) => {
            if (response?.uploadProfileImage?.fileID) {
                userInfo.img_url = response.uploadProfileImage.fileID;
                localStorage.setItem('USER_INF', JSON.stringify(userInfo));
                setlocalImgUrl(prepareURL(response.uploadProfileImage.fileID));
                growl.show({ life: 6000, severity: 'success', summary: 'Profile image updated successfully' });
                setState({ ...state, loading: false });
            } else {
                setState({ ...state, loading: false });
            }
        }
    });

    const [deleteProfileImage] = useMutation(DELETE_PROFILE_IMAGE, {
        client: adminClient,
        onCompleted: (response) => {
            if (response?.resetProfileImage?.status === 'SUCCESS') {
                userInfo.img_url = '';
                localStorage.setItem('USER_INF', JSON.stringify(userInfo));
                setlocalImgUrl(prepareURL(''));
                growl.show({ life: 6000, severity: 'success', summary: 'Profile image deleted successfully' });
                setState({ ...state, loading: false, showConfirmation: false, deleteProfile: false });
            } else {
                growl.show({ life: 6000, severity: 'success', summary: 'Profile image unable to delete' });
                setState({ ...state, loading: false, showConfirmation: false, deleteProfile: false });
            }
        }
    });

    const openProfile = () => {
        setShowProfile(true);
    };
    const openUpdatePassword = () => {
        setShowUpdatePw(true);
    };
    const logoutTooltip = () => (
        <div>
            <List>
                <ListItemText onClick={() => openProfile()}>
                    <span className="appkiticon secondary-color icon-avatar-outline m-r-10" />
                    Profile
                </ListItemText>
                <ListItemText onClick={() => openUpdatePassword()}>
                    <span className="change_pw change_pw_secondry pi pi-key " />
                    Change Password
                </ListItemText>
                <ListItemText onClick={() => logout()}>
                    <span className="appkiticon secondary-color icon-log-out-outline m-r-10" />
                    Logout
                </ListItemText>
            </List>
        </div>
    );
    const handleCancel = () => {
        setShowProfile(false);
        setErrors(false);
        setProfileState({
            ...profileState,
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name
        });
    };
    const handleCancelUpdatePW = () => {
        setShowUpdatePw(false);
        setErrors(false);
        setStatePassword({
            ...statePassword,
            password: '',
            new_password: '',
            confirm_new_password: '',
            new_password_Icon: true,
            confirm_new_password_Icon: true
        });
    };

    const handleChangePassword = (ev, key) => {
        const value = ev;
        let btnUpdateDisabled = true;
        if (statePassword.password !== '' && statePassword.new_password !== '' && statePassword.confirm_new_password !== '') {
            btnUpdateDisabled = false;
        }
        if (key === 'confirm_new_password' && ev === '') {
            btnUpdateDisabled = true;
        }
        if (key === 'new_password' && ev === '') {
            btnUpdateDisabled = true;
        }
        if (key === 'password' && ev === '') {
            btnUpdateDisabled = true;
        }
        if (key === 'confirm_new_password') {
            if (statePassword.new_password !== ev) {
                btnUpdateDisabled = true;
            } else {
                btnUpdateDisabled = false;
            }
        }
        if (key === 'new_password') {
            if (statePassword.confirm_new_password !== ev) {
                btnUpdateDisabled = true;
            } else {
                btnUpdateDisabled = false;
            }
        }
        setStatePassword({
            ...statePassword,
            [key]: value,
            updateDisable: btnUpdateDisabled
        });
        validateFeild(ev, key);
    };

    const validateFeild = async (ev, key) => {
        let err = {};
        if (key === 'new_password') {
            err = FormValidation.validateFileld('password', key, ev);
        }
        if (key === 'confirm_new_password') {
            err = FormValidation.validateFileld('mismatch', key, ev, statePassword.new_password);
        }
        if (key === 'first_name' || key === 'last_name' || key === 'password') {
            err = FormValidation.validateFileld('required', key, ev);
        }
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };

    const getImageName = () => {
        let name = '';
        if (userInfo?.first_name && userInfo?.first_name.length > 0) {
            name = name + userInfo?.first_name[0].toUpperCase();
        }
        if (userInfo?.last_name && userInfo?.last_name.length > 0) {
            name = name + userInfo?.last_name[0].toUpperCase();
        }
        return name;
    };
    const [fileUploader, setFileUploader] = useState('');
    const handleUploadImage = () => {
        setState({
            ...state,
            showConfirmation: true,
            deleteProfile: false,
            confirmationText: 'Are you sure you want to upload profile image ?',
            confirmationTitle: 'Upload Profile Image'
        });
    };

    const handleProfileUpload = () => {
        if (state.deleteProfile) {
            deleteProfileImage({ variables: { user_id: userInfo.id } });
        } else {
            fileUploader.click();
            setState({ ...state, showConfirmation: false });
        }
    };

    const handleImageUpload = (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        let file = ev.target.files[0];
        if (file) {
            uploadProfileImage({ variables: { data: { file: file, userID: userInfo.id } } });
            setState({ ...state, loading: true });
        }
    };
    const handlerPassword = (e) => {
        e.preventDefault();
        setStatePassword({
            ...statePassword,
            password_Icon: !statePassword.password_Icon
        });
    };
    const handlerNewPassword = (e) => {
        e.preventDefault();
        setStatePassword({
            ...statePassword,
            new_password_Icon: !statePassword.new_password_Icon
        });
    };
    const handlerConfirmNewPassword = (e) => {
        e.preventDefault();
        setStatePassword({
            ...statePassword,
            confirm_new_password_Icon: !statePassword.confirm_new_password_Icon
        });
    };

    const logout = async () => {
        clearTimeout(tasksNotificationsCount());
        localStorage.clear();
        await history.push('/login');
        window.location.reload();
    };

    const updateSetNotificationCount = (res) => {
        setTaskNotifCount(res.pending_with_self_task_total_count);
        setNotificationCount();
    };

    const { refetch: tasksNotificationsCount } = useQuery(Schema.pending_with_self_task_total_count, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { where: { assigned_to: { equals: userInfo?.user_name }, is_completed: { equals: 0 } } },
        onCompleted: (res) => {
            if (res) updateSetNotificationCount(res);
        },
        onError: (err) => {
            growl.show({ life: 6000, severity: 'error', summary: err.message });
        }
    });

    const { refetch: generalNotifiCount } = useQuery(Schema.total_notifications, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { where: { type: { notIn: ['Workflow Task'] } } },
        onCompleted: (resp) => {
            if (resp) setGeneralNotifCount(resp);
        },
        onError: (err) => {
            growl.show({ life: 6000, severity: 'error', summary: err.message });
        }
    });

    const GeneralNotifications = () => {
        useSubscription(Schema.generalNotificationsApi, {
            shouldResubscribe: true,
            fetchPolicy: 'no-cache',
            client: wsClient,
            variables: { userId: userInfo.id },
            onSubscriptionData: (resp) => {
                if (resp?.subscriptionData?.data?.notifications?.id) {
                    generalNotifiCount().then((res) => {
                        setGeneralNotifCount(res.data);
                    });
                }
            }
        });
    };

    GeneralNotifications();

    const setNotificationCount = () => {
        setTimeout(function () {
            tasksNotificationsCount().then((res) => {
                updateSetNotificationCount(res.data);
            });
        }, 60000);
    };

    useQuery(Schema.dashboard_pending_tasks, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: {
            where: { assigned_to: { equals: userInfo.user_name }, is_completed: { equals: 0 } },
            orderBy: { created_at: 'desc' },
            take: 5
        },
        onCompleted: (res) => {
            if (res && res.dashboard_pending_tasks) {
                res.dashboard_pending_tasks.forEach((notif) => {
                    let [module, moduleStatus] = notif.curr_phase_type.split(notif.id);
                    notif.module = module;
                    notif.moduleStatus = moduleStatus;
                    return notif;
                });
                setState({ ...state, loading: false, taskNotifications: res.dashboard_pending_tasks });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    useQuery(Schema.notifications, {
        client: eventClient,
        fetchPolicy: 'no-cache',
        variables: { take: 5, where: { type: { notIn: ['Workflow Task'] }, is_read: { equals: 0 } } },
        onCompleted: (res) => {
            if (res && res.notifications) {
                setGeneralNotification(res.notifications);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const removeProfile = () => {
        setState({
            ...state,
            showConfirmation: true,
            deleteProfile: true,
            confirmationText: 'Are you sure you want to delete profile image ?',
            confirmationTitle: 'Delete Profile Image'
        });
    };

    const FModalBody = () => {
        return (
            <div className="row">
                {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
                <form ref={(form) => (profileForm = form)} className="w-100">
                    <div className="container col-xs-12 col-sm-12 col-lg-12 col-sm-12 col-xl-12 pointer text-center display-flex align-center justify-content-center">
                        <Avatar
                            className="image profile-img"
                            size="xlarge"
                            shape="circle"
                            alt=""
                            src={
                                userInfo.img_url && userInfo.img_url !== '' && userInfo.img_url !== null
                                    ? localImgUrl
                                    : process.env.PUBLIC_URL + '/assets/icons/face_icon.svg'
                            }
                            label={getImageName()}
                        ></Avatar>
                        <input
                            type="file"
                            id="file"
                            ref={(file) => {
                                setFileUploader(file);
                            }}
                            hidden
                            onChange={(ev) => handleImageUpload(ev)}
                            onClick={(ev) => (ev.target.value = null)}
                        />
                        <div className="middle">
                            <div className="text display-flex justify-content-center align-center">
                                <img
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/camera.svg'}
                                    onClick={() => handleUploadImage()}
                                    height="25px"
                                    width="25px"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    {userInfo.img_url !== '' && (
                        <div className="row p-t-5 justify-content-center align-center">
                            <div className="red cur-point" onClick={(ev) => removeProfile(ev)}>
                                Remove profile picture
                            </div>
                        </div>
                    )}
                    <div className="row m-0 p-t-20">
                        <div className="col-xs-4 col-md-4 col-lg-4 col-sm-4 col-xl-4">
                            <label>
                                <b>Employee Name</b>
                            </label>
                        </div>
                        <div className="col-xs-8 col-md-6 col-lg-8 col-sm-8 col-xl-8">
                            {userInfo && userInfo.employee
                                ? userInfo.employee.first_name +
                                  ' ' +
                                  (userInfo.employee.middle_name ? userInfo.employee.middle_name : '') +
                                  ' ' +
                                  userInfo.employee.last_name
                                : '-'}
                        </div>
                    </div>
                    <div className="row m-0 p-t-20">
                        <div className="col-xs-4 col-md-4 col-lg-4 col-sm-4 col-xl-4">
                            <label>
                                <b>Employee Email</b>
                            </label>
                        </div>
                        <div className="col-xs-8 col-md-8 col-lg-8 col-sm-8 col-xl-8">
                            {userInfo?.employee ? userInfo.employee.email : '-'}
                        </div>
                    </div>
                    <div className="row m-0 p-t-20">
                        <div className="col-xs-4 col-md-4 col-lg-4 col-sm-4 col-xl-4">
                            <label>
                                <b>Department</b>
                            </label>
                        </div>
                        <div className="col-xs-8 col-md-8 col-lg-8 col-sm-8 col-xl-8">
                            {userInfo?.employee
                                ? userInfo.employee.budget_office +
                                  (userInfo.employee.budget_office_name ? `${' - ' + userInfo.employee.budget_office_name}` : '')
                                : '-'}
                        </div>
                    </div>
                    <div className="row m-0 p-t-20">
                        <div className="col-xs-4 col-md-4 col-lg-4 col-sm-4 col-xl-4">
                            <label>
                                <b>Office</b>
                            </label>
                        </div>
                        <div className="col-xs-8 col-md-8 col-lg-8 col-sm-8 col-xl-8">
                            {userInfo?.employee
                                ? userInfo.employee.deputation_office +
                                  (userInfo.employee.deputation_office_name ? `${' - ' + userInfo.employee.deputation_office_name}` : '')
                                : '-'}
                        </div>
                    </div>
                    <div className="row m-0 p-t-20">
                        <div className="col-xs-4 col-md-4 col-lg-4 col-sm-4 col-xl-4">
                            <label className="m-b-0">
                                <b>Login Name</b>
                            </label>
                        </div>
                        <div className="col-xs-8 col-md-8 col-lg-8 col-sm-8 col-xl-8">{userInfo?.employee ? userInfo.user_name : '-'}</div>
                    </div>
                </form>
            </div>
        );
    };

    const FModalBodyUpdatePW = () => {
        return (
            <div className="row">
                <form ref={(form) => (profileForm = form)} className="w-100">
                    <div className="col-xs-12 col-md-8 col-lg-12 col-sm-12 col-xl-12">
                        <label>
                            Old Password <span className="icon-primary"> *</span>
                        </label>
                        <Input
                            inputBoxSize="sm"
                            name="password"
                            type={statePassword.password_Icon ? 'password' : 'text'}
                            value={statePassword.password}
                            validations={['required']}
                            errMsg="Password is mandatory"
                            hasError={errors && errors.password}
                            maxLength={25}
                            autoComplete="off"
                            onChange={(e) => handleChangePassword(e, 'password')}
                        />
                        <span
                            onClick={(e) => handlerPassword(e)}
                            className={
                                statePassword.password_Icon
                                    ? 'btn-show_password a-font-22 pi pi-eye-slash'
                                    : 'btn-show_password a-font-22 pi pi-eye'
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-8 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                        <label>
                            New Password <span className="icon-primary"> *</span>
                        </label>
                        <Input
                            inputBoxSize="sm"
                            name="new_password"
                            type={statePassword.new_password_Icon ? 'password' : 'text'}
                            value={statePassword.new_password}
                            validations={['required']}
                            errMsg="Password should contain atleast 8 characters and should be like this 'Abc@1cdf'"
                            hasError={errors && errors.new_password}
                            maxLength={25}
                            autoComplete="off"
                            onChange={(e) => handleChangePassword(e, 'new_password')}
                        />
                        <span
                            onClick={(e) => handlerNewPassword(e)}
                            className={
                                statePassword.new_password_Icon
                                    ? 'btn-show_password a-font-22 pi pi-eye-slash'
                                    : 'btn-show_password a-font-22 pi pi-eye'
                            }
                        />
                    </div>
                    <div className="col-xs-12 col-md-8 col-lg-12 col-sm-12 col-xl-12 m-t-20">
                        <label>
                            Re Type New Password <span className="icon-primary"> *</span>
                        </label>
                        <Input
                            inputBoxSize="sm"
                            name="confirm_new_password"
                            type={statePassword.confirm_new_password_Icon ? 'password' : 'text'}
                            value={statePassword.confirm_new_password}
                            validations={['required']}
                            autoComplete="off"
                            errMsg="New Password and Re-type New Password does not match"
                            hasError={errors && errors.confirm_new_password}
                            maxLength={25}
                            onChange={(e) => handleChangePassword(e, 'confirm_new_password')}
                        />
                        <span
                            onClick={(e) => handlerConfirmNewPassword(e)}
                            className={
                                statePassword.confirm_new_password_Icon
                                    ? 'btn-show_password a-font-22 pi pi-eye-slash'
                                    : 'btn-show_password a-font-22 pi pi-eye'
                            }
                        />
                    </div>
                </form>
            </div>
        );
    };

    const FModalFooter = () => {
        return (
            <div className="button-comp">
                {!showProfile && (
                    <Button size="md" kind="primary" className="m-r-10 f-wt-bold" onClick={handleSave}>
                        Save
                    </Button>
                )}
                <Button size="md" kind="" className="outline-btn" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        );
    };

    const FModalFooterUpdatePW = () => {
        return (
            <div className="button-comp">
                <Button size="md" kind="primary" className="m-r-10 f-wt-bold" disabled={statePassword.updateDisable} onClick={handleSavePW}>
                    Update
                </Button>
                <Button size="md" kind="" className="outline-btn" onClick={handleCancelUpdatePW}>
                    Cancel
                </Button>
            </div>
        );
    };

    const handleSave = () => {
        let formErrors = FormValidation.validateForm(profileForm);
        const exists = Object.keys(formErrors).some(function (k) {
            return formErrors[k] === true;
        });
        if (exists) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: 'All are mandatory'
            });
            return;
        }

        updateProfile({
            variables: {
                where: { id: userInfo.id },
                data: {
                    first_name: profileState.first_name,
                    last_name: profileState.last_name
                }
            }
        });
        handleCancel();
    };

    const handleSavePW = () => {
        let formErrors = FormValidation.validateForm(profileForm);
        const exists = Object.keys(formErrors).some(function (k) {
            return formErrors[k] === true;
        });

        if (exists) {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: 'All are mandatory'
            });
            return;
        }

        if (statePassword.confirm_new_password === statePassword.new_password) {
            if (statePassword.confirm_new_password.length > 0) {
                updatePassword({
                    variables: {
                        id: userInfo.id,
                        password: statePassword.password,
                        newPassword: statePassword.new_password
                    }
                });
            } else {
                growl.show({
                    life: 6000,
                    severity: 'error',
                    summary: 'All fields are mandatory'
                });
            }
        } else {
            growl.show({
                life: 6000,
                severity: 'error',
                summary: 'New password and Confirm new password values both should be same'
            });
        }
    };

    if (called) {
        if (!loading) {
            localStorage.setItem('USER_INF', JSON.stringify(data.updateOneUser));
            userInfo = JSON.parse(localStorage.getItem('USER_INF'));
        }
    }
    const onClickLogo = () => {
        history.push('/dashboard');
    };

    const closeConfirmation = () => {
        setState({ ...state, showConfirmation: false, deleteProfile: false });
    };

    const [getTaskNotifData] = useLazyQuery(Schema.annualAuditPlanInfo, {
        client: iaClient,
        fetchPolicy: 'no-cache',
        onCompleted: (response) => {
            if (response && response.annualAuditPlanInfo) {
                let val = response.annualAuditPlanInfo?.[0];
                localStorage.setItem('audit_assignment_type', val?.audit_assignment_type ? val?.audit_assignment_type : '');
                localStorage.setItem('audit_assignment_id', val?.audit_assignment_id ? val?.audit_assignment_id : '');
                localStorage.setItem('audit_assignment_name', val?.audit_assignment_type ? auditTypeName(val?.audit_assignment_type) : '');
                localStorage.setItem('aap_detail_id', val?.aap_detail_id ? val?.aap_detail_id : '');
                localStorage.setItem('aap_item_id', val?.aap_item_id ? val?.aap_item_id : '');
                localStorage.setItem('office_code', val?.iau_code ? val?.iau_code : '');
                let csVariables = '';
                if (val?.audit_assignment_id === '') {
                    csVariables = { aap_item_id: val?.aap_item_id };
                } else if (val?.aap_item_id === '') {
                    csVariables = { assignment_id: val?.audit_assignment_id };
                } else {
                    csVariables = { aap_item_id: val?.aap_item_id, assignment_id: val?.audit_assignment_id };
                }
                conclusionStatus({ variables: csVariables });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const [conclusionStatus] = useLazyQuery(AAPEQueries.getConclusionStatus, {
        fetchPolicy: 'no-cache',
        client: iaClient,
        onCompleted: (resp) => {
            if (resp?.conclusionStatus) {
                localStorage.setItem('AAPE_CONCLUSION_STATUS', JSON.stringify(resp.conclusionStatus));
                localStorage.setItem('transaction_id', selectdNotif.id);
                takeActionNavigationForAms(history, selectdNotif);
                window.location.reload();
                setState({ ...state, loading: false });
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });

    const handleTakeAction = (e, task) => {
        op.current.hide(e);
        setSelectdNotif(task);
        if (!window.INTERNAL_AUDIT) {
            takeActionNavigationForFmis(history, task);
        } else {
            let flag = false;
            fmisFunctions.map((ele) => {
                if (ele.code === task.fmis_function) flag = true;
            });
            if (flag) {
                getTaskNotifData({ variables: { where: { source_id: task.source_id, fmis_function: task.fmis_function } } });
            } else {
                takeActionNavigationForAms(history, task);
                localStorage.setItem('transaction_id', task.id);
            }
        }
    };

    return (
        <div className="normal-header-container">
            {showProfile && (
                <UpdateProfileDialog
                    showModal={showProfile}
                    handleCancel={handleCancel}
                    FModalHeader={FModalHeader}
                    FModalBody={FModalBody}
                    FModalFooter={FModalFooter}
                    className="common_css UPDATE_PROFILE"
                />
            )}
            {showUpdatePw && (
                <UpdatePasswordDialog
                    showModal={showUpdatePw}
                    handleCancelUpdatePW={handleCancelUpdatePW}
                    FModalHeaderUpdatePW={FModalHeaderUpdatePw}
                    FModalBodyUpdatePW={FModalBodyUpdatePW}
                    FModalFooterUpdatePW={FModalFooterUpdatePW}
                    className="common_css UPDATE_PROFILE"
                />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            <nav className={'a-header-wrapper justify-content-between'}>
                <div className="a-brand-container">
                    <div className="pointer">
                        <img src={process.env.PUBLIC_URL + '/assets/icons/logo.png'} height="30" alt="Tax Payer" onClick={onClickLogo} />
                    </div>
                    <div className="a-divider" />
                    <h6 className="text-nowrap m-b-0 fs-18">
                        <span className="h1-name">
                            {window.INTERNAL_AUDIT ? 'Audit Management System' : window._env_.REACT_APP_CLIENT_NAME}
                        </span>
                        {!window.INTERNAL_AUDIT && <span className="h1-name2">- {window._env_.REACT_APP_APP_NAME}</span>}
                    </h6>
                </div>
                <div className="a-actions-container">
                    {window.INTERNAL_AUDIT && (
                        <button
                            type="button"
                            label="Toggle"
                            title="Calendar"
                            className="a-btn-background-transparent d-wi-none m-r-20 a-icon-container"
                        >
                            <Link className="whiteLink" to="/calendarblock/calendarblocking">
                                <img src={process.env.PUBLIC_URL + '/assets/icons/calendar.png'} alt="" height="23" />
                            </Link>
                        </button>
                    )}
                    <button
                        type="button"
                        label="Toggle"
                        onClick={(e) => generalNotifRef.current.show(e)}
                        title="Notifications"
                        className="a-btn-background-transparent d-wi-none m-r-20 a-icon-container"
                    >
                        <img src={process.env.PUBLIC_URL + '/assets/icons/Notification_Bell.svg'} alt="" height="23" />
                        <span className="badge-align badge badge-pill badge-danger">
                            {state.showNotifCount ? 0 : generalNotifCount?.total_notifications?.unRead_Count || 0}
                        </span>
                    </button>
                    <OverlayPanel className="overlayPanel" ref={generalNotifRef} id="overlay_panel" showCloseIcon={true}>
                        <div className="task_header display-flex align-center p-t-10 p-l-15 p-b-10 p-r-15">
                            Notifications
                            <span className="task_count p-l-15">
                                {state.showNotifCount ? 0 : generalNotifCount?.total_notifications?.unRead_Count || 0}
                            </span>
                        </div>
                        <hr className="m-0" />
                        {generalNotifications && generalNotifications.length > 0 && !state.showNotifCount ? (
                            generalNotifications.map((notif, index) => {
                                return (
                                    index < 5 && (
                                        <div key={index} className="p-t-10 p-l-15 p-r-15">
                                            <div>
                                                <span className="module_name fs-14">
                                                    <b>{notif.title}</b>
                                                </span>
                                            </div>
                                            <div className="display-flex align-center p-t-10">
                                                <div className="col-md-6 p-0 module_name fs-13">{getDate(notif.version_date)}</div>
                                            </div>
                                        </div>
                                    )
                                );
                            })
                        ) : (
                            <div className="display-flex align-center justify-content-center p-t-30 p-b-30">No new notifications.</div>
                        )}
                        {!(generalNotifications && generalNotifications.length === 0) && !state.showNotifCount && (
                            <Link className="whiteLink" to="/dashboard/gennotifications">
                                <div
                                    className="p-t-10 p-b-10 text-center pointer view_all m-t-20"
                                    onClick={(e) => {
                                        generalNotifRef.current.hide(e);
                                        handleClick();
                                    }}
                                >
                                    <b>View All Notifications</b>
                                </div>
                            </Link>
                        )}
                    </OverlayPanel>

                    <button
                        type="button"
                        label="Toggle"
                        onClick={(e) => op.current.show(e)}
                        title="Task Notifications"
                        className="a-btn-background-transparent d-wi-none m-r-20 a-icon-container"
                    >
                        <img src={process.env.PUBLIC_URL + '/assets/icons/notepad.svg'} alt="" height="23" />
                        <span className="badge-align badge badge-pill badge-danger">{taskNotifCount}</span>
                    </button>
                    <OverlayPanel className="overlayPanel" ref={op} id="overlay_panel" showCloseIcon={true}>
                        <div className="task_header display-flex align-center p-t-10 p-l-15 p-b-10 p-r-15">
                            Task Notifications<span className="task_count p-l-15">{taskNotifCount}</span>
                        </div>
                        <hr className="m-0" />
                        {state.taskNotifications && state.taskNotifications.length > 0 ? (
                            state.taskNotifications.map((notif, index) => {
                                return (
                                    index < 5 && (
                                        <div key={index} className="p-t-10 p-l-15 p-r-15">
                                            <div>
                                                <span className="module_name fs-14">
                                                    <b>{notif.module}</b>
                                                </span>
                                                <span className="module_id fs-13">
                                                    <span className="column-label" onClick={(e) => handleTakeAction(e, notif)}>
                                                        {notif.id}
                                                    </span>
                                                </span>
                                                <span className="module_id fs-13 p-l-5">{notif.moduleStatus}</span>
                                            </div>
                                            <div className="display-flex align-center p-t-10">
                                                <div className="col-md-6 p-0 module_name fs-13">{getDate(notif.version_date)}</div>
                                                <div className="col-md-6 p-0 display-flex justify-flex-end">
                                                    <Button
                                                        size="md"
                                                        kind=""
                                                        className="outline-btn overlay_btn pointer w-50 p-0"
                                                        onClick={(e) => handleTakeAction(e, notif)}
                                                    >
                                                        Take Action
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                );
                            })
                        ) : (
                            <div className="display-flex align-center justify-content-center p-t-30 p-b-30">
                                <span className="fs-14">No new notifications.</span>
                            </div>
                        )}
                        {!(state.taskNotifications && state.taskNotifications.length === 0) && (
                            <Link className="whiteLink" to="/dashboard/notifications">
                                <div className="p-t-10 p-b-10 text-center pointer view_all m-t-20" onClick={(e) => op.current.hide(e)}>
                                    <b>View All Notifications</b>
                                </div>
                            </Link>
                        )}
                    </OverlayPanel>
                    <div className="p-r-20 text-right h1-name2 d-wi-none flex-d-c">
                        <div>
                            {userInfo?.employee?.first_name} {userInfo?.employee?.last_name}
                        </div>
                        <div className="w-100">
                            Last Login: <span>{loginDate}</span>
                        </div>
                    </div>
                    <Tooltip
                        content={logoutTooltip()}
                        trigger="click"
                        refClassName="a-account-info"
                        className="a-account-options"
                        tooltipTheme="light"
                        clickToClose
                        placement="bottom-end"
                    >
                        <img
                            className="user_logo pointer"
                            src={
                                userInfo?.img_url && userInfo.img_url !== '' && userInfo.img_url !== null
                                    ? localImgUrl
                                    : process.env.PUBLIC_URL + '/assets/icons/face_icon.svg'
                            }
                            alt=""
                        />
                    </Tooltip>
                    <div className="d-wi a-sm-options-container sel-option-override">
                        <Select value="1">
                            <SelectOption key="1" value="1" selectedDisplay={<span className="appkiticon icon-vertical-more-fill" />}>
                                <div className="display-flex">
                                    <button
                                        type="button"
                                        label="Toggle"
                                        onClick={(e) => op.current.toggle(e)}
                                        title="Task Notifications"
                                        className="a-font-22 appkiticon icon-primary"
                                    >
                                        <img src={process.env.PUBLIC_URL + '/assets/icons/notepad.svg'} alt="" height="20" />
                                        <span className="badge-align badge badge-pill badge-danger resp-badge">{taskNotifCount}</span>
                                        <span className="a-description p-l-7">Task Notifications</span>
                                    </button>
                                </div>
                            </SelectOption>
                        </Select>
                    </div>
                </div>
            </nav>
            {state.showConfirmation && (
                <CommonModal
                    title={state.confirmationTitle}
                    type="Text"
                    text={state.confirmationText}
                    showModal={state.showConfirmation}
                    closeModal={closeConfirmation}
                    save={true}
                    submit={handleProfileUpload}
                    saveText="Yes"
                    cancelText="No"
                />
            )}
        </div>
    );
};
export default Header;
