import Routing from './routings/Routing';
import { Switch, BrowserRouter, HashRouter } from 'react-router-dom';
import './App.scss';
import 'appkit-react/style/appkit-react.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { setGrowl } from './app-redux/app-action';
import { useQuery } from '@apollo/client';
import Schema from './schema/URSchema';

const App = () => {
    let myVar;
    let dispatch = useDispatch();
    let loadSpinner = useSelector((state) => state.appReducer.spinner);
    const ToastComp = () => {
        return (
            <Toast
                ref={(el) => {
                    dispatch(setGrowl(el));
                }}
            />
        );
    };

    let session = 10;
    let isSession = false;

    useQuery(Schema.lockFacilityQuery, {
        variables: {
            where: {
                id: 'SESSION_TIMEOUT_SYSADMIN'
            }
        },
        onCompleted: (data) => {
            if (parseInt(data.appSetting.value2)) {
                isSession = data.appSetting.value1 === 'true' ? true : false;
                session = parseInt(data.appSetting.value2);
            }
        },
        onError: () => {}
    });
    const onKeyUp = () => {
        if (isSession) {
            calledTimeOut();
        }
    };

    const onKeyDown = () => {
        if (isSession) {
            calledTimeOut();
        }
    };

    const onMouseDown = () => {
        if (isSession) {
            calledTimeOut();
        }
    };

    const onMouseUp = () => {
        if (isSession) {
            calledTimeOut();
        }
    };

    const onMouseMove = () => {
        if (isSession) {
            calledTimeOut();
        }
    };

    const calledTimeOut = () => {
        clearTimeout(myVar);
        myVar = setTimeout(function () {
            logout();
        }, session * 60000);
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };
    return (
        <div
            className={`h-100 ${loadSpinner ? ' mask' : ''} `}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
        >
            <ToastComp />

            {loadSpinner ? <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" /> : null}
            <BrowserRouter>
                <HashRouter>
                    <Switch>
                        <Routing />
                    </Switch>
                </HashRouter>
            </BrowserRouter>
        </div>
    );
};
export default App;
