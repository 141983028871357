/* eslint-disable max-depth */
import validator from 'validator';
function validateForm(form) {
    let formErrors = FormErrors(form);
    const exists = Object.keys(formErrors).some(function (k) {
        return formErrors[k] === true;
    });
    return exists;
}

function FormErrors(form) {
    let elements = form.elements;
    let errors = {};
    let error;
    for (let index in elements) {
        let element = elements[index];
        let attributes = element.attributes;

        if (attributes && attributes.validations) {
            error = validateFormErrors(element, attributes);
        }
        errors = { ...error, ...errors };
    }
    return errors;
}
function validateFormErrors(element, attributes) {
    let error;
    error = validateFileld(
        attributes.validations.nodeValue,
        element.name,
        element.value,
        '',
        attributes?.maxlength?.value && parseInt(attributes?.maxlength?.value),
        attributes?.minlength?.value && parseInt(attributes?.minlength?.value)
    );
    if (attributes.customvalue) {
        error = validateFileld(
            attributes.validations.nodeValue,
            attributes.name.value,
            '',
            attributes.customvalue.value,
            attributes?.maxlength?.value && parseInt(attributes?.maxlength?.value),
            attributes?.minlength?.value && parseInt(attributes?.minlength?.value)
        );
    }
    return error;
}
function validateFileld(fields, name, value, pattern, maxLength, minLength) {
    let errors = {};
    let validators = fields?.split(',');
    let patternArray;
    for (let i = 0; i < validators?.length; i++) {
        validators[i] = validators[i] === 'admissible_variance' ? 'percentage' : validators[i];
        let flag = false;
        switch (validators[i]) {
            case 'required':
                errors[name] = false;
                pattern = new RegExp(pattern);
                patternArray = pattern.test(value);
                flag =
                    !patternArray ||
                    !value ||
                    value === 'Null' ||
                    value === 'null' ||
                    validator.isEmpty(value.toString()) ||
                    (value && value.length > 0 && typeof value === 'string' && value.trim() === '');
                errors[name] = flag;
                break;
            case 'email':
                errors[name] = false;
                pattern = new RegExp(pattern || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
                patternArray = pattern.test(value);
                flag =
                    ((pattern !== undefined || pattern !== '') &&
                        (!patternArray ||
                            !value ||
                            value.toLowerCase() === 'null' ||
                            validator.isEmpty(value.toString()) ||
                            (value && value.length > 0 && typeof value === 'string' && value.trim() === ''))) ||
                    ((pattern === undefined || pattern === '') && !validator.isEmail(value) && !value);

                errors[name] = flag;
                break;
            case 'numeric':
                errors[name] = false;
                flag = !validator.isNumeric(value) || value === '+' || value === 'e' || value === '';
                errors[name] = flag;
                break;
            case 'numericwith':
                errors[name] = false;
                flag = !validator.isNumeric(value) || value === '+' || value === 'e' || value === '';
                errors[name] = flag;
                break;
            case 'atleastOneAlpha':
                errors[name] = false;
                pattern = new RegExp(/^\d*[a-zA-Z][a-zA-Z0-9\s]*$/g);
                patternArray = pattern.test(value);
                flag = !patternArray || value === '' || value.toLowerCase() === 'null' || (value.length > 0 && value.trim().length === 0);
                errors[name] = flag;
                break;
            case 'atleastOneAlphanumeric':
                errors[name] = false;
                pattern = /^(?=.*[a-zA-Z])([a-zA-Z]){1}([a-zA-Z0-9\s]+)$/;
                patternArray = pattern.test(value);
                flag = !patternArray || value.toLowerCase() === 'null' || value === '' || (value.length > 0 && value.trim().length === 0);
                errors[name] = flag;
                break;
            case 'alphaNumeric':
                errors[name] = false;
                pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
                patternArray = pattern.test(value);
                flag =
                    (!patternArray && value !== '') ||
                    value.toLowerCase() === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0);
                errors[name] = flag;
                break;
            case 'isAlpha':
                errors[name] = false;
                pattern = new RegExp(/^[a-zA-Z ]*$/g);
                patternArray = pattern.test(value);
                flag = !patternArray || value === '';
                errors[name] = flag;
                break;
            case 'zipCode':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{6}/g);
                patternArray = pattern.test(value);
                flag = ((!patternArray || value.length > 6) && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'fixed':
                errors[name] = false;
                flag = !validator.isNumeric(value) || value === '+' || value === 'e' || value === '' || value.length > 6;
                errors[name] = flag;
                break;
            case 'phone':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{10}/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 10) && value !== '';
                errors[name] = flag;
                break;
            case 'pan':
                errors[name] = false;
                pattern = new RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/g);
                patternArray = pattern.test(value);
                flag = !patternArray || !value || validator.isEmpty(value.toString());
                errors[name] = flag;
                break;
            case 'aadhaar':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{12}/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 12) && value !== '';
                errors[name] = flag;
                break;
            case 'bank_acc':
                errors[name] = false;
                pattern = new RegExp(/^[0-9]{9,25}$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 25) && value !== '';
                errors[name] = flag;
                break;
            case 'passport':
                errors[name] = false;
                pattern = new RegExp(/^([a-zA-Z]){1}([0-9]){2}\s?([0-9]){5}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 9) && value !== '';
                errors[name] = flag;
                break;
            case 'gstin':
                errors[name] = false;
                pattern = new RegExp(/^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([a-zA-Z0-9]){1}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 15) && value !== '';
                errors[name] = flag;
                break;
            case 'vat':
                errors[name] = false;
                pattern = new RegExp(/^([a-zA-Z]){2}?[0-9]{7}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 9) && value !== '';
                errors[name] = flag;
                break;
            case 'nation_id':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{11}/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 11) && value !== '';
                errors[name] = flag;
                break;
            case 'tpin':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{6}/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 15) && value !== '';
                errors[name] = flag;
                break;
            case 'driving_license':
                errors[name] = false;
                pattern = new RegExp(/^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 16) && value !== '';
                errors[name] = flag;
                break;
            case 'voter_id':
                errors[name] = false;
                pattern = new RegExp(/^([a-zA-Z]){3}([0-9]){7}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 10) && value !== '';
                errors[name] = flag;
                break;
            case 'CIN':
                errors[name] = false;
                pattern = new RegExp(/^([UL]){1}([0-9]){5}([a-zA-Z]){2}([0-9]){4}([a-zA-Z]){3}([0-9]){6}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 21) && value !== '';
                errors[name] = flag;
                break;
            case 'Foreign_Tax_Id':
                errors[name] = false;
                pattern = new RegExp(/^([0-9]){11}?$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray || value.length > 11) && value !== '';
                errors[name] = flag;
                break;
            case 'maxLength':
                errors[name] = false;
                flag = value.length < maxLength || value === '';
                errors[name] = flag;
                break;
            case 'minLength':
                errors[name] = false;
                flag = value.length < minLength || value === '';
                errors[name] = flag;
                break;
            case 'percentage':
                errors[name] = false;
                pattern = new RegExp(/^(100(?:\.0{1,2})?|0*?\.\d{1,2}|\d{1,2}(?:\.\d{2})?)$/g);
                patternArray = pattern.test(value);
                flag = !patternArray || value === '';
                errors[name] = flag;
                break;
            case 'ifsc':
                errors[name] = false;
                pattern = new RegExp(/[A-Za-z]{4}0[A-Za-z0-9]{6}/g);
                patternArray = pattern.test(value);
                flag = ((!patternArray || value.length < 11) && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'supply':
                errors[name] = false;
                pattern = new RegExp(/^\d*[a-zA-Z][a-zA-Z0-9!@#$%&*()-_+"',` ]*$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === 'Null' || value === 'null' || value === '';
                errors[name] = flag;
                break;
            case 'city_code':
                errors[name] = false;
                flag = ((!validator.isAlphanumeric(value) || value.length < 2) && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'bank_name':
                errors[name] = false;
                pattern = new RegExp(/\b^[A-Za-z -.&()]+$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'numbersOnly':
                errors[name] = false;
                pattern = new RegExp(/^\d+$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'floatNumbersOnly':
                errors[name] = false;
                pattern = new RegExp(/^\d+(\.\d+)?$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'floatNumbers':
                errors[name] = false;
                pattern = new RegExp(/^\d+(\.\d{0,2})?$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'hoa':
                errors[name] = false;
                pattern = new RegExp(/^([0-9]+-)*[0-9]+$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'fax':
                errors[name] = false;
                pattern = new RegExp(/^([+]){1}([0-9]){10,14}/g);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '' || value?.length > 16 || value.indexOf(' ') >= 0;
                errors[name] = flag;
                break;
            case 'billType_Code':
                errors[name] = false;
                pattern = new RegExp(/^[A-Za-z0-9_/.&() ]+$/g);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'GPF':
                errors[name] = false;
                pattern = new RegExp(/^([a-zA-Z]){2}([/]){1}([a-zA-Z]){3}([/]){1}([0-9]){1,7}([/]){1}([0-9]){1,7}?$/g);
                patternArray = pattern.test(value);
                flag = !patternArray || !value || validator.isEmpty(value.toString());
                errors[name] = flag;
                break;
            case 'PRAN':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{12}/g);
                patternArray = pattern.test(value);
                flag = !patternArray || value.length > 12 || !value || validator.isEmpty(value.toString());
                errors[name] = flag;
                break;
            case 'startWithAlpha':
                errors[name] = false;
                pattern = new RegExp(/^[a-zA-Z][a-zA-Z0-9!@#$%^&*_:"<>~;',./()-\s]*$/g);
                patternArray = pattern.test(value);
                if (
                    !patternArray ||
                    value === '' ||
                    value === 'Null' ||
                    value === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0)
                ) {
                    errors[name] = true;
                }
                break;
            case 'startWithAlphaNumeric':
                errors[name] = false;
                pattern = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9!@#$%^&*_:"<>~;',./()-\s]*$/g);
                patternArray = pattern.test(value);
                if (
                    !patternArray ||
                    value === '' ||
                    value === 'Null' ||
                    value === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0)
                ) {
                    errors[name] = true;
                }
                break;
            case 'isAlphabet':
                errors[name] = false;
                pattern = new RegExp(/^[a-zA-Z ]*$/g);
                patternArray = pattern.test(value);
                if (
                    !patternArray ||
                    value === '' ||
                    value === 'Null' ||
                    value === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0)
                ) {
                    errors[name] = true;
                }
                break;
            case 'isAlphabetSpecial':
                errors[name] = false;
                pattern = new RegExp(/^[ A-Za-z_@.~!$%^*()/#&+-]*$/g);
                patternArray = pattern.test(value);
                if (
                    !patternArray ||
                    value === 'Null' ||
                    value === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0)
                ) {
                    errors[name] = true;
                }
                break;
            case 'decimalNumber':
                errors[name] = false;
                pattern = new RegExp(/^\d{1,2}(.\d{1,2})?$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '';
                errors[name] = flag;
                break;
            case 'VAT':
                errors[name] = false;
                pattern = new RegExp(/[0-9]{9}/g);
                patternArray = pattern.test(value);
                flag = !patternArray || value.length > 9 || !value || validator.isEmpty(value.toString());
                errors[name] = flag;
                break;
            case 'fileNumber':
                errors[name] = false;
                pattern = new RegExp(/^[0-9]?[0-9]+(-[0-9]+)*$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '-' || value === '';
                errors[name] = flag;
                break;
            case 'grade':
                errors[name] = false;
                pattern = new RegExp(/^[A-Za-z+-]*$/);
                patternArray = pattern.test(value);
                flag = (!patternArray && value !== '') || value === '+' || value === '-' || value === '';
                errors[name] = flag;
                break;
            case 'password':
                errors[name] = false;
                pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
                patternArray = pattern.test(value);
                if (
                    !patternArray ||
                    value === '' ||
                    value === 'Null' ||
                    value === 'null' ||
                    value === '' ||
                    (value.length > 0 && value.trim().length === 0)
                ) {
                    errors[name] = true;
                }
                break;
            case 'mismatch':
                errors[name] = false;
                if (value !== pattern || (value.length > 0 && value.trim().length === 0)) {
                    errors[name] = true;
                }
                break;
            default:
        }
    }
    return errors;
}
export { validateForm, validateFileld, FormErrors };
