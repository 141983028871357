import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import CreateComposition from '../pages/coa/composition/CreateComposition';
import Composition from '../pages/coa/composition/Composition';
import Masters from '../pages/coa/masters/Masters';
import Structure from '../pages/coa/structure/Structure';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const StructureDetails = lazy(() => import('../pages/coa/structure/StructureDetails'));
const CreateStructure = lazy(() => import('../pages/coa/structure/CreateStructure'));
const CommonCloneView = lazy(() => import('../components/CommonCloneView/CommonCloneView'));
const CreateSegment = lazy(() => import('../pages/coa/CreateSegment/CreateSegment'));
const SegmentConfiguration = lazy(() => import('../pages/coa/SegmentConfiguration/SegmentConfiguration'));
const EconomicClassfication = lazy(() => import('../pages/coa/EconomicClassfication/EconomicClassfication'));
const CreateEconomicClassfication = lazy(() => import('../pages/coa/EconomicClassfication/CreateEconomicClassfication'));
const Reports = lazy(() => import('../pages/coa/reports/Reports'));
const Mapping = lazy(() => import('../pages/coa/mapping/Mapping'));
const Codes = lazy(() => import('../pages/coa/codes/Codes'));
const coaBreadcrumb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'System Administration', redirectTo: '/systemadministration' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;

    if (
        subPath[3] === 'view' ||
        subPath[3] === 'clone' ||
        subPath[3] === 'create' ||
        subPath[3] === 'edit' ||
        subPath[3] === 'all' ||
        subPath[3] === 'existingcodes'
    ) {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/coa/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const coaMenuItems = MenuItemsSchema.COA_ITEMS;
const CoaRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={coaBreadcrumb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={CreateSegment}
                    exact
                    path={`${props?.path}/segments`}
                    name="CreateSegment"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd {...props} component={Structure} exact path={`${props?.path}/structures`} menuItems={coaMenuItems} />
                <AuthGaurd
                    {...props}
                    component={CreateStructure}
                    exact
                    path={`${props?.path}/structures/create`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateStructure}
                    exact
                    path={`${props?.path}/structures/edit/:id`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={StructureDetails}
                    exact
                    path={`${props?.path}/structures/view/:id`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd {...props} component={CommonCloneView} exact path={`${props?.path}/structures/clone`} menuItems={coaMenuItems} />
                <AuthGaurd
                    {...props}
                    component={Composition}
                    exact
                    path={`${props?.path}/compositions`}
                    name="Composition"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={Masters}
                    exact
                    path={`${props?.path}/masters`}
                    name="Composition"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateComposition}
                    exact
                    path={`${props?.path}/compositions/create/:yearid`}
                    name="CreateComposition"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateComposition}
                    exact
                    path={`${props?.path}/compositions/view/:id`}
                    name="CreateComposition"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateComposition}
                    exact
                    path={`${props?.path}/compositions/edit/:id`}
                    name="CreateComposition"
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CommonCloneView}
                    exact
                    path={`${props?.path}/compositions/clone/:yearid`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd {...props} component={Codes} exact path={`${props?.path}/codes`} name="Codes" menuItems={coaMenuItems} />
                <AuthGaurd
                    {...props}
                    component={CommonCloneView}
                    exact
                    path={`${props?.path}/codes/clone/:yearid`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd {...props} component={Mapping} path={`${props?.path}/mappings`} menuItems={coaMenuItems} />
                <AuthGaurd {...props} component={Reports} exact path={`${props?.path}/reports`} name="Reports" menuItems={coaMenuItems} />
                <AuthGaurd {...props} component={SegmentConfiguration} exact path={`${props?.path}/csc`} menuItems={coaMenuItems} />
                <AuthGaurd {...props} component={EconomicClassfication} exact path={`${props?.path}/cecc`} menuItems={coaMenuItems} />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/create`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/view/:id`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/edit/:id`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/edit/:id/draft`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/view/:id/draft`}
                    menuItems={coaMenuItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateEconomicClassfication}
                    exact
                    path={`${props?.path}/cecc/all`}
                    menuItems={coaMenuItems}
                />
            </Switch>
        </div>
    );
};
export default CoaRouting;
