import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';

const HumanResourcesPlan = lazy(() => import('../pages/internalAudit/masters/resourceManagement/HumanResourcesPlan'));
const RateCard = lazy(() => import('../pages/internalAudit/masters/resourceManagement/RateCard/RateCard'));
const AddRateCard = lazy(() => import('../pages/internalAudit/masters/resourceManagement/RateCard/AddRateCard'));
const ResourcePlanConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/resourceManagement/resourcePlanConfiguration/ResourcePlanConfiguration')
);
const AddResourcePlanConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/resourceManagement/resourcePlanConfiguration/AddResourcePlanConfiguration')
);
const GradeAllowanceConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/resourceManagement/GradeAllowanceConfiguration/GradeAllowanceConfiguration')
);
const AddGradeAllowanceConfiguration = lazy(() =>
    import('../pages/internalAudit/masters/resourceManagement/GradeAllowanceConfiguration/AddGradeAllowanceConfiguration')
);

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const resourceManagementItems = MenuItemsSchema.RESOURCE_MANAGEMENT;
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const resourceMangBreadCrmb = (props) => {
    let resourceMangValues = [{ name: 'Masters', redirectTo: '/masters' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/resourcemang/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    resourceMangValues = [...resourceMangValues, ...obj];
    return resourceMangValues;
};
const ResourceMangementRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={resourceMangBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={HumanResourcesPlan}
                    exact
                    path={`${props?.path}/humanresourcesplan`}
                    name="Human Resources Plan"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={ResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/create`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/view/:id/draft`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/view/:id`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/edit/:id/draft`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/edit/:id`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/edit/:id/approved`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddResourcePlanConfiguration}
                    exact
                    path={`${props?.path}/resourceplanconfig/view/:id/approved`}
                    name="Resource Plan Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={RateCard}
                    exact
                    path={`${props?.path}/ratecard`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/create`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/view/:id/draft`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/view/:id`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/edit/:id/draft`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/edit/:id`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/edit/:id/approved`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddRateCard}
                    exact
                    path={`${props?.path}/ratecard/view/:id/approved`}
                    name="Rate Card"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={GradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/create`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/view/:id/draft`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/view/:id`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/edit/:id/draft`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/edit/:id/approved`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/view/:id/approved`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddGradeAllowanceConfiguration}
                    exact
                    path={`${props?.path}/gradeallowanceconfig/edit/:id`}
                    name="Grade-Allowance Configuration"
                    menuItems={resourceManagementItems}
                />
            </Switch>
        </div>
    );
};
export default ResourceMangementRouting;
