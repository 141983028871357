/* eslint-disable max-depth */
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../fDataTable/FDataTable.scss';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import InputComponent from '../InputComponent/InputComponent';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'appkit-react';
import Schema from '../../schema/AppSchema';
import { useMutation } from '@apollo/client';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DownloadFile } from '../../commons/Downloader';
import ConfirmationModal from '../DeleteConfirmModal/DeleteConfirmModal';
import FilterConfig from '../fDataTable/TableFilterConfig';
import { getDate, serverDate } from '../../util';
import Calendar from '../../components/Calendar/CalendarComponent';
import IsAuthorized from '../../commons/AuthorizationService';
import { InputNumber } from 'primereact/inputnumber';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { uploadClient } from '../../apollo';

const UPLOAD_FILE = Schema.singleUpload;
const CommonInlineTable = (props) => {
    const [selectedColumns, setSelectedColumns] = useState(props.columns);
    const [rows, setRows] = useState(props.rows);
    const [rowsCount, setRowsCount] = useState(10);
    const [first, setFirst] = useState(0);
    const [fileUploader, setFileUploader] = useState('');
    const [loading, setLoader] = useState(false);
    const [fileIndex, setFileIndex] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [rowDataSel, setRowData] = useState();
    const [uploadFile] = useMutation(UPLOAD_FILE, {
        client: uploadClient,
        onCompleted: (data) => {
            setLoader(false);
            if (data) {
                let editRows = rows;
                editRows[fileIndex]['fileID'] = data.singleUpload.id;
                editRows[fileIndex]['file_name'] = data.singleUpload?.input_name;
                let updateRows = [...editRows];
                setRows(updateRows);
            }
        },
        onError: () => {
            setLoader(false);
        }
    });
    const [state, setState] = useState({
        disableActions: props.disableActions,
        searchRec: '',
        selectedRows: props.selectedRows
    });
    let dt = useRef(null);

    useEffect(() => {
        setRows(props.rows);
        setSelectedColumns(props.columns);
        setState({ ...state, selectedRows: props.selectedRows });
        // eslint-disable-next-line
    }, [props]);

    useEffect(() => {
        if (!props.customSort) {
            dt.current.state.first = 0;
            if (dt?.current?.state?.filters) dt.current.state.filters = {};
        }
    }, [props.rows]);

    const formatDate = (date) => {
        let monthList = {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'June',
            6: 'July',
            7: 'Aug',
            8: 'Sep',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec'
        };
        let day = date.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        return day + ' ' + monthList[date.getMonth()] + ' ' + date.getFullYear();
    };
    const onClickRadio = (e, column, rowData) => {
        if (props.screen === 'deposit-deductions') {
            props.updateInparent(e, rowData, 'radio');
        }
    };

    const onClickDownload = async (fileName, fileID) => {
        if (fileName !== '') {
            await DownloadFile(fileID, fileName);
        }
    };
    const onClickAction = (rowData, column, action) => {
        switch (action) {
            case 'clickable':
                if (column.clickableRoute === 'popup') {
                    props.onclickable(rowData);
                } else {
                    props.history.push(column.clickableRoute + '/' + rowData['id']);
                }
                break;
            case 'remarks':
                props.onClickRemarks(rowData);
                break;
            case 'more':
                props.handleActionsInParent(rowData, column, action);
                break;
            case 'info':
                props.onClickInfo(rowData);
                break;
            case 'edit_info':
                props.handleActionsInParent(rowData, column, action);
                break;
            default:
                break;
        }
    };
    const onClickCheckBox = (e, column, rowData, index) => {
        let editRows = rows;
        editRows[index.rowIndex][column.field] = rowData[column.field] === true ? false : true;
        let updateRows = [...editRows];
        setRows(updateRows);
        if (props?.isUpdateInParent) {
            props.updateInparent(e, rowData, 'checkbox');
            return;
        } else if (props?.isUpdateInParentTable) {
            props.updateInparentTable(e, rowData, 'checkbox', column);
            return;
        }
    };
    const getValue = (rowData, col, key) => {
        let values = col[key].split('.');
        let val = rowData;
        values.forEach((a) => {
            val = val[a];
        });
        return val;
    };
    const prepareActionTemplate = (rowData, column) => {
        return (
            <div>
                {column.remarks && rowData.remarks && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        yes={() => (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/icons/remarks.svg'}
                                className="pointer m-r-10"
                                height="20px"
                                width="20px"
                                onClick={() => onClickAction(rowData, column, 'remarks')}
                                alt=""
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.more && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                        onClick={() => onClickAction(rowData, column, 'more')}
                    >
                        <span className="a-font-18 appkiticon icon-primary icon-horizontal-more-fill" title="Details" />
                    </button>
                )}
            </div>
        );
    };
    const prepareBody = (rowData, column, index) => {
        let body = undefined;
        let colVal = rowData[column.field];
        let fieldSplit = column.field?.split('.');
        let fieldValue = '';
        if (fieldSplit?.length === 2) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]];
        } else if (fieldSplit?.length === 1) {
            fieldValue = rowData?.[fieldSplit?.[0]];
        } else if (fieldSplit?.length === 3) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]]?.[fieldSplit?.[2]];
        }
        switch (column.fieldType) {
            case 'Sl-No':
                body = <div className={`${column.textCenter ? 'text-center' : 'display-flex'}`}>{index?.rowIndex + 1}</div>;
                break;
            case 'hover':
                if (column.field.indexOf('.') !== -1) {
                    colVal = getValue(rowData, column, 'field');
                }
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{colVal}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'tooltip':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{fieldValue}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'template':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">
                            {rowData[column.field]} {column.concatValue}
                        </p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'clickable':
                if (column['clickableLabel'] && !rowData[column.field])
                    body = (
                        <span
                            className={`${rowData['enableEdit'] ? 'column-label' : 'column-text'} `}
                            onClick={() => (rowData['enableEdit'] ? onClickAction(rowData, column, 'clickable') : '')}
                        >
                            {column.clickableLabel}
                        </span>
                    );
                else
                    body = (
                        <span
                            className={`${rowData['enableEdit'] ? 'column-label' : 'column-text'} `}
                            onClick={() => (rowData['enableEdit'] ? onClickAction(rowData, column, 'clickable') : '')}
                        >
                            {rowData[column.field]}
                        </span>
                    );
                break;
            case 'columnClickable':
                body = !rowData.isClickable ? (
                    <span className="column-label m-0 p-ellipsis pointer" onClick={() => onClickAction(rowData, column, 'info')}>
                        <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                    </span>
                ) : (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{fieldValue}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'radio':
                body = (
                    <div>
                        <RadioButton
                            inputId="rb2"
                            value={rowData[column.field]}
                            name="functions"
                            checked={rowData[column.field]}
                            onChange={(e) => onClickRadio(e, column, rowData)}
                        />
                    </div>
                );
                break;
            case 'switch':
                body = (
                    <div>
                        <InputSwitch
                            checked={rowData[column.field]}
                            onChange={(e) => onClickSwitch(e, column, rowData)}
                            title={rowData[column.field] ? 'Deactivate' : 'Activate'}
                            disabled={state.disableActions}
                        />
                    </div>
                );
                break;
            case 'checkbox':
                body = (
                    <div>
                        <Checkbox
                            checked={rowData[column.field]}
                            onChange={(e) => onClickCheckBox(e, column, rowData, index)}
                            disabled={
                                rows[index.rowIndex].checkboxDisable && (rows[index.rowIndex].isView || column.field !== 'is_visible')
                            }
                        ></Checkbox>
                    </div>
                );
                break;
            case 'date':
                var dateStr = rowData[column.field];
                if (dateStr === undefined || dateStr === null || dateStr === '') {
                    return '';
                }
                body = formatDate(new Date(dateStr));
                break;
            case 'input':
                if (props.mode === 'view' || rows[index.rowIndex].isEdit || rows[index.rowIndex][column.field + 'Disable']) {
                    body = <div>{rows[index.rowIndex][column.field]}</div>;
                } else {
                    body = (
                        <InputComponent
                            className="inline-input"
                            inputBoxSize="sm"
                            name={column.field}
                            type={column.inputType}
                            value={rows[index.rowIndex][column.field]}
                            onChange={(e) => onEditorValueChangeForRowEditing(index, e)}
                            placeholder={column.inputPlaceholder}
                            maxLength={column.inputMaxlength || 50}
                            alphabets={column.alphabets}
                            alphanumeric={column.alphanumeric}
                        />
                    );
                }
                break;
            case 'inputNumber':
                if (rowData.isEdit || rowData[column.field + 'Disable'] || props.mode === 'view') {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rowData[column.field]}</p>
                        </EllipsisWithTooltip>
                    );
                } else {
                    body = (
                        <>
                            {column?.inputMode === 'decimal' ? (
                                <InputNumber
                                    value={rowData[column.field]}
                                    className="inline-input"
                                    max={rowData.actual_days ? rowData.actual_days : rowData[column?.maxKey]}
                                    maxFractionDigits={2}
                                    minFractionDigits={2}
                                    mode="decimal"
                                    placeholder={column.inputPlaceholder}
                                    onChange={(e) => {
                                        onEditorValueChangeForRowEditingNumber(column, index, e.value);
                                    }}
                                />
                            ) : column?.inputMode === 'number' ? (
                                <InputNumber
                                    value={rowData[column.field]}
                                    className="inline-input"
                                    max={rowData[column?.maxKey]}
                                    placeholder={column.inputPlaceholder}
                                    onChange={(e) => {
                                        onEditorValueChangeForRowEditingNumber(column, index, e.value);
                                    }}
                                />
                            ) : (
                                <InputComponent
                                    placeholder={column.inputPlaceholder}
                                    className="inline-input"
                                    value={rowData[column.field]}
                                    maxval={rowData[column?.maxKey]}
                                    onChange={(e) => {
                                        if (
                                            isNaN(e) ||
                                            e.length > 20 ||
                                            (e.toString().split('.').length > 1 && e.toString().split('.')[1].length > 2)
                                        )
                                            return;
                                        if (Number(e) === 0) onEditorValueChangeForRowEditingNumber(column, index, '');
                                        else onEditorValueChangeForRowEditingNumber(column, index, parseFloat(e.replace(/-/g, '')));
                                    }}
                                />
                            )}
                        </>
                    );
                }
                break;
            case 'inputNumberandeditInfo':
                if (rowData.isEdit || rowData[column.field + 'Disable']) {
                    body = (
                        <div className="display-flex">
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis">{rowData[column.field]}</p>
                            </EllipsisWithTooltip>
                            {rowData[column.field] && rowData[column.field] !== '' ? (
                                <button
                                    className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                    onClick={() => onClickAction(rowData, column, 'edit_info')}
                                >
                                    <img
                                        className="pointer"
                                        title={props.formType !== 'view' && column.edit ? 'Edit' : 'Info'}
                                        src={
                                            props.formType !== 'view' && column.edit
                                                ? process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                                : process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                        }
                                        alt={props.formType !== 'view' && column.edit ? 'Edit Icon' : 'Info Icon'}
                                        height="20px"
                                    />
                                </button>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                } else {
                    body = (
                        <>
                            {column?.inputMode === 'decimal' ? (
                                <InputNumber
                                    value={rowData[column.field]}
                                    className="inline-input"
                                    max={rowData[column?.maxKey]}
                                    maxFractionDigits={2}
                                    minFractionDigits={2}
                                    mode="decimal"
                                    placeholder={column.inputPlaceholder}
                                    onChange={(e) => {
                                        onEditorValueChangeForRowEditingNumber(column, index, e.value);
                                    }}
                                />
                            ) : column?.inputMode === 'number' ? (
                                <InputNumber
                                    value={rowData[column.field]}
                                    className="inline-input"
                                    max={rowData[column?.maxKey]}
                                    placeholder={column.inputPlaceholder}
                                    onChange={(e) => {
                                        if (e.value <= rowData[column?.maxKey] && e.value > 0)
                                            onEditorValueChangeForRowEditingNumber(column, index, e.value);
                                    }}
                                />
                            ) : (
                                <InputComponent
                                    placeholder={column.inputPlaceholder}
                                    className="inline-input"
                                    value={rowData[column.field]}
                                    maxval={rowData[column?.maxKey]}
                                    onChange={(e) => {
                                        if (
                                            isNaN(e) ||
                                            e.length > 20 ||
                                            (e.toString().split('.').length > 1 && e.toString().split('.')[1].length > 2)
                                        )
                                            return;
                                        if (Number(e) === 0) onEditorValueChangeForRowEditingNumber(column, index, '');
                                        else onEditorValueChangeForRowEditingNumber(column, index, parseFloat(e.replace(/-/g, '')));
                                    }}
                                />
                            )}
                        </>
                    );
                }
                break;

            case 'rowinput':
                if (!rowData.edit || (rowData.edit && !rowData.enableEdit)) {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rowData[column.field]}</p>
                        </EllipsisWithTooltip>
                    );
                } else {
                    body = (
                        <InputComponent
                            className="inline-input"
                            inputBoxSize="sm"
                            name={column.field}
                            type={column.inputType}
                            value={`${rowData[column.field]}`}
                            onChange={(e) => props.handleRowInputChange(rowData, column, index, e)}
                            placeholder={column.inputPlaceholder}
                            maxLength={column.inputMaxlength || 50}
                        />
                    );
                }
                break;
            case 'button':
                if (column.buttonType === 'upload') {
                    body = (
                        <div className="display-flex">
                            {rows[index.rowIndex].isEdit ? (
                                <u
                                    className="pointer icon-primary"
                                    onClick={() => onClickDownload(rows[index.rowIndex][column.field], rows[index.rowIndex].fileID)}
                                >
                                    <EllipsisWithTooltip placement="auto">
                                        <p className="m-0 p-ellipsis">
                                            {column.displayValue
                                                ? rows[index.rowIndex][column.displayValue]
                                                : rows[index.rowIndex][column.field]}
                                        </p>
                                    </EllipsisWithTooltip>
                                </u>
                            ) : (
                                <div>
                                    <Button size="sm" kind="primary" className="m-r-10" onClick={() => handleUploadImage()}>
                                        {column.buttonLabel}
                                    </Button>
                                    <input
                                        type="file"
                                        id="file"
                                        ref={(file) => {
                                            setFileUploader(file);
                                        }}
                                        hidden
                                        onChange={(ev) => onBasicUploadAuto(index, ev)}
                                        onClick={(ev) => (ev.target.value = null)}
                                    />
                                    <EllipsisWithTooltip placement="auto">
                                        <p className="m-0 p-ellipsis"> {rows[index.rowIndex][column.field]}</p>
                                    </EllipsisWithTooltip>
                                </div>
                            )}
                        </div>
                    );
                } else if (column.buttonLabel) {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <span size="sm" kind="primary" className="pointer table-col-link m-0 p-ellipsis">
                                {column.buttonLabel}
                            </span>
                        </EllipsisWithTooltip>
                    );
                } else if (column.buttonType === 'popupcheck') {
                    body = (
                        <div className="display-flex">
                            {!isNaN(rowData[column.field]) ? (
                                <EllipsisWithTooltip placement="auto">
                                    <u className="pointer table-col-link m-0 p-ellipsis" onClick={() => onCheckPopup(rowData)}>
                                        {rowData[column.field]}
                                    </u>
                                </EllipsisWithTooltip>
                            ) : (
                                <EllipsisWithTooltip placement="auto">
                                    <p className="m-0 p-ellipsis"> {rowData[column.field]}</p>
                                </EllipsisWithTooltip>
                            )}
                        </div>
                    );
                } else if (column.buttonType === 'popupcheckcondition') {
                    body = (
                        <div>
                            {rowData[column?.checkField1] || rowData[column?.checkField2] ? (
                                <EllipsisWithTooltip placement="auto">
                                    <u className="pointer table-col-link m-0 p-ellipsis" onClick={() => onCheckPopup(rowData)}>
                                        {rowData[column.field]}
                                    </u>
                                </EllipsisWithTooltip>
                            ) : (
                                <EllipsisWithTooltip placement="auto">
                                    <p className="m-0 p-ellipsis"> {rowData[column.field]}</p>
                                </EllipsisWithTooltip>
                            )}
                        </div>
                    );
                } else {
                    body = (
                        <span size="sm" kind="primary" className="pointer table-col-link" onClick={() => onClickLabel(rowData)}>
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis"> {rowData[column.field]}</p>
                            </EllipsisWithTooltip>
                        </span>
                    );
                }
                break;
            case 'dropdown':
                if (rows[index.rowIndex].isEdit || rows[index.rowIndex].displayLabel) {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rows[index.rowIndex][column.field]}</p>
                        </EllipsisWithTooltip>
                    );
                } else {
                    body = (
                        <div className="">
                            <Dropdown
                                className="w-100"
                                placeholder={column.inputPlaceholder}
                                appendTo={document.body}
                                value={rows[index.rowIndex][column.field]}
                                options={column.filterOptions ? column.filterOptions : rows[index.rowIndex][column.field + 'Options']}
                                onChange={(e) => onEditorValueChangeForRowEditing(index, e.target.value)}
                            />
                        </div>
                    );
                }
                break;
            case 'dynamic':
                if (rows[index.rowIndex].isEdit || rows[index.rowIndex].displayLabel) {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rows[index.rowIndex][column.field]}</p>
                        </EllipsisWithTooltip>
                    );
                } else {
                    body = (
                        <div className="">
                            {rowData.type === 'label' && <p className="m-0 p-ellipsis">{rows[index.rowIndex][column.field]}</p>}
                            {rowData.type === 'input' && (
                                <InputComponent
                                    className="inline-input"
                                    inputBoxSize="sm"
                                    name={column.field}
                                    type={column.inputType}
                                    value={rows[index.rowIndex][column.field]}
                                    onChange={(e) => onEditorValueChangeForRowEditing(index, e)}
                                    placeholder={column.inputPlaceholder}
                                    maxLength={column.inputMaxlength || 50}
                                    alphabets={column.alphabets}
                                />
                            )}
                            {rowData.type === 'dropdown' && (
                                <Dropdown
                                    className="w-100"
                                    placeholder={column.inputPlaceholder}
                                    appendTo={document.body}
                                    value={rows[index.rowIndex][column.field]}
                                    options={column.filterOptions ? column.filterOptions : rows[index.rowIndex][column.field + 'Options']}
                                    onChange={(e) => onEditorValueChangeForRowEditing(index, e.target.value)}
                                />
                            )}
                        </div>
                    );
                }
                break;
            case 'dropdownObj':
                if (!rowData.edit || (rowData.edit && !rowData.enableEdit)) {
                    body = <div>{rows[index.rowIndex][column.field][column.optionLabel]}</div>;
                } else {
                    body = (
                        <div className="">
                            <Dropdown
                                className="w-100"
                                placeholder={column.inputPlaceholder}
                                appendTo={document.body}
                                value={rows[index.rowIndex][column.field]}
                                options={rows[index.rowIndex][column.field + 'Options']}
                                optionLabel={column.optionLabel}
                                onChange={(e) => props.handleDropdownChange(index, e.target.value, rowData, column)}
                            />
                        </div>
                    );
                }
                break;
            case 'InlineActions':
                body = (
                    <div className="display-flex">
                        {props.formType !== 'view' && !props.hideEdit && !rowData['hideEdit'] && (
                            <div>
                                {rows[index.rowIndex]['isEdit'] ? (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                        onClick={() => {
                                            setState({ ...state, existingRec: rowData });
                                            handleRowUpdate(rowData, column, index);
                                        }}
                                        disabled={props?.isNoValidation ? false : checkIsEmpty(rowData) || disableAdd()}
                                    >
                                        <img
                                            title="Edit"
                                            className={`pointer ${
                                                props?.isNoValidation ? false : checkIsEmpty(rowData) || disableAdd() ? 'disabled' : ''
                                            }`}
                                            src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                            alt="Edit Icon"
                                        />
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-l-10"
                                            onClick={() => handleRowSave(rowData, column, index, true)}
                                            disabled={checkIsEmpty(rowData) || disableUpdate()}
                                        >
                                            <span
                                                title="Save"
                                                className={`a-font-18 appkiticon icon-primary icon-check-mark-outline ${
                                                    checkIsEmpty(rowData) || disableUpdate() ? 'disabled' : ''
                                                }`}
                                            />
                                        </button>
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-l-15 m-b-2"
                                            onClick={() => handleRowSave(rowData, column, index, false)}
                                        >
                                            <span title="Cancel" className={'f-14 colour appkiticon icon-primary icon-close-fill'} />
                                        </button>
                                    </>
                                )}
                            </div>
                        )}
                        {!props.hideDel && !rowData['hideDelete'] && rowData['isEdit'] && props.formType !== 'view' && (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => handleRowDelete(rowData, index.rowIndex)}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                        {props.showMore && rows[index.rowIndex]['isEdit'] && (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-15"
                                onClick={() => onClickAction(rowData, column, 'more')}
                            >
                                <span className="a-font-18 appkiticon icon-primary icon-horizontal-more-fill" title="More Details" />
                            </button>
                        )}
                    </div>
                );
                break;
            case 'Actions':
                body = (
                    <div className="display-flex align-center justify-center">
                        {props.formType !== 'view' && rowData.edit ? (
                            <div>
                                {!rows[index.rowIndex]['enableEdit'] ? (
                                    <img
                                        title="Edit"
                                        className="pointer"
                                        src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                        alt="Edit Icon"
                                        onClick={() => props.hadleRowEdit(rowData, column, index)}
                                    />
                                ) : (
                                    <div className="row">
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-r-10"
                                            onClick={() => props.hadleRowSave(rowData, column, index)}
                                        >
                                            <span
                                                title="Save"
                                                className="a-font-18 m-t-2 colour appkiticon icon-primary icon-check-mark-fill"
                                            />
                                        </button>
                                        {props.showCancel && (
                                            <button
                                                className="a-btn-background-transparent a-icon-container"
                                                onClick={() => props.hadleRowCancel(rowData, column, index)}
                                            >
                                                <span title="Cancel" className="fs-13 appkiticon icon-primary icon-close-fill" />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                        {props.formType !== 'view' && rowData.delete ? (
                            <div className="m-l-10">
                                <button
                                    className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                    onClick={() => props.handleRowDelete(rowData, column, index)}
                                >
                                    <img
                                        className="pointer"
                                        height="20px"
                                        title="Delete"
                                        src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                        alt="Delete Icon"
                                    />
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
                break;
            case 'actionTemplate':
                body = prepareActionTemplate(rowData, column);
                break;
            default:
                break;
        }

        return body;
    };

    const handleUploadImage = () => {
        fileUploader.click();
    };

    const onClickLabel = (row) => {
        if (props.handleRowItemClick) props.handleRowItemClick(row);
    };

    const onCheckPopup = (row) => {
        if (props.handleOpenPopup) props.handleOpenPopup(row);
    };

    const onBasicUploadAuto = (values, value) => {
        value.stopPropagation();
        value.preventDefault();
        setLoader(true);
        setFileIndex(values.rowIndex);
        var file = value.target.files[0];
        if (file) {
            uploadFile({
                variables: {
                    file: file
                }
            });
        }
        let updatedRows = [...values.value];
        updatedRows[values.rowIndex][values.field] = file.name;
        setRows(updatedRows);
    };

    const onClickSwitch = (e, column, rowData) => {
        if (props.screen === 'deposit-deductions') {
            props.updateInparent(e, rowData, 'switch');
        }
    };

    const onEditorValueChangeForRowEditing = (values, value) => {
        let updatedRows = [...values.value];
        updatedRows[values.rowIndex][values.field] = value;
        if (props?.editRow) {
            props.editRow(updatedRows, values.rowIndex, values.field);
        }
        if (props?.updateTableRows) props.updateTableRows(updatedRows, values.rowIndex, values.field);
        setRows(updatedRows);
    };

    const onEditorValueChangeForRowEditingNumber = (column, values, value) => {
        let updatedRows = [...values.value];
        updatedRows[values.rowIndex][values.field] = value;
        if (props?.editRow) {
            props.editRow(updatedRows, values.rowIndex, values.field);
        }
        if (props?.updateTableRows) props.updateTableRows(updatedRows, values.rowIndex, values.field);
        setRows(updatedRows);
    };

    const handleRowSave = (rowData, column, index, save) => {
        let editRows = rows;
        if (props?.updateSingle) {
            if (state.existingRec && !save) props.updateSingle(state.existingRec, true, index.rowIndex);
            else props.updateSingle(rowData, save, index.rowIndex);
            setState({ ...state, existingRec: undefined });
            return;
        }
        if (!save) {
            editRows[index.rowIndex]['isEdit'] = true;
            let updateRows = [...editRows];
            setRows(updateRows);
            props?.updateRecord && props.updateRecord(rowData, false);
            return;
        }

        if (props?.updateRecord) {
            props.updateRecord(rowData, true);
            editRows[index.rowIndex]['isEdit'] = true;
            let updateRows = [...editRows];
            setRows(updateRows);
        } else {
            editRows[index.rowIndex]['isEdit'] = true;
            let updateRows = [...editRows];
            setRows(updateRows);
            props?.addRow && props.addRow([...updateRows], props?.id, rowData, index.rowIndex);
        }
    };

    const checkIsEmpty = (rowData) => {
        let result = false;
        for (var key in rowData) {
            if (rowData?.validation) return false;
            if (Array.isArray(rowData[key])) {
                if (rowData[key].length === 0) return true;
                continue;
            }
            if (
                key !== 'isEdit' &&
                (rowData[key] === null || (typeof rowData[key] === 'string' && rowData[key].trim() === '') || rowData[key] === undefined)
            ) {
                result = true;
            }
        }
        return result;
    };
    const handleRowUpdate = (rowData, column, index) => {
        let editRows = rows;
        editRows[index.rowIndex]['isEdit'] = false;
        let updateRows = [...editRows];
        if (props?.existRec) {
            props.existRec(rowData);
        }
        if (props?.editRow) {
            props.editRow(updateRows);
        }
        setRows(updateRows);
    };
    const setOptions = (options) => {
        let obj = {};
        let optionsArr = [];
        if (options?.length > 0 && !FilterConfig[options]) {
            options.forEach((ele) => {
                obj = { label: ele, value: ele, id: ele };
                optionsArr.push(obj);
            });
        }
        return FilterConfig[options] ? FilterConfig[options] : optionsArr;
    };

    const onSelectDate = (event, column) => {
        let dateVal = event.value ? getDate(event.value) : '';
        let val = dateVal.split(',');
        if (val.length >= 2) {
            dateVal = val[0] + ',' + val[1];
        }
        dt.current.filter(dateVal, column.field, 'contains');
        setState({ ...state, ['date' + column.field]: event.value });
    };
    const onDropDownChange = (event, column) => {
        dt.current.filter(event.value, column.field, 'equals');
        setState({ ...state, ['selected' + column.field]: event.value });
    };
    const setFilterElement = (column) => {
        let filterElement = undefined;
        switch (column.filterElement) {
            case 'dropdown':
                filterElement = (
                    <Dropdown
                        className="w-100"
                        placeholder={column.filterPlaceholder}
                        appendTo={document.body}
                        value={state['selected' + column.field]}
                        filter={true}
                        resetFilterOnHide={true}
                        filterPlaceholder="Search"
                        filterBy="label"
                        options={
                            setOptions(column.filterOptions).length > 0
                                ? setOptions(column.filterOptions)
                                : [{ label: 'No results found', value: 'No results found', disabled: true }]
                        }
                        onChange={(e) => onDropDownChange(e, column)}
                        showClear
                    />
                );
                break;
            case 'date':
                filterElement = (
                    <Calendar
                        overlayVisible
                        maxDate={serverDate()}
                        appendTo={document.self}
                        value={state['date' + column.field]}
                        showButtonBar={true}
                        placeholder={column.filterPlaceholder}
                        dateFormat="M dd, yy"
                        showIcon
                        onChange={(e) => onSelectDate(e, column)}
                    />
                );
                break;
            default:
                break;
        }
        return filterElement;
    };
    const handlefiltered = (e) => {
        if (e && e.length <= 0) {
            setState({ ...state, searchRec: 'No Results Found' });
        }
    };
    const renderColumn = (column, ind) => {
        return (
            <Column
                key={ind}
                field={column?.field}
                header={column?.header}
                selectionMode={column?.selectionMode}
                bodyStyle={{ textAlign: column?.bodyStyle }}
                sortable={column?.sortable}
                className={column.className ? column.className : null}
                filter={column?.filter}
                filterMatchMode={column?.filterMatchMode}
                filterPlaceholder={column?.filterPlaceholder}
                rowSpan={column.rowSpan}
                filterElement={column?.filterElement !== null ? setFilterElement(column) : column?.filterElement}
                body={column.fieldType ? (e, index) => prepareBody(e, column, index) : column?.body}
                style={{ width: column?.width, textAlign: column?.headerAlign, paddingLeft: column?.paddingLeft }}
            />
        );
    };
    const addNewRow = () => {
        let arr = [];
        arr.push({
            id: rows.length + 1,
            isEdit: false
        });
        setRows([...rows, ...arr]);
        props?.addRow && props.addRow([...rows, ...arr], props?.id);
    };
    const handleRowDelete = (rowData, index) => {
        if (props.noConfirmation) {
            let arr = rows;
            arr.splice(index, 1);
            setRows([...arr]);
            props?.deleteRow && props.deleteRow([...arr], index, props?.id, rowData);
            return;
        }
        setRowData({
            rowData: rowData,
            index: index
        });
        setShowDeleteModal(true);
    };
    const handleDelete = () => {
        if (props?.deleteRecord) {
            setShowDeleteModal(false);
            props.deleteRecord(rowDataSel.rowData);
            return;
        }
        let arr = rows;
        arr.splice(rowDataSel.index, 1);
        setRows([...arr]);
        props?.deleteRow && props.deleteRow([...arr], rowDataSel.index, props?.id, rowDataSel.rowData);
        setShowDeleteModal(false);
        setRowData({
            rowData: {},
            index: ''
        });
    };
    const disableAdd = () => {
        let btnDisable = false;
        rows.forEach((a) => {
            if (!a.isEdit) {
                btnDisable = true;
                return;
            }
        });
        return btnDisable;
    };

    const disableUpdate = () => {
        return props?.disableUpdate ? true : false;
    };

    const updateSelection = (e) => {
        setState({ ...state, selectedRows: e.value });
        props.updateInparent(e, e.value);
    };
    const handleCancel = () => {
        setState({
            ...state,
            showModal: false,
            selectedData: {}
        });
        setShowDeleteModal(false);
    };
    const handleSort = (sortObj) => {
        if (sortObj && sortObj.sortField) {
            if (selectedColumns.some((i) => i.customSort && i.field === sortObj.sortField)) {
                let obj = selectedColumns.find((i) => i.customSort && i.field === sortObj.sortField);
                if (obj && obj.header && sortObj.sortField === obj.field) {
                    props.handleSort(sortObj, 'custom');
                }
            } else {
                props.handleSort(sortObj);
            }
        }
        dt.current.state.first = 0;
    };
    const onPageWithoutLazyLoad = (event) => {
        setFirst(event.first);
        setRowsCount(event.rows);
    };
    const footerTop = (
        <div className="col-md-12 comment-header">
            <div className="col-md-8">Total</div>
            <div className="col-md-4">{props.totalAmount}</div>
        </div>
    );
    const footerBottom = props.rowAddButtonLabel && props.formType !== 'view' && (
        <div className="comment-header">
            <span onClick={() => addNewRow()} className={`comment-color pointer ${disableAdd() ? 'disabled' : ''}`}>
                +&nbsp;{props.rowAddButtonLabel}
            </span>
        </div>
    );
    return (
        <div className={`${props.className ? props.className : 'inline-table'} ${loading ? 'mask' : ''}`}>
            {loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className={props?.className}>
                <DataTable
                    ref={dt}
                    value={rows}
                    dataKey={props.dataKey || 'id'}
                    sortField={props.sortField}
                    sortOrder={props.sortOrder}
                    rows={!props.noPaginator ? rowsCount : null}
                    emptyMessage={state?.searchRec !== '' ? state.searchRec : props?.emptyMessage}
                    selection={state.selectedRows ? state.selectedRows : props.selectedRows}
                    onSelectionChange={(e) => updateSelection(e)}
                    selectionMode={props?.selectionMode ? props.selectionMode : null}
                    onValueChange={(e) => handlefiltered(e)}
                    resizableColumns={true}
                    onSort={props.customSort ? (e) => handleSort({ sortField: e.sortField, sortOrder: e.sortOrder }) : undefined}
                    columnResizeMode="fit"
                    reorderableColumns={true}
                    footerColumnGroup={props.footerGroup}
                    paginator={props.noPaginator ? false : props.rows && props.rows.length > 0 ? true : false}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                    rowGroupFooterTemplate={() => 'Records per page'}
                    rowsPerPageOptions={[10, 25, 50]}
                    first={first}
                    onPage={onPageWithoutLazyLoad}
                    rowGroupMode={props?.rowGroupMode ? props.rowGroupMode : ''}
                    scrollable={props.scrollable}
                >
                    {selectedColumns && selectedColumns.length > 0 && selectedColumns.map((column, ind) => renderColumn(column, ind))}
                </DataTable>
                {showDeleteModal && (
                    <ConfirmationModal
                        showModal={showDeleteModal}
                        handleCancel={handleCancel}
                        type={props.type ? props.type : 'Document'}
                        name={rowDataSel.rowData.documentName}
                        handleDelete={handleDelete}
                        showSpinner={true}
                    />
                )}
                {props.showTopFooter && footerTop}
                {footerBottom}
            </div>
        </div>
    );
};
export default CommonInlineTable;
