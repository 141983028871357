import { getUTCTime, serverDate } from '../util';

export const ApprovedRecordEntityCheckVariable = (rowData) => {
    let variables = {
        where: {
            ref: { equals: rowData?.ref },
            status: { equals: 'Draft' },
            version_no: { gt: rowData?.version_no },
            version_date: { gt: rowData?.version_date }
        }
    };
    return variables;
};

export const ApprovedRecordApproveCheckVariable = (rowData) => {
    let variables = {
        where: {
            ref: { equals: rowData?.ref },
            status: { equals: 'Draft' },
            version_no: { gt: rowData?.version_no }
        }
    };
    return variables;
};

export const getEffectiveDateWiseApprovedRecordsVariables = (recordRef, recordID) => {
    let variables = {
        take: 2,
        orderBy: { effective_from: 'desc' },
        where: {
            status: { equals: 'Approved' },
            ref: { equals: recordRef },
            OR: [{ id: { equals: recordID } }, { is_effective: { equals: true } }, { effective_from: { gt: getUTCTime(serverDate()) } }]
        }
    };
    return variables;
};

export default {
    ApprovedRecordEntityCheckVariable,
    ApprovedRecordApproveCheckVariable,
    getEffectiveDateWiseApprovedRecordsVariables
};
