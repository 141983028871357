import React from 'react';
const publicUrl = process.env.PUBLIC_URL;
export const MenuItemsSchema = {
    COA_ITEMS: [
        {
            routerLink: '/coa/segments',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/crt-sgmnt.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Segments',
            VIEW: 'COA_SEGMENT_MASTER_R',
            CREATE: 'COA_SEGMENT_MASTER_C',
            UPDATE: 'COA_SEGMENT_MASTER_U',
            DELETE: 'COA_SEGMENT_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/structures',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/structures.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Structures',
            VIEW: 'COA_STRUCTURE_MASTER_R',
            CREATE: 'COA_STRUCTURE_MASTER_C',
            UPDATE: 'COA_STRUCTURE_MASTER_U',
            DELETE: 'COA_STRUCTURE_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/codes',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/COA_Codes.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Codes',
            VIEW: 'COA_CODE_MASTER_R',
            CREATE: 'COA_CODE_MASTER_C',
            UPDATE: 'COA_CODE_MASTER_U',
            DELETE: 'COA_CODE_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/compositions',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/COA_Composition.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Compositions',
            VIEW: 'COA_COMPOSITION_MASTER_R',
            CREATE: 'COA_COMPOSITION_MASTER_C',
            UPDATE: 'COA_COMPOSITION_MASTER_U',
            DELETE: 'COA_COMPOSITION_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/mappings',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/COA_Mapping.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Mappings',
            VIEW: 'COA_MAPPING_MASTER_R',
            CREATE: 'COA_MAPPING_MASTER_C',
            UPDATE: 'COA_MAPPING_MASTER_U',
            DELETE: 'COA_MAPPING_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/reports',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Reports.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Reports',
            VIEW: 'COA_REPORT_MASTER_R',
            CREATE: 'COA_REPORT_MASTER_C',
            UPDATE: 'COA_REPORT_MASTER_U',
            DELETE: 'COA_REPORT_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/masters',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/COA_Masters.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Masters',
            VIEW: 'SYS_FIN_YEAR_MASTER_R',
            CREATE: 'SYS_FIN_YEAR_MASTER_C',
            DELETE: 'SYS_FIN_YEAR_MASTER_D',
            UPDATE: 'SYS_FIN_YEAR_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/csc',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/COASegment.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Segment Configuration',
            VIEW: 'COA_SEGMENT_CONF_MASTER_R',
            CREATE: 'COA_SEGMENT_CONF_MASTER_C',
            UPDATE: 'COA_SEGMENT_CONF_MASTER_U',
            DELETE: 'COA_SEGMENT_CONF_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/coa/cecc',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/EconomicClassif.svg'} className="m-l-15 m-r-15"></img>,
            label: 'COA Economic Classification Configuration',
            VIEW: 'COA_ECONOMIC_CODES_MASTER_R',
            CREATE: 'COA_ECONOMIC_CODES_MASTER_C',
            DELETE: 'COA_ECONOMIC_CODES_MASTER_D',
            UPDATE: 'COA_ECONOMIC_CODES_MASTER_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    USER_ITEMS: [
        {
            routerLink: '/organizationLevel',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/structures.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Organization Levels',
            VIEW: 'SYS_ORG_LEVEL_DISPLAY_R',
            CREATE: 'SYS_ORG_LEVEL_DISPLAY_C',
            DELETE: 'SYS_ORG_LEVEL_DISPLAY_D',
            UPDATE: 'SYS_ORG_LEVEL_DISPLAY_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/users',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Users.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Users',
            VIEW: 'SYS_USER_MASTER_R',
            CREATE: 'SYS_USER_MASTER_C',
            DELETE: 'SYS_USER_MASTER_D',
            UPDATE: 'SYS_USER_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/usermasters',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/User_Masters.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Roles Creation',
            VIEW: 'SYS_ROLE_MASTER_R',
            CREATE: 'SYS_ROLE_MASTER_C',
            DELETE: 'SYS_ROLE_MASTER_D',
            UPDATE: 'SYS_ROLE_MASTER_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    COMMON_MASTERS: [
        {
            routerLink: '/commonmasters/exchangecurrency',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exc_Cur_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Exchange Currency Rate Master',
            VIEW: 'SYS_EXCH_CCY_RATE_MASTER_R',
            CREATE: 'SYS_EXCH_CCY_RATE_MASTER_C',
            DELETE: 'SYS_EXCH_CCY_RATE_MASTER_D',
            UPDATE: 'SYS_EXCH_CCY_RATE_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/ifsccodemaster',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/IFSC_Masters.svg'} className="m-l-15 m-r-15"></img>,
            label: 'IFSC Code Master',
            VIEW: 'SYS_IFSC_CODE_MASTER_R',
            CREATE: 'SYS_IFSC_CODE_MASTER_C',
            DELETE: 'SYS_IFSC_CODE_MASTER_D',
            UPDATE: 'SYS_IFSC_CODE_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/deductionmaster',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Deduction_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Deduction Master',
            VIEW: 'SYS_DEDUCTION_MASTER_R',
            CREATE: 'SYS_DEDUCTION_MASTER_C',
            DELETE: 'SYS_DEDUCTION_MASTER_D',
            UPDATE: 'SYS_DEDUCTION_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/comments_config.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Comments Configuration',
            link: ['overallcomments', 'commentsconfiguration'],
            subMenu: [
                {
                    routerLink: '/commonmasters/overallcomments',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/overAll_comments.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Overall Comments',
                    VIEW: 'SYS_COMMENT_MASTER_R',
                    CREATE: 'SYS_COMMENT_MASTER_C',
                    DELETE: 'SYS_COMMENT_MASTER_D',
                    UPDATE: 'SYS_COMMENT_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/commonmasters/commentsconfiguration',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/function_specific_comments.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Function / Master Specific Comments',
                    VIEW: 'SYS_COMMENT_MASTER_R',
                    CREATE: 'SYS_COMMENT_MASTER_C',
                    DELETE: 'SYS_COMMENT_MASTER_D',
                    UPDATE: 'SYS_COMMENT_MASTER_U',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            routerLink: '/commonmasters/departmentconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Deprt_Conf_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Department Configuration',
            VIEW: 'SYS_DEPT_MASTER_R',
            CREATE: 'SYS_DEPT_MASTER_C',
            DELETE: 'SYS_DEPT_MASTER_D',
            UPDATE: 'SYS_DEPT_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/budgetconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bdgt_Ofc_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Budget Office',
            VIEW: 'SYS_BUDGET_OFFICE_R',
            CREATE: 'SYS_BUDGET_OFFICE_C',
            DELETE: 'SYS_BUDGET_OFFICE_D',
            UPDATE: 'SYS_BUDGET_OFFICE_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/deputationconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Deprt_Ofc.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Deputation Office',
            VIEW: 'SYS_DEPUTATION_OFFICE_R',
            CREATE: 'SYS_DEPUTATION_OFFICE_C',
            DELETE: 'SYS_DEPUTATION_OFFICE_D',
            UPDATE: 'SYS_DEPUTATION_OFFICE_U',
            OFFICE_CODE: 'ALL'
        },
        {
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/comments_config.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Beneficiary',
            link: ['beneficiarydetails', 'beneficiarycategory'],
            subMenu: [
                {
                    routerLink: '/commonmasters/beneficiarycategory',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Beneficiary_Master.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Beneficiary Category',
                    VIEW: 'SYS_BENEFICIARY_DETAILS_R',
                    CREATE: 'SYS_BENEFICIARY_DETAILS_C',
                    DELETE: 'SYS_BENEFICIARY_DETAILS_D',
                    UPDATE: 'SYS_BENEFICIARY_DETAILS_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/commonmasters/beneficiarydetails',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Beneficiary_Master.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Beneficiary Details',
                    VIEW: 'SYS_BENEFICIARY_DETAILS_R',
                    CREATE: 'SYS_BENEFICIARY_DETAILS_C',
                    DELETE: 'SYS_BENEFICIARY_DETAILS_D',
                    UPDATE: 'SYS_BENEFICIARY_DETAILS_U',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            label: 'General Information',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/General_Info'} className="m-l-15 m-r-15"></img>,
            link: ['defineLocalities'],
            subMenu: [
                {
                    routerLink: '/commonmasters/defineLocalities',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Define_locality.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Define Locality',
                    VIEW: 'SYS_LOCALITY_MASTER_R',
                    CREATE: 'SYS_LOCALITY_MASTER_C',
                    DELETE: 'SYS_LOCALITY_MASTER_D',
                    UPDATE: 'SYS_LOCALITY_MASTER_U',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            routerLink: '/commonmasters/documentcategory',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Beneficiary_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Document Category',
            VIEW: 'SYS_DOC_CATEGORY_R',
            CREATE: 'SYS_DOC_CATEGORY_C',
            DELETE: 'SYS_DOC_CATEGORY_D',
            UPDATE: 'SYS_DOC_CATEGORY_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/lockfacility',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Lock.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Lock Facility',
            VIEW: 'SYS_SEC_ACC_LOCK_R',
            UPDATE: 'SYS_SEC_ACC_LOCK_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/sessionexpired',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Session_Exp.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Session Expired',
            VIEW: 'SYS_SEC_SESSION_EXPIRY_R',
            UPDATE: 'SYS_SEC_SESSION_EXPIRY_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/commonmasters/passwordexpired',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Password_Exp.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Password Expired',
            VIEW: 'SYS_SEC_PWD_EXPIRY_R',
            UPDATE: 'SYS_SEC_PWD_EXPIRY_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    WF_CONFIG: [
        {
            routerLink: '/workflow/config',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/workflow.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Workflow Configuration',
            VIEW: 'WORKFLOW_CONFIG_R',
            CREATE: 'WORKFLOW_CONFIG_C',
            DELETE: 'WORKFLOW_CONFIG_D',
            UPDATE: 'WORKFLOW_CONFIG_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    GLOBAL_CONFIG: [
        {
            routerLink: '/global/globalconfiguration',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exc_Cur_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Global Configuration',
            VIEW: 'SYS_CLIENT_CONFIG_R',
            CREATE: 'SYS_CLIENT_CONFIG_C',
            DELETE: 'SYS_CLIENT_CONFIG_D',
            UPDATE: 'SYS_CLIENT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/global/scheduledjobs',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exc_Cur_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Scheduled Jobs Configuration',
            VIEW: 'EMP_PAYROLL_SCHEDULER_R',
            CREATE: 'EMP_PAYROLL_SCHEDULER_C',
            DELETE: 'EMP_PAYROLL_SCHEDULER_D',
            UPDATE: 'EMP_PAYROLL_SCHEDULER_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    CM_MASTER: [
        {
            label: 'Supplier Management',
            link: ['supplycategorymaster'],
            subMenu: [
                {
                    routerLink: '/cmm/supplycategorymaster',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/SupCatMasters.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Supply Category Master',
                    VIEW: 'SYS_SUPPLY_CTGRY_MASTER_R',
                    CREATE: 'SYS_SUPPLY_CTGRY_MASTER_C',
                    DELETE: 'SYS_SUPPLY_CTGRY_MASTER_D',
                    UPDATE: 'SYS_SUPPLY_CTGRY_MASTER_U',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            label: 'Contract Management',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Sup_Mng.svg'} className="m-l-15 m-r-15"></img>,
            link: [
                'securitydepositdeduction',
                'sanctioningMaster',
                'implementingMaster',
                'advancemaster',
                'projectMaster',
                'admissibleVariance'
            ],
            subMenu: [
                {
                    routerLink: '/cmm/securitydepositdeduction',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/SecDepDed.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Security Deposit Deduction',
                    VIEW: 'SYS_SEC_DEP_DEDUCTION_MASTER_R',
                    CREATE: 'SYS_SEC_DEP_DEDUCTION_MASTER_C',
                    DELETE: 'SYS_SEC_DEP_DEDUCTION_MASTER_D',
                    UPDATE: 'SYS_SEC_DEP_DEDUCTION_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/cmm/sanctioningMaster',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/SancAgnc.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Sanctioning Agency Master',
                    VIEW: 'SYS_SANCTION_AGENCY_MASTER_R',
                    CREATE: 'SYS_SANCTION_AGENCY_MASTER_C',
                    DELETE: 'SYS_SANCTION_AGENCY_MASTER_D',
                    UPDATE: 'SYS_SANCTION_AGENCY_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/cmm/implementingMaster',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/ImplmAgnc.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Implementing Agency Master',
                    VIEW: 'SYS_IMPLEMENTING_AGENCY_MASTER_R',
                    CREATE: 'SYS_IMPLEMENTING_AGENCY_MASTER_C',
                    DELETE: 'SYS_IMPLEMENTING_AGENCY_MASTER_D',
                    UPDATE: 'SYS_IMPLEMENTING_AGENCY_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/cmm/projectMaster',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/ProjectMaster.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Project Master',
                    VIEW: 'SYS_PROJECT_MASTER_R',
                    CREATE: 'SYS_PROJECT_MASTER_C',
                    DELETE: 'SYS_PROJECT_MASTER_D',
                    UPDATE: 'SYS_PROJECT_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/cmm/advancemaster',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/AdvMaster.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Advance Master',
                    VIEW: 'SYS_ADVANCE_MASTER_R',
                    CREATE: 'SYS_ADVANCE_MASTER_C',
                    DELETE: 'SYS_ADVANCE_MASTER_D',
                    UPDATE: 'SYS_ADVANCE_MASTER_U',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/cmm/admissibleVariance',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/AdMVar.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Admissible Variance',
                    VIEW: 'SYS_ADMISSIBLE_VARIANCE_MASTER_R',
                    CREATE: 'SYS_ADMISSIBLE_VARIANCE_MASTER_C',
                    DELETE: 'SYS_ADMISSIBLE_VARIANCE_MASTER_D',
                    UPDATE: 'SYS_ADMISSIBLE_VARIANCE_MASTER_U',
                    OFFICE_CODE: 'ALL'
                }
            ]
        }
    ],
    CNTRCT_CREATION: [
        {
            routerLink: '/cc/contracts',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Contracts',
            VIEW: 'CNTR_CONTRACT_CREATION_R',
            CREATE: 'CNTR_CONTRACT_CREATION_C',
            DELETE: 'CNTR_CONTRACT_CREATION_D',
            UPDATE: 'CNTR_CONTRACT_CREATION_U',
            OFFICE_CODE: 'sanct_agency_id'
        }
    ],
    SUPL_MNGMNT: [
        {
            routerLink: '/cm/configuresuppliercategory',
            icon: (
                <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Configure_Supplier_Category.svg'} className="m-l-15 m-r-15"></img>
            ),
            label: 'Configure Supplier Category',
            VIEW: 'CNTR_SUPPLIER_CTGRY_MASTER_R',
            CREATE: 'CNTR_SUPPLIER_CTGRY_MASTER_C',
            DELETE: 'CNTR_SUPPLIER_CTGRY_MASTER_D',
            UPDATE: 'CNTR_SUPPLIER_CTGRY_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/cm/supplierregistration',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Sup_reg.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Supplier Registration',
            VIEW: 'CNTR_SUPPLIER_REGISTRATION_R',
            CREATE: 'CNTR_SUPPLIER_REGISTRATION_C',
            DELETE: 'CNTR_SUPPLIER_REGISTRATION_D',
            UPDATE: 'CNTR_SUPPLIER_REGISTRATION_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    EXP_MASTER: [
        {
            label: 'Employee and Personnel Bills',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Other_bills.svg'} className="m-l-15 m-r-15"></img>,
            link: ['personnelbills'],
            subMenu: [
                {
                    routerLink: '/expmaster/personnelbills',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Contract_Bills.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Personnel Bills ',
                    VIEW: 'EXP_PERSONNEL_BILL_MASTER_R',
                    CREATE: 'EXP_PERSONNEL_BILL_MASTER_C',
                    UPDATE: 'EXP_PERSONNEL_BILL_MASTER_U',
                    DELETE: 'EXP_PERSONNEL_BILL_MASTER_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            routerLink: '/expmaster/contractbill',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Contract_Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Contract Bills',
            VIEW: 'EXP_CONTRACT_BILL_MASTER_R',
            CREATE: 'EXP_CONTRACT_BILL_MASTER_C',
            UPDATE: 'EXP_CONTRACT_BILL_MASTER_U',
            DELETE: 'EXP_CONTRACT_BILL_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/expmaster/otherbills',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Other_bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Other Bills',
            VIEW: 'EXP_OTHER_BILL_MASTER_R',
            CREATE: 'EXP_OTHER_BILL_MASTER_C',
            UPDATE: 'EXP_OTHER_BILL_MASTER_U',
            DELETE: 'EXP_OTHER_BILL_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/expmaster/imprestlimit',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Monthly Imprest Limit',
            VIEW: 'EXP_IMPREST_CONFIG_MASTER_R',
            CREATE: 'EXP_IMPREST_CONFIG_MASTER_C',
            UPDATE: 'EXP_IMPREST_CONFIG_MASTER_U',
            DELETE: 'EXP_IMPREST_CONFIG_MASTER_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    BILLS_MANAGEMENT: [
        {
            label: 'Employee and Personnel Bills',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Other_bills.svg'} className="m-l-15 m-r-15"></img>,
            link: ['payrollvoucher', 'personnelbills'],
            subMenu: [
                {
                    routerLink: '/expbills/payrollvoucher',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Contract_Bills.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Payroll Voucher',
                    VIEW: 'EMP_PAYROLL_VOUCHER_R',
                    CREATE: 'EMP_PAYROLL_VOUCHER_C',
                    UPDATE: 'EMP_PAYROLL_VOUCHER_U',
                    DELETE: 'EMP_PAYROLL_VOUCHER_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/expbills/personnelbills',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Contract_Bills.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Personnel Bills ',
                    VIEW: 'EXP_PERSONNEL_BILL_R',
                    CREATE: 'EXP_PERSONNEL_BILL_C',
                    UPDATE: 'EXP_PERSONNEL_BILL_U',
                    DELETE: 'EXP_PERSONNEL_BILL_D',
                    OFFICE_CODE: 'Budget Office'
                }
            ]
        },
        {
            routerLink: '/expbills/contractbill',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Contract_Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Contract Bills',
            VIEW: 'EXP_CONTRACT_BILL_CREATION_R',
            CREATE: 'EXP_CONTRACT_BILL_CREATION_C',
            UPDATE: 'EXP_CONTRACT_BILL_CREATION_U',
            DELETE: 'EXP_CONTRACT_BILL_CREATION_D',
            OFFICE_CODE: 'Sanctioning Agency'
        },
        {
            routerLink: '/expbills/otherbills',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Exp_Other_bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Other Bills',
            VIEW: 'EXP_OTHER_BILL_CREATION_R',
            CREATE: 'EXP_OTHER_BILL_CREATION_C',
            UPDATE: 'EXP_OTHER_BILL_CREATION_U',
            DELETE: 'EXP_OTHER_BILL_CREATION_D',
            OFFICE_CODE: 'Budget Office'
        },
        {
            label: 'Imprest / Petty Cash',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Imprest_Rls.svg'} className="m-l-15 m-r-15"></img>,
            link: ['imprestrelease', 'imprestadjustment'],
            subMenu: [
                {
                    routerLink: '/expbills/imprestrelease',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Imprest_Rls.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Imprest / Petty Cash Release',
                    VIEW: 'EXP_IMPREST_RELEASE_CREATION_R',
                    CREATE: 'EXP_IMPREST_RELEASE_CREATION_C',
                    UPDATE: 'EXP_IMPREST_RELEASE_CREATION_U',
                    DELETE: 'EXP_IMPREST_RELEASE_CREATION_D',
                    OFFICE_CODE: 'Budget Office'
                },
                {
                    routerLink: '/expbills/imprestadjustment',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Imprest_Cash.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Imprest / Petty Cash Adjustment',
                    VIEW: 'EXP_IMPREST_ADJ_CREATION_R',
                    CREATE: 'EXP_IMPREST_ADJ_CREATION_C',
                    UPDATE: 'EXP_IMPREST_ADJ_CREATION_U',
                    DELETE: 'EXP_IMPREST_ADJ_CREATION_D',
                    OFFICE_CODE: 'Budget Office'
                }
            ]
        }
    ],
    EMP_MASTERS: [
        {
            label: 'Employee Classification',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_classification.svg'} className="m-l-15 m-r-15"></img>,
            link: ['empclsstructure', 'empclsdetails'],
            subMenu: [
                {
                    routerLink: '/empmaster/empclsstructure',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_structure.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Structure',
                    VIEW: 'EMP_CLASSIF_STRUCTURE_R',
                    CREATE: 'EMP_CLASSIF_STRUCTURE_C',
                    UPDATE: 'EMP_CLASSIF_STRUCTURE_U',
                    DELETE: 'EMP_CLASSIF_STRUCTURE_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/empmaster/empclsdetails',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_master_details.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Details',
                    VIEW: 'EMP_CLASSIF_CODE_R',
                    CREATE: 'EMP_CLASSIF_CODE_C',
                    UPDATE: 'EMP_CLASSIF_CODE_U',
                    DELETE: 'EMP_CLASSIF_CODE_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            routerLink: '/empmaster/paycommission',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/pay_commission.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Pay Commission',
            VIEW: 'EMP_PAY_COMMISSION_MASTER_R',
            CREATE: 'EMP_PAY_COMMISSION_MASTER_C',
            UPDATE: 'EMP_PAY_COMMISSION_MASTER_U',
            DELETE: 'EMP_PAY_COMMISSION_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/empmaster/payentitlement',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/pay_entitle_master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Pay Entitlement Master',
            VIEW: 'EMP_PAY_ENTITLEMENT_MASTER_R',
            CREATE: 'EMP_PAY_ENTITLEMENT_MASTER_C',
            UPDATE: 'EMP_PAY_ENTITLEMENT_MASTER_U',
            DELETE: 'EMP_PAY_ENTITLEMENT_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/empmaster/paycomponent',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/pay_component.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Pay Component',
            VIEW: 'EMP_PAY_COMPONENT_MASTER_R',
            CREATE: 'EMP_PAY_COMPONENT_MASTER_C',
            UPDATE: 'EMP_PAY_COMPONENT_MASTER_U',
            DELETE: 'EMP_PAY_COMPONENT_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/empmaster/emppayentitlement',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_pay_entitle.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Employee Pay Entitlements',
            VIEW: 'EMP_PAY_COMPONENT_DEF_MASTER_R',
            CREATE: 'EMP_PAY_COMPONENT_DEF_MASTER_C',
            UPDATE: 'EMP_PAY_COMPONENT_DEF_MASTER_U',
            DELETE: 'EMP_PAY_COMPONENT_DEF_MASTER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/empmaster/empgroup',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_group.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Employee Group',
            VIEW: 'EMP_GROUP_MASTER_R',
            CREATE: 'EMP_GROUP_MASTER_C',
            UPDATE: 'EMP_GROUP_MASTER_U',
            DELETE: 'EMP_GROUP_MASTER_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    EMPLOYEE_MANAGEMENT: [
        {
            routerLink: window.INTERNAL_AUDIT ? '/empfunction/employee' : '/empfunction/empdetails',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Employee Details',
            VIEW: 'EMP_CREATION_R',
            CREATE: 'EMP_CREATION_C',
            UPDATE: 'EMP_CREATION_U',
            DELETE: 'EMP_CREATION_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/empfunction/payentitlementchange',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/pay_entitle_change.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Pay Entitlement Change',
            VIEW: 'EMP_PAY_ENTITLEMENT_CHANGE_R',
            CREATE: 'EMP_PAY_ENTITLEMENT_CHANGE_C',
            UPDATE: 'EMP_PAY_ENTITLEMENT_CHANGE_U',
            DELETE: 'EMP_PAY_ENTITLEMENT_CHANGE_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    REVENUE_MNGMNT: [
        {
            routerLink: '/rm/remittanceconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Remittance_Config.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Remittance Configuration',
            VIEW: 'RVN_SEG_DEF_CONFIG_R',
            CREATE: 'RVN_SEG_DEF_CONFIG_C',
            DELETE: 'RVN_SEG_DEF_CONFIG_D',
            UPDATE: 'RVN_SEG_DEF_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/rpm',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Remittance_Mapping.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Remittance Purpose Mapping',
            VIEW: 'RVN_REMITTANCE_PURPOSE_CONFIG_R',
            CREATE: 'RVN_REMITTANCE_PURPOSE_CONFIG_C',
            DELETE: 'RVN_REMITTANCE_PURPOSE_CONFIG_D',
            UPDATE: 'RVN_REMITTANCE_PURPOSE_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/elrm',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Employee_Loans.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Employee-Loans Mapping',
            VIEW: 'RVN_REMITTANCE_EMP_CONFIG_R',
            CREATE: 'RVN_REMITTANCE_EMP_CONFIG_C',
            DELETE: 'RVN_REMITTANCE_EMP_CONFIG_D',
            UPDATE: 'RVN_REMITTANCE_EMP_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/plrm',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Pension_Loans.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Pension-Loans Mapping',
            VIEW: 'RVN_REMITTANCE_PENSION_CONFIG_R',
            CREATE: 'RVN_REMITTANCE_PENSION_CONFIG_C',
            DELETE: 'RVN_REMITTANCE_PENSION_CONFIG_D',
            UPDATE: 'RVN_REMITTANCE_PENSION_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/adrm',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Contract_Advances.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Contract-Advances Mapping',
            VIEW: 'RVN_REMITTANCE_CONTRACT_CONFIG_R',
            CREATE: 'RVN_REMITTANCE_CONTRACT_CONFIG_C',
            DELETE: 'RVN_REMITTANCE_CONTRACT_CONFIG_D',
            UPDATE: 'RVN_REMITTANCE_CONTRACT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/oirm',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Office_Imprest.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Office-Imprest Mapping',
            VIEW: 'RVN_REMITTANCE_IMPREST_CONFIG_R',
            CREATE: 'RVN_REMITTANCE_IMPREST_CONFIG_C',
            DELETE: 'RVN_REMITTANCE_IMPREST_CONFIG_D',
            UPDATE: 'RVN_REMITTANCE_IMPREST_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/remittancepurpose',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Payment_Config.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Configure Payment Mode',
            VIEW: 'RVN_PAYMENT_CONFIG_R',
            CREATE: 'RVN_PAYMENT_CONFIG_C',
            DELETE: 'RVN_PAYMENT_CONFIG_D',
            UPDATE: 'RVN_PAYMENT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/crf',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Configure_Reports.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Configure Reports & Fields',
            VIEW: 'RVN_REPORT_CONFIG_R',
            CREATE: 'RVN_REPORT_CONFIG_C',
            DELETE: 'RVN_REPORT_CONFIG_D',
            UPDATE: 'RVN_REPORT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/panamountconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Pan_Amount_Config.svg'} className="m-l-15 m-r-15"></img>,
            label: 'PAN Number Amount Configuration',
            VIEW: 'RVN_PAN_AMOUNT_CONFIG_R',
            CREATE: 'RVN_PAN_AMOUNT_CONFIG_C',
            DELETE: 'RVN_PAN_AMOUNT_CONFIG_D',
            UPDATE: 'RVN_PAN_AMOUNT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rm/misreportconfig',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/config_mis_report.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Configure MIS Report',
            VIEW: 'RVN_MIS_REPORT_CONFIG_R',
            CREATE: 'RVN_MIS_REPORT_CONFIG_C',
            DELETE: 'RVN_MIS_REPORT_CONFIG_D',
            UPDATE: 'RVN_MIS_REPORT_CONFIG_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    GENERATE_CHALLAN: [
        {
            routerLink: '/rmm/dashboard',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Dashboard.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Dashboard',
            VIEW: 'GENERATE_CHALLAN_R',
            CREATE: 'GENERATE_CHALLAN_C',
            DELETE: 'GENERATE_CHALLAN_D',
            UPDATE: 'GENERATE_CHALLAN_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rmm/generatechallan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Generate_Challan.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Generate Challan',
            VIEW: 'GENERATE_CHALLAN_R',
            CREATE: 'GENERATE_CHALLAN_C',
            DELETE: 'GENERATE_CHALLAN_D',
            UPDATE: 'GENERATE_CHALLAN_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rmm/receiptentry',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Receipt_entry.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Receipt Entry',
            VIEW: 'GENERATE_CHALLAN_R',
            CREATE: 'GENERATE_CHALLAN_C',
            DELETE: 'GENERATE_CHALLAN_D',
            UPDATE: 'GENERATE_CHALLAN_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    COLLECTION_CASH_BOOK: [
        {
            routerLink: '/cb/cashDashboard',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Dashboard.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Dashboard',
            VIEW: 'RVN_DAILY_CASH_REPORT_R',
            CREATE: 'RVN_DAILY_CASH_REPORT_C',
            DELETE: 'RVN_DAILY_CASH_REPORT_D',
            UPDATE: 'RVN_DAILY_CASH_REPORT_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/cb/cashReport',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Daily_Cash_Report.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Daily Cash Report',
            VIEW: 'RVN_DAILY_CASH_REPORT_R',
            CREATE: 'RVN_DAILY_CASH_REPORT_C',
            DELETE: 'RVN_DAILY_CASH_REPORT_D',
            UPDATE: 'RVN_DAILY_CASH_REPORT_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/cb/collectorReport',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Daily_Collection_Report.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Daily Collection Report',
            VIEW: 'RVN_DAILY_COLLECTION_REPORT_R',
            CREATE: 'RVN_DAILY_COLLECTION_REPORT_C',
            DELETE: 'RVN_DAILY_COLLECTION_REPORT_D',
            UPDATE: 'RVN_DAILY_COLLECTION_REPORT_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    DEPOSIT_VOUCHER: [
        {
            routerLink: '/dv/dvDashboard',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Dashboard.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Dashboard',
            VIEW: 'RVN_DEPOSIT_VOUCHER_R',
            CREATE: 'RVN_DEPOSIT_VOUCHER_C',
            DELETE: 'RVN_DEPOSIT_VOUCHER_D',
            UPDATE: 'RVN_DEPOSIT_VOUCHER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/dv/bankdv',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bank_Deposit_Voucher.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Bank Deposit Voucher',
            VIEW: 'RVN_DEPOSIT_VOUCHER_R',
            CREATE: 'RVN_DEPOSIT_VOUCHER_C',
            DELETE: 'RVN_DEPOSIT_VOUCHER_D',
            UPDATE: 'RVN_DEPOSIT_VOUCHER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/dv/updatebank',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Update_Bank_Ref.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Update Bank Ref. No.',
            VIEW: 'RVN_BANK_REF_UPDATE_R',
            CREATE: 'RVN_BANK_REF_UPDATE_C',
            DELETE: 'RVN_BANK_REF_UPDATE_D',
            UPDATE: 'RVN_BANK_REF_UPDATE_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    REPORTS: [
        {
            routerLink: '/rmp/standardReports',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Standard_Report.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Standard Report',
            VIEW: 'RVN_STD_REPORT_R',
            CREATE: 'RVN_STD_REPORT_C',
            DELETE: 'RVN_STD_REPORT_D',
            UPDATE: 'RVN_STD_REPORT_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    NON_FMIS: [
        {
            routerLink: '/rnf/dashboard',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Dashboard.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Dashboard',
            VIEW: 'RVN_NON_FMIS_CHALLAN_R',
            CREATE: 'RVN_NON_FMIS_CHALLAN_C',
            DELETE: 'RVN_NON_FMIS_CHALLAN_D',
            UPDATE: 'RVN_NON_FMIS_CHALLAN_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/rnf/newchallan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Generate_Challan.svg'} className="m-l-15 m-r-15"></img>,
            label: 'New Challan',
            VIEW: 'RVN_NON_FMIS_CHALLAN_R',
            CREATE: 'RVN_NON_FMIS_CHALLAN_C',
            DELETE: 'RVN_NON_FMIS_CHALLAN_D',
            UPDATE: 'RVN_NON_FMIS_CHALLAN_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    MANAGE_FORMS: [
        {
            routerLink: '/formmanagement/manageforms',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Manage Forms'
        }
    ],
    PAYM_MNGMNT: [
        {
            routerLink: '/pm/bills',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Payment_Bills.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Bills',
            VIEW: 'PAYMENT_INSTRUCTION_R',
            CREATE: 'PAYMENT_INSTRUCTION_C',
            DELETE: 'PAYMENT_INSTRUCTION_D',
            UPDATE: 'PAYMENT_INSTRUCTION_U'
        },
        {
            routerLink: '/pm/pis',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Payment_Instructions.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Payment Instructions',
            VIEW: 'PAYMENT_INSTRUCTION_R',
            CREATE: 'PAYMENT_INSTRUCTION_C',
            DELETE: 'PAYMENT_INSTRUCTION_D',
            UPDATE: 'PAYMENT_INSTRUCTION_U',
            ASSIGN: 'PAYMENT_ASSIGN_CHEQUE_C',
            DOWNLOAD_C: 'PAYMENT_INSTRUCTION_DOWNLOAD_D_C',
            DOWNLOAD_U: 'PAYMENT_INSTRUCTION_DOWNLOAD_D_U',
            DOWNLOAD_R: 'PAYMENT_INSTRUCTION_DOWNLOAD_D_R',
            DOWNLOAD_D: 'PAYMENT_INSTRUCTION_DOWNLOAD_D_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pm/chequecash',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Cheque_Cash_Delivered.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Cheque / Cash Delivered',
            VIEW: 'PAYMENT_CHEQUE_DELIVERY_STATUS_R',
            CREATE: 'PAYMENT_CHEQUE_DELIVERY_STATUS_C',
            DELETE: 'PAYMENT_CHEQUE_DELIVERY_STATUS_D',
            UPDATE: 'PAYMENT_CHEQUE_DELIVERY_STATUS_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pm/recallpayment',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Recall_Payment.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Recall Payment',
            VIEW: 'RECALL_PAYMENT_R',
            CREATE: 'RECALL_PAYMENT_C',
            DELETE: 'RECALL_PAYMENT_D',
            UPDATE: 'RECALL_PAYMENT_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    CHEQUE_MNGMNT: [
        {
            routerLink: '/pmm/bankmaster',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bank_Account_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Bank Account Master ',
            VIEW: 'SYS_BANK_ACC_MASTER_R',
            CREATE: 'SYS_BANK_ACC_MASTER_C',
            DELETE: 'SYS_BANK_ACC_MASTER_D',
            UPDATE: 'SYS_BANK_ACC_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pmm/cashmaster',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Cash_Account_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Cash Account Master ',
            VIEW: 'SYS_CASH_ACC_MASTER_R',
            CREATE: 'SYS_CASH_ACC_MASTER_C',
            DELETE: 'SYS_CASH_ACC_MASTER_D',
            UPDATE: 'SYS_CASH_ACC_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pmm/chequebooks',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Cheque_Books.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Cheque Books',
            VIEW: 'PAYMENT_CHQ_BOOK_MASTER_R',
            CREATE: 'PAYMENT_CHQ_BOOK_MASTER_C',
            DELETE: 'PAYMENT_CHQ_BOOK_MASTER_D',
            UPDATE: 'PAYMENT_CHQ_BOOK_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pmm/chequeregister',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Cheque_Register.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Cheque Register',
            VIEW: 'PAYMENT_CHQ_REGISTER_R',
            CREATE: 'PAYMENT_CHQ_REGISTER_C',
            DELETE: 'PAYMENT_CHQ_REGISTER_D',
            UPDATE: 'PAYMENT_CHQ_REGISTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pmm/voidcheque',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Void_Cheque.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Void Cheque',
            VIEW: 'PAYMENT_CHQ_VOID_R',
            CREATE: 'PAYMENT_CHQ_VOID_C',
            DELETE: 'PAYMENT_CHQ_VOID_D',
            UPDATE: 'PAYMENT_CHQ_VOID_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/pmm/reversecheque',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Reverse_Cheque.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Reverse Cheque',
            VIEW: 'PAYMENT_CHQ_REVERSE_R',
            CREATE: 'PAYMENT_CHQ_REVERSE_C',
            DELETE: 'PAYMENT_CHQ_REVERSE_D',
            UPDATE: 'PAYMENT_CHQ_REVERSE_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    GL_MASTER_ITEMS: [
        {
            routerLink: '/glSetups',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/GL_Setup.svg'} className="m-l-15 m-r-15"></img>,
            label: 'GL Setup',
            VIEW: 'GL_SETUP_MASTER_R',
            CREATE: 'GL_SETUP_MASTER_C',
            DELETE: 'GL_SETUP_MASTER_D',
            UPDATE: 'GL_SETUP_MASTER_U',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/journalSources',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/JournalSources.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Journal Source',
            VIEW: 'GL_JOURNAL_SOURCE_MASTER_R',
            CREATE: 'GL_JOURNAL_SOURCE_MASTER_C',
            DELETE: 'GL_JOURNAL_SOURCE_MASTER_D',
            UPDATE: 'GL_JOURNAL_SOURCE_MASTER_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    JOURNAL_LEDGER_ITEMS: [
        {
            routerLink: '/journals',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Journals.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Journals',
            VIEW: 'GL_MANUAL_JOURNAL_R',
            CREATE: 'GL_MANUAL_JOURNAL_C',
            DELETE: 'GL_MANUAL_JOURNAL_D',
            UPDATE: 'GL_MANUAL_JOURNAL_U',
            OFFICE_CODE: 'ALL'
        }
    ],
    GLOBAL_MASTERS: [
        {
            routerLink: '/globalmaster/audithierarchy',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Hierarchy',
            VIEW: 'IA_AUDIT_HIERARCHY_R',
            CREATE: 'IA_AUDIT_HIERARCHY_C',
            UPDATE: 'IA_AUDIT_HIERARCHY_U',
            DELETE: 'IA_AUDIT_HIERARCHY_D',
            OFFICE_CODE: 'ALL'
        },
        {
            label: 'Risks and Controls Definition',
            link: ['riskdef', 'controlsdef'],
            subMenu: [
                {
                    routerLink: '/globalmaster/riskdef',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Risk_Definition.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Risk Definition',
                    VIEW: 'IA_RISK_DEFINITION_R',
                    CREATE: 'IA_RISK_DEFINITION_C',
                    UPDATE: 'IA_RISK_DEFINITION_U',
                    DELETE: 'IA_RISK_DEFINITION_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/globalmaster/controlsdef',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Control_Definition.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Controls Definition',
                    VIEW: 'IA_CONTROLS_DEFINITION_R',
                    CREATE: 'IA_CONTROLS_DEFINITION_C',
                    UPDATE: 'IA_CONTROLS_DEFINITION_U',
                    DELETE: 'IA_CONTROLS_DEFINITION_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            label: 'Risks and Controls Configuration',
            link: ['transriskandcontrols', 'transriskranking'],
            subMenu: [
                {
                    routerLink: '/globalmaster/transriskandcontrols',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Transaction_Risks_Controls.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Transaction Risks and Controls',
                    VIEW: 'IA_TXN_RISK_CNTRL_R',
                    CREATE: 'IA_TXN_RISK_CNTRL_C',
                    UPDATE: 'IA_TXN_RISK_CNTRL_U',
                    DELETE: 'IA_TXN_RISK_CNTRL_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/globalmaster/transriskranking',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Transaction_risk_Ranking.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Transaction Risk Ranking',
                    VIEW: 'IA_TXN_RISK_RANK_R',
                    CREATE: 'IA_TXN_RISK_RANK_C',
                    UPDATE: 'IA_TXN_RISK_RANK_U',
                    DELETE: 'IA_TXN_RISK_RANK_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            label: 'Audit Configuration',
            link: ['auditplanconfig', 'auditprogram'],
            subMenu: [
                {
                    routerLink: '/globalmaster/auditplanconfig',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Configuration.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Audit Plan Configuration',
                    VIEW: 'IA_AUDIT_PLAN_CONFIG_R',
                    CREATE: 'IA_AUDIT_PLAN_CONFIG_C',
                    UPDATE: 'IA_AUDIT_PLAN_CONFIG_U',
                    DELETE: 'IA_AUDIT_PLAN_CONFIG_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/globalmaster/auditprogram',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Program_Configuration.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Audit Program Configuration',
                    VIEW: 'IA_AUDIT_PROGRAM_CONFIG_R',
                    CREATE: 'IA_AUDIT_PROGRAM_CONFIG_C',
                    UPDATE: 'IA_AUDIT_PROGRAM_CONFIG_U',
                    DELETE: 'IA_AUDIT_PROGRAM_CONFIG_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        },
        {
            label: 'Others',
            link: ['auditareaandhierarchy', 'filenumber', 'guidelineauditopinion', 'followupauditconfig'],
            subMenu: [
                {
                    routerLink: '/globalmaster/auditareaandhierarchy',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Auditable_Area_Hierarchy.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Auditable Area and Hierarchy',
                    VIEW: 'IA_AUDITABLE_AREA_HIERARCHY_R',
                    CREATE: 'IA_AUDITABLE_AREA_HIERARCHY_C',
                    UPDATE: 'IA_AUDITABLE_AREA_HIERARCHY_U',
                    DELETE: 'IA_AUDITABLE_AREA_HIERARCHY_D',
                    PRIVILEGE: 'IA_AUDITABLE_AREA_HIERARCHY',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/globalmaster/filenumber',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/File_Number.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'File Number',
                    VIEW: 'IA_FILE_NUMBER_R',
                    CREATE: 'IA_FILE_NUMBER_C',
                    UPDATE: 'IA_FILE_NUMBER_U',
                    DELETE: 'IA_FILE_NUMBER_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    routerLink: '/globalmaster/guidelineauditopinion',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Guideline_Opinion.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Guideline For Audit Opinion',
                    VIEW: 'IA_GUIDELINE_AUDIT_OPINION_R',
                    CREATE: 'IA_GUIDELINE_AUDIT_OPINION_C',
                    UPDATE: 'IA_GUIDELINE_AUDIT_OPINION_U',
                    DELETE: 'IA_GUIDELINE_AUDIT_OPINION_D',
                    OFFICE_CODE: 'ALL'
                }
                /*
                 * {
                 *     routerLink: '/globalmaster/followupauditconfig',
                 *     icon: (
                 *         <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_master_details.svg'} className="m-l-15 m-r-15"></img>
                 *     ),
                 *     label: 'Follow-Up Audit Configuration',
                 *     VIEW: 'IA_FOLLOWUP_AUDIT_CONF_R',
                 *     CREATE: 'IA_FOLLOWUP_AUDIT_CONF_C',
                 *     UPDATE: 'IA_FOLLOWUP_AUDIT_CONF_U',
                 *     DELETE: 'IA_FOLLOWUP_AUDIT_CONF_D',
                 *     OFFICE_CODE: 'ALL'
                 * }
                 */
            ]
        }
    ],
    AUDIT_UNIVERSE_MANAGEMENT: [
        {
            routerLink: '/audituniversemang/stakeholders',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Sup_Mng.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Key Stakeholders',
            VIEW: 'IA_KEY_STAKE_HOLDER_R',
            CREATE: 'IA_KEY_STAKE_HOLDER_C',
            UPDATE: 'IA_KEY_STAKE_HOLDER_U',
            DELETE: 'IA_KEY_STAKE_HOLDER_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/audituniversemang/mdaprofile',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>,
            label: 'MDA Profile',
            VIEW: 'IA_MDA_PROFILE_R',
            CREATE: 'IA_MDA_PROFILE_C',
            UPDATE: 'IA_MDA_PROFILE_U',
            DELETE: 'IA_MDA_PROFILE_D',
            PRIVILEGE: 'IA_MDA_PROFILE',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/audituniversemang/auditunit',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Internal Audit Units',
            VIEW: 'IA_IAU_DIVISION_R',
            CREATE: 'IA_IAU_DIVISION_C',
            UPDATE: 'IA_IAU_DIVISION_U',
            DELETE: 'IA_IAU_DIVISION_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    RESOURCE_MANAGEMENT: [
        {
            routerLink: '/resourcemang/resourceplanconfig',
            icon: (
                <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Resource_Plan_Configuration.svg'} className="m-l-15 m-r-15"></img>
            ),
            label: 'Resource Plan Configuration',
            VIEW: 'IA_RESOURCE_PLAN_CONFIG_R',
            CREATE: 'IA_RESOURCE_PLAN_CONFIG_C',
            UPDATE: 'IA_RESOURCE_PLAN_CONFIG_U',
            DELETE: 'IA_RESOURCE_PLAN_CONFIG_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/resourcemang/gradeallowanceconfig',
            icon: (
                <img
                    alt=""
                    src={publicUrl + '/assets/icons/leftMenuIcons/Grade_Allowance_Configuration .svg'}
                    className="m-l-15 m-r-15"
                ></img>
            ),
            label: 'Grade-Allowance Configuration',
            VIEW: 'IA_LOCATION_ALLOWANCE_MAPPING_R',
            CREATE: 'IA_LOCATION_ALLOWANCE_MAPPING_C',
            UPDATE: 'IA_LOCATION_ALLOWANCE_MAPPING_U',
            DELETE: 'IA_LOCATION_ALLOWANCE_MAPPING_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/resourcemang/ratecard',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Rate_Card.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Rate Card',
            VIEW: 'IA_RATE_CARD_R',
            CREATE: 'IA_RATE_CARD_C',
            UPDATE: 'IA_RATE_CARD_U',
            DELETE: 'IA_RATE_CARD_D',
            OFFICE_CODE: 'ALL'
        },

        {
            routerLink: '/resourcemang/humanresourcesplan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bank_Account_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Human Resources Plan',
            VIEW: 'IA_HR_PLAN_R',
            CREATE: 'IA_HR_PLAN_C',
            UPDATE: 'IA_HR_PLAN_U',
            DELETE: 'IA_HR_PLAN_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    CALENDAR_BLOCKING: [
        {
            routerLink: '/calendarblock/calendarblocking',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bank_Account_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Calendar Blocking',
            VIEW: 'IA_CALENDAR_EVENT_R',
            CREATE: 'IA_CALENDAR_EVENT_C',
            UPDATE: 'IA_CALENDAR_EVENT_U',
            DELETE: 'IA_CALENDAR_EVENT_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    STRATEGIC_AUDIT_PLAN: [
        {
            routerLink: '/strategicauditplan/riskmaturityassessment',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Risk Maturity Assessment',
            VIEW: 'IA_RISK_MATURITY_ASSESSMENT_R',
            CREATE: 'IA_RISK_MATURITY_ASSESSMENT_C',
            UPDATE: 'IA_RISK_MATURITY_ASSESSMENT_U',
            DELETE: 'IA_RISK_MATURITY_ASSESSMENT_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/strategicauditplan/audiplanschedule',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Schedule.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Plan Schedule',
            VIEW: 'IA_AUDIT_PLAN_SCHEDULE_R',
            CREATE: 'IA_AUDIT_PLAN_SCHEDULE_C',
            UPDATE: 'IA_AUDIT_PLAN_SCHEDULE_U',
            DELETE: 'IA_AUDIT_PLAN_SCHEDULE_D',
            OFFICE_CODE: 'ALL'
        },

        {
            routerLink: '/strategicauditplan/auditplanstatus',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Plan Status',
            VIEW: 'IA_AUDIT_PLAN_STATUS_R',
            CREATE: 'IA_AUDIT_PLAN_STATUS_C',
            UPDATE: 'IA_AUDIT_PLAN_STATUS_U',
            DELETE: 'IA_AUDIT_PLAN_STATUS_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/strategicauditplan/riskassessment',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Risk_Assessment.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Risk Assessment',
            VIEW: 'IA_RISK_ASSESSMENT_R',
            CREATE: 'IA_RISK_ASSESSMENT_C',
            UPDATE: 'IA_RISK_ASSESSMENT_U',
            DELETE: 'IA_RISK_ASSESSMENT_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/strategicauditplan/rankedaudituniverse',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/ranked.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Ranked Audit Universe',
            VIEW: 'IA_RANKED_AUDIT_UNIVERSE_R',
            CREATE: 'IA_RANKED_AUDIT_UNIVERSE_C',
            UPDATE: 'IA_RANKED_AUDIT_UNIVERSE_U',
            DELETE: 'IA_RANKED_AUDIT_UNIVERSE_D',
            OFFICE_CODE: 'ALL'
        },
        {
            routerLink: '/strategicauditplan/strategicadtplan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Strategic Audit Plan',
            VIEW: 'IA_STRATEGIC_AUDIT_PLAN_R',
            CREATE: 'IA_STRATEGIC_AUDIT_PLAN_C',
            UPDATE: 'IA_STRATEGIC_AUDIT_PLAN_U',
            DELETE: 'IA_STRATEGIC_AUDIT_PLAN_D',
            OFFICE_CODE: 'ALL'
        }
    ],
    ANNUAL_AUDIT_PLAN: [
        {
            routerLink: '/annualauditplan/annualauditplan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Annual Audit Plan',
            VIEW: 'IA_ANNUAL_AUDIT_PLAN_R',
            CREATE: 'IA_ANNUAL_AUDIT_PLAN_C',
            UPDATE: 'IA_ANNUAL_AUDIT_PLAN_U',
            DELETE: 'IA_ANNUAL_AUDIT_PLAN_D',
            OFFICE_CODE: 'ALL'
        }
    ],

    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION: [
        {
            routerLink: '/aape/aapexecution/auditassignmentstatus',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Approved_Annual_Audit_Plan.svg'} className="m-l-15 m-r-15" />,
            label: 'Audit Assignment Status',
            VIEW: 'IA_AUDIT_ASSIGNMENT_STATUS_R',
            CREATE: 'IA_AUDIT_ASSIGNMENT_STATUS_C',
            UPDATE: 'IA_AUDIT_ASSIGNMENT_STATUS_U',
            DELETE: 'IA_AUDIT_ASSIGNMENT_STATUS_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/auditstatus',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Approved_Annual_Audit_Plan.svg'} className="m-l-15 m-r-15" />,
            label: 'Audit Status',
            VIEW: 'IA_AUDIT_ASSIGNMENT_STATUS_R',
            CREATE: 'IA_AUDIT_ASSIGNMENT_STATUS_C',
            UPDATE: 'IA_AUDIT_ASSIGNMENT_STATUS_U',
            DELETE: 'IA_AUDIT_ASSIGNMENT_STATUS_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/conflictofinterest',
            icon: (
                <img
                    alt=""
                    src={publicUrl + '/assets/icons/leftMenuIcons/Conflicts_Interest_Declaration.svg'}
                    className="m-l-15 m-r-15"
                ></img>
            ),
            label: 'Conflict of Interest Declaration',
            VIEW: 'IA_COI_DECLARATION_R',
            CREATE: 'IA_COI_DECLARATION_C',
            UPDATE: 'IA_COI_DECLARATION_U',
            DELETE: 'IA_COI_DECLARATION_D',
            OFFICE_CODE: 'ALL',
            type: [
                window.AUDIT_TYPE.SCHEDULE_AUDIT,
                window.AUDIT_TYPE.ADHOC_AUDIT,
                window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT,
                window.AUDIT_TYPE.FOLLOW_UP_AUDIT
            ]
        },
        {
            routerLink: '/aape/aapexecution/auditobservations',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Observations',
            VIEW: 'IA_AUDIT_OBSERVATIONS_R',
            CREATE: 'IA_AUDIT_OBSERVATIONS_C',
            UPDATE: 'IA_AUDIT_OBSERVATIONS_U',
            DELETE: 'IA_AUDIT_OBSERVATIONS_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        },
        {
            label: 'Initiating Audit Assignment',
            link: ['auditcommencementform', 'requestlistresponse'],
            subMenu: [
                {
                    routerLink: '/aape/aapexecution/auditcommencementform',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Commencement_Form.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Audit Resources and Schedule',
                    VIEW: 'IA_AUDIT_COMMENCEMENT_FORM_R',
                    CREATE: 'IA_AUDIT_COMMENCEMENT_FORM_C',
                    UPDATE: 'IA_AUDIT_COMMENCEMENT_FORM_U',
                    DELETE: 'IA_AUDIT_COMMENCEMENT_FORM_D',
                    OFFICE_CODE: 'ALL',
                    type: [
                        window.AUDIT_TYPE.SCHEDULE_AUDIT,
                        window.AUDIT_TYPE.FOLLOW_UP_AUDIT,
                        window.AUDIT_TYPE.ADHOC_AUDIT,
                        window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT
                    ]
                },
                {
                    routerLink: '/aape/aapexecution/requestlistresponse',
                    icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Payment_Bills.svg'} className="m-l-15 m-r-15"></img>,
                    label: 'Request List Response',
                    VIEW: 'IA_REQUEST_LIST_RESPONSE_R',
                    CREATE: 'IA_REQUEST_LIST_RESPONSE_C',
                    UPDATE: 'IA_REQUEST_LIST_RESPONSE_U',
                    DELETE: 'IA_REQUEST_LIST_RESPONSE_D',
                    OFFICE_CODE: 'ALL',
                    type: [
                        window.AUDIT_TYPE.SCHEDULE_AUDIT,
                        window.AUDIT_TYPE.ADHOC_AUDIT,
                        window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT,
                        window.AUDIT_TYPE.FOLLOW_UP_AUDIT
                    ]
                }
            ]
        },
        {
            label: 'Understanding Auditable Entity',
            link: ['summaryauditablearea', 'summaryauditsubject'],
            subMenu: [
                {
                    routerLink: '/aape/aapexecution/summaryauditablearea',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Summary Auditable Area',
                    VIEW: 'IA_SUMMARY_AUDITABLE_AREA_R',
                    CREATE: 'IA_SUMMARY_AUDITABLE_AREA_C',
                    UPDATE: 'IA_SUMMARY_AUDITABLE_AREA_U',
                    DELETE: 'IA_SUMMARY_AUDITABLE_AREA_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT]
                },
                {
                    routerLink: '/aape/aapexecution/summaryauditsubject',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Summary_Audit_Subject.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Summary Audit Subject',
                    VIEW: 'IA_SUMMARY_AUDIT_SUBJECT_R',
                    CREATE: 'IA_SUMMARY_AUDIT_SUBJECT_C',
                    UPDATE: 'IA_SUMMARY_AUDIT_SUBJECT_U',
                    DELETE: 'IA_SUMMARY_AUDIT_SUBJECT_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT]
                }
            ]
        },
        {
            label: 'Risk Assessment and Controls',
            link: ['auditscope', 'inherentrisk', 'controlassessment', 'residualriskassessment', 'riskassessmentconclusion'],
            subMenu: [
                {
                    routerLink: '/aape/aapexecution/auditscope',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Schedule.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Audit Scope',
                    VIEW: 'IA_AUDIT_SCOPE_R',
                    CREATE: 'IA_AUDIT_SCOPE_C',
                    UPDATE: 'IA_AUDIT_SCOPE_U',
                    DELETE: 'IA_AUDIT_SCOPE_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
                },
                {
                    routerLink: '/aape/aapexecution/inherentrisk',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Risk_control_Definition.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Inherent Risk',
                    VIEW: 'IA_INHERENT_RISK_R',
                    CREATE: 'IA_INHERENT_RISK_C',
                    UPDATE: 'IA_INHERENT_RISK_U',
                    DELETE: 'IA_INHERENT_RISK_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
                },
                {
                    routerLink: '/aape/aapexecution/controlassessment',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Control_Assessment.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Control Assessment',
                    VIEW: 'IA_CONTROL_ASSESSMENT_R',
                    CREATE: 'IA_CONTROL_ASSESSMENT_C',
                    UPDATE: 'IA_CONTROL_ASSESSMENT_U',
                    DELETE: 'IA_CONTROL_ASSESSMENT_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
                },
                {
                    routerLink: '/aape/aapexecution/residualriskassessment',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Residual_Risk_Assessment.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Residual Risk Assessment',
                    VIEW: 'IA_RESIDUAL_RISK_ASSESSMENT_R',
                    CREATE: 'IA_RESIDUAL_RISK_ASSESSMENT_C',
                    UPDATE: 'IA_RESIDUAL_RISK_ASSESSMENT_U',
                    DELETE: 'IA_RESIDUAL_RISK_ASSESSMENT_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
                },
                {
                    routerLink: '/aape/aapexecution/riskassessmentconclusion',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Risk Assessment_Conclusion.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Risk Assessment Conclusion',
                    VIEW: 'IA_RISK_ASSESSMENT_CONCLUSION_R',
                    CREATE: 'IA_RISK_ASSESSMENT_CONCLUSION_C',
                    UPDATE: 'IA_RISK_ASSESSMENT_CONCLUSION_U',
                    DELETE: 'IA_RISK_ASSESSMENT_CONCLUSION_D',
                    OFFICE_CODE: 'ALL',
                    type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
                }
            ]
        },

        {
            routerLink: '/aape/aapexecution/auditassignmentplan',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Assignment_Plan.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Assignment Plan',
            VIEW: 'IA_AUDIT_ASSIGNMENT_PLAN_R',
            CREATE: 'IA_AUDIT_ASSIGNMENT_PLAN_C',
            UPDATE: 'IA_AUDIT_ASSIGNMENT_PLAN_U',
            DELETE: 'IA_AUDIT_ASSIGNMENT_PLAN_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/aapauditprogram',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Program.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Program',
            VIEW: 'IA_AUDIT_PROGRAM_R',
            CREATE: 'IA_AUDIT_PROGRAM_C',
            UPDATE: 'IA_AUDIT_PROGRAM_U',
            DELETE: 'IA_AUDIT_PROGRAM_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/audittestresults',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Bdgt_Ofc_Master.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Test Results',
            VIEW: 'IA_AUDIT_TEST_RESULT_R',
            CREATE: 'IA_AUDIT_TEST_RESULT_C',
            UPDATE: 'IA_AUDIT_TEST_RESULT_U',
            DELETE: 'IA_AUDIT_TEST_RESULT_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/auditfindings',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Others.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Findings',
            VIEW: 'IA_AUDIT_FINDING_R',
            CREATE: 'IA_AUDIT_FINDING_C',
            UPDATE: 'IA_AUDIT_FINDING_U',
            DELETE: 'IA_AUDIT_FINDING_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/engagementReports',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Imprest.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Engagement Reports',
            VIEW: 'IA_ENGAGEMENT_FORM_R',
            CREATE: 'IA_ENGAGEMENT_FORM_C',
            UPDATE: 'IA_ENGAGEMENT_FORM_U',
            DELETE: 'IA_ENGAGEMENT_FORM_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/auditmeetings',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Imprest.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Meetings',
            VIEW: 'IA_AUDIT_MEETING_R',
            CREATE: 'IA_AUDIT_MEETING_C',
            UPDATE: 'IA_AUDIT_MEETING_U',
            DELETE: 'IA_AUDIT_MEETING_D',
            OFFICE_CODE: 'ALL',
            type: [
                window.AUDIT_TYPE.SCHEDULE_AUDIT,
                window.AUDIT_TYPE.ADHOC_AUDIT,
                window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT,
                window.AUDIT_TYPE.FOLLOW_UP_AUDIT
            ]
        },
        {
            routerLink: '/aape/aapexecution/reportactuals',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Cheque_Books.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Report Actuals',
            VIEW: 'IA_REPORT_ACTUAL_R',
            CREATE: 'IA_REPORT_ACTUAL_C',
            UPDATE: 'IA_REPORT_ACTUAL_U',
            DELETE: 'IA_REPORT_ACTUAL_D',
            OFFICE_CODE: 'ALL',
            REPORT_ACTUAL_VIEW: 'IA_REPORT_ACTUALS_VIEW_R',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT, window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT]
        },
        {
            routerLink: '/aape/aapexecution/auditclosureform',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Closure_Form.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Closure Form',
            VIEW: 'IA_AUDIT_CLOSURE_FORM_R',
            CREATE: 'IA_AUDIT_CLOSURE_FORM_C',
            UPDATE: 'IA_AUDIT_CLOSURE_FORM_U',
            DELETE: 'IA_AUDIT_CLOSURE_FORM_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.SCHEDULE_AUDIT, window.AUDIT_TYPE.ADHOC_AUDIT]
        },
        {
            label: 'Reports',
            link: ['generatereport', 'reports'],
            subMenu: [
                {
                    routerLink: '/aape/aapexecution/generatereport',
                    icon: (
                        <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/Audit_Plan_Status.svg'} className="m-l-15 m-r-15"></img>
                    ),
                    label: 'Generate Report',
                    VIEW: 'IA_REPORT_R',
                    CREATE: 'IA_REPORT_C',
                    UPDATE: 'IA_REPORT_U',
                    DELETE: 'IA_REPORT_D',
                    OFFICE_CODE: 'ALL',
                    type: [
                        window.AUDIT_TYPE.SCHEDULE_AUDIT,
                        window.AUDIT_TYPE.ADHOC_AUDIT,
                        window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT,
                        window.AUDIT_TYPE.FOLLOW_UP_AUDIT
                    ]
                },
                {
                    routerLink: '/aape/aapexecution/reports',
                    icon: (
                        <img
                            alt=""
                            src={publicUrl + '/assets/icons/leftMenuIcons/Summary_Audit_Subject.svg'}
                            className="m-l-15 m-r-15"
                        ></img>
                    ),
                    label: 'Report',
                    VIEW: 'IA_REPORT_R',
                    CREATE: 'IA_REPORT_C',
                    UPDATE: 'IA_REPORT_U',
                    DELETE: 'IA_REPORT_D',
                    OFFICE_CODE: 'ALL',
                    type: [
                        window.AUDIT_TYPE.SCHEDULE_AUDIT,
                        window.AUDIT_TYPE.ADHOC_AUDIT,
                        window.AUDIT_TYPE.CONSULTING_ASSIGNMENT_AUDIT,
                        window.AUDIT_TYPE.FOLLOW_UP_AUDIT
                    ]
                }
            ]
        },
        {
            routerLink: '/aape/aapexecution/audithistory',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit History',
            VIEW: 'IA_AUDIT_HISTORY_R',
            CREATE: 'IA_AUDIT_HISTORY_C',
            UPDATE: 'IA_AUDIT_HISTORY_U',
            DELETE: 'IA_AUDIT_HISTORY_D',
            OFFICE_CODE: 'ALL',
            type: [window.AUDIT_TYPE.FOLLOW_UP_AUDIT]
        }
    ],
    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER: [
        {
            routerLink: '/aape/aapexecution',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Assignment Planning and Execution',
            VIEW: 'IA_ANNUAL_AUDIT_PLAN_R',
            CREATE: 'IA_ANNUAL_AUDIT_PLAN_C',
            UPDATE: 'IA_ANNUAL_AUDIT_PLAN_U',
            DELETE: 'IA_ANNUAL_AUDIT_PLAN_D',
            OFFICE_CODE: 'ALL',
            subRoutes: [
                {
                    type: 'Administrative_Activities',
                    VIEW: 'IA_AAP_ADMIN_ACTIVITY_EXEC_R',
                    CREATE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_C',
                    UPDATE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_U',
                    DELETE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'Schedule_Audits',
                    VIEW: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_R',
                    CREATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_C',
                    UPDATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_U',
                    DELETE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'FollowUp_Audits',
                    VIEW: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_R',
                    CREATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_C',
                    UPDATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_U',
                    DELETE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'Audit_Register',
                    VIEW: 'IA_AUDIT_REGISTER_R',
                    CREATE: 'IA_AUDIT_REGISTER_C',
                    UPDATE: 'IA_AUDIT_REGISTER_U',
                    DELETE: 'IA_AUDIT_REGISTER_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        }
    ],
    AUDIT_ASSIGNMENT_PLANNING_AND_EXECUTION_TAKER_BACKUP: [
        {
            routerLink: '/aape/aapexecution',
            icon: <img alt="" src={publicUrl + '/assets/icons/leftMenuIcons/emp_details.svg'} className="m-l-15 m-r-15"></img>,
            label: 'Audit Assignment Planning and Execution',
            VIEW: 'IA_ANNUAL_AUDIT_PLAN_R',
            CREATE: 'IA_ANNUAL_AUDIT_PLAN_C',
            UPDATE: 'IA_ANNUAL_AUDIT_PLAN_U',
            DELETE: 'IA_ANNUAL_AUDIT_PLAN_D',
            OFFICE_CODE: 'ALL',
            subRoutes: [
                {
                    type: 'Administrative_Activities',
                    VIEW: 'IA_AAP_ADMIN_ACTIVITY_EXEC_R',
                    CREATE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_C',
                    UPDATE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_U',
                    DELETE: 'IA_AAP_ADMIN_ACTIVITY_EXEC_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'Schedule_Audits',
                    VIEW: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_R',
                    CREATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_C',
                    UPDATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_U',
                    DELETE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'FollowUp_Audits',
                    VIEW: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_R',
                    CREATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_C',
                    UPDATE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_U',
                    DELETE: 'IA_SCHEDULE_AAP_SUBASSIGNMENT_D',
                    OFFICE_CODE: 'ALL'
                },
                {
                    type: 'Audit_Register',
                    VIEW: 'IA_AUDIT_REGISTER_R',
                    CREATE: 'IA_AUDIT_REGISTER_C',
                    UPDATE: 'IA_AUDIT_REGISTER_U',
                    DELETE: 'IA_AUDIT_REGISTER_D',
                    OFFICE_CODE: 'ALL'
                }
            ]
        }
    ]
};
