/* eslint-disable max-nested-callbacks */
import React, { useState } from 'react';
import { Button } from 'appkit-react';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useQuery, useMutation } from '@apollo/client';
import Schema from '../../../schema/AppSchema';
import './Composition.scss';
import '../COA.scss';
import CustomTreeSelect from './CustomTreeSelect';
import { Dropdown } from 'primereact/dropdown';

const fmisFunction = [
    { label: 'Security Deposit Deduction Master' },
    { label: 'Advance Master' },
    { label: 'Manage Supplier Data' },
    { label: 'Supply Category' },
    { label: 'Deduction' },
    { label: 'Employee Creation' },
    { label: 'Employee Transfer' },
    { label: 'Employee Promotion' },
    { label: 'Employee Voluntary Retirement' },
    { label: 'Employee Suspension' },
    { label: 'EFT Payable' },
    { label: 'Cheque payable' },
    { label: 'Bank' },
    { label: 'Cash' },
    { label: 'Imprest Release' },
    { label: 'Imprest Adjustment' },
    { label: 'Other Bills' },
    { label: 'Personnel Bills' },
    { label: 'Employee Payroll' },
    { label: 'Employee Arrears' },
    { label: 'General Ledger' },
    {
        label: 'Other Retirement Bills',
        subFunctions: [{ label: 'GPF bill' }, { label: 'Gratuity Bill' }, { label: 'Leave Encashment bill' }, { label: 'Ex-gratia bill' }]
    }
];

const createCompositionQry = Schema.createMultiComposition;
const updateCompositionQry = Schema.updateOneAdmCOAComposition;
const fetchstructureAlongWithCompositions = Schema.admCoaStructureWithCompositions;
const fetchComposition = Schema.admCoaComposition;

const CreateComposition = (props) => {
    const [state, setState] = useState({
        loading: true,
        viewData: props.location.pathname.indexOf('view') !== -1,
        editData: props.location.pathname.indexOf('edit') !== -1,
        selectedFmisFunction: [],
        disableButtons: false
    });
    const [coaStructure, setCOAStructure] = useState({});
    const [existedFMIS, setExistedFMIS] = useState([]);
    const [growl, setGrowl] = useState();

    let id = '';
    let yearID = '';
    if (props.location.pathname.indexOf('create') !== -1) {
        let arr = window.location.href.split('/');
        yearID = arr[arr.length - 1];
    } else {
        let arr = window.location.href.split('/');
        id = arr[arr.length - 1];
    }

    const { loading, data: compositionData } = useQuery(fetchComposition, {
        variables: {
            where: {
                id: id
            }
        },
        skip: id === '',
        onCompleted: (data) => {
            if (data && data.admCoaComposition) {
                initializeState(data.admCoaComposition.structure, data.admCoaComposition);
            }
        },
        onError: () => {},
        fetchPolicy: 'no-cache'
    });

    const { loading: loadingStructure } = useQuery(fetchstructureAlongWithCompositions, {
        variables: {
            whereForStructure: { year_id: yearID },
            whereforCompositions: { year_id: { equals: yearID } }
        },
        skip: id !== '',
        onCompleted: (data) => {
            setState({ ...state, loading: false });
            if (data && data.admCoaStructure) {
                initializeState(data.admCoaStructure);
                setExistedFMISFunctions(data.admCoaCompositions);
            }
        },
        onError: () => {},
        fetchPolicy: 'no-cache'
    });
    const getExistedSUbFunction = (compositions, subFunction) => {
        let existedSubFunction = compositions.filter((comp) => comp.fmis_sub_function === subFunction.label);
        return existedSubFunction;
    };
    const setExistedFMISFunctions = (admCoaCompositions) => {
        let existedFunctions = [];
        fmisFunction.forEach((func) => {
            let compositions = admCoaCompositions.filter((comp) => {
                return comp.fmis_function === func.label;
            });
            if (compositions.length === 0) {
                existedFunctions.push(func);
            } else if (func.subFunctions) {
                let subFunctions = [];
                func.subFunctions.forEach((subFunction) => {
                    let existedSubFunction = getExistedSUbFunction(compositions, subFunction);
                    if (existedSubFunction.length === 0) {
                        subFunctions.push(subFunction);
                    }
                });
                if (subFunctions.length > 0) {
                    existedFunctions.push({ label: func.label, subFunctions: subFunctions });
                }
            }
        });
        setExistedFMIS(existedFunctions);
    };

    const [createMultiComposition] = useMutation(createCompositionQry, {
        onCompleted: (data) => {
            if (data) {
                setState({ ...state, loading: false });
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: `COA Composition ${state.type === 'submit' ? 'submitted' : 'saved'} successfully`
                });
                setTimeout(() => {
                    handleCancel();
                }, 1000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false, disableButtons: false });
        }
    });

    const [updateComposition] = useMutation(updateCompositionQry, {
        onCompleted: (data) => {
            if (data) {
                setState({ ...state, loading: false });
                growl.show({
                    life: 6000,
                    severity: 'success',
                    summary: `COA Composition ${state.type === 'submit' ? 'submitted' : 'saved'} successfully`
                });
                setTimeout(() => {
                    handleCancel();
                }, 1000);
            }
        },
        onError: () => {
            setState({ ...state, loading: false, disableButtons: false });
        }
    });

    const initializeState = (structure, composition) => {
        let structureTemp = { loading: false };
        let itemStatus = composition?.status;
        setCOAStructure(structure);
        structure.details.forEach((structureSegment) => {
            let segmentName = 'selected' + structureSegment.seg_def.name;
            if (composition) {
                composition.details
                    .filter((compositionSegment) => {
                        return compositionSegment.seg_def.id === structureSegment.seg_def.id;
                    })
                    .forEach((selectedSegment) => {
                        if (state.editData) structureTemp[segmentName] = selectedSegment.seg_level.id;
                        if (state.viewData) structureTemp[segmentName] = selectedSegment.seg_level.name;
                    });
            } else {
                structureTemp[segmentName] = '';
            }
        });
        if (composition) structureTemp['selectedFmisFunction'] = compositionData.admCoaComposition.fmis_function;
        setState({ ...state, ...structureTemp, status: itemStatus });
    };

    const handleCancel = () => {
        props.history.push('/coa/compositions');
    };

    const handleSave = (status, type) => {
        setState({ ...state, loading: true, type: type, disableButtons: true });
        let admCOACompDetails = [];
        coaStructure.details.forEach((structure) => {
            const segment = 'selected' + structure.seg_def.name;
            const selectedSegment = structure.seg_def.levels.filter((level) => {
                return level.id === state[segment];
            });
            admCOACompDetails.push({
                seg_def: { connect: { id: structure.seg_def.id } },
                seg_level: { connect: { id: selectedSegment[0].id } }
            });
        });
        if (state.editData) {
            updateCoaComposition(admCOACompDetails, status);
            return;
        }
        createCoaComposition(admCOACompDetails, status);
    };

    const validateFeilds = () => {
        if (
            Object.keys(state).some((key) => {
                if (key === 'selectedFmisFunction') {
                    return state[key].length === 0;
                }
                return state[key] === '';
            })
        ) {
            return false;
        }
        return true;
    };

    const updateCoaComposition = (admCOACompDetails, status) => {
        let dataDeletObj = {
            details: {
                deleteMany: { comp_id: { equals: id } }
            }
        };
        let dataObj = {
            details: { create: admCOACompDetails },
            status: { set: status }
        };
        updateComposition({
            variables: {
                where: { id: id },
                data: dataObj,
                dataDelete: dataDeletObj
            }
        });
    };

    const createCoaComposition = (admCOACompDetails, status) => {
        let queryObj = {
            year_id: coaStructure.year.id,
            structure_id: coaStructure.id,
            details: admCOACompDetails,
            status: status
        };
        setFmisFunctionValue(queryObj);
        createMultiComposition({
            variables: {
                data: queryObj
            }
        });
    };

    const setFmisFunctionValue = (queryObj) => {
        let functionList = [];
        state.selectedFmisFunction.forEach((selectedFmis) => {
            fmisFunction.forEach((fmis) => {
                if (fmis.label === selectedFmis) {
                    functionList.push({ function: selectedFmis });
                    return;
                } else if (fmis.subFunctions) {
                    selectedFmis = selectedFmis.split(fmis.label + ' - ')[1];
                    fmis.subFunctions.forEach((sub) => {
                        if (sub.label === selectedFmis) {
                            let func = functionList.filter((fmisFunc) => {
                                return fmisFunc.function === fmis.label;
                            });
                            if (func.length > 0) {
                                func[0].sub_functions.push(selectedFmis);
                            } else {
                                functionList.push({ function: fmis.label, sub_functions: [selectedFmis] });
                            }
                        }
                    });
                }
            });
        });
        queryObj['function_list'] = functionList;
    };

    function StructureDetails() {
        return (
            <div className="row description-div m-0 p-20">
                <div className="col-md-2 p-0 apply-border">
                    <p className="detail-heading">Financial Year</p>
                    <b>{coaStructure.year.name}</b>
                </div>
                <div className="col-md-4  apply-border">
                    <p className="detail-heading">COA Name</p>
                    <b>{coaStructure.name}</b>
                </div>
                <div className={compositionData ? 'col-md-4 apply-border' : 'col-md-6'}>
                    <p className="detail-heading">COA Description</p>
                    <b>{coaStructure.description}</b>
                </div>
                {compositionData && (
                    <div className="col-md-2">
                        <p className="detail-heading">Composition Status</p>
                        <b>{compositionData.admCoaComposition.status}</b>
                    </div>
                )}
            </div>
        );
    }

    const segments = () => {
        return coaStructure.details.map((segment, ind) => {
            let selectedVar = 'selected' + segment.seg_def.name;
            return (
                <div key={ind}>
                    {/* read only segments data for viewdata */}
                    {state.viewData && (
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-xs-6 col-lg-3 col-xl-3 m-t-10">
                                <b>
                                    <span className="folder-icon appkiticon icon-folder-closed-fill m-r-5 " />
                                    {segment.seg_def.name} :
                                </b>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6 col-lg-2 col-xl-3 m-t-10">
                                <p>{state[selectedVar]}</p>
                            </div>
                        </div>
                    )}
                    {/* segments dropdown for edit and create */}
                    {!state.viewData && (
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-xs-6 col-lg-3 col-xl-3 m-t-10">
                                <b>
                                    <span className="folder-icon appkiticon icon-folder-closed-fill m-r-5 " />
                                    {segment.seg_def.name} <span className="icon-primary p-l-5">*</span>
                                </b>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-6 col-lg-2 col-xl-3 m-t-10">
                                <Dropdown
                                    name={selectedVar}
                                    value={state[selectedVar]}
                                    onChange={(e) => setState({ ...state, [selectedVar]: e.value })}
                                    options={segment.seg_def.levels}
                                    disabled={!state.editData && existedFMIS.length === 0}
                                    placeholder="Select Level"
                                    validations={['required']}
                                    optionValue="id"
                                    optionLabel="name"
                                    className="w-100"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="name"
                                    appendTo={document.self}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        });
    };

    const handleStateChange = (e) => {
        setState({ ...state, selectedFmisFunction: e });
    };

    return (
        <div className={`page-body ${state.loading ? 'mask pos-rel' : 'pos-rel'}`}>
            {(loading || loadingStructure || state.loading) && (
                <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            <div className="page-header p-l-10 w-100">
                <span className="light-color">COA Composition</span>
                <span className="backBtn pointer" onClick={() => props.history.goBack()}>
                    <span className="appkiticon icon-left-chevron-outline icon_size" />
                    Back
                </span>
            </div>
            <div
                className={
                    state.status === 'Approved' && state.viewData === true ? 'page-content-ht-without-btns' : 'page-content-ht-with-btns'
                }
            >
                {coaStructure && coaStructure.details && StructureDetails()}
                {!loading && !loadingStructure && (
                    <div className="dropdowns-div p-t-20">
                        {/*  fmis function dropdown for edit and create */}
                        {!state.viewData && !state.editData && existedFMIS.length === 0 && (
                            <div className="m-l-10 display-flex m-b-20">
                                <img src={process.env.PUBLIC_URL + '/assets/icons/information.svg'} alt="info Icon" />
                                <span className="m-l-10 icon-primary font-15 info-message p_5">
                                    COA Compositions for all the FMIS Functions and Sub Functions have been defined
                                </span>
                            </div>
                        )}

                        {!state.viewData && !state.editData && (
                            <CustomTreeSelect options={existedFMIS} updateParentState={(e) => handleStateChange(e)} />
                        )}
                        {/* read only fmis function and fmis sub function for view and edit data */}
                        {(state.editData || state.viewData) && compositionData.admCoaComposition && (
                            <div className="row">
                                <div className="col-md-3 col-sm-6 col-xs-6 col-lg-2 col-xl-3">
                                    <b className="w-30">FMIS Function :</b>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-6 col-lg-2 col-xl-3">
                                    <p>{compositionData.admCoaComposition.fmis_function}</p>
                                </div>
                                {compositionData.admCoaComposition.fmis_sub_function && (
                                    <div className="col-md-6 col-sm-6 col-xs-6 col-lg-10 col-xl-6">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6">
                                                <b>FMIS Sub Function :</b>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6">
                                                <p>{compositionData.admCoaComposition.fmis_sub_function}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {coaStructure && coaStructure.details && segments()}
                    </div>
                )}
            </div>
            {/* save, submit and cancel for edit and create */}
            {!loading && !loadingStructure && !state.viewData && (
                <div className="bottom-right-btm-div m-b-10 m-r-10">
                    <Button
                        size="sm"
                        kind="primary"
                        className="m-r-5 "
                        onClick={() => handleSave('Approved', 'submit')}
                        disabled={!validateFeilds() || state.disableButtons}
                    >
                        Submit
                    </Button>
                    <Button
                        size="sm"
                        kind="primary"
                        className="m-r-5"
                        onClick={() => handleSave('Draft', 'save')}
                        disabled={!validateFeilds() || state.disableButtons}
                    >
                        Save
                    </Button>
                    <Button size="sm" kind="secondary" disabled={state.disableButtons} onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CreateComposition;
