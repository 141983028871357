import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const depositeVoucherItems = MenuItemsSchema.DEPOSIT_VOUCHER;
const DepositVoucherDashboard = lazy(() => import('../pages/revenueManagement/depositVoucher/dashboard/depositVoucherDashboard'));
const BankDepositVoucher = lazy(() => import('../pages/revenueManagement/depositVoucher/bankDepositVoucher/bankDepositVoucher'));
const UpdateBankRefNo = lazy(() => import('../pages/revenueManagement/depositVoucher/updateBankRef/updateBankRef'));
const revenueManagementBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Revenue Management', redirectTo: '/revenuemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/rmm/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const DepositVoucherRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={revenueManagementBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={DepositVoucherDashboard}
                    exact
                    path={`${props?.path}/dvDashboard`}
                    name="Deposit Voucher"
                    menuItems={depositeVoucherItems}
                />
                <AuthGaurd
                    {...props}
                    component={BankDepositVoucher}
                    exact
                    path={`${props?.path}/bankdv`}
                    name="Deposit Voucher"
                    menuItems={depositeVoucherItems}
                />
                <AuthGaurd
                    {...props}
                    component={UpdateBankRefNo}
                    exact
                    path={`${props?.path}/updatebank`}
                    name="Deposit Voucher"
                    menuItems={depositeVoucherItems}
                />
            </Switch>
        </div>
    );
};
export default DepositVoucherRouting;
