import React, { useState, useEffect } from 'react';
import { Button } from 'appkit-react';
import { TableSchema } from '../../../TableSchema';
import { Dropdown } from 'primereact/dropdown';
import InputComponent from '../../../../../components/InputComponent/InputComponent';
import SecondaryDataTable from '../../../../../components/fDataTable/SecondaryTable';
import ConfirmationModal from '../../../../../components/DeleteConfirmModal/DeleteConfirmModal';
import * as FormValidation from '../../../../../Validation';
import { uniqueId } from '../../../../../util';

const EmployeeSpecificInfo = (props) => {
    const [formState, setFormState] = useState(props.formStateObj?.EMPLOYEE_SPECIFIC_INFO);
    let viewMode = TableSchema.EMPLOYEE_SPECIFIC_INFO.filter((a) => a.fieldType !== 'actions');

    let tablecolms = props.formType !== 'view' ? TableSchema.EMPLOYEE_SPECIFIC_INFO : viewMode;
    const [state, setState] = useState({
        showForm: false,
        columns: tablecolms,
        actionType: '',
        showDeleteModal: false,
        skillsList: props.skillsList,
        proficiencyList: [1, 2, 3, 4, 5]
    });
    let emptyObj = {
        experience: '',
        proficiency: '',
        skills: ''
    };
    const [currentDetailsObj, setCurrentDetailsObj] = useState(emptyObj);
    const [editObj, setEditObj] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormState(props.formStateObj?.EMPLOYEE_SPECIFIC_INFO);
        // eslint-disable-next-line
    }, [props.formStateObj?.EMPLOYEE_SPECIFIC_INFO]);

    useEffect(() => {
        props.saveFormData('EMPLOYEE_SPECIFIC_INFO', formState);
        // eslint-disable-next-line
    }, [formState]);

    const fields = [
        { key: 'experience', validators: 'decimalNumber' },
        { key: 'overAllExp', validators: 'decimalNumber' }
    ];
    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0]?.validators, key, ev, text[0]?.pattern, text[0]?.maxLength, text[0]?.minLength);

        if (
            err[key] ||
            ((key === 'experience' || key === 'overAllExp') && parseInt(ev) <= 0) ||
            (typeof ev === 'string' && ev.trim() === '')
        ) {
            errors[key] = true;
        } else {
            delete errors[key];
        }
        if (ev === null) {
            delete errors[key];
        }
        setErrors(errors);
    };

    const handleChange = (ev, key) => {
        validateFeild(ev, key);
        setCurrentDetailsObj({
            ...currentDetailsObj,
            [key]: ev
        });
    };

    const handleFormChange = (ev, key) => {
        validateFeild(ev, key);
        setFormState({
            ...formState,
            [key]: ev
        });
    };

    const saveForm = () => {
        if (state.actionType === 'edit') {
            let indx = formState.rows.findIndex((a) => a.id === editObj.id);
            formState.rows[indx] = currentDetailsObj;
            setFormState({ ...formState, rows: formState.rows });
        } else {
            currentDetailsObj.id = uniqueId(20);
            formState.rows.push(currentDetailsObj);
            setFormState({ ...formState, rows: formState.rows });
        }
        setState({ ...state, showForm: false, actionType: '' });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    const disableSave = () => {
        if (Object.keys(errors).length > 0) return true;
        for (let key in currentDetailsObj) {
            if (
                currentDetailsObj[key] === undefined ||
                currentDetailsObj[key] === '' ||
                (currentDetailsObj[key] && typeof currentDetailsObj[key] === 'string' && currentDetailsObj[key].trim() === '')
            ) {
                return true;
            }
        }
        return false;
    };

    const handleActions = (rowData, column, sAction) => {
        if (sAction === 'edit') {
            let newObj = JSON.parse(JSON.stringify(rowData));
            setCurrentDetailsObj(newObj);
            setEditObj(newObj);
            setErrors({});
            setState({ ...state, showForm: true, actionType: sAction });
        }
        if (sAction === 'delete') {
            setCurrentDetailsObj(rowData);
            setState({ ...state, showDeleteModal: true, actionType: sAction, showForm: false });
        }
    };

    const handleDeleteRow = () => {
        let filteredRows = formState.rows.filter((a) => {
            return a.id !== currentDetailsObj.id;
        });
        setFormState({ ...formState, rows: filteredRows });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});

        handleCancel('showDeleteModal');
    };
    const handleCancel = (type) => {
        setState({ ...state, [type]: false });
    };

    const addNewRow = () => {
        setState({ ...state, showForm: true });
        setCurrentDetailsObj(emptyObj);
        setEditObj({});
    };

    return (
        <div className={'coa-card overflow-initial p-10'}>
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                <b className="fs-14">
                                    Overall Experience in Years
                                    {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                </b>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                {props.formType !== 'view' && (
                                    <InputComponent
                                        inputBoxSize="sm"
                                        name="overAllExp"
                                        type="text"
                                        value={`${formState.overAllExp}`}
                                        onChange={(e) => handleFormChange(e, 'overAllExp')}
                                        placeholder="Enter Overall Experience in Years"
                                        hasError={errors && errors.overAllExp}
                                        errMsg="Please enter valid Overall Experience"
                                        maxLength={2}
                                    />
                                )}
                                {props.formType === 'view' && (
                                    <span className="p-b-10">{formState.overAllExp ? formState.overAllExp : '–'}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <SecondaryDataTable
                    rows={formState.rows}
                    columns={state.columns}
                    noPaginator={true}
                    handleActionsInParent={handleActions}
                    emptyMessage="Employee Specific Information are yet to be created"
                    className="secondary-table"
                    scrollable
                    mode={props?.formType}
                />
                {state.showForm && (
                    <div className="m-t-10">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Skills{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="skills"
                                            value={currentDetailsObj.skills}
                                            onChange={(e) => handleChange(e.value, 'skills')}
                                            appendTo={document.self}
                                            options={state.skillsList}
                                            optionLabel="name"
                                            placeholder="Select Skills"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Proficiency{props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <Dropdown
                                            name="proficiency"
                                            value={currentDetailsObj.proficiency}
                                            onChange={(e) => handleChange(e.value, 'proficiency')}
                                            appendTo={document.self}
                                            options={state.proficiencyList}
                                            placeholder="Select Proficiency"
                                            className="w-100"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
                                        <b className="fs-14">
                                            Experience in Years
                                            {props.formType !== 'view' ? <span className="icon-primary"> *</span> : ' :'}
                                        </b>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                                        <InputComponent
                                            inputBoxSize="sm"
                                            name="experience"
                                            type="text"
                                            value={currentDetailsObj.experience}
                                            maxLength={2}
                                            onChange={(e) => handleChange(e, 'experience')}
                                            placeholder="Enter Experience in Years"
                                            hasError={errors && errors.experience}
                                            errMsg="Please enter valid Experience in Years"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <Button size="sm" disabled={disableSave()} kind="primary" className="m-r-10" onClick={() => saveForm()}>
                                {state.actionType === 'edit' ? 'Update' : 'Save'}
                            </Button>
                            <Button
                                size="sm"
                                kind="secondary"
                                onClick={() => {
                                    setState({ ...state, showForm: false, actionType: '' });
                                    setCurrentDetailsObj(emptyObj);
                                    setEditObj({});
                                    setErrors({});
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {!state.showForm && props.formType !== 'view' && (
                <div className="comment-header">
                    <span onClick={() => addNewRow()} className={'comment-color pointer'}>
                        +&nbsp;{' Add ' + (formState.rows?.length === 0 ? '' : 'Another ') + 'Employee Specific Information'}
                    </span>
                </div>
            )}
            {state.showDeleteModal && (
                <ConfirmationModal
                    handleDelete={handleDeleteRow}
                    handleCancel={() => handleCancel('showDeleteModal')}
                    type="Employee Specific Information"
                    showModal={state.showDeleteModal}
                    name={'Employee Specific Information : ' + currentDetailsObj.skills?.name}
                />
            )}
        </div>
    );
};

export default EmployeeSpecificInfo;
