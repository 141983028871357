import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const PaymentBills = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentBills'));
const PaymentInstructions = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentInstructions'));
const AddPaymentInstructions = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/AddPaymentInstructions'));
const PaymentView = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentView'));
const PaymentStatus = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentStatus'));
const PaymentStatusView = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentStatusView'));
const PaymentStatusShare = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PaymentStatusShare'));
const AssignCheques = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/AssignCheques'));
const AssignCheque = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/AssignCheque'));
const ChequeCashDeliver = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/ChequeCashDeliver'));
const RecallPayment = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/RecallPayment'));
const RecallPaymentView = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/RecallPaymentView'));
const PIVIEW = lazy(() => import('../pages/paymentManagement/generatePaymentInstructions/PIView'));

const paymentManagementBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Payment Management', redirectTo: '/paymentmanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;

    if (
        subPath[3] === 'view' ||
        subPath[3] === 'piview' ||
        subPath[3] === 'clone' ||
        subPath[3] === 'create' ||
        subPath[3] === 'edit' ||
        subPath[3] === 'assigncheques' ||
        subPath[3] === 'assigncheque'
    ) {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/pm/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};

const paymentManagementItems = MenuItemsSchema.PAYM_MNGMNT;
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const PaymentManagementRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={paymentManagementBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={PaymentBills}
                    exact
                    path={`${props?.path}/bills`}
                    name="Payment Bills"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaymentView}
                    exact
                    path={`${props?.path}/bills/view/:id`}
                    name="View Payment Bills"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaymentInstructions}
                    exact
                    path={`${props?.path}/pis`}
                    name="Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPaymentInstructions}
                    exact
                    path={`${props?.path}/pis/create`}
                    name="Create Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PIVIEW}
                    exact
                    path={`${props?.path}/pis/view/:id`}
                    name="View Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPaymentInstructions}
                    exact
                    path={`${props?.path}/pis/piview/:id`}
                    name="View Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPaymentInstructions}
                    exact
                    path={`${props?.path}/pis/edit/:id`}
                    name="Edit Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPaymentInstructions}
                    exact
                    path={`${props?.path}/pis/edit/:id/draft`}
                    name="Draft Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPaymentInstructions}
                    exact
                    path={`${props?.path}/pis/view/:id/draft`}
                    name="Draft Payment Instructions"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AssignCheques}
                    exact
                    path={`${props?.path}/pis/assigncheques`}
                    name="Assign Cheques"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={AssignCheque}
                    exact
                    path={`${props?.path}/pis/assigncheque/:id`}
                    name="Assign Cheques"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={ChequeCashDeliver}
                    exact
                    path={`${props?.path}/chequecash`}
                    name="Cheque/ Cash Deliver"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={RecallPayment}
                    exact
                    path={`${props?.path}/recallpayment`}
                    name="Recall Payment"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={RecallPaymentView}
                    exact
                    path={`${props?.path}/recallpayment/view/:id`}
                    name="View Recall Payment"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaymentStatus}
                    exact
                    path={`${props?.path}/paymentstatus`}
                    name="Payment Status"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaymentStatusView}
                    exact
                    path={`${props?.path}/paymentstatus/view/:id`}
                    name="View Payment Status"
                    menuItems={paymentManagementItems}
                />
                <AuthGaurd
                    {...props}
                    component={PaymentStatusShare}
                    exact
                    path={`${props?.path}/paymentstatus/share/:id`}
                    name="Share Payment Status"
                    menuItems={paymentManagementItems}
                />
            </Switch>
        </div>
    );
};
export default PaymentManagementRouting;
