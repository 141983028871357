import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
const GenarateChallanDashboard = lazy(() => import('../pages/revenueManagement/genarateChallanModule/dashboard/genarateChallanDashboard'));
const GenarateChallan = lazy(() => import('../pages/revenueManagement/genarateChallanModule/generateChallan/genarateChallan'));
const ReceiptEntry = lazy(() => import('../pages/revenueManagement/genarateChallanModule/receiptEntry/receiptEntry'));
const generateChallanItems = MenuItemsSchema.GENERATE_CHALLAN;
const revenueManagementBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Revenue Management', redirectTo: '/revenuemanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/rmm/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '', key: subPath[2] }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};

const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const GenerateChallanRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={revenueManagementBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={GenarateChallanDashboard}
                    exact
                    path={`${props?.path}/dashboard`}
                    name="Dashboard"
                    menuItems={generateChallanItems}
                />
                <AuthGaurd
                    {...props}
                    component={GenarateChallan}
                    exact
                    path={`${props?.path}/generatechallan`}
                    name="GenarateChallan"
                    menuItems={generateChallanItems}
                />
                <AuthGaurd
                    {...props}
                    component={GenarateChallan}
                    exact
                    path={`${props?.path}/generatechallan/view/:id`}
                    name="View Challan"
                    menuItems={generateChallanItems}
                />
                <AuthGaurd
                    {...props}
                    component={GenarateChallan}
                    exact
                    path={`${props?.path}/generatechallan/edit/:id`}
                    name="Edit Challan"
                    menuItems={generateChallanItems}
                />
                <AuthGaurd
                    {...props}
                    component={ReceiptEntry}
                    exact
                    path={`${props?.path}/receiptentry`}
                    name="ReceiptEntry"
                    menuItems={generateChallanItems}
                />
                <AuthGaurd
                    {...props}
                    component={ReceiptEntry}
                    exact
                    path={`${props?.path}/receiptentry/view/:id`}
                    name="View Receipt"
                    menuItems={generateChallanItems}
                />
            </Switch>
        </div>
    );
};
export default GenerateChallanRouting;
