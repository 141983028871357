import React, { useState } from 'react';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal';
import ConfirmationModal from '../../../components/CreateOrConfirmModal/CreateOrConfirmModal';
import { CommonTable } from '../../../components/commonTable/CommonTable';
import { ProgressSpinner } from 'primereact/progressspinner';
import TableSchema from '../../../commons/TableSchema';
import Schema from '../../../schema/AppSchema';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Toast } from 'primereact/toast';
import { DownloadFile } from '../../../commons/Downloader';
import TopButtonComponent from '../../../components/topComponents/TopButtonComponent';
import IsAuthorized from '../../../commons/AuthorizationService';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import { GetFinYear } from '../../../commons/commons';
import { serverDate } from '../../../util';

const DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
const fetchAllQuery = Schema.admCoaStructureDraftsAndStructures;
const deleteOneQuery = Schema.deleteOneAdmCOAStructureDraft;
const downloadStructureQuery = Schema.DownloadCOAStructure;
const Structure = (props) => {
    let Privileges = '';
    let loc = window.location.href.split('#');
    if (loc.length > 0) Privileges = MenuItemsSchema.COA_ITEMS.find((a) => a.routerLink === loc[1]);
    let rowsData = [];
    const [growl, setGrowl] = useState();
    let fiVal = GetFinYear();
    const [properties, setProperties] = useState({
        financialYear: { name: fiVal },
        showModal: false,
        startDate: '',
        editData: {},
        endDate: '',
        btnInfo: '',
        loading: fiVal ? true : false
    });

    // loading structures and drafts
    const {
        data: StructureData,
        loading: loadingStructure,
        refetch: refetchStructures,
        called: StructuresCall
    } = useQuery(fetchAllQuery, {
        fetchPolicy: 'no-cache',
        skip: !fiVal,
        variables: { yearname: { name: fiVal }, orderBy: { year_id: 'desc' } },
        onCompleted: () => {
            setProperties({ ...properties, loading: false });
        },
        onError: () => {
            setProperties({ ...properties, loading: false });
        }
    });

    // remove coa adm structure draft mutation
    const [removeStructure] = useMutation(deleteOneQuery, {
        onCompleted: (data) => {
            if (data) {
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_COA.DeleteStructure });
                setProperties({ ...properties, loading: false, showModal: false, editData: {} });
            }
        },
        onError: () => {
            setProperties({ ...properties, loading: false });
        }
    });
    const [structureDownload] = useLazyQuery(downloadStructureQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                DownloadFile(data.DownloadCOAStructure.id, data.DownloadCOAStructure.input_name);
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_COA.DownloadCOAStructure });
                setProperties({ ...properties, loading: false });
            }
        },
        onError: () => {
            setProperties({ ...properties, loading: false });
        }
    });
    // table list with combination of drafts and structures
    const prepareRowsData = (data) => {
        const list = [];
        data.forEach((item) => {
            list.push({
                id: item.id,
                name: item.name,
                f_year: item.year.name,
                status: item.status ? item.status : 'Approved'
            });
        });
        return list;
    };
    if (!loadingStructure) {
        if (StructureData?.admCoaStructureDrafts && StructureData.admCoaStructureDrafts.length > 0)
            rowsData = [...rowsData, ...prepareRowsData(StructureData.admCoaStructureDrafts)];
        if (StructureData?.admCoaStructures && StructureData.admCoaStructures.length > 0)
            rowsData = [...rowsData, ...prepareRowsData(StructureData.admCoaStructures)];
        if (StructureData?.admFinYear) {
            properties.financialYear = StructureData.admFinYear;
            properties.startDate = new Date(properties.financialYear.master_start_date).toLocaleDateString('en-US', DATE_OPTIONS);
            properties.endDate = new Date(properties.financialYear.master_end_date).toLocaleDateString('en-US', DATE_OPTIONS);
            properties.btnInfo =
                '( COA Structure For FY ' +
                properties.financialYear.name +
                ' can be created between ' +
                properties.startDate +
                ' to ' +
                properties.endDate +
                ')';
        } else {
            properties.financialYear = {
                name: properties.financialYear.name
            };
            properties.btnInfo =
                '( COA Structure For FY ' + (!properties.financialYear.name ? '20__' : properties.financialYear.name) + ' is not defined )';
        }
    }
    // edit for Drafted row
    const handleEdit = (rowsEditData) => {
        props.history.push('structures/edit/' + rowsEditData.id);
    };
    // delet for drafted row
    const handleDelete = async () => {
        await removeStructure({ variables: { where: { id: properties.editData.id } } });
        refetchStructures();
    };
    const confirmDelete = (rowData) => {
        setProperties({ showModal: true, editData: rowData });
    };
    const handleCancel = () => {
        setProperties({ showModal: false, editData: {} });
    };
    const downloadStructure = (rowData) => {
        let obj = { yearID: rowData.f_year };
        setProperties({ ...properties, loading: true });
        if (rowData.status === 'Draft') obj['status'] = 'Draft';
        structureDownload({ variables: obj });
    };
    // customized action template for structures and drafted
    const actionTemplate = (rowData) => {
        return (
            <div>
                {rowData.status === 'Draft' && (
                    <IsAuthorized
                        privRequired={Privileges.UPDATE}
                        officeCode={Privileges.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => handleEdit(rowData)}
                            >
                                <img
                                    title="Edit"
                                    className="pointer"
                                    src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                    alt="Edit Icon"
                                    height="20px"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status === 'Draft' && (
                    <IsAuthorized
                        privRequired={Privileges.DELETE}
                        officeCode={Privileges.OFFICE_CODE}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-15"
                                onClick={() => confirmDelete(rowData)}
                            >
                                <img
                                    className="pointer"
                                    height="20px"
                                    title="Delete"
                                    src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                    alt="Delete Icon"
                                />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {rowData.status === 'Approved' && (
                    <button
                        className="m-l-5 m-r-5 a-btn-background-transparent a-icon-container"
                        onClick={() => downloadStructure(rowData)}
                    >
                        <img
                            className="pointer"
                            height="20px"
                            title="Download"
                            src={process.env.PUBLIC_URL + '/assets/icons/Icons-Outline-Download Light.svg'}
                            alt="Download Icon"
                        />
                    </button>
                )}
            </div>
        );
    };
    const disableAddBtn = () => {
        let disableBtn = true;
        const currentDate = serverDate();
        const minDate = new Date(properties.startDate);
        const maxDate = new Date(properties.endDate);
        const isExist = rowsData.find((row) => {
            return row.f_year === properties.financialYear.name;
        });
        if (
            properties.financialYear &&
            properties.financialYear.master_start_date &&
            !isExist &&
            currentDate >= minDate &&
            currentDate <= maxDate
        ) {
            disableBtn = false;
        }
        return disableBtn;
    };
    const propsObj = {
        title: 'COA Structures',
        type: 'COA Structure',
        dataKey: 'admCoaStructures',
        topGridProps: {
            buttonLabel: 'Create COA Structure for ' + (!properties.financialYear.name ? '20__' : properties.financialYear.name),
            buttonInformation: properties.btnInfo,
            disableAdd: window?._VALIDATIONS_?.COA_STRUCTURE_DISABLE_BTN ? disableAddBtn() : false,
            createPriv: Privileges.CREATE,
            officeCode: Privileges.OFFICE_CODE
        },
        createRouter: 'structures/create',
        editRouter: 'structures/edit',
        viewRouter: 'structures/view',
        cloneRouter: 'structures/clone',
        FieldsToApplyColor: ['status'],
        customizedRows: rowsData,
        customizedActionTemplate: actionTemplate,
        disableClone: rowsData.length < 1,
        fetchAllQuery: Schema.admCoaStructures,
        createOneQuery: Schema.createOneAdmCOAStructure,
        updateOneQuery: Schema.updateOneAdmCOAStructure,
        deleteOneQuery: Schema.deleteOneAdmCOAStructure,
        columns: TableSchema.COA_STRUCTURE,
        emptyMessage: 'COA Structures are yet to be created'
    };
    const StructureHoc = CommonTable(propsObj, ConfirmationModal, TopButtonComponent);

    return (
        <div className={`${properties.loading ? 'mask' : ''}`}>
            {properties.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            {properties.showModal && (
                <DeleteConfirmModal
                    showModal={properties.showModal}
                    name={'Structure : ' + properties?.editData?.name}
                    handleCancel={handleCancel}
                    type="Structure"
                    handleDelete={handleDelete}
                    showSpinner={true}
                />
            )}
            <Toast ref={(el) => setGrowl(el)} />
            {StructuresCall && !loadingStructure && <StructureHoc />}
        </div>
    );
};
export default Structure;
