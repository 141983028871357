import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import CreatePersonnelBills from '../pages/expenditureManagement/bills-management/PersonnelBills/CreatePersonnelBills';
const billsManageItems = MenuItemsSchema.BILLS_MANAGEMENT;
const ContractBills = lazy(() => import('../pages/expenditureManagement/bills-management/contract-bills/Contractbills'));
const CreateContractBills = lazy(() => import('../pages/expenditureManagement/bills-management/contract-bills/CreateContractBill'));
const OtherBills = lazy(() => import('../pages/expenditureManagement/bills-management/other-bills/Otherbills'));
const CreateOtherBill = lazy(() => import('../pages/expenditureManagement/bills-management/other-bills/CreateOtherBill'));
const ImprestRelease = lazy(() => import('../pages/expenditureManagement/bills-management/imprest-cash/ImprestRelease'));
const ImprestAdjustment = lazy(() => import('../pages/expenditureManagement/bills-management/imprest-cash/ImprestAdjustment'));
const ImprestReleaseCreate = lazy(() => import('../pages/expenditureManagement/bills-management/imprest-cash/ImprestReleaseCreate'));
const ImprestAdjustmentCreate = lazy(() => import('../pages/expenditureManagement/bills-management/imprest-cash/ImprestAdjustmentCreate'));
const PayrollVoucher = lazy(() => import('../pages/expenditureManagement/bills-management/PayrollVoucher/PayrollVoucher'));
const AddPayrollVoucher = lazy(() => import('../pages/expenditureManagement/bills-management/PayrollVoucher/AddPayrollVoucher'));
const PersonnelBills = lazy(() => import('../pages/expenditureManagement/bills-management/PersonnelBills/PersonnelBills'));
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const expBillsBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'Expenditure Management', redirectTo: '/expendituremanagement' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (subPath[3] === 'view' || subPath[3] === 'clone' || subPath[3] === 'create' || subPath[3] === 'edit') {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/expbills/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const ExpBillsRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={expBillsBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={ContractBills}
                    exact
                    path={`${props.path}/contractbill`}
                    name="Contract Bills"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/create`}
                    name="Create Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/create/:contractId`}
                    name="Create Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/view/:id/draft`}
                    name="Create Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/edit/:id/draft`}
                    name="Create Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/view/:id`}
                    name="View Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateContractBills}
                    exact
                    path={`${props.path}/contractbill/edit/:id`}
                    name="Edit Contract Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={OtherBills}
                    exact
                    path={`${props.path}/otherbills`}
                    name="Other Bills"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props.path}/otherbills/create`}
                    name="Create Other Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props.path}/otherbills/view/:id`}
                    name="View Other Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props.path}/otherbills/edit/:id`}
                    name="Edit Other Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props.path}/otherbills/view/:id/draft`}
                    name="Edit Other Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateOtherBill}
                    exact
                    path={`${props.path}/otherbills/edit/:id/draft`}
                    name="Edit Other Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestRelease}
                    exact
                    path={`${props.path}/imprestrelease`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestReleaseCreate}
                    exact
                    path={`${props.path}/imprestrelease/create`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestReleaseCreate}
                    exact
                    path={`${props.path}/imprestrelease/edit/:id`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestReleaseCreate}
                    exact
                    path={`${props.path}/imprestrelease/view/:id`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestReleaseCreate}
                    exact
                    path={`${props.path}/imprestrelease/view/:id/draft`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestReleaseCreate}
                    exact
                    path={`${props.path}/imprestrelease/edit/:id/draft`}
                    name="Imprest / Petty Cash Release"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustment}
                    exact
                    path={`${props.path}/imprestadjustment`}
                    name="Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustmentCreate}
                    exact
                    path={`${props.path}/imprestadjustment/create`}
                    name="Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustmentCreate}
                    exact
                    path={`${props.path}/imprestadjustment/view/:id`}
                    name="Edit Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustmentCreate}
                    exact
                    path={`${props.path}/imprestadjustment/edit/:id`}
                    name="Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustmentCreate}
                    exact
                    path={`${props.path}/imprestadjustment/edit/:id/draft`}
                    name="Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={ImprestAdjustmentCreate}
                    exact
                    path={`${props.path}/imprestadjustment/view/:id/draft`}
                    name="Imprest / Petty Cash Adjustment"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={PayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher`}
                    name="Create Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher/create`}
                    name="Create Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher/edit/:id`}
                    name="Edit Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher/view/:id/draft`}
                    name="Edit Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher/edit/:id/draft`}
                    name="Edit Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddPayrollVoucher}
                    exact
                    path={`${props.path}/payrollvoucher/view/:id`}
                    name="View Payroll Voucher"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={PersonnelBills}
                    exact
                    path={`${props.path}/personnelbills`}
                    name="Create Personnel Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBills}
                    exact
                    path={`${props.path}/personnelbills/create`}
                    name="Create Personnel Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBills}
                    exact
                    path={`${props.path}/personnelbills/edit/:id`}
                    name="Edit Personnel Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBills}
                    exact
                    path={`${props.path}/personnelbills/view/:id/draft`}
                    name="Edit Personnel Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBills}
                    exact
                    path={`${props.path}/personnelbills/edit/:id/draft`}
                    name="Edit Personnel Bill"
                    menuItems={billsManageItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreatePersonnelBills}
                    exact
                    path={`${props.path}/personnelbills/view/:id`}
                    name="View Personnel Bill"
                    menuItems={billsManageItems}
                />
            </Switch>
        </div>
    );
};
export default ExpBillsRouting;
