export const TableSchema = {
    ADHierarchy: [
        {
            header: 'Audit Hierarchy Details ID',
            field: 'ref',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Hierarchy Details ID',
            width: '150px'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Search by Last Update Date & Time',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ],
    AAHierarchy: [
        {
            header: 'Auditable Area Number',
            field: 'ref',
            fieldType: 'clickable',
            customView: true,
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Auditable Area Number',
            width: '150px'
        },
        {
            header: 'Internal Audit Unit',
            field: 'mda_profile_val',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Internal Audit Unit',
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Auditable Area',
            field: 'auditable_area_name',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Auditable Area',
            width: '160px',
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Search by Last Update Date & Time',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ],
    Audit_Objectives: [
        {
            header: 'Audit Objective Number',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by AO No.',
            width: '180px',
            sortable: true
        },
        {
            header: 'Audit Objective',
            field: 'objective',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Objective',
            width: '180px',
            sortable: true
        },
        {
            header: 'Audit Domain',
            field: 'domain',
            filter: true,
            filterElement: 'search',
            fieldType: 'tooltip',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Domain',
            width: '180px',
            sortableDisabled: true,
            sortable: true
        },
        {
            header: 'Audit Subject',
            field: 'subject',
            filter: true,
            filterElement: 'search',
            fieldType: 'tooltip',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Subject',
            width: '180px',
            sortableDisabled: true,
            sortable: true
        },
        {
            header: 'Audit Classification',
            field: 'audit_classification',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Classification',
            width: '180px',
            sortable: true,
            fieldType: 'abbreviation'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        /*
         * {
         *  header: 'Details',
         *  field: 'details',
         *  filter: true,
         *  filterElement: null,
         *  filterMatchMode: 'contains',
         *  filterPlaceholder: 'Search by Details',
         *  width: '180px',
         *  sortable: true
         * },
         */
        {
            header: 'Active / InActive',
            field: 'is_active',
            width: '140px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],
    Audit_Procedures_Master: [
        {
            header: 'AP No.',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by AP No.',
            width: '180px',
            sortable: true
        },
        {
            header: 'Audit Procedure',
            field: 'name',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Procedure',
            fieldType: 'viewDetails',
            width: '180px',
            sortable: true
        },
        {
            header: 'Controls',
            field: 'controls',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            fieldType: 'tooltip',
            filterPlaceholder: 'Search by Controls',
            width: '180px',
            sortableDisabled: true,
            sortable: true
        },
        {
            header: 'No of Tests',
            field: 'testLength',
            width: '180px',
            sortableDisabled: true,
            sortable: true
        },
        // {
        //     header: 'Details',
        //     field: 'procedure',
        //     filter: false,
        //     fieldType: 'button',
        //     buttonType: 'label',
        //     buttonLabel: 'View',
        //     sortable: false,
        //     width: '150px'
        // },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],
    Audit_Master_Add_Controls: [
        {
            header: '',
            field: 'checked',
            fieldType: 'checkbox',
            selectionMode: 'multiple',
            width: '55px'
        },
        {
            header: 'Risk No.',
            filterPlaceholder: 'Search by Risk No',
            field: 'risk.ref',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk',
            filterPlaceholder: 'Search by Risk',
            field: 'risk.name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control No',
            filterPlaceholder: 'Search by Control No',
            field: 'control_def.ref',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control ',
            filterPlaceholder: 'Search by Control  ',
            field: 'control_def.name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    Audit_Master_Add_Taks: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '40px'
        },
        {
            header: 'Audit Test',
            field: 'test',
            width: '100px',
            fieldType: 'tooltip'
        },
        {
            header: '',
            fieldType: 'actions',
            edit: true,
            delete: true,
            width: '80px'
        }
    ],
    AUDIT_OBJECTIVE_RISK: [
        {
            header: 'Risk Number',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk No.',
            width: '180px',
            sortable: true
        },
        {
            header: 'Risk Name',
            field: 'riskName',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Name',
            width: '180px',
            sortable: true
        }
    ],
    RISKS: [
        {
            header: 'Risk No.',
            filterPlaceholder: 'Search by Risk No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Name',
            filterPlaceholder: 'Search by Risk Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Description',
            filterPlaceholder: 'Search by Risk Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Category',
            filterPlaceholder: 'Search by Risk Category ',
            field: 'category',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Actions',
            field: 'actions',
            width: '150px'
        }
    ],
    CONTROLS: [
        {
            header: 'Control No.',
            filterPlaceholder: 'Search by Control No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Name',
            filterPlaceholder: 'Search by Control Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Description',
            filterPlaceholder: 'Search by Control Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Type of Control',
            filterPlaceholder: 'Search by Type of Control',
            field: 'control_type',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Actions',
            field: 'actions',
            width: '150px',
            body: ''
        }
    ],
    CONTROLS_DEF: [
        {
            header: 'Control No.',
            filterPlaceholder: 'Search by Control No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Name',
            filterPlaceholder: 'Search by Control Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Description',
            filterPlaceholder: 'Search by Control Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Type of Control',
            filterPlaceholder: 'Search by Type of Control',
            field: 'control_type',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Actions',
            field: 'actions',
            width: '150px',
            body: ''
        }
    ],
    RISKS_AND_CONTROLS_DEF: [
        {
            header: '',
            expander: true,
            field: 'expander',
            width: '50px',
            bodyStyle: 'center'
        },
        {
            header: 'Control No.',
            filterPlaceholder: 'Search by Control No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Name',
            filterPlaceholder: 'Search by Control Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Description',
            filterPlaceholder: 'Search by Control Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Type of Control',
            filterPlaceholder: 'Search by Type of Control',
            field: 'control_type',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Actions',
            field: 'actions',
            width: '150px',
            body: ''
        }
    ],
    RISKS_DEF: [
        {
            header: '',
            expander: true,
            field: 'expander',
            width: '50px',
            bodyStyle: 'center'
        },
        {
            header: 'Risk No.',
            filterPlaceholder: 'Search by Risk No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Name',
            filterPlaceholder: 'Search by Risk Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Description',
            filterPlaceholder: 'Search by Risk Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Category',
            filterPlaceholder: 'Search by Risk Category',
            field: 'category',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Actions',
            field: 'actions',
            width: '150px',
            body: ''
        }
    ],
    RISKS_INSIDE_CONTROL: [
        {
            header: 'Risk No.',
            filterPlaceholder: 'Search by Risk No',
            field: 'ref',
            width: '150px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Name',
            filterPlaceholder: 'Search by Risk Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Description',
            filterPlaceholder: 'Search by Risk Description',
            field: 'description',
            width: '300px',
            fieldType: 'tooltip'
        },
        {
            header: 'Risk Category',
            filterPlaceholder: 'Search by Risk Category ',
            field: 'category',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '200px',
            fieldType: 'tooltip'
        }
    ],
    CONTROLS_INSIDE_RISK: [
        {
            header: 'Control No.',
            filterPlaceholder: 'Search by Control No',
            field: 'ref',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Name',
            filterPlaceholder: 'Search by Control Name',
            field: 'name',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Description',
            filterPlaceholder: 'Search by Control Description',
            field: 'description',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Control Category',
            filterPlaceholder: 'Search by Control Category ',
            field: 'category',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Is Active',
            filterPlaceholder: 'Search by Is Active ',
            field: 'isActive',
            width: '100px',
            fieldType: 'tooltip'
        }
    ],
    Risk_Likelihood: [
        {
            header: 'Risk Score',
            field: 'risk_score',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Score',
            width: '180px',
            sortable: true
        },
        {
            header: 'Descriptor',
            field: 'descriptor',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Descriptor',
            width: '180px',
            sortable: true
        },
        {
            header: 'Narration',
            field: 'narration',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Narration',
            fieldType: 'tooltip',
            width: '230px',
            sortable: true
        }
    ],
    Risk_Impact: [
        {
            header: 'Impact Score',
            field: 'score',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Impact Score',
            width: '180px',
            sortable: true
        },
        {
            header: 'Descriptor',
            field: 'descriptor',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Descriptor',
            width: '180px',
            sortable: true
        },
        {
            header: 'Risk Consequences Category',
            field: 'category',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Consequences Category',
            width: '180px',
            sortable: true
        },
        {
            header: 'Narration',
            field: 'narration',
            filter: true,
            filterElement: 'dropdown',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Narration',
            width: '230px',
            fieldType: 'tooltip',
            sortable: true
        }
    ],
    Risk_Definition: [
        {
            header: 'Risk Number',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Number',
            width: '180px',
            sortable: true
        },
        {
            header: 'Risk Name',
            field: 'name',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Name',
            width: '180px',
            sortable: true
        },
        {
            header: 'Risk Description',
            field: 'description',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Description',
            width: '180px',
            sortable: true
        },
        {
            header: 'Risk Category',
            field: 'category',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Risk Category',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            filterMatchMode: 'contains',
            sortableDisabled: true,
            width: '180px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],
    Trns_Risk_and_Definition: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '50px'
        },
        {
            header: 'Version',
            field: 'name',
            width: '180px'
        }
        /*
         * {
         *     header: 'Applicability (In Years) - From',
         *     field: 'from_year',
         *     filterElement: null,
         *     filterPlaceholder: 'Applicable From',
         *     filterMatchMode: 'contains',
         *     filter: true,
         *     width: '100px'
         * },
         * {
         *     header: 'Applicability (In Years) - To',
         *     field: 'to_year',
         *     filterElement: null,
         *     filterPlaceholder: 'Applicable To',
         *     filterMatchMode: 'contains',
         *     filter: true,
         *     width: '100px'
         * },
         * {
         *     header: 'Actions',
         *     field: 'Actions',
         *     width: '120px',
         *     alignTo: 'left',
         *     bodyStyle: 'left',
         *     body: ''
         * }
         */
    ],
    Controls_Definition: [
        {
            header: 'Control Number',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Control Number',
            width: '180px',
            sortable: true
        },
        {
            header: 'Control Name',
            field: 'name',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Control Name',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Control Description',
            field: 'description',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Control Description',
            width: '180px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Type of Control',
            field: 'control_type',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Type of Control',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filterElement: 'dropdown',
            filterOptions: [],
            filterMatchMode: 'contains',
            filter: true,
            filterPlaceholder: 'Select Workflow Status',
            width: '255px',
            sortable: true
        },
        {
            header: 'Last Update Date & Time',
            fieldType: 'date',
            field: 'version_date_local',
            filter: true,
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '250px'
        },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],

    ControlScore_Configuration: [
        {
            header: 'Slab No.',
            field: 'slab_no',
            filterElement: null,
            width: '100px'
        },
        {
            header: 'Starting Range',
            field: 'start_range',
            filterElement: null,
            width: '100px'
        },
        {
            header: 'Ending Range',
            field: 'end_range',
            filterElement: null,
            fieldType: 'tooltip',
            width: '100px'
        },
        {
            header: 'Descriptor',
            field: 'descriptor',
            filterElement: null,
            fieldType: 'tooltip',
            width: '180px'
        },
        {
            header: 'Interpretation of Score',
            field: 'interpretation',
            filterElement: null,
            fieldType: 'tooltip',
            width: '250px'
        },
        {
            header: 'Testing Required',
            field: 'testing_required',
            filterElement: null,
            width: '120px'
        }
    ],
    Risk_Eval_Grid: [
        {
            header: 'Sl. No',
            field: 'slno',
            fieldType: 'Sl-No',
            width: '80px'
        },
        {
            header: 'Risk Impact',
            field: 'risk_imapct.score',
            width: '100px'
        },
        {
            header: 'Risk Likelihood',
            field: 'risk_likelihood.risk_score',
            width: '150px'
        },
        {
            header: 'Risk Score',
            field: 'risk_score',
            width: '80px'
        },
        {
            header: 'Descriptor',
            field: 'descriptor',
            width: '100px'
        },
        {
            header: 'Colour',
            field: 'colour_code',
            width: '100px'
        },
        {
            header: 'Interpretation and Required Action',
            field: 'interpretation_req_action',
            width: '250px',
            fieldType: 'tooltip'
        }
    ],
    AUDIT_GUIDELINE: [
        {
            header: 'Slab No.',
            field: 'slab_no',
            field_type: 'tooltip',
            width: '80px',
            bodyStyle: 'center'
        },
        {
            header: 'Starting %',
            field: 'txn_fail_percent_start',
            field_type: 'tooltip',
            width: '120px',
            bodyStyle: 'center'
        },
        {
            header: 'Ending %',
            field: 'txn_fail_percent_end',
            field_type: 'tooltip',
            width: '120px',
            bodyStyle: 'center'
        },
        {
            header: 'Conclusion/Opinion',
            field: 'conclusion_opinion',
            fieldType: 'tooltip',
            width: '220px',
            bodyStyle: 'left'
        },
        {
            header: 'Description',
            field: 'description',
            fieldType: 'tooltip',
            width: '220px',
            bodyStyle: 'left'
        },
        {
            header: 'Actions',
            field: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '80px'
        }
    ],

    AUDIT_OBJECTIVE_GUILDELINE: [
        {
            header: 'Audit Objective No.',
            field: 'ref',
            field_type: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Objective No.',
            filterOptions: [],
            width: '150px',
            sortable: true
        },
        {
            header: 'Audit Domain',
            field: 'domain',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Domain',
            filterOptions: [],
            width: '150px'
        },
        {
            header: 'Audit Subject',
            field: 'subject',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Subject',
            filterOptions: [],
            width: '150px'
        },
        {
            header: 'Audit Objective',
            field: 'objective',
            fieldType: 'tooltip',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Objective',
            filterOptions: [],
            width: '150px'
        },
        {
            header: 'No. of slabs',
            field: 'no_of_slabs',
            filter: true,
            filterElement: 'search',
            filterPlaceholder: 'Search by No. of slabs',
            filterOptions: [],
            field_type: 'tooltip',
            width: '80px',
            numericInput: true
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '100px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_str_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '150px'
        },
        {
            header: 'Active / InActive',
            field: 'is_active',
            width: '140px'
        },
        {
            header: '',
            field: 'actions',
            filter: false,
            filterPlaceholder: '',
            filterElement: null,
            body: '',
            width: '100px'
        }
    ],
    Objective_Procedure_Mapping: [
        {
            header: 'Audit Objective No.',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Objective No.',
            width: '180px',
            sortable: true
        },
        {
            header: 'Audit Domain',
            field: 'domain',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Domain',
            width: '250px',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true
        },
        {
            header: 'Audit Subject',
            field: 'subject',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Subject',
            width: '250px',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true
        },
        {
            header: 'Audit Classification',
            field: 'classification',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Classification',
            width: '250px',
            sortable: true,
            fieldType: 'abbreviation'
        },
        {
            header: 'Audit Objective',
            field: 'objective',
            filter: true,
            filterElement: 'search',
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Objective ID',
            width: '280px',
            sortable: true,
            fieldType: 'tooltip',
            sortableDisabled: true
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            width: '200px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'version_date_local',
            filter: true,
            fieldType: 'date',
            filterPlaceholder: 'Search by Last Update Date & Time',
            filterElement: 'date',
            filterMatchMode: 'contains',
            sortable: true,
            width: '200px'
        },
        /*
         * {
         *  header: 'Details',
         *  field: 'details',
         *  filter: true,
         *  filterElement: null,
         *  filterMatchMode: 'contains',
         *  filterPlaceholder: 'Search by Details',
         *  width: '180px',
         *  sortable: true
         * },
         */
        // {
        //     header: 'Active / InActive',
        //     field: 'is_active',
        //     width: '140px',
        //     fieldType: 'tooltip'
        // },
        {
            header: '',
            field: 'Actions',
            width: '120px',
            alignTo: 'left',
            bodyStyle: 'left',
            body: ''
        }
    ],
    AUDIT_PROCEDURE_TESTS: [
        {
            header: 'Test No.',
            field: 'ref',
            fieldType: 'tooltip',
            width: '200px'
        },
        {
            header: 'Test',
            field: 'test',
            fieldType: 'tooltip',
            width: '200px'
        }
    ],
    IA_UNIT: [
        {
            header: 'Auditable Area Code',
            field: 'auditable_area_code',
            filter: false,
            filterElement: 'search',
            sortable: false,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Auditable Area Code',
            width: '200px',
            fieldType: 'tooltip'
        },
        {
            header: 'Auditable Area',
            field: 'auditable_area',
            filter: false,
            filterElement: 'search',
            sortable: false,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Auditable Area',
            width: '200px',
            fieldType: 'tooltip'
        }
    ],
    AUDIT_PLAN_CONFIGURATION: [
        {
            header: 'Audit Plan Configuration ID',
            field: 'ref',
            filter: true,
            filterElement: 'search',
            sortable: true,
            filterMatchMode: 'contains',
            filterPlaceholder: 'Search by Audit Plan Configuration ID',
            width: '150px'
        },
        {
            header: 'Workflow Status',
            field: 'status',
            fieldType: 'workflowStatus',
            filter: true,
            filterPlaceholder: 'Search by Workflow Status',
            filterElement: 'dropdown',
            filterOptions: [],
            sortable: true,
            sortableDisabled: true,
            width: '130px'
        },
        {
            header: 'Last Update Date & Time',
            field: 'updated_at_local',
            filter: true,
            filterElement: 'date',
            filterOptions: [],
            filterPlaceholder: 'Search by Last Update Date & Time',
            width: '200px',
            sortable: true,
            fieldType: 'tooltip'
        },
        {
            header: '',
            field: 'Actions',
            width: '100px',
            body: ''
        }
    ]
};

export default TableSchema;
