import React, { useState, useEffect } from 'react';
import TopCardComponentItems from '../../../components/topComponents/TopCardComponentItems';
import TopCardComponent from '../../../components/topComponents/TopCardComponent';
import FDataTable from '../../../components/fDataTable/FDataTable';
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'appkit-react';
import { useQuery, useMutation } from '@apollo/client';
import Schema from '../../../schema/URSchema';
import AppSchema from '../../../schema/AppSchema';
import { ProgressSpinner } from 'primereact/progressspinner';
import TableSchema from '../TableSchema';
import { MenuItemsSchema } from '../../../commons/MenuItemsSchema';
import IsAuthorized from '../../../commons/AuthorizationService';
import { Global, NON_GLOBAL } from '../CONSTANT';
import store from '../../../index';
import { getDate, getUTCGteLteFromDate, convertTextCase, isSuperAdmin } from '../../../util';
import DeleteConfirmModal from '../../../components/DeleteConfirmModal/DeleteConfirmModal.jsx';
import { eventClient } from '../../../apollo';
import { Toast } from 'primereact/toast';
import AuditComponent from '../../../components/auditLog/auditLog';
import RecallButton from '../../../commons/RecallButton';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import './Masters.scss';

const Masters = (props) => {
    let currentUser = store.getState()?.appReducer?.auth;
    const currentUserId = currentUser.user.id;
    const [selected, setSelected] = useState({});
    let Privileges = '';
    let loc = window.location.href.split('#');
    const [selectedData, setSelectedData] = useState({});
    const [growl, setGrowl] = useState();
    let userPrivileges = store.getState()?.appReducer?.auth?.privileges;
    const [showModal, setShowModal] = useState(false);
    const [reference, setReference] = useState({});
    const [showAudit, setShowAudit] = useState(false);
    const [recallLoader, setRecallLoader] = useState(false);
    const [recallObj, setRecallObj] = useState();
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [pageRows, setPageRows] = useState(10);
    const [searchFieldKey, setSearchFieldKey] = useState({});
    const [sortField, setSortField] = useState('version_date');
    const [sortOrder, setSortOrder] = useState('desc');
    const [cardCount, setCardCount] = useState('');
    const [tableLoader, setTableLoader] = useState(false);
    const [where, setWhere] = useState(
        props?.whereQry ? { ...props.whereQry, ...{ is_latest: { equals: true } } } : { is_latest: { equals: true } }
    );

    if (loc.length > 0) Privileges = MenuItemsSchema.USER_ITEMS.find((a) => a.routerLink === loc[1]);

    useEffect(() => {
        localStorage.removeItem('Reference');
    }, []);

    const actionTemplateView = (seleData) => {
        return (
            <div>
                <EllipsisWithTooltip placement="auto">
                    <span
                        className="pointer icon-primary"
                        onClick={() => (seleData.entity_id ? handleDraftDetails(seleData) : handleViewRoleDetails(seleData))}
                    >
                        {seleData.txnId ? seleData.txnId : seleData.ref}
                    </span>
                </EllipsisWithTooltip>
            </div>
        );
    };
    const onClickAudit = (rowData) => {
        setReference(rowData);
        setShowAudit(true);
    };
    const actionTemplate = (selData) => {
        const showRecallBtn = recallObj && recallObj[selData.id];
        return (
            <div>
                {
                    <div>
                        {(isSuperAdmin() ||
                            loggedUser.user_name === selData.created_by ||
                            (userPrivileges[Privileges.UPDATE] &&
                                userPrivileges[Privileges.UPDATE]?.length === 1 &&
                                selData.offices?.length === 1 &&
                                userPrivileges[Privileges.UPDATE][0] === selData.offices[0].segment_full_code)) &&
                            selected?.label !== 'Pending For Verification/Approval' &&
                            selData.status !== 'Sent For Verification' && (
                                <IsAuthorized
                                    privRequired={Privileges.UPDATE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    yes={() => (
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                            onClick={() => (selData.entity_id ? handleDraft(selData) : handleEdit(selData))}
                                        >
                                            <img
                                                title="Edit Role"
                                                className="pointer"
                                                src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                                alt="Edit Icon"
                                                height="20px"
                                            />
                                        </button>
                                    )}
                                ></IsAuthorized>
                            )}
                        {(selData.status === 'Draft' || selData.status === 'Created' || selData.status === 'Recalled') && selected.label === 'Pending With Self' && (
                            <IsAuthorized
                                privRequired={Privileges.UPDATE}
                                officeCode={Privileges.OFFICE_CODE}
                                yes={() => (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                        onClick={() => confirmDelete(selData)}
                                    >
                                        <img
                                            className="pointer ver-super"
                                            height="20px"
                                            title="Delete Role"
                                            src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                            alt="Delete Icon"
                                        />
                                    </button>
                                )}
                            ></IsAuthorized>
                        )}
                        {selData.status !== 'Draft' && (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                onClick={() => onClickAudit(selData)}
                            >
                                <img
                                    className="a-font-18"
                                    title="Audit"
                                    src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                                    alt="view Icon"
                                />
                            </button>
                        )}
                        {showRecallBtn && selected.label === 'Pending For Verification/Approval' && (
                            <RecallButton
                                rowData={selData}
                                setCustomizedRows={setCustomizedRows}
                                onClickRecall={onClickRecall}
                                source_id={selectedData.id}
                                handleCalRecallStatus={handleCalRecallStatus}
                                handleCancelModal={handleCancelModal}
                                handleLoader={handleLoader}
                                commentRequired={state.commentRequired}
                                showConfirmModal={state.showConfirmModal}
                                growl={growl}
                                module={'SYSTEM_ADMINISTRATION'}
                                fmis_function={'SYS_ROLE_MASTER'}
                            />
                        )}
                    </div>
                }
            </div>
        );
    };

    let tableColumns = JSON.parse(JSON.stringify(TableSchema.MASTER));
    tableColumns[0].body = actionTemplateView;
    tableColumns[tableColumns.length - 1].body = actionTemplate;
    const [state, setState] = React.useState({
        cards: TopCardComponentItems.MASTERS_CARDS,
        createModal: false,
        loading: true,
        rows: [],
        columns: tableColumns,
        where: { is_latest: { equals: true } },
        showEllipsisData: false,
        ellipsisData: '',
        showConfirmModal: false,
        commentRequired: false
    });

    const assignedTo = {
        header: 'Assigned To',
        field: 'assignedTo',
        filter: true,
        sortable: true,
        filterMatchMode: 'contains',
        filterPlaceholder: 'Search By Assigned To',
        width: '200px',
        fieldType: 'tooltip'
    };

    let loggedUser = JSON.parse(localStorage.getItem('USER_INF'));

    const onClickRecall = (rowData) => {
        setSelectedData(rowData);
    };
    const setCustomizedRows = () => {
        let index = state.rows.findIndex((pro) => pro === selectedData);
        state.rows.splice(index, 1);

        setSelectedData({});
        setState({ ...state, showConfirmModal: false, rows: state.rows });
        setRecallLoader(false);
    };
    const handleCalRecallStatus = (flag) => {
        setState({ ...state, showConfirmModal: true, commentRequired: flag });
    };
    const handleCancelModal = () => {
        setState({ ...state, showConfirmModal: false });
    };
    const handleLoader = (flag) => {
        setRecallLoader(flag);
    };

    const onClickAdd = () => {
        localStorage.setItem('transaction_id', '');
        props.history.push('/usermasters/create');
    };
    const prepareData = (obj) => {
        for (let index = 0; index < obj.roles.length; index++) {
            let ele = obj.roles[index];
            ele.updated_at_str_local = getDate(ele.version_date);
            if (
                ele.id !== currentUserId &&
                selected.ids?.length > 0 &&
                selected.label !== 'Total Roles' &&
                selected.status_obj &&
                selected.status_obj[ele.id]
            ) {
                let record = selected?.status_obj[ele.id]?.record;
                record && (record.status = selected.status_obj[ele.id].status) && (record.ref = ele.ref);
                record && (obj.roles[index] = record);
                record && (obj.roles[index].updated_at_str_local = getDate(record.version_date));
                ele.status = selected.status_obj[ele.id].status;
                ele.txnId = selected.status_obj[ele.id].txnId;
                ele.assignedTo = convertTextCase(selected.status_obj[ele.id].assignedTo, false) || '';
            }
            ele.is_active = ele.is_active === true ? 'Active' : 'Inactive';
            ele.department =
                ele.type === NON_GLOBAL
                    ? ele.departments.length > 0
                        ? ele.departments.map((dep) => dep.segment_full_code + ' - ' + dep.segment_name).join(', ')
                        : ''
                    : 'All';
            ele.office =
                ele.type === NON_GLOBAL
                    ? ele.offices.length > 0
                        ? ele.offices.map((ofc) => ofc.segment_full_code + ' - ' + ofc.segment_name).join(', ')
                        : ''
                    : 'All';
            ele.type = ele.type === NON_GLOBAL ? 'Non - Global' : Global;
        }

        if (selected.new_objs?.length > 0 && selected.label === 'Pending With Self') {
            selected.new_objs.forEach((element) => {
                element.isDraft = true;
                element.department =
                    element?.type === NON_GLOBAL && element?.department ? element?.department?.split(',')?.join(', ') : 'All';
                element.office = element?.type === NON_GLOBAL && element?.office ? element?.office?.split(',')?.join(', ') : 'All';
                element.updated_at_str_local = getDate(element?.version_date);
                obj.roles.push(element);
            });
        }

        let wfStatusArr = obj.roles.map((a) => a.status).filter((item, i, ar) => ar.indexOf(item) === i);
        state.columns.forEach((a) => {
            if (a.field === 'status') {
                a.filterOptions = wfStatusArr;
            }
        });
        setState({ ...state, rows: obj.roles, loading: false });
        setTableLoader(false);
    };
    const handleViewRoleDetails = (data) => {
        localStorage.setItem('Reference', data.ref);
        localStorage.setItem('transaction_id', data.txnId ? data.txnId : '');
        props.history.push('usermasters/view/' + data['id']);
        localStorage.setItem('roleType', data['type']);
    };

    const handleDraftDetails = (data) => {
        localStorage.setItem('Reference', data.ref);
        localStorage.setItem('transaction_id', data.txnId ? data.txnId : '');
        props.history.push('usermasters/view/' + data['entity_id'] + '/draft');
        localStorage.setItem('roleType', data['type']);
    };

    const handleEdit = (data) => {
        localStorage.setItem('Reference', data.ref);
        localStorage.setItem('transaction_id', data.txnId ? data.txnId : '');
        props.history.push('usermasters/edit/' + data['id']);
        localStorage.setItem('roleType', data['type']);
    };

    const handleDraft = (data) => {
        localStorage.setItem('Reference', data.ref);
        localStorage.setItem('transaction_id', data.txnId ? data.txnId : '');
        props.history.push('usermasters/edit/' + data['entity_id'] + '/draft');
        localStorage.setItem('roleType', data['type']);
    };

    const confirmDelete = (rowData) => {
        setShowModal(true);
        setSelectedData(rowData);
    };

    const handleCancel = () => {
        setShowModal(false);
        setSelectedData({});
    };

    const handleDelete = () => {
        if (selectedData.entity_id) {
            deleteWorkFlowAction({
                variables: {
                    where: { id: selectedData.entity_id }
                }
            });
            return;
        }
    };

    const [deleteWorkFlowAction, { loading: delLoader }] = useMutation(AppSchema.deleteOneEntityRecord, {
        client: eventClient,
        onCompleted: (data) => {
            if (data) {
                setSelectedData({});
                let index = state.rows.findIndex((pro) => pro === selectedData);
                state.rows.splice(index, 1);

                setShowModal(false);
                growl.show({ life: 6000, severity: 'success', summary: window.SYSTEM_ADMINISTRATION_USER_REGISTRATION.DeleteRole });
                setState({ ...state, rows: state.rows });
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    let variables = { orderBy: { [sortField]: sortOrder }, where: state.where };
    if (!selected.label || selected.label === 'Total Roles') {
        variables.skip = skip;
        variables.take = take;
    }
    const { refetch: fetchRoleDetails, error } = useQuery(Schema.getRoleDetails, {
        variables: variables,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            prepareData(data);
        },
        onError: () => {
            setState({
                ...state,
                rows: [],
                loading: false
            });
            setTableLoader(false);
        }
    });

    const navigateTo = (query, selectedObj, columns) => {
        if (selectedObj.label !== selected.label) {
            let tWhere = JSON.parse(JSON.stringify(where));
            setWhere(query);
            if (selectedObj.label === 'Pending For Verification/Approval') {
                setRecallObj(selectedObj.canRecall);
                columns.splice(columns.length - 1, 0, assignedTo);
            }
            columns.forEach((col) => {
                if (col['field'] === 'txnId') {
                    col['width'] = '180px';
                }
                if (col['field'] === 'status' || col['field'] === 'is_active') {
                    col['width'] = '150px';
                }
            });
            setState({ ...state, where: query, columns: columns, loading: true });
            setSelected(selectedObj);
            setSkip(0);
            setTake(10);
            setPageRows(10);
            setSortField('version_date');
            setSortOrder('desc');
            setSearchFieldKey({});
            setTableLoader(true);
            if (query?.id?.in.toString() === tWhere?.id?.in.toString()) {
                fetchRoleDetails({ variables: { where: query } }).then((response) => {
                    if (response) prepareData(response.data);
                });
            }
        }
    };
    const eventListener = (item, type) => {
        item.addEventListener(
            'click',
            () => {
                onTooltipClick(item, type);
            },
            false
        );
    };
    const onValueChange = () => {
        setTimeout(() => {
            document.querySelectorAll('td.departmentTooltip').forEach((item) => {
                eventListener(item, 'Departments');
            });
            document.querySelectorAll('td.officeTooltip').forEach((item) => {
                eventListener(item, 'Offices');
            });
        }, 1000);
    };
    const onTooltipClick = (item, header) => {
        setState({ ...state, showEllipsisData: true, ellipsisData: item.innerText, ellipsisDataHeader: header });
    };

    useEffect(() => {
        onValueChange();
    }, [state]);

    const onCloseEllipsisData = () => {
        setState({ ...state, showEllipsisData: false });
    };
    const goBack = () => {
        setShowAudit(false);
    };
    if (state.columns) {
        state.columns[state.columns.length - 1].body = actionTemplate;
        state.columns[0].body = actionTemplateView;
    }
    const { refetch: refetchCount } = useQuery(Schema.rolesCount, {
        fetchPolicy: 'no-cache',
        variables: { where: state.where },
        skip: selected.label && selected.label !== 'Total Roles',
        onCompleted: (resp) => {
            setTotalRecords(resp.rolesCount);
            if (!cardCount || cardCount === '') {
                setCardCount(resp.rolesCount);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const onPageChange = (event) => {
        setTableLoader(true);
        setSkip(event.first);
        setTake(event.rows);
        setPageRows(event.rows);
        if (!selected.label || selected.label === 'Total Roles') {
            refetchCount();
        }
        fetchRoleDetails();
    };
    const searchRecord = (e, column, filterKeys) => {
        setTableLoader(true);
        Object.keys(filterKeys).map((filterKey) => {
            if (filterKeys[filterKey].contains === null) {
                delete filterKeys[filterKey];
            }
            return filterKey;
        });
        let keyData = { ...filterKeys };
        setSearchFieldKey(keyData);
        let obj;
        obj = { is_latest: { equals: true } };
        if (filterKeys['updated_at_str_local']) {
            if (filterKeys['updated_at_str_local'].contains) {
                const dateObj = getUTCGteLteFromDate(filterKeys['updated_at_str_local'].contains);
                filterKeys['version_date'] = { gte: dateObj.gte, lte: dateObj.lte };
                delete filterKeys['updated_at_str_local'];
            } else {
                delete filterKeys['updated_at_str_local'];
            }
        }
        if (filterKeys['type']) {
            if (filterKeys['type'].contains) {
                filterKeys['type'] = {
                    equals: filterKeys['type'].contains?.toLowerCase() === 'global' ? 'GLOBAL' : 'NON_GLOBAL',
                    mode: 'insensitive'
                };
            } else {
                delete filterKeys['type'];
            }
        }
        setState({ ...state, where: { ...obj, ...filterKeys } });
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
        if (!selected.label || selected.label === 'Total Roles') {
            refetchCount();
        }
    };
    const onSort = (event) => {
        setTableLoader(true);
        let srtField = event.sortField;
		if (event.sortField === 'updated_at_str_local') {
            srtField = 'version_date';
        }
        let srtOrder = 'asc';
        if (sortField === srtField) {
            srtOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        }
        setSortOrder(srtOrder);
        setSortField(srtField);
        setSkip(skip);
        setTake(take);
        setPageRows(pageRows);
    };

    if (error) return `Error! ${error.message}`;

    return (
        <div>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <Toast ref={(el) => setGrowl(el)} />
            {!showAudit ? (
                <div className={`${'page-body'} ${state.loading || delLoader || recallLoader ? 'mask' : ''}`}>
                    <div className="page-header">
                        <span className="light-color"> Roles Creation</span>
                    </div>
                    <div className="coa-card-content">
                        {!delLoader && !recallLoader && (
                            <TopCardComponent
                                fetchParent={(query, selectedObj, columns) => navigateTo(query, selectedObj, columns)}
                                columns={tableColumns}
                                createRouter="usermasters/create"
                                editRouter="usermasters/edit"
                                selected={selected}
                                fmis_function="SYS_ROLE_MASTER"
                                approvedLabel="Total Roles"
                                fmis_sub_function="NA"
                                cardCount={cardCount}
                            ></TopCardComponent>
                        )}
                        <div className="row m-0">
                            <div className="col-md-12 p-0 text-right">
                                <IsAuthorized
                                    privRequired={Privileges.CREATE}
                                    officeCode={Privileges.OFFICE_CODE}
                                    yes={() => (
                                        <Button size="md" onClick={() => onClickAdd()} title="Add Role & Function Mapping">
                                            {'Add Role & Function Mapping'}
                                        </Button>
                                    )}
                                ></IsAuthorized>
                            </div>
                        </div>
                        <div className="row m-t-10">
                            <div className="col-md-12">
                                {!state.loading && (
                                    <FDataTable
                                        className="primary-table"
                                        rows={state.rows}
                                        onValueChange={onValueChange}
                                        columns={state.columns}
                                        emptyMessage="Roles are not created"
                                        totalRecords={totalRecords}
                                        count={skip}
                                        onPageChange={(e) => onPageChange(e)}
                                        pageRows={pageRows}
                                        searchRecord={searchRecord}
                                        filterKeys={searchFieldKey}
                                        lazyLoad={!selected.label || selected.label === 'Total Roles' ? true : false}
                                        onSort={onSort}
                                        sortField={sortField}
                                        sortOrder={sortOrder === 'asc' ? 1 : -1}
                                        loading={tableLoader}
                                        scrollable
                                    ></FDataTable>
                                )}
                            </div>
                        </div>
                    </div>
                    {showModal && (
                        <DeleteConfirmModal
                            showModal={showModal}
                            handleCancel={handleCancel}
                            type={'Supplier'}
                            name={selectedData.entity_id ? `Transaction ID: ${selectedData.txnId}` : `Role :  ${selectedData.name}`}
                            handleDelete={handleDelete}
                        />
                    )}
                    <Modal
                        visible={state.showEllipsisData}
                        onCancel={onCloseEllipsisData}
                        className="common_css  assignRolesModal"
                        backdropClosable={false}
                    >
                        <ModalHeader dialogclassname="your-dialog-classname" className="modal-header">
                            {state.ellipsisDataHeader}
                        </ModalHeader>
                        <ModalBody>{state.ellipsisData}</ModalBody>
                        <ModalFooter className="p-10">
                            <Button size="sm" kind="secondary" className="" onClick={onCloseEllipsisData}>
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            ) : (
                <AuditComponent
                    refence_label="Masters"
                    goBack={goBack}
                    reference_value={reference.ref}
                    sourceData={reference}
                    transaction_Id={reference.txnId}
                />
            )}
        </div>
    );
};
export default Masters;
