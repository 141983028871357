import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import Calendar from '../../components/Calendar/CalendarComponent';
import { Column } from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import FilterConfig from './TableFilterConfig';
import IsAuthorized from '../../commons/AuthorizationService';
import { useHistory } from 'react-router-dom';
import { convertTextCase, getDate, isConcurrentApprover, getDateOnly } from '../../util';
import { DownloadFile } from '../../commons/Downloader';
import { roundFloat } from '../../commons/commons';
import InputComponent from '../../components/InputComponent/InputComponent';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip';
import { useDispatch } from 'react-redux';
import { uploadClient } from '../../apollo';
import { useMutation } from '@apollo/client';
import Schema from '../../schema/AppSchema';
import { loadSpinner } from '../../app-redux/app-action';
import { Tooltip } from 'primereact/tooltip';
import './FDataTable.scss';

const FDataTable = (props) => {
    let dispatch = useDispatch();
    const [selectedColumns, setColumns] = useState(props.columns);
    const [rows, setRows] = useState(props.rows);
    const [filterKeys, setFilterKeys] = useState(props.filterKeys);
    const [first, setFirst] = useState(0);
    const [rowsCount, setRowsCount] = useState(10);
    const [docColumn, setDocColumn] = useState('');
    const [rowIndexVal, setRowIndexval] = useState('');
    const [state, setState] = useState({
        disableActions: props.disableActions,
        searchRec: '',
        selectedRows: props.selectedRows,
        docRowIndex: ''
    });

    let dt = useRef(null);
    useEffect(() => {
        setColumns(props.columns);
        setRows(props.rows);
        setColumns(props.columns);
        let tempState = { selectedRows: props.selectedRows };
        if (selectedColumns && selectedColumns.length > 0) {
            selectedColumns.forEach((column) => {
                if (column.filterElement === 'dropdown') {
                    tempState['selected' + column.field] = null;
                }
                if (column.filterElement === 'multiSelect') {
                    tempState['selected' + column.field] = null;
                }
            });
            setState({ ...state, ...tempState });
        }
    }, [props]);

    useEffect(() => {
        dt.current.state.first = 0;
        if (dt?.current?.state?.filters) dt.current.state.filters = {};
    }, [props.rows]);

    let history = useHistory();

    const onColumnToggle = (event) => {
        let selCol = event.value;
        let orderedSelectedColumns = props.columns.filter((col) => selCol.includes(col));
        setColumns(orderedSelectedColumns);
    };

    const onClickDownload = async (fileName, fileID) => {
        if (fileName !== '') {
            await DownloadFile(fileID, fileName);
        }
    };
    const onClickLabel = (row, column, index) => {
        if (props.handleRowItemClick) props.handleRowItemClick(row, column, index);
    };

    const onClickOpenPopup = (row) => {
        if (props.handleOpenPopup) props.handleOpenPopup(row);
    };

    const onClickAction = (rowData, column, action, index) => {
        switch (action) {
            case 'view':
                if (rowData.view) {
                    // eslint-disable-next-line max-depth
                    if (column.viewRoute) history.push(column.viewRoute + '/' + rowData['id']);
                    else props.onClickView(rowData);
                } else {
                    props.onClickView(rowData);
                }
                break;
            case 'audit':
            case 'clickable':
                props.onClickAudit(rowData);
                break;
            case 'details':
                if (column.routerLink === 'popup' || column.customView) {
                    props.onClickDetails(rowData);
                } else {
                    history.push(column.routerLink + '/' + rowData['id']);
                }
                break;
            case 'edit':
                if (column.editRoute === 'popup') {
                    props.onClickEdit(rowData);
                } else if (rowData.status?.toLowerCase() === 'draft' || rowData.status?.toLowerCase() === 'sent for revision') {
                    const id = rowData['entity_id'] ? rowData['entity_id'] : rowData['id'];
                    history.push(column.editRoute + '/' + id + '/' + true);
                } else {
                    history.push(column.editRoute + '/' + rowData['id']);
                }
                break;
            case 'addRow':
                props.onClickAddRow(rowData, column, index);
                break;
            case 'viewRow':
                props.onClickViewRow(rowData, column, index);
                break;
            case 'navigateRow':
                props.onClickNavigateRow(rowData, column, index);
                break;
            case 'delete':
                props.onClickDelete(rowData);
                break;
            case 'share':
                if (column.shareRoute === 'custom') {
                    props.onClickDetails(rowData, 'share');
                } else {
                    history.push(column.shareRoute + '/' + rowData['id']);
                }
                break;
            case 'recall':
                props.onClickRecall(rowData, column);
                break;
            case 'remarks':
                props.onClickRemarks(rowData);
                break;
            case 'comments':
                props.onClickComments(rowData);
                break;
            case 'info':
                props.onClickInfo(rowData, column);
                break;
            case 'add_info':
                props.onClickInfo(rowData, column, action);
                break;
            case 'editComments':
                props.onClickEditComments(rowData, column);
                break;
            case 'more':
                props.onClickMore(rowData, column);
                break;
            default:
                break;
        }
    };
    const getOfficeCode = (rowData) => {
        if (props.officeCode === 'ALL') return props.officeCode;
        if (props.getOfficeCode) return props.getOfficeCode(rowData);
        return 'ALL';
    };

    const prepareActionTemplate = (rowData, column, index) => {
        return (
            <div className="audit-height">
                {column.audit && (
                    <button
                        className="a-btn-background-transparent a-icon-container"
                        onClick={() => onClickAction(rowData, column, 'audit')}
                    >
                        <img
                            className="pointer m-l-5 m-r-5"
                            height="20px"
                            title="Audit"
                            src={process.env.PUBLIC_URL + '/assets/icons/audit_trail.svg'}
                            alt="view Icon"
                        />
                    </button>
                )}
                {column.clickable && rowData.is_audit_exists && (
                    <span className="column-label pointer" onClick={() => onClickAction(rowData, column, 'clickable')}>
                        Show More
                    </span>
                )}
                {column.more && (
                    <button
                        className="a-btn-background-transparent a-icon-container m-l-5"
                        onClick={() => onClickAction(rowData, column, 'more', index)}
                    >
                        <span className="a-font-18 appkiticon icon-primary icon-horizontal-more-fill pointer" title="Details" />
                    </button>
                )}
                {column.addRow && !rowData.is_default && !rowData.showDoc && props.mode !== 'view' && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={props.officeCode}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="22px"
                                title="Add"
                                src={process.env.PUBLIC_URL + '/assets/icons/doc_add.png'}
                                alt="Add Icon"
                                onClick={() => onClickAction(rowData, column, 'addRow', index)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.viewRow && !rowData.is_default && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={props.officeCode}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5 m-b-3"
                                height="20px"
                                title="View"
                                src={process.env.PUBLIC_URL + '/assets/icons/clipboard.png'}
                                alt="Add Icon"
                                onClick={() => onClickAction(rowData, column, 'viewRow', index)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.navigateTo && rowData.navigateRow && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={props.officeCode}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5 m-b-3"
                                height="20px"
                                title="Navigate"
                                src={process.env.PUBLIC_URL + '/assets/icons/Transaction_risk_Ranking.svg'}
                                alt="Add Icon"
                                onClick={() => onClickAction(rowData, column, 'navigateRow', index)}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.edit && (rowData.showEdit === undefined || rowData.showEdit) && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={getOfficeCode(rowData)}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Edit"
                                src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                alt="Edit Icon"
                                onClick={() => onClickAction(rowData, column, 'edit')}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.delete && !rowData.is_default && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={getOfficeCode(rowData)}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                height="20px"
                                title="Delete"
                                src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                alt="Delete Icon"
                                onClick={() => onClickAction(rowData, column, 'delete')}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {(column.share || rowData.share) && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        yes={() => (
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-10"
                                onClick={() => onClickAction(rowData, column, 'share')}
                            >
                                <span className="pw_link a-font-18 pi pi-share-alt" title="Share" />
                            </button>
                        )}
                    ></IsAuthorized>
                )}
                {(column.view || rowData.view) && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={getOfficeCode(rowData)}
                        yes={() => (
                            <img
                                className="pointer m-l-5 m-r-5"
                                title="View"
                                src={process.env.PUBLIC_URL + '/assets/icons/eye.svg'}
                                alt="view Icon"
                                onClick={() => onClickAction(rowData, column, 'view')}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {(column.recall || rowData.recall) && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        yes={() => (
                            <img
                                alt=""
                                src={process.env.PUBLIC_URL + '/assets/icons/recall.svg'}
                                className="pointer m-r-10"
                                height="20px"
                                width="20px"
                                onClick={() => onClickAction(rowData, column, 'recall')}
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.remarks && rowData.remarks && (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        yes={() => (
                            <img
                                src={process.env.PUBLIC_URL + '/assets/icons/remarks.svg'}
                                className="pointer m-r-10"
                                height="20px"
                                width="20px"
                                onClick={() => onClickAction(rowData, column, 'remarks')}
                                alt=""
                            />
                        )}
                    ></IsAuthorized>
                )}
                {column.comments && rowData.comments && (
                    <img
                        src={process.env.PUBLIC_URL + '/assets/icons/remarks.svg'}
                        className="pointer m-r-10"
                        height="20px"
                        width="20px"
                        onClick={() => onClickAction(rowData, column, 'comments')}
                        alt=""
                    />
                )}
            </div>
        );
    };

    const handleInputChange = (e, index, column, flag) => {
        if (flag) {
            if (/^0/.test(e)) {
                e = e.replace(/^0/, '');
            }
        }
        let editRows = [...rows];
        editRows[index.rowIndex][column.field] = e;
        let updateRows = [...editRows];
        setRows(updateRows);
        if (props.handleInputChange) {
            props.handleInputChange(e, index, column);
        }
    };

    const handleDropdownChange = (e, index, column) => {
        let flag = false;
        if (props.selectedList && props.selectedList.length > 0) {
            props.selectedList.map((a) => {
                if (a.sap_list_id === index.rowData.id && a.classification === e) {
                    flag = true;
                }
            });
            props.selectedList.map((a) => {
                if (flag) {
                    props.dropDownChange(column.field);
                    return;
                } else if (a.id === index.rowData.id && a.classification !== e) {
                    dropChanges(e, index, column, flag);
                } else {
                    dropChanges(e, index, column, flag);
                }
            });
        } else {
            dropChanges(e, index, column);
        }
    };

    const dropChanges = (e, index, column, flag) => {
        if (!flag) {
            let editRows = rows;
            editRows[index.rowIndex][column.field] = e;
            let updateRows = [...editRows];
            if (props.checkTableList) {
                let check = true;
                updateRows.filter((val) => {
                    if (val.managementResponse) check = false;
                    else check = true;
                });
                props.checkTableList(check);
            }
            setRows(updateRows);
            if (props.dropDownChange) {
                props.dropDownChange(updateRows, index);
            }
        }
    };

    const handleMultiSelectChange = (e, index, column) => {
        let editRows = rows;
        editRows[index.rowIndex][column.field] = e;
        let updateRows = [...editRows];
        setRows(updateRows);
        if (props.handleMultiSelect) {
            props.handleMultiSelect(updateRows, index);
        }
    };

    const onClickRadio = (e, column, rowData, index) => {
        switch (props.screen) {
            case 'deposit-deductions':
                props.updateInparent(e, rowData, 'radio');
                break;
            case 'cash-report':
            case 'Audit-Plan-Schedule': {
                let editRows = rows;
                editRows[index.rowIndex][column.field] = e.value;
                let updateRows = [...editRows];
                setRows(updateRows);
                props.screen !== 'Audit-Plan-Schedule' && props.updateInparent(e, rowData, 'radio');
                break;
            }
            default:
                break;
        }
    };

    const abbreviationRec = (column, fieldValue, rowData) => {
        if (column.fieldType === 'abbreviation') {
            let abbrValRet = '';
            let val = rowData[column.field];
            let abbreviationTxt = val?.split(',').join(', ');
            /* prettier-ignore */
            fieldValue = val?.split(',')?.length > 1 ? val.split(',').map((b) => (b = b.charAt(0).toUpperCase())).join(', ') : val;

            if (val?.split(',')?.length > 1) {
                abbrValRet = (
                    <div className="p-d-flex p-ai-center">
                        <Tooltip
                            target=".show-tooltip"
                            className="f-12 over-tooltip"
                            position="bottom"
                            appendTo={document.self}
                            autoZIndex={true}
                        />
                        <p className="m-0 p-ellipsis show-tooltip" data-pr-tooltip={`${abbreviationTxt}`}>
                            {fieldValue}
                        </p>
                    </div>
                );
            } else {
                abbrValRet = <p className="m-b-0">{fieldValue}</p>;
            }
            return abbrValRet;
        }
    };

    const deleteFile = (rowData, index, column) => {
        let editRows = [...rows];
        editRows[index.rowIndex][column.field] = null;
        let updateRows = [...editRows];
        setRows(updateRows);
        props.deleteFile(updateRows);
    };

    const prepareBody = (rowData, column, index) => {
        let body = undefined;
        let fieldSplit = column.field?.split('.');
        let fieldValue = '';
        if (fieldSplit?.length === 2) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]];
        } else if (fieldSplit?.length === 1) {
            fieldValue = rowData?.[fieldSplit?.[0]];
        } else if (fieldSplit?.length === 3) {
            fieldValue = rowData?.[fieldSplit?.[0]]?.[fieldSplit?.[1]]?.[fieldSplit?.[2]];
        }

        switch (column.fieldType) {
            case 'date':
            case 'tooltip':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{fieldValue}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'dateConversion':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{column.dateOnly ? getDateOnly(fieldValue) : getDate(fieldValue)}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'abbreviation':
                body = abbreviationRec(column, fieldValue, rowData);
                break;
            case 'viewDetails':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        {fieldValue && fieldValue !== '–' && rowData.viewInfo && (
                            <IsAuthorized
                                privRequired={props?.riskView ? props?.readPriv : props.updatePriv}
                                officeCode={props.officeCode}
                                yes={() => (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, 'info')}
                                    >
                                        <img
                                            title="Info"
                                            className="pointer"
                                            src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                            alt="Info Icon"
                                            height="20px"
                                        />
                                    </button>
                                )}
                            ></IsAuthorized>
                        )}
                    </div>
                );
                break;
            case 'viewDetailsInfoColor':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        {fieldValue && fieldValue !== '–' && rowData.viewInfo && rowData[column.checkField] === undefined ? (
                            <IsAuthorized
                                privRequired={props.updatePriv}
                                officeCode={props.officeCode}
                                yes={() => (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, 'info')}
                                    >
                                        <img
                                            title="Info"
                                            className="pointer"
                                            src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                            alt="Info Icon"
                                            height="20px"
                                        />
                                    </button>
                                )}
                            ></IsAuthorized>
                        ) : (
                            <IsAuthorized
                                privRequired={props.updatePriv}
                                officeCode={props.officeCode}
                                yes={() => (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, 'info')}
                                    >
                                        <img
                                            title="Info"
                                            className="pointer"
                                            src={process.env.PUBLIC_URL + '/assets/icons/green_info.png'}
                                            alt="Info Icon"
                                            height="20px"
                                        />
                                    </button>
                                )}
                            ></IsAuthorized>
                        )}
                    </div>
                );
                break;

            case 'template':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">
                            {roundFloat(fieldValue, 2)} {column.concatValue}
                        </p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'clickable':
                body = !rowData.isClickable ? (
                    <span className="column-label m-0 p-ellipsis pointer" onClick={() => onClickAction(rowData, column, 'details')}>
                        <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                    </span>
                ) : (
                    <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                );
                break;
            case 'columnClickable':
                body = !rowData.isClickable ? (
                    <span className="column-label m-0 p-ellipsis pointer" onClick={() => onClickAction(rowData, column, 'info')}>
                        <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                    </span>
                ) : (
                    <EllipsisWithTooltip placement="auto">
                        <p className="m-0 p-ellipsis">{fieldValue}</p>
                    </EllipsisWithTooltip>
                );
                break;
            case 'workflowStatus':
                body =
                    fieldValue?.toLowerCase() === 'approved' ? (
                        <span className="Approved">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(fieldValue, false)}</EllipsisWithTooltip>
                        </span>
                    ) : fieldValue?.toLowerCase() === 'draft' ? (
                        <span className="Draft">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(fieldValue, false)}</EllipsisWithTooltip>
                        </span>
                    ) : fieldValue?.toLowerCase() === 'sent for approval' ? (
                        <span className="SentForApproval">
                            <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                        </span>
                    ) : fieldValue?.toLowerCase() === 'sent for revision' ? (
                        <span className="SentForRevision">
                            <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                        </span>
                    ) : fieldValue?.toLowerCase() === 'sent for verification' ? (
                        <span className="SentForVerification">
                            <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                        </span>
                    ) : fieldValue?.toLowerCase() === 'rejected' || fieldValue?.toLowerCase() === 'cancelled' ? (
                        <span className="Rejected">
                            <EllipsisWithTooltip placement="auto">{convertTextCase(fieldValue, false)}</EllipsisWithTooltip>
                        </span>
                    ) : (
                        <EllipsisWithTooltip placement="auto">{fieldValue}</EllipsisWithTooltip>
                    );
                break;
            case 'radio':
                body = (
                    <div>
                        <IsAuthorized
                            privRequired={props.updatePriv}
                            officeCode={props.officeCode}
                            yes={() => (
                                <RadioButton
                                    inputId="rb2"
                                    value={fieldValue}
                                    name="functions"
                                    checked={fieldValue}
                                    onChange={(e) => onClickRadio(e, column, rowData)}
                                />
                            )}
                            no={() => <EllipsisWithTooltip placement="auto">{fieldValue ? 'Yes' : 'No'}</EllipsisWithTooltip>}
                        ></IsAuthorized>
                    </div>
                );
                break;
            case 'multiRadio':
                body = (
                    <IsAuthorized
                        privRequired={props.updatePriv}
                        officeCode={props.officeCode}
                        yes={() => (
                            <div>
                                <RadioButton
                                    inputId="rb1"
                                    value="Yes"
                                    name="functions"
                                    checked={fieldValue === 'Yes'}
                                    onChange={(e) => onClickRadio(e, column, rowData, index)}
                                />
                                <span htmlFor="rb1" className="p-radiobutton-label">
                                    Yes
                                </span>
                                <RadioButton
                                    className="m-l-20"
                                    inputId="rb2"
                                    value="No"
                                    name="functions"
                                    checked={fieldValue === 'No'}
                                    onChange={(e) => onClickRadio(e, column, rowData, index)}
                                />
                                <span htmlFor="rb2" className="p-radiobutton-label">
                                    No
                                </span>
                            </div>
                        )}
                    ></IsAuthorized>
                );
                break;
            case 'multiRadioApproach':
                if (!rowData.edit || (rowData.edit && !rowData.enableEdit)) {
                    body = (
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{rowData[column.field]}</p>
                        </EllipsisWithTooltip>
                    );
                } else {
                    body = (
                        <IsAuthorized
                            privRequired={props.updatePriv}
                            officeCode={props.officeCode}
                            yes={() => (
                                <div>
                                    <RadioButton
                                        inputId="rb1"
                                        value="ARA"
                                        name="functions"
                                        checked={fieldValue === 'ARA'}
                                        onChange={(e) => onClickRadio(e, column, rowData, index)}
                                    />
                                    <span htmlFor="rb1" className="p-radiobutton-label">
                                        ARA
                                    </span>
                                    <RadioButton
                                        className="m-l-20"
                                        inputId="rb2"
                                        value="NRB"
                                        name="functions"
                                        checked={fieldValue === 'NRB'}
                                        onChange={(e) => onClickRadio(e, column, rowData, index)}
                                    />
                                    <span htmlFor="rb2" className="p-radiobutton-label">
                                        NRB
                                    </span>
                                </div>
                            )}
                        ></IsAuthorized>
                    );
                }
                break;
            case 'switch':
                body = (
                    <div>
                        <IsAuthorized
                            privRequired={props.updatePriv}
                            officeCode={props.officeCode}
                            yes={() => (
                                <InputSwitch
                                    checked={fieldValue}
                                    onChange={(e) => onClickSwitch(e, column, rowData)}
                                    className={column.className ? column.className : ''}
                                    title={fieldValue ? 'Deactivate' : 'Activate'}
                                    disabled={state.disableActions}
                                />
                            )}
                            no={() => <EllipsisWithTooltip placement="auto">{fieldValue ? 'Active' : 'Deactive'}</EllipsisWithTooltip>}
                        ></IsAuthorized>
                    </div>
                );
                break;
            case 'checkbox':
                body = (
                    <IsAuthorized
                        privRequired={isConcurrentApprover() ? props.viewPriv : props.updatePriv}
                        officeCode={props.officeCode}
                        yes={() => (
                            <Checkbox
                                checked={fieldValue}
                                onChange={(e) => onClickCheckBox(e, column, rowData, index)}
                                className={!checkDisable(rowData, column, index) ? 'pointer' : 'checked-disabled'}
                                disabled={checkDisable(rowData, column, index) || props.formType === 'view'}
                            ></Checkbox>
                        )}
                    ></IsAuthorized>
                );
                break;
            case 'Sl-No':
                body = <div className={`${column.textCenter ? 'text-center' : 'display-flex'}`}>{index?.rowIndex + 1}</div>;
                break;
            case 'buttonLabelData':
                body = (
                    <div>
                        {rowData[column.field] !== '' ? (
                            <div className="spaceBetween">
                                <EllipsisWithTooltip placement="auto">
                                    <u
                                        className="pointer table-col-link m-0 p-ellipsis"
                                        onClick={() => onClickLabel(rowData, column, index)}
                                    >
                                        {column.buttonLabel2}
                                    </u>
                                </EllipsisWithTooltip>
                                <IsAuthorized
                                    privRequired={props.updatePriv}
                                    officeCode={getOfficeCode(rowData)}
                                    yes={() => (
                                        <img
                                            className="pointer m-l-5 m-r-5"
                                            height="20px"
                                            title={props.mode === 'view' ? 'Info' : 'Edit'}
                                            src={
                                                props.mode === 'view'
                                                    ? process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                                    : process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                            }
                                            alt="Edit Icon"
                                            onClick={() => onClickLabel(rowData, column)}
                                        />
                                    )}
                                ></IsAuthorized>
                            </div>
                        ) : (
                            <EllipsisWithTooltip placement="auto">
                                <u className="pointer table-col-link m-0 p-ellipsis" onClick={() => onClickLabel(rowData, column, index)}>
                                    {column.buttonLabel1}
                                </u>
                            </EllipsisWithTooltip>
                        )}
                    </div>
                );
                break;
            case 'buttonLabelEdit':
                body = !rowData.enableEdit ? (
                    <div className="spaceBetween">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        <IsAuthorized
                            privRequired={props.updatePriv}
                            officeCode={getOfficeCode(rowData)}
                            yes={() => (
                                <img
                                    className="pointer m-l-5 m-r-5"
                                    height="20px"
                                    title={props.mode === 'view' ? 'Info' : 'Edit'}
                                    src={
                                        props.mode === 'view'
                                            ? process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                            : process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                    }
                                    alt="Edit Icon"
                                    onClick={() => onClickLabel(rowData, column, index)}
                                />
                            )}
                        ></IsAuthorized>
                    </div>
                ) : (
                    <div className="spaceBetween">
                        <Dropdown
                            name={column.ddName ? column.ddName : column.field}
                            value={rows[index.rowIndex][column.field]}
                            onChange={(e) => handleDropdownChange(e.value, index, column)}
                            appendTo={document.self}
                            options={props.ddOptions ? props.ddOptions : setOptions(column.filterOptions)}
                            placeholder={column.ddPlaceholder}
                            className="w-100"
                            optionLabel={column.ddOptionLabel}
                            optionValue={column.ddOptionLabel}
                            filter={column.ddFilter ? true : false}
                            resetFilterOnHide={column.ddFilter ? true : false}
                            filterPlaceholder={column.ddFilter ? 'Search' : ''}
                            filterBy={column.ddFilter ? 'label' : ''}
                            showClear={column.ddClear ? true : false}
                        />
                        <div className="display-flex">
                            <button
                                className="a-btn-background-transparent a-icon-container m-l-10 m-r-10"
                                onClick={() => props.hadleRowSave(rowData, column, index)}
                            >
                                <span title="Save" className="a-font-18 m-t-2 colour appkiticon icon-primary icon-check-mark-fill" />
                            </button>
                            {props.showCancel && (
                                <button
                                    className="a-btn-background-transparent a-icon-container"
                                    onClick={() => props.hadleRowCancel(rowData, column, index)}
                                >
                                    <span title="Cancel" className="fs-13 appkiticon icon-primary icon-close-fill" />
                                </button>
                            )}
                        </div>
                    </div>
                );
                break;
            case 'button':
                if (column.buttonType === 'label') {
                    body = (
                        <div className="display-flex">
                            {column?.buttonLabel ? (
                                <EllipsisWithTooltip placement="auto">
                                    <u
                                        className="pointer table-col-link m-0 p-ellipsis"
                                        onClick={() => onClickLabel(rowData, column, index)}
                                    >
                                        {column.buttonLabel}
                                    </u>
                                </EllipsisWithTooltip>
                            ) : (
                                <EllipsisWithTooltip placement="auto">
                                    <u
                                        className="pointer table-col-link m-0 p-ellipsis"
                                        onClick={() => onClickLabel(rowData, column, index)}
                                    >
                                        {fieldValue}
                                    </u>
                                </EllipsisWithTooltip>
                            )}
                        </div>
                    );
                } else if (column.buttonType === 'upload') {
                    body = (
                        <div className="display-flex">
                            {rows[index.rowIndex].isEdit && (
                                <u
                                    className="pointer table-col-link word-ellipses"
                                    onClick={() => onClickDownload(rows[index.rowIndex][column.field], rows[index.rowIndex].fileID)}
                                >
                                    <EllipsisWithTooltip placement="auto">
                                        <p className="m-0 p-ellipsis">{rows[index.rowIndex][column.field]}</p>
                                    </EllipsisWithTooltip>
                                </u>
                            )}
                        </div>
                    );
                } else if (column.buttonType === 'popupcheck') {
                    body = (
                        <div className="display-flex">
                            {rowData[column.checkField] ? (
                                <u className="pointer table-col-link" onClick={() => onClickOpenPopup(rowData)}>
                                    <EllipsisWithTooltip placement="auto">
                                        <p className="m-0 p-ellipsis"> {fieldValue}</p>
                                    </EllipsisWithTooltip>
                                </u>
                            ) : (
                                <EllipsisWithTooltip placement="auto">
                                    <p className="m-0 p-ellipsis"> {fieldValue}</p>
                                </EllipsisWithTooltip>
                            )}
                        </div>
                    );
                } else {
                    body = (
                        <span size="sm" kind="primary" className="pointer icon-primary">
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis">{column.buttonLabel}</p>
                            </EllipsisWithTooltip>
                        </span>
                    );
                }
                break;
            case 'customSwitch':
                body = (
                    <div>
                        <IsAuthorized
                            privRequired={props.updatePriv}
                            officeCode={props.officeCode}
                            yes={() => (
                                <InputSwitch
                                    checked={fieldValue}
                                    onChange={(e) => onClickSwitch(e, column, rowData)}
                                    className={`m-0 ${column.className ? column.className : ''}`}
                                    title={fieldValue ? 'No' : 'Yes'}
                                    disabled={fieldValue}
                                />
                            )}
                        ></IsAuthorized>
                    </div>
                );
                break;
            case 'actions':
                body = prepareActionTemplate(rowData, column, index);
                break;
            case 'browse':
                body = rowData.showBrowse && (
                    <div className="display-flex align-center">
                        {props.mode !== 'view' && (!rows[index.rowIndex]?.fileName || rows[index.rowIndex]?.fileName === '') && (
                            <label htmlFor="file" className="btn browse_btn" onClick={() => setRowIndexval(index)}>
                                Browse
                            </label>
                        )}
                        <input
                            hidden
                            id="file"
                            type="file"
                            key={index}
                            placeholder="Browse"
                            onChange={(ev) => onBasicUploadAuto(ev, column)}
                            onClick={(ev) => (ev.target.value = null)}
                        />
                        {rows[index.rowIndex]?.fileName && rows[index.rowIndex]?.fileName !== '' && (
                            <div className="m-0 row ">
                                <div
                                    className="col-md-10 pointer table-col-link p-ellipsis"
                                    onClick={() => onClickDownload(rows[index.rowIndex]?.fileName, rows[index.rowIndex].doc)}
                                >
                                    <EllipsisWithTooltip placement="auto">{rows[index.rowIndex]?.fileName}</EllipsisWithTooltip>
                                </div>
                                <div className="col-md-2 float-right m-t-3">
                                    {props.mode !== 'view' && (
                                        <i className="pi pi-trash pointer" onClick={() => deleteFile(rowData, index, column)}></i>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
                break;
            case 'downloadBrowse':
                body = rowData.showDownloadBrowse && (
                    <div>
                        <>
                            {props.mode !== 'view' && rows[index.rowIndex].showDownloadBtn && (
                                <div className="m-r-5">
                                    <label className="btn browse_btn" onClick={() => onDownloadFileQuery(rowData, column, index)}>
                                        Download
                                    </label>
                                </div>
                            )}
                            {props.mode !== 'view' &&
                                rows[index.rowIndex].showBrowse &&
                                (!rows[index.rowIndex]?.fileName || rows[index.rowIndex]?.fileName === '') && (
                                    <label htmlFor="file" className="btn browse_btn" onClick={() => setRowIndexval(index)}>
                                        Browse
                                    </label>
                                )}
                            <input
                                hidden
                                id="file"
                                type="file"
                                key={index}
                                placeholder="Browse"
                                onChange={(ev) => onBasicUploadAuto(ev, column)}
                                onClick={(ev) => (ev.target.value = null)}
                            />
                        </>
                        {rows[index.rowIndex]?.fileName && rows[index.rowIndex]?.fileName !== '' && (
                            <div className="display-flex">
                                <EllipsisWithTooltip placement="auto">
                                    <p
                                        className="m-0 pointer table-col-link p-ellipsis"
                                        onClick={() => onClickDownload(rows[index.rowIndex]?.fileName, rows[index.rowIndex].doc)}
                                    >
                                        {rows[index.rowIndex]?.fileName}
                                    </p>
                                </EllipsisWithTooltip>
                                <div className="m-l-10 float-right m-t-3">
                                    {props.mode !== 'view' && (
                                        <i className="pi pi-trash pointer" onClick={() => deleteFile(rowData, index, column)}></i>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
                break;
            case 'dropdown':
                body = (
                    <div>
                        {props.mode === 'view' ? (
                            rows[index.rowIndex][column.field]
                        ) : (
                            <Dropdown
                                name={column.ddName ? column.ddName : column.field}
                                value={rows[index.rowIndex][column.field]}
                                onChange={(e) => handleDropdownChange(e.value, index, column)}
                                appendTo={document.self}
                                options={props.ddOptions ? props.ddOptions : setOptions(column.filterOptions)}
                                placeholder={column.ddPlaceholder}
                                className="w-100"
                                optionLabel={column.ddOptionLabel}
                                optionValue={column.ddOptionLabel}
                                filter={column.ddFilter ? true : false}
                                resetFilterOnHide={column.ddFilter ? true : false}
                                filterPlaceholder={column.ddFilter ? 'Search' : ''}
                                filterBy={column.ddFilter ? 'label' : ''}
                                showClear={column.ddClear ? true : false}
                            />
                        )}
                    </div>
                );
                break;
            case 'dropdownWrtRowConditionally':
                body = rowData.enableDropDown ? (
                    <div>
                        {props.mode === 'view' ? (
                            rows[index.rowIndex][column.field]
                        ) : (
                            <Dropdown
                                name={column.ddName ? column.ddName : column.field}
                                value={rows[index.rowIndex][column.field]}
                                onChange={(e) => handleDropdownChange(e.value, index, column)}
                                appendTo={document.self}
                                options={props.ddOptions ? props.ddOptions : setOptions(column.filterOptions)}
                                placeholder={column.ddPlaceholder}
                                className="w-100"
                                optionLabel={column.ddOptionLabel}
                                optionValue={column.ddOptionLabel}
                                filter={column.ddFilter ? true : false}
                                resetFilterOnHide={column.ddFilter ? true : false}
                                filterPlaceholder={column.ddFilter ? 'Search' : ''}
                                filterBy={column.ddFilter ? 'label' : ''}
                                showClear={column.ddClear ? true : false}
                            />
                        )}
                    </div>
                ) : rowData[column.field] ? (
                    rowData[column.field]
                ) : (
                    ''
                );
                break;
            case 'multiSelect':
                body = (
                    <div>
                        {props.mode === 'view' ? (
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-ellipsis">{rows[index.rowIndex][column.field]}</p>
                            </EllipsisWithTooltip>
                        ) : (
                            <MultiSelect
                                responsive
                                filter={true}
                                className="w-100"
                                name={column.field}
                                filterPlaceholder="Search"
                                filterBy={column.ddOptionLabel}
                                resetFilterOnHide={true}
                                appendTo={document.self}
                                optionLabel={column.ddOptionLabel}
                                value={rows[index.rowIndex][column.field]}
                                placeholder={column.ddPlaceholder}
                                onChange={(e) => handleMultiSelectChange(e.value, index, column)}
                                options={props.ddOptions}
                                tooltip={
                                    rows[index.rowIndex][column.field]?.length > 0
                                        ? rows[index.rowIndex][column.field].map((a) => a[column.ddOptionLabel])
                                        : null
                                }
                                tooltipOptions={{ position: 'bottom', className: 'font-13 m-width-px' }}
                            />
                        )}
                    </div>
                );
                break;
            case 'inputComponent':
                body = (
                    <div>
                        {props.mode === 'view' ? (
                            rows[index.rowIndex][column.field]
                        ) : (
                            <>
                                {column?.inputMode === 'number' ? (
                                    <InputComponent
                                        placeholder={column.inputPlaceholder}
                                        name={column?.field}
                                        value={rows[index?.rowIndex][column?.field]}
                                        onChange={(e) => handleInputChange(e, index, column, true)}
                                        className="w-100"
                                        inputBoxSize="sm"
                                        numbers="true"
                                        type={column.inputType}
                                        maxLength={column.inputMaxlength || 50}
                                    />
                                ) : column?.inputMode === 'text' ? (
                                    <InputComponent
                                        placeholder={column.inputPlaceholder}
                                        name={column?.field}
                                        value={rows[index?.rowIndex][column?.field]}
                                        onChange={(e) => handleInputChange(e, index, column, true)}
                                        className="w-100"
                                        inputBoxSize="sm"
                                        type={column.inputType}
                                        maxLength={column.inputMaxlength || 50}
                                    />
                                ) : (
                                    <InputComponent
                                        name={column?.ddName}
                                        value={rows[index?.rowIndex][column?.field]}
                                        onChange={(e) => props.handleInputChange(e, index, column)}
                                        placeholder={column?.ddPlaceholder}
                                        className="w-100"
                                        inputBoxSize="sm"
                                        type={column.inputType}
                                        maxLength={column.inputMaxlength || 50}
                                        hasError={
                                            props?.errors && props?.errors[index?.rowIndex] && props?.errors[index?.rowIndex][column?.field]
                                        }
                                        errMsg={props?.errMsg}
                                    />
                                )}
                            </>
                        )}
                    </div>
                );
                break;
            case 'inputComponentWrtRowConditionally':
                body = rowData.enableInput ? (
                    <div>
                        {props.mode === 'view' ? (
                            rows[index.rowIndex][column.field]
                        ) : (
                            <InputComponent
                                name={column?.ddName}
                                value={rows[index?.rowIndex][column?.field]}
                                onChange={(e) => handleInputChange(e, index, column, false)}
                                placeholder={column?.ddPlaceholder}
                                className="w-100"
                                inputBoxSize="sm"
                                type={column.inputType}
                                maxLength={column.inputMaxlength || 50}
                            />
                        )}
                    </div>
                ) : (
                    ''
                );
                break;
            case 'comments':
                body = (
                    <div className="display-flex">
                        <EllipsisWithTooltip placement="auto">
                            <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                        </EllipsisWithTooltip>
                        {fieldValue && fieldValue !== '–' && rowData.editComments && (
                            <IsAuthorized
                                privRequired={props.updatePriv}
                                officeCode={props.officeCode}
                                yes={() => (
                                    <button
                                        className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, 'editComments')}
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/icons/remarks.svg'}
                                            className="pointer"
                                            height="20px"
                                            width="20px"
                                            alt=""
                                        />
                                    </button>
                                )}
                            ></IsAuthorized>
                        )}
                    </div>
                );
                break;
            case 'Actions':
                body = (
                    <div className="display-flex align-center justify-center">
                        {props.formType !== 'view' && rowData.edit ? (
                            <div>
                                {!rows[index.rowIndex]['enableEdit'] ? (
                                    <img
                                        title="Edit"
                                        className="pointer"
                                        src={process.env.PUBLIC_URL + '/assets/icons/edit.svg'}
                                        alt="Edit Icon"
                                        onClick={() => props.hadleRowEdit(rowData, column, index)}
                                    />
                                ) : (
                                    <div className="row">
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-r-10"
                                            onClick={() => props.hadleRowSave(rowData, column, index)}
                                        >
                                            <span
                                                title="Save"
                                                className="a-font-18 m-t-2 colour appkiticon icon-primary icon-check-mark-fill"
                                            />
                                        </button>
                                        {props.showCancel && (
                                            <button
                                                className="a-btn-background-transparent a-icon-container"
                                                onClick={() => props.hadleRowCancel(rowData, column, index)}
                                            >
                                                <span title="Cancel" className="fs-13 appkiticon icon-primary icon-close-fill" />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                        {props.formType !== 'view' && rowData.delete ? (
                            <div className="m-l-10">
                                <button
                                    className="a-btn-background-transparent a-icon-container m-l-5 m-r-5"
                                    onClick={() => props.handleRowDelete(rowData, column, index)}
                                >
                                    <img
                                        className="pointer"
                                        height="20px"
                                        title="Delete"
                                        src={process.env.PUBLIC_URL + '/assets/icons/delete.svg'}
                                        alt="Delete Icon"
                                    />
                                </button>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
                break;
            case 'checkboxAndInfo':
                body = (
                    <div className="row">
                        <div className="col-md-2">
                            <IsAuthorized
                                privRequired={isConcurrentApprover() ? props.viewPriv : props.updatePriv}
                                officeCode={props.officeCode}
                                yes={() => (
                                    <Checkbox
                                        checked={fieldValue}
                                        onChange={(e) => onClickCheckBox(e, column, rowData, index)}
                                        className={!checkDisable(rowData, column, index) ? 'pointer' : 'checked-disabled'}
                                        disabled={checkDisable(rowData, column, index)}
                                    ></Checkbox>
                                )}
                            ></IsAuthorized>
                        </div>
                        <div className="col-md-6">
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                            </EllipsisWithTooltip>
                        </div>

                        <div className="display-flex col-md-2">
                            {fieldValue && fieldValue !== '–' && rowData.viewInfo && (
                                <IsAuthorized
                                    privRequired={props.updatePriv}
                                    officeCode={props.officeCode}
                                    yes={() => (
                                        <button
                                            className="a-btn-background-transparent a-icon-container m-l-auto m-r-5"
                                            onClick={() => onClickAction(rowData, column, 'info')}
                                        >
                                            <img
                                                title="Info"
                                                className="pointer"
                                                src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                                                alt="Info Icon"
                                                height="20px"
                                            />
                                        </button>
                                    )}
                                ></IsAuthorized>
                            )}
                        </div>
                    </div>
                );
                break;
            case 'addInfo':
                body = (
                    <div className="display-flex">
                        {column.showVal ? (
                            <EllipsisWithTooltip placement="auto">
                                <p className="m-0 p-r-5 p-ellipsis">{fieldValue}</p>
                            </EllipsisWithTooltip>
                        ) : (
                            <>
                                {fieldValue ? (
                                    <p className="m-0 p-r-5 p-ellipsis">{props.formType !== 'view' ? 'Added' : 'View ' + column.header}</p>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                        {fieldValue ? (
                            <button
                                className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                onClick={() => onClickAction(rowData, column, 'add_info')}
                            >
                                <img
                                    className="pointer"
                                    title={props.formType !== 'view' && column.edit ? 'Edit' : 'Info'}
                                    src={
                                        props.formType !== 'view' && column.edit
                                            ? process.env.PUBLIC_URL + '/assets/icons/edit.svg'
                                            : process.env.PUBLIC_URL + '/assets/icons/info.svg'
                                    }
                                    alt={props.formType !== 'view' && column.edit ? 'Edit Icon' : 'Info Icon'}
                                    height="20px"
                                />
                            </button>
                        ) : (
                            <>
                                {props.formType !== 'view' && (
                                    <button
                                        className="a-btn-background-transparent icon-primary a-icon-container m-l-auto m-r-5"
                                        onClick={() => onClickAction(rowData, column, 'add_info')}
                                    >
                                        + Add
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                );
                break;
            case 'document_download':
                body = (
                    <EllipsisWithTooltip placement="auto">
                        <u
                            onClick={() => onClickDownload(rowData.document, rowData.document_id)}
                            className="m-0 p-r-5 p-ellipsis primary-color pointer"
                        >
                            {fieldValue}
                        </u>
                    </EllipsisWithTooltip>
                );
                break;
            default:
                break;
        }
        return body;
    };

    const onDownloadFileQuery = (value, column, index) => {
        props.downloadFileQueryEvent(value, column, index);
    };

    const onBasicUploadAuto = (value, column) => {
        const file = value.target.files[0];
        setDocColumn(column);
        setState({ ...state, docRowIndex: rowIndexVal });
        dispatch(loadSpinner(true));
        if (file) uploadFile({ variables: { file: file } });
    };

    const [uploadFile] = useMutation(Schema.singleUpload, {
        client: uploadClient,
        onCompleted: (data) => {
            if (data) {
                let editRows = rows;
                editRows[state.docRowIndex.rowIndex][docColumn.field] = data.singleUpload.id;
                editRows[state.docRowIndex.rowIndex].fileName = data.singleUpload.input_name;
                let updateRows = [...editRows];
                setRows(updateRows);
                setState({ ...state, loading: false });
                dispatch(loadSpinner(false));
                props.deleteFile(updateRows);
            }
        },
        onError: () => {
            setState({ ...state, loading: false });
            dispatch(loadSpinner(false));
        }
    });

    const onClickSwitch = (e, column, rowData) => {
        props.updateInparent(e, rowData, 'switch');
    };

    const onClickCheckBox = (e, column, rowData, index) => {
        let editRows = rows;
        editRows[index.rowIndex][column.field] = rowData[column.field] === true ? false : true;
        let updateRows = [...editRows];
        setRows(updateRows);
        if (props?.isUpdateInParent) {
            props.updateInparent(e, rowData, 'checkbox', column);
            return;
        }
        if (props?.addRow) {
            props.addRow([...updateRows], rowData);
        }
    };

    const checkDisable = (rowData, column, index) => {
        if (props?.checkDisableInParent) {
            return props.checkDisableInParent(rowData, column.field);
        }
        return rows[index.rowIndex].checkboxDisable && (rows[index.rowIndex].isView || column.field !== 'is_visible');
    };

    const setOptions = (options) => {
        let obj = {};
        let optionsArr = [];
        if (options?.length > 0 && !FilterConfig[options]) {
            options.forEach((ele) => {
                obj = { label: ele, value: ele, id: ele };
                optionsArr.push(obj);
            });
        }
        return FilterConfig[options] ? FilterConfig[options] : optionsArr;
    };

    const onDropDownChange = (event, column) => {
        dt.current.filter(event.value, column.field, 'equals');
        setState({ ...state, ['selected' + column.field]: event.value });
        props.lazyLoad && onChangeSearch(event.value, column);
    };
    const onMultiSelectChange = (event, column) => {
        dt.current.filter(event.value, column.field, 'in');
        setState({ ...state, ['selected' + column.field]: event.value });
        props.lazyLoad && onChangeSearch(event.value, column);
    };

    const onSelectDate = (event, column) => {
        let dateVal = event.value ? getDate(event.value) : '';
        let val = dateVal.split(',');
        if (val.length >= 2) {
            dateVal = val[0] + ',' + val[1];
        }
        dt.current.filter(dateVal, column.field, 'contains');
        setState({ ...state, ['date' + column.field]: event.value });
        props.lazyLoad && onChangeSearch(event.value, column);
    };

    const setFilterElement = (column) => {
        let filterElement = undefined;
        switch (column.filterElement) {
            case 'dropdown':
                filterElement = (
                    <Dropdown
                        className="w-100"
                        placeholder={column.filterPlaceholder}
                        appendTo={document.self}
                        value={
                            filterKeys && filterKeys[column.field] ? filterKeys[column.field].contains : state['selected' + column.field]
                        }
                        filter={true}
                        resetFilterOnHide={true}
                        filterPlaceholder="Search"
                        filterBy="label"
                        options={
                            setOptions(column.filterOptions).length > 0
                                ? setOptions(column.filterOptions)
                                : [{ label: 'No results found', value: 'No results found', disabled: true }]
                        }
                        onChange={(e) => onDropDownChange(e, column)}
                        showClear
                    />
                );
                break;
            case 'multiSelect':
                filterElement = (
                    <MultiSelect
                        className="w-100"
                        placeholder={column.filterPlaceholder}
                        appendTo={document.self}
                        value={
                            filterKeys && filterKeys[column.field] ? filterKeys[column.field].contains : state['selected' + column.field]
                        }
                        filter={true}
                        resetFilterOnHide={true}
                        filterPlaceholder="Search"
                        filterBy="label"
                        options={
                            setOptions(column.filterOptions).length > 0
                                ? setOptions(column.filterOptions)
                                : [{ label: 'No results found', value: 'No results found', disabled: true }]
                        }
                        onChange={(e) => onMultiSelectChange(e, column)}
                        showClear
                    />
                );
                break;
            case 'date':
                filterElement = (
                    <Calendar
                        overlayVisible
                        appendTo={document.self}
                        value={state['date' + column.field]}
                        showButtonBar={true}
                        placeholder={column.filterPlaceholder}
                        dateFormat="M dd, yy"
                        showIcon
                        onChange={(e) => onSelectDate(e, column)}
                    />
                );
                break;
            case 'search':
                filterElement = column.numericInput ? (
                    <InputText
                        className="filter-input w-100"
                        keyfilter="num"
                        value={
                            filterKeys[column.field]
                                ? filterKeys[column.field].contains
                                    ? filterKeys[column.field].contains
                                    : filterKeys[column.field].equals
                                : state['search' + column.field]
                        }
                        placeholder={column.filterPlaceholder}
                        onChange={(e) => onChangeSearch(e.target.value, column)}
                        autoComplete="off"
                        maxLength={column.maxLength ? column.maxLength : '50'}
                    />
                ) : (
                    <InputComponent
                        className="filter-input"
                        value={
                            filterKeys[column.field]
                                ? filterKeys[column.field].contains
                                    ? filterKeys[column.field].contains
                                    : filterKeys[column.field].equals
                                : state['search' + column.field]
                        }
                        placeholder={column.filterPlaceholder}
                        onChange={(e) => onChangeSearch(e, column)}
                        maxLength={column.maxLength ? column.maxLength : '50'}
                    />
                );
                break;
            default:
                break;
        }
        return filterElement;
    };

    const onChangeSearch = (e, column) => {
        if (column.numericInput && e.toString().split('.').length > 1 && e.toString().split('.')[1].length > 2) {
            return false;
        }
        let filterData = filterKeys;
        if (column.field === 'office') {
            filterData['departments'] = { some: { office_name: { contains: e, mode: 'insensitive' } } };
            setFilterKeys({ ...filterKeys, departments: { some: { office_name: { contains: e, mode: 'insensitive' } } } });
            props.lazyLoad && props.searchRecord(e, column, filterData);
        } else if (column.field === 'department') {
            filterData['departments'] = { some: { dept_name: { contains: e, mode: 'insensitive' } } };
            setFilterKeys({ ...filterKeys, departments: { some: { dept_name: { contains: e, mode: 'insensitive' } } } });
            props.lazyLoad && props.searchRecord(e, column, filterData);
        } else {
            filterData[column?.field] = { contains: e, mode: 'insensitive' };
            setFilterKeys({ ...filterKeys, [column?.field]: { contains: e, mode: 'insensitive' } });
            props.lazyLoad && props.searchRecord(e, column, filterData);
        }
    };

    const renderColumn = (column, ind) => {
        return (
            <Column
                key={ind}
                field={column?.field}
                header={column?.header}
                filter={column?.filter}
                frozen={column.frozen}
                sortable={column?.sortable}
                sortableDisabled={column?.sortableDisabled}
                selectionMode={column?.selectionMode}
                bodyStyle={{ textAlign: column?.bodyStyle }}
                filterMatchMode={column.filterMatchMode}
                filterPlaceholder={column?.filterPlaceholder}
                className={column?.className ? column.className : null}
                body={column.fieldType ? (e, index) => prepareBody(e, column, index) : column?.body}
                filterElement={
                    column?.filterElement !== null
                        ? props.lazyLoad
                            ? setFilterElement(column)
                            : column.filterElement === 'search'
                            ? null
                            : setFilterElement(column)
                        : column?.filterElement
                }
                style={{ width: column?.width, textAlign: column?.headerAlign, paddingLeft: column?.paddingLeft }}
            />
        );
    };

    const handlefiltered = (e) => {
        props.onValueChange && props.onValueChange();
        if (e && e.length <= 0) {
            setState({ ...state, searchRec: 'No Results Found' });
        }
    };

    const updateSelection = (e) => {
        if (props.isEffectOnRows) {
            if (props.isExist(e.value)) {
                setState({ ...state, selectedRows: e.value });
                props.updateInparent(e.value);
            }
        } else {
            setState({ ...state, selectedRows: e.value });
            props.updateInparent(e.value);
        }
    };

    const onPageChange = (event) => {
        props.lazyLoad && props.onPageChange(event);
    };

    const onPageWithoutLazyLoad = (event) => {
        setFirst(event.first);
        setRowsCount(event.rows);
    };

    const onSort = (event) => {
        props.lazyLoad && props.onSort(event);
    };
    return (
        <div className={`${props.loading ? 'mask' : ''}`}>
            {props.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            {props?.columnSelection && (
                <div className="col-md-12 p-t-10">
                    <div className="fTableRight">
                        <MultiSelect
                            value={selectedColumns}
                            options={props.columns}
                            optionLabel="field"
                            onChange={onColumnToggle}
                            className="fMultiSel"
                        />
                    </div>
                </div>
            )}
            <div className={props.className ? props.className : 'primary-table'}>
                <DataTable
                    ref={dt}
                    value={rows}
                    dataKey={props.dataKey || 'id'}
                    frozenWidth={props.frozenWidth}
                    scrollable={props.scrollable}
                    scrollHeight={props.scrollHeight}
                    headerColumnGroup={props.headerColumnGroup}
                    footerColumnGroup={props.footerGroup}
                    onValueChange={(e) => handlefiltered(e)}
                    rows={!props.noPaginator ? (props.pageRows && props.lazyLoad ? props.pageRows : rowsCount) : null}
                    selection={state.selectedRows ? state.selectedRows : props.selectedRows}
                    selectionMode={props?.selectionMode ? props.selectionMode : null}
                    onSelectionChange={(e) => updateSelection(e)}
                    resizableColumns={props.resizableColumns ? false : true}
                    columnResizeMode="fit"
                    reorderableColumns={true}
                    paginatorDropdownAppendTo={document.self}
                    paginator={props.noPaginator ? false : props.rows && props.rows.length > 0 ? true : false}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{first} to {last} of {totalRecords} Records"
                    rowGroupFooterTemplate={props.rowGroupFooterTemplate}
                    rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
                    rowsPerPageOptions={[10, 25, 50]}
                    lazy={props.lazyLoad ? true : false}
                    first={props.lazyLoad ? props?.count : first}
                    rowGroupMode={props.rowGroupMode ? props.rowGroupMode : null}
                    groupField={props.groupField ? props.groupField : null}
                    sortField={props.lazyLoad || props.customSort ? props.sortField : null}
                    sortOrder={props.lazyLoad || props.customSort ? props.sortOrder : null}
                    onSort={props.lazyLoad ? onSort : null}
                    onPage={props.lazyLoad ? onPageChange : onPageWithoutLazyLoad}
                    totalRecords={props?.totalRecords}
                    emptyMessage={state?.searchRec !== '' ? state.searchRec : props?.emptyMessage}
                    editMode={props.customEdit ? props.editMode : null}
                >
                    {selectedColumns && selectedColumns.length > 0 && selectedColumns.map((column, ind) => renderColumn(column, ind))}
                </DataTable>
            </div>
        </div>
    );
};
export default FDataTable;
