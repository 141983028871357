import React, { useState, useEffect } from 'react';
import InputComponent from '../../../components/InputComponent/InputComponent';
import FormSchema from './formSchema';
import './contract.scss';
import Schema from '../../../schema/AppSchema';
import { useQuery } from '@apollo/client';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import Calendar from '../../../components/Calendar/CalendarComponent';
import { contractClient } from '../../../apollo';
import { getDate, serverDate } from '../../../util';
import { getFinancialYears } from '../../../services/commonService';
import ContractTypeModal from './ContractTypeModal';
import * as FormValidation from '../../../Validation';

const GeneralInformationForm = (props) => {
    const [state, setState] = useState({
        sanAgencyList: [],
        impleAgencyList: [],
        projectsList: [],
        loading: true,
        showCntrctModal: false,
        eve: ''
    });
    let years = props?.formStateObj?.year_id?.split('-');
    let signingDateRange = years[0] + ':' + (parseInt(years[1])).toString();

    const [viewState, setViewState] = useState();
    let startDate = props.adminData?.admFinYear?.master_start_date;
    const [formState, setFormState] = useState(props.formStateObj?.General_Contract_Information);

    const handleChange = async (ev, key) => {
        if (key === 'closing_date') {
            let val = checkValidation(ev);
            if (val) {
                setState({ ...state, showCntrctModal: true, eve: ev });
                return;
            }
        } else if (key === 'proc_ref' || key === 'adm_ref' || key === 'tech_ref') {
            validateFeild(ev, key);
        }

        await setFormState({
            ...formState,
            [key]: ev
        });
    };

    const validateFeild = async (ev, key) => {
        let err = {};
        const text = fields.filter((obj) => {
            return key === obj['key'];
        });
        err = FormValidation.validateFileld(text[0].validators, key, ev);
        let prevErrors = { ...errors, ...err };
        setErrors(prevErrors);
    };

    const fields = [
        { key: 'proc_ref', validators: 'startWithAlpha' },
        { key: 'adm_ref', validators: 'startWithAlpha' },
        { key: 'tech_ref', validators: 'startWithAlpha' }
    ];

    const [errors, setErrors] = useState({});

    useQuery(Schema.generalInfoForm, {
        variables: { prjcts: { is_active: { equals: true } } },
        client: contractClient,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data?.sanction && data?.implement) {
                data.sanction.filter((a) => (a.nameCode = a.segment_full_code + '-' + a.segment_name));
                data.implement.filter((a) => (a.nameCode = a.segment_full_code + '-' + a.segment_name));
                data.projects.filter((a) => (a.nameCode = a.project_name + ' (' + a.project_number + ')'));
                setState({
                    ...state,
                    sanAgencyList: data.sanction,
                    impleAgencyList: data.implement,
                    projectsList: data.projects,
                    loading: false
                });
                if (props.formType === 'view' || props.checkIsApproved) {
                    let sanc = data.sanction.find((a) => a.id === formState.sanct_agency_id)?.nameCode || '–';
                    let impl = data.implement.find((a) => a.id === formState.impl_agency_id)?.nameCode || '–';
                    let projct = data.projects?.find((a) => a.id === formState.project)?.nameCode || '–';
                    setViewState({ sanct_agency_id: sanc, impl_agency_id: impl, project: projct });
                }
                return;
            }
            setState({ ...state, loading: false });
        },
        onError: () => {
            setState({ ...state, loading: false });
        }
    });
    const getSuplCats = (ev) => {
        let suplCats = [];
        for (let i = 0; i < props.formStateObj.Contract_Details.rows.length; i++) {
            const element = props.formStateObj.Contract_Details.rows[i];
            let years = getFinancialYears(formState.signing_date, ev);
            let exists = element.cntrct_fin_sanc.finRows.some((a) => years.indexOf(a.year) === -1);
            if (element.cntrct_fin_sanc?.finRows?.length > 0 && ev && exists) {
                element.cntrct_fin_sanc.finRows.forEach((a) => {
                    if (years.indexOf(a.year) === -1) suplCats.push(element.sup_cat?.name + '(' + element.hoa + ')');
                });
            }
        }
        return suplCats;
    };
    const checkValidation = (ev) => {
        let suplCats = [];
        if (props.formStateObj?.Contract_Details?.rows?.length > 0) {
            // financial sanction years greater than closing date
            suplCats = getSuplCats(ev);
            if (suplCats.length > 0)
                return 'Closing date is modified, kindly update Financial Sanction for Supply Categories(HOA) : ' + suplCats.toString();
        }
    };
    useEffect(() => {
        props.saveFormData('General_Contract_Information', formState);
        // eslint-disable-next-line
    }, [formState]);

    const handleYes = () => {
        formState.closing_date = new Date(state.eve);
        setFormState({
            ...formState,
            closing_date: state.eve
        });
        setState({ ...state, eve: '', showCntrctModal: false });
    };

    const handleCancel = () => {
        formState.closing_date = new Date(props.formStateObj?.General_Contract_Information?.closing_date);
        setFormState({ ...formState, closing_date: formState.closing_date });
        setState({ ...state, eve: '', showCntrctModal: false });
    };

    return (
        <div className={`coa-card overflow-initial p-10 ${state.loading ? 'mask' : ''}`}>
            {state.loading && <ProgressSpinner className="spinner-wt-ht" strokeWidth="5" animationDuration=".5s" />}
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_SANC_AGNCY.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_SANC_AGNCY.name}
                                    {FormSchema.FLD_GCI_SANC_AGNCY.is_mandatory !== -1 &&
                                    props.formType !== 'view' &&
                                    !props.checkIsApproved ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && !props.checkIsApproved ? (
                                <Dropdown
                                    className="w-100"
                                    name="sanct_agency_id"
                                    value={formState.sanct_agency_id}
                                    onChange={(e) => handleChange(e.value, 'sanct_agency_id')}
                                    appendTo={document.self}
                                    options={state.sanAgencyList}
                                    placeholder="Select Sanctioning Agency"
                                    optionLabel="nameCode"
                                    optionValue="id"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    resetFilterOnHide={true}
                                    filterBy="nameCode"
                                />
                            ) : (
                                <span>{viewState?.sanct_agency_id ? viewState?.sanct_agency_id : '–'}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_IMPL_AGNCY.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_IMPL_AGNCY.name}
                                    {FormSchema.FLD_GCI_IMPL_AGNCY.is_mandatory !== -1 &&
                                    props.formType !== 'view' &&
                                    !props.checkIsApproved ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && !props.checkIsApproved ? (
                                <Dropdown
                                    className="w-100"
                                    name="impl_agency_id"
                                    value={formState.impl_agency_id}
                                    onChange={(e) => handleChange(e.value, 'impl_agency_id')}
                                    appendTo={document.self}
                                    options={state.impleAgencyList}
                                    placeholder="Select Implementing / Purchasing Agency"
                                    resetFilterOnHide={true}
                                    optionLabel="nameCode"
                                    optionValue="id"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="nameCode"
                                />
                            ) : (
                                <span>{viewState?.impl_agency_id ? viewState?.impl_agency_id : '–'}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-m-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6 ">
                            <b>Currency : </b>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                            <span className="fs-14 year-color">{formState.ccy ? formState.ccy : '–'}</span>
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_SIGN_DTAE.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_SIGN_DTAE.name}
                                    {FormSchema.FLD_GCI_SIGN_DTAE.is_mandatory !== -1 &&
                                    props.formType !== 'view' &&
                                    !props.checkIsApproved ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && !props.checkIsApproved ? (
                                <Calendar
                                    className="w-100"
                                    minDate={new Date(startDate)}
                                    maxDate={formState.closing_date}
                                    readOnlyInput={true}
                                    placeholder="Select Signing Date"
                                    appendTo={document.self}
                                    value={formState.signing_date}
                                    onChange={(e) => handleChange(e.target.value, 'signing_date')}
                                    dateFormat="dd M yy"
                                    showIcon
                                    yearRange={signingDateRange}
                                    yearNavigator={true}
                                    monthNavigator={true}
                                />
                            ) : (
                                <span>{getDate(formState.signing_date, true) ? getDate(formState.signing_date, true) : '–'}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_CLOSE_DATE.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_CLOSE_DATE.name}
                                    {FormSchema.FLD_GCI_CLOSE_DATE.is_mandatory !== -1 && props.formType !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && !state.showCntrctModal && (
                                <Calendar
                                    className="w-100"
                                    minDate={!props.checkIsApproved ? formState.signing_date : serverDate()}
                                    readOnlyInput={true}
                                    disabled={!formState.signing_date}
                                    placeholder="Select Closing Date"
                                    appendTo={document.self}
                                    value={formState.closing_date}
                                    onChange={(e) => handleChange(e.target.value, 'closing_date')}
                                    dateFormat="dd M yy"
                                    showIcon
                                    yearRange={signingDateRange}
                                    yearNavigator={true}
                                    monthNavigator={true}
                                />
                            )}
                            {props.formType !== 'view' && state.showCntrctModal && (
                                <Calendar
                                    placeholder="Select Closing Date"
                                    appendTo={document.self}
                                    value={props.formStateObj?.General_Contract_Information?.closing_date}
                                    dateFormat="dd M yy"
                                    showIcon
                                />
                            )}
                            {props.formType === 'view' && (formState.closing_date ? getDate(formState.closing_date, true) : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_GOVT_REF_NO.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b title="Government Contract ref no./Work Order No.">
                                    {FormSchema.FLD_GCI_GOVT_REF_NO.name}
                                    {FormSchema.FLD_GCI_GOVT_REF_NO.is_mandatory !== -1 && props.formType !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="govt_ref"
                                    type="text"
                                    value={formState.govt_ref}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'govt_ref')}
                                    placeholder="Enter Contract/Work Order No"
                                    alphanumeric="true"
                                />
                            )}
                            {props.formType === 'view' && (formState.govt_ref ? formState.govt_ref : '–')}
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_PRJCT_NAME_ID.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_PRJCT_NAME_ID.name}
                                    {FormSchema.FLD_GCI_PRJCT_NAME_ID.is_mandatory !== -1 &&
                                    props.formType !== 'view' &&
                                    !props.checkIsApproved ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && !props.checkIsApproved ? (
                                <Dropdown
                                    className="w-100"
                                    name="project"
                                    value={formState.project}
                                    onChange={(e) => handleChange(e.value, 'project')}
                                    appendTo={document.self}
                                    options={state.projectsList}
                                    placeholder="Select Project Name / Project ID"
                                    optionLabel="nameCode"
                                    filter={true}
                                    filterPlaceholder="Search"
                                    filterBy="nameCode"
                                    optionValue="id"
                                    resetFilterOnHide={true}
                                />
                            ) : (
                                <span>{viewState?.project ? viewState.project : '–'}</span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"></div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_ADMN_REF_NO.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b title="Administration Approval Ref No./ F&CC Approval Ref No. (Finance and contract committee)">
                                    {FormSchema.FLD_GCI_ADMN_REF_NO.name}
                                    {FormSchema.FLD_GCI_ADMN_REF_NO.is_mandatory !== -1 && props.formType !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="adm_ref"
                                    type="text"
                                    value={formState.adm_ref}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'adm_ref')}
                                    placeholder="Enter Adm. Approval/ F&CC Approval Ref No"
                                    errMsg={formState.adm_ref ? 'Please enter valid Adm. Approval/ F&CC Approval Ref No' : ''}
                                    hasError={errors && errors.adm_ref}
                                    validations={['startWithAlpha']}
                                    alphanumeric="true"
                                />
                            )}
                            {props.formType === 'view' && (formState.adm_ref ? formState.adm_ref : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-b-10">
                    <div className="row">
                        {FormSchema.FLD_GCI_TECH_REF_NO.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b>
                                    {FormSchema.FLD_GCI_TECH_REF_NO.name}
                                    {FormSchema.FLD_GCI_TECH_REF_NO.is_mandatory !== -1 && props.formType !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="tech_ref"
                                    type="text"
                                    value={formState.tech_ref}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'tech_ref')}
                                    placeholder="Enter Technical Sanction Ref No"
                                    errMsg={formState.tech_ref ? 'Please enter valid Technical Sanction Ref No' : ''}
                                    hasError={errors && errors.tech_ref}
                                    validations={['startWithAlpha']}
                                    alphanumeric="true"
                                />
                            )}
                            {props.formType === 'view' && (formState.tech_ref ? formState.tech_ref : '–')}
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="row">
                        {FormSchema.FLD_GCI_PROCU_REF_NO.is_visible !== -1 && (
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-6">
                                <b title="Procurement Ref No./ NIT Ref No.(Notice Inviting Tender)">
                                    {FormSchema.FLD_GCI_PROCU_REF_NO.name}
                                    {FormSchema.FLD_GCI_PROCU_REF_NO.is_mandatory !== -1 && props.formType !== 'view' ? (
                                        <span className="icon-primary"> *</span>
                                    ) : (
                                        ' :'
                                    )}
                                </b>
                            </div>
                        )}
                        <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-6">
                            {props.formType !== 'view' && (
                                <InputComponent
                                    inputBoxSize="sm"
                                    name="proc_ref"
                                    type="text"
                                    value={formState.proc_ref}
                                    maxLength={30}
                                    onChange={(e) => handleChange(e, 'proc_ref')}
                                    placeholder="Enter Procurement/NIT Ref No"
                                    validations={['startWithAlpha']}
                                    errMsg={formState.proc_ref ? 'Please enter valid Procurement/NIT Ref No' : ''}
                                    hasError={errors && errors.proc_ref}
                                    alphanumeric="true"
                                />
                            )}
                            {props.formType === 'view' && (formState.proc_ref ? formState.proc_ref : '–')}
                        </div>
                    </div>
                </div>
            </div>
            {state.showCntrctModal && (
                <ContractTypeModal
                    handleYes={handleYes}
                    handleCancel={() => handleCancel()}
                    showModal={state.showCntrctModal}
                    bodyText="Are you sure you want to change Closing Date ? "
                    headerText="Closing Date"
                />
            )}
        </div>
    );
};

export default GeneralInformationForm;
