import { Switch, Redirect, Route } from 'react-router-dom';
import React, { lazy } from 'react';
import BreadCRumb from '../components/breadCrumb/BreadCrumb';
import IsAuthorized from '../commons/AuthorizationService';
import Auth from '../pages/auth/Auth';
import { MenuItemsSchema } from '../commons/MenuItemsSchema';
import DocumentCategory from '../pages/commonMasters/DocumentCategory/DocumentCategory';
import CreateDocumentCategory from '../pages/commonMasters/DocumentCategory/CreateDocumentCategory';
const ExchangeCurrency = lazy(() => import('../pages/commonMasters/ExchangeCurrency/ExchangeCurrency'));
const IFSCCodes = lazy(() => import('../pages/commonMasters/IFSC/IFSCcodes'));
const OverallComments = lazy(() => import('../pages/commonMasters/commentConfiguration/OverallComments'));
const OverallCommentsCreate = lazy(() => import('../pages/commonMasters/commentConfiguration/OverallCommentsCreate'));
const CommentConfiguration = lazy(() => import('../pages/commonMasters/commentConfiguration/commentConfiguration'));
const FunctionalCommentsCreate = lazy(() => import('../pages/commonMasters/commentConfiguration/FunctionalCommentsCreate'));
const DeductionMasters = lazy(() => import('../pages/commonMasters/DeductionMasters/DeductionMasters'));
const AddDeductionMaster = lazy(() => import('../pages/commonMasters/DeductionMasters/AddDeductionMaster'));
const LockFacility = lazy(() => import('../pages/commonMasters/SystemSecurity/LockFacility'));
const PasswordExpired = lazy(() => import('../pages/commonMasters/SystemSecurity/PasswordExpired'));
const SessionExpired = lazy(() => import('../pages/commonMasters/SystemSecurity/SessionExpired'));
const BeneficiaryDetails = lazy(() => import('../pages/commonMasters/beneficiary/beneficiaryDetails/BeneficiaryDetails'));
const CreateBeneficiary = lazy(() => import('../pages/commonMasters/beneficiary/beneficiaryDetails/CreateBeneficiary'));
const BeneficiaryCategories = lazy(() => import('../pages/commonMasters/beneficiary/beneficiaryCategory/BeneficiaryCategory'));
const AddBeneficiaryCategory = lazy(() => import('../pages/commonMasters/beneficiary/beneficiaryCategory/AddBeneficiaryCategory'));
const DepartmentconfigMaster = lazy(() => import('../components/agencymaster/DepartmentConfig/Departments'));
const ConfigDepartmentMaster = lazy(() => import('../components/agencymaster/DepartmentConfig/ConfigDepartments'));
const BudgetConfigMaster = lazy(() => import('../components/agencymaster/AgencyMaster'));
const DeputationconfigMaster = lazy(() => import('../components/agencymaster/AgencyMaster'));
const commonMastersItems = MenuItemsSchema.COMMON_MASTERS;
const DefineLocalitiesCodes = lazy(() => import('../pages/commonMasters/DefineLocalities/DefineLocalitiesCodes.jsx'));
const commonMasterBreadCrmb = (props) => {
    let AllCoaBreadcrumbValues = [{ name: 'System Administration', redirectTo: '/systemadministration' }];
    const pathTotalValue = props.parentProps.location.pathname;
    let subPath = pathTotalValue.split('/');
    let obj;
    if (
        subPath[3] === 'view' ||
        subPath[3] === 'clone' ||
        subPath[3] === 'create' ||
        subPath[3] === 'edit' ||
        subPath[3] === 'approveddepartment'
    ) {
        obj = [
            { name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), redirectTo: '/commonmasters/' + subPath[2], key: subPath[2] },
            { name: subPath[3].charAt(0).toUpperCase() + subPath[3].slice(1), redirectTo: '', key: subPath[3] }
        ];
    } else {
        obj = [{ name: subPath[2].charAt(0).toUpperCase() + subPath[2].slice(1), key: subPath[2], redirectTo: '' }];
    }
    AllCoaBreadcrumbValues = [...AllCoaBreadcrumbValues, ...obj];
    return AllCoaBreadcrumbValues;
};
const UnAuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Auth}></Route>
            <Redirect to="/login" />
        </Switch>
    );
};
const AuthGaurd = (params) => {
    let Privileges = '';
    if (params.menuItems?.length > 0) Privileges = params.menuItems.find((a) => params.path.indexOf(a.routerLink) !== -1);
    if (params.menuItems?.length > 0 && !Privileges) {
        params.menuItems.forEach((a) => {
            let val = '';
            if (a.subMenu) val = a.subMenu.find((b) => params.path.indexOf(b.routerLink) !== -1);
            if (val) {
                Privileges = val;
                return Privileges;
            }
        });
    }
    let route = (
        <IsAuthorized
            privRequired={Privileges?.VIEW}
            officeCode={Privileges?.OFFICE_CODE}
            yes={() => <Route {...params} />}
            no={() => <UnAuthRoutes />}
        ></IsAuthorized>
    );
    return route;
};
const CommonMastersRouting = (props) => {
    return (
        <div className="coa-card p-10">
            <BreadCRumb items={commonMasterBreadCrmb(props)} homeRoute="/dashboard" />
            <Switch>
                <AuthGaurd
                    {...props}
                    component={ExchangeCurrency}
                    exact
                    path={`${props?.path}/exchangecurrency`}
                    name="Exchange Currency Rate Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={IFSCCodes}
                    exact
                    path={`${props?.path}/ifsccodemaster`}
                    name="IFSC Code Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={OverallComments}
                    exact
                    path={`${props?.path}/overallcomments`}
                    name="Overall Comments"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={OverallCommentsCreate}
                    exact
                    path={`${props?.path}/overallcomments/create`}
                    name="Overall Comments"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={OverallCommentsCreate}
                    exact
                    path={`${props?.path}/overallcomments/edit/:id`}
                    name="Overall Comments"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CommentConfiguration}
                    exact
                    path={`${props?.path}/commentsconfiguration`}
                    name="Comments Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={FunctionalCommentsCreate}
                    exact
                    path={`${props?.path}/commentsconfiguration/create`}
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={FunctionalCommentsCreate}
                    exact
                    path={`${props?.path}/commentsconfiguration/edit/:id`}
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={FunctionalCommentsCreate}
                    exact
                    path={`${props?.path}/commentsconfiguration/view/:id`}
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={DeductionMasters}
                    exact
                    path={`${props?.path}/deductionmaster`}
                    name="Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/view/:id/draft`}
                    name="Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/edit/:id/draft`}
                    name="Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/view/:id`}
                    name="Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/view/:id`}
                    name="Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/create`}
                    name="Add Deduction Master Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/edit/:id`}
                    name="Add Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/edit/:id/approved/:ref`}
                    name="Add Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddDeductionMaster}
                    exact
                    path={`${props?.path}/deductionmaster/view/:id/approved/:ref`}
                    name="Add Deduction Master"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={DepartmentconfigMaster}
                    exact
                    path={`${props?.path}/departmentconfig`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/approveddepartment`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/create`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/edit/:id`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/view/:id`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/view/:id/draft`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={ConfigDepartmentMaster}
                    exact
                    path={`${props?.path}/departmentconfig/edit/:id/draft`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={BudgetConfigMaster}
                    exact
                    path={`${props?.path}/budgetconfig`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={DeputationconfigMaster}
                    exact
                    path={`${props?.path}/deputationconfig`}
                    name="Department Configuration"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={DefineLocalitiesCodes}
                    exact
                    path={`${props?.path}/defineLocalities`}
                    name="Define Locality"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={BeneficiaryDetails}
                    exact
                    path={`${props?.path}/beneficiarydetails`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/create`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/edit/:id/draft`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/view/:id/draft`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/view/:id`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/edit/:id`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/view/:id/approved/:ref`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateBeneficiary}
                    exact
                    path={`${props?.path}/beneficiarydetails/edit/:id/approved/:ref`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={BeneficiaryCategories}
                    exact
                    path={`${props?.path}/beneficiarycategory`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/create`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/edit/:id/draft`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/view/:id/draft`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/view/:id`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/edit/:id`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/view/:id/approved`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={AddBeneficiaryCategory}
                    exact
                    path={`${props?.path}/beneficiarycategory/edit/:id/approved`}
                    name="Beneficiary Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={LockFacility}
                    path={`${props?.path}/lockfacility`}
                    name="Lock Facility"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={SessionExpired}
                    path={`${props?.path}/sessionexpired`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={PasswordExpired}
                    path={`${props?.path}/passwordexpired`}
                    name="Beneficiary Details"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={DocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory/create`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory/edit/:id/draft`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory/view/:id/draft`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory/view/:id`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
                <AuthGaurd
                    {...props}
                    component={CreateDocumentCategory}
                    exact
                    path={`${props?.path}/documentcategory/edit/:id`}
                    name="Document Category"
                    menuItems={commonMastersItems}
                />
            </Switch>
        </div>
    );
};
export default CommonMastersRouting;
